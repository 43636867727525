
let balMap = {};

export function clearBalancesCache() {
  balMap = {};
}

export function getBalMap() {
  return balMap;
}

