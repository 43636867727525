
export const styles = () => ({

  root: {
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'row',
  },
  reviewCardRoot: {
    maxHeight: 250,
    padding: 20,
    paddingBottom: 10,
  },
  header: {
    background: '#c5c5c5',
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px 20px 0px 20px',
  },
  transactionList: {
    maxHeight: 190,
    paddingRight: 12,
  },
});

