import React, { FC, Fragment, useState } from 'react';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import GraphIcon from '@mui/icons-material/Leaderboard';
import SettingsIcon from '@mui/icons-material/Settings';
import RolloverPoistiveIcon from '@mui/icons-material/Forward';

import { tracker } from 'companion-app-components/utils/core';
import { formatNumber } from 'components/QuickenControls/AmountField';
import { BudgetRollOverProps } from 'companion-app-components/flux/budgets/budgetsTypes';
import AnnualViewCategoryLabel from './AnnualViewCategoryLabel';
import { border, fontWeight, StickyTableCell } from './styles';
import { BUDGET_ANNUAL_SUBVIEWS, BUDGET_ANNUAL_INVISIBLE_AMOUNT_SYMBOL } from '../../constants';
import {
  getMonthlyCellSxProps,
  getAmountColor,
  hideToDateColumn,
  hideBalanceViewFutureAmount,
  hideDetailsViewCells,
} from '../../utils';

/* eslint react/prop-types: 0 */

const currentMonth = DateTime.local().month;
const currentYear = DateTime.local().year;
let putTrackerEvent = true;

const RolloverAllIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5858 4.00002L10.2929 3.70712L11.7071 2.29291L14.4142 5.00001L11.7071 7.70712L10.2929 6.29291L10.5858 6.00002H9.57037C9.23601 6.00002 8.92378 6.16712 8.73832 6.44531L8.22655 7.21297L6.99521 5.45443L7.07422 5.33591C7.63061 4.50132 8.56731 4.00002 9.57037 4.00002H10.5858ZM10.5858 10L10.2929 9.70712L11.7071 8.29291L14.4142 11L11.7071 13.7071L10.2929 12.2929L10.5858 12H9.57037C8.56731 12 7.63061 11.4987 7.07422 10.6641L4.26168 6.44532C4.07622 6.16712 3.76399 6.00002 3.42963 6.00002H2V4.00002H3.42963C4.43269 4.00002 5.36939 4.50132 5.92578 5.33591L8.73832 9.55472C8.92378 9.83291 9.23601 10 9.57037 10H10.5858ZM4.61357 9.02689L4.26168 9.55472C4.07622 9.83291 3.76399 10 3.42963 10H2V12H3.42963C4.38438 12 5.279 11.5458 5.84277 10.7824L4.61357 9.02689Z" />
  </SvgIcon>
);

const renderBudgetAmountText = (
  annualSubView,
  objMonthlyAmounts,
  amount,
  handleActualAmountClick: null | (() => void) = null,
  isRollover = false,
  isQWinDataset = false,
  handleRolloverAmountClick: (null | ((evt: React.MouseEvent<HTMLButtonElement>) => void)) = null,
  isSelected?: boolean,
  showFutureMonthDetails?: boolean,
  budgetEndMonth?: number,
  budgetEndYear?: number,
) => {
  let returnData = amount;
  if (hideBalanceViewFutureAmount(showFutureMonthDetails, objMonthlyAmounts, annualSubView, currentMonth, currentYear, budgetEndMonth, budgetEndYear)) {
    returnData = BUDGET_ANNUAL_INVISIBLE_AMOUNT_SYMBOL;
  } else if ((annualSubView === BUDGET_ANNUAL_SUBVIEWS.balance.key || annualSubView === BUDGET_ANNUAL_SUBVIEWS.details.key)
    && isRollover && isQWinDataset && !objMonthlyAmounts.isToDate) {
    returnData = (
      <Link
        component="button"
        underline="none"
        onClick={(evt: React.MouseEvent<HTMLButtonElement>) => handleRolloverAmountClick && handleRolloverAmountClick(evt)}
        style={{ color: isSelected ? undefined : 'inherit', fontWeight: 600 }}
        fontSize={14}
      >
        {amount}
      </Link>
    );
  } else if ((annualSubView === BUDGET_ANNUAL_SUBVIEWS.actual.key || annualSubView === BUDGET_ANNUAL_SUBVIEWS.details.key)
    && !objMonthlyAmounts.isToDate) {
    returnData = (
      <Link
        component="button"
        underline="none"
        onClick={() => handleActualAmountClick && handleActualAmountClick()}
        fontSize={14}
      >
        {amount}
      </Link>
    );
  }

  return returnData;
};

const renderBudgetAmountInputField = (
  handleBudgetItemAmountFocus,
  handleBudgetItemAmountChange,
  handleBudgetItemAmountSave,
  objMonthlyAmounts,
) => (
  <TextField
    variant="outlined"
    size="small"
    value={objMonthlyAmounts.budgetAmount}
    sx={{ backgroundColor: 'white' }}
    InputProps={{
      inputProps: {
        style: { textAlign: 'right', padding: 8, fontSize: 14 },
      },
    }}
    onFocus={handleBudgetItemAmountFocus}
    onBlur={handleBudgetItemAmountSave}
    onChange={handleBudgetItemAmountChange}
  />
);

const AnnualViewCategoryRow = ({
  budget,
  budgetItem,
  detailsViewKey,
  annualSubView,
  handleParentCategoryToggle,
  budgetEndYear,
  budgetEndMonth,
  colorGreen,
  colorLightBlue,
  colorActual,
  colorBalance,
  isToDateColumnEnabled,
  amountFormat,
  colorSelectedRow,
  handleBudgetItemSelect,
  handleBudgetItemGraphClick,
  handleBudgetItemMenuClick,
  handleBudgetItemAmountFocus,
  handleBudgetItemAmountChange,
  handleBudgetItemAmountSave,
  setPopperAndContents,
  isQWinDataset,
  showFutureMonthDetails,
  enableBudgetCategoryGraph,
  enableBudgetCategoryEdit,
}) => {
  const {
    key,
    displayLabel,
    negate,
    hasChildren,
    monthlyAmounts,
    summary,
    isSelfExpanded,
    rolloverType,
    parentsCount,
    isSelected = false,
    isEverythingElse = false,
  } = budgetItem;
  const isExpense = negate < 0;
  const summaryAmount = summary?.[BUDGET_ANNUAL_SUBVIEWS[annualSubView].amountKey] || 0;
  const showAmountInput = isSelected && !hasChildren &&
    (annualSubView === BUDGET_ANNUAL_SUBVIEWS.budget.key || annualSubView === BUDGET_ANNUAL_SUBVIEWS.details.key);
  const [arrMonthlyAmounts, setArrMonthlyAmounts] = useState(monthlyAmounts);
  let inputNumber = 0;
  const isRollover = rolloverType === BudgetRollOverProps.ROLLOVER_POSITIVE || rolloverType === BudgetRollOverProps.ROLLOVER_ALL;
  
  if (putTrackerEvent && !isExpense && isRollover) {
    tracker.track(tracker.events.rolloverEnabled);
    putTrackerEvent = false;
  }

  return (
    <TableRow onClick={(evt) => handleBudgetItemSelect(budgetItem, evt)}>
      <StickyTableCell
        sx={{
          p: 0,
          pl: `${parentsCount * 32}px`,
          ...(isSelected && { backgroundColor: `${colorSelectedRow} !important` }),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {hasChildren ? (
              <IconButton onClick={(evt) => handleParentCategoryToggle(budgetItem, evt)}>
                {isSelfExpanded ? <RemoveIcon /> : <AddIcon />}
              </IconButton>
            ) : (
              <IconButton disableRipple disabled>
                <SvgIcon />
              </IconButton>
            )}
            <Typography variant="subtitle2">
              <AnnualViewCategoryLabel label={displayLabel} />
            </Typography>
          </Box>
          {budgetItem.depth > 0 && isSelected && (
            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
              {enableBudgetCategoryGraph && (
                <IconButton
                  disableRipple
                  size="small"
                  onClick={() => handleBudgetItemGraphClick(budgetItem.id, null, isEverythingElse, displayLabel)}
                >
                  <GraphIcon fontSize="small" />
                </IconButton>
              )}
              {enableBudgetCategoryEdit && !budgetItem.hasChildren
                && annualSubView !== BUDGET_ANNUAL_SUBVIEWS.actual.key
                && annualSubView !== BUDGET_ANNUAL_SUBVIEWS.balance.key
                ? (
                  <IconButton
                    disableRipple
                    size="small"
                    onClick={(evt) => handleBudgetItemMenuClick(evt)}
                  >
                    <SettingsIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <IconButton disableRipple disabled size="small">
                    <SvgIcon />
                  </IconButton>
                )}
            </Box>
          )}
          {budgetItem.depth > 0 && !hasChildren && isRollover &&
            <IconButton
              disableRipple
              size="small"
              sx={{
                cursor: 'not-allowed',
                opacity: isRollover ? 1 : 0.5,
              }}
            >
              {rolloverType === BudgetRollOverProps.ROLLOVER_POSITIVE
                ? <RolloverPoistiveIcon fontSize="small" sx={{ color: colorGreen }} />
                : <RolloverAllIcon fontSize="small" sx={{ color: colorGreen }} />}
            </IconButton>}
        </Box>
      </StickyTableCell>
      {arrMonthlyAmounts && arrMonthlyAmounts.map((objMonthlyAmounts) => {
        if (hideToDateColumn(isToDateColumnEnabled, objMonthlyAmounts.isToDate, currentMonth, budgetEndMonth)) {
          return null;
        }
        if (!objMonthlyAmounts.isToDate) { inputNumber += 1; }

        let colorItemBalance = 'inherit';
        const objMonthlyCellSxProps = getMonthlyCellSxProps(
          objMonthlyAmounts.startDate,
          currentMonth,
          currentYear,
          budgetEndYear,
          colorLightBlue,
          border,
          objMonthlyAmounts.isToDate,
          isSelected,
          colorSelectedRow,
        );
        if ((objMonthlyAmounts.balanceAmount < 0 && (isExpense || !isExpense))) {
          colorItemBalance = colorBalance;
        }
        const suffixKey = objMonthlyAmounts.isToDate ? 'toDate' : `${objMonthlyAmounts.startDate.month}`;
        if (hideDetailsViewCells(showFutureMonthDetails, objMonthlyAmounts, annualSubView, currentMonth, currentYear)) {
          return (
            <TableCell
              align="right"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sx={{
                ...objMonthlyCellSxProps,
                ...(!showAmountInput && { pr: 1 }),
              }}
              data-input-number={inputNumber}
            >
              {showAmountInput
                ? renderBudgetAmountInputField(
                  (evt) => handleBudgetItemAmountFocus(objMonthlyAmounts, evt),
                  (evt) => handleBudgetItemAmountChange(arrMonthlyAmounts, setArrMonthlyAmounts, evt),
                  () => handleBudgetItemAmountSave(budgetItem, objMonthlyAmounts),
                  objMonthlyAmounts,
                ) : formatNumber(objMonthlyAmounts.budgetAmount, false, amountFormat)}
            </TableCell>
          );
        }
        return annualSubView === detailsViewKey && !objMonthlyAmounts.isToDate ? (
          <Fragment key={`annual-row-column-cell-${budget.id}-${key}_${suffixKey}`}>
            <TableCell
              align="right"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sx={{
                ...objMonthlyCellSxProps,
                ...(!showAmountInput && { pr: 1 }),
              }}
              data-input-number={inputNumber}
            >
              {showAmountInput
                ? renderBudgetAmountInputField(
                  (evt) => handleBudgetItemAmountFocus(objMonthlyAmounts, evt),
                  (evt) => handleBudgetItemAmountChange(arrMonthlyAmounts, setArrMonthlyAmounts, evt),
                  () => handleBudgetItemAmountSave(budgetItem, objMonthlyAmounts),
                  objMonthlyAmounts,
                ) : formatNumber(objMonthlyAmounts.budgetAmount, false, amountFormat)}
            </TableCell>
            <TableCell
              align="right"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sx={{
                ...objMonthlyCellSxProps,
                color: colorActual,
                pr: 1,
              }}
            >
              {renderBudgetAmountText(
                annualSubView,
                objMonthlyAmounts,
                formatNumber(objMonthlyAmounts.actualAmount, false, amountFormat),
                () => handleBudgetItemGraphClick(budgetItem.id, objMonthlyAmounts.startDate, isEverythingElse, displayLabel),
              )}
            </TableCell>
            <TableCell
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sx={{
                ...objMonthlyCellSxProps,
                fontWeight,
                color: colorItemBalance,
                pr: 1,
              }}
              align="right"
            >
              {(isRollover && isQWinDataset) ? renderBudgetAmountText(
                annualSubView,
                objMonthlyAmounts,
                formatNumber(objMonthlyAmounts.balanceAmount, false, amountFormat),
                null,
                isRollover,
                isQWinDataset,
                (evt: React.MouseEvent<HTMLElement>) => {
                  const targetElement = evt.target as HTMLElement;
                  setPopperAndContents(targetElement.parentElement, { displayLabel, rolloverType, objMonthlyAmounts, colorItemBalance, budgetItem, arrMonthlyAmounts, setArrMonthlyAmounts }, evt);
                },
                isSelected,
              ) : formatNumber(objMonthlyAmounts.balanceAmount, false, amountFormat)}
            </TableCell>
          </Fragment>
        ) : (
          <TableCell
            key={`annual-row-column-cell-${budget.id}-${key}-${suffixKey}`}
            sx={{
              maxWidth: 80,
              ...objMonthlyCellSxProps,
              ...((annualSubView === BUDGET_ANNUAL_SUBVIEWS.balance.key
                || annualSubView === BUDGET_ANNUAL_SUBVIEWS.details.key)
                && { fontWeight }),
              color: getAmountColor(annualSubView, objMonthlyAmounts[BUDGET_ANNUAL_SUBVIEWS[annualSubView].amountKey], colorActual, colorBalance, false, objMonthlyAmounts.isToDate, false),
              ...((!showAmountInput || objMonthlyAmounts.isToDate) && { pr: 1 }),
            }}
            align="right"
            data-input-number={inputNumber}
          >
            {showAmountInput && !objMonthlyAmounts.isToDate
              ? renderBudgetAmountInputField(
                (evt) => handleBudgetItemAmountFocus(objMonthlyAmounts, evt),
                (evt) => handleBudgetItemAmountChange(arrMonthlyAmounts, setArrMonthlyAmounts, evt),
                () => handleBudgetItemAmountSave(budgetItem, objMonthlyAmounts),
                objMonthlyAmounts,
              ) : renderBudgetAmountText(
                annualSubView,
                objMonthlyAmounts,
                formatNumber(objMonthlyAmounts[BUDGET_ANNUAL_SUBVIEWS[annualSubView].amountKey], false, amountFormat),
                () => handleBudgetItemGraphClick(budgetItem.id, objMonthlyAmounts.startDate, isEverythingElse, displayLabel),
                isRollover,
                isQWinDataset,
                (evt: React.MouseEvent<HTMLElement>) => {
                  const targetElement = evt.target as HTMLElement;
                  setPopperAndContents(targetElement.parentElement, { displayLabel, rolloverType, objMonthlyAmounts, colorItemBalance, budgetItem, arrMonthlyAmounts, setArrMonthlyAmounts }, evt);
                },
                isSelected,
                showFutureMonthDetails,
                budgetEndMonth,
                budgetEndYear,
              )}
          </TableCell>
        );
      })}
      <TableCell
        sx={{
          border,
          p: 0,
          pr: 1,
          fontWeight,
          ...(summaryAmount < 0 && { color: colorBalance }),
          ...(isSelected && { backgroundColor: colorSelectedRow }),
        }}
        align="right"
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {hideBalanceViewFutureAmount(showFutureMonthDetails, {}, annualSubView, currentMonth, currentYear, budgetEndMonth, budgetEndYear, true)
          ? BUDGET_ANNUAL_INVISIBLE_AMOUNT_SYMBOL
          : formatNumber(summaryAmount, false, amountFormat)}
      </TableCell>
    </TableRow>
  );
};

export default AnnualViewCategoryRow;
