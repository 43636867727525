import React from 'react';

import StdDialogActions from 'components/Dialogs/StdDialogActions';
import StdDialogContent from 'components/Dialogs/StdDialogContent';
import Typography from '@mui/material/Typography';

type Props = {
  aggAction: any,
  fiBrandingIndent: number,
  onSubmit: any,
}

const AggActionIntro = ({ aggAction, fiBrandingIndent, onSubmit } : Props) => (
  <>
    <StdDialogContent flexDirection="column" ml={`${fiBrandingIndent}px`} useContainerFlexHeight>
      <Typography variant="subtitle1" sx={{ fontWeight: '500', lineHeight: '1.25' }}>
        {aggAction.text}
      </Typography>
      {aggAction.additionalInfo &&
        <Typography variant="body1" sx={{ mt: 2, lineHeight: '1.25', whiteSpace: 'pre-line' }}>
          {aggAction.additionalInfo}
        </Typography>}
      {(aggAction.statusCode || aggAction.code) &&
        <Typography variant={'caption'} sx={{ mt: 2 }}>
          Care code: {aggAction.statusCode || aggAction.code}
        </Typography>}
    </StdDialogContent>
    <StdDialogActions
      primaryLabel={aggAction.actionLabel}
      primaryOnClick={onSubmit}
    />
  </>
);

export default AggActionIntro;

