// @flow
import React from 'react';
import type { Node } from 'react';
import compose from 'utils/compose';
import { connect } from 'react-redux';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { withStyles } from 'tss-react/mui';

import QButton from 'components/QButton';
import { removeDialog } from 'data/rootUi/actions';
import StdDialog from 'components/Dialogs/StdDialog';
import { styles } from './styles';

type Props = {
  dialogProps: ?Object,
  dialogId: string,
  content: Node,
  onClose: () => void,
  buttonLabel: ?string,
  title: string,
  removeDialog: (Event) => void,
}

class NotificationDialog extends React.PureComponent<Props> {

  static defaultProps = {
    buttonLabel: 'OK',
  };

  handleClose = () => {
    this.props.removeDialog(this.props.dialogId);
    if (this.props.onClose) {
      this.props.onClose();
    }
    // this.props.closeDialog(event);
  };

  render() {
    const { buttonLabel, content, title, dialogProps } = this.props;
    const contentIsString = typeof content === 'string';

    return (
      <StdDialog
        open
        onClose={this.handleClose}
        showCloseButton={false}
        title={title}
        sharedcomponentid={'DIALOG_NOTIFICATION'}
        {...dialogProps}
      >
        <DialogContent>
          {contentIsString ?
            <DialogContentText id="alert-dialog-description">
              {this.props.content}
            </DialogContentText>
            :
            content}
        </DialogContent>
        {buttonLabel &&
          <DialogActions>
            <QButton
              id={`notification-${buttonLabel}`}
              variant="contained"
              onClick={this.handleClose}
            >
              {buttonLabel}
            </QButton>
          </DialogActions>}
      </StdDialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    removeDialog: (props) => dispatch(removeDialog(props)),
  };
}

export default compose(
  connect(null, mapDispatchToProps),
)(withStyles(NotificationDialog, styles, { name: 'AlertDialog' }));
