// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import { platform } from 'companion-app-components/utils/core';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

import { isPushNotificationsWaitingPermissions } from 'swPushNotifications';

import {
  shouldUserBePromptedToInstall,
  shouldUserBePromptedForPushNotifications,
  trackUse,
} from './PwaHelpers';
import InstallViaAddToHomeScreen from './InstallViaAddToHomeScreen';
import InstallViaInstallPrompt from './InstallViaInstallPrompt';
import PushNotificationPrompt from './PushNotificationsPrompt';

const renderInstallPrompt = () => {
  if (platform.isPwaInstalled || !(platform.supportsOnBeforeInstallPrompt || platform.supportsManualAddToHomeScreen)) {
    return null;
  }

  if (!shouldUserBePromptedToInstall()) {
    return null;
  }

  if (platform.supportsOnBeforeInstallPrompt) {
    return (<InstallViaInstallPrompt />);
  }

  if (platform.supportsManualAddToHomeScreen) {
    return (<InstallViaAddToHomeScreen />);
  }

  return null;
};

const renderPushNotificationsPrompt = () => {
  if (!platform.supportsPushNotifications
    || !isPushNotificationsWaitingPermissions()
    || !shouldUserBePromptedForPushNotifications()
  ) {
    return null;
  }

  return (<PushNotificationPrompt />);
};

const ShowPwaPrompts = () => {
  trackUse();

  const serviceWorkerEnableInstall = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'serviceWorkerEnableInstall'));
  const serviceWorkerEnablePushNotifications = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'serviceWorkerEnablePushNotifications'));

  return (
    <>
      {serviceWorkerEnableInstall && renderInstallPrompt()}
      {serviceWorkerEnablePushNotifications && renderPushNotificationsPrompt()}
    </>
  );
};

export default ShowPwaPrompts;
