import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ButtonBase from '@mui/material/ButtonBase';

import { tracker } from 'companion-app-components/utils/core';
import { authActions } from 'companion-app-components/flux/auth';
import QButton from 'components/QButton';
import * as syncSentimentActions from 'data/syncSentiment/syncSentimentActions';
import StdDialog from 'components/Dialogs/StdDialog';
import { removeDialog } from 'data/rootUi/actions';
import BadIcon from 'assets/sync-sentiments/bad.svg';
import PoorIcon from 'assets/sync-sentiments/poor.svg';
import AverageIcon from 'assets/sync-sentiments/average.svg';
import GoodIcon from 'assets/sync-sentiments/good.svg';
import ExcellentIcon from 'assets/sync-sentiments/excellent.svg';
import {
  DIALOG_TYPE_SYNC_SENTIMENTS_SURVEY,
  triggerSyncSentimentsThankYouDialog,
} from './actions';
import styles from './styles';
import {
  SYNC_SENTIMENTS_SURVEY_COMPLETED,
  SYNC_SENTIMENTS_SURVEY_ABANDONED,
  SYNC_SENTIMENTS_SURVEY_CANCELLED,
  SYNC_SENTIMENTS_SURVEY_BUTTON_SUBMIT,
} from './constants';

const arrSyncSentiments = [
  { score: 1, label: 'Bad', icon: BadIcon },
  { score: 2, label: 'Poor', icon: PoorIcon },
  { score: 3, label: 'Average', icon: AverageIcon },
  { score: 4, label: 'Good', icon: GoodIcon },
  { score: 5, label: 'Excellent', icon: ExcellentIcon },
];

const useStyles = makeStyles()(styles);

const SyncSentimentsSurveyDialog = ({ dialogId }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [userScore, setUserScore] = useState();
  const [userFeedback, setUserFeedback] = useState();
  const dispatchUpdateAuth = (data) => dispatch(authActions.updateAuth(data));
  const dispatchPostSyncSentimentSurveyResultAction = (data) => dispatch(syncSentimentActions.postSyncSentimentSurveyResultAction(data));
  const msgToShow = userScore > 3 ? 'What did you like?' : 'What can we improve on?';

  const handleClose = (reason = '') => {
    let surveyResult;
    dispatchUpdateAuth({ syncSurveyRequested: false }); // optimistic update
    if (reason === SYNC_SENTIMENTS_SURVEY_BUTTON_SUBMIT) {
      surveyResult = SYNC_SENTIMENTS_SURVEY_COMPLETED;
    } else if (!userScore) {
      surveyResult = SYNC_SENTIMENTS_SURVEY_ABANDONED;
    } else {
      surveyResult = SYNC_SENTIMENTS_SURVEY_CANCELLED;
    }
    dispatchPostSyncSentimentSurveyResultAction({ surveyResult });

    if (surveyResult === SYNC_SENTIMENTS_SURVEY_COMPLETED) {
      tracker.track(tracker.events.syncSentiment, {
        rating: userScore,
        feedback: userFeedback?.length > 0 ? userFeedback : undefined,
      });
      dispatch(triggerSyncSentimentsThankYouDialog());
    } else {
      tracker.track(tracker.events.syncSentiment, {
        status: surveyResult,
      });
    }
    dispatch(removeDialog(dialogId));
  };

  return (
    <StdDialog
      open
      onClose={handleClose}
      title="Share your feedback with Quicken"
      sharedcomponentid={DIALOG_TYPE_SYNC_SENTIMENTS_SURVEY}
    >
      <DialogContent>
        <Typography
          my={2}
          variant="body1"
          textAlign="center"
        >
          How would you rate the accuracy of the data you see on Quicken web app?
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="nowrap"
          my={2}
        >
          {arrSyncSentiments.map(({ score, label, icon }) => (
            <ButtonBase
              key={score}
              id={`sync-sentiments-score-${score}`}
              aria-label={score}
              onClick={() => setUserScore(score)}
              className={classes.boxFeedbackIcon}
              sx={({ components }) => ({
                ...(score === userScore && { bgcolor: components.syncSurvey.buttonFeedback }),
              })}
            >
              <img
                src={icon}
                alt={`Feedback Icon - ${label}`}
                width={32}
              />
              <Typography>{label}</Typography>
            </ButtonBase>
          ))}
        </Box>
        {userScore && (
          <TextField
            autoFocus
            id="sync-sentiments-feedback-text"
            label={msgToShow}
            aria-label={msgToShow}
            multiline
            value={userFeedback}
            onChange={(evt) => setUserFeedback(evt.target.value)}
            margin="normal"
            fullWidth
            inputProps={{ maxLength: 255 }}
            rows="4"
            variant="outlined"
          />
        )}
      </DialogContent>
      <DialogActions>
        {userScore && (
          <QButton
            id="sync-sentiments-submit-btn"
            aria-label="Submit Feedback"
            onClick={() => handleClose(SYNC_SENTIMENTS_SURVEY_BUTTON_SUBMIT)}
          >
            Submit
          </QButton>
        )}
        <QButton
          id="sync-sentiments-close-btn"
          aria-label="Close Feedback"
          onClick={handleClose}
        >
          Close
        </QButton>
      </DialogActions>
    </StdDialog>
  );
};

SyncSentimentsSurveyDialog.propTypes = {
  dialogId: PropTypes.string,
};

export default React.memo(SyncSentimentsSurveyDialog);
