// @flow
import { DateTime } from 'luxon';
import memoizeOne from 'memoize-one';
import { RRule } from 'rrule';
import numeral from 'numeral';

import { accountsTypes } from 'companion-app-components/flux/accounts';
import { scheduledTransactionsUtils, scheduledTransactionsTypes, RecurrenceType } from 'companion-app-components/flux/scheduled-transactions';
import { chartOfAccountsUtils, chartOfAccountsTypes } from 'companion-app-components/flux/chart-of-accounts';
import { transactionsUtils } from 'companion-app-components/flux/transactions';

import { getAccountById } from 'data/accounts/retrievers';

export const getCategoryString = (schTxn, categoriesById, accountsById) => {

  if (transactionsUtils.isSplitTxn(schTxn)) {
    return '--- Split ---';
  }

  const coa = schTxn && schTxn.coa;
  if (!coa) {
    return 'Not Found';
  }

  const category = {
    [chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED]: () => 'Uncategorized',
    [chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT]: () => chartOfAccountsUtils.getBalanceAdjustmentString(coa.id),
    [chartOfAccountsTypes.CoaTypeEnum.ACCOUNT]: () => {
      const acct = accountsById.get(coa.id);
      return (acct && acct.name) || 'ACCOUNT NOT SYNCED';
    },
    default: () => {
      const cat = categoriesById.get(coa.id);

      return cat && cat.name;
    },
  };

  return (category[coa.type] || category.default)() || 'Not Found';
};

// This getter will return a string with the next payment instance and date of the scheduled transaction
const getNextDateFormatted = (firstUpcomingAmount, dueOn) => {
  if (dueOn && firstUpcomingAmount) { // If date and amount exist
    return `Next: ${numeral(firstUpcomingAmount).format('$0,0.00')} on ${DateTime.fromISO(dueOn).toLocaleString(DateTime.DATE_MED)}`;
  }
  if (dueOn) return `Next: ${DateTime.fromISO(dueOn).toLocaleString(DateTime.DATE_MED)}`; // If only date exists
  if (firstUpcomingAmount) return `Next: ${numeral(firstUpcomingAmount).format('$0,0.00')}`; // If only amount exists
  return 'Next: Unknown'; // error case
};
export const userFriendlyTxnNames = memoizeOne((scheduledtxn, categoriesById, accountsById, firstUpcomingTxn) => {
  const accountInfo = scheduledtxn && scheduledtxn.transaction &&
    scheduledtxn.transaction.accountId && getAccountById(scheduledtxn.transaction.accountId);
  const accountTypeNode = scheduledtxn && scheduledtxn.transaction &&
    scheduledtxn.transaction.accountId && accountInfo &&
    accountsTypes.getAccountTypeNodeByQcsType(accountInfo.type, accountInfo.subType);
  const scheduleTxnType = scheduledtxn.type;
  const dueOn = (firstUpcomingTxn && firstUpcomingTxn.postedOn) || (scheduledtxn.dueOn);
  // used to show firstUpcomingTransaction.amount in Simplifi if it existed. Now show same across WF/Simplifi
  const amount = scheduledtxn.transaction && scheduledtxn.transaction.amount;
  const firstUpcomingAmount = firstUpcomingTxn ? Math.abs(firstUpcomingTxn.amount) : '';
  return {
    id: scheduledtxn && scheduledtxn.id,
    clientId: scheduledtxn.transaction && scheduledtxn.transaction.clientId,
    accountId: scheduledtxn.transaction && scheduledtxn.transaction.accountId,
    payeeName: (scheduledtxn.transaction && scheduledtxn.transaction.payee) || 'New Series',
    amount: amount || '',
    accountName: (accountInfo && accountInfo.name) || '[Account Not Synced]',
    frequency: scheduledTransactionsUtils.frequencyAliasFromRecurrence(scheduledtxn.recurrence) || 'Unknown',
    nextDate: dueOn && DateTime.fromISO(dueOn).toLocaleString(),
    nextDateFormatted: getNextDateFormatted(firstUpcomingAmount, dueOn),
    endDate:
      scheduledtxn.recurrence && scheduledtxn.recurrence.endOn &&
      DateTime.fromISO(scheduledtxn.recurrence.endOn).toLocaleString(),
    endDateFormatted:
      scheduledtxn.recurrence && scheduledtxn.recurrence.endOn &&
      DateTime.fromISO(scheduledtxn.recurrence.endOn).toLocaleString(DateTime.DATE_MED),
    subtype: scheduleTxnType === scheduledTransactionsTypes.STTypeEnum.TRANSFER
      ? 'Transfer from'
      : accountTypeNode && accountTypeNode.displayName,
    type: scheduleTxnType === scheduledTransactionsTypes.STTypeEnum.TRANSFER
      ? 'Transfer to'
      : scheduleTxnType,
    category: getCategoryString(scheduledtxn.transaction, categoriesById, accountsById),
  };
});

// THIS IS NOT COMPLETE, will need to add additional cases for various recurrence types
export const calcMontlyAverageForScheduledTxn = (schedTxn) => {

  let { amount } = schedTxn.transaction;
  switch (schedTxn.recurrence.frequency) {
    case 'WEEKLY':
      amount *= 4;
      break;
    case 'YEARLY':
      amount = amount ? amount / 12 : amount;
      break;
    case 'DAILY':
      amount *= 30;
      break;
    case 'MONTHLY':
      if (schedTxn.recurrence.byMonthDayOrLastDay && schedTxn.recurrence.byMonthDayOrLastDay.length > 1) {
        amount *= schedTxn.recurrence.byMonthDayOrLastDay.length;
      }
      break;

    default:
      break;
  }
  return amount / (schedTxn.recurrence.interval || 1);
};

const weekDaysMap = Object.freeze({
  MO: RRule.MO,
  TU: RRule.TU,
  WE: RRule.WE,
  TH: RRule.TH,
  FR: RRule.FR,
  SA: RRule.SA,
  SU: RRule.SU,
});

export const rruleFromRecurrence = (recurrence: RecurrenceType) => {
  if (!recurrence) {
    return null;
  }

  let freq;
  switch (recurrence.frequency) {
    case scheduledTransactionsTypes.FrequencyEnum.WEEKLY:
      freq = RRule.WEEKLY;
      break;
    case scheduledTransactionsTypes.FrequencyEnum.YEARLY:
      freq = RRule.YEARLY;
      break;
    case scheduledTransactionsTypes.FrequencyEnum.MONTHLY:
      freq = RRule.MONTHLY;
      break;
    case scheduledTransactionsTypes.FrequencyEnum.DAILY:
      freq = RRule.DAILY;
      break;
    default:
      assert(false, `unknown frequency = ${recurrence.frequency}`);
  }

  return {
    freq,
    interval: recurrence.interval,
    bymonth: recurrence.byMonth,
    bymonthday: recurrence.byMonthDay || recurrence.byMonthDayOrLastDay,
    byyearday: recurrence.byYearDay,
    byweekday: recurrence.byDay ? recurrence.byDay.map((weekDay) => weekDaysMap[weekDay]) : undefined,
  };
};
