/* eslint react/prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';

import { transactionsActions } from 'companion-app-components/flux/transactions';

import QDialogs from 'components/QDialogs';

import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import * as transactionSelectors from 'data/transactions/selectors';
import * as balancesSelectors from 'data/accountBalances/selectors';

import * as qdata from './core';

export default () => (WrappedComponent) => {

  class QData extends React.PureComponent {

    performTransactionAction = (action) => qdata.performTransactionAction(action, this.props);
    unMatchTransaction = (txn) => qdata.unMatchTransaction(txn, this.props);
    matchTransactions = (txns) => qdata.matchTransactions(txns, this.props);
    deleteTransactions = (txns) => qdata.deleteTransactions(txns, this.props);
    saveTransactions = (txns, cb, options) => qdata.saveTransactions(txns, cb, { ...this.props, ...options });
    setBalanceAdjust = (accountId, date, amount) => qdata.setBalanceAdjust(accountId, date, amount, this.props);

    render() {

      const qDataProps = {
        qDataSaveTransactions: this.saveTransactions,
        qDataDeleteTransactions: this.deleteTransactions,
        qDataMatchTransactions: this.matchTransactions,
        qDataUnMatchTransaction: this.unMatchTransaction,
        qDataPerformTransactionAction: this.performTransactionAction,
        qDataSetBalanceAdjust: this.setBalanceAdjust,
      };

      // wrapped component with its props, the state from HOC and uiStateProps
      return (
        <WrappedComponent
          {...{
            ...this.props,
            ...qDataProps,
          }}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      txnsByAccountId: transactionSelectors.getTransactionsByAccountId(state),
      balancesByAccountId: balancesSelectors.getBalancesByAccountId(state),
      featureFlags: featureFlagsSelectors.getFeatureFlags(state),
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      createTransaction: (payload, meta) => dispatch(transactionsActions.createTransaction(payload, meta)),
      deleteTransaction: (payload, meta) => dispatch(transactionsActions.deleteTransaction(payload, meta)),
      updateTransaction: (payload, meta) => dispatch(transactionsActions.updateTransaction(payload, meta)),
      batchTransactions: (payload, meta) => dispatch(transactionsActions.batchTransactions(payload, meta)),
      performTransactionAction: (payload, meta) => dispatch(transactionsActions.performTransactionAction(payload, meta)),
      getTransactions: () => dispatch(transactionsActions.getTransactions(undefined, { context: 'qdata' })),
      genericDispatch: (action) => dispatch(action),
    };
  }


  QData.propTypes = {
    createTransaction: PropTypes.func,
    updateTransaction: PropTypes.func,
    deleteTransaction: PropTypes.func,
    batchTransactions: PropTypes.func,
    getTransactions: PropTypes.func,
    performTransactionAction: PropTypes.func,
    txnsByAccountId: PropTypes.object,
    balancesByAccountId: PropTypes.object,

    // QDialog
    wrapperId: PropTypes.string,
  };

  // the HOC itself is wrapped in connect
  // return connect(mapStateToProps, mapDispatchToProps)(QData);

  return compose(
    QDialogs(),
    connect(mapStateToProps, mapDispatchToProps),
  )(QData);
};
