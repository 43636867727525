import React, { FC, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QDialogs from 'components/QDialogs';
import queryString from 'query-string';
import compose from 'utils/compose';
import { List as ListImmutable, Record as ImmutableRecord } from 'immutable';
import memoizeOne from 'memoize-one';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';

import { authSelectors } from 'companion-app-components/flux/auth';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { transactionsActions } from 'companion-app-components/flux/transactions';
import type RootState from 'companion-app-components/utils/redux-store/rootState';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import type { BudgetRecord } from 'companion-app-components/flux/budgets/budgetsTypes';
import * as budgetsSelectors from 'companion-app-components/flux/budgets/budgetsSelectors';
import { categoriesActions, categoriesSelectors } from 'companion-app-components/flux/categories';
import { getExcludedBudgetAccounts } from 'companion-app-components/flux/accounts/accountsSelectors';
import { budgetItemsSelectors, budgetItemsActions } from 'companion-app-components/flux/budget-items';
import { getBudgetsAction, createBudgetsAction, deleteBudgetsAction, callFullCalcBudgetAction } from 'companion-app-components/flux/budgets/budgetsActions';
import * as preferencesSelectors from 'companion-app-components/flux/preferences/selectors';
import {
  getPreferences as getPreferencesAction,
  setPreference as setPreferenceAction,
} from 'companion-app-components/flux/preferences/actions';
import { getCategoryGroupsAction } from 'companion-app-components/flux/category-groups/categoryGroupsActions';
import * as categoryGroupsSelectors from 'companion-app-components/flux/category-groups/categoryGroupsSelectors';
import { getCategoryGroupListsAction } from 'companion-app-components/flux/category-group-lists/categoryGroupListsActions';
import * as categoryGroupListsSelectors from 'companion-app-components/flux/category-group-lists/categoryGroupListsSelectors';

import { isBankOwnedPending } from 'data/transactions/utils';
import { getTransactionsByFilterFn } from 'data/transactions/selectors';

import UIState from 'components/UIState';
import BudgetsV2 from './BudgetsV2';
import type { BudgetUiStateType } from './types';

const transactionFilterFnGUID = uuidv4();

const transactionFilterFn = (transaction, props) => {
  let keepTransaction = !(transaction.source === 'SCHEDULED_TRANSACTION_PENDING');
  keepTransaction = (keepTransaction && props?.excludedAccounts?.size > 0) ? !props.excludedAccounts.includes(transaction.accountId) : keepTransaction;
  keepTransaction = (keepTransaction && props.accountIds && props.accountIds.size > 0) ? props.accountIds.has(transaction.accountId) : keepTransaction;
  const postedOnDate = DateTime.fromISO(transaction.postedOn).toJSDate();
  keepTransaction = (keepTransaction && props.startDate) ? postedOnDate >= props.startDate : keepTransaction;
  keepTransaction = (keepTransaction && props.endDate) ? postedOnDate <= props.endDate : keepTransaction;
  keepTransaction = (keepTransaction && props.coas && props.coas.size > 0) ? Boolean(
    (transaction.coa && props.coas.find((coa) => coa.type === transaction.coa.type && coa.id === transaction.coa.id
      && (transaction.coa.type !== 'ACCOUNT' || !props.negator || Math.sign(transaction.amount) === props.negator))) // special casing for transfers - drop one side of a transfer
    ||
    (transaction.split && transaction.split.items &&
      transaction.split.items.find((splitItem) => props.coas.find((coa) => coa.type === splitItem.coa.type && coa.id === splitItem.coa.id
        && (splitItem.coa.type !== 'ACCOUNT' || !props.negator || Math.sign(splitItem.amount) === props.negator))) // special casing for transfers - drop one side of a transfer
    ),
  ) : false; // keepTransaction;
  return keepTransaction;
};

const transactionFilterPropsCached = memoizeOne((startDate, endDate, coas, accountIds, negator, excludedAccounts) => ({
  startDate,
  endDate,
  coas,
  accountIds,
  negator,
  filterFn: transactionFilterFn,
  filterFnGUID: transactionFilterFnGUID,
  excludedAccounts,
}));
interface BudgetWrapperProps {
  uiState: BudgetUiStateType;
  setUIState: (uiState: BudgetUiStateType) => void;
  dialogAlert: (title: string, description: string, cb: (e: Record<string, any>) => void, btns: string[], type: string, icon?: boolean) => void;
}

const BudgetWrapper: FC<BudgetWrapperProps> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const query = location.search ? queryString.parse(location.search) : {};
  const queryBudgetId = query?.displayBudget ? query.displayBudget : null;
  const queryBudgetNodeKey = query?.displayNode ? query.displayNode : null;

  const preferences = useSelector((state: RootState) => preferencesSelectors.getPreferences(state));
  const sharedDatasetPreferences = preferences && preferences.shared.dataset;
  const lastViewedBudgetId = sharedDatasetPreferences && sharedDatasetPreferences.budgetOptions && sharedDatasetPreferences.budgetOptions.lastViewedBudgetId;
  const budgets = useSelector((state: RootState) => budgetsSelectors.getBudgets(state));

  let budget = queryBudgetId && budgets.get(queryBudgetId as string) as BudgetRecord;
  budget = budget || budgets.get(lastViewedBudgetId) as BudgetRecord;
  budget = budget || (budgets && budgets.first()) as BudgetRecord;

  const budgetTreeNodes = useSelector((state: RootState) => {
    if (!budget || !props.uiState || !props.uiState.startDate || !props.uiState.endDate) {
      return null;
    }
    return budgetsSelectors.getBudgetTreeNodes(state, {
      budget,
      startDate: props.uiState.startDate as unknown as DateTime,
      endDate: props.uiState.endDate as unknown as DateTime,
    });
  });

  const navigationPath = budgetTreeNodes && queryBudgetNodeKey && budgetsSelectors.pathToNodeWithKey(budgetTreeNodes, queryBudgetNodeKey as string);
  const budgetTreeNode = navigationPath && navigationPath.length > 0 && navigationPath[navigationPath.length - 1];

  const coas = budgetTreeNode ? budgetTreeNode.coas : budgetTreeNodes && budgetsSelectors.coasForNodes(budgetTreeNodes);
  const accountIds = budget && budget.filterAccountIds && budget.filterAccountIds.map((account) => account.id);
  const allAccounts = useSelector((state: RootState) => accountIds ? accountsSelectors.getAccountsById(state) : null);
  const excludedAccounts = useSelector((state: RootState) => accountIds ? getExcludedBudgetAccounts(state).map((account) => account.id) : null);

  const budgetAccounts = accountIds && allAccounts && allAccounts.filter((account) => accountIds.includes(account.id) && account.currency === budget?.currency);
  const budgetAccountIds = (budgetAccounts && budgetAccounts.map((account) => account.id)) || ListImmutable([]);
  const negator = (budgetTreeNode && budgetTreeNode.negate) || undefined;

  let transactions = useSelector((state: RootState) => {
    if (!(props.uiState && props.uiState.startDate && props.uiState.endDate && coas && budgetAccountIds)) {
      return null;
    }
    return getTransactionsByFilterFn(
      state,
      transactionFilterPropsCached(
        props.uiState.startDate,
        props.uiState.endDate,
        coas,
        budgetAccountIds,
        negator,
        excludedAccounts,
      ),
    );
  });
  
  transactions = transactions && transactions.filter((txn) => !isBankOwnedPending(txn));

  const loadPendingBudgets = useSelector((state: RootState) => budgetsSelectors.getLoadPending(state));
  const loadPendingCategories = useSelector((state: RootState) => categoriesSelectors.getLoadPending(state));
  const loadPendingBudgetItems = useSelector((state: RootState) => budgetItemsSelectors.getLoadPending(state));
  const loadPendingCategoryGroups = useSelector((state: RootState) => categoryGroupsSelectors.getLoadPending(state));
  const loadPendingCategoryGroupLists = useSelector((state: RootState) => categoryGroupListsSelectors.getLoadPending(state));
  
  const isLoadingBudgets = useSelector((state: RootState) => budgetsSelectors.getIsLoading(state));
  const isLoadingCategories = useSelector((state: RootState) => categoriesSelectors.getIsLoading(state));
  const isLoadingBudgetItems = useSelector((state: RootState) => budgetItemsSelectors.getIsLoading(state));
  const isLoadingCategoryGroups = useSelector((state: RootState) => categoryGroupsSelectors.getIsLoading(state));
  const isLoadingCategoryGroupLists = useSelector((state: RootState) => categoryGroupListsSelectors.getIsLoading(state));

  const selectorStates = {
    budget,
    budgets,
    preferences,
    transactions,
    navigationPath,
    budgetTreeNode,
    budgetTreeNodes,
    budgetAccountIds,
    dashboardRef: query?.ref ? query.ref : null,
    budgetItems: useSelector((state: RootState) => budgetItemsSelectors.getBudgetItems(state)),
    isWebFirstDataset: useSelector((state: RootState) => authSelectors.getIsWebfirstDataset(state)),
    categoryGroups: useSelector((state: RootState) => categoryGroupsSelectors.getCategoryGroupsById(state)),
    hasReportableAccounts: useSelector((state: RootState) => accountsSelectors.getHasReportableAccounts(state)),
    budgetsEnabled: useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state).get('budgets')),
    categoryGroupLists: useSelector((state: RootState) => categoryGroupListsSelectors.getCategoryGroupLists(state)),
    budgetsCreation: useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state).get('budgetsCreation')),
    isLoading: Boolean(isLoadingBudgets || isLoadingBudgetItems || isLoadingCategoryGroups || isLoadingCategoryGroupLists || isLoadingCategories),
    loadPending: Boolean(loadPendingBudgets || loadPendingBudgetItems || loadPendingCategoryGroups || loadPendingCategoryGroupLists || loadPendingCategories),
  };

  const dispatchActions = {
    dispatchGetBudgetsAction: () => dispatch(getBudgetsAction()),
    dispatchGetCategoryGroupsAction: () => dispatch(getCategoryGroupsAction()),
    dispatchDeleteBudgetAction: (data) => dispatch(deleteBudgetsAction([data])),
    dispatchGetCategoriesAction: () => dispatch(categoriesActions.getCategories()),
    dispatchGetCategoryGroupListsAction: () => dispatch(getCategoryGroupListsAction()),
    dispatchBudgetFullCalcCall: (budgetId) => dispatch(callFullCalcBudgetAction(budgetId)),
    dispatchGetBudgetItemsAction: () => dispatch(budgetItemsActions.getBudgetItemsAction()),
    dispatchSetSharedWebAppDatasetPreferenceAction: (objPreference) => dispatch(setPreferenceAction({
      section: 'shared',
      group: 'dataset',
      preference: { webApp: objPreference },
    }, { context: 'QPreferences:setDatasetPreference' })),
    dispatchGetPreferencesAction: () => dispatch(getPreferencesAction({ section: 'shared', group: 'dataset' })),
    dispatchGetUserPreferencesAction: () => dispatch(getPreferencesAction({ section: 'shared', group: 'user' })),
    dispatchGetTransactionsAction: () => dispatch(transactionsActions.getTransactions(undefined, { context: 'budgets' })),
    dispatchCreateBudgetAction: (data) => dispatch(createBudgetsAction([data], { undo: { userMessage: 'New budget created.' } })),
    dispatchSetUserPreferenceAction: (preference) => dispatch(setPreferenceAction({ section: 'shared', group: 'user', preference })),
    dispatchSetSharedDatasetPreferenceAction: (preference) => dispatch(setPreferenceAction({ section: 'shared', group: 'dataset', preference })),
    dispatchCreateBudgetItemsAction: (data) => dispatch(budgetItemsActions.createBudgetItemsAction(data, { undo: { userMessage: 'Budget items created' } })),
  };

  const propsToPass = {
    ...props,
    ...selectorStates,
    ...dispatchActions,
  };

  return <BudgetsV2 {...propsToPass} />;
};

const BudgetsUIState = ImmutableRecord({
  startDate: null,
  endDate: null,
  selectedAnnualBudgetItem: null,
  selectedMonthDate: null,
});

const uiStateConfig = {
  name: 'Budgets',
  state: () => new BudgetsUIState(),
  persist: false,
};

export default compose(
  UIState(uiStateConfig),
  QDialogs(),
)(memo(BudgetWrapper));
