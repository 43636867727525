import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';

import { accountsSelectors } from 'companion-app-components/flux/accounts';

import * as QC from './core';

export default () => (WrappedComponent) => {

  class QCurrency extends React.PureComponent {

    getObjectSharedCurrency = (...args) => QC.getObjectSharedCurrency(...args);
    getAccountsSharedCurrency = (...args) => QC.getAccountsSharedCurrency(...args, this.props.accounts);
    getSharedCurrency = (...args) => QC.getSharedCurrency(...args, this.props.accounts);
    getAccountIdsCurrency = (...args) => QC.getAccountIdsCurrency(...args, this.props.accounts);
    getTxnCurrency = (...args) => this.getAccountIdsCurrency(...args);
    formatAmount = (...args) => QC.formatAmount(...args);
    formatTxnAmount = (...args) => QC.formatTxnAmount(...args);

    render() {

      const qCurrencyProps = {
        // (value, currencySymbol, omitCents) - currencySymbol is TLA ('USD', 'INR')
        formatAmount: this.formatAmount,

        // (txnsArray)
        // takes an array of lists, if you only have 1 list pass it as [txnList]
        getSharedCurrency: this.getSharedCurrency,

        // (txn) - returns TLA currency for this transaction
        getTxnCurrency: this.getTxnCurrency,

        // (txn, omitCents) - returns the formatted amount string
        formatTxnAmount: this.formatTxnAmount,

        // takes an array of objects, of which each has a 'currency' property
        getObjectSharedCurrency: this.getObjectSharedCurrency,

        // takes an array of accountIds, and returns the shared currency or none
        getAccountsSharedCurrency: this.getAccountsSharedCurrency,
      };

      // wrapped component with its props, the state from HOC and uiStateProps
      return (
        <WrappedComponent
          {...{
            ...this.props,
            ...qCurrencyProps,
          }}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      accounts: accountsSelectors.getAccountsById(state),
    };
  }

  function mapDispatchToProps() {
    return {};
  }


  QCurrency.propTypes = {
    formatNumber: PropTypes.func,
    getSharedCurrency: PropTypes.func,
    accounts: PropTypes.object,
  };

  // the HOC itself is wrapped in connect
  // return connect(mapStateToProps, mapDispatchToProps)(QData);

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
  )(QCurrency);
};
