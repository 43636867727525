// @flow
import * as React from 'react';

import MuiTextField from '@mui/material/TextField';
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { getIn } from 'formik';

import type { TextFieldProps } from './types';

export const fieldToTextField =
  ({
    field,
    form,
    disabled = false,
    ...props
  }: TextFieldProps): MuiTextFieldProps => {
    const { name, value } = field;
    const { touched, errors, isSubmitting } = form;

    const fieldError = getIn(errors, name);
    const showError = getIn(touched, name) && !!fieldError;

    return {
      ...props,
      ...field,
      error: showError,
      helperText: (showError ? fieldError : props.helperText),
      disabled: isSubmitting || disabled,
      value: value || '',
    };
  };

const handleBlur = (onBlur, onFieldBlur) => (e) => {
  onBlur(e);

  if (onFieldBlur) {
    onFieldBlur(e, e.target.value);
  }
};

const handleChange = (onChange, onFieldChange) => (e) => {
  onChange?.(e);

  onFieldChange?.(e, e.target.value);
};

const FormikTextField: React.ComponentType<TextFieldProps> = ({ children, ...props }: TextFieldProps) => {
  const { onBlur, onChange, onFieldBlur, onFieldChange, ...textFieldProps } = fieldToTextField(props);

  return (
    <MuiTextField
      onBlur={handleBlur(onBlur, onFieldBlur)}
      onChange={handleChange(onChange, onFieldChange)}
      {...textFieldProps}
    >
      {children}
    </MuiTextField>
  );
};

FormikTextField.displayName = 'FormikTextField';

export default FormikTextField;
