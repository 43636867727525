import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { TextFieldProps } from '@mui/material';

import { exists } from 'utils/utils';
import MaterialComboBox from './MaterialComboBox';
import { Payee } from '../types';

const displayTemplate = (componentProps, item, props, inputValue, theme) => {
  const { dataSelected, ...other } = props;
  const { menuShowFields } = componentProps;

  const showCat = (menuShowFields || '').includes('category');
  const showAmount = (menuShowFields || '').includes('amount');
  const showPayee = (menuShowFields || 'payee').includes('payee');
  const showTags = (menuShowFields || '').includes('tags');
  const showMemo = (menuShowFields || '').includes('memo');

  // highlight the inputValue
  let str = item.name;
  let strJsx: null | ReactElement = null;

  if (str) {
    const start = str.indexOf(inputValue);
    if (start >= 0) {
      const strTip = str.slice(0, start);
      const strMid = str.substr(start, inputValue.length);
      const strEnd = str.slice(start + inputValue.length);
      strJsx = (
        <div>
          {strTip}<b>{strMid}</b>{strEnd}
        </div>
      );
    } else {
      strJsx = (
        <div>
          {str}
        </div>
      );
    }

    const miniStyle = {
      fontSize: '11px',
      lineHeight: '11px',
      color: theme.components.payeeField.displayTemplate,
    };
    str = (
      <>
        {showPayee ? strJsx : null}
        {showAmount &&
          <div style={miniStyle}>
            {`Amt: ${item.amountString}`}
          </div>}
        {showCat &&
          <div style={miniStyle}>
            {`Cat: ${item.catString}`}
          </div>}
        {showMemo && item.txn &&
          <div style={miniStyle}>
            {`Memo: ${item.txn.memo}`}
          </div>}
        {showTags && item.tagString && item.tagString.length > 0 &&
          <div style={miniStyle}>
            {`Tags: ${item.tagString}`}
          </div>}
      </>
    );
  }

  return (
    <ListItem
      button
      {...other}
      style={{
        // gets rid of highlight in text box
        // backgroundColor: stateColor,
        textAlign: 'left',
      }}
      key={`autocomplete-item-${item.id}`}
      dense
    >
      <ListItemText
        style={{ fontWeight: dataSelected ? 600 : 'inherit' }}
        primary={str}
      />
    </ListItem>
  );
};

type FilteredInputStateType = {
  isOpen: boolean;
  currSel: null | number;
  listItems: null | Record<string, any>;
};

interface FilteredInputProps {
  data: Payee[];
  onChange: (e: React.MouseEvent<HTMLElement> | Record<string, any>) => void;
  onBlur?: () => void;
  value: string;
  name: string;
  disableUnderline?: boolean;
  menuPosition?: string;
  inputRef?: (x: HTMLInputElement) => void;
  fontSize?: string;
  onChangeInput: (e: any) => void;
  menuShowFields: string; // eslint-disable-line
  label?: string;
  placeholder?: string;
  width: string;
  textFieldProps?: TextFieldProps;
  textFieldVariant?: string;
  marginProp?: string;
  autoFocus?: boolean; // eslint-disable-line
}

const FilteredInput: React.FC<FilteredInputProps> = (props) => {
  const theme = useTheme();
  const { data, name, disableUnderline, menuPosition, fontSize, onChangeInput, onBlur, placeholder, label,
    textFieldProps, textFieldVariant, marginProp } = props;

  const [filteredInputState, setFilteredInputState] = useState<FilteredInputStateType>({
    isOpen: false,
    currSel: null,
    listItems: null,
  });
  const prevPropsValue = usePrevious(props.value);
  const currentValueRef = useRef<string>('');

  const onListChange = (changedListItems) => {
    filteredInputState.listItems = changedListItems;
  };

  const handleKeydown = (event: KeyboardEvent) => {
    switch (event.key) {
      // Enter key is for the list to drive a selection of a highlighted item, however, if there is no highlighted
      // item, then Enter should accept the current input (do not stop propogation)
      case 'Enter':
        if (filteredInputState.isOpen && exists(filteredInputState.currSel) && filteredInputState.listItems) {
          event.stopPropagation();
        }
        break;

      case 'Escape':
        if (filteredInputState.isOpen) {
          event.stopPropagation();
        }
        break;

      case 'Tab':
        if (filteredInputState.isOpen) {
          if (props.onChange && exists(filteredInputState.currSel) && filteredInputState.listItems) {
            props.onChange(filteredInputState.listItems.get(filteredInputState.currSel));
          }
        } else if (currentValueRef.current) {
          props.onChange({ action: 'Tab', name: currentValueRef.current });
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (props.value !== prevPropsValue) {
      currentValueRef.current = props.value;
    }
  }, [props.value, prevPropsValue]);

  return (
    <Box
      component="div"
      sx={{ flex: 2, overflow: 'hidden' }}
      // @ts-expect-error - FIXME: some event type issue
      onKeyDown={handleKeydown}
    >
      <div>
        <MaterialComboBox
          // this is used for setting the name property of the input field
          name={name}
          // This is the list of data that is databound to the combobox
          items={data}
          inputRef={props.inputRef}
          value={props.value}
          disableUnderline={disableUnderline}
          menuPosition={menuPosition}
          textFieldProps={textFieldProps}
          // This is a template that can be overridden for each list item
          displayTemplate={(item: string, filteredInputProps: FilteredInputProps, inputValue: string) =>
            displayTemplate(props, item, filteredInputProps, inputValue, theme)}
          // This is placeholder text when the input has focus
          placeholder={placeholder || 'Payee Name'}
          label={label}
          fontSize={fontSize}
          textFieldVariant={textFieldVariant}
          marginProp={marginProp}
          // This affects the search behavior
          // Maybe what you search is different from just
          // one single prop.
          // so this is how one might search across many properties
          searchTemplate={(item) => `${item.name}`}
          // Currently a noop, but you can fire an event
          // as a side effect each time the input changes
          onChangeInput={(e) => {
            // @ts-expect-error TS(2339) - FIXME: Property 'value' does not exist on type 'EventTarget'
            currentValueRef.current = e.target.value;
            onChangeInput(e);
          }}
          onBlur={onBlur}
          // This controls what you see in the actual input
          // when an item is selected
          inputDisplayTemplate={(selectedItem) => `${selectedItem ? selectedItem.name : ''}`}
          onListChange={onListChange}
          initialHighlightedIndex={0}
          // This event fires every time there are state changes inside the Combobox
          // @ts-expect-error - FIXME: type issue with DownShift when adding this as a prop type in MaterialCombobox.
          onStateChange={({
            highlightedIndex,
            isOpen,
          }) => {
            // NOTE, material combobox does not accurately tell us when the list
            // closes if it closes due to there being no matches.  So we proxy that here
            // by setting isOpen to reflect whether the current list being rendered has any
            // items at all (in the cases where isOpen is undefined
            //
            if (isOpen !== undefined) {
              setFilteredInputState((prevState) => ({
                ...prevState,
                isOpen: Boolean(isOpen),
              }));
            } else {
              setFilteredInputState((prevState) => ({
                ...prevState,
                isOpen: Boolean(filteredInputState.listItems && filteredInputState.listItems.size > 0),
              }));
            }
            setFilteredInputState((prevState) => ({
              ...prevState,
              currSel: highlightedIndex,
            }));
          }}
          // This (very important) event fires everytime a new item in the combobox
          // Is selected
          onChange={(item) => {
            if (item && props.onChange) {
              props.onChange(item);
            }
          }}
          width={props.width}
        />
      </div>
    </Box>
  );
};

export default FilteredInput;
