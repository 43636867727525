import { isAcme } from 'isAcme';

export const styles = (_theme) => ({
  root: {
    padding: 0,
  },
  switchBox: {
    display: 'flex',
    textAlign: 'center',
  },
  labelContainer: {
    flex: 1,
  },
  labelText: {
    flex: 1,
    margin: 'auto',
    textAlign: 'left',
  },
  clearText: {
    paddingRight: 12,
    paddingLeft: 12,
  },
  radioGroup: {
    background: isAcme ? _theme.palette.background.paper : 'white',
    padding: 5,
    borderRadius: '0 0 10px 10px',
  },
  radioItemContainer: {
    display: 'flex',
    textAlign: 'center',
  },
  radioItem: {
    flex: 0,
    minWidth: 40,
    padding: 3,
  },

});
