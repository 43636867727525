const daysPerRow = 7;

export const transactionCalendarStyles = (theme) => ({
  container: {
    padding: theme.spacing(0, 3, 2),
  },
  fullScreen: {
    paddingLeft: theme.components.register.paddingLeft,
    paddingRight: theme.components.register.paddingRight,
  },
});

export const monthlyCalendarStyles = ({ typography, spacing, components: { transactionCalendar } }) => {
  const legend = {
    ...typography.caption,
    height: 20,
    padding: spacing(0, 1.2),
    marginRight: spacing(1),
  };
  return ({
    root: {
      maxWidth: 1134,
      minWidth: 1134,
      margin: '0 auto',
    },
    legendsRoot: {
      display: 'flex',
      marginBottom: spacing(1),
    },
    legend,
    income: {
      ...legend,
      backgroundColor: transactionCalendar.incomeColor,
    },
    expense: {
      ...legend,
      backgroundColor: transactionCalendar.expenseColor,
    },
    overdue: {
      ...legend,
      backgroundColor: transactionCalendar.overdueColor,
    },
    normal: {
      ...legend,
      backgroundColor: transactionCalendar.normalColor,
    },
  });
};

export const calTopHeaderStyles = (theme) => ({
  monthHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginTop: 20,
  },
  controlGroup: {
    display: 'flex',
    border: `1px ${theme.palette.grey.level3} solid`,
    borderRadius: theme.shape.borderRadius,
    '&$controlRoot': {
      borderRight: `1px ${theme.palette.grey.level3} solid`,
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
  controlRoot: {
    borderRight: `1px ${theme.palette.grey.level3} solid`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  iconControl: {
    width: 45,
    height: '100%',
    color: theme.palette.text.secondary,
  },
  iconControlDisabled: {
    color: `${theme.palette.text.disabled} !important`,
  },
  iconToday: {
    transform: 'rotate(45deg)',
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },
  monthPickerRoot: {
    width: 145,
  },
  yearPickerRoot: {
    width: 100,
  },
  inputRoot: {
    ...theme.typography.h6,
    textAlign: 'center',
    width: '100%',
  },
  settingsRoot: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    display: 'flex',
  },
  settingsIconRoot: {
    padding: 12,
    borderRadius: '50%',
  },
  settingsIcon: {
    color: theme.palette.action.active,
  },
  button: {
    backgroundColor: theme.components.transactionCalendar.resetDefaultColor,
    boxShadow: 'none',
    marginRight: 10,
    '&:hover': {
      background: theme.components.transactionCalendar.resetDefaultColor,
    },
  },
});

export const calWeekDaysHeaderStyles = ({ spacing, palette, typography }) => ({
  weekDays: {
    ...typography.subtitle2,
    display: 'flex',
    background: palette.grey[200],
    border: `solid 1px ${palette.divider}`,
    borderLeft: 'none',
  },
  dayLabel: {
    flex: `1 0 ${100 / daysPerRow}%`,
    maxWidth: `calc(100% / ${daysPerRow})`,
    textIndent: spacing(1),
    borderLeft: `solid 1px ${palette.divider}`,
    padding: '5px 0',
  },
});

export const calDaysGridStyles = ({ typography, palette, spacing }) => ({
  days: {
    background: palette.common.white,
    display: 'flex',
    flexWrap: 'wrap',
    borderRight: `solid 1px ${palette.divider}`,
    marginBottom: 4,
    transform: 'translateZ(0)',
    position: 'relative',
  },
  popperRoot: {
    width: 300,
  },
  footnote: {
    ...typography.caption,
    marginTop: spacing(2),
  },
  tabs: {
    marginBottom: 10,
  },
  customIndicator: {
    bottom: 0,
  },
});

export const calDayStyles = ({ palette, typography, spacing, components: { transactionCalendar } }) => ({
  day: {
    height: 125,
    position: 'relative',
    flex: `1 0 ${100 / daysPerRow}%`,
    maxWidth: `calc(100% / ${daysPerRow})`,
    minHeight: 100,
    borderLeft: `solid 1px ${palette.divider}`,
    borderBottom: `solid 1px ${palette.divider}`,
    cursor: 'pointer',
    '&:hover': {
      outline: `1px solid ${palette.color1.opacity100}`,
      outlineOffset: -1,
    },
  },
  dayHolder: {
    height: '100%',
  },
  notCurrentMonth: {
    background: transactionCalendar.notCurrentMonth,
  },
  header: {
    display: 'inline-block',
    paddingTop: 2,
    marginBottom: 2,
  },
  dayIndicator: {
    ...typography.subtitle1,
    display: 'inline-block',
    borderRadius: '50%',
    height: 30,
    width: 30,
    textAlign: 'center',
    lineHeight: '30px',
  },
  currentDay: {
    background: transactionCalendar.currentDay,
    color: palette.common.white,
    marginLeft: 2,
  },
  txnWrapper: {
    overflow: 'hidden',
    height: 'calc(100% - 34px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  popperHeader: {
    ...typography.h6,
  },
  popperTxnEmptyList: {
    height: 181,
  },
  popperTxnList: {
    height: 150,
    overflowY: 'auto',
  },
  balanceLabelRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 2,
    ...typography.caption,
    '& strong': {
      fontWeight: typography.fontWeightBold,
    },
  },
  balanceDivider: {
    margin: spacing(0.5, 0),
  },
  balanceAccountLabel: {
    flex: '1 0 55%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  balanceAmountLabel: {
    flex: '1 0 45%',
    textAlign: 'right',
  },
  positiveBalance: {
    color: transactionCalendar.positiveBalance,
  },
  negativeBalance: {
    color: `${transactionCalendar.negativeBalance} !important`,
  },
  dailyBalanceLabel: {
    ...typography.caption,
    height: 20,
    textAlign: 'right',
    padding: '0px 6px',
    cursor: 'pointer',
  },
  dailyBalanceLabelFloating: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

export const transactionLabelStyles = ({ palette, typography, components: { transactionCalendar } }, _props, classes) => ({
  txnLabel: {
    ...typography.caption,
    width: '100%',
    height: 20,
    marginBottom: 1,
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 6px',
    [`& .${classes.reminderIcon}`]: {
      display: 'none',
    },
  },
  payeeLabel: {
    flex: '1 0 55%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    paddingTop: 1,
  },
  amountLabel: {
    flex: '1 0 45%',
    textAlign: 'right',
  },

  // type styles
  moreType: {
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  incomeType: {
    background: transactionCalendar.incomeColor,
    '&:hover': {
      background: transactionCalendar.incomeColor,
    },
  },
  expenseType: {
    background: transactionCalendar.expenseColor,
    '&:hover': {
      background: transactionCalendar.expenseColor,
    },
  },
  overdueType: {
    [`& .${classes.reminderIcon}`]: {
      display: 'inline-block',
      color: palette.other.overdueTxn,
    },
    background: `${transactionCalendar.overdueColor} !important`,
    '&:hover': {
      background: transactionCalendar.overdueColor,
    },
  },
  normalType: {
    background: transactionCalendar.normalColor,
    '&:hover': {
      background: transactionCalendar.normalColor,
    },
  },
  dueType: {
    [`& .${classes.reminderIcon}`]: {
      display: 'inline-block',
      // color: palette.other.dueTxn,
      color: palette.greyScaleDeprecated[3],
      opacity: 0.90,
    },
  },
  reminderIcon: {
    fontSize: 13,
    marginRight: 4,
  },
});

export const calendarPrefsStyles = ({ typography, spacing }) => ({
  dialogPaper: {
    overflowY: 'visible',
  },
  buttonArea: {
    padding: 10,
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  fieldRoot: {
    display: 'flex',
    minWidth: 450,
  },
  fieldLabel: {
    ...typography.body2,
    flexGrow: 2,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  exceedAmountRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  exceedAmountField: {
    width: 85,
    marginLeft: spacing(1),
  },
});

export const calendarTxnModalStyles = ({ palette, typography, spacing }) => {
  const cellContainer = {
    padding: spacing(1),
  };
  return ({
    modalTitle: {
      textAlign: 'left',
    },
    buttonArea: {
      padding: 10,
      paddingTop: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      '& button + button': {
        marginLeft: 15,
      },
    },
    tableContainer: {
      maxHeight: 300,
      padding: '0 25px',
      position: 'relative',
    },
    footerTable: {
      position: 'sticky',
      bottom: 0,
      left: 0,
      zIndex: '2',
      background: palette.common.white,
    },
    tableBodyCell: {}, // Do not delete
    tableBodyRow: {
      '&:hover $tableBodyCell': {
        backgroundColor: palette.grey['50'],
      },
    },
    resetCell: {
      background: `${palette.common.white} !important`,
    },
    cellContainer,
    reminderColContainer: {
      ...cellContainer,
      textAlign: 'center',
    },
    payeeColContainer: {
      ...cellContainer,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    accountColContainer: {
      ...cellContainer,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    categoryColContainer: {
      ...cellContainer,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    amountColContainer: {
      ...cellContainer,
    },
    optionsColContainer: {
      ...cellContainer,
    },
    reminderCol: {
      width: 40,
      maxWidth: 40,
    },
    payeeCol: {
      width: 240,
      maxWidth: 240,
    },
    accountCol: {
      width: 200,
      maxWidth: 200,
    },
    categoryCol: {
      width: 150,
      maxWidth: 150,
    },
    amountCol: {
      width: 150,
      maxWidth: 150,
    },
    optionsCol: {
      width: 56,
      maxWidth: 56,
    },
    reminderIcon: {},
    expandIcon: {
      color: palette.text.primary,
    },
    expandIconRoot: {
      padding: 8,
      borderRadius: '50%',
    },
    notDue: {
      color: palette.greyScaleDeprecated[3],
    },
    dueType: {
      color: palette.other.dueTxn,
    },
    overdueType: {
      color: palette.other.overdueTxn,
    },
    totalLabelCell: {
      width: 590,
      maxWidth: 590,
      fontWeight: typography.fontWeightBold,
    },
    totalAmountCell: {
      width: 206,
      maxWidth: 206,
      fontWeight: typography.fontWeightBold,
    },
  });
};

export const calendarAccountsModalStyles = ({ palette, spacing }) => {
  const cellContainer = {
    padding: spacing(1),
  };
  return ({
    dialogPaper: {
      overflowY: 'visible',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 24px 0px 24px',
    },
    resetButton: {
      margin: 10,
      padding: 10,
    },
    buttonArea: {
      padding: 10,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    tableContainer: {
      maxHeight: 300,
      padding: '0 25px',
    },
    resetCell: {
      background: `${palette.common.white} !important`,
    },
    cellContainer,
    tableBodyCell: {}, // Do not delete
    tableBodyRow: {
      cursor: 'pointer',
      '&:hover $tableBodyCell': {
        backgroundColor: palette.grey['50'],
      },
    },
    checkBoxColContainer: {
      width: 40,
    },
    accountColContainer: {
      width: 240,
    },
    typeColContainer: {
      width: 130,
    },
    descriptionColContainer: {
      width: 250,
    },
    checkBoxCol: {
      ...cellContainer,
    },
    accountCol: {
      ...cellContainer,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    typeCol: {
      ...cellContainer,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    descriptionCol: {
      ...cellContainer,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  });
};
