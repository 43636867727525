import React, { ReactElement, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import Downshift from 'downshift';

import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { noNaN } from 'utils/utils';
import { Payee } from '../types';
import styles from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface MaterialComboBoxProps {
  onChangeInput: (e: React.MouseEvent<HTMLElement>) => void;
  onKeyDown: (e: KeyboardEvent) => void;
  items: Payee[];
  displayTemplate: (item, itemProps, inputValue) => void;
  placeholder: string;
  searchTemplate: (item: Payee) => string;
  inputDisplayTemplate: (item: Payee) => string;
  classes: object;
  value: string;
  onChange: (e: React.MouseEvent<HTMLElement>) => void;
  onBlur?: () => void;
  onListChange: (items: Payee[]) => void;
  name: string;
  disableUnderline?: boolean;
  menuPosition?: string;
  inputRef?: (x: HTMLInputElement) => void;
  fontSize?: string;
  label?: string;
  textFieldProps?: TextFieldProps;
  width: string;
  textFieldVariant?: string;
  marginProp?: string;
  initialHighlightedIndex?: number;
}

const MaterialCombobox: React.FC<MaterialComboBoxProps> = (props) => {
  const { width = 'auto', marginProp = 'none' } = props;

  const { classes } = useStyles();
  const [initialValue, setInitialValue] = useState<null | string>(props.value);
  const prevPropsValue = usePrevious(props.value);
  let target;

  const updateInputValue = (event: React.MouseEvent<HTMLElement>) => {
    if (props.onChangeInput) {
      props.onChangeInput(event);
      setInitialValue(null);
    }
  };

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      case '__autocomplete_click_item__':
      case '__autocomplete_blur_input__':
        if (state.selectedItem) {
          return {
            ...changes,
            inputValue: null,
          };
        }
        break;

      default:
        return changes;
    }
    return changes;
  };

  const itemChange = (e: React.MouseEvent<HTMLElement>) => {
    if (props.onChange) {
      props.onChange(e);
    }
    if (target) {
      target.select();
    }
  };

  const handleFocus = (e) => {
    e.target.select();
    target = e.target;
  };

  const keyDown = (e: KeyboardEvent, newItems) => {
    if (e.key === 'Escape') {
      setInitialValue(props.value);
    }
    if (e.key === 'Tab') {
      if (newItems && Math.max(noNaN(newItems.size), noNaN(newItems.length)) > 0) {
        // TODO: keeping this alread commented code, need to check the reason.
        /*
          const event = new KeyboardEvent('keydown', {
            key: 'Enter',
            keyCode: 13,
            bubbles: true,
            cancelable: true,
          });
          this.target.dispatchEvent(event);
          this.itemChange(items.get('0'));
        */
      }
    } else if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  };

  function autoCompleteContents({
    getInputProps,
    getItemProps,
    highlightedIndex,
    inputValue,
    isOpen,
    selectHighlightedItem,
    selectedItem,
  }) {
    function autoCompleteItemToHtml(item, index) {
      let selected = false;

      if (selectedItem) {
        if (item.id && selectedItem.id) {
          selected = item.id === selectedItem.id;
        }
      }

      const itemProps = getItemProps({
        item,
        index,
        dataHighlighted: highlightedIndex === index,
        dataSelected: selected,
        onClick: () => {
          selectHighlightedItem();
        },
      });

      let stateColor = 'rgba(0,0,0,0)';

      if (itemProps.dataHighlighted) {
        stateColor = 'rgba(0,0,0,0.12)';
      }

      if (itemProps.dataSelected) {
        stateColor = 'rgba(0,0,0,0.0)';
      }

      return props.displayTemplate
        ? props.displayTemplate(item, itemProps, inputValue)
        : (
          <ListItem
            {...itemProps}
            style={{
              backgroundColor: stateColor,
            }}
          >
            <ListItemText primary={`${item}`} />
          </ListItem>
        );
    }

    function valuesBySearchTerm(item: Payee) {
      const currentVal = inputValue || '';

      if (!currentVal) {
        return true;
      }

      const searchThis = props.searchTemplate
        ? props.searchTemplate(item)?.toLowerCase()
        : item.toString();

      return searchThis.indexOf(currentVal?.toLowerCase()) === 0;
    }

    let filteredItems: Payee[] = [];
    let autoCompleteMenuItems: ReactElement[] = [];
    if (isOpen) {
      filteredItems = props.items.filter(valuesBySearchTerm);
      // @ts-expect-error - FIXME:
      autoCompleteMenuItems = filteredItems.map(autoCompleteItemToHtml);
      if (props.onListChange) {
        props.onListChange(filteredItems);
      }
    }

    const autoCompleteMenu = (
      <div className={classes.menuAnchor}>
        <List
          className={classes.listContainer}
          sx={{ ...(props.menuPosition && { position: props.menuPosition }) }}
        >
          {autoCompleteMenuItems}
        </List>
      </div>
    );

    const value = inputValue || initialValue || '';

    const inputProps = getInputProps({
      placeholder: props.placeholder || '',
      value,
      className: props.textFieldVariant === 'outlined' ? '' : classes.inputField,
      label: props.label,
      onChange: updateInputValue,
      autoFocus: true,
      onFocus: handleFocus,
      onKeyDown: (e) => keyDown(e, filteredItems),
      name: props.name,
      // padding added directly to the right input to accommodate the menu icon
      InputProps: {
        inputProps: {
          ref: props.inputRef,
          style: { fontSize: props.fontSize || '13px', paddingRight: 6, minHeight: 23 },
          'aria-label': 'Payee Field',
        },
        onBlur: props.onBlur,
        ...(props.textFieldVariant !== 'outlined' && { disableUnderline: props.disableUnderline }),
      },
    });

    const retItems: ReactElement[] = [];
    retItems.push(
      <div className={classes.frame}>
        <Box className={classes.inputFrame} width={width}>
          <TextField
            variant={props.textFieldVariant}
            margin={marginProp}
            {...props.textFieldProps}
            {...inputProps}
            fullWidth
          />
        </Box>
        <div className={classes.menuFrame}>
          {isOpen ? autoCompleteMenu : null}
        </div>
      </div>,
    );
    return retItems;
  }

  useEffect(() => {
    if (props.value !== prevPropsValue) {
      setInitialValue(props.value);
    }
  }, [props.value, prevPropsValue]);

  return (
    <Downshift
      {...props}
      stateReducer={stateReducer}
      // @ts-expect-error TS() - FIXME: some event handler type issue with Downshift component
      onChange={itemChange}
      itemToString={props.inputDisplayTemplate}
    >
      {autoCompleteContents.bind(this)}
    </Downshift>
  );
};

export default React.memo(MaterialCombobox);
