// @flow
import { accountsTypes, accountsTransformers } from 'companion-app-components/flux/accounts';

export function transformResponseToInvestmentAccounts(response: any) {
  return response.data.resources.map((account) => transformQ17ApiDataToInvestmentAccount(account));
}

export function transformInvestmentAccountToQ17RequestData(account: accountsTypes.AccountRecordTypes) {
  return transformInvestmentAccountToQ17ApiRequestData(account);
}

export const transformInvestmentAccountsToQ17RequestData = (accounts: Array<accountsTypes.AccountRecordTypes>) => ({
  resources: accounts.map(transformInvestmentAccountToQ17ApiRequestData),
});

function transformQ17ApiDataToInvestmentAccount(data) {
  // todo: remove when investment accounts are migrated in QCS
  //   what about INVESTMENT_TAXABLE, INVESTMENT_TAXABLE_DEFERRED, ESOP, ESPP, TRUST, TDA,
  //
  let subType = null;
  switch (data.investmentAccountType) {
    case 'INV_ACCT_TYPE_BROKERAGE':
      subType = 'BROKERAGE';
      break;
    case 'INV_ACCT_TYPE_401K':
      subType = '401K';
      break;
    case 'INV_ACCT_TYPE_IRA':
      subType = 'IRA';
      break;
    case 'INV_ACCT_TYPE_529':
      subType = '529';
      break;
    case 'INV_ACCT_TYPE_UGMA':
      subType = 'UGMA';
      break;
    case 'INV_ACCT_TYPE_403B':
      subType = '403B';
      break;
    case 'INV_ACCT_TYPE_KEOGH':
      subType = 'KEOGH';
      break;
    case 'INV_ACCT_TYPE_SEP':
      subType = 'SEP';
      break;
    case 'INV_ACCT_TYPE_SIMPLE':
      subType = 'SIMPLE_IRA';
      break;
    case 'INV_ACCT_TYPE_OTHER':
      subType = 'OTHER_INVESTMENT';
      break;
    default:
      subType = null;
      break;
  }

  return accountsTypes.mkInvestmentAccount({
    ...accountsTransformers.transformApiDataToAccountMap(data),
    type: 'INVESTMENT',
    subType,
    name: data.userAccountName,
    isDeleted: data.deleted,
    cashBalance: data.cashBalance,
    currency: 'USD',
  });
}

function transformInvestmentAccountToQ17ApiRequestData(account: accountsTypes.AccountRecordTypes) {
  const id = account.id !== '0' ? account.id : null;
  let investmentAccountType = null;

  switch (account.subType) {
    case 'BROKERAGE':
      investmentAccountType = 'INV_ACCT_TYPE_BROKERAGE';
      break;
    case '401K':
      investmentAccountType = 'INV_ACCT_TYPE_401K';
      break;
    case 'IRA':
      investmentAccountType = 'INV_ACCT_TYPE_IRA';
      break;
    case '529':
      investmentAccountType = 'INV_ACCT_TYPE_529';
      break;
    case 'UGMA':
      investmentAccountType = 'INV_ACCT_TYPE_UGMA';
      break;
    case '403B':
      investmentAccountType = 'INV_ACCT_TYPE_403B';
      break;
    case 'KEOGH':
      investmentAccountType = 'INV_ACCT_TYPE_KEOGH';
      break;
    case 'SEP':
      investmentAccountType = 'INV_ACCT_TYPE_SEP';
      break;
    case 'SIMPLE_IRA':
      investmentAccountType = 'INV_ACCT_TYPE_SIMPLE';
      break;
    case 'OTHER_INVESTMENT':
      investmentAccountType = 'INV_ACCT_TYPE_OTHER';
      break;
    default:
      investmentAccountType = null;
      break;
  }

  return {
    id,
    clientId: undefined,
    investmentAccountType,
    accountNumberLast4: undefined,
    userAccountName: account.name,
    fiName: undefined,
    cashBalance: account.cashBalance,
    isDeleted: account.isDeleted,
    isExcludedFromAccountBar: account.isExcludedFromAccountBar,
    isExcludedFromReports: account.isExcludedFromReports,
    isClosed: account.isClosed,
    deleted: account.isDeleted,
  };
}



