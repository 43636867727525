import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { List as ImmList } from 'immutable';

import { TextFieldProps } from '@mui/material';
import Box from '@mui/material/Box';

import RootState from 'companion-app-components/utils/redux-store/rootState';

import { getPayeesAsList } from 'data/payees/selectors';
import QTip from 'components/QuickenControls/QTip';
import QTypography from 'components/MUIWrappers/QTypography';
import FilteredInput from './FilteredInput';

const emptyList = ImmList();

interface PayeeFieldEditableProps {
  accountIds?: string[];  // required for payeeList Selector, if not specfied, uses "all account"
  editable?: boolean;
  onChange?: (obj: {
    target: Record<string, any>;
  }) => void;
  onBlur?: () => void;
  value: Record<string, any>;
  initialFocus?: boolean;
  clickable?: boolean;
  textFieldProps?: TextFieldProps;
  classNameWhenNotEditable?: string;
  tooltip?: boolean; // show a tooltip on static value?
  disableUnderline?: boolean;
  autoFocus?: boolean;
  menuPosition?: string;
  fontSize?: string;
  inputRef?: (x: HTMLInputElement) => void;
  name?: string;
  menuShowFields?: string;  // comma separated string, valid values are 'category', 'amount', 'payee', 'tags', 'memo'
  placeholder?: string;
  label?: string;
  width?: string;
  textFieldVariant?: string;
  marginProp?: string;
  useAllAccounts?: boolean;
}

const PayeeFieldEditable: React.FC<PayeeFieldEditableProps> = (props) => {
  const { menuShowFields = 'payee', width = 'auto' } = props;

  const [stateValue, setStateValue] = useState(props.value);
  const prevPayeeValue = usePrevious(props.value.payee);
  const payeeList = useSelector((state: RootState) => 
    getPayeesAsList(state, { ...props, accountIds: props?.useAllAccounts ? null : props.accountIds }));

  let inputRef: null | HTMLInputElement = null;

  const menuOnChange = (item) => {
    if (props.onChange) {
      props.onChange({ target: { value: item } });
    }
  };

  const onChangeInput = (event) => {
    if (props.onChange) {
      props.onChange({ target: { value: { name: event.target.value } } });
    }
  };

  useEffect(() => {
    if (props.initialFocus && inputRef) {
      inputRef.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.value.payee !== prevPayeeValue) {
      setStateValue((prevState) => ({
        ...prevState,
        payee: props.value.payee,
      }));
    }
  }, [props.value, prevPayeeValue]);

  return (
    <>
      {props.editable &&
        <Box
          display="flex"
          width={width}
        >
          <FilteredInput
            placeholder={props.placeholder}
            label={props.label}
            data={payeeList || emptyList}
            value={stateValue.payee}
            onChange={menuOnChange}
            onChangeInput={onChangeInput}
            onBlur={props.onBlur}
            name={props.name || 'payeelist'}
            disableUnderline={props.disableUnderline}
            autoFocus={props.autoFocus}
            menuPosition={props.menuPosition}
            textFieldProps={props.textFieldProps}
            textFieldVariant={props.textFieldVariant}
            marginProp={props.marginProp}
            inputRef={(x: HTMLInputElement) => {
              if (props.inputRef) {
                props.inputRef(x);
              }
              inputRef = x;
            }}
            fontSize={props.fontSize}
            menuShowFields={menuShowFields}
            width={width}
          />
        </Box>}
      {!props.editable &&
        <QTip
          wrapOnly
          title={props.tooltip ? stateValue.payee : null}
        >
          <QTypography
            type="body1"
            clickable={props.clickable}
            thinFont
            className={props.classNameWhenNotEditable}
          >
            {stateValue.payee}
          </QTypography>
        </QTip>}
    </>
  );
};

export default React.memo(PayeeFieldEditable);
