/**
 * Asynchronously loads the component for NetIncome
 */
// import React, { lazy, Suspense, ReactNode } from 'react';
import * as React from 'react';
import LoadingView from 'components/LoadingView';
import retryPromise from 'utils/retryPromise';

const Component = React.lazy(() => retryPromise(() => import('./index')));

const loadable = (props): React.ReactNode =>
  <React.Suspense fallback={<LoadingView />}>
    <Component {...props} />
  </React.Suspense>;

export default loadable;
