import { createAction } from 'redux-actions';

import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';

export const DIALOG_TYPE = 'RELEASE_NOTES';

export const showReleaseNotesDialog = createAction(
  createDialog.toString(),
  () => mkRootUiData({
    type: DIALOG_TYPE,
    allowNesting: true,
  })
);
