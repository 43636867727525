import { select, takeEvery, put } from 'redux-saga/effects';

import { getLogger, tracker } from 'companion-app-components/utils/core';
import { authSelectors } from 'companion-app-components/flux/auth';
import { accountsActions, accountsSelectors } from 'companion-app-components/flux/accounts';
import { transactionsActions } from 'companion-app-components/flux/transactions';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import * as actions from 'companion-app-components/flux/preferences/actions';
import * as preferencesSelectors from 'companion-app-components/flux/preferences/selectors';
import { datasetsSelectors } from 'companion-app-components/flux/datasets';
import * as institutionLoginsSelectors from 'companion-app-components/flux/institution-logins/institutionLoginsSelectors';

import { getInstitutionLoginsById } from 'data/institutionLogins/selectors';
import * as transactionsSelectors from 'data/transactions/selectors';
import { getBalanceForAccountAtDate } from 'data/accountBalances/retrievers';
import { initBalMismatchPrefs } from 'data/accountBalances/utils';
import { APP_STRING_NOT_APPLICABLE } from 'utils/constants';
import { ACCOUNT_TYPES } from 'data/accounts/constants';
import { getInstitutionLoginsSuccess } from 'data/institutionLogins/actions';

const log = getLogger('data/accountBalances/sagas');

let checkedBalances = false;

const createEventObject = (datasetId, account, deltaAmount, institutionLogin, isC2REnabled) => ({
  datasetId,
  accountId: account.id,
  accountName: account.name,
  accountType: account.type,
  accountSubType: account.subType,
  institutionId: institutionLogin?.institutionId ?? APP_STRING_NOT_APPLICABLE,
  institutionName: institutionLogin?.name ?? 'manual',
  channel: institutionLogin?.channel ?? APP_STRING_NOT_APPLICABLE,
  brandingId: institutionLogin?.brandingId ?? APP_STRING_NOT_APPLICABLE,
  aggStatus: institutionLogin?.aggregators?.get?.(0)?.aggStatus ?? APP_STRING_NOT_APPLICABLE,
  delta: deltaAmount,
  isC2REnabled,
});


const isResetOfBalMismatchPrefNeeded = (deltaAmount, zeroValue, balMismatchPref, account) => Boolean(
  deltaAmount === zeroValue &&
  balMismatchPref?.byId &&
  balMismatchPref?.byId[account.id]?.mismatchAmount &&
  balMismatchPref?.byId[account.id]?.mismatchAmount !== zeroValue,
);


// Check if all resoucess are loaded and available before we start validation for account balances

function* areResoucesReadyToCalculateBalances() {
  const accountsPending = yield select(accountsSelectors.getLoadPending);
  const transactionsPending = yield select(transactionsSelectors.getLoadPending);
  const preferencesPending = yield select(preferencesSelectors.getLoadPending);
  const institutionLoginsPending = yield select(institutionLoginsSelectors.getLoadPending);
  const preferencesLoading = yield select(preferencesSelectors.getIsLoading);
  const accountsLoading = yield select(accountsSelectors.getIsLoading);
  const transactionsLoading = yield select(transactionsSelectors.getIsLoading);
  const institutionLoginsLoading = yield select(institutionLoginsSelectors.getIsLoading);

  return !accountsLoading &&
    !accountsPending &&
    !transactionsLoading &&
    !transactionsPending &&
    !preferencesPending &&
    !preferencesLoading &&
    !institutionLoginsPending &&
    !institutionLoginsLoading;
}

// ====================================================
// VALIDATE BALANCES
export function* validateBalances() {
  try {
    const datasetId = yield select(authSelectors.getDatasetId);
    const showEvent = (yield select(featureFlagsSelectors.getFeatureFlags)).get('flagBalanceMismatchWithEvent');
    const resourcesReady = yield areResoucesReadyToCalculateBalances();
    const datasetsById = yield select(datasetsSelectors.getDatasetsById);
    const isSharedDataset = datasetId && datasetsById.get(datasetId)?.shared;

    if (!isSharedDataset && resourcesReady && !checkedBalances) {
      checkedBalances = true;
      let setPreferenceForBalMismatch = false;
      log.log('VALIDATE BALANCES FOR ALL ACCOUNTS ------ ');

      let balMismatchPref = yield select(
        preferencesSelectors.getSharedPreferencesByPath,
        { group: 'dataset', path: ['balanceMismatchPrefs'] },
      );

      const c2rEnabledAccountList = yield select(
        preferencesSelectors.getSharedPreferencesByPath,
        { group: 'dataset', path: ['c2rEnabledAccounts'] },
      );

      const accountsById = yield select(accountsSelectors.getAccountsById);
      const institutionLogins = yield select(getInstitutionLoginsById);
      const periodicEvents = [];

      accountsById.forEach((account) => {
        if (account.type !== ACCOUNT_TYPES.INVESTMENT) {
          const balanceAsOf = getBalanceForAccountAtDate({
            accountId: account.id,
            date: account.balanceAsOfOn,
            balanceType: 'currentBalance',
          });

          if (balanceAsOf == null || account.balanceAsOf == null) {
            return;
          }

          const deltaAmount = (Number(balanceAsOf) - Number(account.balanceAsOf)).toFixed(2);

          const zeroValue = Number(0).toFixed(2);
          const resetBalMismatchPref = isResetOfBalMismatchPrefNeeded(deltaAmount, zeroValue, balMismatchPref, account);
          const institutionLogin = account.institutionLoginId ? institutionLogins.get(account.institutionLoginId) : undefined;
          const isC2REnabled = Boolean(c2rEnabledAccountList?.includes(account.id));

          if (showEvent && deltaAmount !== zeroValue) {
            const oldEvent = createEventObject(datasetId, account, deltaAmount, institutionLogin, isC2REnabled);
            log.log('Balance Event Sent: ', oldEvent);
            tracker.track(tracker.events.accountBalanceMismatch, oldEvent);
          }

          if ((deltaAmount !== zeroValue && deltaAmount !== balMismatchPref?.byId[account.id]?.mismatchAmount) || resetBalMismatchPref) {
            balMismatchPref = initBalMismatchPrefs(account.id, deltaAmount, balMismatchPref);
            setPreferenceForBalMismatch = true;

            // Periodic event need also be be sent when resetBalMismatchPref to track the fixing ratios
            if (showEvent) {
              const event = createEventObject(datasetId, account, deltaAmount, institutionLogin, isC2REnabled);
              log.log('New Balance Mismatch Event Sent: ', event);
              periodicEvents.push(event);
            }
          }
        }
      });

      if (setPreferenceForBalMismatch) {
        const prefObj = {
          section: 'shared',
          group: 'dataset',
          preference: { balanceMismatchPrefs: balMismatchPref },
        };

        // Pass this meta information so we could confirm the put call is from accountBalanceMismatch
        // and pass mixpanel events so they can be sent on preference success
        const meta = {
          context: 'setBalanceMismatchPeriodicPreferences',
          trackEvents: periodicEvents,
        };

        yield put(actions.setPreference(prefObj, meta));
      }
    }
  } catch (error) {
    log.error('Error in validateBalances:', error);
  }
}


export function* trackBalanceMismatchPeroidicOnPreferenceSuccess(action) {
  if (action?.meta?.context === 'setBalanceMismatchPeriodicPreferences') {
    action?.meta?.trackEvents?.forEach((event) => tracker.track(tracker.events.accountBalanceMismatchPeriodic, event));
  }
}

// ====================================================
// ACTION WATCHERS to trigger SAGAS calls

export function* getPreferencesSuccessActionWatcher() {
  yield takeEvery(actions.setPreferenceSuccess, trackBalanceMismatchPeroidicOnPreferenceSuccess);
}

export function* watchForValidateBalances() {
  yield takeEvery(
    [
      accountsActions.getAccountsSuccess,
      transactionsActions.getTransactionsSuccess,
      getInstitutionLoginsSuccess,
    ],
    validateBalances,
  );
}


// ====================================================
// EXPORTS

export default [
  watchForValidateBalances,
  getPreferencesSuccessActionWatcher,
];
