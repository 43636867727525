export function lightOrDark(color) {

  let r;
  let g;
  let b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If HEX --> store the red, green, blue values in separate variables
    //
    const tmp = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = tmp[1];
    g = tmp[2];
    b = tmp[3];
  } else {

    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    //
    // const tmp = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
    const tmp = `0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    /* eslint-disable no-bitwise */
    r = tmp >> 16;
    g = tmp >> 8 & 255;
    b = tmp & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt((0.299 * (r * r)) + (0.587 * (g * g)) + (0.114 * (b * b)));

  return hsp > 127.5 ? 'light' : 'dark';
}
