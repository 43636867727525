export const styles = (theme) => ({
  reviewed: {
    color: '#777',
    '&.reviewed': {
      color: theme.palette.gray5,
      fontSize: 'small',
    },
    '&.notReviewed': {
      color: theme.palette.blue6,
      fontSize: 'small',
    },
  },
  text: {
    color: theme.palette.secondary.dark,
    marginLeft: '24px',
  },
  container: {
    marginTop: '0px',
    background: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.1),
    display: 'flex',
    alignItems: 'center',
    height: theme.components.register.controlBarHeight,
    paddingLeft: theme.components.register.paddingLeft,
    paddingRight: theme.components.register.paddingRight,
    paddingBottom: 0,
  },
  cancelBtn: {
    marginLeft: 20,
    transform: 'scale(.9)',
  },
});
