
import React from 'react';
import { getSmartDateLabel, getSmartTimeLabel } from 'utils/date/utils';
import { retryPromise } from 'utils/retryPromise';
import { applyOpacityToHex } from 'themes/themeUtils';
import { DateTime } from 'luxon';

import { mkSharedFunction } from './Wrappers/factories';

const smartDates = [
  DateTime.local().minus({ days: 366 }).toISODate(),
  DateTime.local().minus({ days: 7 }).toISODate(),
  DateTime.local().minus({ days: 6 }).toISODate(),
  DateTime.local().minus({ days: 5 }).toISODate(),
  DateTime.local().minus({ days: 1 }).toISODate(),
  DateTime.local().toISODate(),
  DateTime.local().plus({ days: 1 }).toISODate(),
  DateTime.local().plus({ days: 5 }).toISODate(),
  DateTime.local().plus({ days: 6 }).toISODate(),
  DateTime.local().plus({ days: 7 }).toISODate(),
  DateTime.local().plus({ days: 366 }).toISODate(),
];

const smartTimes = [
  DateTime.local().toISO(),
  DateTime.local().minus({ minutes: 1 }).toISO(),
  DateTime.local().minus({ minutes: 59 }).toISO(),
  DateTime.local().minus({ hours: 1 }).toISO(),
  DateTime.local().minus({ days: 1 }).toISO(),
  DateTime.local().minus({ days: 364 }).toISO(),
  DateTime.local().minus({ days: 365 }).toISO(),
];

const utilitiesConfig = {
  name: 'Utilities',
  id: 'UTILITIES',
  children: [
    mkSharedFunction({
      name: 'getSmartDateLabel',
      id: 'GET_SMART_DATE_LABEL',
      searchTags: ['time', 'date', 'convert'],
      func: getSmartDateLabel,
      parameters: ['isoDate (2020-01-01)'],
      description: 'returns human readable representation of the interval between now and date provided',
      examples: smartDates.map((isoDate) => (
        <div>{isoDate} : {getSmartDateLabel(isoDate)}</div>
      )),
    }),

    mkSharedFunction({
      name: 'getSmartTimeLabel',
      id: 'GET_SMART_TIME_LABEL',
      searchTags: ['time', 'date', 'convert'],
      parameters: ['isoDateTime (2019-12-31T23:57:25Z)'],
      func: getSmartTimeLabel,
      description: 'returns human readable representation of the interval between now and time provided',
      examples: smartTimes.map((isoTime) => (
        <div>{isoTime} : {getSmartTimeLabel(isoTime)}</div>
      )),
    }),

    mkSharedFunction({
      name: 'retryPromise',
      id: 'RETRY_PROMISE',
      description: 'Retries a given promise a certain amount of times',
      searchTags: [],
      parameters: ['makeOriginalPromise', 'attemptsLeft', 'attemptsInterval'],
      func: retryPromise,
    }),

    mkSharedFunction({
      name: 'applyOpacityToHex',
      id: 'APPLY_OPACITY_TO_HEX',
      description: 'convert a hex color code to rgba and give it an opacity, must be full hex code',
      searchTags: ['theme', 'colors'],
      func: applyOpacityToHex,
    }),

  ],
};

export default utilitiesConfig;
