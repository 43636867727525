import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Downshift from 'downshift';
import VirtualList from 'react-tiny-virtual-list-dmk';

import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ListItem from '@mui/material/ListItem';

import { getInvestmentSymbols } from 'data/investmentSymbols/investmentSymbolsActions';
import { getInvestmentSymbolsFiltered, getIsLoading } from 'data/investmentSymbols/investmentSymbolsSelectors';
import HighlightText from 'components/HighlightText';
import ErrorBoundary from 'components/ErrorBoundary';

const itemHeight = 48;  //  The height of the items and the max # of items to be shown in the list
const itemsToShow = 7;

const useStyles = makeStyles()((theme) => ({
  popperStyle: {
    zIndex: theme.zIndex.modal,
  },
  itemList: {
    width: '96%',
    //  marginRight: '10px',
    marginLeft: '10px',
    height: itemHeight,
    borderRadius: '12px',
    '&.hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  itemListCreate: {
    minWidth: '500px',
    height: itemHeight,
    borderRadius: '12px',
    '&.hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  itemListTextSymbol: {
    width: 120,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  itemListTextName: {
    width: 380,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  itemListTextCreate: {
    minWidth: '500px',
  },
  itemListSelected: {
    backgroundColor: theme.palette.action.hover,
    borderRadius: '12px',
  },
  itemTextWrapper: {
    display: 'flex',
  },
  addBtnIcon: {
    color: theme.palette.text.primary,
  },
}));

const SymbolLookupField = React.memo((props) => {
  const { id, value, onSelected, onInputValueChange, hideCreateOption, symbol, ...otherProps } = props;
  const dispatch = useDispatch();

  const { classes } = useStyles();

  const [filter, setFilter] = useState();
  useEffect(() => {
    if (filter) {
      dispatch(getInvestmentSymbols(undefined, { axiosConfig: { params: { keyword: filter } } }));
    }
  }, [filter, dispatch]);

  const items = useSelector((state) => getInvestmentSymbolsFiltered(state, filter));
  const investmentSymbolsIsLoading = useSelector((state) => getIsLoading(state));

  const anchorElRef = useRef();

  return (
    <ErrorBoundary>
      <Downshift
        defaultHighlightedIndex={0}
        initialInputValue={value}
        itemToString={(item) => (item?.symbol ? `${item.symbol} ${item.name}` : item?.name)}
        onChange={(item) => onSelected?.(item)}
        onInputValueChange={(inputValue) => onInputValueChange?.(inputValue)}
        id={id}
        stateReducer={(state, changes) => {
          let changesApproved = changes;
          switch (changes.type) {
          //   case Downshift.stateChangeTypes.blurInput:
            case Downshift.stateChangeTypes.keyDownEscape:
            case Downshift.stateChangeTypes.mouseUp:
              changesApproved = {
                ...changes,
                inputValue: state.inputValue,
                selectedItem: { name: state.inputValue }, // create custom
              };
              break;
            default:
              changesApproved = changes;
          }
          return changesApproved;
        }}
      >
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          //  highlightedIndex,
          selectHighlightedItem,
        }) => {
          setFilter(inputValue);
          return (
            <div
              {...getRootProps({}, { suppressRefError: true })}
            >
              <TextField
                {...otherProps}
                sharedcomponentid={'SYMBOL_LOOKUP_FIELD'}
                inputRef={anchorElRef}
                onInput={(event) => {
                  if (symbol) {
                    const { target } = event;
                    const { selectionStart } = target;
                    target.value = target.value.toUpperCase();
                    target.setSelectionRange(selectionStart, selectionStart);
                  }
                }}
                InputProps={{
                  ...getInputProps({
                    autoComplete: 'off',
                    endAdornment: investmentSymbolsIsLoading &&
                      <InputAdornment variant="filled" position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>,
                    onKeyDown: (event) => {
                      if (isOpen && event.key === 'Tab') {
                        selectHighlightedItem();
                      }
                    },
                    ...otherProps.InputProps,
                  }),
                }}
              />

              <Popper
                open={isOpen}
                anchorEl={anchorElRef.current}
                placement="bottom-start"
                className={classes.popperStyle}
                //  style={{ zIndex: theme.zIndex.modal }}
              >
                <div {...getMenuProps({}, { suppressRefError: true })}>
                  <Paper elevation={12}>
                    {items.size > 0 &&
                    <VirtualList
                      width="500px"
                      height={itemHeight * (items.size < itemsToShow ? items.size : itemsToShow + 0.5)}
                      itemSize={items.size > itemHeight ? itemHeight : items.size}
                      itemCount={items.size > itemHeight ? itemHeight : items.size}
                      overscanCount={40}
                      //  scrollToIndex={highlightedIndex}
                      //  scrollToAlignment="auto"
                      renderItem={({ index /* , style */ }) => {
                        const item = items.get(index);
                        if (!item) return null;
                        return (
                          <ListItem
                            button
                            className={classes.itemList}
                            {...getItemProps({
                              //  style,
                              index,
                              key: item.symbol,
                              id: item.symbol,
                              item,
                              //  selected: index === highlightedIndex,
                            })}
                          >
                            <ListItemText
                              primary={
                                <div
                                  className={classes.itemTextWrapper}
                                >
                                  <div
                                    className={classes.itemListTextSymbol}
                                  >
                                    <HighlightText
                                      className={classes.itemListTextSymbol}
                                      text={item.symbol}
                                      searchKeys={[filter]}
                                    />
                                  </div>
                                  <div
                                    className={classes.itemListTextName}
                                  >
                                    <HighlightText
                                      className={classes.itemListTextName}
                                      text={item.name}
                                      searchKeys={[filter]}
                                    />
                                  </div>
                                </div>
                              }
                            />
                          </ListItem>
                        );
                      }}
                    />}
                    {inputValue && !hideCreateOption && items.size === 0 &&
                    <ListItem
                      button
                      className={classes.itemListCreate}
                      {...getItemProps({
                        index: items.size,
                        key: inputValue,
                        id: inputValue,
                        item: { name: inputValue }, // create custom
                        //  selected: items.size === highlightedIndex,
                      })}
                    >
                      <ListItemText
                        primary={
                          <HighlightText
                            primaryText
                            className={classes.itemListTextCreate}
                            text={`Create holding with "${inputValue}" name.`}
                            searchKeys={[`"${filter}"`]}
                          />
                        }
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <AddCircleOutlineIcon className={classes.addBtnIcon} />
                    </ListItem>}
                  </Paper>
                </div>
              </Popper>
            </div>
          );
        }}
      </Downshift>
    </ErrorBoundary>
  );
});

SymbolLookupField.propTypes = {
  onSelected: PropTypes.func,
  onInputValueChange: PropTypes.func,
  id: PropTypes.any,
  value: PropTypes.string,
  hideCreateOption: PropTypes.bool,
  symbol: PropTypes.bool,
};

export default SymbolLookupField;

