//  @flow
import React from 'react';
import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';

import { v4 as uuidv4 } from 'uuid';

// import { getLogger } from 'utils/logger';

import {
  stdFormAdd,
  stdFormDelete,
  stdFormSubmitSuccess,
  stdFormCancelled,
} from 'data/StdForm/actions';

// const log = getLogger('StdForm');

type Config = {
  type: ?string,
}

type Props = {

  // from connect
  pvtStdFormAdd: (any) => void,
  pvtStdFormDelete: (string) => void,

  pvtStdFormSubmitSuccess: (any) => void,
  pvtStdFormCancelled: (string) => void,
}

export default (config: Config) => (WrappedComponent: ComponentType<any>): ComponentType<any> => {
  class StdForm extends React.Component<Props> {

    id: string;
    type: string;

    constructor(props) {
      super(props);

      this.id = uuidv4();
      this.type = config.type ? config.type : 'STD_FORM';
    }

    UNSAFE_componentWillMount() {
      this.props.pvtStdFormAdd({ id: this.id, type: this.type });
    }

    componentWillUnmount() {
      this.props.pvtStdFormDelete(this.id);
    }

    stdFormHandleSubmitSuccess = (data = null) => this.props.pvtStdFormSubmitSuccess({ id: this.id, data });
    stdFormHandleCancel = () => this.props.pvtStdFormCancelled(this.id);

    render() {
      const { pvtStdFormAdd, pvtStdFormDelete, pvtStdFormSubmitSuccess, pvtStdFormCancelled, ...passThroughProps } = this.props;

      const stdFormProps = {
        stdFormHandleSubmitSuccess: this.stdFormHandleSubmitSuccess,
        stdFormHandleCancel: this.stdFormHandleCancel,
      };

      // wrapped component with its props, the state from HOC and uiStateProps
      return (
        <WrappedComponent
          {...{
            ...passThroughProps,
            formId: this.id,
            ...stdFormProps,
          }}
        />
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      pvtStdFormAdd: (id) => dispatch(stdFormAdd(id)),
      pvtStdFormDelete: (id) => dispatch(stdFormDelete(id)),
      pvtStdFormSubmitSuccess: (data) => dispatch(stdFormSubmitSuccess(data)),
      pvtStdFormCancelled: (id) => dispatch(stdFormCancelled(id)),
    };
  }

  return compose(
    connect(null, mapDispatchToProps),
  )(StdForm);
};
