import React from 'react';
import PropTypes from 'prop-types';

import { scheduledTransactionsUtils } from 'companion-app-components/flux/scheduled-transactions';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// CUSTOM
import StdDialog from 'components/Dialogs/StdDialog';
import QButton from 'components/QButton';
import AmountField, { ShowSignEnum } from 'components/QuickenControls/AmountField';
import BillPresentmentConnectBox from 'components/BillPresentmentConnectBox';
import ConnectedAvatar from 'components/ConnectedAvatar';
import QSkeleton from 'components/QSkeleton';


const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '12px 0px 24px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  dismiss: {
    position: 'absolute',
    borderRadius: 10,
    top: 6,
    right: 6,
    height: 20,
    width: 20,
    '&:hover': {
      backgroundColor: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.1),
    },
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    padding: 4,
    color: theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.8),
  },
  name: {
    width: '100%',
    padding: '0 24px',
    margin: '8px 0 14px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  dotRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  amount: {
    fontWeight: 500,
    color: 'inherit',
  },
  frequency: {
    color: 'inherit',
  },
  dot: {
    height: 8,
    weight: 8,
  },
  editButton: {
    marginTop: 16,
    marginBottom: 12,
  },
}));

const location = 'create series';

function ReviewDialog(props) {
  const { classes } = useStyles();
  const { model, open, onClose, edit } = props;

  const txn = model.transaction || {};

  const frequency = model && scheduledTransactionsUtils.frequencyAliasFromRecurrence(model.recurrence);

  const exist = Boolean(txn.payee);
  return (
    <StdDialog
      open={open}
      onClose={onClose}
      title="New Recurring"
      fullWidth
      maxWidth="sm"
    >
      <div className={classes.root}>
        <ConnectedAvatar model={model} size={80} />
        {exist ?
          <Typography variant="h5" className={classes.name}>
            {txn.payee}
          </Typography>
          :
          <Skeleton variant="rectangular" width={240} height={34} className={classes.name} />}

        {exist ?
          <div className={classes.dotRow}>
            <AmountField
              showSign={ShowSignEnum.POSITIVE_ONLY}
              value={txn.amount}
              className={classes.amount}
              id="amount_id"
              variant="subtitle2"
              editable={false}
            />

            <FiberManualRecordIcon className={classes.dot} />

            <Typography variant="subtitle2" className={classes.frequency}>
              {frequency}
            </Typography>
          </div>
          :
          <Skeleton variant="rectangular" width={280} height={25} />}

        <QSkeleton show={!exist}>
          <QButton
            variant="text"
            id="edit-series-button"
            className={classes.editButton}
            onClick={edit}
          >
            Edit Series
          </QButton>
        </QSkeleton>

        <QSkeleton show={!exist}>
          <BillPresentmentConnectBox model={model} onConnect={onClose} location={location} />
        </QSkeleton>
      </div>
    </StdDialog>
  );
}

ReviewDialog.defaultProps = {
  open: false,
  model: {},
};

ReviewDialog.propTypes = {
  model: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.func,
};

export default ReviewDialog;
