// @flow
import { Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

import { DIALOG_CLOSE_EVENTS } from '../StdDialogDeprecated/types';
export const ACTION_EVENT_TYPES = ['CONFIRM', 'DENY', ...DIALOG_CLOSE_EVENTS];

type ConfirmationDialogPropsProps = {
  title: ?string,

  content: ?string,
  contentViewId: ?string,

  confirmLabel: string,
  denyLabel: string,

  confirmAction: ?Object | ?(ACTION_EVENT_TYPES, Event) => void,
  denyAction: ?Object | ?(ACTION_EVENT_TYPES, Event) => void,
  onClose: ?Object | ?(ACTION_EVENT_TYPES, Event) => void,

  dialogCloseAliasAction: ACTION_EVENT_TYPES,

  textEntryConfirmationText: ?string,
  unregisterContentViewOnClose: boolean,
}
export type ConfirmationDialogProps = RecordOf<ConfirmationDialogPropsProps>;
export const mkConfirmationDialogProps: RecordFactory<ConfirmationDialogPropsProps> =
  Record({
    title: null,

    content: null,
    contentViewId: null,

    confirmLabel: 'OK',
    confirmButtonVariant: 'contained',
    denyLabel: 'Cancel',
    denyButtonVariant: 'outlined',

    confirmAction: null,
    denyAction: null,
    onClose: null,

    dialogCloseAliasAction: 'DENY',

    textEntryConfirmationText: null,
    unregisterContentViewOnClose: false,

    tertiaryLabel: null,
    tertiaryAction: null,
    tertiaryLabelVariant: 'delete',
  });

export const DIALOG_TYPE = 'DIALOG_CONFIRMATION';

