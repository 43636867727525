
import React, { FC, useState, useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { List as ImmutableList } from 'immutable';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeleteIcon from '@mui/icons-material/Delete';

import QAmountField from 'components/QAmountField';
import QIconButton from 'components/QIconButton';
import CategoryReviewPopupCard from 'components/CategoryReviewPopupCard';
import QTip from 'components/QuickenControls/QTip';
import { setAmountSign } from 'components/Budgets/EditBudgetsDialog/editBudgetHelpers';
import { getCategoryGroupsById } from 'companion-app-components/flux/category-groups/categoryGroupsSelectors';

import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface BudgetGroupItemProps {
  item: Record<string, any>;
  autoFocus?: boolean;
  onChange?: (item: any) => void;
  onDelete?: (item: any) => void;
  accountIds: ImmutableList<string> | null;
  currency: string;
  isRollup?: boolean;
  depth: number;
  isEverythingElse?: boolean;
  overrideLabel?: string | null;
  chartIds?: Record<string, any>;
  classesPassed?: Record<string, any>;
}

const BudgetGroupItem: FC<BudgetGroupItemProps> = ({ item, autoFocus, onChange, onDelete, currency, accountIds, isRollup,
  chartIds, isEverythingElse, overrideLabel, depth = 0, classesPassed }) => {

  const chartIdsToUse = useMemo(() => chartIds || (item.coa ? [item.coa.id] : []), [chartIds, item.coa]);

  const [amountFieldValue, setAmountFieldValue] = useState(Number(item.amount));
  const [isIncomeItem, setIsIncomeItem] = useState(item.isIncome);
  const [catReviewAnchorEl, setCatReviewAnchorEl] = useState(null);
  const [showIcons, setShowIcons] = useState(false);
  const [alternativeChartIds, setAlternativeChartIds] = useState(chartIdsToUse.map((x) => x.id));

  const { classes } = useStyles({ isRollup, depth });

  const categoryGroupsById = useSelector((state) => getCategoryGroupsById(state), shallowEqual);

  useEffect(() => {
    // by keeping internal state of the 'value', this prevents from sending multiple change events
    // after changes and before the item is actually changed by the caller
    setAmountFieldValue(Number(item.amount));
    setIsIncomeItem(item.isIncome);
  }, [item]);

  // Determine the id's to use for the chart
  useEffect(() => {
    if (!chartIds && item.type === 'GROUP' && item.groupId && categoryGroupsById) {
      const group = categoryGroupsById.get(item.groupId);
      if (group && group.coas) {
        const idList = ImmutableList(group.coas.map((coa) => coa.id));
        setAlternativeChartIds(idList);
      }
    } else {
      setAlternativeChartIds(chartIdsToUse.map((x) => x.id));
    }
  }, [item, categoryGroupsById, chartIds, chartIdsToUse]);

  const internalOnChange = (e) => {
    // flip the sign for expense cats as appropriate
    const newVal = isIncomeItem ? Number(Math.abs(e.target.value)) : -Number(Math.abs(e.target.value));

    // only indicate change if it, well... changed.
    if (newVal !== Number(amountFieldValue)) {
      const newItem = item.set('amount', newVal);
      if (onChange) onChange(newItem);
    }
    setAmountFieldValue(newVal);
  };

  const constructName = () => {
    let str = item.name;
    const catParts = str.split(':');
    let parentStr = '';
    if (!isRollup && catParts.length > (depth + 1)) {
      parentStr = `(${catParts[depth]})`;
    }
    str = catParts[catParts.length - 1];

    if (item.budgetedWithChild) str = `Total of ${str}`;
    if (item.type === 'CATEGORY_OTHER') str = `${str} (Other)`;
    if (isEverythingElse) str = `All others in ${str}`;

    return [str, parentStr];
  };

  const nameArray = overrideLabel ? [overrideLabel, ''] : constructName();

  return (
    <div
      className={classNames(classesPassed?.categoryRow || classes.categoryRow, isRollup ? 'rollup' : '')}
      onMouseEnter={() => setShowIcons(true)}
      onMouseLeave={() => setShowIcons(false)}
    >
      <div className={classes.categoryNameWrapper}>
        <Typography
          key={`editBudgetCats:str1 ${item.id}`}
          variant="body2"
          className={classNames(classesPassed?.categoryName, classes.categoryName)}
        >
          {nameArray[0]}
        </Typography>
        <Typography
          key={`editBudgetCats:str2 ${item.id}`}
          variant="body2"
          className={classes.categoryNameParent}
        >
          {nameArray[1]}
        </Typography>
      </div>
      <div
        className={!isRollup ? classes.categoryAmount : classes.rollUpAmount}
      >
        <QAmountField
          id={`budgetAmt:${nameArray[0]}`}
          editable={!isRollup}
          expectedSign={item.isIncome ? 'positive' : 'negative'}
          value={item.isIncome ? Math.abs(amountFieldValue) : -Math.abs(amountFieldValue)}
          currency={currency}
          autoFocus={autoFocus}
          onSubmit={(e) => internalOnChange(e)}
          submitOnBlur
          decimals={0}
          disableUnderline
          InputProps={{
            classes: {
              input: classes.amountInputStyle,
              adornedStart: classes.dollarAdornment,
            },
          }}
        />
      </div>
      {!isRollup &&
        <div className={classNames(classes.iconHolder, showIcons ? '' : classes.hide)}>
          <QTip
            title="Category Details Card..."
          >
            <QIconButton
              aria-label="Show Budget Item Info"
              onClick={(e) => setCatReviewAnchorEl(e.currentTarget)}
              id={`row-${item.id}-delete-item`}
              size="small"
            >
              <BarChartIcon
                className={classes.barChartIcon}
              />
            </QIconButton>
          </QTip>
          {onDelete &&
            <QIconButton
              aria-label="Remove Budget Item"
              onClick={() => onDelete(item)}
              id={`row-${item.id}-delete-item`}
              size="small"
            >
              <DeleteIcon
                className={classes.deleteIcon}
              />
            </QIconButton>}
          {catReviewAnchorEl &&
          <CategoryReviewPopupCard
            anchorEl={catReviewAnchorEl}
            open
            onClose={() => setCatReviewAnchorEl(null)}
            coa={item.coa}
            coaIds={alternativeChartIds.toArray()}
            classes={{
              root: classes.categoryReviewCardRoot,
            }}
            currency={currency}
            accountIds={accountIds}
            onSetValueFromGraph={(amt) => internalOnChange({ target: { value: setAmountSign(amt, item.coa) } })}
            showCents
          />}
        </div>}
    </div>
  );
};

export default BudgetGroupItem;
