import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Map as ImmutableMap } from 'immutable';

export const DIALOG_TYPE = 'YEARLY_BUDGET_EDIT';

export const showYearlyBudgetEdit = createAction(
  createDialog.toString(),
  (annualBudgetDataOfCategory = {}, budgetId, setHasAmountSaved, showCalculateAverageView = false, directlyCloseCalculateAverageView = false, allCategoryIdsOfBudget, selectedMonthDate) => mkRootUiData({
    type: DIALOG_TYPE,
    allowNesting: true,
    props: ImmutableMap({
      annualBudgetDataOfCategory,
      budgetId,
      setHasAmountSaved,
      showCalculateAverageView,
      directlyCloseCalculateAverageView,
      allCategoryIdsOfBudget,
      selectedMonthDate,
    }),
  })
);
