/*
 * PREFERENCE SELECTORS - getting data out of the preference store
 *
 * These routines require a "state" value from the preference store, to access
 * the data model from anywhere,use the preferenceStore module which will create the
 * state then call these routines
 */

import { createSelector } from 'reselect';

import { getSharedPreferencesByPath } from 'companion-app-components/flux/preferences/selectors';

export const isCompareToRegisterEnabled = createSelector(
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['compareToRegister', 'enabled'] }),
  (compareToRegisterStatus) => compareToRegisterStatus,
);

export const getRunningBalanceExcludeAccountIds = createSelector(
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['excludePendingInRunningBalance'] }),
  (runningBalanceExcludeAccountIds) => runningBalanceExcludeAccountIds,
);

export const isApplyMemorizedRulesEnabled = createSelector(
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['transactionOptions', 'applyMemorizedRules'] }),
  (applyMemorizedRules) => applyMemorizedRules,
);
