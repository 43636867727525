// @flow
/* eslint react/prop-types: 0 */

import React from 'react';

import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import defaultIcon from 'assets/error-connection.svg';
import QButton from 'components/QButton';
import classNames from 'classnames';

const styles = (_theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 auto',
    color: '#666',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
  },
  primary: {
    fontSize: 24,
  },
  primarySmall: {
    fontSize: 20,
  },
  secondary: {
    fontSize: 20,
    maxWidth: '88%',
    paddingTop: 16,
  },
  secondarySmall: {
    lineHeight: 'normal',
    maxWidth: '88%',
    fontSize: 16,
    paddingTop: 12,
  },
  details: {
    fontSize: 14,
    paddingTop: 16,
  },
  detailsSmall: {
    lineHeight: 'normal',
    maxWidth: '88%',
    fontSize: 12,
    paddingTop: 12,
  },
  buttons: {
    paddingTop: 28,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 'calc(5% + 20px)',
    width: '54%',
  },
  spaceWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    color: '#666',
    maxHeight: 240,
    marginBottom: '7%',
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  iconImg: {
    maxHeight: 219,
    maxWidth: 203,
    width: '23%',
  },
});

type Props = {
  onClick: (Event) => void,
  icon: ?any,
  style: Object,

  primary: ?(string | React.Node),
  primaryTypographyProps: ?Object,

  secondary: ?(string | React.Node),
  secondaryTypographyProps: ?Object,

  disableTypography: ?boolean,
  useSmallerFont: ?boolean,
  children?: React.Node,

  // from withStyles
  classes: Object,
};


const swallowClick = (event) => event.stopPropagation();

// we set this to transparent for now, don't seem to need it and don't want clutter on load
const InteractiveStateView = (props: Props) => {

  const {
    children,
    classes,
    disableTypography,
    icon,
    onClick,
    primary: primaryProp,
    primaryTypographyProps,
    secondary: secondaryProp,
    secondaryTypographyProps,
    details: detailsProp,
    detailsTypographyProps,
    useSmallerFont,
    goClick,
    goText,
    backClick,
    backText,
    widths,
    goProps,
    backProps,
  } = props;

  let primary = primaryProp || children || 'No Data';

  if (primary != null && primary.type !== Typography && !disableTypography) {
    primary = (
      <Typography
        variant="h5"
        className={useSmallerFont ? classes.primarySmall : classes.primary}
        {...primaryTypographyProps}
      >
        {primary}
      </Typography>
    );
  }

  let secondary = secondaryProp;

  if (secondary != null && secondary.type !== Typography && !disableTypography) {
    secondary = (
      <Typography
        variant="h6"
        className={useSmallerFont ? classes.secondarySmall : classes.secondary}
        {...secondaryTypographyProps}
      >
        {secondary}
      </Typography>
    );
  }

  let details = detailsProp;

  if (details != null && details.type !== Typography && !disableTypography) {
    details = (
      <Typography
        variant="body2"
        className={useSmallerFont ? classes.detailsSmall : classes.details}
        color="textSecondary"
        {...detailsTypographyProps}
      >
        {details}
      </Typography>
    );
  }

  return (
    <div className={classNames(classes.root, onClick ? classes.clickable : null)} onClick={onClick || swallowClick} role="none" style={props.style}>
      <div className={classes.spaceWrapper}>
        <img alt="nothing to report" src={icon || defaultIcon} className={classes.iconImg} />
        <div className={classes.rightSide}>
          <div className={classes.textBlock}>
            {primary}
            {secondary}
            {details}
          </div>
          <div className={classes.buttons}>
            {goText &&
              <QButton
                variant="contained"
                style={{ marginRight: 16, width: widths || '' }}
                onClick={goClick}
                {...goProps}
              >
                {goText}
              </QButton>}
            {backText &&
              <QButton
                variant="outlined"
                onClick={backClick}
                style={{ width: widths || '' }}
                {...backProps}
              >
                {backText}
              </QButton>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(InteractiveStateView, styles);
