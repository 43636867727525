import * as React from 'react';
import { FC, forwardRef, useState } from 'react';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import NestedMenuItem from './NestedMenuItem';
import IconMenuItem from './IconMenuItem';
import { StyledButton } from './styles';

interface MenuItemData {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  label: string;
  items?: MenuItemData[];
  callback?: () => void;
  sx?: Record<string, any>;
  disabled?: boolean;
}

interface NestedMenuItemsFromObjectProps {
  menuItemsData: MenuItemData[];
  isOpen: boolean;
  handleClose: () => void;
}

const nestedMenuItemsFromObject: FC<NestedMenuItemsFromObjectProps> = ({ menuItemsData, isOpen, handleClose }) => menuItemsData.map((item) => {
  const { leftIcon, rightIcon, label, items, callback, sx, disabled = false } = item;

  if (items && items.length > 0) {
    // Recurse deeper
    return (
      <NestedMenuItem
        key={label}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        label={label}
        parentMenuOpen={isOpen}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: TODO: fix later
        sx={sx}
        disabled={disabled}
      >
        {/* Call this function to nest more items */}
        {nestedMenuItemsFromObject({
          menuItemsData: items,
          isOpen,
          handleClose,
        })}
      </NestedMenuItem>
    );
  }

  // No children elements, return MenuItem
  return (
    <IconMenuItem
      key={label}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      label={label}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: TODO: fix later
      onClick={() => {
        handleClose();
        if (callback) {
          callback();
        }
      }}
      sx={sx}
      disabled={disabled}
    />
  );
});

interface NestedDropdownProps {
  menuItemsData: {
    label?: string;
    items?: MenuItemData[];
  };
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  ButtonProps?: React.ComponentProps<typeof StyledButton>;
  MenuProps?: React.ComponentProps<typeof Menu>;
}

const NestedDropdown = forwardRef<HTMLDivElement, NestedDropdownProps>((props, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    menuItemsData: data,
    onClick,
    ButtonProps,
    MenuProps,
    ...rest
  } = props;

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    if (onClick) {
      onClick(e);
    }
  };

  const handleClose = () => setAnchorEl(null);

  const menuItems = nestedMenuItemsFromObject({
    menuItemsData: data?.items ?? [],
    isOpen: open,
    handleClose,
  });

  return (
    <div ref={ref} {...rest}>
      <StyledButton
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        disableElevation
        disableFocusRipple
        disableRipple
        {...ButtonProps}
      >
        {data?.label ?? 'Menu'}
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { minWidth: '172px' } }}
        {...MenuProps}
      >
        {menuItems}
      </Menu>
    </div>
  );
});

export default NestedDropdown;
