import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { calWeekDaysHeaderStyles } from '../../styles';

const useStyles = makeStyles()(calWeekDaysHeaderStyles as Record<string, any>);

interface CalWeekDaysHeaderProps {
  days: string[];
}

const CalWeekDaysHeader:React.FC<CalWeekDaysHeaderProps> = (props) => {
  const { classes } = useStyles();

  return (
    <header className={classes.weekDays}>
      {props.days.map((weekday) => (
        <strong key={weekday} className={classes.dayLabel}>{weekday}</strong>
      ))}
    </header>
  );
};

export default CalWeekDaysHeader;
