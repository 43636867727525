import styled from 'styled-components';

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: left;
  padding-left: 24px;
  align-content: center;
  flex-direction: column;
  margin-top: 3%;
  margin-bottom: 9%
`;

export default LoadingSpinner;
