export const styles = ({ palette }) => ({
  dialogTitleWrapper: {
    padding: '16px 0',
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 16,
  },
  dialogContent: {
    padding: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  formWrapper: {
    margin: '16px 0',
    display: 'flex',
    gap: 16,
    '& div': {
      flex: 1,
    },
  },
  alert: {
    backgroundColor: palette.polar,
    color: palette.koalaGrey,
    marginTop: 24,
    fontSize: 12,
  },
  dialogAction: {
    padding: '24px 0',
    gap: 16,
  },
  contents: {
    textAlign: 'center',
    borderTop: `1px solid ${palette.silverGrey}`, 
  },
  sentImg: {
    margin: '16px 0 48px',
  },
  weight400: {
    fontWeight: 400,
  },
  title: {
    color: palette.shadowGrey,
  },
  textTertiary: {
    color: palette.koalaGrey,
  },
  confirmationContents: {
    padding: '30px 0',
    borderBottom: `1px solid ${palette.silverGrey}`, 
  },
});
