import styled from 'styled-components';
import { MyBlueButton, CloseBox } from '../StyledComponents/HomePageAll';
import { applyOpacityToHex } from '../../themes/themeUtils';

export const ModalDocDiv = styled.div`
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DocSubmitButton = styled(MyBlueButton)`
  margin-top: 15px;
`;

export const DropzoneStyled = styled.div`
  flex: 0;
  &.custom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: inherit;
    width: 121px;
    height: 35px;
    border-radius: 4px;
    box-shadow: 0 0 6px 0 ${({ theme }) => applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.15)};
    overflow: hidden;
    cursor: pointer;
  }
  &:focus-within {
    background: ${({ theme }) => theme.palette.greyScaleDeprecated[7]};
    text-decoration: underline;
  }
`;
export const HiddenInput = styled.input`
  position: fixed;
  height: 2px;
  width: 2px;
  opacity: 0;
`;
export const UploadFileList = styled.ul`
  list-style-type: none;
  padding-left: 0px;
  line-height: 20px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 16px;
  li {
    cursor: pointer;
    display: inline-block;
    margin-right: 8px;
    border: solid 1px ${({ theme }) => theme.palette.greyScaleDeprecated[5]};
  }
  li:hover {
    border: solid 1px ${({ theme }) => theme.palette.greyScaleDeprecated[3]};
  }
`;

export const FileName = styled.p`
  display: inline;
  line-height: inherit;
  margin-left: 5px;
`;

export const DelFile = styled(CloseBox)`
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  height: 15px;
  width: 15px;
  display: inline-block;
  vertical-align: center;
  margin: auto;
  float: left;
  padding: 2px 2px 2px 4px;
  font-size: 10px;
  line-height: 11px;
`;
