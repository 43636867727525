/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-foreign-prop-types */

import _ from 'lodash';

import LayoutComponentsConfig from './ComponentConfigs/layout';
import InputsComponentsConfig from './ComponentConfigs/inputs';
import NavigationComponentsConfig from './ComponentConfigs/navigation';
import SurfacesComponentsConfig from './ComponentConfigs/surfaces';
import FeedbackComponentsConfig from './ComponentConfigs/feedback';
import DataDisplayComponentsConfig from './ComponentConfigs/datadisplay';
import OtherComponentsConfig from './ComponentConfigs/other';
import DialogsConfig from './ComponentConfigs/dialogs';

import utilitiesConfig from './utilitiesConfig';

import { THEME_WRAPPER_KEY } from './Wrappers/themeDocs';
import { ASSETS_WRAPPER_KEY } from './Wrappers/assetsDocs';
import { INFO_WRAPPER_KEY } from './Wrappers/Info';

const componentsConfig = {
  name: 'Components',
  id: 'COMPONENTS',
  children: [
    LayoutComponentsConfig,
    InputsComponentsConfig,
    NavigationComponentsConfig,
    SurfacesComponentsConfig,
    FeedbackComponentsConfig,
    DataDisplayComponentsConfig,
    OtherComponentsConfig,
    DialogsConfig,
  ],
};

function getNestedIdsAsList(children) {
  return _.flatten(children.map((c) => c.children ? getNestedIdsAsList(c.children) : c.id));
}

export const LIST_OF_COMPONENT_IDS = getNestedIdsAsList(componentsConfig.children);

// WARNING: any config item that is not a component MUST have a defined "type" property (components can have type "COMPONENT", but not necessary)
//    - supported types (not including INFO, ASSETS, THEME) are as follows: "FUNCTION" & "CONSTANT"
export const configChildren = [
  {
    name: 'Info',
    id: 'INFO',
    type: INFO_WRAPPER_KEY,
  },
  {
    name: 'Theme',
    id: 'THEME',
    type: THEME_WRAPPER_KEY,
  },
  {
    name: 'Assets',
    id: 'ASSETS',
    type: ASSETS_WRAPPER_KEY,
  },
  utilitiesConfig,
  componentsConfig,
];
