import { call, select, fork, race, take, put, takeEvery, takeLeading, delay } from 'redux-saga/effects';

import { tagsActions } from 'companion-app-components/flux/tags';
import { authSelectors } from 'companion-app-components/flux/auth';
import { coreActions } from 'companion-app-components/flux/core-actions';
import { datasetsActions } from 'companion-app-components/flux/datasets';
import { accountsActions } from 'companion-app-components/flux/accounts';
import { categoriesActions } from 'companion-app-components/flux/categories';
import { transactionsActions } from 'companion-app-components/flux/transactions';
import { budgetItemsActions } from 'companion-app-components/flux/budget-items';
import { getBudgetsAction } from 'companion-app-components/flux/budgets/budgetsActions';
import { getRenameRules } from 'companion-app-components/flux/rename-rules/renameRulesActions';
import { getInvestmentHoldings } from 'companion-app-components/flux/investmentHoldings/actions';
import { checkIfNPSSurveyRequestedAction } from 'companion-app-components/flux/nps/npsActions';
import { scheduledTransactionsActions } from 'companion-app-components/flux/scheduled-transactions';
import { getEnvironmentConfig, getLogger, localPreferences } from 'companion-app-components/utils/core';
import { featureFlagsActions, featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { getPreferences } from 'companion-app-components/flux/preferences/actions';
import { getCategoryGroupsAction } from 'companion-app-components/flux/category-groups/categoryGroupsActions';
import { getCategoryGroupListsAction } from 'companion-app-components/flux/category-group-lists/categoryGroupListsActions';
import * as sharesActions from 'companion-app-components/flux/dataset-shares/datasetSharesActions';
import { entitlementsActions } from 'companion-app-components/flux/entitlements';
import * as userInfoActions from 'companion-app-components/flux/user-info/userInfoActions';
import { getDocuments } from 'companion-app-components/flux/documents/documentsActions';
import { getPreferencesV2 } from 'companion-app-components/flux/preferences-v2/preferencesV2Actions';
import { getMemorizedRules } from 'companion-app-components/flux/memorized-rules/memorizedRulesActions';


import { clearBalancesCache } from 'data/accountBalances/balCache';
import { createJobStatus, deleteJobStatus, createJobStatusSuccess, createJobStatusFailure } from 'data/jobStatus/actions';
import { getSubscriptions } from 'data/subscriptions/subscriptionsActions';
import { getInstitutionLogins, refreshAccounts, refreshAccountsCompleted } from 'data/institutionLogins/actions';
import { getInvestmentAccounts } from 'data/accounts/actions';
import { initializeFeatureFlags } from 'utils/featureFlags/initialize';
import { setAwaitingToAutoRefresh } from 'data/app/actions';
import { mcRulesExecute } from 'data/mcRules/mcRulesActions';
import { checkIfSyncSentimentSurveyRequestedAction } from 'data/syncSentiment/syncSentimentActions';
import { navigate } from 'NavigateSetter';

import AxiosFactory from 'utils/axiosFactory';

import * as actions from './actions';

const qcsAxios = AxiosFactory.get('qcs');
const log = getLogger('data/app/sagas.js');

function* appHistoryPush(action) {
  const { payload: { location } } = action;

  yield put(navigate(location));
}

function* appHistoryReplace(action) {
  const { payload: { location } } = action;

  yield put(navigate(location, { replace: true }));
}

function* fetchUserLevelData() {
  log.log('fetch data (user level)...');

  yield put(getSubscriptions());
  yield put(entitlementsActions.getEntitlements());
  yield put(datasetsActions.getDatasets());
  yield put(getPreferences({ section: 'shared', group: 'user' }));
  yield put(sharesActions.getDatasetShares());
}

function* fetchData() {
  log.log('fetch data (common)...');

  yield call(clearBalancesCache);

  yield put(getPreferencesV2());
  yield put(getPreferences({ section: 'shared', group: 'dataset' }));
  yield put(getInstitutionLogins());
  yield put(accountsActions.getAccounts());
  yield put(tagsActions.getTags());
  yield put(categoriesActions.getCategories());
  yield put(getDocuments());
  yield put(transactionsActions.getTransactions());
}

function* fetchFeaturedData() {
  log.log('fetch data (featured)...');

  const featureFlags = yield select(featureFlagsSelectors.getFeatureFlags);

  yield put(getInvestmentAccounts());
  yield put(getInvestmentHoldings());

  if (featureFlags.get('nps')) {
    yield put(checkIfNPSSurveyRequestedAction());
  }

  if (featureFlags.get('syncSentiment')) {
    yield put(checkIfSyncSentimentSurveyRequestedAction());
  }

  if (featureFlags.get('mcp')) {
    yield put(mcRulesExecute());
  }
  if (featureFlags.get('budgets')) {
    yield put(getBudgetsAction());
    yield put(budgetItemsActions.getBudgetItemsAction());
    yield put(getCategoryGroupsAction());
    yield put(getCategoryGroupListsAction());
  }
  if (featureFlags.get('scheduledTransactions') || featureFlags.get('scheduledTransactionsInRegister')) {
    yield put(scheduledTransactionsActions.getScheduledTransactions());
  }
  if (featureFlags.get('showMemorizedTransactionsSettings') || featureFlags.get('rules')) {
    yield put(getMemorizedRules());
  }
  if (featureFlags.get('showRenameRulesSettings') || featureFlags.get('rules')) {
    yield put(getRenameRules());
  }
}

function* startRefreshAccounts(payload) {
  log.log('accounts refresh triggered...');

  yield put(refreshAccounts(payload));

  const datasetId = yield select(authSelectors.getDatasetId);
  const now = Date.now();
  localPreferences.setItem(`${datasetId}_last_auto_refresh_time`, now.toString());
}

function* appRefreshAll(action) {
  log.info('refresh (start)->');

  const authTokenValid = yield select(authSelectors.isAuthTokenValid);
  if (!authTokenValid) {
    log.warn('refresh (invalid auth token) X');
  } else {
    // fetch user level data
    yield call(fetchUserLevelData);

    const datasetId = yield select(authSelectors.getDatasetId);
    if (datasetId) {
      yield put(coreActions.setUpdateAllState({
        updateAllFailed: null,
        updateAllMessages: null,
      }));

      // if create job status fails, don't retry just continue loading
      yield put(createJobStatus({ type: 'SYNC', datasetId }, { attemptsLeft: 0 }));
      const { createJobStatusSuccessAction } = yield race({
        createJobStatusSuccessAction: take(createJobStatusSuccess),
        createJobStatusFailureAction: take(createJobStatusFailure),
      });

      // fetch data
      yield call(fetchData);

      // fetch featured data
      const featureFlagsLoadPending = yield select(featureFlagsSelectors.getLoadPending);
      if (!featureFlagsLoadPending) {
        yield call(fetchFeaturedData);
      } else {
        const featureFlagsIsLoading = yield select(featureFlagsSelectors.getIsLoading);
        if (!featureFlagsIsLoading) {
          yield call(initializeFeatureFlags);
        }
        yield fork(function* waitForFlags() {
          yield race([
            take(featureFlagsActions.getFeatureFlagsSuccess),
            take(featureFlagsActions.getFeatureFlagsFailure),
          ]);
          yield call(fetchFeaturedData);
        });
      }

      // trigger fi refresh
      let refreshStarted;
      if (action.payload?.context === 'init') {
        const lastRefreshTimeStr = localPreferences.getItem(`${datasetId}_last_auto_refresh_time`);
        const lastRefreshTime = lastRefreshTimeStr ? parseInt(lastRefreshTimeStr, 10) : 0;
        const timeOkToAutoRefresh = lastRefreshTime + (60 * 60 * 1000);
        const now = Date.now();
        if (now >= timeOkToAutoRefresh) {
          // we are delaying just a bit so user doesn't get a Credential Required or MFA dialog immediately upon login
          yield put(setAwaitingToAutoRefresh(true));
          yield delay(5000);
          yield call(startRefreshAccounts, { autoRefresh: true });
          refreshStarted = true;
        }
      } else {
        yield call(startRefreshAccounts);
        refreshStarted = true;
      }
      if (refreshStarted) {
        yield take(refreshAccountsCompleted);
        yield put(setAwaitingToAutoRefresh(false));
      }

      // even if create job status fails, need to still delete job status (for reducer benefit)
      yield put(deleteJobStatus({ id: createJobStatusSuccessAction?.payload?.id })); // it's a hack - refresh is complete, but fetch still is in progress
      yield put(datasetsActions.getDatasets()); // another hack to ensure that lastSyncedAt is up to date
      yield put(userInfoActions.getUserInfo());
    }
  }

  log.info('refresh (complete)<-');
}

// TODO: switch to web socket - and remove method below
function* fetchRefreshedData() {
  log.log('fetch data (refreshed)...');
  clearBalancesCache();

  yield put(getInstitutionLogins());
  yield put(accountsActions.getAccounts());
  yield put(transactionsActions.getTransactions());

  const featureFlags = yield select(featureFlagsSelectors.getFeatureFlags);
  if (featureFlags.get('budgets')) {
    yield put(budgetItemsActions.getBudgetItemsAction());
  }
  yield put(getInvestmentAccounts());
  yield put(getInvestmentHoldings());
  if (featureFlags.get('scheduledTransactions') || featureFlags.get('scheduledTransactionsInRegister')) {
    yield put(scheduledTransactionsActions.getScheduledTransactions());
  }
  // QCS doesn't support incremental updates for IRR and TWR now - not providing query params startOn/endOn/accountIds would cause http500
  // if (featureFlags.get('investment-performance')) {
  //   yield put(getInvestmentPerformance());
  //   yield put(getInvestmentIRR());
  // }
}

// =================================================================================================
// upsertPushNotificationToken
// =================================================================================================

function* upsertPushNotificationToken({ payload: token }: { payload: any }): Generator<*, *, *> {
  log.info('upsertPushNotificationToken called...', token);

  const datasetId = yield select(authSelectors.getDatasetId);

  const requestUrl = `${getEnvironmentConfig().services_url}/messenger/push-token`;
  const requestData = {
    datasetId,
    tokenType: 'FCM',
    pushToken: token,
    devicePlatform: 'web',
  };

  try {
    const response = yield call(qcsAxios.post, requestUrl, requestData);
    log.info('upsertPushNotificationToken response...', response);

    // yield put(actions.searchForInstitutionsSuccess(institutions));
  } catch (error) {
    log.error('upsertPushNotificationToken error...', error);
    // yield put(actions.searchForInstitutionsFailure(error));
  }
}

// =============================================================================
// Watchers
// =============================================================================

function* appHistoryPushActionWatcher() {
  yield takeEvery(actions.appHistoryPush, appHistoryPush);
}

function* appHistoryReplaceActionWatcher() {
  yield takeEvery(actions.appHistoryReplace, appHistoryReplace);
}

function* appRefreshAllActionWatcher() {
  yield takeLeading(actions.appRefreshAll, appRefreshAll);
}

function* refreshAccountsCompletedActionWatcher() {
  yield takeLeading(refreshAccountsCompleted, fetchRefreshedData);
}

function* upsertPushNotificationTokenWatcher() {
  yield takeLeading(actions.appSubmitPushNotificationToken, upsertPushNotificationToken);
}

export default [
  appHistoryPushActionWatcher,
  appHistoryReplaceActionWatcher,
  appRefreshAllActionWatcher,
  refreshAccountsCompletedActionWatcher,
  upsertPushNotificationTokenWatcher,
];
