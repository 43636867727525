import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { useTheme } from '@mui/material/styles';

import RootState from 'companion-app-components/utils/redux-store/rootState';
import { chartOfAccountsUtils, chartOfAccountsTypes } from 'companion-app-components/flux/chart-of-accounts';
import { getChartOfAccountNodesById } from 'companion-app-components/flux/chart-of-accounts/chartOfAccountsSelectors';

import QTip from 'components/QuickenControls/QTip';
import QTypography from 'components/MUIWrappers/QTypography';
import CategoryFieldEditable from './CategoryFieldEditable';
import { getDisplayName, isTransfer } from './helpers';

const STR_NOT_FOUND = 'NOTFOUND';

interface CategoryFieldProps {
  variant: string;
  editable: boolean;
  value: chartOfAccountsTypes.ChartOfAccountProps;
  name: string;
  className: string;
  clickable: boolean;
  returnSelectionMethod: boolean;
  longCats: boolean;
  allowBlank: boolean;
  onlyL1: boolean;
  classNameWhenNotEditable: string;
  tooltip: boolean; // show a tooltip on static value?
  fontSize: string;
  disableTooltip: boolean;
  noWrap: boolean;
}

const CategoryField: FC<CategoryFieldProps> = (props) => {
  const theme: Record<string, any> = useTheme();
  const {
    editable,
    clickable,
    classNameWhenNotEditable,
    name,
    noWrap,
    variant = 'body2',
    disableTooltip = false,
  } = props;
  const coaNodesById = useSelector((state: RootState) => getChartOfAccountNodesById(state, props));

  const getCoaNode = useCallback((coaId: string) => {
    let ret = coaNodesById ? coaNodesById.get(coaId) : null;
  
    if (chartOfAccountsUtils.isTransferCoaNotFound(props.value)) {
      ret = coaNodesById.get(chartOfAccountsUtils.TRANSFER_NOT_FOUND_COA_ID);
    }
    return ret;
  }, [coaNodesById, props.value]);
    
  const makeTransferName = useCallback((val?: string | null) =>
    `${theme.components.register.transferDelimeter1}${val}${theme.components.register.transferDelimeter2}`, [theme]);

  const coaId = chartOfAccountsUtils.getCoaId(props.value, props.allowBlank); // allow blank
  const coaNode = getCoaNode(coaId);

  const [state, setState] = useState({
    tfValue: coaId === 'BLANK' ? '' : getDisplayName(STR_NOT_FOUND, props.longCats, coaNode),
    isTransfer: isTransfer(coaNode),
  });

  const toolTipTitle = state.isTransfer ? makeTransferName(state.tfValue) : state.tfValue;
  const Wrap = disableTooltip ? React.Fragment : QTip;
  const wrapProps = disableTooltip ? {} : { wrapOnly: true, title: props.tooltip ? toolTipTitle : null };

  const prevValue = usePrevious(props.value);
  const prevCoaNodesById = usePrevious(coaNodesById);
  const prevLongCats = usePrevious(props.longCats);

  useEffect(() => {
    if (!coaNodesById) { return; }

    if (props.value !== prevValue
      || coaNodesById !== prevCoaNodesById
      || props.longCats !== prevLongCats) {

      const newCoaId = chartOfAccountsUtils.getCoaId(props.value, props.allowBlank);
      const newCoaNode = getCoaNode(newCoaId);
      // TODO: if coaNode cannot be found, set it to uncategorized coaNode
      if (newCoaNode) {
        setState({
          isTransfer: isTransfer(newCoaNode),
          tfValue: getDisplayName(STR_NOT_FOUND, props.longCats, newCoaNode),
        });
      } else {
        setState({
          isTransfer: false,
          tfValue: '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coaNodesById, prevCoaNodesById, prevValue, prevLongCats, props]);

  return (
    <>
      {editable && (
        <CategoryFieldEditable
          sharedcomponentid="CATEGORY_FIELD"
          coaNodesById={coaNodesById}
          {...props}
        />
      )}

      {!editable &&
        <Wrap
          {...wrapProps}
        >
          <QTypography
            variant={variant}
            clickable={clickable}
            className={classNameWhenNotEditable}
            sharedcomponentid="CATEGORY_FIELD"
            name={name}
            noWrap={noWrap}
          >
            {state.isTransfer ? makeTransferName(state.tfValue) : state.tfValue}
          </QTypography>
        </Wrap>}
    </>
  );
};

export default memo(CategoryField);
