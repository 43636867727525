export const PAST_DAYS_NUMBER = 10;

export const TODAY_INDEX = PAST_DAYS_NUMBER;

export const TYPE_VALUES = {
  seven: 7,
  fourteen: 14,
  thirty: 30,
  fortyFive: 45,
  sixty: 60,
  ninety: 90,
  oneEighty: 180,
};

export const RANGE_INDICES = [17, 24, 40, 55, 70, 100, 190];
