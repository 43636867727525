import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

import { getEnvironmentConfig, getHostConfig, crashReporterInterface, getLogger, tracker, localPreferences } from 'companion-app-components/utils/core';
import { authActions, authSelectors } from 'companion-app-components/flux/auth';

import { isSanitized, getIsOffline } from 'data/app/selectors';
import store from 'store';
import * as sessionStorageEx from 'utils/sessionStorageEx';

const log = getLogger('utils/axiosFactory.js');

const AxiosFactory = {
  registeredAxiosInstances: new Map(),

  register(axiosInstanceName, axiosInstance) {
    if (!AxiosFactory.registeredAxiosInstances.has(axiosInstanceName)) {
      AxiosFactory.registeredAxiosInstances.set(axiosInstanceName, axiosInstance);
    } else {
      log.error('This axios instance has already been registered ', axiosInstance);
    }
  },

  get(axiosInstanceName) {
    return AxiosFactory.registeredAxiosInstances.get(axiosInstanceName);
  },

  unregister(axiosInstanceName) {
    log.log(`AxiosFactory.unregister(${axiosInstanceName})`);
    if (AxiosFactory.registeredAxiosInstances.has(axiosInstanceName)) {
      delete AxiosFactory.registeredAxiosInstances[axiosInstanceName];
    }
  },
};

// /////////////////////////////////////////////////////////////////////////////
// Setup Axios Instance for QCS
// /////////////////////////////////////////////////////////////////////////////

const qcsAxiosInstance = Axios.create();
export const cancelTokenSource = Axios.CancelToken.source();

qcsAxiosInstance.defaults.cancelToken = cancelTokenSource.token;
// eslint-disable-next-line camelcase
qcsAxiosInstance.defaults.headers.common['app-client-id'] = getEnvironmentConfig().client_id;
qcsAxiosInstance.defaults.headers.common['app-release'] = process?.env?.APP_VERSION;
qcsAxiosInstance.defaults.headers.common['app-build'] = process?.env?.BUILD_NUMBER;
qcsAxiosInstance.defaults.headers.patch['Content-Type'] = 'application/json;charset=UTF-8';
qcsAxiosInstance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
qcsAxiosInstance.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8';

qcsAxiosInstance.interceptors.request.use(
  (config) => {
    let configOrPromise = config;

    if (config.cancelToken && config.cancelToken.throwIfRequested) {
      config.cancelToken.throwIfRequested();
    }

    if ((getIsOffline(store.getState()) || !navigator.onLine) && localPreferences.getOfflineSupport()) {
      return Promise.reject(Error('offline'));
    }

    // Ensure request headers are set correctly
    configOrPromise.headers['client-tid'] = configOrPromise.headers['client-tid'] || uuidv4().toUpperCase();

    configOrPromise = handleAuthorization(configOrPromise);

    return configOrPromise;
  },
  (error) => {
    log.info('qcsAxiosInstance.interceptors.request.use ERROR');
    return Promise.reject(error);
  }
);

qcsAxiosInstance.interceptors.response.use(
  (response) => {
    // log.debug('Axios Response:', response);

    const itemWithError = response.data && response.data.items && response.data.items.find((item) => item.errors && item.errors.length > 0);
    if (itemWithError) {
      const error = itemWithError.errors[0];
      const url = new URL(response.config && response.config.url);
      const tag = url && url.pathname && url.pathname.replace(/\/\d+(?=\/|$)/g, '/xxx');
      tracker.track(tracker.events.connectivity, {
        status: 'error',
        method: response.config && response.config.method && response.config.method.toUpperCase(),
        host: url && url.hostname,
        path: tag,
        tag,
        description: error.title,
        error_code: error.httpStatus,
        error_sub_code: error.code,
        details: error.title,
        request_size: response.config && response.config.data && response.config.data.length,
        response_size: response.request && response.request.response && response.request.response.length,
      });
      trackPayload(error.title, response.config, response, `${error.httpStatus}:${error.code}`);
    }

    requestAttempts.delete(response.config.headers['client-tid']);

    return response;
  },
  (error) => {
    log.warn('Axios ERROR: ', error, JSON.stringify(error, null, 2));

    assert(error.message !== "Cannot read property 'protocol' of undefined", `bug trap: ${error.message}\n${error}`); // bug trap

    const url = error.config && error.config.url && new URL(error.config.url);
    const tag = url && url.pathname && url.pathname.replace(/\/\d+(?=\/|$)/g, '/xxx');
    tracker.track(tracker.events.connectivity, {
      status: 'error',
      method: error.config && error.config.method && error.config.method.toUpperCase(),
      host: url && url.hostname,
      path: tag,
      tag,
      description: error.message,
      error_code: error.response?.status || error.message,
      error_sub_code: error.response?.data?.errors?.[0]?.code,
      details: error.response?.data?.errors?.[0]?.detail || error.response?.data?.errors?.[0]?.title,
      request_size: error.config && error.config.data && error.config.data.length,
      response_size: error.request && error.request.response && error.request.response.length,
    });
    trackPayload(error.message, error.config, error.response, `${error.response?.status || error.message}:${error.response?.data?.errors?.[0]?.code || error.code}`);

    if (error.response && error.response.data) {
      const qcsErrors = error.response.data.errors;
      if (qcsErrors) {
        log.warn(`Received QCS application level errors, errors=${JSON.stringify(qcsErrors)}`);

        // check if we have an authorization failure (will only have a single error if authentication fails)
        if (qcsErrors.length === 1) {
          const qcsError = qcsErrors[0];

          log.warn(`QcsError: [httpStatus=${qcsError.httpStatus}, code=${qcsError.code}, detail='${qcsError.detail}']`);
          if (qcsError.httpStatus === 401) {
            if (qcsError.code === 'QCS-0401-2') {
              const authSession = authSelectors.getAuthSession(store.getState());
              if (authSession && !isRefreshTokenExpired(authSession) && authSession.refreshToken) {
                const attempts = requestAttempts.get(error.config.headers['client-tid']);
                if (attempts < 2) {
                  doRefreshToken(authSession.refreshToken);
                  return retryRequestAfterRefreshingQcsToken(error.config);
                }
              }
            } else if (qcsError.code === 'QCS-0401-7') { // QCS Unauthorized Access - Username requires verification for community access
              return error.response;
            }
            // on any other 401 (besides token expired, redirect to login)
            log.log('Service returned a non-recoverable 401');

            if (qcsError.code === 'QCS-0401-5' // Trying to access a deleted dataset.
              || qcsError.code === 'QCS-0401-6' // "QCS Unauthorized Access - Entity was not found [name=Dataset, id=188911040088047616, parameter=id]"
            ) {
              // The dataset being operated on has been deleted.  Panic!
              /* eslint-disable no-alert */
              // verify(false, 'This dataset has been reset or deleted, you will need to login again.');
              store.dispatch(authActions.authLogout({ reason: 'AUTH_LOGOUT_UNAUTHORIZED' }));
              return error.response;
            }

            // assert(false, `response auth error - force logout\n${error.config?.url}`);
            // store.dispatch(authActions.authLogout({ reason: 'AUTH_LOGOUT_UNAUTHORIZED' }));
            store.dispatch(authActions.invalidateAccessToken(undefined, { context: 'axios 401' }));

          } else if (qcsError.httpStatus === 400) {
            if (qcsError.code === 'QCS-0400-19') {
              log.error('NO VALID DATASET ID RESPONSE - FORCE USER LOGIN');
              assert(false, `QCS-0400-19 - NO NO NO!!!\n${error.config?.url}`);
              store.dispatch(authActions.authSelectDataset({ dataset: null, reload: false, location: '/' }));
            }
          }
        }
      } else if (error.response.data.error) {
        log.log(`Received SpringBoot level error, error=${error.response.data.error}`);
        if (error.response.data.status === 404) {
          /* eslint-disable no-param-reassign */
          error.response.data.extra = 'Please try again. If this problem persists please contact QCS Engineering.';
        }
      } else if (Math.trunc(error.response.status / 100) === 4 || Math.trunc(error.response.status) / 100 === 5) {
        log.debug(`Received generic service level error, error=${error.response.status}`);
        error.response.data = {
          status: error.response.status,
          error: `Generic service error, error=${error.response.status}`,
          message: `Generic service error, error=${error.response.status}`,
        };
      } else {
        log.warn(`Unexpected service error, error==${error}`);
        const status = error.response.status || 500;
        error.response.data = {
          status,
          error: `Unexpected service error, error=${status}`,
          message: `Unexpected service error, error=${status}`,
        };
      }
    } else if (error.response) {
      const status = error.response.status || 500;
      log.warn(`Received generic service level error, status=${status}`);
      error.response.data = { status, error: `Generic service error (${status}). Please try again. If this problem persists please contact Quicken support` };
    } else if (error.message && error.message === 'Network Error') {
      log.warn('Found network error');
      if (error.response) {
        error.response.data = { status: 500, error: 'Network Error (500). Please try again.' };
      }
    } else {
      log.warn(`Unexpected error response, error=${error}`);
    }

    if (error && error.config && error.config.headers) {
      requestAttempts.delete(error.config.headers['client-tid']);
    }

    return Promise.reject(error);
  }
);

AxiosFactory.register('qcs', qcsAxiosInstance);

export default AxiosFactory;

// /////////////////////////////////////////////////////////////////////////////
// QCS Refresh Token Helpers
// /////////////////////////////////////////////////////////////////////////////

let queuedQcsRequestsToRetry = [];
const requestAttempts = new Map();

const handleAuthorization = (config) => {
  let configOrPromise = config;

  if (isSanitized(store.getState()) || sessionStorageEx.getSandbox()) {
    return Promise.reject(Error('sandbox'));
  }

  if (config.cancelToken && config.cancelToken.throwIfRequested) {
    config.cancelToken.throwIfRequested();
  }

  const attempts = requestAttempts.get(configOrPromise.headers['client-tid']) || 0;
  requestAttempts.set(configOrPromise.headers['client-tid'], attempts + 1);

  // use Authorization = null if the header is not required
  if (typeof configOrPromise.headers.Authorization === 'object') { // null or {...}
    delete configOrPromise.headers.Authorization;
  } else {
    const authSession = authSelectors.getAuthSession(store.getState());
    if (authSession && authSession.accessToken && !isAccessTokenExpired(authSession)) {
      configOrPromise.headers.Authorization = `Bearer ${authSession.accessToken}`; // eslint-disable-line no-param-reassign
      // we enforce qcs-dataset-id header to be always defined (qcs-dataset-id = null if the header is not required)
      if (typeof configOrPromise.headers['qcs-dataset-id'] === 'object') { // null or {...}
        delete configOrPromise.headers['qcs-dataset-id'];
      } else if (!configOrPromise.headers['qcs-dataset-id']) {
        if (authSession.datasetId) {
          configOrPromise.headers['qcs-dataset-id'] = authSession.datasetId;
        } else {
          log.error('NO DATASET ID IN AUTH SESSION');
          trackError(config, 'no dataset id');

          assert(false, `unauthorized request: no qcs-dataset-id \n${configOrPromise.method} ${configOrPromise.url?.replace(/\/\\d+(?=\/|$)/g, '/xxx')}`);

          configOrPromise = Promise.reject(Error(`No dataset id found in DB. ${configOrPromise.method} ${configOrPromise.url?.replace(/\/\\d+(?=\/|$)/g, '/xxx')}`));

          // TODO: trigger select dataset UI instead of logout
        }
      }
    } else if (authSession && !isRefreshTokenExpired(authSession) && authSession.refreshToken) { // use refresh token
      doRefreshToken(authSession.refreshToken);
      configOrPromise = resumeRequestAfterRefreshingQcsToken(configOrPromise);
    } else { // force user to login again
      log.log('NO VALID AUTH TOKENS - FORCE USER TO SIGN-IN');
      trackError(config, 'no valid auth');

      // assert(false, `auth error - force logout\n${config?.url}`);
      configOrPromise = Promise.reject(Error('valid auth tokens not found locally'));
      // store.dispatch(authActions.authLogout({ reason: 'AUTH_LOGOUT_UNAUTHORIZED' }));
    }
  }

  return configOrPromise;
};

function isRefreshTokenExpired(authSession) {
  if (!authSession.refreshTokenExpired) {
    return false;
  }
  const expiredTime = DateTime.fromISO(authSession.refreshTokenExpired);
  return expiredTime <= DateTime.local();
}

function isAccessTokenExpired(authSession) {
  // cannot tell if we don't have an expiration date so just return false
  if (!authSession.accessTokenExpired) {
    return false;
  }
  const expiredTime = DateTime.fromISO(authSession.accessTokenExpired);
  return expiredTime <= DateTime.local();
}

function doRefreshToken(refreshToken) {
  log.info('Refreshing token');
  new Promise((resolve, reject) => store.dispatch(authActions.exchangeRefreshToken({
    grantType: 'refreshToken',
    responseType: 'token',
    redirectUri: `${getHostConfig().redirect_uri}`,
    clientId: `${getEnvironmentConfig().client_id}`,
    clientSecret: `${getEnvironmentConfig().client_secret}`,
    refreshToken,
  }, {
    resolve,
    reject,
    axiosConfig: {
      headers: {
        Authorization: null,
      },
    },
  })))
    .then((refreshTokenResponse) => afterTokenRefreshSucceeds(refreshTokenResponse))
    .catch((refreshTokenError) => afterTokenRefreshFails(refreshTokenError));
}

function resumeRequestAfterRefreshingQcsToken(config) {
  const resumeRequestPromise = new Promise((resumeRequestResolve, resumeRequestReject) => {
    const refreshSuccessCallback = (_refreshTokenResponse) => resumeRequestResolve(handleAuthorization(config));
    const refreshFailureCallback = (refreshTokenError) => resumeRequestReject(refreshTokenError);
    queuedQcsRequestsToRetry.push([refreshSuccessCallback, refreshFailureCallback]);
  });

  return resumeRequestPromise;
}

function retryRequestAfterRefreshingQcsToken(config) {
  const retryRequest = new Promise((retryResolve, retryReject) => {
    const retrySuccessCallback = (_refreshTokenResponse) => {
      qcsAxiosInstance.request(config).then((retryResponse) => {
        retryResolve(retryResponse);
      }).catch((retryError) => {
        retryReject(retryError);
      });
    };
    const retryFailureCallback = (refreshTokenError) => {
      retryReject(refreshTokenError);
    };
    queuedQcsRequestsToRetry.push([retrySuccessCallback, retryFailureCallback]);
  });
  return retryRequest;
}

function clearQueuedQcsReqestsToRetry() {
  queuedQcsRequestsToRetry = [];
}

function afterTokenRefreshSucceeds(refreshTokenResponse) {
  log.info('Refreshing token - succeed');

  queuedQcsRequestsToRetry.map((callbacks) => callbacks[0](refreshTokenResponse));
  clearQueuedQcsReqestsToRetry();
}

function afterTokenRefreshFails(error) {
  log.error(`Refreshing token - failed, error=${error}`);

  clearQueuedQcsReqestsToRetry();

  // assert(false, `refresh token failure - force logout\n${refreshTokenError?.config?.url}`);
  // store.dispatch(authActions.authLogout({ reason: 'AUTH_LOGOUT_UNAUTHORIZED' }));
  if ([400, 401, 406, 500].includes(error?.response?.status)) {
    store.dispatch(authActions.invalidateRefreshToken(undefined, { context: 'axios refresh failed' }));
  }
}

const trackError = (config, description) => {
  const url = new URL(config.url);
  const tag = url?.pathname?.replace(/\/\d+(?=\/|$)/g, '/xxx');
  tracker.track(tracker.events.connectivity, {
    status: 'error',
    method: config.method && config.method.toUpperCase(),
    host: url && url.hostname,
    path: tag,
    tag,
    description,
    details: description,
    error_code: description,
    error_sub_code: description,
  });
};

export const sanitizeData = (data) => {
  let dataSanitized = data;
  if (typeof dataSanitized === 'string') {
    dataSanitized = dataSanitized.replace(/"credentials"\s*:\s*\[.*?\]/gs, (credentials) => credentials.replace(/(\{.*?"value"\s*:\s*")(.*?)("\s*\},?)/gs, '$1***$3'));
    dataSanitized = dataSanitized.replace(/("refreshToken"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("accessToken"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("refresh_token"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("access_token"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("username"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("firstName"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("middleName"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("lastName"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("primaryAddress"\s*:\s*\{)(.*?)(\})/gs, '$1$3');
    dataSanitized = dataSanitized.replace(/("primaryPhone"\s*:\s*\{)(.*?)(\})/gs, '$1$3');
    dataSanitized = dataSanitized.replace(/("primaryEmail"\s*:\s*\{)(.*?)(\})/gs, '$1$3');
    dataSanitized = dataSanitized.replace(/("Authorization"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("dateOfBirth"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("ssn"\s*:\s*")(.*?)(")/gs, '$1***$3');
    dataSanitized = dataSanitized.replace(/("ssnEncrypted"\s*:\s*")(.*?)(")/gs, '$1***$3');
  }
  return dataSanitized;
};

const errorsBlackList = [
  /.*\[Network Error:undefined\]/, // no connection
  /.*\[Request aborted:ECONNABORTED\]/, // browser 'stop' aborts requests
  /.*\[timeout exceeded:ECONNABORTED\]/, // browser 'stop' aborts requests
  '/institution-logins [409:QCS-0409]',
  '/institution-logins/xxx [409:QCS-0409]',
  '/analysis/saga/xxx [404:undefined]',
  '/pfm-importer/import [403:QCS-0403-2]', // Bad File: Could not find fields [Amount, Category, Date, Payee, Tags]' in input
  '/job-statuses [401:QCS-0401-5]', // Trying to access a deleted dataset.
  '/jwt-sso/token-redirect [401:QCS-0401-7]', // QCS Unauthorized Access - Username requires verification for community access
  '/oauth/token [401:QCS-0401-19]', // refresh token is revoked due to password change
  /.*\[401:QCS-0401-2\]/, // The token expired, was revoked, or the token ID is incorrect.
  /\/institution-logins.* \[429:QCS-0429-2\]/, // QCS-AASR-1 - Please try again later. If the problem persists, contact Customer Care for support.
];

const trackPayload = (message, request, response, tag) => {
  if (request) {
    const url = new URL(request.url);
    const path = url?.pathname?.replace(/\/\d+(?=\/|$)/g, '/xxx');
    const context = `${path} [${tag}]`; // we use context as black list key -> keep errorsBlackList up to date
    if (errorsBlackList.find((mask) => typeof mask === 'string' ? context.includes(mask) : mask.test?.(context))) {
      log.warn(`network error blacklisted: '${context}`);
    } else {
      log.warn(`network error will be send to bugsnag: '${context}`);
      const error = new Error(message);
      error.name = 'HTTPError';

      let requestData;
      try {
        const requestString = sanitizeData(request?.data);
        requestData = requestString && JSON.parse(requestString);
      } catch (e) {
        assert(false, 'request parsing failed: ', e);
      }

      let responseData;
      try {
        const responseString = sanitizeData(JSON.stringify(response?.data));
        responseData = responseString && JSON.parse(responseString);
      } catch (e) {
        assert(false, 'response parsing failed: ', e);
        responseData = response?.data;
      }

      crashReporterInterface.reportError(error, (event) => {
        event.context = context;
        event.addMetadata('payload', {
          message,
          request: {
            method: request.method,
            url: request.url,
            params: request.params,
            headers: {
              ...request.headers,
              ...(request.headers.Authorization ? { Authorization: '***' } : {}),
            },
            data: requestData,
          },
          response: {
            status: response?.status,
            headers: response?.headers,
            data: responseData,
          },
        });
      });
    }
  }
};
