// @flow
import React from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import { List } from 'immutable';
import { withTheme } from '@emotion/react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CheckIcon from '@mui/icons-material/DoneRounded';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

import { Field, Formik } from 'formik';
import FormikTextField from 'components/Formik/TextField';

import { getPartnerAuthUris as getPartnerAuthUrisAction } from 'components/Dialogs/AccountDiscovery/actions';

import type { Institution, InstitutionChannelData } from 'data/institutions/types';
import { mkInstitutionLogin } from 'data/institutionLogins/types';
import type { InstitutionLogin } from 'data/institutionLogins/types';

import { getRedirectUrl, tracker } from 'companion-app-components/utils/core';
import isAcme from 'isAcme';

import StdDialogActions from 'components/Dialogs/StdDialogActions';
import StdDialogContent from 'components/Dialogs/StdDialogContent';
import StdDialogFormik from 'components/Dialogs/StdDialogFormik';
import StdDialogProgress from 'components/Dialogs/StdDialogProgress';

import TertiaryTrust from '../TertiaryTrust';
import * as selectors from '../selectors';

import ErrorContentView from './ErrorContentView';

import styles from './styles';

type Props = {
  institution: Institution,
  channelData: InstitutionChannelData,

  fullAddAccountsDialog: boolean,
  hideInitialPartnerAuthIntro: boolean,
  institutionLogin: ?InstitutionLogin,
  scope: string,

  error: Object,

  onCancel: () => void,
  onSubmit: (InstitutionLogin, () => void, () => void) => void,

  trackingProperties: Object, // for mixpanel tracking
  updateMode: boolean,

  // from connect
  partnerAuthUris: List<string>,
  getPartnerAuthUris: (any) => void,

  // from styles
  classes: Object,
  theme: Object,
}

type State = {
  showPassword: boolean,

  triggerPartnerAuthSignIn: boolean,
  partnerAuthInProgress: boolean,
  partnerAuthError: Object,
}

class InstitutionLoginForm extends React.PureComponent<Props, State> {

  form = null;
  inputRefs = [];

  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,

      triggerPartnerAuthSignIn: props.channelData?.partnerAuth && props.hideInitialPartnerAuthIntro,
      partnerAuthInProgress: false,
    };
  }

  componentDidMount() {
    const { channelData, scope } = this.props;

    // trigger call to getPartnerAuthUris if auth-type is PARTNER_AUTh
    if (channelData?.partnerAuth && !this.props.partnerAuthUris) {
      this.props.getPartnerAuthUris({ partnerAuth: channelData.partnerAuth, redirectUri: `${getRedirectUrl()}/partnerAuth` }, { scope });
    }
  }

  componentDidUpdate(prevProps) {
    const { error, partnerAuthUris, trackingProperties, updateMode } = this.props;
    const { partnerAuthInProgress, triggerPartnerAuthSignIn } = this.state;

    if (error && error !== prevProps.error) {
      tracker.track(!updateMode ? tracker.events.addFIError : tracker.events.fixFIError,
        {
          ...trackingProperties,
          error_code: error.status,
          agg_status_code: error.statusCode,
        });
    }
    if (triggerPartnerAuthSignIn && partnerAuthUris && !partnerAuthInProgress) {
      this.doPartnerAuthSignIn();

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ triggerPartnerAuthSignIn: false });
    }
  }

  doPartnerAuthSignIn = () => {
    const { channelData, partnerAuthUris, scope } = this.props;
    const partnerAuthUri = partnerAuthUris.get(0);
    const redirectUrl = getRedirectUrl();

    const child = window.open(partnerAuthUri, 'windowname1', 'width=600, height=650');
    const pollTimer = window.setInterval(() => {
      try {
        if (child.document === undefined) {
          window.clearInterval(pollTimer);
          this.props.getPartnerAuthUris({ partnerAuth: channelData.partnerAuth, redirectUri: `${redirectUrl}/partnerAuth` }, { scope });
          this.setState({ partnerAuthInProgress: false, partnerAuthError: null });
        } else if (child.document?.URL?.indexOf(`${redirectUrl}/partnerAuth`) !== -1) {
          let partnerAuthError = null;

          const urlParams = new URLSearchParams(child.location.search);
          child.close();

          const token = urlParams.get('response_token');
          if (token) {
            const credentials = channelData.partnerAuth.fields?.map((field) => ({
              key: field.key,
              value: field.displayLabel === 'response_token' ? token : '',
            }));
            this.doSubmit(channelData, credentials);
          } else {
            // const errorSource = urlParams.get('error_source');
            // const errorCode = urlParams.get('error_code');
            const errorDescription = urlParams.get('error_description');
            if (errorDescription) {
              partnerAuthError = { text: atob(errorDescription) };
            } else {
              partnerAuthError = { text: 'Unexpected error' };
            }
          }

          window.clearInterval(pollTimer);
          this.props.getPartnerAuthUris({ partnerAuth: channelData.partnerAuth, redirectUri: `${redirectUrl}/partnerAuth` }, { scope });
          this.setState({ partnerAuthInProgress: false, partnerAuthError });
        }
      } catch (e) {
        if (e.name === 'SecurityError') {
          // until we are redirected back to our domain, this exception is expected
        } else {
          // don't really expect this to happen
          window.clearInterval(pollTimer);
          this.setState({
            partnerAuthInProgress: false,
            partnerAuthError: { text: `Unexpected Error: ${e.name}` },
          });
        }
      }
    }, 10);
    this.setState({ partnerAuthInProgress: true, partnerAuthError: null });
  };

  handleCancel = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel({
        trackingProperties: {
          step: 'credentials',
        },
      });
    }
  };

  handleClickShowPassword = () => this.setState((prevState) => ({ showPassword: !prevState.showPassword }));

  handlePartnerAuthSignIn = () => this.setState({ triggerPartnerAuthSignIn: true });

  handleSubmit = (values, _actions) => {
    const { channelData } = this.props;
    // Exclude fields with type 'COOKIE' as discussed with QCS team.
    // These fields cannot be excluded at the transformers level
    // as credentials encryption fails if all fields are not considered.
    const credentials = channelData.formFields.filter((field) => field?.type !== 'COOKIE').map((field) => ({
      key: field.key,
      value: values[field.key],
    }));
    this.doSubmit(channelData, credentials);
  };

  doSubmit = (channelData, credentials) => {
    if (this.props.onSubmit) {
      const institutionLogin = this.props?.institutionLogin
        || mkInstitutionLogin({
          institutionId: this.props.institution.id,
          channel: channelData.type,

          name: this.props.institution.name,
        });
      this.props.onSubmit(
        institutionLogin.set('credentials', credentials),
        () => {
          this.form?.setSubmitting?.(false);
        },
        () => {
          this.form?.setSubmitting?.(false);
        }
      );
    }
  };

  pushRef = (node) => {
    this.inputRefs.push(node);
  };

  renderField = (field, index, formikBag, classes, _theme) => {
    const isPasswordField = field.type === 'PASSWORD';
    const isCookie = field.type === 'COOKIE';
    // Exclude fields with type 'COOKIE' as discussed with QCS team.
    // These fields cannot be excluded at the transformers level
    // as credentials encryption fails if all fields are not considered.
    if (!isCookie) {
      return (
        <Box display="flex" key={field.key} className={classes.field}>
          <Field
            component={FormikTextField}
            fullWidth
            label={field.displayLabel}
            name={field.key}
            placeholder="answer"
            variant="outlined"
            type={!isPasswordField || this.state.showPassword ? 'text' : 'password'}
            autoComplete={isPasswordField ? 'new-password' : null}
            helperText=" "
            InputProps={{
              id: `field#${index}`,
              autoFocus: index === 0,
              endAdornment: (
                <InputAdornment position="end">
                  <LockOutlinedIcon className={classes.lock} />
                </InputAdornment>
              ),
              inputProps: {
                maxLength: 214, // max that RSA 2048 encryption could do
              },
            }}
            InputLabelProps={{
              classes: {
                shrink: classes.whiteBack,
              },
            }}
          />
        </Box>
      );
    }
    return null;
  };

  renderSecurityMessage = (icon, title, message) => (
    <Box display="flex" flexDirection="column" width="220px">
      <Box display="flex">
        <Box component={icon} color="text.secondary" mt="2px" width="18px" />
        <Box display="flex" flexDirection="column" ml={1} mb={3}>
          <Typography color="textPrimary" variant="body1">
            {title}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {message}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  renderCredentialsForm = () => {
    const {
      classes,
      error,
      channelData,
      fullAddAccountsDialog,
      theme,
    } = this.props;

    const cancelButtonLabel = fullAddAccountsDialog ? null : 'Cancel';
    const submitButtonLabel = fullAddAccountsDialog ? 'Connect Bank' : 'Submit';

    return (
      <Formik
        className={classes.formik}
        initialValues={{}}
        onSubmit={this.handleSubmit}
        validate={(values) => {
          // Exclude fields with type 'COOKIE' as discussed with QCS team.
          // These fields cannot be excluded at the transformers level
          // as credentials encryption fails if all fields are not considered.
          const validateResult = channelData.formFields.filter((field) => field?.type !== 'COOKIE').reduce((result, field) => ({
            ...result,
            ...(!values[field.key] ? { [field.key]: 'Required' } : null),
          }), {});
          return validateResult;
        }}
      >
        {(formikBag) => {
          const { handleSubmit: formikHandleSubmit, isSubmitting } = formikBag;
          this.form = formikBag;  // required to access properties within Formik
          return (
            <StdDialogFormik onSubmit={formikHandleSubmit}>
              <StdDialogContent flexDirection="row" useContainerFlexHeight>
                {channelData?.formFields?.size ?
                  <Box display="flex" flex="1" flexDirection="column" id="hello" className={fullAddAccountsDialog ? classes.formContent : classes.smallFormContent}>
                    {error?.text && <ErrorContentView error={error} />}
                    {channelData.formFields.map((field, index) => this.renderField(field, index + 4, formikBag, classes, theme))}
                    <FormControlLabel
                      label="Show Password"
                      classes={{ label: classes.checkLabel, root: classes.checkBox }}
                      control={
                        <Checkbox
                          checked={this.state.showPassword}
                          onChange={this.handleClickShowPassword}
                          value="Show Password"
                        />
                      }
                    />
                  </Box>
                  :
                  <Box mb={2}>This Bank is not available right now</Box>}
              </StdDialogContent>
              <StdDialogActions
                primaryId="connectButton"
                primaryDisabled={!channelData || isSubmitting}
                primaryLabel={submitButtonLabel}
                secondaryLabel={cancelButtonLabel}
                secondaryOnClick={!fullAddAccountsDialog && this.handleCancel}
                secondaryVariant="outlined"
                tertiaryNode={fullAddAccountsDialog && <TertiaryTrust />}
              />
            </StdDialogFormik>
          );
        }}
      </Formik>
    );
  };

  renderPartnerAuth = () => {
    const { classes, error, partnerAuthUris, theme, fullAddAccountsDialog } = this.props;
    const { partnerAuthError, partnerAuthInProgress } = this.state;

    const errorToDisplay = error || partnerAuthError;
    const isLoadingPartnerAuthInfo = !partnerAuthUris && !errorToDisplay;
    return (
      <>
        <StdDialogContent flexDirection="column" useContainerFlexHeight>
          {!partnerAuthUris && error &&
            <Box>This Bank is not available right now</Box>}
          {isLoadingPartnerAuthInfo &&
            <StdDialogProgress icon={CheckIcon} label="Securing connection..." />}
          <Box style={{ visibility: isLoadingPartnerAuthInfo ? 'hidden' : null }}>
            <Typography variant="subtitle1">
              Authorization access required
            </Typography>
            {errorToDisplay && <ErrorContentView error={errorToDisplay} />}
            <Box component="ul" color={theme.palette.greyScaleDeprecated[2]} pl="30px">
              <li>
                <Typography variant="body2" className={classes.partnerListItem}>
                  Sign in to {this.props.institution?.name || 'your bank'} through the secure browsing window.
                </Typography>
              </li>
              <li>
                <Typography variant="body2" className={classes.partnerListItem}>
                  When prompted, SELECT ALL YOUR ACCOUNTS.
                </Typography>
              </li>
              <li>
                <Typography variant="body2" className={classes.partnerListItem}>
                  {`You'll be able to hide accounts in ${isAcme ? 'Simplifi' : 'Quicken'} after a secure connection is established.`}
                </Typography>
              </li>
            </Box>
          </Box>
        </StdDialogContent>
        {partnerAuthUris &&
          <StdDialogActions
            primaryId="connectButton"
            primaryDisabled={isLoadingPartnerAuthInfo || partnerAuthInProgress}
            primaryLabel={!partnerAuthInProgress ? 'Sign In' : <CircularProgress size={24} />}
            primaryOnClick={this.handlePartnerAuthSignIn}
            tertiaryNode={fullAddAccountsDialog && <TertiaryTrust />}
          />}
      </>
    );
  };

  render() {
    return this.props.channelData?.authType === 'PARTNER_AUTH' ? this.renderPartnerAuth() : this.renderCredentialsForm();
  }

}

function mapStateToProps(state, props) {
  const { scope } = props;
  return {
    channelData: selectors.getInstitutionChannelData(state, { scope }),
    partnerAuthUris: selectors.getPartnerAuthUris(state, { scope }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPartnerAuthUris: (payload, meta) => dispatch(getPartnerAuthUrisAction(payload, meta)),
  };
}

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(InstitutionLoginForm, styles));
