import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { List as ImmutableList, Map as ImmutableMap, OrderedMap } from 'immutable';
import { usePrevious } from 'react-use';

import { makeStyles } from 'tss-react/mui';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import Skeleton from '@mui/material/Skeleton';

import RootState from 'companion-app-components/utils/redux-store/rootState';
import { tracker } from 'companion-app-components/utils/core';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

import zeroStateIcon from 'assets/zero-state-images/bank.svg';
import navIconInvestments from 'assets/nav-menu/investments.svg';
import { getAccountNodesTree, getAccountNodesById } from 'data/accountNodes/selectors';
import type { UseStylesType } from 'components/AccountCard/types';
import RendersCount from 'components/RendersCount';
import QTextButton from 'components/QTextButton';
import Dump from 'components/Dump';
import AccountRow from './AccountRow';
import { makeDisplayOptions } from './types';
import { HandleAddInstitutionLogin } from '../AccountDrawer/types';
import type { AccountListState, DisplayOptions } from './types';
import styles from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface AccountListProps {
  dimAccountList?: boolean,
  accountNode?: Record<string, any>,
  accountNodeName?: string,
  borderBottom?: any,
  balanceType: string,
  onSelect: (node: Record<string, any>) => void,
  selectedNodeId?: string,
  showColors: boolean,
  omitCents: boolean,
  handleAddInstitutionLogin: HandleAddInstitutionLogin,
  prefix?: string,
}

const AccountList: React.FC<AccountListProps> = (props) => {
  const { classes, theme }: UseStylesType = useStyles();
  const isLoading = useSelector((state: RootState) => accountsSelectors.getLoadPending(state));
  const accountNodesTree = useSelector((state: RootState) => getAccountNodesTree(state, props));
  const prevAccountNodesTree = usePrevious(accountNodesTree);

  const mkDisplayOptionsMapEntry = (path, node) => {
    let children: null | OrderedMap<string, DisplayOptions> = null;
    let expandParent = true;

    if (node.children) {
      children = OrderedMap(node.children.map((child) => {
        const pathToChild = path.concat(['children', child.id]);
        const displayNodeEntry = mkDisplayOptionsMapEntry(pathToChild, child);
        // start separate and hidden sections as collapsed
        if (path.includes('SEPARATE') || path.includes('HIDDEN')) {
          expandParent = false;
        }
        if (displayNodeEntry[1].selected) {
          expandParent = true;
        }
        return displayNodeEntry;
      }));
    }

    const displayOptions = makeDisplayOptions({
      selected: false,
      expand: expandParent,

      path: ImmutableList(path),
      children,
    });

    return [node.id, displayOptions];
  };

  const mkDisplayOptionsMap = (newAccountNodesTree): ImmutableMap<string, DisplayOptions> => (
    ImmutableMap(newAccountNodesTree.map((node) => mkDisplayOptionsMapEntry([node.id], node)))
  );

  const [accountListState, setAccountListState] = useState<AccountListState>({
    displayOptionsMap: mkDisplayOptionsMap(accountNodesTree),
  });

  const numAccounts = isLoading ? 0 : accountNodesTree.size;
  // Somewhat temporary to AB test the account list havving "Add" buttons, see SIMPL-9339 for more
  const showOptionToAddUnderAccounts = useSelector((state: RootState) =>
    featureFlagsSelectors.getFeatureFlags(state).get('accountListSubLevelAdd') && false);
  const accountNodesById = useSelector((state: RootState) => getAccountNodesById(state, props));
  let accountsToShowAddOptionUnder: string[] = [];
  if (showOptionToAddUnderAccounts) {
    const accountIdToDisplayLabel = { BANKING: 'Banking', LOAN: 'Loans', ASSET: 'Property', INVESTMENT: 'Investments' };
    accountsToShowAddOptionUnder = ['BANKING', 'INVESTMENT', 'ASSET', 'LOAN']
      .filter((id) => !accountNodesById.has(id))
      .map((id) => accountIdToDisplayLabel[id]);
  }

  const handleExpandCollapse = (displayOptions) => {
    if (!displayOptions.children) {
      // cannot expand/collapse non-parent nodes
      return;
    }
    setAccountListState({
      displayOptionsMap: accountListState.displayOptionsMap.setIn(
        displayOptions.path,
        displayOptions.set('expand', !displayOptions.expand),
      ),
    });

    const trackObj = {
      state: displayOptions.expand ? 'collapsed' : 'expanded',
      level: displayOptions.path.size === 3 ? 2 : 1,
    };
    tracker.track(tracker.events.accountListArrow, trackObj);
  };

  const renderDrawerSkeleton = () => {
    const skeletonDrawer: ReactElement[] = [];
    for (let i = 0; i < 5; i += 1) {
      skeletonDrawer.push(
        <div key={`skeleton-${i}`} className={classes.skeletonGroupWrapper}>
          <Skeleton variant="rectangular" className={classNames(classes.skeletonBlock, 'large')} />
          <Skeleton variant="rectangular" className={classes.skeletonBlock} />
          <Skeleton variant="rectangular" className={classNames(classes.skeletonBlock, 'small')} />
        </div>,
      );
    }

    return (
      <div className={classes.skeletonWrapper}>
        <Skeleton variant="rectangular" className={classNames(classes.skeletonBlock, 'netWorth')} />
        {skeletonDrawer}
      </div>
    );
  };

  useEffect(() => {
    if (accountNodesTree !== prevAccountNodesTree) {
      const displayOptionsMap = mkDisplayOptionsMap(accountNodesTree);
      setAccountListState({ displayOptionsMap });
    }
  }, [accountNodesTree, prevAccountNodesTree]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.base} id="qCard-account-list">
      <Dump obj={{ accountNodesTree }} sx={{ left: 'unset', right: 0 }} />
      {isLoading &&
        <div className={classes.loadingStateContainer}>
          <Fade
            in={isLoading}
            unmountOnExit
          >
            <CircularProgress sx={{ color: theme.palette.primaryBlue }} />
          </Fade>
        </div>}
      {/* This skeleton for the accountDrawer on the Transactions page for acme is currently not in use. 
      It may be added in the future */}
      {(isLoading && false) && renderDrawerSkeleton()}
      {!isLoading && numAccounts > 0 &&
        <List
          dense
          classes={{
            root: props.borderBottom ? classes.rootWithBorderBottom : classes.root,
          }}
          className={props.dimAccountList ? 'dim' : ''}
        >
          {accountListState.displayOptionsMap && accountNodesTree.map((node, i) => {
            if (node.hide) {
              return (<div key={node.id} />);
            }
            return (
              <AccountRow
                key={node.id}
                id={`${node.displayLabel}-${i}`}
                node={node}
                dense
                displayOptions={accountListState.displayOptionsMap.get(node.id)}
                onClick={props.onSelect}
                onExpandCollapse={handleExpandCollapse}
                selectedNodeId={props.selectedNodeId}
                showColors={props.showColors}
                balanceType={props.balanceType}
                omitCents={props.omitCents}
              />
            );
          })}

          <Box marginTop={2} paddingLeft={2} paddingRight={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            {accountsToShowAddOptionUnder.map((accountType, i) => (
              <Slide
                key={`transition-${accountType}-empty`}
                direction={'up'}
                in
                style={{ width: '100%' }}
                timeout={400 + (accountNodesTree.size + i) * 50}
              >
                <Box>
                  <Fade in timeout={2500 + ((accountNodesTree.size + i) * 50)}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                      <div className={classes.emptyAccountTypeHeader}>
                        <Typography variant={'subtitle1'}>{accountType}</Typography>
                      </div>
                      <QTextButton
                        className={classes.emptyAccountTypeAddButton}
                        onClick={props.handleAddInstitutionLogin(
                          `account list${props.prefix === 'dashboard' ? ' dashboard' : ' txns'}`,
                          accountType === 'Property',
                        )}
                        variant={'body2'}
                        title={`+ ${accountType?.replace(/s$/, '')} account`}
                      />
                    </Box>
                  </Fade>
                </Box>
              </Slide>
            ))}
          </Box>

          {props.borderBottom &&
            <Divider
              classes={{
                root: classes.dividerRoot,
              }}
            />}
        </List>}
      {!isLoading && numAccounts === 0 && !props.accountNodeName &&
        <div className={classes.zeroStateContainer}>
          <img alt="no transactions" src={zeroStateIcon} />
          <br />
          <div className={classes.zeroStateTextHeadline}>No synced financial accounts</div>
          <div className={classes.zeroStateTextBody}>All your connected financial accounts will be displayed here.</div>
        </div>}
      {props.accountNodeName === 'INVESTMENT' && !isLoading && !props.accountNode &&
        <div className={classes.zeroStateContainer}>
          <img alt="no investments" src={navIconInvestments} />
          <br />
          <div className={classes.zeroStateTextHeadline}>No synced investment accounts</div>
          <div className={classes.zeroStateTextBody}>All your connected investment accounts will be displayed here.</div>
        </div>}
      <RendersCount id="accountDrawer" />
    </div>
  );
};

export default AccountList;
