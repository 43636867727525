import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { scheduledTransactionsSelectors } from 'companion-app-components/flux/scheduled-transactions';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { tracker } from 'companion-app-components/utils/core';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { getBudgets } from 'companion-app-components/flux/budgets/budgetsSelectors';
import type RootState from 'companion-app-components/utils/redux-store/rootState';

import TaskListProgress from 'components/TaskListProgress';

interface SetupStatusBarProps {
  titleOnTop?: boolean;
  classes?: Record<string, string>;
  selected?: string;
  onOpenToggle?: (open: boolean) => void;
  name?: string;
}

type SetUpItemsType = {
  name: string;
  title: string;
  completed: boolean;
};

let globalStateOpen = true;

const SetupStatusBar: FC<SetupStatusBarProps> = (props) => {
  const navigate = useNavigate();

  const { titleOnTop, classes, selected, onOpenToggle, name } = props;
  
  const [open, setOpen] = useState(globalStateOpen);

  const accountsById = useSelector((state: RootState) => accountsSelectors.getAccountsById(state));
  const scheduledTransactionsById = useSelector((state: RootState) => scheduledTransactionsSelectors.getScheduledTransactions(state));
  const budgetsById = useSelector((state: RootState) => getBudgets(state));
  const isScheduledTransactionsLoading = useSelector((state: RootState) => scheduledTransactionsSelectors.getIsLoading(state));
  const useScheduledTransactions = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state).get('scheduledTransactions'));

  const setupItems = () => {
    const items: SetUpItemsType[] = [];
    items.push(
      {
        name: 'addAccounts',
        title: 'Add Accounts',
        completed: accountsById.size > 0,
      },
    );
    if (useScheduledTransactions) {
      items.push(
        {
          name: 'connectBills',
          title: 'Connect Your Bills',
          completed: !isScheduledTransactionsLoading && scheduledTransactionsById &&
            // @ts-expect-error: Property 'isUserVerified' does not exist on type 'ScheduledTransaction'.
            scheduledTransactionsById.size > 0 && scheduledTransactionsById.filter((x) => x.isUserVerified).size > 0,
        },
      );
    }
    items.push(
      {
        name: 'createBudget',
        title: 'Create a Budget',
        completed: budgetsById && budgetsById.size > 0,
      },
    );
    return items;
  };

  const onClick = (e, itemName) => {
    switch (itemName) {
      case 'addAccounts':
        if (!titleOnTop) {
          tracker.track(tracker.events.dashboardAddAccount);
        } else {
          tracker.track(tracker.events.wizardTopMenuAccounts);
        }
        navigate('/getting-started-webfirst?page=addAccounts');
        break;

      case 'connectBills':
        if (!titleOnTop) {
          tracker.track(tracker.events.dashboardBillscout);
        } else {
          tracker.track(tracker.events.wizardTopMenuBillscout);
        }
        navigate('/getting-started-webfirst?page=connectBills');
        break;

      case 'createBudget':
        if (!titleOnTop) {
          tracker.track(tracker.events.dashboardAddBudget);
        } else {
          tracker.track(tracker.events.wizardTopMenuBudgets);
        }
        navigate('/getting-started-webfirst?page=createBudget');
        break;

      default:
        break;
    }
  };

  return (
    <TaskListProgress
      setupItems={setupItems()}
      selected={selected}
      open={open}
      titleOnTop={titleOnTop}
      onOpenToggle={titleOnTop ? null : (e, val) => {
        globalStateOpen = val;
        setOpen(val);
        if (onOpenToggle) {
          onOpenToggle(val);
        }
      }}
      onClick={onClick}
      classes={classes}
      name={name}
    />
  );
  
};

export default SetupStatusBar;
