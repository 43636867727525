import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Today from '@mui/icons-material/Today';
import IconButton from '@mui/material/IconButton';
import { DateTime } from 'luxon';
import Typography from '@mui/material/Typography';


const QMonthNavigator = (props) => (
  <span
    style={{
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      ...props.style,
    }}
  >
    <IconButton
      id="previous-interval"
      aria-label="previous"
      style={{
        width: 35,
        height: 35,
        padding: 0,
      }}
      onClick={props.onPreviousClick}
      disabled={props.previousDisabled}
      size="large"
    >
      <KeyboardArrowLeft />
    </IconButton>

    <IconButton
      id="current-interval"
      aria-label="current"
      style={{
        width: 35,
        height: 35,
        padding: 0,
      }}
      onClick={props.onCurrentClick}
      size="large"
    >
      <Today />
    </IconButton>

    <Typography
      variant="body2"
      style={{
        display: 'inline-block',
        width: 60,
        textAlign: 'center',
        fontSize: 13,
        color: '#666',
        fontWeight: 500,
        position: 'relative',
      }}
    >
      {props.date && props.date.toLocaleString({
        month: 'short',
        year: 'numeric',
      })}
    </Typography>

    <IconButton
      id="next-interval"
      aria-label="next"
      style={{
        width: 35,
        height: 35,
        padding: 0,
      }}
      onClick={props.onNextClick}
      disabled={props.nextDisabled}
      size="large"
    >
      <KeyboardArrowRight />
    </IconButton>
  </span>
);

QMonthNavigator.defaultProps = {
  date: DateTime.local(),
};

QMonthNavigator.propTypes = {
  style: PropTypes.object,
  date: PropTypes.object,
  onPreviousClick: PropTypes.func,
  previousDisabled: PropTypes.bool,
  onCurrentClick: PropTypes.func,
  onNextClick: PropTypes.func,
  nextDisabled: PropTypes.bool,
};

export default QMonthNavigator;

