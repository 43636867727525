
import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export const ErrorHandler = (props) => {
  const { postponedActions, open, onClose } = props;
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    !isProduction &&
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          {postponedActions && postponedActions.map((postponedAction, index) => (
            <div key={postponedAction.key}>
              {`${index + 1}: ${postponedAction.action && postponedAction.action.type} [${postponedAction.error}]`}
            </div>
          ))}
        </DialogContent>
      </Dialog>
  );
};

ErrorHandler.propTypes = {
  postponedActions: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
