// @flow
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { accountsSelectors } from 'companion-app-components/flux/accounts';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

import { Field, FormikBag, getIn } from 'formik';

import AccountTypeSelector from 'components/Formik/AccountTypeSelector';
import FormikTextField from 'components/Formik/TextField';

import AccountActionSelector from './AccountActionSelector';

import { ACCOUNT_ACTION_ADD, ACCOUNT_ACTION_IGNORE } from '../../types';
import type { AccountAction, AccountData } from '../../types';

import { useStyles } from './styles';

type Props = {
  account: AccountData,
  index: number,

  otherNames: Object,

  formik: FormikBag,
}

const AccountRow = (props: Props) => {
  const { account, formik, index } = props;

  const nameRef = useRef();

  const accounts = useSelector(accountsSelectors.getAccountsById);
  const [errorOutline, setErrorOutline] = useState();
  const { classes } = useStyles();

  const validateOriginalName = (name) => {
    const { otherNames } = props;
    let ret = null;
    const lowerCaseName = name?.toLowerCase();
    accounts.forEach((otherAccount) => {
      if (otherAccount.name?.toLowerCase() === lowerCaseName) {
        setErrorOutline(true);
        ret = 'Account name must be unique.';
      }
    });

    let count = 0;
    otherNames.forEach((stagedName) => {
      if (stagedName === lowerCaseName) {
        count += 1;
      }
    });
    if (count > 1) {
      setErrorOutline(true);
      ret = 'Account name must be unique.';
    }
    !ret && setErrorOutline(false);
    return ret;
  };

  const validateType = (type) => {
    let ret = null;
    if (type.qcsType === 'UNKNOWN') {
      ret = 'Please pick an account type';
    }
    return ret;
  };

  const getNameFieldName = () => `${account.formFieldName}.name`;
  const getTypeFieldName = () => `${account.formFieldName}.type`;
  const getActionFieldName = () => `${account.formFieldName}.action`;

  const getDiscoveredAccountName = () => account.discoveredAccount.name;

  const getMaskedNumber = () => {
    const { maskedNumber } = account.discoveredAccount;
    if (!maskedNumber) {
      return undefined;
    }
    const charactersNum = maskedNumber.length >= 4 ? 4 : maskedNumber.length;
    const lastCharacters = maskedNumber && maskedNumber.slice(-charactersNum);
    const last4Characters = (lastCharacters && !Number.isNaN(Number(lastCharacters))) ? 'X'.repeat(4 - charactersNum) + lastCharacters : 'XXXX';
    return `••••${last4Characters}`;
  };

  const handleAccountActionChange = (event: Object, child?: Object) => {
    const nameFieldName = getNameFieldName();
    const actionFieldName = getActionFieldName();

    const prevAction = getIn(formik.values, actionFieldName);
    const newAction: AccountAction = event.target.value;

    if ((newAction === ACCOUNT_ACTION_ADD || newAction === ACCOUNT_ACTION_IGNORE) && prevAction.type === 'LINK_TO') {
      formik.setFieldValue(nameFieldName, account.name);
    }
    if (newAction.type === 'LINK_TO') {
      formik.setFieldValue(nameFieldName, newAction.accountToLinkTo ? newAction.accountToLinkTo.name : 'unknown');
    }

    formik.handleChange(event, child);
  };

  const nameFieldName = getNameFieldName();
  const typeFieldName = getTypeFieldName();
  const actionFieldName = getActionFieldName();

  const discoveredAccountName = getDiscoveredAccountName();
  const maskedNumber = getMaskedNumber();
  const name = getIn(formik.values, nameFieldName);
  const action = getIn(formik.values, actionFieldName);

  const badType = getIn(formik.errors, typeFieldName);

  // field is disabled: ignore & link_to
  // link_to shows discovered name and linked_to name
  // add allows name to be edited and shows discovered name
  // ignore only shows discovered name

  const disableAccountName = action.type === 'LINK_TO';
  const ignore = action.type === 'IGNORE';

  const showNameTip = nameRef.current && (nameRef.current.offsetWidth < nameRef.current.scrollWidth);
  const showLinkToInfo = action.type === 'LINK_TO';

  let additionalNameInfo;
  if (showLinkToInfo) {
    additionalNameInfo = `Link to: ${action.accountToLinkTo.name}`;
  } else if (name !== discoveredAccountName) {
    additionalNameInfo = `${discoveredAccountName}`;
    if (maskedNumber) {
      additionalNameInfo += ` ${maskedNumber}`;
    }
  } else if (maskedNumber) {
    additionalNameInfo = maskedNumber;
  }

  return (
    <Box className={classes.borderDiv}>
      <Box flexBasis="49%" maxWidth="49%" className={classes.cell}>
        <div className={classes.nameSpace}>
          <Tooltip title={(showNameTip && !ignore) ? name : ''}>
            <div
              style={{ marginRight: 40 }}
              // don't remove this div - it is important for catching the tooltip ref, as field
              // is a 3rd party functional component and therefore cannot hold a ref
            >
              <Field
                component={FormikTextField}
                disabled={disableAccountName}
                fullWidth
                name={nameFieldName}
                variant="outlined"
                className={classNames(ignore ? classes.ignoreFields : classes.fields, errorOutline && classes.errorOutline)}
                validate={(action.type === 'ADD_ACCOUNT') ? validateOriginalName : undefined}
                InputProps={{
                  className: classes.nameInputFocus,
                  inputProps: {
                    id: `account#${index}name`,
                    className: classes.nameInput,
                    disabled: ignore,
                    ref: nameRef,
                  },
                }}
              />
            </div>
          </Tooltip>
          <Typography
            variant="caption"
            className={classNames(ignore ? classes.greyAccDetails : classes.accDetails, errorOutline && classes.paddedSubheader)}
          >
            {additionalNameInfo}
          </Typography>
        </div>
      </Box>
      <Box flexBasis="23%" maxWidth="23%" className={classes.cell}>
        <div className={classes.nameSpace}>
          <AccountTypeSelector
            currentAccountType={account.type}
            id={`account#${index}type`}
            name={typeFieldName}
            textFieldProps={{
              className: classNames(ignore ? classes.ignoreTypeBox : classes.typeBox, errorOutline && classes.errorPadding, classes.plainBackground),
              variant: 'standard',
              fullWidth: true,
              disabled: ignore,
              label: null,
              InputProps: {
                disableUnderline: true,
                endAdornment: <InputAdornment position="end"><ArrowDropDownRoundedIcon /></InputAdornment>,
              },
            }}
            validate={(action.type !== 'IGNORE') ? validateType : undefined}
          />
          <Typography variant="caption" className={classes.errorType}>
            {action.type !== 'IGNORE' && badType}
          </Typography>
        </div>
      </Box>
      <Box flexBasis="24%" maxWidth="24%" className={classes.cell}>
        <AccountActionSelector
          disableUnderline
          id={`account#${String(index)}action`}
          linkableAccountsSection1={account.linkableAccountsSection1}
          linkableAccountsSection2={account.linkableAccountsSection2}
          linkableAccountsSection3={account.linkableAccountsSection3}
          name={actionFieldName}
          onBlur={formik.handleBlur}
          onChange={handleAccountActionChange}
          value={action}
          variant="standard"
        />
      </Box>
    </Box>
  );
};

export default AccountRow;
