import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  menuIcon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
  plainBackground: {
    background: theme.components.accountDiscovery.background,
    '&:focus': {
      background: theme.components.accountDiscovery.background,
    },
  },
  check: {
    backgroundColor: theme.palette.others.link,
    borderRadius: '50%',
    color: theme.components.accountDiscovery.background,
    width: 24,
    height: 24,
    padding: 2,
  },
  ignore: {
    backgroundColor: theme.components.accountDiscovery.ignore,
    borderRadius: '50%',
    color: theme.components.accountDiscovery.background,
    width: 24,
    height: 24,
    padding: 2,
  },
  link: {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: '50%',
    color: theme.palette.grey.level0,
    width: 24,
    height: 24,
    padding: 2,
  },

  smallCheck: {
    backgroundColor: theme.palette.others.link,
    borderRadius: '50%',
    color: theme.components.accountDiscovery.background,
    width: 20,
    height: 20,
    padding: 2,
    marginRight: 10,
  },
  smallX: {
    backgroundColor: theme.components.accountDiscovery.ignore,
    borderRadius: '50%',
    color: theme.components.accountDiscovery.background,
    width: 20,
    height: 20,
    padding: 2,
    marginRight: 10,
  },
  smallLink: {
    backgroundColor: theme.components.accountDiscovery.linkToBackground,
    borderRadius: '50%',
    color: theme.components.accountDiscovery.background,
    width: 20,
    height: 20,
    padding: 2,
    marginRight: 10,
  },
}));
