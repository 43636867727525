import { Record } from 'immutable';
import { isAcme } from 'isAcme';

import { ShowBalance } from 'companion-app-components/flux/preferences/types';

export const AccountBalances = Record({
  accountId: null,
  accountType: null,
  currentBalance: null,
  onlineBalance: null,
  endingBalance: null,
  endingBalanceNoRecurring: null,
  finalBalance: null,
  goalsBalance: null,
  earliestTransactionDate: null,
  txnsDeltaFromCurrentBalance: null,
  cpOnlineBalance: undefined,
  cpAvailableBalance: undefined,
  defaultBalance: undefined,
});


export const visibleBalances =
  !isAcme ?
    [
      { label: "Today's Balance", key: 'sumCurrentAccountBalances', value: 'today' },
      { label: 'Online Balance', key: 'sumOnlineAccountBalances', value: 'online' },
      { label: 'Projected Balance', key: 'sumEndingAccountBalances', value: 'ending' },
    ] :
    [
      { label: 'Current Balance', key: 'sumOnlineAccountBalances', value: 'online' }, // TODO: remove when balance-toggle (balanceToggle) FF flag expire
      { label: 'Balance', key: 'sumCurrentAccountBalances', value: 'today' }, // TODO: remove when balance-toggle (balanceToggle) FF flag expire
      { label: 'Bank Balance', key: 'sumCPOnlineAccountBalances', value: ShowBalance.BANK_REPORTED },
      { label: 'Balance with pending', key: 'sumCurrentAccountBalances', value: ShowBalance.INCLUDE_PENDING },
    ];

export const schedTxViews = [
  { label: 'Next 7 Days', value: '7' },
  { label: 'Next 14 Days', value: '14' },
  { label: 'Next 30 Days', value: '30' },
  { label: 'Next 90 Days', value: '90' },
  { label: 'Next 12 months', value: '365' },
  { label: "Don't show reminders", value: '0' },
];

export const schedTxViewsMac = [
  { label: 'Next 7 Days', value: 7 },
  { label: 'Next 14 Days', value: 14 },
  { label: 'Next 30 Days', value: 30 },
  { label: 'Next 90 Days', value: 90 },
  { label: 'Next 6 months', value: 182 },
  { label: 'Next 12 months', value: 365 },
  { label: 'The next upcoming occurrence', value: -1 },  // reminderDays === -1 means just the next occurrence (Mac only)
  { label: "Don't show reminders", value: 0 },
];
export const schedTxViewsWindows = [
  { label: 'Next 7 Days', value: 7 },
  { label: 'Next 14 Days', value: 14 },
  { label: 'Next 30 Days', value: 30 },
  { label: 'Next 90 Days', value: 90 },
  { label: 'Next 12 months', value: 365 },
  { label: "Don't show reminders", value: 0 },
];
