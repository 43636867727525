// @flow
import { createAction as reduxActionsCreator } from 'redux-actions';
import { Map as ImmutableMap } from 'immutable';
import { v4 as uuid } from 'uuid';

import { refreshAccounts } from 'data/institutionLogins/actions';

import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';

import { createAction } from 'utils/actionHelpers';

import { DIALOG_TYPE_ACCOUNTS_DISCOVERY, mkAccountDiscoveryDialogProps } from './types';

export const accountDiscoverySetup = createAction('ACCOUNT_DISCOVERY_SETUP');
export const accountDiscoveryCleanup = createAction('ACCOUNT_DISCOVERY_CLEANUP');

export const institutionSelected = createAction('ACCOUNT_DISCOVERY_INSTITUTION_SELECTED');

export const accountDiscoveryCredentialsFormSubmit = createAction('ACCOUNT_DISCOVERY_CREDENTIALS_FORM_SUBMIT');
export const accountDiscoveryCredentialsFormSubmitResponse = createAction('ACCOUNT_DISCOVERY_CREDENTIALS_FORM_SUBMIT_SUCCESS');

export const accountDiscoveryStatus = createAction('ACCOUNT_DISCOVERY_STATUS');

export const accountDiscoveryMfaRequired = createAction('ACCOUNT_DISCOVERY_MFA_REQUIRED');
export const accountDiscoveryMfaFormSubmit = createAction('ACCOUNT_DISCOVERY_MFA_FORM_SUBMIT');
export const accountDiscoveryMfaFormSubmitResponse = createAction('ACCOUNT_DISCOVERY_MFA_FORM_SUBMIT_RESPONSE');

export const getPartnerAuthUris = createAction('GET_PARTNER_AUTH_URIS');
export const getPartnerAuthUrisSuccess = createAction('GET_PARTNER_AUTH_URIS_SUCCESS');
export const getPartnerAuthUrisFailure = createAction('GET_PARTNER_AUTH_URIS_FAILURE');

export const getAggregationInfo = createAction('GET_AGGREGATION_INFO');

export const accountDiscoverySuccessWithAccounts = createAction('ACCOUNT_DISCOVERY_SUCCESS_WITH_ACCOUNTS');
export const accountDiscoverySuccess = createAction('ACCOUNT_DISCOVERY_SUCCESS');

export const accountDiscoveryFailure = createAction('ACCOUNT_DISCOVERY_FAILURE');
export const accountDiscoveryStopped = createAction('ACCOUNT_DISCOVERY_STOPPED');
export const accountDiscoveryError = createAction('ACCOUNT_DISCOVERY_ERROR');

export const discoverInstitutionLoginWithAccounts = createAction('DISCOVER_INSTITUTION_LOGIN_WITH_ACCOUNTS');
export const discoverInstitutionLoginWithAccountsByPolling = createAction('DISCOVER_INSTITUTION_LOGIN_WITH_ACCOUNTS_BY_POLLING');
export const discoverInstitutionLoginWithAccountsResponse = createAction('DISCCOVER_INSTITUTION_LOGIN_WITH_ACCOUNTS_RESPONSE');
export const discoverInstitutionLoginWithAccountsDiscard = createAction('DISCCOVER_INSTITUTION_LOGIN_WITH_ACCOUNTS_DISCARD');

export const accountDiscoveryWidgetUpdateSuccess = createAction('ACCOUNT_DISCOVERY_PLAID_ITEM_UPDATE_SUCCESS');

export const logPlaidItemEvent = createAction('ACCOUNT_DISCOVERY_LOG_PLAID_ITEM_EVENT');
export const logPlaidItemEventResponse = createAction('ACCOUNT_DISCOVERY_LOG_PLAID_ITEM_EVENT_RESPONSE');

export const doAccountDiscovery = reduxActionsCreator(
  createDialog.toString(),
  (options = {}) => mkRootUiData({
    id: uuid(),
    type: DIALOG_TYPE_ACCOUNTS_DISCOVERY,
    allowNesting: true,
    props: ImmutableMap(mkAccountDiscoveryDialogProps({
      initiator: options.initiator || 'unknown',
      mode: options.mode || 'ADD-FI',
    })),
  }),
  (_, meta) => meta,
);

export const doAccountRediscovery = reduxActionsCreator(
  createDialog.toString(),
  (institutionLogin, options = {}) => mkRootUiData({
    id: uuid(),
    type: DIALOG_TYPE_ACCOUNTS_DISCOVERY,
    allowNesting: true,
    props: ImmutableMap(mkAccountDiscoveryDialogProps({
      initiator: options.initiator || 'unknown',
      mode: 'ACCOUNT-REDISCOVERY',
      institutionLogin,

      afterCloseAction: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    })),
  }),
  (_, meta) => meta
);

export const doProvideCredentialsAndRefresh = reduxActionsCreator(
  createDialog.toString(),
  (institutionLogin, options = {}) => mkRootUiData({
    id: uuid(),
    type: DIALOG_TYPE_ACCOUNTS_DISCOVERY,
    allowNesting: false,
    props: ImmutableMap(mkAccountDiscoveryDialogProps({
      initiator: options.initiator || 'unknown',
      mode: 'PROVIDE-CREDENTIALS',
      institutionLogin,

      afterCloseAction: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    })),
  }),
  (_, meta) => meta
);

export const doReAuthenticate = reduxActionsCreator(
  createDialog.toString(),
  (institutionLogin) => mkRootUiData({
    id: uuid(),
    type: DIALOG_TYPE_ACCOUNTS_DISCOVERY,
    allowNesting: false,
    props: ImmutableMap(mkAccountDiscoveryDialogProps({
      mode: 'RE-AUTHENTICATE',
      institutionLogin,

      afterCloseAction: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    })),
  }),
);
export const doUpdateCredentials = reduxActionsCreator(
  createDialog.toString(),
  (institutionLogin) => mkRootUiData({
    id: uuid(),
    type: DIALOG_TYPE_ACCOUNTS_DISCOVERY,
    allowNesting: false,
    props: ImmutableMap(mkAccountDiscoveryDialogProps({
      mode: 'UPDATE-CREDENTIALS',
      institutionLogin,

      afterCloseAction: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    })),
  }),
);
export const doUpdateFi = reduxActionsCreator(
  createDialog.toString(),
  ({ institutionLogin, clearInstitution = false }) => mkRootUiData({
    id: uuid(),
    type: DIALOG_TYPE_ACCOUNTS_DISCOVERY,
    allowNesting: false,
    props: ImmutableMap(mkAccountDiscoveryDialogProps({
      mode: 'UPDATE-FI',
      institutionLogin: clearInstitution
        ? institutionLogin.merge({ institutionId: null, cpInstitutionId: null, brandingId: null })
        : institutionLogin,

      // afterCloseAction: refreshAccounts({ institutionLoginId: institutionLogin.id }),
    })),
  }),
);
export const doMigrateFi = reduxActionsCreator(
  createDialog.toString(),
  (institutionLogin, aggAction) => mkRootUiData({
    id: uuid(),
    type: DIALOG_TYPE_ACCOUNTS_DISCOVERY,
    allowNesting: false,
    props: ImmutableMap(mkAccountDiscoveryDialogProps({
      mode: 'MIGRATE-INSTITUTION-LOGIN',
      institutionLogin,
      aggAction,
    })),
  }),
);

