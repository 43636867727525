import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Map as ImmutableMap } from 'immutable';

export const DIALOG_TYPE = 'ADD_SCHEDULED_LIGHT_DIALOG';

export const scheduledTransactionLightEditDialog = createAction(
  createDialog.toString(),
  (scheduledTransaction) => mkRootUiData({
    type: DIALOG_TYPE,
    allowNesting: true,
    props: ImmutableMap({
      scheduledTransaction,
    }),
  })
);
