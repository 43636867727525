import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { Tabs, Tab } from '@mui/material';

import { Map as ImmutableMap } from 'immutable';

import { accountsTypes } from 'companion-app-components/flux/accounts';
import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { tracker } from 'companion-app-components/utils/core';

import compose from 'utils/compose';
import QPreferences from 'components/QPreferences';
import { getBalancesForAccountsAtDates } from 'data/accountBalances/selectors';
import QPopper from 'components/QPopper';
import { CurrMonth } from 'components/TransactionCalendar/types';

import { calDaysGridStyles } from '../../styles';
import CalTxnsModal from '../CalTxnsModal';
import CalDay from '../CalDay';


const tabs = ['TRANSACTIONS', 'BALANCES'];

const useStyles = makeStyles()(calDaysGridStyles as Record<string, any>);

interface CalDaysGridProps {
  daysTxsMap: ImmutableMap<string, ImmutableMap<string, transactionsTypes.CashFlowTransaction>>;
  curMonth: CurrMonth;
  accountIds: ImmutableMap<string, accountsTypes.Account>;
  onTransactionClick: (txn: transactionsTypes.CashFlowTransaction | undefined) => void; 
  datasetPreferences: any;
}

const CalDaysGrid: React.FC<CalDaysGridProps> = (props) => {
  const { classes } = useStyles();
  const {
    daysTxsMap, curMonth, onTransactionClick, accountIds,
    datasetPreferences: { calendarTransaction: calendarTransactionPref },
  } = props;

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<string | undefined | null>(null);
  const [popperData, setPopperData] = useState<Record<string, any> | null>(null);
  const [showTxnModal, setShowTxnModal] = useState<Record<string, any> | null>(null);
  const [currentTab, setCurrentTab] = useState<string>(tabs[0]);

  const todayDate: string | null = DateTime.local().toISODate();
  const txnModelDate: string = showTxnModal?.toISODate();

  const setPopper = useCallback((popperEl: string | undefined | null, data: Record<string, any> | null) => {
    setAnchorEl(popperEl);
    setPopperData(data);
    if (data) {
      tracker.track(tracker.events.calendarOnOpenPopover, {
        type: data.hoveringContent === 'full' ? 'Transactions' : 'Balances',
      });
    } else {
      setCurrentTab(tabs[0]);
    }
  }, [setAnchorEl, setPopperData, setCurrentTab]);

  const onGoToBills = useCallback((id) => {
    navigate(`/bills-and-income?selectNode=${id}`);
    tracker.track(tracker.events.calendarOnGoToBills, { schTxn: id });
  }, [navigate]);

  const onMouseLeave = useCallback(() => {
    setPopper(null, null);
  }, [setPopper]);

  const onTxnsModalOpen = useCallback((date: Record<string, any>) => {
    setShowTxnModal(date);
    tracker.track(tracker.events.calendarTxnModalOpen, { date: date.toISODate() });
  }, [setShowTxnModal]);

  const onTxnsModalClose = useCallback(() => {
    setShowTxnModal(null);
  }, [setShowTxnModal]);

  const cachedCalendarTransactionPref = useMemo(() => calendarTransactionPref, [calendarTransactionPref]);

  const balancesData = useSelector((state) => {
    const canGetData = cachedCalendarTransactionPref.showDailyBalances && daysTxsMap;
    return canGetData ? getBalancesForAccountsAtDates(state, {
      accountIds, dates: [...daysTxsMap.keys()],
    }) : null;
  });

  const balances = useMemo(() => balancesData, [balancesData]);
  const renderedDays = useMemo(() => (
    daysTxsMap && daysTxsMap.map((txnList, date: string) => {
      const dateLux = DateTime.fromISO(date);
      const currentMonth = dateLux.month === curMonth?.date?.month;
      const currentDay = todayDate === date;
      const balanceForDay = balances && (balances.has(date) ? balances.get(date) : undefined);
      return (
        <CalDay
          key={dateLux.toISODate()}
          setPopper={setPopper}
          day={dateLux.day}
          date={dateLux}
          currentMonth={currentMonth}
          currentDay={currentDay}
          txnList={txnList}
          balances={balanceForDay}
          onTxnsModalOpen={onTxnsModalOpen}
        />
      );
    }).toList()
  ), [daysTxsMap, balances, curMonth, onTxnsModalOpen, todayDate, setPopper]);

  const handleTabChange = (_, tab : string) => {
    setCurrentTab(tab);
  };

  const renderPopperContent = useCallback(() => (
    <div className={classes.popperRoot}>
      { popperData?.content?.header }
      <Tabs 
        value={currentTab} 
        onChange={handleTabChange}
        classes={{
          indicator: classes.customIndicator,
        }}
        className={classes.tabs}
        variant="fullWidth"
      >
        {
          tabs.map((tab) => (
            <Tab label={tab} value={tab} key={tab} />
          ))
        }
      </Tabs>
      { currentTab === tabs[0] && popperData?.content?.transactions }
      { currentTab === tabs[1] && popperData?.content?.balance }
    </div>
  ), [popperData, classes, currentTab]);

  const openPopper = useMemo(() => Boolean(anchorEl) && Boolean(popperData), [anchorEl, popperData]);

  return (
    <>
      <div className={classes.days} onMouseLeave={onMouseLeave}>
        {daysTxsMap && renderedDays}
        <QPopper
          id="transaction-calendar-popper"
          open={openPopper}
          anchorEl={anchorEl}
          placement="top"
          disablePortal
          transition
          transitionEffect="fade"
          modifiers={[
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [0, 10],
              },
            },
          ]}
        >
          {renderPopperContent()}
        </QPopper>
      </div>
      <p className={classes.footnote}>The Calendar View only uses information from your banking accounts. It does not include investment account information.</p>
      {showTxnModal && (
        <CalTxnsModal
          onClose={onTxnsModalClose}
          date={(showTxnModal as DateTime).toLocaleString(DateTime.DATE_HUGE)}
          txns={txnModelDate ? daysTxsMap.get(txnModelDate) : undefined}
          onGoToRegister={onTransactionClick}
          onGoToBills={onGoToBills}
        />
      )}
    </>
  );
};

export default compose(
  QPreferences(),
)(React.memo(CalDaysGrid));
