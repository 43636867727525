import { isAcme } from 'isAcme';

const NEW_BTN_HEIGHT = 24;

export const styles = (theme) => ({

  buttonContainer: {
    marginLeft: 16,
    marginRight: 'auto',
    alignSelf: 'center',
    lineHeight: `${NEW_BTN_HEIGHT}px`,
  },
  title: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  searchContainer: {
    marginLeft: 'auto',
    marginRight: '0px',
    alignSelf: 'center',
    lineHeight: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  smartSearch: {
    height: 'calc(100% - 1px)',
    marginTop: 0,
  },
  addTxnButton: {
    marginLeft: 8,
  },
  iconButton: {
    marginRight: 1,
    color: theme.palette.text.primary,
  },
  scheduleMenuHolder: {
    display: 'inline-block',
  },
  csvDownload: {
    marginLeft: 5,
    marginTop: 4,
    color: theme.palette.grey.level5,

  },
  container: {
    background: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
    display: 'flex',
    height: theme.components.register.controlBarHeight,
    paddingTop: 6,
    paddingBottom: 3,
  },
  filterIconButton: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  rightControls: {
    display: 'flex',
    borderRadius: 12,
    marginRight: 10,
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
  searchBox: {
    padding: 1,
    border: `1px solid ${theme.components.txns.searchBox}`,
    borderRadius: '5px 0px 0px 5px',
    borderColor: theme.palette.greyScaleDeprecated[3],
    '&:focus-within': {
      borderColor: ` ${theme.palette.primary.main} !important`,
    },
    '&:hover': {
      borderColor: `${theme.palette.greyScaleDeprecated[1]}`,
      color: theme.palette.primary.main,
    },
  },
  filterBox: {
    padding: 1,
    border: `1px solid ${theme.components.txns.searchBox}`,
    borderLeft: '0px solid',
    borderRadius: '0px 5px 5px 0px',
    '&:hover': {
      background: theme.components.txns.filterHover,
    },
    display: 'flex',
  },
  searchMetrics: {
    display: 'inline-block',
    margin: 'auto 0 auto 17px',
    paddingTop: 4,
    color: theme.palette.greyScaleDeprecated[1],
  },
  searchHelpContainer: {
    padding: 20,
    background: theme.palette.greyScaleDeprecated[6],
  },
  searchHelpTitle: {
    marginBottom: 2,
  },
  searchHelpSubtitle: {
    marginBottom: 3,
    marginTop: 3,
  },
  helpText: {
    marginBottom: 5,
  },
  filterNum: {
    whiteSpace: 'nowrap',
    padding: '1px 4px 0 3px',
    margin: 'auto 8px auto 0',
    textAlign: 'center',
    background: theme.palette.color1.opacity50,
    color: theme.palette.common.black,
    borderRadius: 5,
  },
  filterNumClose: {
    borderRadius: '50%',
    width: 14,
    height: 14,
    background: theme.palette.grey.level5,
    textAlign: 'center',
    left: -2,
    top: 2,
    position: 'relative',
    marginLeft: -10,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.greyScaleDeprecated[1],
    },
  },
  filterNumCloseText: {
    fontSize: '10px',
    fontWeight: 500,
    color: theme.palette.common.white,
  },
  circularProgress: {
    display: 'grid',
    color: theme.palette.grey[50],
  },
  iconDivider: {
    borderImage: `radial-gradient(${theme.palette.greyScaleDeprecated[3]}, ${theme.palette.greyScaleDeprecated[7]}) 1`,
    borderLeft: '1px solid',
    borderRight: '1px solid',
  },
});
