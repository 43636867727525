import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import type RootState from 'companion-app-components/utils/redux-store/rootState';
import type { Dataset } from 'companion-app-components/flux/datasets/datasetsTypes';
import { removeDialog } from 'companion-app-components/flux/root-ui/rootUiActions';
import { datasetSharesSelectors } from 'companion-app-components/flux/dataset-shares';
import { profileSelectors } from 'companion-app-components/flux/profile';

import { getFullName } from 'containers/App/Header/ProfileMenu/utils';
import UserList from './components/UserList';
import { DIALOG_TYPE_MANAGE_MEMBERS } from './actions';
import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface ManageMembersProps {
  dataset?: Dataset;
}

const ManageMembers: FC<ManageMembersProps> = ({ dataset }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const profile = useSelector((state: RootState) => profileSelectors.getProfile(state));
  const sharedWith = useSelector((state) => datasetSharesSelectors.getSharesByDatasetId(state, dataset?.id))?.toList();

  const handleClose = () => {
    dispatch(removeDialog(DIALOG_TYPE_MANAGE_MEMBERS));
  };

  return (
    <Dialog
      open
      sx={{
        '& .MuiPaper-root': {
          padding: '0 20px',
          minWidth: 680,
        },
      }}
    >
      <DialogTitle
        className={classes.dialogTitleWrapper}
      >
        <Typography variant="h6" className={classes.title}>
          Manage Access
        </Typography>
        <Typography variant="body1" className={classes.textTertiary}>
          Manage who has access to this financial data.
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers className={classes.dialogContent}>
        <UserList
          ownerName={getFullName(profile)}
          ownerEmail={profile?.username || ''}
          datasetName={dataset?.name}
          manager
        />
        {sharedWith.map((share) => (
          <UserList
            share={share}
            datasetName={dataset?.name}
          />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default ManageMembers;
