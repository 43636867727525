// @flow
import { List, Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

import { mkConfirmationDialogProps } from '../ConfirmationDialog/types';
import type { ConfirmationDialogProps } from '../ConfirmationDialog/types';

type RefreshTimeoutDialogPropsProps = {
  dialogIdsToClose: List<string>,

  confirmationDialogProps: ConfirmationDialogProps,
}
export type RefreshTimeoutDialogProps = RecordOf<RefreshTimeoutDialogPropsProps>;
export const mkRefreshTimeoutDialogProps: RecordFactory<RefreshTimeoutDialogPropsProps> =
  Record({
    dialogIdsToClose: List(),

    confirmationDialogProps: mkConfirmationDialogProps(),
  });

export const DIALOG_TYPE = 'DIALOG_REFRESH_TIMEOUT';

