export const userListStyles = ({ palette }) => ({
  wrapper: {
    display: 'flex',
    minWidth: 500,
    gap: 16,
    alignItems: 'center',
  },
  nameWrapper: {
    lineHeight: 1,
    textTransform: 'capitalize',
  },
  rightBlock: {
    marginLeft: 'auto',
  },
  textTertiary: {
    color: palette.koalaGrey,
  },
  chip: {
    borderRadius: 8,
    background: palette.athensGrey,
  },
  managerBlock: {
    marginRight: 50,
  },
  labelsWrapper: {
    maxWidth: 430,
  },
  textEllipsis: {
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap',
  },
});
