
import React, { FC, ReactNode, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Set, List } from 'immutable';
import { BudgetNodeTypes } from 'companion-app-components/flux/budgets/budgetsTypes';

import ButtonBase from '@mui/material/ButtonBase';

import BudgetGroupItem from './BudgetGroupItem';
import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface BudgetGroupHeaderProps {
  classes: Record<string, any>;
  onClick: () => void;
  node: Record<string, any>;
  currency: string;
  accountIds: List<string> | null;
}

const BudgetGroupHeader: FC<BudgetGroupHeaderProps> = ({ classes, onClick, node, currency, accountIds }) => {

  const isRollupRow = (node.depth > 0);
  return (
    <ButtonBase
      style={{ width: '100%' }}
      onClick={onClick}
      href=""
      focusRipple
    >
      <BudgetGroupItem
        item={{ amount: node.targetAmount,
          name: !isRollupRow ? node.displayLabel.toUpperCase() : node.displayLabel,
          isIncome: node.targetAmount > 0,
          id: node.id,
          budgetedWithChild: true,
        }}
        currency={currency}
        accountIds={accountIds}
        isRollup
        classesPassed={{ categoryRow: isRollupRow ? '' : classes.rootNode, categoryName: classes.categoryName }}
        depth={isRollupRow ? 0 : -1}
      />
    </ButtonBase>
  );
};

interface EditBudgetCategoryGroupsProps {
  currency: string;
  budgetTreeNodes: Record<string, any>;
  onChange: (cb?: any) => void;
  onDelete: (cb?: any) => void;
  accountIds: List<string> | null;
}

const EditBudgetCategoryGroups: FC<EditBudgetCategoryGroupsProps> = ({ currency, budgetTreeNodes, onChange, onDelete, accountIds }) => {

  // @ts-expect-error: Only a void function can be called with the 'new' keyword.
  const [hiddenGroups, setHiddenGroups] = useState(new Set());

  const { classes } = useStyles();

  const showHideGroup = (node) => {
    setHiddenGroups(hiddenGroups.has(node.id) ? hiddenGroups.delete(node.id) : hiddenGroups.add(node.id));
  };

  const getInnerNodes = (nodes, depth = 0) => {

    let localNodeList: ReactNode[] = [];
    nodes.forEach((node) => {

      let overrideLabel = null;
      if (node.type === BudgetNodeTypes.GROUP_EE) {
        overrideLabel = node.displayLabel;
      }
      localNodeList.push(
        <React.Fragment key={`budgetNode:${node.id}${node.displayLabel}${node.type}`}>
          {((node.depth === 0) || node.type === 'crup') &&
            <BudgetGroupHeader
              classes={classes}
              onClick={() => showHideGroup(node)}
              node={node}
              currency={currency}
              accountIds={accountIds}
            />}
          {(node.depth) > 0 && !hiddenGroups.has(node.id) && node.budgetItems.size > 0 &&
          <BudgetGroupItem
            item={node.budgetItems.first()}
            chartIds={node.coas}
            currency={currency}
            onChange={onChange}
            onDelete={node.type === BudgetNodeTypes.GROUP_EE ? undefined : onDelete}
            accountIds={accountIds}
            overrideLabel={overrideLabel}
            depth={depth - 1 - (node.type === BudgetNodeTypes.GROUP_EE ? 1 : 0)}
            isEverythingElse={node.isEverythingElse && node.type !== BudgetNodeTypes.GROUP_EE}
          />}
        </React.Fragment>,
      );
      if ((node.children.size > 0) && !hiddenGroups.has(node.id)) {
        localNodeList = localNodeList.concat(getInnerNodes(node.children, depth + 1));
      }
    });
    return localNodeList;
  };

  // RENDER
  // Build inner nodes using a sequencer to avoid React errors (does not support Map)

  // if (budgetTreeNodes) console.log ("NODE LIST ", budgetTreeNodes.toJS());
  const innerNodes = getInnerNodes(budgetTreeNodes);
  return innerNodes && innerNodes.length ? (
    <div style={{ overflowY: 'scroll' }}>
      {innerNodes}
    </div>
  ) : null;
};

export default EditBudgetCategoryGroups;

