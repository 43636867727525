import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import Immutable, { List, Set } from 'immutable';
import { useSelector } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import { accountsSelectors } from 'companion-app-components/flux/accounts';

import DialogHoc from 'components/DialogHoc';
import StdDialog from 'components/Dialogs/StdDialog';
import useQPreferences from 'components/QPreferences/useQPreferences';
import { isOldEdge } from 'utils/utils';
import TableHeader from './TableHeader';
import TableAccountRow from './TableAccountRow';
import { calendarAccountsModalStyles } from '../../styles';

const isIe = require('is-iexplorer') || isOldEdge();  // eslint-disable-line

const useStyles = makeStyles()(calendarAccountsModalStyles as Record<string, any>);

const tableColumns = Object.freeze({
  checkBoxCol: {
    field: 'checkbox',
  },
  accountCol: {
    label: 'Account',
  },
  typeCol: {
    label: 'Type',
  },
  descriptionCol: {
    label: 'Description',
  },
});

interface CalAccountsPrefsModalProps {
  onClose: () => void;
  setDialog: (header: string, message: string, callBackFunction: (dialog: StdDialog) => void, actions: string[]) => void;
}


const CalAccountsPrefsModal: React.FC<CalAccountsPrefsModalProps> = ({ onClose, setDialog }) => {
  const { classes } = useStyles();
  const { datasetPreferences, setDatasetPreference } = useQPreferences<Record<string, any>>();

  const [accountsToShow, setAccountsToShow] = useState<Immutable.Set<string>>(Set());
  const accountsByIdData = useSelector(accountsSelectors.getAccountsById);

  const originalAccountsToShow = useMemo(() => datasetPreferences.calendarTransaction.accountsToShow, [datasetPreferences]) || List();
  const accountsById = useMemo(() => accountsByIdData && accountsByIdData.filterNot((account) => (account.type === 'INVESTMENT')), [accountsByIdData]);

  const hasChanges = useMemo(() => {
    if (originalAccountsToShow.size !== accountsToShow.size) return true;
    if (originalAccountsToShow.size && originalAccountsToShow.every((acc) => !accountsToShow.has(acc))) return true;
    return false;
  }, [originalAccountsToShow, accountsToShow]);

  const onCloseHandler = useCallback((event: any) => {
    if (event && hasChanges) {
      setDialog(
        'Discard changes?',
        'Are you sure you want discard all the changes',
        (dialog) => { if (['DISCARD', 'Enter'].includes(dialog.action)) onClose(); },
        ['CANCEL', 'DISCARD'],
      );
    } else {
      onClose();
    }
  }, [onClose, hasChanges, setDialog]);

  const onDoneHandler = useCallback(() => {
    setDatasetPreference({ calendarTransaction: { accountsToShow } });
    onCloseHandler(false);
  }, [onCloseHandler, setDatasetPreference, accountsToShow]);

  const onResetHandler = useCallback(() => {
    setDatasetPreference({ calendarTransaction: { accountsToShow: accountsById.map((acc) => acc.id).toSet() } });
  }, [accountsById, setDatasetPreference]);

  const onChangeAccountsToShow = useCallback((id: string | null | undefined) => {
    if (!id) return;

    let newAccountsToShow: Immutable.Set<string>;

    if (id === 'ALL') { // Select all accounts
      newAccountsToShow = accountsById.map((acc) => acc.id).toSet();
    } else if (id === 'CLEAR') { // Deselect all accounts
      newAccountsToShow = accountsToShow.clear();
    } else { // Toggle selection of single accounts
      newAccountsToShow = accountsToShow.has(id) ? accountsToShow.delete(id) : accountsToShow.add(id);
    }

    setAccountsToShow(newAccountsToShow);
  }, [setAccountsToShow, accountsToShow, accountsById]);

  useEffect(() => {
    if (originalAccountsToShow && originalAccountsToShow?.size >= 0) {
      setAccountsToShow(Set(originalAccountsToShow));
    }
  }, [originalAccountsToShow]);

  return (
    <StdDialog
      open
      classes={isIe ? { paper: classes.dialogPaper } : {}}
      onClose={onCloseHandler}
      title="Calendar Accounts"
      maxWidth="xlg"
    >
      <DialogContent>
        <div>Select accounts to include:</div>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader padding="none" size="small">
            <TableHeader
              columns={tableColumns}
              classes={classes}
              selectedAccounts={accountsToShow.size}
              totalRows={accountsById.size}
              onChangeAccountsToShow={onChangeAccountsToShow}
            />
            <TableBody>
              {accountsById.map((account) => (
                <TableAccountRow
                  key={`row-${account.id}`}
                  classes={classes}
                  columns={tableColumns}
                  selected={accountsToShow.has(account.id)}
                  account={account}
                  onChangeAccountsToShow={onChangeAccountsToShow}
                />
              )).toList()}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={onResetHandler}
          id="txn-pref-reset-to-defaults"
          className={classes.resetButton}
        >
          Reset To Defaults
        </Button>
        <div className={classes.buttonArea}>
          <Button
            color="primary"
            onClick={onCloseHandler}
            id="txn-modal-done"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={onDoneHandler}
            id="txn-modal-done"
            disabled={!hasChanges}
          >
            Done
          </Button>
        </div>

      </div>

    </StdDialog>
  );
};

export default compose(
  DialogHoc(),
)(React.memo(CalAccountsPrefsModal));
