import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import numeral from 'numeral';
import classNames from 'classnames';
import { List } from 'immutable';

import { getLogger } from 'companion-app-components/utils/core';
import { transactionsTypes } from 'companion-app-components/flux/transactions';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import compose from 'utils/compose';

import { formatNumber } from 'components/QuickenControls/AmountField';

import QData from 'components/QData';
import QDialogs from 'components/QDialogs';

import { getReconcileInfoForAccount } from 'data/reconcileList/selectors';

import { connect } from 'react-redux';

import TransactionSelectList from './TransactionSelectList';

const log = getLogger('component/ReconcileWorkspace/index.js');

// ==============================================================
//
// Presents the form to bulk edit a group of transactions
//
//
class ReconcileWorkspace extends PureComponent {

  constructor(props) {
    super(props);
    this.state = this.calculateState(props);
    log.log('Reconcile Workspace Initialize');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reconcileInfo !== this.props.reconcileInfo) {
      this.setState(this.calculateState(nextProps));
    }
  }

  calculateState = (props) => {
    let payments = List();
    let deposits = List();

    if (props.reconcileInfo && props.reconcileInfo.get('reconcileTxns')) {
      payments = props.reconcileInfo.get('reconcileTxns').filter((x) => x.amount < 0);
      deposits = props.reconcileInfo.get('reconcileTxns').filter((y) => y.amount >= 0);
    }
    const difference = this.round2(props.reconcileInfo.get('clearedBalance')) - this.round2(props.statementEndBalance);

    return { payments, deposits, difference };
  };

  convertClearedToReconciled = (txns) => {
    const newTxns = txns.filter((x) => x.state === 'CLEARED').map((txn) => txn.set('state', 'RECONCILED'));
    this.props.qDataSaveTransactions(newTxns);
  };

  clearAll = () => {
    const txns = this.props.reconcileInfo.get('reconcileTxns');
    const newTxns = txns.map((txn) => txn.state !== 'CLEARED' ? txn.set('state', 'CLEARED') : null).filter((x) => x);
    this.props.qDataSaveTransactions(newTxns);
  };

  unclearAll = () => {
    const txns = this.props.reconcileInfo.get('reconcileTxns');
    const newTxns = txns.map((txn) => txn.state === 'CLEARED' ? txn.set('state', 'PENDING') : null).filter((x) => x);
    this.props.qDataSaveTransactions(newTxns);
  };

  //-------------------------------------------------------
  // onSave
  //
  onSave = () => {

    // convert all cleared transactions in the window to reconciled
    // if the balance is zero.  If not, offer to make a balance adjustment

    if (this.state.difference === 0) {
      this.convertClearedToReconciled(this.props.reconcileInfo.get('reconcileTxns'));
      setTimeout(() => this.closeAndExit('completed'), 500);
    } else {
      this.props.dialogAlert(
        'Balances do not match',
        'The total of items you have marked is ' +
        `${formatNumber(this.state.difference, this.props.account.currency, '0,00.00')} more than  ` +
        'the total of the items from your bank.',
        (ret) => {
          if (ret.btnPressed === 'Create a balance adjustment') {
            const newBal = this.round2(this.props.statementEndBalance); // - this.state.difference;
            // this.props.qDataSetBalanceAdjust(this.props.account.id, this.props.statementEndDate, newBal);
            const balAdjustTxn = new transactionsTypes.CashFlowTransaction(
              {
                accountId: this.props.account.id,
                amount: -this.state.difference,
                isReviewed: true,
                state: 'RECONCILED',
                payee: 'Reconciliation Balance Adjustment',
                memo: `Requested cleared balance on ${DateTime.fromISO(this.props.statementEndDate).toFormat('M/d/yyyy')}` +
                  ` to be ${numeral(newBal).format('0,00.00')}`,
                postedOn: this.props.statementEndDate,
                coa: {
                  type: 'BALANCE_ADJUSTMENT',
                  id: '0',
                },
              },
            );
            this.props.qDataSaveTransactions([balAdjustTxn]);

            this.convertClearedToReconciled(this.props.reconcileInfo.get('reconcileTxns'));
            setTimeout(() => this.closeAndExit('completed'), 500);
          }
        },
        ['Create a balance adjustment', 'return to reconcile'],
      );
    }

  };

  closeAndExit = (status) => {
    this.props.onClose(status);
  };

  //------------------------------------------------------
  // handleKeyDown
  //

  onKeyDown = () => {
    // const el = document.getElementById('reconcile-payments');
    // if (el && e.key === 'ArrowRight') {
    //   el.focus();
    // }

  };

  transactionClick = (txn) => {

    const newTxn = txn.set('state', txn.state === 'CLEARED' ? 'PENDING' : 'CLEARED');

    this.props.qDataSaveTransactions([newTxn]);
  };

  renderReconcile = () => {

    const { classes } = this.props;

    if (this.props.reconcileInfo && this.props.reconcileInfo.get('reconcileTxns')) {

      return (
        <Paper elevation={0}>
          <div style={{ display: 'flex' }}>
            <TransactionSelectList
              onClick={this.transactionClick}
              title="Payments and Checks"
              transactions={this.state.payments}
              className={classes.txList}
              showTotals
              onKeyDown={this.onKeyDown}
              id="reconcile-payments"
              currencyString={this.props.account.currency}
              accountType={this.props.account.type}
            />
            <TransactionSelectList
              onClick={this.transactionClick}
              title="Deposits"
              transactions={this.state.deposits}
              className={classNames(classes.txList, 'right')}
              showTotals
              onKeyDown={this.onKeyDown}
              id="reconcile-deposits"
              currencyString={this.props.account.currency}
              accountType={this.props.account.type}
            />
          </div>
        </Paper>
      );
    }
    return [];
  };

  round2 = (x) => Math.round(x * 100) / 100;

  /* eslint-disable arrow-body-style */
  renderReconcileWorkspace = (classes) => {

    const { account } = this.props;

    return (
      <>
        <Paper elevation={0} className={classes.container}>
          {this.renderReconcile()}
        </Paper>

        <Divider className={classes.divider} />

        <Paper elevation={0} className={classes.buttonHolderWithTotal}>

          <div style={{ flex: 1, textAlign: 'left', display: 'flex' }}>
            <div style={{ paddingLeft: 20 }}>
              <Typography variant="subtitle2">
                Statement Ending Balance
              </Typography>
              <Typography>
                {formatNumber(this.props.statementEndBalance, account.currency, '0,00.00')}
              </Typography>
            </div>
            <div style={{ paddingLeft: 20 }}>
              <Typography variant="subtitle2">
                Cleared Balance
              </Typography>
              <Typography>
                {formatNumber(this.props.reconcileInfo.get('clearedBalance'), account.currency, '0,00.00')}
              </Typography>
            </div>
            <div style={{ paddingLeft: 20 }}>
              <Typography variant="subtitle2">
                Difference
              </Typography>
              <Typography>
                {formatNumber(this.state.difference, account.currency, '0,00.00')}
              </Typography>
            </div>
            <div className={classes.markAllLabel}>
              <Typography variant="subtitle2">
                Mark all as:
              </Typography>
            </div>
            <Button
              className={classes.buttons}
              onClick={this.clearAll}
              color="primary"
              id="reconcile-clear-all"
            >
              Cleared
            </Button>
            <Typography 
              className={classes.separator}
            >
              |
            </Typography>
            <Button
              className={classes.buttons}
              onClick={this.unclearAll}
              color="primary"
              id="reconcile-unclear-all"
            >
              Uncleared
            </Button>
          </div>
          <Button
            className={classes.buttons}
            onClick={() => this.closeAndExit('canceled')}
            color="primary"
            id="reconcile-cancel"
          >
            Finish Later
          </Button>
          <Typography
            className={classes.separator}
          >
            |
          </Typography>
          <Button
            className={classes.buttons}
            onClick={this.onSave}
            name="reconcile-last-tab"
            color="primary"
            id="reconcile-save"
          >
            Finish Reconciliation
          </Button>
        </Paper>
      </>
    );
  };

  render() {

    const { classes, statementEndDate } = this.props;

    const title = `Reconcile Transactions up to ${DateTime.fromISO(statementEndDate).toFormat('MM/dd/yyyy')}`;

    return (
      <Dialog
        classes={{ paperWidthLg: this.props.classes.paperWidthMd }}
        open
        show="true"
        maxWidth={false}
        onClose={() => this.closeAndExit('canceled')}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <DialogTitle> {title} </DialogTitle>

        {this.renderReconcileWorkspace(classes)}

      </Dialog>
    );
  }
}

ReconcileWorkspace.propTypes = {
  statementEndDate: PropTypes.string,
  statementEndBalance: PropTypes.number,
  account: PropTypes.object,
  onClose: PropTypes.func,

  classes: PropTypes.object,
  reconcileInfo: PropTypes.object,
  qDataSaveTransactions: PropTypes.func,
  // qDataSetBalanceAdjust: PropTypes.func,
  dialogAlert: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    reconcileInfo: getReconcileInfoForAccount(state,
      {
        accountId: ownProps.account.id,
        statementDate: ownProps.statementEndDate,
        statementEndingBalance: ownProps.statementEndBalance,
      }),
  };
}

function mapDispatchToProps() {
  return {
  };
}

// These are prop injectors
export default compose(
  QDialogs(),
  QData(),
)(connect(mapStateToProps, mapDispatchToProps)(ReconcileWorkspace));

