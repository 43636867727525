// @flow
import React from 'react';
import { connect } from 'react-redux';

import { removeDialog } from 'data/rootUi/actions';
import ConfirmationDialog from '../ConfirmationDialog';

import type { RefreshTimeoutDialogProps } from './types';

export { DIALOG_TYPE, mkRefreshTimeoutDialogProps } from './types';
export { mkConfirmationDialogProps } from '../ConfirmationDialog/types';

type Props = RefreshTimeoutDialogProps & {
  removeDialog: (string) => void,
}

class RefreshTimeoutDialog extends React.PureComponent<Props> {

  handleClose = () => {
    if (this.props.dialogIdsToClose) {
      this.props.dialogIdsToClose.forEach((id) => this.props.removeDialog(id));
    }
  };

  render() {
    return (
      <ConfirmationDialog
        {...(this.props.confirmationDialogProps?.toJS?.() || this.props.confirmationDialogProps)}
        onClose={this.handleClose}
        sharedcomponentid={'DIALOG_REFRESH_TIMEOUT'}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    removeDialog: (dialogId) => dispatch(removeDialog(dialogId)),
  };
}

export default connect(null, mapDispatchToProps)(RefreshTimeoutDialog);

