const styles = ({ applyOpacityToHex, palette }) => ({
  dialogContentPadding: {
    maxWidth: 600,
    paddingBottom: 16,
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: 552,
  },
  leftSide: {
    width: 80,
    marginRight: 32,
  },
  rightSide: {
    flexGrow: 2,
    minWidth: 420,
    paddingRight: 6,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    paddingBottom: 16,
    paddingRight: 40,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: applyOpacityToHex(palette.greyScaleDeprecated[0], 0.15),
    marginBottom: 24,
  },
  dividerThin: {
    width: '100%',
    height: 1,
    backgroundColor: applyOpacityToHex(palette.greyScaleDeprecated[0], 0.15),
    marginBottom: 8,
  },
  name: {
    paddingBottom: 16,
  },
  amtField: {
    paddingRight: 16,
  },
  amtTxt: {
    textAlign: 'left',
  },
  categories: {
    inputField: {
      '& input': {
        '&::-ms-clear ': {
          display: 'none',
        },
      },
    },
  },
  catField: {
    paddingRight: 16,
  },
  noPadding: {
    padding: 0,
  },
  expansionPanelSummary: {
    flexDirection: 'row-reverse',
    padding: 0,
  },
  expansionPanelSummaryContent: {
    paddingLeft: 12,
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${palette.greyScaleDeprecated[5]}`,
    borderRadius: 4,
    padding: '6px 10px',
  },
  dateWidth: {
    width: '100%',
  },
  downShift: {
    width: '100%',
    paddingRight: 16,
  },
  accountField: {
    width: 276,
    paddingBottom: 16,
  },
  bottomSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomLeft: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
  },
  bottomRight: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
  },
  greyText: {
    color: palette.text.secondary,
  },
  type: {
    paddingTop: 8,
  },
  billAmount: {
    paddingRight: 16,
  },
});

export default styles;
