// @flow
import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    height: '100%',
  },
  progress: {
    color: theme.palette.primary.main,
    margin: 20,
  },
});


type Props = {
  size?: Number,

  // from withStyles
  classes: Object,
  style: Object,
  circularProgressStyle: Object,
  children?: React.Node,
  title?: string,
  subTitle?: string,
  id: string,
  in: boolean,
  className: Object,
};

const LoadingView = React.forwardRef((props: Props, ref) => {
  const { classes, size, style, circularProgressStyle, in: show, className } = props;

  return (
    <Fade
      in={show}
      timeout={800}
      mountOnEnter
      unmountOnExit
    >
      <div
        ref={ref}
        className={classNames(classes.root, className)}
        style={style}
        id={props.id}
        sharedcomponentid={'LOADING_VIEW'}
      >
        <CircularProgress
          className={classes.progress}
          size={size}
          style={circularProgressStyle}
        />

        {props.title && (
          <Typography variant="h6">
            {props.title}
          </Typography>
        )}

        {props.subTitle && (
          <Typography variant="subtitle2">
            {props.subTitle}
          </Typography>
        )}

        {props.children}
      </div>
    </Fade>
  );
});

LoadingView.defaultProps = {
  size: 40,
  in: true,
};

export default withStyles(LoadingView, styles);
