import { handleActions } from 'redux-actions';
import { List } from 'immutable';

import * as actions from 'data/subscriptions/subscriptionsActions';
import { resourceUpserters, resourceStoreTypes } from 'companion-app-components/flux/core';
// import { getLogger } from 'utils/logger';

import { getSubscriptionsOverride } from './helpers';

// const log = getLogger('data/subscriptions/subscriptionsReducer.js');

let projectSupportedSubscriptions;
/////////////////////////////////////
/////////////////////////////////////////
//////////////////////////////////////////////////////////
///
////////
projectSupportedSubscriptions = new Map([
  ['quicken-ca', {
    supportedDatasetPlatforms: List(['QWIN_CA', 'QWIN_CA_MWF', 'QMAC_MWF', 'QMAC']),
  }],
  ['quicken-us', {
    supportedDatasetPlatforms: List(['QMAC', 'QMAC_MWF', 'QWIN', 'QWIN_MWF']),
  }],
]);
/////////

const subscriptionsReducer = handleActions({

  [actions.getSubscriptions]:
    (state) => state.merge({ isLoading: true }),
  [actions.getSubscriptionsSuccess]:
    (state, { payload }) => {
      let subscriptions = payload.resources;

////////////////////////////////////////////////////////////////////////////////////////
      const subscriptionsOverridden = getSubscriptionsOverride();
      if (subscriptionsOverridden) {
        subscriptions = subscriptionsOverridden;
      }
///////////////

      subscriptions = subscriptions.reduce((list, subscription) => {
        const supportedSubscriptionInfo = projectSupportedSubscriptions.get(subscription.productLineUriName);
        if (supportedSubscriptionInfo) {
          list = list.push(subscription.merge({    // eslint-disable-line no-param-reassign
            supportedDatasetPlatforms: supportedSubscriptionInfo.supportedDatasetPlatforms,
          }));
        }
        return list;
      }, List());
      return resourceUpserters.upsertResources(state, payload.merge({ resources: subscriptions, replaceCollection: true }));
    },
  [actions.getSubscriptionsFailure]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

}, resourceStoreTypes.mkQcsSyncResourceStore());

export const SUBSCRIPTIONS_REDUCER_KEY = 'subscriptionsStore';
export default subscriptionsReducer;
