
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';

import { datasetsActions, datasetsSelectors } from 'companion-app-components/flux/datasets';

import { withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

// custom
import LoadingView from 'components/LoadingView';
import QButton from 'components/QButton';


export const useAlternateBackgroundColor = 'is-getting-started-acme-page';

const styles = (theme) => ({
  dialogStyle: {
    zIndex: '1200 !important',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    width: '100%',
    maxHeight: '100%',
    height: 500,
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 3,
  },
});

class ResolveDatasetDialog extends PureComponent { // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);

    if (!props.isDatasetsLoading) {
      props.dispatchGetDatasetsAction();
    }
  }

  render() {
    const { classes, datasets, isDatasetsLoadPending, isDatasetsLoading, datasetError, dispatchGetDatasetsAction } = this.props;

    return (
      <Dialog
        open={datasets.size === 0}
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
        disableEnforceFocus
        classes={{
          root: classes.dialogStyle,
        }}
      >
        <div className={classes.root}>
          {isDatasetsLoadPending && !isDatasetsLoading && datasets.size === 0 && datasetError ?
            <>

              <Typography variant="h4" style={{ paddingBottom: 24 }}>
                Oops!
              </Typography>
              <Typography variant="h6">
                {"We're having trouble retrieving your dataset."}
              </Typography>
              <QButton variant="contained" style={{ marginTop: 36 }} onClick={dispatchGetDatasetsAction}>
                Try Again
              </QButton>
            </>
            :
            <LoadingView />}
        </div>
      </Dialog>
    );
  }
}

ResolveDatasetDialog.propTypes = {
  classes: PropTypes.object,
  dispatchGetDatasetsAction: PropTypes.func,
  datasets: PropTypes.object,
  datasetError: PropTypes.object,
  isDatasetsLoadPending: PropTypes.bool,
  isDatasetsLoading: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    datasets: datasetsSelectors.getDatasetsById(state),
    isDatasetsLoadPending: datasetsSelectors.getLoadPending(state),
    isDatasetsLoading: datasetsSelectors.getIsLoading(state),
    datasetError: datasetsSelectors.getError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetDatasetsAction: () => dispatch(datasetsActions.getDatasets()),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(ResolveDatasetDialog, styles));
