import { call, put, takeEvery, race, take } from 'redux-saga/effects';

import { getLogger } from 'companion-app-components/utils/core';
import { resourceStoreTypes, resourceSagaUtils } from 'companion-app-components/flux/core';
import { getPreferencesV2, getPreferencesV2Failure, getPreferencesV2Success } from 'companion-app-components/flux/preferences-v2/preferencesV2Actions';

import * as actions from './actions';
import { transformResponseToInstitutions, transformResponseToInstitution, transformSearchResponseToInstitutions } from './transformers';

const log = getLogger('data/institutions/sagas');

const mkResourceConfig = (props) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'institutions',
  resourceBaseUrl: '/institutions',
  getLastSyncDate: null,
  transformResponseToResource: transformResponseToInstitution,
  ...props,
});

// =================================================================================================
// searchForInstitutions
// =================================================================================================

function* searchForInstitutions(action): Generator<*, *, *> {
  log.log('searchForInstitutions called...', action.payload);
  const { aggregator, searchString } = action.payload;

  const params = {
    aggregator,
    name: searchString,
  };

  yield call(resourceSagaUtils.qcsSyncGetResources, resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceBaseUrl: '/institutions/search',
    transformResponseToResources: transformSearchResponseToInstitutions,
    axiosConfig: { params },
    successAction: actions.searchForInstitutionsSuccess,
    failureAction: actions.searchForInstitutionsFailure,
  }), action);
}

function* getInstitution(action): Generator<*, *, *> {
  yield put(getPreferencesV2());
  yield race([
    take(getPreferencesV2Success),
    take(getPreferencesV2Failure),
  ]);

  const resourceConfig = mkResourceConfig({
    resourceBaseUrl: (institutionsAction) => `/institutions/${institutionsAction.payload.id}`,
    successAction: actions.getInstitutionResponse,
    failureAction: actions.getInstitutionResponse,
  });
  yield call(resourceSagaUtils.qcsSyncGetResource, resourceConfig, action);
}

// =============================================================================
// Watchers
// =============================================================================

function* getInstitutionActionWatcher(): Generator<*, *, *> {
  yield takeEvery(actions.getInstitution, getInstitution);
}

function* getPopularInstitutionsActionWatcher(): Generator<*, *, *> {
  const resourceConfig = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'popular-institutions',
    resourceBaseUrl: '/institutions/suggested',
    axiosConfig: {
      params: {
        limit: 10,
      },
    },
    getLastSyncDate: null,
    transformResponseToResources: transformResponseToInstitutions,
    successAction: actions.getPopularInstitutionsSuccess,
    failureAction: actions.getPopularInstitutionsFailure,
  });
  yield takeEvery(actions.getPopularInstitutions, resourceSagaUtils.qcsSyncGetResources, resourceConfig);
}

function* searchForInstitutionsActionWatcher(): Generator<*, *, *> {
  yield takeEvery(actions.searchForInstitutions, searchForInstitutions);
}

// =============================================================================
// Exports
// =============================================================================

export default [
  getInstitutionActionWatcher,
  getPopularInstitutionsActionWatcher,
  searchForInstitutionsActionWatcher,
];

