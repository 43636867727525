// @flow
import { createAction as reduxActionsCreator } from 'redux-actions';
import { identity, omit } from 'lodash';

// enhances the default create action to pass along meta data from 2nd argument when using action
export const createAction = (type: string) => reduxActionsCreator(type, identity, (_, meta) => meta);

export const bindPromiseAction = (dispatch: any, actionCreator: any): any => (payload: any, meta: any) =>
  new Promise((resolve, reject) => dispatch(actionCreator(payload, { ...meta, resolve, reject })));

const blacklistForCpMetaForResponseAction = ['resolve', 'reject'];
const blacklistForCpMetaForEmbeddedAction = ['resolve', 'reject'];

export const cpMetaForResponseAction = (meta) =>
  meta ? omit(meta, blacklistForCpMetaForResponseAction) : null;

export const cpMetaForEmbeddedAction = (meta) =>
  meta ? omit(meta, blacklistForCpMetaForEmbeddedAction) : null;

export class StdFormError extends Error {

}
