
export const styles = (theme) => ({
  confirmationTextField: {
    marginTop: theme.spacing(1),
  },
  cancelButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    height: 36,
    color: theme.palette.secondary.main,
    borderRadius: 18,
  },
  tertiary: {
    marginRight: 'auto',
  },
});

