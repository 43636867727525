import { appSubmitPushNotificationToken } from 'data/app/actions';

import { getLogger } from 'companion-app-components/utils/core';

import { messaging } from 'swFirebaseInit';
import { getSwRegistration } from 'swMain';
import store from 'companion-app-components/utils/redux-store';
import { navigate } from 'NavigateSetter';

const log = getLogger('pushNotifications');

export const isPushNotificationsWaitingPermissions = () => Notification.permission === 'default';
export const isPushNotificationsAccepted = () => Notification.permission === 'granted';
export const isPushNotificationsDenied = () => Notification.permission === 'denied';

// TODO: Can we / should we, relate token to datasetId?
const getToken = async () => {
  try {
    const token = await messaging.getToken({ serviceWorkerRegistration: getSwRegistration() });
    store.dispatch(appSubmitPushNotificationToken(token));
  } catch (e) {
    log.error('An error occurred while retrieving token. ', e);
  }
};

export const initPushNotificationsIfAccepted = () => {
  if (!messaging || !isPushNotificationsAccepted()) {
    return;
  }

  getToken();
  messaging.onTokenRefresh(async () => {
    getToken();
  });
};

export const enablePushNotifications = async () => {
  await messaging.requestPermission().catch((e) => {
    log.info('Push Notification Permissions Denied. ', e);
    return false;
  });

  initPushNotificationsIfAccepted();
  return true;
};

if (messaging) {
  messaging.onMessage((payload) => {
    log.debug('Received Push Notification', payload);
  });

  navigator?.serviceWorker?.addEventListener('message', (event) => {
    log.debug('Received message from service-worker', event);

    const { data } = event;
    if (data && data.messageType === 'notification-clicked') {
      const path = data.notification.click_action;
      if (path) {
        navigate(path);
      }
    }
  });
}

