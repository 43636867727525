import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import compose from 'utils/compose';
import QPreferences from 'components/QPreferences';
import { tracker } from 'companion-app-components/utils/core';

import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

import { defaultDatasetPreferences } from 'data/preferences/defaults';

import { isOldEdge } from 'utils/utils';

// ==============================================================
//
// Presents the form to edit register preferences
//

const isIe = require('is-iexplorer') || isOldEdge();  // eslint-disable-line

export const styles = (theme) => ({

  dialogPaper: {
    overflowY: 'visible',
  },
  preferencesText: {
    fontSize: theme.components.register.fontSize.default,
  },
  buttonArea: {
    padding: 10,
    paddingTop: 0,
  },
  link: {
    verticalAlign: 'inherit',
    marginLeft: 4,
    textDecoration: 'underline',
    color: 'blue',
    '&:focus': {
      background: '#dedede',
    },
    width: 'inherit',
    textAlign: 'left',
    justifyContent: 'left',
    textTransform: 'initial',
  },
});

const PREFS_DATA = [
  {
    label: 'Allow editing amounts of downloaded transactions',
    field: 'editDownloaded',
    id: 'txn-pref-edit-downloaded-txns',
    fflag: 'prefAllowEditingAmounts',
  },
  {
    label: 'Allow deletion of downloaded transactions',
    field: 'deleteDownloaded',
    id: 'txn-pref-delete-downloaded-txns',
    fflag: 'prefDeleteDownloaded',
  },
  {
    label: 'Automatically save edited transactions',
    field: 'autoSave',
    id: 'txn-pref-auto-save-edited-txns',
    fflag: 'prefAutoSaveEditedTransactions',
  },
  {
    label: 'Show separate Money In and Money Out columns',
    field: 'doubleAmounts',
    id: 'txn-pref-show-money-in-money-out',
    fflag: 'prefMoneyInOutColumn',
  },
  {
    label: 'Automatically search in register as you type in search field',
    field: 'autoSearch',
    id: 'txn-pref-auto-search',
    fflag: 'prefAutoSearch',
  },
  {
    label: 'Wrap text in transaction list fields',
    field: 'wrapText',
    id: 'txn-pref-wrap-text-in-txn-field',
    fflag: 'prefWrapText',
  },
  {
    label: 'Show long form of category names (show full path with parent names)',
    field: 'longCats',
    id: 'txn-pref-show-long-category-names',
    fflag: 'prefLongCatName',
  },
  {
    label: 'Show currency symbol on amounts in the transaction list',
    field: 'showCurrencySymbol',
    id: 'txn-pref-show-currency-symbol-on-amounts',
    fflag: 'prefCurrencySymbolTransactionList',
  },
  {
    label: 'After adding a new transaction include new transaction entry above all existing transactions',
    field: 'continuousCreate',
    id: 'txn-pref-new-txn-entry-above-txns',
    fflag: 'prefNewTransactionEntry',
  },
  {
    label: 'Show colored icon in transaction list instead of full account name',
    field: 'showAccountColors',
    id: 'txn-pref-show-account-colors',
    fflag: 'prefColoredAccounts',
  },
];

export class RegPrefsModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAdvanced: false,
    };
  }

  //------------------------------------------------------
  // handleCheckboxChange
  //
  //
  handleChange = (event, field) => {

    if (event && event.target) {
      this.props.setDatasetPreference({ transactionRegister: { [field]: event.target.value } });
    }
    tracker.track(tracker.events.txnPrefs, {
      type: field,
      state: event.target.value,
    });
  };

  handleCheck = (field) => (event, checked) => {
    if (event && event.target) {
      this.props.setDatasetPreference({ transactionRegister: { [field]: checked } });
    }
    tracker.track(tracker.events.txnPrefs, {
      type: field,
      state: checked,
    });
  };

  closeAndExit = () => {
    this.props.onClose();
  };

  resetDefaults = () => {
    tracker.track(tracker.events.txnPrefs, {
      type: 'reset',
    });
    this.props.setDatasetPreference({ transactionRegister: defaultDatasetPreferences.transactionRegister });
  };

  /* eslint-disable arrow-body-style */
  createPreferencesItems = (prefs, prefsData) => {

    const elements = prefsData.map((pref) => {

      if (!pref.fflag || this.props[pref.fflag] || this.state.showAdvanced) {

        if (pref.options) {
          const prefOptions = pref.options.map((x) => {
            return <option key={x} value={x?.toLowerCase()}> {x} </option>;
          });

          return (
            <div key={pref.label} style={{ display: 'flex' }}>
              <Typography
                className={this.props.classes.preferencesText}
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
              >
                {pref.label}
              </Typography>
              <Select
                id={pref.id}
                native
                style={{ marginLeft: 'auto', marginRight: '12px', fontSize: '13px' }}
                onChange={(e) => this.handleChange(e, pref.field)}
                options={pref.options}
                value={prefs[pref.field]}
              >
                {prefOptions}
              </Select>
            </div>
          );
        }
      } else {
        return null;
      }

      return (
        <div
          style={{ display: 'flex' }}
          key={`RegPrefsItem-${pref.field}`}
        >
          <Typography
            className={this.props.classes.preferencesText}
            style={{ flexGrow: 2, marginTop: 'auto', marginBottom: 'auto' }}
          >
            {pref.label}
          </Typography>
          <Switch
            style={{ float: 'right' }}
            color="primary"
            checked={prefs[pref.field]}
            value={prefs[pref.field] ? 'on' : 'off'}
            onChange={this.handleCheck(pref.field)}
            id={pref.id}
          />
        </div>
      );
    });
    return elements;

  };

  render() {

    const { classes, onClose, datasetPreferences } = this.props;

    const advancedPrefs = PREFS_DATA.filter((x) =>
      (x.fflag && (this.props[x.fflag] === 'advanced' || !this.props[x.fflag])));
    const prefsData = PREFS_DATA.filter((x) =>
      (!x.fflag || (this.props[x.fflag] === true || this.props[x.fflag] === 'show')));

    const noAdvanced = advancedPrefs.length === 0;

    return (
      <Dialog
        open
        classes={isIe ? { paper: classes.dialogPaper } : {}}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >

        <DialogTitle>
          Transactions Preferences
        </DialogTitle>

        <DialogContent>
          <FormControl component="fieldset">
            {datasetPreferences && datasetPreferences.transactionRegister &&
              <FormGroup>

                {this.createPreferencesItems(datasetPreferences.transactionRegister, prefsData)}
                {this.props.prefAdvancedOption && !noAdvanced &&
                  <Divider />}
                {advancedPrefs.length > 0 && this.state.showAdvanced &&
                  <>
                    <Typography
                      variant="body1"
                      style={{ marginTop: 10 }}
                      className={this.props.classes.preferencesText}
                    >
                      Advanced Options
                    </Typography>
                    {this.createPreferencesItems(datasetPreferences.transactionRegister, advancedPrefs)}
                  </>}

              </FormGroup>}

          </FormControl>
          {this.props.prefAdvancedOption && !noAdvanced &&
            <ButtonBase
              label="Advanced options"
              className={classes.link}
              onClick={() => this.setState({ showAdvanced: !this.state.showAdvanced })}
              style={{ marginTop: 10, display: 'block', paddingLeft: 0 }}
              id={`pref:link-advanced:${this.state.showAdvanced ? 'on' : 'off'}`}
            >
              <Typography
                className={classes.link}
              >
                {this.state.showAdvanced ? '<<< Hide Advanced' : 'Advanced Options >>>'}
              </Typography>
            </ButtonBase>}
        </DialogContent>

        <div className={classes.buttonArea}>
          <Button
            style={{ float: 'left' }}
            color="primary"
            onClick={this.resetDefaults}
            id="txn-pref-reset-to-defaults"
          >
            Reset To Defaults
          </Button>
          <Button
            style={{ float: 'right' }}
            color="primary"
            onClick={this.closeAndExit}
            id="txn-pref-done"
          >
            Done
          </Button>
        </div>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    prefMoneyInOutColumn: featureFlagsSelectors.getFeatureFlags(state).get('prefMoneyInOutColumn'),
    prefCurrencySymbolTransactionList: featureFlagsSelectors.getFeatureFlags(state).get('prefCurrencySymbolTransactionList'),
    prefNewTransactionEntry: featureFlagsSelectors.getFeatureFlags(state).get('prefNewTransactionEntry'),
    prefColoredAccounts: featureFlagsSelectors.getFeatureFlags(state).get('prefColoredAccounts'),
    prefLongCatName: featureFlagsSelectors.getFeatureFlags(state).get('prefLongCatName'),
    prefAdvancedOption: featureFlagsSelectors.getFeatureFlags(state).get('prefAdvancedOption'),
    prefWrapText: featureFlagsSelectors.getFeatureFlags(state).get('prefWrapText'),
    prefAllowEditingAmounts: featureFlagsSelectors.getFeatureFlags(state).get('prefAdvaprefAllowEditingAmountsncedOption'),
    prefDeleteDownloaded: featureFlagsSelectors.getFeatureFlags(state).get('prefDeleteDownloaded'),
    prefAutoSaveEditedTransactions: featureFlagsSelectors.getFeatureFlags(state).get('prefAutoSaveEditedTransactions'),
  };
}

function mapDispatchToProps() {
  return {
  };
}

RegPrefsModal.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func,
  datasetPreferences: PropTypes.object,
  setDatasetPreference: PropTypes.func,
  prefAdvancedOption: PropTypes.bool,
};


export default compose(
  QPreferences(),
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(RegPrefsModal, styles));

