// @flow
import { Map as ImmutableMap, Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

type StdFormProps = {
  id: string,
  type: string,

  cancelled: boolean,
  submitSucceeded: boolean,
  submitFailed: boolean,
  submitResponseData: ?any,
}
export type StdForm = RecordOf<StdFormProps>;
export const mkStdForm: RecordFactory<StdFormProps> =
  Record({
    id: '0',
    type: 'StdForm',

    cancelled: false,
    submitSucceeded: false,
    submitFailed: false,
    submitResponseData: null,
  });

type StdFormsStoreProps = {
  stdFormsById: ImmutableMap<string, StdForm>,
}
export type StdFormsStore = RecordOf<StdFormsStoreProps>;
export const mkStdFormsStore: RecordFactory<StdFormsStoreProps> =
  Record({
    stdFormsById: ImmutableMap(),
  });
