const Styles = ({ spacing, palette, components }) => ({
  headingStyle: {
    fontWeight: 500,
    textAlign: 'left',
  },
  dialogContentStyle: {
    backgroundColor: palette.background.default,
    paddingBottom: 0,
  },
  subHeadTextStyle: {
    fontSize: 14.5,
    marginBottom: spacing(1),
    textAlign: 'center',
  },
  qScrollStyle: {
    backgroundColor: palette.grey.level0,
    padding: '0 15px', 
    borderRadius: 5,
    width: 400,
  },
  textCenter: {
    textAlign: 'center',
  },
  buttonTextColor: {
    color: components.yearlyBudget.buttonText,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: 224,
    gap: '7px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
  },
  flexMontlyItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
  },
  hideMonthlyItem: {
    display: 'none',
  },
  monthLabel: {
    width: 28,
    fontSize: 14,
  },
  input: {
    color: palette.black,
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: 0,
    fontSize: components.register.fontSize.default,
    width: 100,
    alignItems: 'right',
    '& input': {
      padding: '0 5px',
      fontSize: components.register.fontSize.default,
      border: `1px solid ${components.yearlyBudget.inputBorder}`,
      borderRadius: 5,
      height: 28, // IE-11 needs this
      '&::-ms-clear ': {
        display: 'none',
      },
    },
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  selectBox: {
    width: 100,
    height: 30,
  },
  budgetTexts: {
    width: 150,
    fontSize: 14.5,
    textAlign: 'right',
    marginRight: 10,
  },
  actionsWrapper: {
    backgroundColor: palette.background.default,
  },
  applySelectBox: {
    padding: 0,
    width: 170,
    height: 30,
    fontSize: 14,
    marginBottom: 15,
  },
  dialogTitle: {
    paddingLeft: 24,
    justifyContent: 'left',
  },
  yearText: {
    backgroundColor: palette.white,
    padding: '0 20px',
  },
  calcButtonWrapper: {
    paddingTop: spacing(1),
  },
});

export default Styles;
