import React, { lazy, Suspense } from 'react';
import LoadingView from 'components/LoadingView';
import retryPromise from 'utils/retryPromise';
const Component = lazy(() => retryPromise(() => import('./index')));

const loadable = (props) =>
  <Suspense fallback={<LoadingView />}>
    <Component {...props} />
  </Suspense>;

export default loadable;
