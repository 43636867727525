import React from 'react';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import QTypography from 'components/MUIWrappers/QTypography';
import PropTypes from 'prop-types';

import { isAcme } from 'isAcme';

import { clrFieldOptions } from 'components/TransactionRegister/transactionsConfig';


const styles = (theme) => ({

  clrFieldIcon: {
    border: 'solid',
    borderWidth: '2px',
    borderColor: theme.palette.greyScaleDeprecated[4],
    borderRadius: theme.shape.borderRadius * 6,
    width: '24px',
    height: '24px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 1,
    ...clrFieldOptions.styles(theme),
  },
  clrFieldText: {
    borderRadius: theme.shape.borderRadius * 6,
    padding: `2px ${theme.shape.padding[4]}px`,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    ...clrFieldOptions.styles(theme),
  },
  iconFont: {
    fontSize: '13px',
    borderRadius: theme.spacing.borderRadius * 6,
    lineHeight: '21px',
  },
  iconText: {
    fontSize: '14px',
    borderRadius: '23px',
    lineHeight: '21px',
    background: isAcme ? 'transparent' : 'unset',
    ...clrFieldOptions.styles(theme),
  },
});

class ClrField extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.btnRef = null;
    this.actions = null;
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    event.stopPropagation();
  };

  handleClose = (item) => {
    this.setState({ anchorEl: null });
    if (this.props.onChange && item) {
      const e = { target: { value: item.state } };
      this.props.onChange(e);
    }
    setTimeout(this.setBtnFocus, 100);
  };

  onKeyDown = (event) => {
    if (event.key === 'Escape' || event.key === 'Enter') {
      event.stopPropagation();
      this.setState({ anchorEl: null });
    }
  };

  setBtnFocus = () => {
    if (this.btnRef && this.actions) {
      this.btnRef.focus();
      this.actions.focusVisible();
    }
  };

  getRef = (x) => {
    this.btnRef = x;
    if (this.props.inputRef) {
      this.props.inputRef(x);
    }
  };

  makeReconcileIcon = (val, isBlank = false) => {

    const { classes } = this.props;

    if (isBlank && val?.toLowerCase() === 'cleared') {
      return <div />;
    }
    if (isAcme) {
      return (
        <div
          className={classNames(classes.clrFieldText, val.replace(' ', '_'))}
        >
          <QTypography
            className={classNames(classes.iconText, val)}
            variant="caption"
            thinFont
          >
            {`${(val || ' ')}`}
          </QTypography>
        </div>
      );
    }
    return (
      <div
        className={classNames(classes.clrFieldIcon, val)}
      >
        <QTypography
          className={classes.iconFont}
          thinFont
        >
          {`${(val || ' ').toUpperCase().charAt(0)}`}
        </QTypography>
      </div>
    );
  };

  makeMenuItem = (item, hasIcon = true) => {

    if (item.menu) {
      const text = item.label;
      return (
        <MenuItem key={`clrmenu:${item.label}`} onClick={() => this.handleClose(item)}>
          {hasIcon &&
            <ListItemIcon>
              {this.makeReconcileIcon(item.label)}
            </ListItemIcon>}

          {!isAcme &&
            <ListItemText>
              {text}
            </ListItemText>}
        </MenuItem>
      );
    }
    return null;
  };

  render() {
    const { anchorEl } = this.state;
    const { value, autoFocus, inputRef, onChange, alternateMenu, classes, editable, includeEmpty, clearedIsBlank, ...other } = this.props;

    let menuItems = [];

    if (alternateMenu) { // currently doesn't display correctly for Simplifi, but is never used so it's OK
      menuItems = menuItems.concat(
        alternateMenu.map((item) =>
          this.makeMenuItem({ menu: true, label: item.label, state: item.value }, false))
      );
    } else {
      menuItems = clrFieldOptions.options.map((item) => this.makeMenuItem(item)).filter((x) => x !== null);
      if (includeEmpty) {
        menuItems.push(this.makeMenuItem({
          label: ' No Change',
          value: ' ',
        }));
      }
    }

    const currItemRef = clrFieldOptions.options.find((x) => x.state === value);
    const noChangeText = isAcme ? 'No Change' : ' ';

    if (editable || alternateMenu) {
      return (
        <div
          {...other}
        >
          <ButtonBase
            {...other}
            autoFocus={autoFocus}
            aria-owns={anchorEl ? 'clrField-menu' : undefined}
            aria-haspopup="true"
            focusRipple
            onClick={this.handleClick}
            ref={this.btnRef}
            action={(actions) => {
              if (autoFocus && actions) {
                this.actions = actions;
                actions.focusVisible();
              }
            }}
          >
            {this.makeReconcileIcon(currItemRef ? currItemRef.label : noChangeText, clearedIsBlank) }
          </ButtonBase>
          {editable &&
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => this.handleClose(null)}
              onKeyDown={this.onKeyDown}
            >
              {menuItems}
            </Menu>}
        </div>
      );
    }
    return this.makeReconcileIcon(currItemRef ? currItemRef.label : ' ', clearedIsBlank);
  }
}

ClrField.propTypes = {
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
  alternateMenu: PropTypes.array, // does not display correctly for Simplifi
  editable: PropTypes.bool,
  includeEmpty: PropTypes.bool,
  clearedIsBlank: PropTypes.bool,

  classes: PropTypes.object,
};


export default withStyles(ClrField, styles);
