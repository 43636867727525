import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Map as ImmutableMap } from 'immutable';

export const DIALOG_TYPE = 'TRANSACTION_DETAILS_DIALOG';

export const showTxnDetailsDialog = createAction(
  createDialog.toString(),
  ({ txn, ...otherProps }) => mkRootUiData({
    type: DIALOG_TYPE,
    id: `TRANSACTION_DETAILS_${txn?.id || '0'}`,
    props: ImmutableMap({ txn, ...otherProps }),
  })
);

