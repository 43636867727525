import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { makeStyles } from 'tss-react/mui';

import styles from './styles';
import { ChartDataType } from './utils';

const CHART_ID = 'progressChart';

interface ProgressChartProps {
  data: ChartDataType[];
}

const useStyles = makeStyles()(styles as Record<string, any>);

const ProgressChart: FC<ProgressChartProps> = ({ data }) => {

  const chartRef = useRef<HTMLDivElement>(null);
  const { classes, theme }: { classes: Record<string, any>, theme: Record<string, any> } = useStyles();

  useEffect(() => {
    const chart = am4core.create(chartRef.current || CHART_ID, am4charts.XYChart);
    chart.data = data;
    chart.responsive.enabled = true;
    chart.paddingLeft = 0;
    chart.paddingRight = 0;

    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.dataFields.category = 'label';
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;

    const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.labels.template.disabled = true;
    xAxis.min = 0;
    xAxis.max = data[data.length - 1].to;
    xAxis.strictMinMax = true; 

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = 'to';
    series.dataFields.openValueX = 'from';
    series.dataFields.categoryY = 'label';
    series.columns.template.propertyFields.fill = 'fill';
    series.columns.template.strokeOpacity = 0;
    series.columns.template.height = am4core.percent(100);

    const valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = '{graphValue}';
    valueLabel.label.fontSize = 12;
    valueLabel.label.fill = theme.components.progressChart.textLabel;
    valueLabel.label.truncate = false;
    valueLabel.label.hideOversized = false;
    valueLabel.locationX = 0.5;

    return () => {
      chart?.dispose();
    };

  }, [data, theme]);

  return (
    <div className={classes.progressChartWrapper}>
      <div 
        id={CHART_ID} 
        ref={chartRef}
        className={classes.progressChart}
      />
    </div>
  );
};

export default ProgressChart;
