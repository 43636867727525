import styled from 'styled-components';

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
`;

export const LabelSpan = styled.span`
   font-size: 14px;
   font-weight: 300;
   color: #666666;
`;

export const InputContainer = styled.div`
  padding-bottom: 8px;
`;

export const InputText = styled.input`
  font-size: 14px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 4px;
  border: ${(props) => props.showError ? '1px solid #c60c30' : '1px solid lightgray'};
  background: ${(props) => props.showError ? '#fdf4f5' : ''};
  padding-left: 8px;
  margin-top: 5px;
  width: 100%;
`;

export const Errors = styled.div`
  max-width: 300px;
  line-height: 1;
`;

export const ErrorText = styled.span`
  color: #c60c30;
  font-size: 12px;
`;

export const WarnText = styled.span`
  color: darkorange;
  font-size: 12px;
`;

export const HelpText = styled.span`
  color: #999999;
  font-size: 12px;
`;

export const ShowPassword = styled.span`
  font-weight: 400;
  font-size: 12px;
  float: left;
  margin-bottom: 2px;
`;

export const ShowPasswordCheckbox = styled.input`
  cursor: pointer;
  margin-top: 7px;
  margin-right: 3px;
`;

export const StateList = styled.select`
  width: 175px;
  border-color: #CCCCCC;
  border-width: 1px;
  border-style: solid;
  background-color: white;
  height: 34px;
  margin-top: 5px;
  border: ${(props) => props.showError ? '1px solid #c60c30' : '1px solid lightgray'};
  background: ${(props) => props.showError ? '#fdf4f5' : ''};
`;
