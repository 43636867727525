import { List as ImmutableList } from 'immutable';

export const getCoords = (elem: HTMLElement) => {
  const box = elem.getBoundingClientRect();
  return {
    top: box.top + window.scrollY,
    left: box.left + window.scrollX,
  };
};

export const regFieldsOrderDefault = ImmutableList(
  [
    'accountColor',
    'select',
    'account',
    'postedOn',
    'payee',
    'category',
    'split',
    'expense',
    'income',
    'amount',
    'save',
    'close',
    'menu',
  ],
);
