
import { removeSpecialCharacters } from 'components/Formik/utils';
import { mkBillerLogin, RefreshStatusEnum } from './types';

export const isRefreshDone = (billerLogin) => {
  if (!billerLogin) {
    return false;
  }
  const { refreshStatus } = billerLogin;
  switch (refreshStatus) {
    case RefreshStatusEnum.REFRESHING_CREDENTIALS_VERIFIED:
    case RefreshStatusEnum.REFRESHING:
      return false;
    default:
      return true;
  }
};

// Perhaps also keep if refreshStatus === 'LOCKED'?
// TODO: Check back to see if locked delete still errors
export const keepLogin = (billerLogin, status = false, MFA = false) => {
  if (MFA) {
    return true;
  }
  if (billerLogin?.refreshStatus === RefreshStatusEnum.REFRESHED
    || billerLogin?.refreshStatus === RefreshStatusEnum.CREDENTIALS_MISSING) {
    return true;
  }
  return status === 'ASSIGNED_TO_EXISTING';
};

export const isRefreshing = (billerLogin) => {
  if (!billerLogin) {
    return false;
  }
  const { refreshStatus } = billerLogin;
  return (refreshStatus === RefreshStatusEnum.REFRESHING_CREDENTIALS_VERIFIED || refreshStatus === RefreshStatusEnum.REFRESHING);
};

export const inError = (billerLogin) => {
  if (!billerLogin) {
    return false;
  }
  const { refreshStatus } = billerLogin;
  switch (refreshStatus) {
    case RefreshStatusEnum.REFRESHING_CREDENTIALS_VERIFIED:
    case RefreshStatusEnum.REFRESHING:
    case RefreshStatusEnum.REFRESHED:
    case RefreshStatusEnum.REFRESHED_PARTIAL:
      return false;
    default:
      return true;
  }
};

// used to add another field so 'descriptions' with special characters don't break formik
export const mkUIBillerLogin = (billerLogin) => {
  let newLogin = billerLogin;
  if (billerLogin?.credentials?.map) {
    newLogin = newLogin.set('credentials', billerLogin.credentials.map((item) => ({
      ...item,
      display: item.description,
      description: removeSpecialCharacters(item.description),
    })));
  }
  return mkBillerLogin(newLogin);
};
