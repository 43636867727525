
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import TextField from '@mui/material/TextField';

import SDatePicker from 'components/SDatePicker';

const readOnlyProps = { disableUnderline: true, readOnly: true };

function DateField(props) {
  const { date, onChange, onBlur, className, readOnly, allowNever, autoClose, allowInvalid, id, ...otherProps } = props;

  const [currentDate, setDate] = useState(date);
  const [renderKey, setRenderKey] = useState(false);

  let stagedDate = null;

  useEffect(() => {
    setDate(date);
  }, [date]);

  const showNever = Boolean(allowNever && (!currentDate || (currentDate === '')));

  const handleChange = (newDate) => {
    stagedDate = null;
    setDate(newDate);
    if ((allowInvalid || newDate?.isValid) || (allowNever && (newDate === null))) {
      stagedDate = newDate; // stage date to send it with onBlur if autoOk (auto close on modal select) is on
      onChange?.(newDate);
    }
  };

  const handleBlur = (e) => {
    let returnDate = date;
    if (showNever) { // if no date, set to null and force field rerender through renderKey (to keep 'Never' selected)
      if (e && (e.target?.value !== 'Never')) {
        setRenderKey(!renderKey);
      }
      returnDate = null;
    } else if (allowInvalid || currentDate?.isValid) {
      returnDate = currentDate;
    }
    if (!e && (allowInvalid || stagedDate?.isValid)) { // if date staged from change, use that
      returnDate = stagedDate;
    } else {
      stagedDate = null;
      setDate(returnDate);
    }
    onBlur?.(returnDate);
  };

  // const formatNull = (dateToFormat, invalidLabel) => {
  //   if (!dateToFormat) {
  //     return 'Never';
  //   }
  //   return dateToFormat?.toLocaleString() || invalidLabel;
  // };

  let field = (
    <SDatePicker
      key={renderKey}
      id={id || 'date-field'}
      value={(!currentDate || currentDate === '') ? null : currentDate}
      onChange={handleChange}
      onClose={handleBlur}
      textFieldProps={{
        onBlur: handleBlur,
        placeholder: showNever ? 'Never' : undefined,
        variant: readOnly ? 'standard' : 'outlined',
        className,
        margin: 'normal',
      }}
      label="Date"
      OpenPickerButtonProps={{
        'aria-label': 'change date',
      }}
      {...otherProps}
    />
  );

  if (readOnly) {
    field = (
      <TextField
        id={id || 'date-field'}
        margin="normal"
        label="Date"
        type="datetime-local"
        value={date}
        InputProps={readOnlyProps}
        classes={{ root: className }}
        {...otherProps}
      />
    );
  }

  return field;
}

DateField.defaultProps = {
  allowNever: false,
};

DateField.propTypes = {
  date: PropTypes.any, // serves as the 'initialValue' if uncontrolled, and 'value' if controlled
  onChange: PropTypes.func, // use if you want a controlled input
  onBlur: PropTypes.func, // use if you DON'T want a controlled input (just get value on blur)
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  allowNever: PropTypes.bool, // allow 'never' when no date is selected
  allowInvalid: PropTypes.bool, // will return invalid dates back to your forms, if you for some reason want that 🙃
  id: PropTypes.string,
};

export default DateField;
