// CORE
import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { chartOfAccountsUtils, chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';

// MUI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { makeStyles } from 'tss-react/mui';

// COMPONENTS
import AmountSpan from 'components/QuickenControls/AmountField/AmountSpan';
import Dump from 'components/Dump';
import QSkeleton from 'components/QSkeleton';

export const TransactionColumns = Object.freeze({
  accountName: 'accountName',
  postedOn: 'postedOn',
  payee: 'payee',
  securityOrPayee: 'securityOrPayee',
  descriptionOrCategory: 'descriptionOrCategory',
  category: 'category',
  amount: 'amount',
});

/** Expected Props
 * txns         ===> ordered list of transactions
 * columnOrder  ===> ordered list of columns, handled types include ACCOUNT_NAME, POSTED_ON, PAYEE, CATEGORY, AMOUNT
 * classes      ===> object containing key-value pairs like column-className, ie { payee: payeeClassName }
 */
const DEFAULT_COLUMNS = ['accountName', 'postedOn', 'payee', 'category', 'amount'];
const DEFAULT_GRID_SIZES = {
  [TransactionColumns.accountName]: '1fr',
  [TransactionColumns.postedOn]: '1fr',
  [TransactionColumns.payee]: '2fr',
  [TransactionColumns.securityOrPayee]: '2fr',
  [TransactionColumns.category]: '2fr',
  [TransactionColumns.descriptionOrCategory]: '2fr',
  [TransactionColumns.amount]: '1fr',
};

const useStyles = makeStyles()(({ spacing, orderedColumns }) => ({
  root: {
    height: 400,
    maxHeight: 'calc(50vh)',
    overflowY: 'auto',
  },
  item: {
    display: 'grid',
    // build the column template based on the columns passed
    gridTemplateColumns: orderedColumns.map((col) => DEFAULT_GRID_SIZES[col] || '2fr').join(' '),
    padding: `${spacing(1.5)} 0`,
    borderRadius: '0 !important',
  },
  columnItem: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: `0 ${spacing(1.5)}`,
  },
}));

export default function StaticTransactionList(props) {
  const { txns, orderedColumns = DEFAULT_COLUMNS, className, listItemProps, classes: overrideClasses, sortByDate, showSkeleton, ...otherProps } = props;

  const { classes, cx } = useStyles({ orderedColumns });

  const accountsById = useSelector(accountsSelectors.getAccountsById);
  const coaNodesById = useSelector(chartOfAccountsSelectors.getAllChartOfAccountNodesById);

  const renderItem = (col, txn) => {
    switch (col) {
      case TransactionColumns.accountName: {
        return accountsById?.get(txn.accountId)?.name || 'ERROR';
      }
      case TransactionColumns.postedOn: {
        return DateTime.fromISO(txn.postedOn).toFormat('MMM dd, yyyy') || 'ERROR';
      }
      case TransactionColumns.payee: {
        return txn.payee;
      }
      case TransactionColumns.securityOrPayee: {
        return txn.symbol || txn.payee;
      }
      case TransactionColumns.category: {
        const coaId = chartOfAccountsUtils.getCoaId(txn.coa);
        const coaNode = coaNodesById?.get(coaId) || 'ERROR';
        return coaNode.name;
      }
      case TransactionColumns.descriptionOrCategory: {
        const description = txn.units && txn.symbol && txn.costBasis ? `${txn.units} shares of ${txn.symbol} @ ${txn.costBasis}` : undefined;
        const coaId = chartOfAccountsUtils.getCoaId(txn.coa);
        const coaNode = coaNodesById?.get(coaId) || 'ERROR';
        return description || coaNode.name;
      }
      case TransactionColumns.amount: {
        return (
          <AmountSpan
            currencyString="USD"
            editable={false}
            initialValue={txn.amount}
            negator={-1}
            variant="body2"
          />
        );
      }
      default: {
        return 'UNHANDLED COLUMN';
      }
    }
  };

  const sortedTxns = sortByDate ?
    txns.sort((a, b) => {
      const dateA = DateTime.fromISO(a.postedOn);
      const dateB = DateTime.fromISO(b.postedOn);
      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      }
      return 0;
    })
    :
    txns;

  return (
    <List
      className={cx(classes.root, className)}
      {...otherProps}
    >
      <Divider />
      {(showSkeleton ? [{}] : sortedTxns).map((txn) => (
        <ListItem
          divider
          {...listItemProps}
          key={`static-txn-list-item-${txn.id}`}
          className={cx(classes.item, listItemProps.className)}
        >
          <Dump obj={txn} />
          {orderedColumns.map((col) => (
            <QSkeleton show={showSkeleton} key={`skeleton-${txn.id}-${col}`}>
              <Typography
                className={cx(classes.columnItem, overrideClasses[col])}
                variant="body2"
                component="span"
                key={`static-txn-list-column-item-${txn.id}-${col}`}
              >
                {renderItem?.(col, txn)}
              </Typography>
            </QSkeleton>
          ))}
        </ListItem>
      ))}
    </List>
  );
}

StaticTransactionList.propTypes = {
  txns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  orderedColumns: PropTypes.array,
  classes: PropTypes.object,
  className: PropTypes.string,
  listItemProps: PropTypes.object,
  sortByDate: PropTypes.bool,
  showSkeleton: PropTypes.bool,
};

StaticTransactionList.defaultProps = {
  orderedColumns: DEFAULT_COLUMNS,
  listItemProps: {},
  classes: {},
  sortByDate: true,
};
