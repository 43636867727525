/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-foreign-prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'lodash';

import { accountsSelectors } from 'companion-app-components/flux/accounts';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import QButton from 'components/QButton';
import FileImportDialog from 'components/Dialogs/FileImportDialog';
import QFilterSelect from 'components/QFilterSelect';
import SelectDatasetDialog from 'components/Dialogs/SelectDatasetDialog';

import StdDialog from 'components/Dialogs/StdDialog';
import StdDialogActions from 'components/Dialogs/StdDialogActions';


import { FilterObject } from 'components/QFilterSelect/types';

import { MODAL_COMPONENTS } from 'components/RootModal';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import Button from '@mui/material/Button';
import { Map as ImmutableMap } from 'immutable';

import { mkSharedComponent } from '../Wrappers/factories';

const rootUIArray = Object.entries(MODAL_COMPONENTS).map(([key, component]) => (
  mkSharedComponent({
    name: key,
    id: key,
    component: () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dispatch = useDispatch();
      const path = `/reports?showDialog=${key}`;
      return (
        <>
          <Button
            onClick={() => dispatch(createDialog(mkRootUiData({
              id: key,
              type: key,
              allowNesting: true,
              props: ImmutableMap({
                id: key,
                title: `${key}:${component?.name}`,
                name: key,
                subtitle: 'subtitle',
                buttons: ['button1', 'button2', 'button3'],
                content: <div>Sample content?</div>,
                confirmLabel: 'Yes',
                denyLabel: 'No',
              }),
            })))}
          >
            Click Me
          </Button>
          <Link href={path}>
            {path}
          </Link>
        </>
      );
    },
  })
));

const config = {
  name: 'Dialogs',
  id: 'DIALOGS',
  children: [
    {
      name: 'Standard Dialogs',
      id: 'STANDARD_DIALOGS',
      children: [
        mkSharedComponent({
          name: 'StdDialog',
          id: 'STD_DIALOG',
          importName: 'import StdDialog from \'components/Dialogs/StdDialog\';',
          componentPropTypes: StdDialog.propTypes,
          defaultState: {
            showStandardDialog: false,
          },
          component: (props) => (
            <>
              <QButton onClick={() => props.setstate({ showStandardDialog: true })}>Standard dialog</QButton>
              <StdDialog
                open={props.state.showStandardDialog}
                title="Standard Dialog Title"
                showCloseButton
                onClose={(_event, _reason) => props.setstate({ showStandardDialog: false })}
                showBackButton
                onBack={() => {
                  if (window.confirm('Go Back?\nOK - close')) { // eslint-disable-line no-alert
                    props.setstate({ showStandardDialog: false });
                  }
                }}
                {...props.exampleProps}
              >
                <>
                  <DialogContent>
                    Your content here
                  </DialogContent>

                  <DialogActions>
                    <QButton
                      variant="outlined"
                      onClick={() => props.setstate({ showStandardDialog: false })}
                    >
                      Cancel
                    </QButton>
                    <QButton
                      variant="contained"
                      type="submit"
                      onClick={() => props.setstate({ showStandardDialog: false })}
                    >
                      Do
                    </QButton>
                  </DialogActions>
                </>
              </StdDialog>
            </>
          ),
          examples: [
            {
              label: 'default',
              description: 'default standard dialog',
              props: {},
            },
            {
              label: 'hide close X button',
              description: 'showCloseButton = false',
              props: { showCloseButton: false },
            },
            {
              label: 'no header',
              description: 'title = null, showBackButton = false, showCloseButton = false',
              props: { title: null, showBackButton: false, showCloseButton: false },
            },
            {
              label: 'set header color',
              description: 'colorBar = transparent',
              props: { colorBar: 'cadetblue' },
            },
            {
              label: 'hide back button',
              description: 'showBackButton = false',
              props: { showBackButton: false },
            },
          ],
        }),
        mkSharedComponent({
          name: 'StdDialogActions',
          id: 'STD_DIALOG_ACTIONS',
          importName: 'import StdDialogActions from \'components/Dialogs/StdDialogActions\';',
          componentPropTypes: StdDialogActions.propTypes,
          defaultState: {
            // showStandardDialog: false,
          },
          component: (props) => (
            <StdDialogActions {...props.exampleProps} />
          ),
          examples: [
            {
              label: 'Default',
              description: 'Default action bar. Without a primary click handler, expects to be in a <form> control ' +
                '(or formik form) with a defiend action.',
              props: {},
            },
            {
              label: 'Add a secondary action',
              description: '',
              props: { secondaryOnClick: noop },
            },
            {
              label: 'Primary Label',
              description: 'primaryLabel = Continue',
              props: { primaryLabel: 'Continue' },
            },
            {
              label: 'set header color',
              description: 'colorBar = transparent',
              props: { colorBar: 'cadetblue' },
            },
            {
              label: 'hide back button',
              description: 'showBackButton = false',
              props: { showBackButton: false },
            },
          ],
        }),
        mkSharedComponent({
          name: 'File import Dialog',
          id: 'FILE_IMPORT_DIALOG',
          componentPropTypes: FileImportDialog.propTypes,
          defaultState: {
            showFileImportDialog: false,
          },
          component: (props) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const account = useSelector(accountsSelectors.getAccountsById)?.first();
            return (
              <>
                <QButton onClick={() => props.setstate({ showFileImportDialog: true })}>File Import Dialog</QButton>
                <FileImportDialog
                  open={props.state.showFileImportDialog}
                  account={account}
                  onHandleClose={() => props.setstate({ showFileImportDialog: false })}
                />
              </>
            );
          },
        }),
      ],
    },
    mkSharedComponent({
      name: 'Select Finances Dialog (SelectDatasetDialog)',
      id: 'SELECT_DATASET_DIALOG',
      defaultState: {
        showSelectDatasetDialog: false,
      },
      component: (props) => (
        <>
          <QButton onClick={() => props.setstate({ showSelectDatasetDialog: true })}>Select Finances Dialog</QButton>
          <SelectDatasetDialog
            open={props.state.showSelectDatasetDialog}
            onClose={() => props.setstate({ showSelectDatasetDialog: false })}
          />
        </>
      ),
    }),
    mkSharedComponent({
      name: 'QFilter Select',
      id: 'Q_FILTER_SELECT',
      componentPropTypes: QFilterSelect.propTypes,
      defaultState: {
        qFilterSelectShowEl: null,
        qFilterObject: new FilterObject(),
      },
      component: (props) =>
        <>
          <Typography>
            {`Provides a FilterObject. NumItems=${props.state.qFilterObject.numFilterItems}`}
          </Typography>
          <QButton
            onClick={(e) => {
              props.setstate({ qFilterSelectShow: e.currentTarget });
            }}
          >
            QFilter Modal Dialog
          </QButton>
          {props.state.qFilterSelectShow &&
          <QFilterSelect
            popover
            initialFilter={props.state.qFilterObject}
            anchorEl={props.state.qFilterSelectShow}
            allowFilterOut
            onClose={() => props.setstate({ qFilterSelectShow: null })}
            onApply={(filterObj) => {
              props.setstate({ qFilterSelectShow: null, qFilterObject: filterObj });
            }}
          />}
        </>,
    }),
    {
      name: 'Root Dialogs',
      id: 'ROOT_DIALOGS',
      children: rootUIArray,
    },
  ],
};

export default config;
