// @flow
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { accountsTypes, accountsUtils } from 'companion-app-components/flux/accounts';
import type { AccountTypeNode } from 'companion-app-components/flux/accounts';
import { entitlementsSelectors } from 'companion-app-components/flux/entitlements';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

import type { SelectProps as FormikSelectProps } from 'components/Formik/QSelect';

import NestedQMenu from 'components/NestedQMenu';

const useStyles = makeStyles()({
  field: {
    cursor: 'pointer',
  },
});

const getMenuOptionForNode = (node: AccountTypeNode, showManualTypes, currentNode, tier, showManualInvestment) => {
  if (!accountsUtils.isAccountNodeSelectable(node, currentNode, tier)) {
    return null;
  }
  if (node.selectable && (node.connectable || showManualTypes)
    && (!showManualTypes || node.id !== 'INVESTMENT' || (showManualTypes && showManualInvestment)) // temporary until manual holdings released
  ) {
    if (node.children) {
      const childMenuItems = node.children.reduce((menuItems, child) => {
        const menuItem = getMenuOptionForNode(child, showManualTypes, currentNode, tier, showManualInvestment);
        if (menuItem != null) {
          menuItems.push(menuItem);
        }
        return menuItems;
      }, []);

      return {
        label: node.displayName,
        value: node,
        onSelect: () => true,
        subMenu: childMenuItems,
      };
    }
    return { label: node.displayName, value: node };
  }
  return null;
};

type Props = FormikSelectProps & {
  currentAccountType: AccountTypeNode,
  textFieldProps: Object,
  showManualTypes: boolean,
  name: string,
  IconComponent: PropTypes.object,
};

const AccountTypeSelector = (props: Props) => {
  const {
    currentAccountType,
    name = 'accountType',
    showManualTypes,
    textFieldProps,
    IconComponent,
    ...other
  } = props;

  const { classes, cx } = useStyles();

  const tier = useSelector(entitlementsSelectors.getTopTierEntitlement);

  const showManualInvestment = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'manualHoldings'));

  const [anchorEl, setAnchorEl] = useState(null);

  const menuOptions = useMemo(() => accountsTypes.AccountTypeNodesTree.reduce((menuItems, node) => {
    const menuItem = getMenuOptionForNode(node, showManualTypes, currentAccountType, tier, showManualInvestment);
    if (menuItem != null) {
      menuItems.push(menuItem);
    }
    return menuItems;
  }, []), [currentAccountType, showManualInvestment, showManualTypes, tier]);

  const handleChange = (form) => (val, _event) => {
    form.setFieldValue(name, val);
    setAnchorEl(null);
  };

  return (
    <Field
      {...other}
      name={name}
    >
      {({ field, form }) => {
        let accountTypeFormValue = field.value?.displayName || currentAccountType?.displayName;
        accountTypeFormValue = accountTypeFormValue || form?.initialValues[name]?.displayName;
        accountTypeFormValue = accountTypeFormValue || '';
        return (
          <>
            <TextField
              {...field}
              onChange={() => true}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              value={accountTypeFormValue}
              variant="outlined"
              label={'Account Type'}
              id={other.id || 'account-type-select'}
              fullWidth
              {...textFieldProps}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </InputAdornment>
                ),
                ...textFieldProps?.InputProps,
              }}
              className={cx(classes.field, textFieldProps?.className)}
            >
            </TextField>
            <NestedQMenu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              options={menuOptions}
              onChange={handleChange(form)}
              onClose={() => setAnchorEl(null)}
            />
          </>
        );
      }}
    </Field>
  );
};

export default AccountTypeSelector;
