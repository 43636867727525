import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as mcRulesSelectors from 'data/mcRules/mcRulesSelectors';
import type { MCRuleMessage } from 'data/mcRules/mcRulesTypes';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import QButton, { QButtonVariantEnum } from 'components/QButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import * as mcRulesActions from 'data/mcRules/mcRulesActions';
import { removeDialog } from 'data/rootUi/actions';
import useEvent from 'hooks/useEvent';
import { tracker } from 'companion-app-components/utils/core';

const useStyles = makeStyles()((theme: Object) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  remindButton: {
    position: 'absolute',
    left: theme.spacing(1),
  },
  dialogContentPadding: {
    paddingTop: 0,
    paddingBottom: 20,
  },
  iframe: {
    opacity: 1, // this is needed to defeat the janky iframe opacity override on body (for userSnap)
  },
}));

const MCPDialog = (props) => {
  const { classes } = useStyles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const message: MCRuleMessage = useSelector((state) => mcRulesSelectors.getMessageById(state, props.id)) || {};
  const [open, setOpen] = useState(true);
  const [remindMeLater, setRemindMeLater] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    tracker.track(tracker.events.memberCommunicationDisplayMessage, {
      ruleId: message?.ruleId,
      ruleName: message?.ruleName,
      messageId: message?.id,
      hook: message?.hook,
      reDisplayCount: message?.reDisplayCount,
      variantName: message?.variantName,
    });
  }, [message]);

  useEvent('message', useCallback((event) => {
    if (event?.data?.type === 'mcp' && event?.data?.action === 'dismiss') {
      onClose();
    }
  }, [onClose]));

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  function onExit() {
    if (message && message.id) {
      dispatch(mcRulesActions.mcMessagesTrack({
        id: message.id,
        isRemindMeLater: message.canUserDismiss ? remindMeLater : undefined,
        reDisplayCount: message.reDisplayCount,
        ruleContext: message.ruleContext,
      }));
      tracker.track(tracker.events.memberCommunicationDismissMessage, {
        ruleContext: message?.ruleContext,
        type: message?.type,
        reDisplayCount: message?.reDisplayCount,
        ruleId: message?.ruleId,
        ruleName: message?.ruleName,
        hook: message?.hook,
        variantName: message?.variantName,
        didUserOptOut: remindMeLater ? 0 : 1,
        displayNameOptOut: message?.ruleGroup?.displayName,
      });
    }
    dispatch(removeDialog(props.dialogId));
  }

  return (
    <Dialog
      open={Boolean(message && open)}
      aria-labelledby="message-from-app"
      onClose={onClose}
      TransitionProps={{
        onExit,
      }}
    >
      <DialogTitle sharedcomponentid={'MCP_DIALOG'} id="important-message-dialog-title">
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        {props.title}
      </DialogTitle>
      <DialogContent className={classes.dialogContentPadding}>
        <iframe
          className={classes.iframe}
          title={message.id}
          width={message.contentWidth}
          height={message.contentHeight}
          srcDoc={message.messageText ? message.messageText : undefined}
          src={message.messageUrl ? message.messageUrl : undefined}
        />
      </DialogContent>
      <DialogActions>
        {message && message.canUserDismiss &&
        <FormControlLabel
          className={classes.remindButton}
          checked={remindMeLater}
          control={<Checkbox color="primary" />}
          label="Remind me later"
          labelPlacement="end"
          onChange={(event, checked) => setRemindMeLater(checked)}
        />}
        <QButton
          type="submit"
          variant={QButtonVariantEnum.CONTAINED}
          onClick={onClose}
        >
          Close
        </QButton>
      </DialogActions>
    </Dialog>
  );
};

MCPDialog.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  dialogId: PropTypes.string,
};

export default MCPDialog;
