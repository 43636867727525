export const styles = (theme) => ({
  root: {
    height: 400,
    width: 1000,
    margin: 'auto',
  },
  paperWidthMd: {
    maxWidth: 850,
  },
  scrollPaper: {
    maxWidth: 'unset',
    maxHeight: 'unset',
  },
  paper: {
    maxWidth: 'unset',
    maxHeight: 'unset',
  },
  base: {
    fontWeight: 200,
    padding: 3,
  },
  title: {
    padding: 3,
    fontSize: '25px',
    fontWeight: 400,
  },
  subTitle: {
    fontSize: '20px',
    fontWeight: 200,
    padding: 3,
  },
  successTitle: {
    fontWeight: 200,
    padding: 3,
    fontSize: '22px',
  },
  successSubTitle: {
    fontWeight: 200,
    padding: 3,
    fontSize: '18px',
  },
  buttonArea: {
    marginBottom: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  setupButton: {
    borderRadius: 3,
  },
  skipTextButton: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(),
    fontWeight: theme.typography.fontWeightLight,
  },
  skipSecondaryTextButton: {
    color: theme.palette.text.lightContrast,
    backgroundColor: theme.palette.secondary.main,
  },
  statusBar: {
    border: 'none',
    borderBottom: 'solid 1px #bbb',
  },
  bottomCaption: {
    padding: theme.spacing(1.5, 6, 1),
  },
});

