// @flow
import { createAction } from 'redux-actions';

export const createPayeeList = createAction('UPDATE_PAYEE_LIST');
export const createPayeeListSuccess = createAction('UPDATE_PAYEE_LIST_SUCCESS');
export const createPayeeListFailure = createAction('UPDATE_PAYEE_LIST_FAILURE');


export const updatePayeeListAsync = createAction('UPDATE_PAYEE_LIST_ASYNC');
export const updatePayeeList = createAction('UPDATE_PAYEE_LIST');
export const updatePayeeListSuccess = createAction('UPDATE_PAYEE_LIST_SUCCESS');
export const updatePayeeListFailure = createAction('UPDATE_PAYEE_LIST_FAILURE');

