export const styles = ({ palette, _components }) => ({
  dialogRoot: {
    padding: 30,
    paddingTop: 10,
    minHeight: 500,
    maxWidth: 555,
  },
  dividerRoot: {
    backgroundColor: palette.cloudyGrey,
  },
  headerRow: {
    background: '#fff0f3',
  },
  header: {
    padding: '15px 0px 15px 0px',
  },
  title: {
    marginBottom: 10,
  },
  sectionHeader: {
    marginTop: 15,
    borderBottom: 'solid',
    borderBottomColor: palette.cloudyGrey,
    borderBottomWidth: 1,
  },
  dialogActions: {
    marginTop: 15,
  },
  qCard: {
    zIndex: 5000,
  },
});
