import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { v4 as uuid } from 'uuid';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { datasetSharesTypes, datasetSharesActions } from 'companion-app-components/flux/dataset-shares';
import { removeDialog } from 'companion-app-components/flux/root-ui/rootUiActions';
import { tracker } from 'companion-app-components/utils/core';

import { isEmail } from 'containers/App/Header/ProfileMenu/utils';
import { DIALOG_TYPE_SHARE_FINANCES } from './actions';
import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface ShareFinancesDialogProps {
  id: string;
  name: string;
}

const ShareFinancesDialog: FC<ShareFinancesDialogProps> = ({ name, id }) => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();
  const { classes } = useStyles();

  const handleClose = () => {
    dispatch(removeDialog(DIALOG_TYPE_SHARE_FINANCES));
  };

  const handleInvite = () => {
    const createPayload = datasetSharesTypes.mkDatasetShare({
      clientId: uuid(),
      datasetId: id,
      granteeFirstName: firstName,
      granteeLastName: lastName,
      granteeEmail: email,
      state: datasetSharesTypes.DATASET_SHARING_STATES.INVITED,
    });
    dispatch(datasetSharesActions.createDatasetShare(createPayload));
    tracker.track(tracker.events.datasetSharing, { property: 'inviteClicked' });
    handleClose();
  };

  const inviteEnabled = firstName.trim() && lastName.trim() && email.trim() && isEmail(email);
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  
  const handleEmailKeyPress = (e) => {
    if (e.key === 'Enter' && inviteEnabled) {
      handleInvite();
    }
  };

  return (
    <Dialog
      open
      sx={{ '& .MuiPaper-root': { padding: '0 20px' } }}
    >
      <DialogTitle
        className={classes.dialogTitleWrapper}
      >
        <Typography
          variant="h6" 
          className={classes.title}
        >
          Share finances
        </Typography>
        <Typography
          variant="body2"
          className={classes.textTertiary}
        >
          Enter details of the Quicken member who will share your financial data.
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className={classes.closeButton}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogContent dividers>
        <Typography variant="h5" className={classes.weight400}>
          {name}
        </Typography>
        <Typography gutterBottom variant="caption" className={classes.textTertiary}>
          Sharing with Quicken member
        </Typography>
        <div className={classes.formWrapper}>
          <div>
            <Typography variant="caption">
              First Name
            </Typography>
            <TextField 
              variant="outlined"
              fullWidth
              size="small"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <Typography variant="caption">
              Last Name
            </Typography>
            <TextField 
              variant="outlined"
              fullWidth
              size="small"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div>
          <Typography variant="caption">
            Email
          </Typography>
          <TextField 
            variant="outlined"
            fullWidth
            size="small"
            value={email}
            onChange={handleEmailChange}
            onKeyPress={handleEmailKeyPress}
          />
        </div>
        <Alert 
          icon={false} 
          className={classes.alert}
        >
          By sharing your data, the invited member will have access to view and modify all your accounts, transactions, and other details.
        </Alert>
      </DialogContent>
      <DialogActions
        className={classes.dialogAction}
      >
        <Button 
          onClick={handleClose}
          variant="outlined"
          size="small"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          disabled={!inviteEnabled}
          onClick={handleInvite}
        >
          INVITE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareFinancesDialog;
