import { createAction } from 'redux-actions';

export const getSubscriptions = createAction('GET_SUBSCRIPTIONS');
export const getSubscriptionsSuccess = createAction('GET_SUBSCRIPTIONS_SUCCESS');
export const getSubscriptionsFailure = createAction('GET_SUBSCRIPTIONS_FAILURE');

export const updateSubscriptionRenewalFrequency = createAction('UPDATE_SUBSCRIPTION_RENEWAL_FREQUENCY');
export const updateSubscriptionRenewalFrequencySuccess = createAction('UPDATE_SUBSCRIPTION_RENEWAL_FREQUENCY_SUCCESS');
export const updateSubscriptionRenewalFrequencyFailure = createAction('UPDATE_SUBSCRIPTION_RENEWAL_FREQUENCY_FAILURE');

