import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  const accDetails = {
    paddingLeft: 6,
    height: 19,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.greyScaleDeprecated[2],
  };
  const plainBackground = {
    '&:hover': {
      background: theme.palette.grey.level1,
    },
  };
  return ({
    accDetails,
    greyAccDetails: {
      ...accDetails,
      color: `${theme.palette.grey.level3} !important`,
    },
    borderDiv: {
      border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
      borderRadius: theme.shape.borderRadius * 2,
      margin: `${theme.spacing(1)} 0`,
      padding: theme.spacing(1),
      alignItems: 'center',
      display: 'flex',
    },
    cell: {
      margin: '0 1%',
      borderBottom: 'none',
      display: 'flex',
      justifyContent: 'right',

      '&:first-child': {
        marginLeft: '0px',
      },
      '&:last-child': {
        marginLeft: '0px',
      },
    },
    errorOutline: {
      '& div': {
        '& fieldset': {
          borderColor: theme.palette.error.main,
          borderWidth: 1,
        },
      },
    },
    errorPadding: {
      paddingBottom: 20,
    },
    errorType: {
      paddingLeft: 6,
      height: 19,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: `${theme.palette.error.main} !important`,
    },
    fields: {
      borderRadius: theme.shape.borderRadius,
      '& div': {
        '& fieldset': {
          // border: 'none',
          borderWidth: 0,
        },
      },
    },
    ignoreFields: {
      '& div': {
        color: `${theme.palette.grey.level3} !important`,
        '& fieldset': {
          borderWidth: 0,
        },
      },
    },
    ignoreTypeBox: {
      ...plainBackground,
      padding: `0 ${theme.spacing(1)}`,
      '&:hover': {
        background: theme.palette.grey.level0,
      },
      '& div': {
        color: theme.palette.grey.level3,
        '& div': {
          paddingLeft: 8,
        },
      },
    },
    nameInput: {
      padding: '6px 6px',
      width: '260px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
    },
    nameInputFocus: {
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      '&:hover': {
        background: theme.palette.grey.level1,
      },
    },
    nameSpace: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
    },
    paddedSubheader: {
      paddingLeft: 12,
    },
    plainBackground,
    typeBox: {
      borderRadius: theme.shape.borderRadius,
      padding: `0 ${theme.spacing(1)}`,
      '& div': {
        '& div': {
          paddingLeft: 8,
        },
      },
    },
  });
});


