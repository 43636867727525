import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

// eslint-disable-next-line no-console,import/no-mutable-exports
let navigate: NavigateFunction = () => console.warn('Attempted history nav before init!');

type Props = {
  children: React.ReactNode,
};

export function NavigateSetter({ children } : Props) {
  navigate = useNavigate();

  return children;
}

export default NavigateSetter;

export { navigate };
