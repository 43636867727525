/*
 * PREFERENCE STORE SAGAS
 *
 * These routines listen for request actions, and satisfies them.  These are asynchronous routines, and upon
 * completion will dispatch a new action with the results
 *
 *
 */
import { List as ImmutableList } from 'immutable';
import { call, put, takeEvery, select } from 'redux-saga/effects';

import { getLogger } from 'companion-app-components/utils/core';
import { accountsActions, accountsSelectors } from 'companion-app-components/flux/accounts';
import { preferencesActions, preferencesSelectors, preferencesUtils, preferencesSagas } from 'companion-app-components/flux/preferences';

import { defaultDatasetPreferences } from 'data/preferences/defaults';
import AxiosFactory from 'utils/axiosFactory';
import { requiredColumns, fieldData } from 'components/TransactionRegister/transactionsConfig';

const log = getLogger('data/preferences/sagas.js');

export function* resetAllPreferences() {

  try {
    const qcsAxios = AxiosFactory.get('qcs');
    const data = {
      section: 'shared',
      group: 'dataset',
      preference: null,
    };
    // first set redux prefs to defaults with a faux response
    yield put(preferencesActions.setPreferenceSuccess({
      data,
      response: { data: defaultDatasetPreferences },
    }));

    // now null out the prefs on QCS
    const requestUrl = preferencesSagas.constructPreferencesUrl(data);

    yield call(qcsAxios.put, requestUrl, { webApp: null }, data.group === 'user' && { headers: { 'qcs-dataset-id': null } });
  } catch (error) {
    assert(false, error);

    log.log('Error in resetting preferences');
  }
}

// ====================================================
// INIT ACCOUNT PREFERENCES
export function* initAccountPreferences() {

  const accountsPending = yield select(accountsSelectors.getLoadPending);
  const prefsPending = yield select(preferencesSelectors.getLoadPendingState);
  if (!accountsPending && !prefsPending.shared.dataset) {
    const accounts = yield select(accountsSelectors.getAccountsById);
    const acctPrefs = yield select(preferencesSelectors.allAccountPreferences);

    if (accounts) {
      const newAcctPrefs = preferencesUtils.initAccountPrefs(accounts, acctPrefs);

      if (newAcctPrefs) {

        const prefObj = {
          section: 'shared',
          group: 'dataset',
          preference: { webApp: { accountsPrefs: newAcctPrefs } },
        };
        yield put(preferencesActions.setPreference(prefObj, { context: 'initAccountPreferences1' }));
      }
    }
    //
    // Also check if the transaction columns config was updated with new defaults
    // if the required defaults in the code contain any columns NOT in the registerPrefs, then
    // add required defaults in
    //
    const prefs = yield select(preferencesSelectors.getSharedPreferencesByPath,
      { group: 'dataset', path: ['webApp'] });

    const buildRegFields = (regFields, regFieldsDefault) => {
      let newRegFields = regFields || ImmutableList();
      regFieldsDefault?.forEach((col) => {
        if (!newRegFields.includes(col)) {
          const slot = newRegFields.findIndex((x) => fieldData[x].position > fieldData[col].position);
          newRegFields = newRegFields.insert(slot, col);
        }
      });
      return newRegFields;
    };
    const regFieldsCashFlow = prefs.transactionRegister.regFieldsSort.cashFlow;
    const newFieldsCashFlow = buildRegFields(regFieldsCashFlow, requiredColumns.default);
    const regFieldsInvestment = prefs.transactionRegister.regFieldsSort.investment;
    const newRegFieldsInvestment = buildRegFields(regFieldsInvestment, requiredColumns.investment);
    if (newFieldsCashFlow !== regFieldsCashFlow || newRegFieldsInvestment !== regFieldsInvestment) {
      const prefObj = {
        section: 'shared',
        group: 'dataset',
        preference: { webApp: { transactionRegister: { regFieldsSort: {
          cashFlow: newFieldsCashFlow,
          investment: newRegFieldsInvestment,
        } } } },
      };
      yield put(preferencesActions.setPreference(prefObj, { context: 'initAccountPreferences2' }));
    }
  }
}

export function* getAccountsSuccessActionWatcher() {
  yield takeEvery(accountsActions.getAccountsSuccess, initAccountPreferences);
}

export function* upsertAccountsSuccessActionWatcher() {
  yield takeEvery(accountsActions.batchAccountsSuccess, initAccountPreferences);
}

export function* initAccountPreferencesActionWatcher() {
  yield takeEvery(preferencesActions.initAccountPreferences, initAccountPreferences);
}

export function* resetAllPreferencesActionWatcher() {
  yield takeEvery(preferencesActions.resetAllPreferences, resetAllPreferences);
}

export function* getPreferencesSuccessActionWatcher() {
  yield takeEvery(preferencesActions.getPreferencesSuccess, initAccountPreferences);
}

export default [
  getAccountsSuccessActionWatcher,
  getPreferencesSuccessActionWatcher,
  initAccountPreferencesActionWatcher,
  resetAllPreferencesActionWatcher,
  upsertAccountsSuccessActionWatcher,
];
