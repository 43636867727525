import React from 'react';
import PropTypes from 'prop-types';
import { Map as ImmutableMap } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';

import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';

const ReportProblemLink = (props) => {
  const { feedbackSubject, ...linkProps } = props;
  const dispatch = useDispatch();
  const feedback = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'feedback'));

  if (!feedback) {
    return null;
  }
  return (
    <Link
      component="button"
      onClick={() => dispatch(createDialog(mkRootUiData({
        id: 'report-error',
        type: 'DIALOG_TYPE_REPORT_ERROR',
        allowNesting: true,
        props: ImmutableMap({
          feedbackSubject,
        }),
      })))}
      sharedcomponentid={'REPORT_PROBLEM_LINK'}
      underline="none"
      {...linkProps}
    >
      Send feedback
    </Link>
  );
};

ReportProblemLink.propTypes = {
  feedbackSubject: PropTypes.string,
};

export default ReportProblemLink;
