import mixpanel from 'mixpanel-browser';

import { clientType } from 'isAcme';
import { Config, BUILD_TYPE, HOSTNAME } from './config';

import platform from './platform';

const mixpanelToken = (HOSTNAME && Config.hosts[HOSTNAME]?.mixpanel_token)
  || Config?.builds?.[BUILD_TYPE]?.mixpanel_token 
  || 'f02e19059c63af74c567d59435e16a1d';  // test environment by default

mixpanel.init(mixpanelToken, {
  cookie_name: 'web_app',
  cross_subdomain_cookie: false,
  persistence: 'localStorage',
  persistence_name: 'web_app',
});

const errorsBlackList = [ // Events to ignore from tracking
  'Uncaught Error: EventDispatcher is disposed',
];

mixpanel.disable(errorsBlackList); // Events array passed wont be tracked. 

// TODO: This is for backwards compatible, should we change it for better visibility into type and model
const deviceType = platform.device?.type || (platform.device.type === 'tablet' && 'iPad') || 'Desktop';

// TODO: Ask Sergey about the device type check below (why undefined)
mixpanel.register({
  client_type: clientType,
  quicken_year: '2021',
  $app_version_string: process.env.APP_VERSION,
  $app_build_number: process.env.BUILD_NUMBER,
  ...(deviceType ? { $device: deviceType } : undefined), // mixpanel has issues with $device detection for desktop and iPad - overriding it
});

export const dynamicDefaultPropertiesCreator = () => {
  const dynamicDefaultProps = {};
  dynamicDefaultProps.pathname = window?.location.pathname;

  return dynamicDefaultProps;
};

export default mixpanel;


