// @flow
import React, { useCallback, useMemo } from 'react';

import Button from '@mui/material/Button/Button';
import { makeStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';

const useStyles = makeStyles()((theme) => ({
  root: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
    height: 36,
    color: theme.palette.secondary.main,
    borderRadius: 18,
    paddingLeft: 16,
    paddingRight: 16,
  },
  textButton: {
  },
  conButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.greyScaleDeprecated[7],
    boxShadow: `0 4px 4px -2px ${theme.applyOpacityToHex(theme.palette.linkColor, 0.4)}`,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      boxShadow: `0 8px 8px -2px ${theme.applyOpacityToHex(theme.palette.secondary.dark, 0.2)}`,
    },
  },
  outButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      backgroundColor: theme.applyOpacityToHex(theme.palette.secondary.dark, 0.16),
    },
  },
  outBasicButton: {
    border: `1px solid ${theme.palette.grey.level2}`,
    borderRadius: 4,
    color: theme.palette.text.primary,
    textTransform: 'none',
    background: 'transparent',
    '&:hover': {
      background: theme.palette.greyScaleDeprecated[6],
    },
  },
  deleteButton: {
    border: `1px solid ${theme.palette.number.negative}`,
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    color: theme.palette.number.negative,
    '&:hover': {
      backgroundColor: theme.applyOpacityToHex(theme.palette.number.negative, 0.3),
    },
  },
  disableDelete: {
    borderColor: theme.applyOpacityToHex(theme.palette.number.negative, 0.3),
    color: `${theme.applyOpacityToHex(theme.palette.number.negative, 0.3)} !important`,
  },
  disableOut: {
    opacity: 0.5,
    color: `${theme.palette.secondary.main} !important`,
  },
  disableOther: {
    backgroundColor: `${theme.applyOpacityToHex(theme.palette.secondary.light, 0.5)} !important`,
    color: `${theme.applyOpacityToHex(theme.palette.secondary.light, 1)} !important`,
    boxShadow: 'none ',
  },
  disableText: {
    color: `${theme.applyOpacityToHex(theme.palette.secondary.light, 1)} !important`,
    boxShadow: 'none ',
  },
  disableBasic: {
    color: theme.palette.grey.level2,
  },
  labelSpace: {
    margin: '0 8px',
    height: 22,
  },
  textLabel: {
    margin: '0 8px',
    height: 22,
    fontWeight: 500,
  },
  disableOutline: {
    border: 'none',
  },
  centerText: {
    lineHeight: 'normal',
  },
}));

export const QButtonVariantEnum = Object.freeze({
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  OUTLINED_BASIC: 'outlinedBasic',
  DELETE: 'delete',
  DEFAULT: 'default', // same as NOT passing variant, required for some cases where variant has fallback value that's not wanted
});

const QButton = React.forwardRef((props, ref) => {
  const { variant, children, width, style, disableOutline, onClick, classes: classesProp, className, ...otherProps } = props;
  const { root: classesPropRoot, label: classesPropLabel, ...otherClasses } = classesProp || {};

  const { classes, cx } = useStyles();
  const handleKeyDown = useCallback((e) => {
    if (onClick && e.keyCode === 13) {
      onClick();
    }
  }, [onClick]);

  const { styler, disabler, buttonVariant } = useMemo(() => {
    let stylerNew = classes.textButton;
    let disablerNew = classes.disableOther;
    let buttonVariantNew;
    switch (variant) {
      case QButtonVariantEnum.CONTAINED: {
        stylerNew = classesProp?.conButton ? classesProp.conButton : classes.conButton;
        disablerNew = classes.disableOther;
        buttonVariantNew = 'contained';
        break;
      }
      case QButtonVariantEnum.OUTLINED: {
        stylerNew = classes.outButton;
        disablerNew = classes.disableOut;
        buttonVariantNew = 'outlined';
        break;
      }
      case QButtonVariantEnum.OUTLINED_BASIC: {
        stylerNew = classes.outBasicButton;
        disablerNew = classes.disableBasic;
        break;
      }
      case QButtonVariantEnum.DELETE: {
        stylerNew = classes.deleteButton;
        disablerNew = classes.disableDelete;
        break;
      }
      default: {
        break;
      }
    }
    return { styler: stylerNew, disabler: disablerNew, buttonVariant: buttonVariantNew };
  }, [variant, classes, classesProp]);

  return (
    <Button
      color="primary"
      classes={{
        disabled: disabler,
        root: cx(classes.root, styler, disableOutline && classes.disableOutline, className, classesPropRoot),
        label: cx(variant === 'text' ? classes.textLabel : classes.labelSpace, classes.centerText, classesPropLabel),
        ...otherClasses,
      }}
      style={{ width, ...style }}
      onKeyDown={handleKeyDown}
      sharedcomponentid={'Q_BUTTON'}
      onClick={onClick}
      ref={ref}
      {...(buttonVariant && { variant: buttonVariant })}
      {...otherProps}
    >
      {children}
    </Button>
  );
});

QButton.defaultProps = {
  variant: 'text',
};

QButton.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object,
  onClick: PropTypes.func,
  disableOutline: PropTypes.bool,
};

QButton.name = 'QButton';

export default QButton;
