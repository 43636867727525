const isIe = require('is-iexplorer');  // eslint-disable-line

const styles = (theme) => ({
  icon: {
    paddingRight: 12,
  },
  frame: {
    flexGrow: 2,
    margin: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  listContainer: {
    maxHeight: 300,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 0px 2px 0px ${theme.components.payeeField.shadowColor}`,
    width: 'auto',
    marginRight: theme.spacing(1),
    position: 'fixed',
    fontSize: '13px',
    overflowY: 'auto',
    '& floating': {
      position: 'inherit',
    },
  },
  textField: {
    '& label.Mui-focused': {
      color: theme.palette.greyScaleDeprecated[0],
    },
    '& label': {
      color: theme.palette.greyScaleDeprecated[0],
    },

    '& input': {
      paddingBottom: 4,
      '&::placeholder': {
        color: theme.palette.greyScaleDeprecated[0],
        opacity: '100%',
      },
    },
  },
  active: {
    backgroundColor: theme.palette.action.active,
  },
  secondaryActionFrame: {
    overflow: 'visible',
    width: 2,
    height: 2,
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 1,
  },
  secondaryAction: {
    marginLeft: -10,
    height: 'auto',
  },
  inputFrame: {
    position: 'relative',
    display: 'flex',
  },
  inputField: {
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: isIe ? 27 : 24, // ie11 needs 27
      '&::-ms-clear ': {
        display: 'none',
      },
      '&::placeholder': {
        color: 'black',
        opacity: '100%',
      },
    },
  },
  menuFrame: { position: 'relative' },
  menuAnchor: {
    zIndex: 2,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'visible',
    height: 0,
  },
});

export default styles;
