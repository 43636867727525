const styles = ({ defaults, palette, typography }) => ({
  root: {
    position: 'relative', // insures that header's drop shadow is on top, not obscured
    zIndex: 0,
    minWidth: defaults.content.pageMinWidth,
    backgroundColor: palette.background.default,
  },
  header: {
    minHeight: defaults.headers.headerHeight,
    borderBottom: `1px solid ${palette.gray5}`,  // in lieu of the shadow, use a border
    backgroundColor: '#fff',
    zIndex: 2,
  },
  headerContent: {
    width: '100%',
    paddingLeft: defaults.content.paddingLeftMobile,
    paddingRight: defaults.content.paddingRightMobile,
    '@media (min-width: 769px)': {
      paddingLeft: defaults.content.paddingLeft,
      paddingRight: defaults.content.paddingRight,
    },

    minHeight: defaults.headers.headerHeight,
  },
  pathButton: {
    ...typography.body2,
    background: 'transparent',
    border: 'none',
    outline: 0,
    color: palette.blue3,
    textTransform: 'none',
    minWidth: 10,
    marginTop: 7,
    padding: 0,
    cursor: 'pointer',
    '&:disabled': {
      color: palette.gray2,
    },
    '&.isLastLeaf': {
      cursor: 'default',
    },
  },
  pathDivider: {
    ...typography.body2,
    padding: '6px 6px 6px 5px',
  },
});

export default styles;
