import { handleActions } from 'redux-actions';
import { DateTime } from 'luxon';

import { localPreferences } from 'companion-app-components/utils/core';
import { resourceStoreTypes, resourceUpserters } from 'companion-app-components/flux/core';
import { QcsSyncResourceStore, QcsSyncResourceStoreProps } from 'companion-app-components/flux/core/resourceStoreTypes';
import { entitlementsActions, entitlementsReducer } from 'companion-app-components/flux/entitlements';
import { 
  EntitlementProps, 
  mkEntitlement, 
  ENTITLEMENTS_REDUCER_KEY as CAC_ENTITLEMENTS_REDUCER_KEY, 
} from 'companion-app-components/flux/entitlements/entitlementsTypes';

import { appHistoryPush } from 'data/app/actions';
import * as actions from 'data/entitlements/entitlementsActions';
import { loadEntitlements, saveEntitlements, clearEntitlements } from './entitlementsUtils';

const savedStore = loadEntitlements();

const entitlementsLocalReducer = handleActions<
resourceStoreTypes.QcsSyncResourceStore<EntitlementProps>, 
| ReturnType<typeof entitlementsActions.getEntitlementsSuccess>
| ReturnType<typeof entitlementsActions.getEntitlementsFailure>
| ReturnType<typeof actions.setEntitlement>
>({
  [entitlementsActions.getEntitlementsSuccess.type]:
    (state, { payload }: Record<string, any>) => {
      let entitlements = payload.resources;

////////////////////////////////////////////////////////////////////////////////////////
      const entitlementsOverrideEntries = Object.entries(localPreferences.getItem('entitlementsOverride') || {});
      entitlements = entitlements.map((entitlement) => {
        const entitlementOverrideEntry = entitlementsOverrideEntries.find(([key]) => key === entitlement.id);
        const valueOverride = entitlementOverrideEntry ? entitlementOverrideEntry[1] : undefined;
        return entitlementOverrideEntry ?
          entitlement.merge({
            active: Boolean(valueOverride),
            expiresOn: (valueOverride ? DateTime.local().plus({ years: 1 }).toISODate() : DateTime.local().toISODate()) || undefined,
            expiresAt: (valueOverride ? DateTime.local().plus({ years: 1 }).toISO() : DateTime.local().toISO()) || undefined,
          }) : entitlement;
      });
      entitlementsOverrideEntries
        .filter(([key]) => !entitlements.find((entitlement) => entitlement.id === key))
        .forEach(([key, value]) => entitlements.push(mkEntitlement({
          id: key,
          active: Boolean(value),
          expiresOn: (value ? DateTime.local().plus({ years: 1 }).toISODate() : DateTime.local().toISODate()) || undefined,
          expiresAt: (value ? DateTime.local().plus({ years: 1 }).toISO() : DateTime.local().toISO()) || undefined,
        })));
///////////////

      const newState = resourceUpserters.upsertResources(state, payload.merge({ resources: entitlements, replaceCollection: true }));
      saveEntitlements(newState);

      return newState;
    },
  [entitlementsActions.getEntitlementsFailure.type]:
    (state, { payload: error, asyncDispatch }) => {
      if (error.message !== 'sandbox'
        && error.message !== 'offline'
        && error.message !== 'valid auth tokens not found locally') {
        clearEntitlements();
        asyncDispatch(appHistoryPush({ location: '/fetch-subscription' }));
      }
      return resourceUpserters.completeWithError(state, error);
    },

////////////////////////////////////////////////////////////////////////////////////
  [actions.setEntitlement.type]:
    (state, { payload }) => {
      const newState = resourceUpserters.upsertResource(state, payload);
      saveEntitlements(newState);
      return newState;
    },
///////////

}, resourceStoreTypes.mkQcsSyncResourceStore(savedStore) as QcsSyncResourceStore<EntitlementProps, QcsSyncResourceStoreProps<EntitlementProps>>);

export const ENTITLEMENTS_REDUCER_KEY = CAC_ENTITLEMENTS_REDUCER_KEY;

const entitlementsCombinedReducer = (state, action) => {
  const intermediateState = entitlementsLocalReducer(state, action);
  return entitlementsReducer(intermediateState, action);
};

export default entitlementsCombinedReducer;
