/*
 * RegPayeeField
 */

// BASE
import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

// CUSTOM COMPONENTS
import PayeeField from 'components/QuickenControls/PayeeField';
import QTypography from 'components/MUIWrappers/QTypography';
import QTip from 'components/QuickenControls/QTip';

// SELECTORS, ACTIONS, UTILS
import { txnHasTags } from 'data/transactions/utils';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
//  import { getSharedPreferencesByPath } from 'data/preferences/selectors';

// PATH RELATIVE IMPORTS
import { styles } from './styles';

const propTypes = {
  txn: PropTypes.object,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  editable: PropTypes.bool,
  forceStatic: PropTypes.bool,
  inputRef: PropTypes.func,
  accountIds: PropTypes.object,
};

// STYLES HOOK CREATION
const useStyles = makeStyles()(styles);

const RegPayeeField = (props) => {

  // PROPS ============================================================================
  const { txn, onChange, onFocus, onBlur, editable, forceStatic, autoFocus, inputRef, accountIds } = props;
  const theme = useTheme();

  // SELECTORS ==========================================================================
  const payeeShowFillInfoInMenu = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('payeeShowFillInfoInMenu'), shallowEqual);
  const payeeAutoFillCat = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('payeeAutoFillCat'), shallowEqual);
  const payeeAutoFillAmount = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('payeeAutoFillAmount'), shallowEqual);
  const payeeAutoFillMemo = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('payeeAutoFillMemo'), shallowEqual);
  const payeeAutoFillTags = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('payeeAutoFillTags'), shallowEqual);
  /*  const wrapText = useSelector((state) =>
    getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp', 'transactionRegister', 'wrapText'] }), shallowEqual);  */

  const payeeObj = useMemo(() => ({ payee: txn.payee || '' }), [txn.payee]);

  // STYLES ============================================================================
  const { classes } = useStyles(props);

  // RENDER ============================================================================

  let menuFields = 'payee';
  if (payeeShowFillInfoInMenu) {
    if (payeeAutoFillCat) {
      menuFields += 'category';
    }
    if (Number(txn.amount) === 0 && payeeAutoFillAmount) {
      menuFields += 'amount';
    }
    if (!txn.memo && payeeAutoFillMemo) {
      menuFields += 'memo';
    }
    if (!txnHasTags(txn) && payeeAutoFillTags) {
      menuFields += 'tags';
    }
  }

  // Companion render
  return editable ? (
    <PayeeField
      disableUnderline={theme.components.register.noUnderline}
      accountIds={accountIds}
      menuShowFields={menuFields}
      editable
      fontSize={theme.components.register.fontSize.default}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      autoFocus={autoFocus}
      value={payeeObj}
      inputRef={inputRef}
      textFieldVariant="standard"
      width="100%"
    />
  ) : (
    <QTip
      wrapOnly
      title={txn.payee}
      wrapId={`${txn.id}-payee`}
    >
      <QTypography
        clickable={!forceStatic ? 'true' : null}
        thinFont
        className={classes.regItemCore}
      >
        {txn.payee}
      </QTypography>
    </QTip>
  );
};

RegPayeeField.propTypes = propTypes;

// RegPayeeField.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'RegPayeeField',
// }

export default (RegPayeeField);


