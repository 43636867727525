import { List } from 'immutable';

import { getLogger } from 'companion-app-components/utils/core';
import { accountsTypes, accountsUtils } from 'companion-app-components/flux/accounts';
import type { Account } from 'companion-app-components/flux/accounts';

import { ACCOUNT_ACTION_ADD, mkAccountAction } from './types';
import type { DiscoveredAccount } from './types';

const log = getLogger('components/Dialogs/AccountDiscovery/buildUpsertableAccountData.js');

type LinkableAccountPair = {
  discoveredAccount : DiscoveredAccount,
  account: Account,
}

type ScoredLinkableToAccounts = {
  score: number,
  linkableAccountPairs: Array<LinkableAccountPair>
}

function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

// ================================================================================================
// calculateScore
//
// Determines a score based upon likelyhood that a discoveredAccount matches an existing account.
// The higher the score the likelyhood accounts match.
// ================================================================================================

function calculateScore(discoveredAccount, account) {
  let accountName = null;
  let accountNumberMasked = null;

  const aggregator = account.aggregators && account.aggregators.size > 0 ? account.aggregators.get(0) : null;
  if (aggregator) {
    accountName = aggregator.accountName;
    accountNumberMasked = aggregator.accountNumberMasked;
  } else {
    accountName = account.name;
  }

  let score = 0;
  if (!isBlank(discoveredAccount.maskedNumber) && !isBlank(accountNumberMasked)
    && discoveredAccount.maskedNumber.replace(/^\D+/g, '') === accountNumberMasked.replace(/^\D+/g, '')) {
    log.debug('Masked number is same, so increasing score');
    score += 50;
  }
  if (!isBlank(discoveredAccount.name) && !isBlank(accountName)
    && discoveredAccount.name === accountName) {
    log.debug('Account name is same, so increasing score');
    score += 40;
  }
  if (discoveredAccount.type !== 'UNKNOWN' && discoveredAccount.type === account.type) {
    if (discoveredAccount.subType && discoveredAccount.subType === account.subType) {
      log.debug('Type and subtype are the same, so increasing score');
      score += 10;
    } else {
      log.debug('Type is the same, so increasing score');
      score += 5;
    }
  }
  if (discoveredAccount.type === 'UNKNOWN') {
    log.debug('Unknown account, so increasing score');
    score += 1;
  }

  log.debug('Score for linkable pair', score, discoveredAccount, account);
  return score;
}

function buildUpsertableAccountData(accountDiscoveryData, accountsBeingMigrated, manualLinkableAccounts, tier) {
  const accounts = [];
  const scoredLinkableAccounts: Array<ScoredLinkableToAccounts> = [];

  accountDiscoveryData && accountDiscoveryData.newAccounts.forEach((discoveredAccount: DiscoveredAccount, index) => {
    const accountType = accountsTypes.getAccountTypeNodeByQcsType(discoveredAccount.type, discoveredAccount.subType);

    // hide new account if not supported for current product
    if (!accountsUtils.isAccountNodeSelectable(accountType, null, tier)) {
      return;
    }

    const linkableAccountsSection1 = [];
    if (accountDiscoveryData.deadAccounts && accountDiscoveryData.deadAccounts.size) {
      log.debug('Adding deadAccounts', accountDiscoveryData.deadAccounts);
      accountDiscoveryData.deadAccounts.forEach((account) => {
        linkableAccountsSection1.push(account);
        addLinkableAccountsToScoredList(discoveredAccount, account, scoredLinkableAccounts);
      });
    }
    if (accountDiscoveryData.unconnectedAccounts && accountDiscoveryData.unconnectedAccounts.size) {
      log.debug('Adding unconnectedAccounts', accountDiscoveryData.unconnectedAccounts);
      accountDiscoveryData.unconnectedAccounts.forEach((account) => {
        linkableAccountsSection1.push(account);
        addLinkableAccountsToScoredList(discoveredAccount, account, scoredLinkableAccounts);
      });
    }
    if (accountsBeingMigrated && accountsBeingMigrated.size) {
      log.debug('Adding accountsBeingMigrated', accountsBeingMigrated);
      accountsBeingMigrated.forEach((account) => {
        if (linkableAccountsSection1.find((addedAccount) => addedAccount.id === account.id) === undefined) {
          linkableAccountsSection1.push(account);
          addLinkableAccountsToScoredList(discoveredAccount, account, scoredLinkableAccounts);
        }
      });
    }

    linkableAccountsSection1.sort((a, b) => a.name.localeCompare(
      b.displayLabel,
      undefined,
      { numeric: true, sensitivity: 'base' }
    ));

    const linkableAccountsSection2 = [];
    const linkableAccountsSection3 = [];
    if (manualLinkableAccounts && manualLinkableAccounts.size) {
      manualLinkableAccounts.forEach((account) => {
        if (accountType === account.type) {
          linkableAccountsSection2.push(account);
        } else {
          linkableAccountsSection3.push(account);
        }
      });
    }
    linkableAccountsSection2.sort((a, b) => a.name.localeCompare(
      b.displayLabel,
      undefined,
      { numeric: true, sensitivity: 'base' }
    ));
    linkableAccountsSection3.sort((a, b) => a.name.localeCompare(
      b.displayLabel,
      undefined,
      { numeric: true, sensitivity: 'base' }
    ));

    log.info('Going to push an account', discoveredAccount.name);
    accounts.push({
      action: ACCOUNT_ACTION_ADD,

      name: discoveredAccount.name,
      type: accountType,
      discoveredAccount,

      linkableAccountsSection1: linkableAccountsSection1.length ? List(linkableAccountsSection1) : null,
      linkableAccountsSection2: linkableAccountsSection2.length ? List(linkableAccountsSection2) : null,
      linkableAccountsSection3: linkableAccountsSection3.length ? List(linkableAccountsSection3) : null,

      formFieldName: `account_${index}`,
    });
  });
  updateAccountsWithAnyDefaultLinkActions(scoredLinkableAccounts, accounts);

  return accounts;
}

function addLinkableAccountsToScoredList(
  discoveredAccount: DiscoveredAccount,
  account: Account,
  scoredLinkableAccounts,
) {
  const score = calculateScore(discoveredAccount, account);

  let linkableAccountsWithScore = scoredLinkableAccounts.find((e) => e.score === score);
  if (!linkableAccountsWithScore) {
    scoredLinkableAccounts.push((linkableAccountsWithScore = { score, linkableAccountPairs: [] }));
  }
  linkableAccountsWithScore.linkableAccountPairs.push({ discoveredAccount, account });
}

function updateAccountsWithAnyDefaultLinkActions(scoredLinkableAccounts, accounts) {

  // sort scored linkable accounts in descending order by score
  scoredLinkableAccounts.sort((a, b) => b.score - a.score);

  const assignedOrAmbiguousDiscoveredAccounts = [];
  const assignedOrAmbiguousAccounts = [];

  // for each list of linkable-accounts (ordered by highest to lowest score)
  scoredLinkableAccounts.forEach((linkableAccountsWithScore) => {

    const linkableAccounts = linkableAccountsWithScore.linkableAccountPairs;
    log.info('Processing linkable accounts with score', linkableAccountsWithScore.score, linkableAccounts);

    // for all linkable-accounts with a given score
    while (linkableAccounts.length > 0) {
      const linkableAccount = linkableAccounts.pop();

      log.info('Processing linkable account', linkableAccount);

      const isDiscoveredAccountAlreadyAssignedOrAmbigoous =
        assignedOrAmbiguousDiscoveredAccounts.find((discoveredAccount) => discoveredAccount === linkableAccount.discoveredAccount) !== undefined;
      const isAccountAlreadyAssignedOrAmbigoous =
        assignedOrAmbiguousAccounts.find((account) => account === linkableAccount.account) !== undefined;

      log.info('Is already ambiguous', isDiscoveredAccountAlreadyAssignedOrAmbigoous, isAccountAlreadyAssignedOrAmbigoous);

      if (!isDiscoveredAccountAlreadyAssignedOrAmbigoous && !isAccountAlreadyAssignedOrAmbigoous) {

        // is current linkableAccounts ambiguous with any other pair with the same score
        const ambiguous = linkableAccounts.find((otherLinkableAccount) => (
          linkableAccount.discoveredAccount === otherLinkableAccount.discoveredAccount ||
          linkableAccount.account === otherLinkableAccount.account)) !== undefined;

        log.info('Is ambiguous with another pair in same list', ambiguous);

        if (!ambiguous) {
          const accountData = accounts.find((account) => account.discoveredAccount === linkableAccount.discoveredAccount);
          log.info('Find discovered account in accounts', accountData);
          if (accountData) {
            accountData.action = mkAccountAction({
              id: linkableAccount.account.id,
              menuItemLabel: linkableAccount.account.name,
              accountToLinkTo: linkableAccount.account,
            });
          }
        }
      }

      // if not already added, add linkableAccounts to isNew and isExisting assigned or ambiguous lists
      if (!isDiscoveredAccountAlreadyAssignedOrAmbigoous) {
        assignedOrAmbiguousDiscoveredAccounts.push(linkableAccount.discoveredAccount);
      }
      if (!isAccountAlreadyAssignedOrAmbigoous) {
        assignedOrAmbiguousAccounts.push(linkableAccount.account);
      }
    }
  });
}

export default buildUpsertableAccountData;

