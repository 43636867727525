import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';

import { history } from 'companion-app-components/utils/core';
import { entitlementsSelectors } from 'companion-app-components/flux/entitlements';

export const userIsAuthenticatedWithDataset = connectedReduxRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) => {
    const authenticated = entitlementsSelectors.isAuthenticatedWithDataSetId(state);
    return authenticated;
  },
  authenticatingSelector: () => false,
  redirectAction: history.replace,
  wrapperDisplayName: 'UserIsAuthenticatedWithDataset',
});

export const visibleWhenAuthenticated = connectedAuthWrapper({
  authenticatedSelector: entitlementsSelectors.isAuthenticated,
  wrapperDisplayName: 'VisibleWhenAuthenticated',
});

export const isAuthenticatedWithDatasetOrElse = (Component, ElseComponent) => connectedAuthWrapper({
  authenticatedSelector: entitlementsSelectors.isAuthenticatedWithDataSetId,
  FailureComponent: ElseComponent,
  wrapperDisplayName: 'AuthenticatedWithDatasetOrElse',
})(Component);

export const isAuthenticatedOrElse = (Component, ElseComponent) => connectedAuthWrapper({
  authenticatedSelector: (state) => {
    const authenticated = Boolean(entitlementsSelectors.isAuthenticated(state));
    return authenticated;
  },
  FailureComponent: ElseComponent,
  wrapperDisplayName: 'AuthenticatedOrElse',
})(Component);
