import { fromJS } from 'immutable';

import { types, generateType, UISTATE_REDUCER_KEY as REDUCER_KEY } from './utils';

export const UISTATE_REDUCER_KEY = REDUCER_KEY;

const initialState = fromJS({ });

// this reducer handles all state changes for the uiState slice
export default function reducer(state = initialState, action) {
  const { name, state: componentUiState } = action.payload ? action.payload : {};
  if (!name) {
    return state;
  }

  // add initial state
  if (action.type === generateType(types.add, name)) {
    return state.set(name, componentUiState);
  }

  // delete state
  if (action.type === generateType(types.delete, name)) {
    return state.delete(name);
  }

  // reset to initial state
  if (action.type === generateType(types.reset, name)) {
    return state.set(name, componentUiState);
  }

  // shallow merge for state updates
  if (action.type === generateType(types.set, name)) {
    if (state.get(name)) return state.set(name, state.get(name).merge(componentUiState));
    return state;
  }

  return state;
}
