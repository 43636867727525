const styles = ({ palette }) => ({
  base: {
    fontWeight: 200,
    padding: 3,
  },
  title: {
    padding: 3,
    fontSize: '25px',
    fontWeight: 400,
  },
  subTitle: {
    fontSize: '20px',
    fontWeight: 200,
    padding: 3,
  },
  bodyText: {
    padding: 3,
    fontWeight: 200,
    fontSize: '14px',
  },
  choiceTabs: {
    display: 'flex',
    flexDirection: 'row',
    height: 50,
    lineHeight: '50px',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 30,
  },
  tab: {
    flex: 1,
    borderColor: palette.classicGray,
    '&.unselected': {
      borderBottom: 'solid',
      background: palette.lightGray,
      borderBottomWidth: '1px',
    },
    '&.selected': {
      border: 'solid',
      borderBottom: 'none',
      borderWidth: '1px',
    },
  },
  stepBox: {
    flex: 1,
    paddingRight: 13,
  },
  stepHeading: {
    fontSize: '14px',
    fontWeight: 500,
  },
  stepDivider: {
    flex: 1,
    background: palette.pastelGrey,
    height: 1,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 14,
    marginRight: 14,
  },
  footer: {
    height: 50,
    marginTop: 30,
    marginBottom: 50,
  },
  vertBar: {
    display: 'inline-block',
    verticalAlign: 'middle',
    height: '14px',
    width: 1,
    background: palette.classicGray,
    marginLeft: 15,
    marginRight: 15,
  },
  link: {
    cursor: 'pointer',
  },
  startClean: {
    padding: 10,
    textAlign: 'center',
    marginTop: 10,
  },
  imgIcon: {
    display: 'inline',
    marginRight: 10,
  },
});

export default styles;
