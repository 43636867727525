import { createSelector } from 'reselect';
import { createCachedSelector, FlatMapCache, LruObjectCache } from 're-reselect';
import { List as ImmutableList, Map as ImmutableMap, Record } from 'immutable';
import { DateTime } from 'luxon';

import { selectorsHelpers } from 'companion-app-components/flux/core';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { tagsSelectors } from 'companion-app-components/flux/tags';
import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { chartOfAccountsTypes, chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';
import { transactionsTypes, TRANSACTIONS_REDUCER_KEY, transactionsUtils } from 'companion-app-components/flux/transactions';
import { getSharedPreferencesByPath } from 'companion-app-components/flux/preferences/selectors';

import { getGlobalCache, setGlobalCache } from 'utils/auth/caches';
import { noNaN, parseDateString } from 'utils/utils';

import {
  findTransaction,
  isAcceptedScheduledTxn,
  isGoalTxnInNonGoalAccount,
  isUnacceptedScheduledTxn,
  getCalendarTxnType,
  RefundState,
  getRefundState,
  isRefundVirtualTxn,
} from './utils';

export const getTransactionsStore = (state) => state[TRANSACTIONS_REDUCER_KEY];
export const getLoadPending = (state) => getTransactionsStore(state).loadPending;
export const getError = (state) => getTransactionsStore(state).error;
export const getIsLoading = (state) => getTransactionsStore(state).isLoading;
export const getLastSyncDate = (state) => getTransactionsStore(state).lastSyncDate;
export const getResourcesById = (state) => getTransactionsStore(state).resourcesById;

export const getLastReductions = (state) => getTransactionsStore(state).lastReductions;

export const getAllTransactionsById = createSelector(
  getResourcesById,
  accountsSelectors.getAccountsById, // temporary hack to drop any transactions that belongs to investment accounts TODO: remove
  (state) => featureFlagsSelectors.getFeatureFlag(state, 'investmentTransactions'),
  (transactions, accounts, investmentTransactions) => transactions
    .filter((transaction) => investmentTransactions || !(accounts?.get(transaction.accountId)?.type === 'INVESTMENT')), // temporary hack to drop any transactions that belongs to investment accounts
);

export const getTransactionsByAccountIdRaw = createSelector(
  getAllTransactionsById,
  (transactions) => transactions.groupBy((transaction) => transaction.accountId),
);

export const TxnTypes = Object.freeze({
  GOALS: 1,
  REGULAR_TXNS: 2,
  UNACCEPTED_SCHEDULED_TXNS: 4,
  REFUND_VIRTUAL_TXN: 8,

  ALL: 0xFFFF,
});
export const txnTypesDefault = TxnTypes.REGULAR_TXNS | TxnTypes.UNACCEPTED_SCHEDULED_TXNS;

const transactionOfTypes = (txn, goalAccountIds, txnTypes = txnTypesDefault) => {
  if (isRefundVirtualTxn(txn)) {
    return txnTypes & TxnTypes.REFUND_VIRTUAL_TXN;
  }
  if (isGoalTxnInNonGoalAccount(txn, goalAccountIds)) {
    return txnTypes & TxnTypes.GOALS;
  }
  if (isUnacceptedScheduledTxn(txn)) {
    return txnTypes & TxnTypes.UNACCEPTED_SCHEDULED_TXNS;
  }
  return txnTypes & TxnTypes.REGULAR_TXNS;
};

export const getTransactionsOfTypes = createCachedSelector(
  getAllTransactionsById,
  accountsSelectors.getGoalAccountIds,
  (state, txnTypes) => txnTypes || txnTypesDefault,
  (transactionsById, goalAccountIds, txnTypes) => transactionsById.filter(
    (transaction) => transactionOfTypes(transaction, goalAccountIds, txnTypes),
  ),
)({
  keySelector: (_state, props) => props?.txnTypes || 'default',
});

export const getTransactionsById = (state) => getTransactionsOfTypes(state);

// getTransactionsForAccountIdSelectors
//
// This selector returns a map of selectors that can then be used to get transactions
// filtered by txn-types for a specific account.
//
// We are using this level of indirection so that the returned transactions for each
// account will not be invalidated by the adding/removing of accounts or by changing
// transactional data in other accounts.
//
// The selectors returned by this method are not typical selectors. Instead of
// a state and props, the expected arguments are a map of transactions by
// account ID.
//
export const getTransactionsForAccountIdSelectors = createCachedSelector(
  accountsSelectors.getAllAccountIds,
  accountsSelectors.getGoalAccountIds,
  (_state, props) => (props && props.txnTypes) || txnTypesDefault,
  (allAccountIds, goalAccountIds, txnTypes) =>
    ImmutableMap().withMutations((map) => {
      allAccountIds.forEach((accountId) => {
        const selector = createSelector(
          (transactionsByAccountId) => transactionsByAccountId.get(accountId),
          (txnsById) => {
            // handle special case of no transactions in account
            if (txnsById === undefined || txnsById.size === 0) {
              return ImmutableMap();
            }
            const filteredTxnsById = txnsById.filter((txn) => transactionOfTypes(txn, goalAccountIds, txnTypes));
            return filteredTxnsById;
          },
        );
        map.set(accountId, selector);
      });
    }),
)({
  keySelector: (_state, props) => (props && props.txnTypes) || txnTypesDefault,
  cacheObject: new FlatMapCache(),
});

export const getTransactionsByAccountId = createCachedSelector(
  accountsSelectors.getAllAccountIds,
  getTransactionsByAccountIdRaw,
  (state, props) => getTransactionsForAccountIdSelectors(state, props),
  (allAccountIds, txnsByAccountId, txnsForAccountIdSelectors) =>
    ImmutableMap().withMutations((map) => {
      allAccountIds.forEach((accountId) => {
        const txnsForAccountIdSelector = txnsForAccountIdSelectors.get(accountId);
        map.set(accountId, txnsForAccountIdSelector(txnsByAccountId));
      });
    }),
)({
  keySelector: (_state, props) => (props && props.txnTypes) || txnTypesDefault,
  cacheObject: new FlatMapCache(),
});

export const pastTransactions = createSelector(
  getTransactionsByAccountId,
  (state, props) => props.id,
  (state, props) => props.transaction?.accountId,
  (transactionsByAccountId, stModelId, accountId) => {
    const allTxn = transactionsByAccountId.get(accountId);
    return allTxn && allTxn
      .filter((d) => isAcceptedScheduledTxn(d) && d.stModelId === stModelId)
      .sort((a, b) => new Date(b.stDueOn) - new Date(a.stDueOn));
  },
);

export const getAllUpcomingTransactions = createSelector(
  getTransactionsByAccountId,
  (state, props) => props,
  (transactionsByAccountId, schTxns) => {
    if (!transactionsByAccountId || transactionsByAccountId.isEmpty()) {
      return ImmutableMap();
    }

    return ImmutableMap().withMutations((mutableMap) => {
      schTxns.forEach(({ id, transaction: { accountId } }) => {
        const allTxn = transactionsByAccountId.get(accountId);
        const allUpcomingTxn = allTxn && allTxn
          .filter((txn) => isUnacceptedScheduledTxn(txn) && txn.stModelId === id)
          .sort((a, b) => new Date(a.stDueOn) - new Date(b.stDueOn));

        if (allUpcomingTxn && !allUpcomingTxn.isEmpty()) {
          mutableMap.set(id, allUpcomingTxn);
        }
      });
    });
  },
);

export const getRefundActiveTransactions = createSelector(
  (state) => getTransactionsOfTypes(state, txnTypesDefault | TxnTypes.REFUND_VIRTUAL_TXN),
  (transactionsById) => transactionsById.filter((transaction) => {
    const refundState = getRefundState(transaction);
    return refundState === RefundState.EXPECTED || refundState === RefundState.OVERDUE;
  })
    .sortBy((transaction) => transaction.postedOn),
);

export const getRefundActiveTransactionsByAccountId = createCachedSelector(
  getRefundActiveTransactions,
  (state, accountId) => accountId,
  (refundActiveTransactions, accountId) => refundActiveTransactions.filter(
    (transaction) => transaction.accountId === accountId,
  ),
)({
  keySelector: (state, accountId) => accountId || 'none',
  cacheObject: new LruObjectCache({ cacheSize: 10 }),
});

export const getRefundActiveTransactionsTotal = createSelector(
  getRefundActiveTransactions,
  (transactionsById) => transactionsById.reduce((total, transaction) => total + Number(transaction.amount), 0),
);

export const getRefundActiveRelatedTxns = createCachedSelector(
  getRefundActiveTransactions,
  (state, relatedTnxId) => relatedTnxId,
  (refundActiveTransactions, relatedTnxId) => refundActiveTransactions.filter(
    (transaction) => transaction.subtypeRelatedTxn?.id === relatedTnxId,
  ),
)({
  keySelector: (state, relatedTnxId) => relatedTnxId,
  cacheObject: new LruObjectCache({ cacheSize: 10 }),
});

export const getRefundCompleteTransactions = createSelector(
  (state) => getTransactionsOfTypes(state, txnTypesDefault | TxnTypes.REFUND_VIRTUAL_TXN),
  (transactionsById) => transactionsById
    .filter((transaction) => {
      const refundState = getRefundState(transaction);
      return refundState === RefundState.COMPLETE || refundState === RefundState.SHADOW;
    })
    .sort((transaction1, transaction2) => transaction2.postedOn?.localeCompare(transaction1.postedOn)),
);

export const getRefundCompleteTransactionsTotal = createSelector(
  getRefundCompleteTransactions,
  (transactionsById) => transactionsById.reduce((total, transaction) => total + Number(transaction.amount), 0),
);

export const getRefundCompleteRelatedTxns = createCachedSelector(
  getRefundCompleteTransactions,
  (state, relatedTnxId) => relatedTnxId,
  (refundCompleteTransactions, relatedTnxId) => refundCompleteTransactions.filter(
    (transaction) => transaction.subtypeRelatedTxn?.id === relatedTnxId,
  ),
)({
  keySelector: (state, relatedTnxId) => relatedTnxId,
  cacheObject: new LruObjectCache({ cacheSize: 10 }),
});

export const getTransactionsForAccountIds = createCachedSelector(
  (state, props) => props.accountIds,
  getTransactionsByAccountId,
  accountsSelectors.getAccountsById,
  (accountIds, transactionsByAccountId, accountsById) => {

    let accountIdsList = accountIds;
    if (!accountIds || accountIds.length === 0) {
      accountIdsList = accountsById.filter((x) => x.type !== 'GOAL').map((x) => x.id).toList();
    }

    let returnTransactions = ImmutableMap();
    accountIdsList.forEach((id) => {
      returnTransactions = returnTransactions.merge(transactionsByAccountId.get(id));
    });

    return returnTransactions;

  },
)({
  keySelector: (state, props) => props.accountIds ? props.accountIds.hashCode() : 'all',
  cacheObject: new FlatMapCache(),
});


export const getTransactionById = (state, id) => getTransactionsById(state).get(id);

export const CategorySummary = Record({
  count: 0,
  amount: 0,
  amountByMonth: null,
  coa: null,
  acctId: null,
  monthlyAverage: null,
});

export const getMostPopularCatsByAccountId = createCachedSelector(
  getTransactionsByAccountId,
  (state, props) => (props && props.dateRange),
  (state, props) => (props && props.sortByAmount),
  (txns, dateRange, sortByAmount) => {

    let catCount = ImmutableMap();

    txns.forEach((acctTxns, acctId) => {

      acctTxns.forEach((txn) => {

        if (txn.coa && txn.coa.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY) { // ignore splits, transfers, and uncategorized

          // check date if it is supplied
          if (!dateRange || dateRange.contains(DateTime.fromISO(txn.postedOn))) {
            const catRecord = catCount.get(txn.coa.id);
            let amount = +txn.amount;
            let count = 1;
            const { month } = DateTime.fromISO(txn.postedOn);
            let amountByMonth = ImmutableMap().set(month, amount);

            if (catRecord) {
              amount = catRecord.amount + +txn.amount;
              count = catRecord.count + 1;
              amountByMonth = catRecord.amountByMonth.set(month, (catRecord.amountByMonth.get(month) || 0) + amount);
            }
            catCount = catCount.set(txn.coa.id, new CategorySummary({
              count,
              amount,
              amountByMonth,
              coa: txn.coa,
              acctId,
            }));
          }
        }
      });
    });
    if (sortByAmount) {
      return catCount.toList().sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount));
    }
    return catCount.toList().sort((a, b) => b.count - a.count);
  },
)(
  (state, props, selectorKey) => ((props && props.dateRange && props.dateRange.toISO()) || 'nodate') +
    (props && props.sortByAmount) + selectorKey,
  { cacheObject: new LruObjectCache({ cacheSize: 5 }) },
);

export const getPopularCatsMonthlyAverage = createCachedSelector(
  (state, props) => getMostPopularCatsByAccountId(state, props),
  (popularCats) => {
    // must occur more than once
    let filteredCats = popularCats.filter((x) => x.amountByMonth.size > 1);

    // add in monthly average
    filteredCats = filteredCats.map((x) => x.set('monthlyAverage', (x.amount / x.amountByMonth.size).toFixed(0)));

    // sort by monthly average
    filteredCats = filteredCats.sort((a, b) => {

      if (a.amountByMonth.size === b.amountByMonth.size) {
        return b.monthlyAverage - a.monthlyAverage;
      }
      return ((b.amountByMonth.size * 5000) + Math.abs(b.amount)) - ((a.amountByMonth.size * 5000) + Math.abs(a.amount));
    });

    return filteredCats;
  },
)(
  (state, props, selectorKey) => ((props && props.dateRange && props.dateRange.toISO()) || 'nodate') +
    (props && props.sortByAmount) + selectorKey,
  { cacheObject: new LruObjectCache({ cacheSize: 5 }) },
);

export const getTransactionsByIds = createCachedSelector(
  getTransactionsById,
  (state, ids) => ids,
  (transactionsById, ids) => transactionsById && transactionsById.filter((transaction) => ids && ids.includes(transaction.id)),
)(
  (state, ids, selectorKey) => ids && ids.hashCode() + selectorKey,
  { cacheObject: new LruObjectCache({ cacheSize: 30 }) },
);

export const getTransactionsByFilterFn = createCachedSelector(
  (state, props) => props?.transactions || getTransactionsById(state),
  (_, props) => props,
  (transactionsById, props) => transactionsById.filter((transaction) => !props || !props.filterFn || props.filterFn(transaction, props)),
)({
  keySelector: (_, props) => (props && props.filterFnGUID) ?? '',
  cacheObject: new LruObjectCache({ cacheSize: 13 }),
});

export const getTransactionsByFilter = createCachedSelector(
  (state, filter) => filter?.transactions || getTransactionsById(state),
  (_state, filter) => ({ obj: filter && filter.accountIds, deep: true }),
  (_state, filter) => ({ obj: filter && filter.accountIdsArray, deep: true }),
  (_state, filter) => ({ obj: filter && filter.startDate, deep: true }),
  (_state, filter) => ({ obj: filter && filter.endDate, deep: true }),
  (_state, filter) => ({ obj: filter && filter.payee, deep: false }),
  (_state, filter) => ({ obj: filter && filter.amountSign, deep: false }),
  (_state, filter) => ({ obj: filter && filter.rootCOAs, deep: true }),
  (_state, filter) => ({ obj: filter && filter.coas, deep: true }),
  (_state, filter) => ({ obj: filter && filter.isExcludedFromF2S, deep: false }),
  (_state, filter) => ({ obj: filter && filter.isScheduled, deep: false }),
  (_state, filter) => ({ obj: filter && filter.isScheduledPending, deep: false }),
  (
    transactionsById,
    { obj: accountIds },
    { obj: accountIdsArray },
    { obj: startLocalDate },
    { obj: endLocalDate },
    { obj: payee },
    { obj: amountSign },
    { obj: rootCOAs },
    { obj: coas },
    { obj: isExcludedFromF2S },
    { obj: isScheduled },
    { obj: isScheduledPending },
  ) => transactionsById && transactionsById.filter((transaction) => {
    let keepTransaction = true;

    keepTransaction = (keepTransaction && payee !== undefined) ? transaction.payee === payee : keepTransaction;
    keepTransaction = (keepTransaction && amountSign !== undefined) ? Math.sign(transaction.amount) === Math.sign(amountSign) : keepTransaction;
    keepTransaction = (keepTransaction && isScheduled !== undefined) ? Boolean(transaction.stModelId) === Boolean(isScheduled) : keepTransaction;
    keepTransaction = (keepTransaction && isExcludedFromF2S !== undefined) ? Boolean(transaction.isExcludedFromF2S) === Boolean(isExcludedFromF2S) : keepTransaction;
    keepTransaction = (keepTransaction && accountIds && accountIds.size > 0) ? accountIds.has(transaction.accountId) : keepTransaction;
    keepTransaction = (keepTransaction && accountIdsArray && accountIdsArray.size > 0) ?
      accountIdsArray.includes(transaction.accountId) : keepTransaction;
    const postedOnLocalDate = keepTransaction && DateTime.fromISO(transaction.postedOn).toJSDate();
    keepTransaction = (keepTransaction && startLocalDate) ? postedOnLocalDate >= startLocalDate : keepTransaction;
    keepTransaction = (keepTransaction && endLocalDate) ? postedOnLocalDate <= endLocalDate : keepTransaction;
    keepTransaction = (keepTransaction && rootCOAs && rootCOAs.size > 0) ? Boolean(
      (transaction.coa && rootCOAs.find((coa) => coa.type === transaction.coa.type && coa.id === transaction.coa.id)),
    ) : keepTransaction;
    keepTransaction = coas && !coas.size ? false : keepTransaction;
    keepTransaction = (keepTransaction && coas && coas.size > 0) ? Boolean(
      (transaction.coa && coas.find((coa) => coa?.type === transaction?.coa?.type && coa?.id === transaction?.coa?.id))
      ||
      (transaction.split && transaction.split.items &&
        transaction.split.items.find((splitItem) => coas.find((coa) => coa.type === splitItem.coa.type && coa.id === splitItem.coa.id))
      ),
    ) : keepTransaction;
    keepTransaction = (keepTransaction && isScheduledPending !== undefined) ? transaction.source === 'SCHEDULED_TRANSACTION_PENDING' : keepTransaction;

    return keepTransaction;
  }),
)({
  keySelector: (_, filter) => filter && `${filter.isExcludedFromF2S}:${filter.isScheduled}:${filter.amountSign}:${filter.payee}
    :${JSON.stringify(filter.accountIdsArray)}:${filter.accountIds && filter.accountIds.hashCode()}
    :${filter.rootCOAs && filter.rootCOAs.hashCode()}:${filter.coas && filter.coas.hashCode && filter.coas.hashCode()}:${filter.startDate}:${filter.endDate}`,
  cacheObject: new LruObjectCache({ cacheSize: 13 }),
  selectorCreator: selectorsHelpers.createFlexEqualSelector,
});

const searchItemForText = (item, filterText, tagsById, filterNumber) => {
  let found;

  if (!found && item.memo && item.memo?.toLowerCase().includes(filterText)) {
    found = true;
  }
  if (!found && filterNumber !== undefined && Math.round(Math.abs(item.amount)) === filterNumber) {
    found = true;
  }
  if (!found) {
    const coaString = chartOfAccountsSelectors.getCoaStringSelector(undefined, item.coa, true);
    if (coaString && coaString?.toLowerCase().includes(filterText)) {
      found = true;
    }
  }
  if (!found && item.tags && tagsById) {
    if (item.tags.find((tagRef) => {
      const tag = tagsById.get(tagRef.id);
      return tag && tag.name?.toLowerCase().includes(filterText);
    })) {
      found = true;
    }
  }

  return found;
};

export const getTransactionsByTextFilter = createSelector(
  (state, props) => props.transactions || getTransactionsForAccountIds(state, props), // getTransactionsById(state, props),
  accountsSelectors.getAccountsById,
  tagsSelectors.getTags,
  (state, props) => props.filter,
  (transactionsById, accountsById, tagsById, filter) => {
    const filterLowerCase = filter && filter?.toLowerCase();
    const filterDate = parseDateString(filter);
    let filterNumber = Number(filter);
    filterNumber = Number.isNaN(filterNumber) ? undefined : Math.round(Math.abs(filterNumber));

    const transactionsByIdFiltered = (!filterLowerCase || !filterLowerCase.length) ?
      transactionsById :
      transactionsById.filter((transaction) => {
        let keepTransaction = false;

        if (!keepTransaction && transaction.payee && transaction.payee?.toLowerCase().includes(filterLowerCase)) {
          keepTransaction = true;
        }
        if (!keepTransaction) {
          const account = accountsById.get(transaction.accountId);
          if (account && account.name?.toLowerCase().includes(filterLowerCase)) {
            keepTransaction = true;
          }
        }
        if (!keepTransaction && searchItemForText(transaction, filterLowerCase, tagsById, filterNumber)) {
          keepTransaction = true;
        }
        if (!keepTransaction && transaction.split && transaction.split.items) {
          if (transaction.split.items.find((item) => searchItemForText(item, filterLowerCase, tagsById))) {
            keepTransaction = true;
          }
        }
        if (!keepTransaction && filterDate && filterDate === parseDateString(transaction.postedOn)) {
          keepTransaction = true;
        }

        return keepTransaction;
      });

    return transactionsByIdFiltered;
  },
);

export const findTransactionInAccountOrByTxnId = createCachedSelector(
  getTransactionsByAccountId,
  (state, props) => props.accountId,
  (state, props) => props.txnId,
  (transactionsByAccountId, accountId, txnId) =>
    findTransaction(transactionsByAccountId, txnId, accountId),
)(
  (state, props) => `${props.accountId}_${props.txnId}`,
  {
    cacheObject: new LruObjectCache({ cacheSize: 200 }),
  },
);

export const getTransactionsByAccountIdAndTxnId = (state, txnList) =>
  txnList.map((txn) => findTransactionInAccountOrByTxnId(state, {
    txnTypes: txnTypesDefault | TxnTypes.REFUND_VIRTUAL_TXN,
    accountId: txn.accountId,
    txnId: txn.id,
  }));

// getTransactionsByAccountIdsSelectors
//
// We are using a selector based on accounts to return a map of selectors that
// can then be used to get transactions for a specific account. This map of
// selectors are stored in a global cache based on the current login.
//
// We are using this level of indirection and a global cache so that each account
// will have it's own selector for the retrieval of transactions which will not
// be invalided by the adding/removing of accounts, the updating of the account
// or changing transaction data within the account.
//
// The selectors returned by this method are not typical selectors. Instead of
// a state and props, the expected arguments are a map of transactions by
// account ID and options map. Currently the only supported option are
// sortBy and sortOrder.
//
export const getTransactionsByAccountIdsSelectors = createSelector(
  accountsSelectors.getAccountsById,
  (accounts) => {
    let transactionsByAccountIdsSelector = getGlobalCache('TRANSACTIONS_SELECTORS');
    if (!transactionsByAccountIdsSelector) {
      setGlobalCache('TRANSACTIONS_SELECTORS', transactionsByAccountIdsSelector = new Map());
    }
    const activeAccountIds = [];
    accounts.forEach((account) => {
      activeAccountIds.push(account.id);

      // if selector doesn't exist, add it
      if (!transactionsByAccountIdsSelector.get(account.id)) {
        const selector = createSelector(
          (transactionsByAccountId) => transactionsByAccountId.get(account.id),
          (transactionsByAccountId, options) => options,
          (txns) => {   // was (txns, options) if you need the options

            // handle special case of no transactions in account
            if (txns === undefined || txns.size === 0) {
              return ImmutableList([]);
            }
            return ImmutableList(txns.toList());
          },
        );

        transactionsByAccountIdsSelector.set(account.id, selector);
      }
    });
    return ImmutableMap(transactionsByAccountIdsSelector);
  },
);

export const isIncomeTxn = createSelector(
  (state, txn) => txn,
  categoriesSelectors.getIncomeCOAIds,
  transactionsUtils.isIncomeFromTxn,
);

export const filterCalendarTxnBasedOnPreferences = createSelector(
  (state, props) => props.txnList,
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] }).calendarTransaction,
  categoriesSelectors.getIncomeCOAIds,
  (txnList, prefs, incomeCOAs) => {
    const visibleCalTxnTypes = [
      transactionsTypes.calTxnLabelTypes.more,
      ...(prefs.showTransactions ? [transactionsTypes.calTxnLabelTypes.normal] : []),
      ...(prefs.showScheduleTransactions ?
        [transactionsTypes.calTxnLabelTypes.income,
          transactionsTypes.calTxnLabelTypes.expense,
          transactionsTypes.calTxnLabelTypes.overdue] : []),
    ];

    return txnList.filter((txn) => {
      const type = getCalendarTxnType(txn, incomeCOAs);
      let filterAmount = true;

      if (prefs.exceedingAmount !== null) {
        filterAmount = Math.abs(noNaN(txn.amount)) >= Math.abs(noNaN(prefs.exceedingAmount));
      }

      return visibleCalTxnTypes.includes(type) && filterAmount;
    });
  },
);

export const filterDashboardCalendarTxnBasedOnPref = createSelector(
  (state, props) => props.txnList,
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] }).calendarTransaction?.dashboard,
  categoriesSelectors.getIncomeCOAIds,
  (txnList, prefs, incomeCOAs) => {
    const visibleCalTxnTypes = [
      transactionsTypes.calTxnLabelTypes.more,
      ...(prefs?.showTransactions ? [transactionsTypes.calTxnLabelTypes.normal] : []),
      ...(prefs?.showScheduleTransactions ?
        [transactionsTypes.calTxnLabelTypes.income,
          transactionsTypes.calTxnLabelTypes.expense,
          transactionsTypes.calTxnLabelTypes.overdue] : []),
    ];

    return txnList?.filter((txn) => {
      const type = getCalendarTxnType(txn, incomeCOAs);
      return visibleCalTxnTypes.includes(type);
    });
  },
);

// used for on-the-fly creation in transaction details
export const getCreatedTxnByClientAndAccountId = createSelector(
  (state, clientId) => clientId,
  (state, clientId, accountId) => getTransactionsForAccountIds(state, { accountsIds: [accountId] }),
  (clientId, txns) => {
    if (clientId) {
      return txns.find((item) => (item.clientId === clientId) && (item.clientId !== item.id));
    }
    return undefined;
  },
);

export const getTxnByMatchedTxnAndAccountId = createSelector(
  (state, matchedTxnId) => matchedTxnId,
  (state, _matchedTxnId, accountId) => getTransactionsForAccountIds(state, { accountsIds: [accountId] }),
  (matchedTxnId, txns) => {
    if (matchedTxnId) {
      return txns.find((item) => item.matchedTxn?.id === matchedTxnId);
    }
    return undefined;
  },
);

export const getSortedTransactionsByIds = createCachedSelector(
  getTransactionsByIds,
  (txns) => txns?.sort((a, b) => {
    if (DateTime.fromISO(a.postedOn) >= DateTime.fromISO(b.postedOn)) {
      return 1;
    }
    return -1;
  }),
)(
  (state, ids, selectorKey) => ids && ids.hashCode() + selectorKey,
  { cacheObject: new LruObjectCache({ cacheSize: 20 }) },
);

export const getLastReductionsChangeListLatestChange = createSelector(
  getLastReductions,
  (lastReductions) => lastReductions.get('changeList').last(),
);

export const TransactionBucketEnum = Object.freeze({
  EXPENSE: 'EXPENSE',
  INCOME: 'INCOME',
  TRANSFER: 'TRANSFER',
});

export const getTransactionBucket = createCachedSelector(
  (state, transaction) => transactionsUtils.isSplitTxn(transaction),
  (state, transaction) => transaction.coa && transaction.coa.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY ? categoriesSelectors.isIncomeCat(state, transaction.coa.id) : undefined,
  (state, transaction) => transaction.coa,
  (state, transaction) => transaction.amount,
  (isSplit, isIncome, coa, amount) => {
    let coaType;
    if (isSplit) {
      coaType = amount > 0 ? TransactionBucketEnum.INCOME : TransactionBucketEnum.EXPENSE;
    } else {
      switch (coa && coa.type) {
        case 'CATEGORY':
          coaType = isIncome ? TransactionBucketEnum.INCOME : TransactionBucketEnum.EXPENSE;
          break;
        case 'ACCOUNT':
        case 'BALANCE_ADJUSTMENT':
          coaType = TransactionBucketEnum.TRANSFER;
          break;
        case 'UNCATEGORIZED':
          coaType = TransactionBucketEnum.EXPENSE;
          break;
        default:
          coaType = TransactionBucketEnum.EXPENSE;
          break;
      }
    }
    return coaType;
  },
)(
  (state, transaction) => `${transactionsUtils.isSplitTxn(transaction)}:${transaction.coa && transaction.coa.type}:${transaction.coa && transaction.coa.id}:${transaction.amount}`,
  { cacheObject: new LruObjectCache({ cacheSize: 10 }) },
);
