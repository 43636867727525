// @flow
import React from 'react';

import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  errorStatusCode: {
    paddingTop: theme.spacing(1),
  },
  content: {
    paddingTop: 25,
  },
});

type Props = {
  error: any,
  classes: Object,
}

const ContentView = (props: Props) => {
  const { classes, error } = props;
  const textIsString = typeof text === 'string';

  return (
    <div className={classes.content}>
      {error.text && textIsString &&
        <Typography variant="body2">
          {error.text}
        </Typography>}
      {error.text && !textIsString &&
        error.text}
      {error.statusCode &&
        <Typography className={classes.errorStatusCode} variant="caption" display={'block'}>
          {` Care Code: ${error.statusCode}`}
        </Typography>}
    </div>
  );
};

export default withStyles(ContentView, styles);
