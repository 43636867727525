/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-foreign-prop-types */
import React from 'react';
import TableCellExpandable from 'components/TableCellExpandable';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import Table from '@mui/material/Table';

import { mkSharedComponent } from '../Wrappers/factories';


const config = {
  name: 'Layout',
  id: 'LAYOUT',
  children: [

    mkSharedComponent({
      name: 'Table Cell Expandable',
      id: 'TABLE_CELL_EXPANDABLE',
      searchTags: ['expand', 'more', 'hide'],
      defaultState: { expanded: false },
      componentPropTypes: TableCellExpandable.propTypes,
      component: (props) => (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                table header
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow onClick={() => props.setstate({ expanded: !props.state.expanded })}>
              <TableCell>
                Click this Row&nbsp;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellExpandable expanded={props.state.expanded} maxHeight={100}>
                <div height={100}>
                  <InsertEmoticonIcon />
                  expand/collapse animated content
                </div>
              </TableCellExpandable>
            </TableRow>
            <TableRow><TableCell>permanent table row2</TableCell></TableRow>
            <TableRow><TableCell>permanent table row3</TableCell></TableRow>
          </TableBody>
        </Table>
      ),
    }),

  ],
};

export default config;
