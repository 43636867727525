// @flow
import { handleActions } from 'redux-actions';
import { List as ImmutableList } from 'immutable';
import { mkClipboardStore } from 'data/clipboard/types';

import { getLogger } from 'companion-app-components/utils/core';

import * as actions from './actions';

const log = getLogger('data/clipboard/reducer.js');
export const REDUCER_KEY = 'clipboardStore';

const maxClipboardStackSize = 10;

// For Unit Tests
export const copyToClipboardHelper = (state, { payload: clipboardItem }) => {
  log.log('addClipboardAction...', clipboardItem);
  return state.merge({
    clipboard: state.clipboard
      .takeLast(maxClipboardStackSize - 1)
      .push(clipboardItem),
  });
};
export const clearClipboardHelper = (state) => {
  log.log('clearClipboardAction...');
  return state.merge({
    clipboard: ImmutableList(),
  });
};
export const clearClipboardKeyHelper = (state, { payload: key }) => {
  log.log('clearClipboardKeyAction...');
  return state.merge({
    clipboard: state.clipboard.filter((x) => x.key !== key),
  });
};
export const clearClipboardItemHelper = (state, { payload: item }) => state.merge({
  clipboard: state.clipboard.filter((x) => x.id !== item.id),
});

export const reducer = handleActions({
  [actions.copyToClipboard]: (state, data) => copyToClipboardHelper(state, data),

  [actions.clearClipboard]: (state) => clearClipboardHelper(state),

  [actions.clearClipboardKey]: (state, data) => clearClipboardKeyHelper(state, data),

  [actions.deleteClipboardItem]: (state, data) => clearClipboardItemHelper(state, data),
}, mkClipboardStore());

export default reducer;
