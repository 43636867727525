import styled from 'styled-components';
import { InputText, InputContainer, LabelSpan, StateList } from './ReduxFormStylesAll';
import { BlueButton } from './ButtonsAll';
import LoadingSpinner from './LoadingSpinner';

export const Container = styled.div`
  max-width: 900px;
  margin:auto;
  
  position: absolute;
  left: calc(50% - 50px);
  top: 25%;
  
  @media (max-width: 900px) {
    padding: 12px;
  }
`;

export const Spinner = styled(LoadingSpinner)`
  align-items: center;
  padding-left: 5%;
  align-content: center;
  flex-direction: column;
  margin-top: 25%;
`;

export const PageBody = styled.div`
  width: 100%;
  height: 100vh;
`;

export const CloseBox = styled.button`
display: block;
    width: 20px;
    height: 20px;
    background: ${(props) => props.basecolor || '#fff'};
    position: absolute;
    margin-top: ${(props) => props.noRise ? '-7px' : '-15px'};
    margin-left: ${(props) => props.noRise ? '-20px' : '-15px'};
    color: ${(props) => props.textcolor || '#555'};
    line-height: 18px;
    text-align: center;
    padding-left: 5px;
    
    &:hover {
      background: ${(props) => props.hovercolor || '#eee'};
    }
    &:active {
      background: ${(props) => props.activecolor || '#d5d5d5'};
\    }
    &:focus {
      box-shadow: #888 0px 0px 1px;
     }
`;

export const MyBlueButton = styled(BlueButton)`
  width: 200px;
  margin-left: 5px;
  vertical-align: bottom;
`;

export const MyBlueButtonDialog = styled(BlueButton)`
  width: 71%;
  vertical-align: bottom;
  display: inline;
  margin-top: 10px;
`;

export const NewAccountButton = styled(MyBlueButton)`
  height: 25px;
  width: 165px;
  float: right;
  &.small {
    margin-top: 0px;
    height: 18px;
    font-size: 12px;
    width: 100px;
  }
`;

export const Spacer = styled.div`
  height: 1px;
  background: #666;
  &.quarter {
    background-color: #999;
    width: 200px;
    margin-top: -5px;
    margin-bottom: 5px;
    margin-left: 5px;  }
`;

export const AccountsListContainer = styled.div`
  padding-top: 5px;
  padding-left:10px;
  padding-right: 10px;

  ul {
    list-style-type: none;
  }
`;

export const CategoriesList = styled(AccountsListContainer)`

  padding-top: 5px;
  &.popUp {
    padding: 0px 3px 3px 3px;
  }
`;

export const TableDiv = styled.table`
  width: 100%;
  margin-top: 10px;
  a {
    &:hover {
      text-decoration: underline;
    }
    &:active {
      text-decoration: underline;
      opacity: 0.80;
    }
  }
  td {
    &.red {
      color: #882222;
    }
    &.green {
      color: #228822;
    }
  }
`;

export const ALHeader = styled.div`
  display: inline-block;
  width: 100%;
  p {
    float: left;
    font-size: 17px;
    display: inline-block;
  }  
`;

export const ALHeaderRow = styled.tr`
  background: #ddd;
`;

export const ALHeaderStandard = styled.td`
  font-weight: bold;
  width: 12%;  
`;

export const ALHeaderIcon = styled.td`
  font-weight: bold;
  width: 9%;
`;

export const ALHeaderSmallIcon = styled.td`
  font-weight: bold;
  width: 3%;
`;

export const ALHeaderFlex = styled.td`
  font-weight: bold;
  width: 20%;
`;

export const AccountDescription = styled.p`
  font-size: 12px;
  margin-left: 5px;
`;

export const ModalDiv = styled.div`
  position: fixed;
  top: 157px;
  text-align: center;
  width: 50%;
  left: 25%;
  background: #f5f5f5;
  padding: 20px;
  box-shadow: 1px 1px 1px #777;
  z-index: 3;
  
  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
`;

export const ButtonHolder = styled.div`
  margin: auto;
  margin-top: 10px;
`;

export const ModalHeader = styled.div`
  font-weight: bold;
  font-size: 17px;
  text-align: center;
`;

export const InputTextHP = styled(InputText)`
  font-size: 13px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid lightgray;
  background: ;
  padding-left: 8px;
  margin-top: 5px;
  width: 80%;
  &:focus {
    border-color: #164b80;
    outline: none;
    border-style: solid;
    border-width: 1px;
  }
`;

export const ModalField = styled.div`
  font-size: 13px;
  height: 34px;
  line-height: 32px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid lightgray;
  margin-top: 5px;
  width: 80%;
  display: inline-block;
  padding-left: 0px;
  &:focus {
    border-color: #164b80;
    outline: none;
    border-style: solid;
  }
`;

export const InputContainerHP = styled(InputContainer)`
  text-align: left;
`;

export const CheckContainerHP = styled(InputContainer)`
  text-align: left;
  padding: 0;
  display: block;
  margin-top: 10px;
`;

export const LabelSpanHP = styled(LabelSpan)`
  width: 20%;
  display: inline-block;
`;

export const LabelCheckSpanHP = styled(LabelSpan)`
  width: 145px;
  display: inline-block;
  margin-left: 10px;
`;

export const StateListHP = styled(StateList)`
  background: white;
  border: 1px solid lightgray;
  &:focus {
    border-color: #164b80;
    outline: none;
    border-style: solid;
    border-width: 1px;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  a {
    &:hover {
      text-decoration: underline;
    }
    &:active {
      text-decoration: underline;
      opacity: 0.80;
    }
    &.parent-cat {
      font-weight: bold;
    }
  }
`;

export const ListRow = styled.div`
  display: flex;
  align-items: stretch;
  &.list-head {
    margin-bottom: 2px;
    margin-top: 5px;
  }
  &.col-headers {
    background: #ddd;
  }
  &.selected {
    color: white;
    background: #333;
  }
`;

export const ListItem = styled.div`
  width: 50px;
  min-width: 100px;
  padding-left: 5px;
  padding-right: 5px;
  
  &.indentL1 {
    padding-left: 10px;
    margin-right: -10px
  }
  &.indentL2 {
    padding-left: 35px;
    margin-right: -35px;
  }
  &.indentL3 {
    padding-left: 45px;
    margin-right: -45px;
  }
  &.parent-cat {
    font-weight: bold;
  }
  &.edit-row {
    padding-top: 3px;
    padding-bottom: 3px;
    border: solid;
    border-width: 1px;
    margin-left: -1px;
    color: #888;
  }
  &.popUpAction {
    width: inherit;
    text-align: center;
  }
`;
export const ListItemStretch1 = styled(ListItem)`
  flex: 1;
`;
export const ListItemStretch2 = styled(ListItem)`
  flex: 2;
`;

export const CatIcon = styled.img`
  display: inline-block;
  height: 21px;
  margin-left: -5px;
  margin-right: 7px;
  opacity: 0.7;
  &:hover {
    opacity: 1.0;
  }
`;
export const ListImage = styled.img`
    height: 19px;
    margin-top: -5px;
    margin-left: 5px;
`;

export const Mask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #aaa;
  opacity: 0.3;
  z-index: 1;
`;

export const Refresh = styled.button`
  width: 80px;
  height: 20px;
  background: #164b80;
  float: right;
  color: white;
`;

