import compose from 'utils/compose';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';

import { budgetItemsActions } from 'companion-app-components/flux/budget-items';
import { getCategoryBudgetTransactions } from 'companion-app-components/flux//budgets/budgetsSelectors';

import { removeDialog } from 'data/rootUi/actions';

import YearlyBudgetEditView from './yearlyBudgetEditView';
import styles from './styles';

const mapStateToProps = (state, props) => (
  { 
    annualBudgetDataOfCategory: props.annualBudgetDataOfCategory,
    categoryBudgetDetails: getCategoryBudgetTransactions(state, props.budgetId, props.annualBudgetDataOfCategory.id, false, null, null, props.allCategoryIdsOfBudget),
    showCalculateAverageView: props.showCalculateAverageView,
    directlyCloseCalculateAverageView: props.directlyCloseCalculateAverageView,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    removeDialog: () => dispatch(removeDialog()),
    updateBudgetItems: (data) => dispatch(budgetItemsActions.updateBudgetItemsAction(data, { undo: { userMessage: 'Budget items updated.' } })),
  }
);

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(YearlyBudgetEditView, styles));
