import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import BuildIcon from '@mui/icons-material/Build';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';

import ReportProblemLink from 'components/ReportProblemLink';

export const ActionsSnackBar = (props) => {
  const {
    postponedActions, dispatchDismissPostponedActionsAction,
    showFailedActionsHandler, dispatchDispatchPostponedActionsAction,
  } = props;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={postponedActions && postponedActions.size > 0}
      autoHideDuration={300000}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') {
          dispatchDismissPostponedActionsAction(postponedActions);
        }
      }}
    >
      <Alert
        severity="warning"
        action={[
          process.env.NODE_ENV !== 'production' &&
          <IconButton
            key="details"
            aria-label="details"
            color="primary"
            size="small"
            onClick={showFailedActionsHandler}
          >
            <BuildIcon />
            {postponedActions.size}
          </IconButton>,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatchDismissPostponedActionsAction(postponedActions)}
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      >
        An error occurred. Please&nbsp;
        <Link
          id="try-again-id"
          href="#"
          onClick={() => dispatchDispatchPostponedActionsAction(postponedActions)}
        >
          try again.
        </Link>

        <br />
        <ReportProblemLink feedbackSubject="report QCS API error" />
      </Alert>
    </Snackbar>
  );
};

ActionsSnackBar.propTypes = {
  postponedActions: PropTypes.object,
  dispatchDismissPostponedActionsAction: PropTypes.func,
  dispatchDispatchPostponedActionsAction: PropTypes.func,
  showFailedActionsHandler: PropTypes.func,
};
