import * as React from 'react';
import { Field, FormikProps, FormikValues } from 'formik';
import { get } from 'lodash';
import classNames from 'classnames';

import AmountField from 'components/QuickenControls/AmountField';
import { setVirtualAmount } from './utils';

interface RecrAmountFieldProps {
  classes: Record<string, string>;
  name: string;
  formikProps: FormikProps<FormikValues>;
  isTypeGrossPaycheck: boolean;
}

const RecrAmountField: React.FC<RecrAmountFieldProps> = ({ classes, name, formikProps, isTypeGrossPaycheck }) => (
  <Field
    name={name}
    validate={(value) => value === undefined || value === null || value === '' ? 'required' : undefined}
  >
    {({ field, form: { touched, errors, values } }) => (
      <AmountField
        {...field}
        onChange={(e) => {
          formikProps.setFieldValue('virtualAmount', setVirtualAmount(formikProps.values, e.target.value));
          field.onChange(e);
        }}
        showSign={values.virtualShowSign}
        showAmountAdornment
        editable
        omitCents={false}
        currencySymbol="USD"
        amountType="amount"
        id="schedtxn-amount"
        label={name.includes('split') ? 'Amount' : 'Recurring Amount'}
        marginProp="normal"
        fullWidth
        autoComplete="off"
        error={Boolean(get(touched, field.name) && get(errors, field.name))}
        helperText={get(touched, field.name) && get(errors, field.name)}
        classes={{ inputField: classNames(classes.amtTxt) }}
        InputProps={{
          classes: { underline: classes.underlineOverride },
        }}
        disabled={isTypeGrossPaycheck}
      />
    )}
  </Field>
);

export default RecrAmountField;
