// @flow
import { Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

type SubscriptionType = {
  id: ?string,
  clientId: ?string,
  isDeleted: ?boolean,
  createdAt: ?string,
  modifiedAt: ?string,
  userModifiedAt: ?string,
  productLineName: ?string,
  productLineUriName: ?string,
  tierName: ?string,
  tierUriName: ?string,
  active: ?boolean,
  autoRenew: ?boolean,
  userId: ?string,
  effectiveAt: ?string,
  expireAt: ?string, // note - expireAt is deprecated, use expireOn instead
  expireOn: ?string,
  updatingPaymentMethodWillAutorenew: ?boolean,
  supportedDatasetPlatforms: ?Array,
  isInTrialPeriod: ?string,
  renewalFrequency: ?string,
  merchant: ?string,
  isInGracePeriod: ?boolean,
};

export type Subscription = RecordOf<SubscriptionType>;
export const mkSubscription: RecordFactory<SubscriptionType> = Record({
  id: undefined,
  clientId: undefined,
  isDeleted: undefined,
  createdAt: undefined,
  modifiedAt: undefined,
  userModifiedAt: undefined,
  productLineName: undefined,
  productLineUriName: undefined,
  tierName: undefined,
  tierUriName: undefined,
  active: undefined,
  autoRenew: undefined,
  userId: undefined,
  effectiveAt: undefined,
  expireAt: undefined,
  expireOn: undefined,
  updatingPaymentMethodWillAutorenew: undefined,
  supportedDatasetPlatforms: undefined,
  isInTrialPeriod: undefined,
  renewalFrequency: undefined,
  merchant: undefined,
  isInGracePeriod: undefined,
});
