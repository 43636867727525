import { makeStyles } from 'tss-react/mui';


export const useStyles = makeStyles()(({ spacing, typography }) => ({
  root: {
    padding: spacing(4, 3),
    justifyContent: 'space-around',
  },
  sectionRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: 270,
    width: '50%',
    padding: spacing(0, 2),
  },
  supportSectionRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: 270,
    width: '50%',
    padding: spacing(0, 2),
  },
  questionsSectionRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: 270,
    width: '50%',
    padding: spacing(0, 2),
  },
  videoSectionRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: 270,
    width: '50%',
    padding: spacing(0, 2),
  },
  title: {
    ...typography.h5,
    textTransform: 'uppercase',
  },
  mediaHolder: {
    width: '100%',
    margin: spacing(3, 0),
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
}));
