import { Record, Set as ImmutableSet } from 'immutable';

export const AdvancedFilterObject = Record({
  isBillOrSubscription: undefined,
  isExcludedFromReports: undefined,
  isReviewed: undefined,
  isExcludedFromF2S: undefined,
});

export const FilterObject = Record({
  categories: new ImmutableSet(),
  payees: new ImmutableSet(),
  tags: new ImmutableSet(),
  accounts: new ImmutableSet(),
  payeeNames: new ImmutableSet(),
  advanced: new AdvancedFilterObject(),
  numFilterItems: 0,
  filterOut: false,   // filter items IN or OUT
});

