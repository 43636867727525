import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import { Tooltip as TippyTip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

import Tooltip from '@mui/material/Tooltip';

import { styles } from './styles';

const useStyle = makeStyles()(styles);

type Placement = 'bottom' | 'left' | 'right' | 'top' | 'bottom-end' | 'top-start' | 'top-end' | 'bottom-start' | 'left-start' | 'left-end' | 'right-start' | 'right-end' | undefined;

interface QTipProps {
  children: React.ReactNode;
  className: string;
  title: string | React.ReactNode;
  html: string | React.ReactNode;
  wrapOnly: boolean;
  wrapId: string;
  arrowClass?: string;
  placement: Placement;
  position: Placement;
  popperProps: Record<string, any>;
  noTippyTip: boolean;
  enableOnMobile: boolean;
}

const QTip: React.FC<QTipProps> = ({
  children,
  title,
  html,
  className,
  wrapId,
  wrapOnly,
  popperProps,
  arrowClass,
  noTippyTip,
  enableOnMobile,
  placement,
  position,
  ...other
}) => {

  const [id, setId] = React.useState(wrapId || uuidv4());
  const [overflow, setOverflow] = React.useState(false);

  const wrapRef = React.useRef(null);
  const { classes } = useStyle();

  const isElementOverflow = (element) => element && element.clientWidth < element.scrollWidth;

  const checkOverflow = () => {
    let element: Element | null = wrapRef.current || document.getElementById(`QtipId:${id}`);
    if (element && element.children) {
      element = element.firstElementChild;
    }
    const hasOverflow = isElementOverflow(element);
    setOverflow(hasOverflow);
  };

  React.useEffect(() => {
    if (wrapOnly) {
      checkOverflow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapOnly, id]);

  React.useEffect(() => {
    setId(wrapId || uuidv4());
  }, [wrapId]);

  if ((!isMobile || enableOnMobile) && ((html || (typeof title === 'string' && title.trim().length > 0)) && (!wrapOnly || overflow))) {
    return html && !noTippyTip ? (
      // @ts-expect-error No overload matches this call.
      <TippyTip
        animation="fade"
        id={`tooltip-id:${id}`}
        theme="transparent"
        hideOnClick
        size="small"
        duration={200}
        distance={15}
        position={placement || 'bottom-end'}
        title={html ? null : title || ''}
        html={html}
        delay={500}
        {...other}
      >
        <span style={{ height: '100%' }} id={`QtipId:${id}`} className={className} ref={wrapRef}>
          {children}
        </span>
      </TippyTip>
    ) : (
      <Tooltip
        enterDelay={500}
        id={`tooltip:${id}`}
        leaveDelay={200}
        placement={position || 'bottom-end'}
        title={html || title || ''}
        classes={{ tooltip: classes.tooltip, arrow: arrowClass }}
        PopperProps={popperProps}
        {...other}
      >
        <span id={`QtipId:${id}`} className={className} ref={wrapRef}>
          {children}
        </span>
      </Tooltip>
    );
  }

  return (
    <span id={`QtipId:${id}`} className={className} ref={wrapRef}>
      {children}
    </span>
  );
};

export default QTip;
