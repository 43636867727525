
export const qCards = {
  firstCard: {
    elementId: 'qCard-account-list',
    edge: 'righttop',
    width: 350,
    height: 200,
    nudge: { top: -11, left: 6 },
    title: 'Accounts',
    next: 'qcard2',
    content: 'Select an Account to view the transactions for that account. You can also select groups of accounts ' +
      'such as Banking or Investments. It is a good idea to review your transactions and ' +
      'make sure they are correctly categorized.',
  },
  qcard2: {
    elementId: 'qmenu_open_txpref',
    edge: 'left',
    width: 350,
    height: 180,
    nudge: { left: -10 },
    title: 'Transaction Settings',
    prev: 'firstCard',
    next: 'qcard3',
    content: 'Select [gear icon] to customize your Transactions view. You can adjust the list display, show or hide ' +
      'columns, reconcile your account and set other preferences for the way you view your transactions.',
  },
  qcard3: {
    elementId: 'register-search-icon',
    edge: 'left',
    width: 350,
    height: 180,
    nudge: { left: -10 },
    title: 'Searching for Transactions',
    prev: 'qcard2',
    next: 'qcard4',
    content: 'Use the Search feature to find specific transactions. Enter a search term such as a ' +
      'transaction name, a category, or an amount. This is a great way to find transactions quickly.',
  },
  qcard4: {
    elementId: 'qCard-transaction-first-row',
    edge: 'right',
    width: 300,
    height: 220,
    title: 'Editing Transactions',
    prev: 'qcard3',
    next: 'qcard5',
    content: 'To edit a transaction, you need to select the part of the transaction you plan to change.  ' +
      'You can change almost any information about a transaction such as the amount, the category, ' +
      'the payee, the date, or the account. ',
  },
  qcard5: {
    elementId: 'registerWrapper_TxPage-1_select_',
    edge: 'right',
    width: 300,
    height: 250,
    nudge: { left: 6 },
    title: 'Selecting Multiple Transactions',
    prev: 'qcard4',
    next: 'qcard6',
    content: 'Use the checkboxes to select multiple transactions. You can then work those transactions as a group. ' +
      'This can be useful for editing transactions, deleting transactions, or downloading a list of transactions. ' +
      'It is also a great way to get the sum of several transactions.',
  },
  qcard6: {
    elementId: 'registerWrapper_TxPage-1_reviewed_',
    edge: 'right',
    width: 350,
    height: 220,
    title: 'Reviewing Transactions',
    prev: 'qcard5',
    next: 'qcard7',
    content: 'The [gray checkmark icon] indicates that a transaction has not been reviewed yet. ' +
      'It is a good idea to review all of your transactions for accuracy and to set the appropriate category. ' +
      'Once you have reviewed a transaction, mark the transaction as reviewed [green checkmark icon].',
  },
  qcard7: {
    elementId: 'registerWrapper_TxPage-1_category_',
    edge: 'left',
    width: 300,
    height: 180,
    title: 'Categories',
    prev: 'qcard6',
    content: 'Ensure your transactions are categorized accurately in the register to better track your spending ' +
      'and create accurate budgets. Select a transaction\'s category to change it.',
  },
};


