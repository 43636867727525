import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { List } from 'immutable';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import AddBoxIcon from '@mui/icons-material/AddBox';

import QPanelButton from 'components/QPanelButton';
import type { InstitutionLogin } from 'data/institutionLogins/types';

import ExistingLoginPicker from './ExistingLoginPicker';

type Props = {
  existingLoginsAtInstitution: List<InstitutionLogin>,
  fiBrandingIndent: number,

  onAdd: (any) => void,
  onBack: (any) => void,
  onRediscover: (any) => void,
}

const NewOrRediscoverPicker = forwardRef((props: Props, ref) => {
  const { existingLoginsAtInstitution, fiBrandingIndent, onAdd, onBack, onRediscover } = props;

  const [page, setPage] = useState(null);
  const theme = useTheme();

  useImperativeHandle(ref, () => ({
    handleBack: () => {
      if (page) {
        setPage(null);
      } else {
        onBack();
      }
    },
  }));

  const handleAdd = () => onAdd && onAdd();
  const handleUpdate = () => {
    if (existingLoginsAtInstitution.length === 1) {
      onRediscover(existingLoginsAtInstitution[0]);
    } else {
      setPage('EXISTING_LOGIN_PICKER');
    }
  };

  const handleSelectLogin = (login) => onRediscover(login);

  return (
    <>
      {!page &&
        <Box display="flex" flex="1" flexDirection="column" mt={2} ml={`${fiBrandingIndent}px`} mr={3}>
          <Typography variant="subtitle1">
            You already have this institution connected to Simplifi. Which of the following are you trying to do?
          </Typography>
          <Box display="flex" flex="1" flexDirection="column" mt={1}>
            <QPanelButton
              id={'update-existing-connection'}
              onClick={handleUpdate}
              title="Update existing username to add new accounts"
              startIcon={<AddBoxIcon style={{ color: theme.palette.grey.level6 }} />}
              subtitle="(e.g. additional credit card, checking account, etc.)"
            />
            <QPanelButton
              id={'add-new-connection'}
              onClick={handleAdd}
              title="Add a new username to connect other accounts"
              startIcon={<AddBoxIcon style={{ color: theme.palette.grey.level6 }} />}
              subtitle="(e.g. partner or kid's accounts)"
            />
          </Box>
        </Box>}
      {page === 'EXISTING_LOGIN_PICKER' &&
        <ExistingLoginPicker
          existingLoginsAtInstitution={existingLoginsAtInstitution}
          fiBrandingIndent={fiBrandingIndent}
          onSelect={handleSelectLogin}
        />}
    </>
  );
});

export default NewOrRediscoverPicker;

