// @flow
// eslint-disable-next-line max-classes-per-file
import { List, Map as ImmutableMap, Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';
import ExtendableError from 'es6-error';

import { accountsTypes } from 'companion-app-components/flux/accounts';
import type { Account } from 'companion-app-components/flux/accounts';

import type { Institution } from 'data/institutions/types';
import type { InstitutionLogin, MfaChallenge } from 'data/institutionLogins/types';

export const DIALOG_TYPE_ACCOUNTS_DISCOVERY = 'DIALOG_TYPE_ACCOUNTS_DISCOVERY';
export type AccountDiscoveryMode =
  'ADD-FI' | 'PROVIDE-CREDENTIALS' | 'PROVIDE-MFA' | 'UPDATE-CREDENTIALS' | 'ACCOUNT-REDISCOVERY' | 'RE-AUTHENTICATE' | 'UPDATE-FI' | 'MIGRATE-INSTITUTION-LOGIN';

type AccountDiscoveryDialogPropsProps = {
  mode: AccountDiscoveryMode,
  initiator: ?string,

  institutionLogin: InstitutionLogin,
  mfaChallenge: ?MfaChallenge,

  aggAction: ?any,

  afterCloseAction: ?any,
}
export type AccountDiscoveryDialogProps = RecordOf<AccountDiscoveryDialogPropsProps>;
export const mkAccountDiscoveryDialogProps: RecordFactory<AccountDiscoveryDialogPropsProps> =
  Record({
    mode: 'ADD-FI',
    initiator: undefined,

    institutionLogin: undefined,
    mfaChallenge: undefined,

    aggAction: undefined,

    afterCloseAction: undefined,
    onClose: undefined,
  });

// =============================================================================
// Account Discovery Data
// =============================================================================

type DiscoveredAccountProps = {
  shadowAccountId: string,

  id: string,
  cpId: string,
  cpLoginId: string,

  channel: string,

  type: string,
  subType: ?string,

  name: string,
  number: string,
  maskedNumber: string,

  balance: number,
  balanceAt: string,

  currency: string,
};
export type DiscoveredAccount = RecordOf<DiscoveredAccountProps>;
export const mkDiscoveredAccount: RecordFactory<DiscoveredAccountProps> =
  Record({
    shadowAccountId: null,

    id: null,
    cpId: null,
    cpLoginId: null,

    channel: null,

    type: null,
    subType: null,

    name: null,
    number: null,
    maskedNumber: null,

    balance: null,
    balanceAt: null,

    currency: null,
  });

type ActiveDiscoveredAccountProps = {
  account: Account,
  discoveredAccount: DiscoveredAccount
}
export type ActiveDiscoveredAccount = RecordOf<ActiveDiscoveredAccountProps>;
export const mkActiveDiscoveredAccount: RecordFactory<ActiveDiscoveredAccountProps> =
  Record({
    account: accountsTypes.mkAccount('UNKNOWN'),
    discoveredAccount: mkDiscoveredAccount(),
  });

type AccountDiscoveryDataProps = {
  institutionLoginId: string,
  credentialsBlob: ?string,

  mode: string,
  status: string,

  activeAccounts: ?List<ActiveDiscoveredAccount>,
  deadAccounts: ?List<Account>,
  newAccounts: ?List<DiscoveredAccount>,
  unconnectedAccounts: ?List<Account>,
};
export type AccountDiscoveryData = RecordOf<AccountDiscoveryDataProps>;
export const mkAccountDiscoveryData: RecordFactory<AccountDiscoveryDataProps> =
  Record({
    institutionLoginId: null,
    credentialsBlob: null,

    status: null,

    activeAccounts: null,
    deadAccounts: null,
    newAccounts: null,
    unconnectedAccounts: null,
  });

// =============================================================================
// InstitutionLoginUpserter
// =============================================================================

type InstitutionLoginUpserterDataProps = {
  institution: Institution,
  channel: ?string,

  institutionLogin: ?InstitutionLogin,
  institutionLoginIdBeingMigrated: ?String,

  // contains MFA challenge data if server has requested an MFA challenge
  mfaChallenge: ?MfaChallenge,

  // contains partnerAuthUris after oauth flow (if authType is PARTNER_AUTH
  partnerAuthUris: ?List<string>,

  // currently used for Plaid when intializing link (may be used by other aggregators in future)
  authToken: ?string,

  // contains discovered accounts data (if discovering accounts and discovered data is available
  accountDiscoveryData: ?AccountDiscoveryData,
  updateStrategy: 'REPLACE' | 'UPDATE',

  isAuthenticationDone: boolean,
  isAccountUpsertingDone: boolean,
  isAccountRefreshingDone: boolean,

  isPolling: false,
  isSubmitting: boolean,

  error: ?Object,
};
export type InstitutionLoginUpserterData = RecordOf<InstitutionLoginUpserterDataProps>;
export const mkInstitutionLoginUpserterData: RecordFactory<InstitutionLoginUpserterDataProps> =
  Record({
    institution: undefined,
    channel: undefined,

    institutionLogin: undefined,
    institutionLoginIdBeingMigrated: undefined,

    mfaChallenge: undefined,
    partnerAuthUris: undefined,
    publicToken: undefined,

    accountDiscoveryData: undefined,
    updateStrategy: 'UPDATE',

    isAuthenticationDone: false,
    isAccountUpsertingDone: false,
    isAccountRefreshingDone: false,

    isPolling: false,
    isSubmitting: false,

    error: undefined,
  });

type InstitutionLoginUpserterStoreProps = {
  upserterData: ImmutableMap,
}
export type InstitutionLoginUpserterStore = RecordOf<InstitutionLoginUpserterStoreProps>;
export const mkInstitutionLoginUpserterStore: RecordFactory<InstitutionLoginUpserterStoreProps> =
  Record({
    upserterData: ImmutableMap(),
  });

// ================================================================================================
// Types AccountDiscovery Status
// ================================================================================================

type AccountDiscoveryAggregatorStatusProps = {
  isProcessing: boolean,
  aggStatus: string,
  cpAggStatusCode: ?string,
  cpAggStatusDetail: ?string,
  userInstructions: ?string,
};
export type AccountDiscoveryAggregatorStatus = RecordOf<AccountDiscoveryAggregatorStatusProps>;
export const mkAccountDiscoveryAggregatorStatus: RecordFactory<AccountDiscoveryAggregatorStatusProps> =
  Record({
    isProcessing: false,

    aggStatus: 'unknown',

    cpAggStatusCode: undefined,
    cpAggStatusDetail: undefined,
    userInstructions: undefined,
  });


type AccountDiscoveryStatusProps = {
  institutionLoginId: string,
  isProcessing: boolean,
  status: string,
  aggregators: List<AccountDiscoveryAggregatorStatus>,

}
export type AccountDiscoveryStatus = RecordOf<AccountDiscoveryStatusProps>;
export const mkAccountDiscoveryStatus: RecordFactory<AccountDiscoveryStatusProps> =
  Record({
    institutionLoginId: null,
    isProcessing: false,
    status: '',
    aggregators: List(),
  });

// ================================================================================================
// Account Upserter Types
// ================================================================================================

export type ACCOUNT_ACTION_TYPES = 'ADD_ACCOUNT' | 'IGNORE' | 'LINK_TO';

export type AccountActionProps = {
  id: string,
  type: ACCOUNT_ACTION_TYPES,
  menuItemLabel: string,
  selectedItemLabel: string,
  accountToLinkTo: ?Account,
}
export type AccountAction = RecordOf<AccountActionProps>;
export const mkAccountAction: RecordFactory<AccountActionProps> =
  Record({
    id: '0',
    type: 'LINK_TO',
    menuItemLabel: 'Link to',
    selectedItemLabel: 'Link',
    accountToLinkTo: null,
  });

export const ACCOUNT_ACTION_ADD = mkAccountAction({
  id: 'ADD',
  type: 'ADD_ACCOUNT',
  menuItemLabel: 'Add',
  selectedItemLabel: 'Add',
});
export const ACCOUNT_ACTION_IGNORE = mkAccountAction({
  id: 'IGNORE',
  type: 'IGNORE',
  menuItemLabel: 'Ignore',
  selectedItemLabel: 'Ignore',
});

export type AccountData = {
  action: AccountAction,

  name: string,
  type: string,
  discoveredAccount: DiscoveredAccount,

  linkableAccountsSection1: ?List<Account>,
  linkableAccountsSection2: ?List<Account>,
  linkableAccountsSection3: ?List<Account>,

  formFieldName: string,
};

// ================================================================================================
// Account Discovery Error Types
// ================================================================================================

export class AccountDiscoveryFailure extends ExtendableError {
  constructor(message: String, aggStatus: String, aggStatusCode: String) {
    super(message);
    this.aggStatus = aggStatus;
    this.aggStatusCode = aggStatusCode;
  }
}

// ================================================================================================
// Aggregation Info Types
// ================================================================================================

type AggregationInfoProps = {
  type: 'FINICITY' | 'PLAID',
};
export type AggregationInfo = RecordOf<AggregationInfoProps>;

type FinicityAggregationInfoProps = AggregationInfoProps & {
  connectUrl: string,
  pollingRef: string,
}
export type FinicityAggregationInfo = RecordOf<FinicityAggregationInfoProps>;

export const mkFinicityAggregationInfo: RecordFactory<FinicityAggregationInfo> =
  Record({
    type: 'FINICITY',
    connectUrl: undefined,
    pollingRef: undefined,
  });

type PlaidAggregationInfoProps = AggregationInfoProps & {
  linkToken: string,
}
export type PlaidAggregationInfo = RecordOf<PlaidAggregationInfoProps>;

export const mkPlaidAggregationInfo: RecordFactory<PlaidAggregationInfoProps> =
  Record({
    type: 'PLAID',
    linkToken: null,
  });
