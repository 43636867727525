import * as React from 'react';
import { get } from 'lodash';
import { Field, FormikProps, FormikValues } from 'formik';

import TagsField from 'components/QuickenControls/TagsField';

interface RecrTagFieldProps {
  classes: Record<string, string>;
  name: string;
  formikProps: FormikProps<FormikValues>;
}

const RecrTagField: React.FC<RecrTagFieldProps> = ({ name, formikProps, classes }) => (
  <Field name={name}>
    {({ field, form: { touched, errors } }) => (
      <TagsField
        {...field}
        onChange={(tags: string[]) => formikProps.setFieldValue(field.name, tags)}
        error={Boolean(get(touched, field.name) && get(errors, field.name))}
        helperText={get(touched, field.name) && get(errors, field.name)}
        label="Tags"
        placeholder=" "
        margin="dense"
        id="transaction-tags"
        fullWidth
        InputProps={{
          classes: {
            underline: classes.underlineOverride,
            input: classes.tags,
          },
        }}
      />
    )}
  </Field>
);

export default RecrTagField;
