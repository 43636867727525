import { List } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import { budgetItemsTypes } from 'companion-app-components/flux/budget-items';
import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { chartOfAccountsTypes, chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';

export const createNewBudgetItems = (budget, groupId, dateRange, itemInfo) => {

  let itemsToAdd = List();
  let startingDate = dateRange.startDate.set({ month: 1, day: 1 });
  const isIncome = itemInfo.coa && categoriesSelectors.isIncomeCat(null, itemInfo.coa.id);

  do {
    const newBudgetItem = budgetItemsTypes.mkBudgetItem({
      clientId: uuidv4().toUpperCase(),
      budgetId: budget.id,
      startDate: startingDate.toISODate() ?? undefined,
      amount: (isIncome ? Math.abs(itemInfo.amount) : -Math.abs(itemInfo.amount)),
      coa: itemInfo.coa && chartOfAccountsTypes.mkChartOfAccount(itemInfo.coa),
      groupId,
      name: itemInfo.name || chartOfAccountsSelectors.getCoaStringSelector(undefined, itemInfo.coa),
      type: itemInfo.budgetItemType || 'COA',
    });
    itemsToAdd = itemsToAdd.push(newBudgetItem);
    startingDate = startingDate.plus({ months: 1 });
  } while (startingDate.month !== 1);

  return itemsToAdd;
};

export const setAmountSign = (amt, coa) => {
  // for the budget item, we flip the sign for expense cats when this amount was provided
  // from the category review card
  if (coa) {
    return categoriesSelectors.isIncomeCat(null, coa.id) ? amt : -amt;
  }
  return amt;
};
