// @flow
import UAParser from 'ua-parser-js';

import { getLogger, PlatformTypes } from 'companion-app-components/utils/core';

const log = getLogger('Platform');

const getDevice = (uaDevice) => {
  let { type, vendor, model } = uaDevice;

  if (!type) {
    const ipad = /ipad/i.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (ipad) {
      type = 'tablet';
      vendor = 'Apple';
      model = 'ipad';
    } else {
      // if we don't know what the device is, we will default to the desktop
      type = 'desktop';
    }
  }
  return ({ type, vendor, model });
};

const getPlatform = () => {
  const ua = new UAParser();
  log.log('UA Result', ua.getResult());

  const browser = ua.getBrowser();
  const device = getDevice(ua.getDevice());
  const os = ua.getOS();

  let displayMode = 'other';
  if (typeof window === 'object') {
    if (window.matchMedia?.('(display-mode: standalone)').matches) {
      displayMode = 'standalone';
    } else if (window.matchMedia?.('(display-mode: browser)').matches) {
      displayMode = 'browser';
    } else if (window.matchMedia?.('(display-mode: fullscreen)').matches) {
      displayMode = 'fullscreen';
    } else if (window.matchMedia?.('(display-mode: minimal-ui)').matches) {
      displayMode = 'minimal-ui';
    }
  }

  const isPwaDisplayMode = displayMode === 'standalone' || displayMode === 'fullscreen' || displayMode === 'minimal-ui';

  const isIDevice = device.type === 'ipad' || device.type === 'iphone';

  const isAndroid = os.name === 'Android';

  const isFirefox = browser.name === 'firefox';
  const isOpera = /opera/.test(browser.name);
  const isSafari = browser.name === 'Safari' || browser.name === 'Mobile Safari';

  const showAddToHomeScreenForSafari = isIDevice && isSafari;
  const showAddToHomeScreenForFirefox = isAndroid && isFirefox;
  const showAddToHomeScreenForOpera = isAndroid && isOpera;

  const supportsManualAddToHomeScreen =
    showAddToHomeScreenForSafari || showAddToHomeScreenForFirefox || showAddToHomeScreenForOpera;

  const isMouseSupported = typeof window === 'object' && window.matchMedia?.('(hover:hover)').matches;

  // isChromebook now returns a Promise.
  const isChromebook = async () => {
    if (typeof window === 'object' && window.getDigitalGoodsService) {
      try {
        const service = Object.freeze(await window.getDigitalGoodsService('https://play.google.com/billing'));
        if (service === null) {
          // DGAPI 1.0
          // Play Billing is not available. Use another payment flow.
          return false;
        }
        // Play billing is available.
        return true;
      } catch (error) {
        // DGAPI 2.0
        // Play Billing is not available. Use another payment flow.
        return false;
      }
    }
    // Not a chromebook
    return false;
  };
  // const isChromebook = () => { true }; // hack

  return PlatformTypes.mkPlatform({
    os,
    browser: PlatformTypes.mkBrowser({ ...browser }),
    device: PlatformTypes.mkDevice({ ...device }),

    displayMode,

    // This only detects whether we were launched in PWA mode - may still be installed
    isPwaInstalled: isPwaDisplayMode,
    isPwaInstallable: supportsManualAddToHomeScreen,

    supportsOnBeforeInstallPrompt: typeof window === 'object' && 'onbeforeinstallprompt' in window,

    supportsManualAddToHomeScreen,
    showAddToHomeScreenForSafari,
    showAddToHomeScreenForFirefox,
    showAddToHomeScreenForOpera,

    supportsPushNotifications: typeof navigator === 'object' && 'serviceWorker' in navigator
      && typeof window === 'object' && 'PushManager' in window,

    isMouseSupported,
    isChromebook,
  });
};

const platform = getPlatform();
export default platform;
