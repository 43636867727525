const styles = (theme: Object) => ({
  checkBox: {
    marginTop: '-8px',
    paddingLeft: '3px',
    float: 'right',
  },
  checkLabel: {
    color: theme.applyOpacityToHex(theme.palette.grey.level7, 0.7),
    size: '12px',
  },
  field: {
    display: 'flex',
    padding: '10px 0',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: 124,
    marginRight: 204,
    overflow: 'hidden',
    height: 'fit-content',
  },
  smallFormContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  lock: {
    color: theme.applyOpacityToHex(theme.palette.grey.level7, 0.7),
  },
  partnerListItem: {
    color: theme.palette.greyScaleDeprecated[2],
    paddingBottom: 18,
  },
  whiteBack: {
    backgroundColor: theme.palette.greyScaleDeprecated[7],
  },
});

export default styles;

