/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-foreign-prop-types */

const config = {
  name: 'Other',
  id: 'OTHER_COMPONENTS',
  children: [],
};

export default config;
