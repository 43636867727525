import React from 'react';
import ReportProblemLink from 'components/ReportProblemLink';

import { mkSharedComponent } from '../Wrappers/factories';

/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-foreign-prop-types */

const config = {
  name: 'Navigation',
  id: 'NAVIGATION',
  children: [
    mkSharedComponent({
      name: 'ReportProblemLink',
      description: 'link like button that triggers "Report a Problem" dialog',
      componentPropTypes: ReportProblemLink.propTypes,
      searchTags: ['report', 'error', 'problem', 'link'],
      id: 'REPORT_PROBLEM_LINK',
      defaultState: {},
      // examples: [],
      component: () => (
        <ReportProblemLink feedbackSubject="problem in my feature" />
      ),
    }),
  ],
};

export default config;
