import { handleActions } from 'redux-actions';

import * as actions from './actions';
import { mkPersistStore, PersistStore } from './types';

const reducer = handleActions({

  [actions.loadPersistedDatasetData]: (state: PersistStore) => state.set('loadedPersistedDatasetData', true),

}, mkPersistStore());

export const REDUCER_KEY = 'persistStore';
export default reducer;
