import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import * as Docs from '../DocsUtils';

const svgCache = {};
const picCache = {};
function importAll(r, cache) {
  r.keys().forEach((key) => { cache[key] = r(key); }); // eslint-disable-line no-param-reassign
}
importAll(require.context('assets/', true, /\.svg$/), svgCache);
importAll(require.context('assets/', true, /\.png$|jpg$/), picCache);

export const ASSETS_WRAPPER_KEY = 'ASSETS';

function getAssets(cache) {
  const hydratedAssets = {};
  Object.entries(cache).forEach(([filePath, file]) => {
    const splitPath = filePath.replace('./', '').split('/');
    let storageRef = hydratedAssets;
    while (splitPath.length > 1) {
      const pathPrefix = splitPath.shift();
      if (!storageRef[pathPrefix]) {
        storageRef[pathPrefix] = {};
      }
      storageRef = storageRef[pathPrefix];
    }
    storageRef[splitPath.shift()] = file;
  });
  return hydratedAssets;
}

const useStyles = makeStyles()((theme) => ({
  assetCard: {
    display: 'inline-block',
    padding: 5,
    margin: 5,
    '&:hover': {
      boxShadow: theme.shadows[11],
    },
  },
  assetInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));


const Asset = (props) => {
  const { fileName, src, path, ...other } = props;

  const [copied, setCopied] = useState(false);
  const { classes } = useStyles();

  const rendered = useMemo(() => {
    if (fileName.match(/\.inline\.svg/)) {
      return <src.default style={{ height: 50, width: 'auto' }} {...other} />;
    }
    if (fileName.match(/\.png$|\.jpg$/)) {
      return <img style={{ height: 100, width: 'auto' }} alt={'asset-alt'} src={src?.default || src} {...other} />;
    }
    return <img style={{ height: 50, width: 'auto' }} alt={'asset-alt'} src={src?.default || src} {...other} />;
  }, [fileName, src, other]);

  const handleClick = useCallback((_event) => {
    const name = _.camelCase(path.split('/').pop().split('.')[0]);
    navigator.clipboard.writeText(`import ${name} from '${path}';`);
    setCopied(true);
  }, [path]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <Card
      style={{ display: 'inline-block', padding: 5, margin: 5 }}
      className={classes.assetCard}
      onClick={handleClick}
    >
      <div className={classes.assetInnerContainer}>
        {rendered}
        <Typography variant={'caption'}>{copied ? 'copied' : fileName}</Typography>
      </div>
    </Card>
  );
};
Asset.propTypes = {
  fileName: PropTypes.string,
  src: PropTypes.any,
  path: PropTypes.string,
};

function renderAssets(assetsObject, path) {
  return Object.entries(assetsObject).map(([fileName, children]) => (typeof (children) === 'object' && children !== null && !children.__esModule) ?  // eslint-disable-line no-underscore-dangle
    <Docs.Section key={`assets-${fileName}`}>
      <Docs.SectionSubheader>{fileName}</Docs.SectionSubheader>
      {renderAssets(children, `${path}/${fileName}`)}
      <Docs.SectionSpacer />
    </Docs.Section>
    :
    <Asset path={`${path}/${fileName}`} key={`assets-${fileName}`} fileName={fileName} src={children} alt={fileName} />);
}

const AssetsDocs = (_props) => {

  const svgs = useMemo(() => renderAssets(getAssets(svgCache), 'assets'), []);
  const pics = useMemo(() => renderAssets(getAssets(picCache), 'assets'), []);

  return (
    <div>
      <Docs.Header>Assets</Docs.Header>

      <Docs.Section>
        <Docs.SectionHeader>Material-UI Icons</Docs.SectionHeader>
        <Docs.SectionContent>
          The majority of icons used in the app can be found and searched through&nbsp;
          <a target={'_blank'} href={'https://material-ui.com/components/material-icons/'} rel={'noreferrer noopener'}>here</a>
          &nbsp;on MUI&apos;s website
        </Docs.SectionContent>
      </Docs.Section>

      <Docs.Spacer />
      <Docs.Note>click to copy import line</Docs.Note>


      <Docs.Section>
        <Docs.SectionHeader>SVGs</Docs.SectionHeader>
        {svgs}
      </Docs.Section>

      <Docs.Spacer />

      <Docs.Section>
        <Docs.SectionHeader>Pictures</Docs.SectionHeader>
        {pics}
      </Docs.Section>
    </div>
  );
};

export default AssetsDocs;

