import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { transactionsTypes } from 'companion-app-components/flux/transactions';

import { makeStyles } from 'tss-react/mui';
import AlarmOn from '@mui/icons-material/AlarmOn';
import { formatNumber } from 'components/QuickenControls/AmountField';
import { getCalendarTxnType } from 'data/transactions/utils';
import { transactionLabelStyles } from '../../styles';

const useStyles = makeStyles()(transactionLabelStyles as Record<string, any>);

interface TransactionLabelProps {
  txn?: transactionsTypes.CashFlowTransaction;
  showMoreLabel?: boolean;
  extraTxns?: number;
}

function getTxnTypeClasses(type: string, classes: Record<string, any>) {
  
  // @ts-expect-error Property 'calTxnLabelTypes' does not exist on type 'typeof transactionsTypes'.
  const { income, expense, overdue, normal, more } = transactionsTypes.calTxnLabelTypes;

  switch (type) {
    case more:
      return classes.moreType;
    case income:
    case expense:
      return classes.dueType;
    case overdue:
      return classes.overdueType;
    case normal:
    default:
      return null; // classes.normalType
  }
}

const TransactionLabel: React.FC<TransactionLabelProps> = (props) => {
  const { classes, cx } = useStyles();
  const { txn, showMoreLabel, extraTxns } = props;

  const incomeCOAIds = useSelector((state) => !showMoreLabel && categoriesSelectors.getIncomeCOAIds(state));

  // @ts-expect-error Property 'calTxnLabelTypes' does not exist on type 'typeof transactionsTypes'.
  const type = useMemo(() => !showMoreLabel ? getCalendarTxnType(txn, incomeCOAIds) : transactionsTypes.calTxnLabelTypes.more, [showMoreLabel, txn, incomeCOAIds]);

  const typeClasses: string = useMemo(() => getTxnTypeClasses(type, classes), [type, classes]);
  const isIncome: boolean | undefined = useMemo(() => !showMoreLabel && txn && parseFloat(txn.amount) > 0.0, [txn, showMoreLabel]);
  const payeeLabel: string = useMemo(() => !showMoreLabel ? txn && txn.payee : `${extraTxns} more...`, [showMoreLabel, txn, extraTxns]);
  const amountLabel: string = useMemo(() => !showMoreLabel && txn && formatNumber(txn.amount, 'USD', '0,00.00'), [showMoreLabel, txn]);

  return (
    <div className={cx(classes.txnLabel, { [typeClasses]: Boolean(typeClasses) }, isIncome ? classes.incomeType : classes.expenseType)}>
      <div className={classes.payeeLabel}>
        <AlarmOn className={classes.reminderIcon} />
        <span>{payeeLabel}</span>
      </div>
      {amountLabel && <div className={classes.amountLabel}>{amountLabel}</div>}
    </div>
  );
};

export default React.memo(TransactionLabel);
