import React, { useEffect, useState, useRef, useCallback, FC } from 'react';
import { DateTime } from 'luxon';
import { List as ListImmutable, Map } from 'immutable';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useEffectOnce } from 'react-use';

import { tracker } from 'companion-app-components/utils/core';
import { budgetItemsTypes } from 'companion-app-components/flux/budget-items';
import type { BudgetRecord, BudgetTreeNodeProps } from 'companion-app-components/flux/budgets/budgetsTypes';

import BudgetHeader from 'containers/BudgetsV2/components/BudgetHeader';
import BudgetEmbedded from 'containers/BudgetsV2/components/BudgetEmbedded';
import { computeViewState, ViewStateEnum } from 'containers/OverviewPage/components/helpers';
import { BUDGET_VIEWS, BUDGET_ANNUAL_SUBVIEWS } from 'containers/BudgetsV2/constants';
import { staticConfig } from 'containers/SetupPage/GettingStartedWebFirst/config';
import LoadingView from 'components/LoadingView';
import ZeroStateView from 'components/ZeroStateView';
import QButton from 'components/QButton';
import WFBudgetSetup from 'components/Dialogs/WFBudgetSetup';
import EditBudgetsDialog from 'components/Budgets/EditBudgetsDialog';
import GettingStartedModule from 'components/GettingStartedModule';
import RendersCount from 'components/RendersCount';
import zeroStateIcon from 'assets/zero-state-images/budgets.svg';
import underConstructionImage from 'assets/under-construction.png';

import { getBudgetViewStateObject, getKeyFutureMonthsVisibility } from './utils';
import type { BudgetUiStateType } from './types';

import styles from './styles';

const staticConfigWF = staticConfig(true, false, true);
const budgetConfig = staticConfigWF.length && staticConfigWF.length >= 3 && staticConfigWF[2]; // Get third element Budgets
const useStyles = makeStyles()(styles as Record<string, any>);

interface BudgetsV2Props {
  budgets: Map<string, BudgetRecord>;
  budgetsCreation: boolean;
  budget: BudgetRecord;
  budgetTreeNode: BudgetTreeNodeProps;
  budgetTreeNodes: ListImmutable<BudgetTreeNodeProps>;
  transactions: Record<string, any>;
  navigationPath: Record<string, any>[];
  budgetItems: ListImmutable<BudgetRecord>;
  dispatchGetUserPreferencesAction: () => void;
  dispatchGetBudgetsAction: () => void;
  dispatchDeleteBudgetAction: (budget: Record<string, any>) => void;
  dispatchGetBudgetItemsAction: () => void;
  dispatchGetCategoryGroupListsAction: () => void;
  dispatchGetCategoryGroupsAction: () => void;
  dispatchGetCategoriesAction: () => void;
  dispatchGetPreferencesAction: () => void;
  dispatchGetTransactionsAction: () => void;
  dispatchSetSharedDatasetPreferenceAction: (preference: Record<string, any>) => void;
  dispatchSetSharedWebAppDatasetPreferenceAction: (options: Record<string, any>) => void;
  hasReportableAccounts: boolean;
  isLoading: boolean;
  loadPending: boolean;
  uiState: BudgetUiStateType;
  setUIState: (uiState: BudgetUiStateType) => void;
  budgetsEnabled: boolean;
  dialogAlert: (title: string, description: string, cb: (e: Record<string, any>) => void, btns: string[], type: string, icon?: boolean) => void;
  budgetAccountIds: Record<string, any>;
  isWebFirstDataset: boolean;
  dashboardRef: string | string[] | null;
  preferences: Record<string, any>;
  dispatchBudgetFullCalcCall: (id: string) => void;
  dispatchCreateBudgetItemsAction: (arr: ListImmutable<BudgetRecord>) => void;
}

const BudgetsV2: FC<BudgetsV2Props> = ({
  loadPending,
  isLoading,
  hasReportableAccounts,
  budgets,
  budget,
  budgetItems,
  navigationPath,
  budgetTreeNode,
  budgetTreeNodes,
  transactions,
  budgetsEnabled,
  budgetAccountIds,
  budgetsCreation,
  isWebFirstDataset,
  preferences,
  dialogAlert,
  uiState,
  dashboardRef,

  setUIState,
  dispatchGetUserPreferencesAction,
  dispatchBudgetFullCalcCall,
  dispatchSetSharedDatasetPreferenceAction,
  dispatchSetSharedWebAppDatasetPreferenceAction,
  dispatchGetBudgetsAction,
  dispatchGetBudgetItemsAction,
  dispatchGetCategoryGroupListsAction,
  dispatchGetCategoryGroupsAction,
  dispatchGetCategoriesAction,
  dispatchGetPreferencesAction,
  dispatchGetTransactionsAction,
  dispatchDeleteBudgetAction,
  dispatchCreateBudgetItemsAction,
}) => {

  const navigate = useNavigate();
  const { classes, theme }: { classes: Record<string, any>, theme: Record<string, any> } = useStyles();

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showNewBudgetSetup, setShowNewBudgetSetup] = useState(false);

  const prevUiState = useRef(uiState);
  const prevLoadPending = useRef(loadPending);
  const prevIsLoading = useRef(isLoading);
  const prevNavigationPath = useRef(navigationPath);
  const prevBudget = useRef(budget);

  const goToDate = (date) => {
    setUIState({
      ...uiState,
      startDate: new Date(date.getFullYear(), date.getMonth(), 1),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    });
  };

  const refreshData = () => {
    dispatchGetBudgetsAction();
    dispatchGetBudgetItemsAction();
    dispatchGetCategoryGroupListsAction();
    dispatchGetCategoryGroupsAction();
    dispatchGetCategoriesAction();
    dispatchGetPreferencesAction();
    dispatchGetTransactionsAction();
  };

  const budgetFullCalcTrigger = (budgetId) => {
    const fullCalcTriggeredList = preferences?.shared?.dataset?.budgetOptions?.fullCalcTriggeredList || [];
    if (!fullCalcTriggeredList.includes(budgetId)) {  
      dispatchBudgetFullCalcCall(budgetId);
    }
  };

  useEffectOnce(() => {
    goToDate(new Date());
    if (!isLoading) {
      dispatchGetUserPreferencesAction();
      refreshData();
    }
    const budgetId = budget?.id;
    if (budgetId) {
      setTimeout(() => {
        budgetFullCalcTrigger(budgetId);
      }, 1000);
    }
  });

  const createNewBudget = useCallback((startDate, budgetItemsPriorYear, monthByMonth) => {
    let allBudgetItemsToSave: ListImmutable<BudgetRecord> = ListImmutable();
    // iterate over 12 months
    for (let month = 0; month < 12; month++) {
      const copyMonth = monthByMonth ? month : 11;
      const budgetItemsToCopy = budgetItemsPriorYear.filter((x) => DateTime.fromISO(x.startDate).month === copyMonth);

      const budgetItemsToSave = budgetItemsToCopy.map((item) =>
        budgetItemsTypes.mkBudgetItem({
          clientId: uuidv4().toUpperCase(),
          budgetId: budget.id,
          startDate: DateTime.fromISO(startDate).set({ month }).toFormat('yyyy-MM-dd'),
          amount: item.amount,
          groupId: item.groupId,
          type: item.type,
          coa: item.coa,
          calculatedActualsAmount: undefined,
          // @ts-expect-error: Object literal may only specify known properties,
          calclulatedRolloverAmount: undefined,
        }));
      allBudgetItemsToSave = allBudgetItemsToSave.concat(budgetItemsToSave.toList());
    }
    dispatchCreateBudgetItemsAction(allBudgetItemsToSave);
  }, [dispatchCreateBudgetItemsAction, budget]);

  const checkBudgetExistence = useCallback(() => {
    const { startDate } = uiState;

    if (!budget) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const budgetItemsViewingYear = budgetItems.filter((x) => x.budgetId === budget.id && (DateTime.fromISO(x.startDate).year === DateTime.fromISO(startDate).year));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const budgetItemsPriorYear = budgetItems.filter((x) => x.budgetId === budget.id && (DateTime.fromISO(x.startDate).year === (DateTime.fromISO(startDate).year - 1)));

    // if the year being viewed has no budgetItems, but the year PRIOR to it does, then
    // offer to create items for that year.
    if (isLoading || loadPending || budgetItemsViewingYear.size > 0 || budgetItemsPriorYear.size === 0) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { year } = DateTime.fromISO(startDate);
    const priorYear = Number(year) - 1;

    if (budgetTreeNodes && budgetTreeNodes.size < 1) {
      dialogAlert(
        `Create budget for ${year}?`,
        `Quicken creates budgets one year at a time, and you currently do not have a budget defined for ${year}. ` +
        `Would you like to create a ${year} budget based on your budget for ${priorYear}?`,
        (ret) => {
          if (ret.btnPressed.includes('Yes')) {
            // TODO create budget items for the next year
            const monthByMonth = !(ret.btnPressed.includes('December'));
            createNewBudget(startDate, budgetItemsPriorYear, monthByMonth);
          }
        },
        [`Yes, copy each month from ${priorYear}`, `Yes, copy from December, ${priorYear}`, 'No'],
        'info',
      );
    }
  }, [
    dialogAlert, 
    uiState, 
    budgetTreeNodes,
    budgetItems, 
    isLoading, 
    loadPending, 
    budget,
    createNewBudget,
  ]);

  useEffect(() => {
    if (budgetsCreation && isWebFirstDataset &&
      (uiState.startDate !== prevUiState.current.startDate ||
      loadPending !== prevLoadPending.current ||
      isLoading !== prevIsLoading.current)) {
      checkBudgetExistence();
    }
    // Update previous values
    prevUiState.current = uiState;
    prevLoadPending.current = loadPending;
    prevIsLoading.current = isLoading;
  }, [
    budgetsCreation, 
    isWebFirstDataset, 
    uiState, 
    loadPending, 
    isLoading, 
    checkBudgetExistence,
  ]);

  const fullNavPath = (params) => {
    let navPath;
    if (params.navigationPath && params.budget) {
      navPath = [params.budget.id, ...params.navigationPath.map((node) => node.id)];
    } else if (params.budget) {
      navPath = [params.budget.id];
    }
    return navPath;
  };

  useEffect(() => {
    const navPath = fullNavPath({ navigationPath, budget });
    const prevNavPath = fullNavPath({ prevNavigationPath, prevBudget });
    if (JSON.stringify(navPath) !== JSON.stringify(prevNavPath)) {
      const nodesType = budgetTreeNodes?.reduce((results, node) => {
        const resultsMutable = results;
        resultsMutable.isExpense = resultsMutable.isExpense || node.negate < 0;
        resultsMutable.isIncome = resultsMutable.isIncome || node.negate > 0;
        return resultsMutable;
      }, {
        isIncome: false,
        isExpense: false,
      });
      let groupType;
      if (nodesType && nodesType.isIncome && !nodesType.isExpense) {
        groupType = 'income';
      } else if (nodesType && nodesType.isExpense && !nodesType.isIncome) {
        groupType = 'expense';
      } else {
        groupType = 'dynamic';
      }
      tracker.track(tracker.events.budgetView, { group_type: groupType, level: navPath && (navPath.length - 1) });
      prevBudget.current = budget;
      prevNavigationPath.current = navigationPath;
    }
  }, [navigationPath, budget, budgetTreeNodes]);

  const goPrevMonth = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newDate = new Date(uiState.startDate.getFullYear(), uiState.startDate.getMonth() - 1, 1);
    goToDate(newDate);
    tracker.track(tracker.events.budgetChange, { type: 'prev_date' });
  };

  const goThisMonth = () => {
    goToDate(new Date());
    tracker.track(tracker.events.budgetChange, { type: 'cur_date' });
  };

  const goNextMonth = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newDate = new Date(uiState.startDate.getFullYear(), uiState.startDate.getMonth() + 1, 1);
    goToDate(newDate);
    tracker.track(tracker.events.budgetChange, { type: 'next_date' });
  };

  const handleNavigationClick = (node) => {
    if (budget) {
      if (node) {
        navigate(`/budgets?displayBudget=${budget.id}&displayNode=${node.key}`, { replace: true });
      } else {
        navigate(`/budgets?displayBudget=${budget.id}`, { replace: true });
      }
    }
  };

  const handleBudgetChange = (event) => {
    if (event.target && event.target.value) {
      dispatchSetSharedDatasetPreferenceAction({ budgetOptions: { lastViewedBudgetId: event.target.value } });
      setUIState({ 
        ...uiState,
        selectedAnnualBudgetItem: null,
      });
      navigate(`/budgets?displayBudget=${event.target.value}`, { replace: true });
      goThisMonth(); 
      tracker.track(tracker.events.budgetChange, { type: 'budget' });
      budgetFullCalcTrigger(event.target.value);
    }
  };

  const handleViewChange = ({ id, name }, annualSubView, event) => {
    if (event.target.value === BUDGET_VIEWS.annual.key) {
      navigate(`/budgets?displayBudget=${id}`, { replace: true });
    }
    dispatchSetSharedWebAppDatasetPreferenceAction(
      getBudgetViewStateObject(id, name, event.target.value, annualSubView, getKeyFutureMonthsVisibility(annualSubView)),
    );
    tracker.track(tracker.events.budgetViewChange, { changedBudgetView: event.target.value });
  };

  const handleAnnualSubViewChange = ({ id, name }, budgetView, event) => {
    const annualSubView = event.target.value;
    dispatchSetSharedWebAppDatasetPreferenceAction(
      getBudgetViewStateObject(id, name, budgetView, annualSubView, getKeyFutureMonthsVisibility(annualSubView)),
    );
    tracker.track(tracker.events.annualBudgetViewChange, { annualViewType: BUDGET_ANNUAL_SUBVIEWS[annualSubView].label });
  };

  const updateBudget = () => {
    dispatchGetBudgetItemsAction();
  };

  const deleteBudget = () => {
    if (!budget) return;
    dialogAlert(
      `Delete the budget "${budget.name}"`,
      `This will delete the budget "${budget.name}" and all its items.  This cannot be undone. Are you sure?`,
      (ret) => {
        if (ret.btnPressed === 'Yes') {
          dispatchDeleteBudgetAction(budget.set('isDeleted', true));
        }
      },
      ['Yes', 'Cancel'],
      'delete',
      false,
    );
  };

  const view = computeViewState(
    () => isLoading,
    () => !(budgets && budgets.size),
    hasReportableAccounts,
    loadPending,
    !budgetsEnabled,
  );

  return (
    <div
      style={{ height: '100%' }}
    >
      <Helmet>
        <title>Budgets</title>
      </Helmet>

      {view === ViewStateEnum.DISABLED &&
        <img
          alt="page is under construction"
          src={underConstructionImage}
          style={{
            display: 'block',
            width: '50%',
            marginTop: 40,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />}

      {view === ViewStateEnum.LOADING &&
        <LoadingView
          style={{ height: theme.defaults.content.height }}
        />}

      {view === ViewStateEnum.LOAD_PENDING &&
        <ZeroStateView
          style={{ margin: 40 }}
          primary="No Data"
          icon={zeroStateIcon}
          secondary="We can't fetch your data from the server. Please try to refresh the page."
        />}

      {(view === ViewStateEnum.ZERO || view === ViewStateEnum.EMPTY) && !isWebFirstDataset &&
        <ZeroStateView
          primary=""
          style={{ margin: 'auto', height: theme.defaults.content.height }}
          icon={zeroStateIcon}
          secondary={"It looks like you don't have a budget set up. If you have a budget set up on the desktop, be sure to sync it first." +
            `${budgetsCreation && isWebFirstDataset ? "Or you can create a budget from here by clicking 'Create' " : ''}`}
        >
          {budgetsCreation && isWebFirstDataset &&
            <div style={{ marginBottom: 25 }}>
              <QButton
                variant="contained"
                onClick={() => setShowNewBudgetSetup(true)}
              >
                Create a Budget
              </QButton>
            </div>}
        </ZeroStateView>}

      {(view === ViewStateEnum.ZERO || view === ViewStateEnum.EMPTY || (view === ViewStateEnum.MAIN && showNewBudgetSetup)) &&
        isWebFirstDataset && budgetConfig && (
        <div className={classes.gettingStartedModuleRoot}>
          <GettingStartedModule config={budgetConfig} />
          <div className={classes.buttonArea}>
            {budgetConfig.buttons.map((btn) =>
              <div key={`SETUPBTN: ${btn.value}`}>
                <QButton
                  variant="contained"
                  classes={{ conButton: classes.setupButton }}
                  onClick={() => btn.value === 'createBudget' && setShowNewBudgetSetup(true)}
                  id={btn.id}
                >
                  {btn.label}
                </QButton>
              </div>)}
          </div>
        </div>
      )}

      {view === ViewStateEnum.MAIN && !showNewBudgetSetup &&
        <div>
          <RendersCount id="budget-header" />
          <BudgetHeader
            isLoading={view === ViewStateEnum.LOADING}
            budget={budget}
            budgets={budgets}
            budgetsCreation={budgetsCreation && isWebFirstDataset}
            startDate={uiState.startDate}
            goNextMonth={goNextMonth}
            goPrevMonth={goPrevMonth}
            goThisMonth={goThisMonth}
            handleBudgetChange={handleBudgetChange}
            onEdit={() => setShowEditDialog(true)}
            onDelete={deleteBudget}
            handleViewChange={handleViewChange}
            handleAnnualSubViewChange={handleAnnualSubViewChange}
            dashboardRef={dashboardRef}
          />
          <BudgetEmbedded
            isLoading={view === ViewStateEnum.LOADING}
            budgetsCreation={budgetsCreation && isWebFirstDataset}
            budget={budget}
            accountIds={budgetAccountIds}
            navigationPath={navigationPath}
            budgetTreeNodes={budgetTreeNode ? ListImmutable([budgetTreeNode]) : budgetTreeNodes}
            transactions={transactions}
            onTxnUpdate={updateBudget}
            handleNavigationClick={handleNavigationClick}
            dashboardRef={dashboardRef}
            budgetUiState={uiState}
            setBudgetUiState={setUIState}
          />
        </div>}
      {showNewBudgetSetup &&
        <WFBudgetSetup
          onClose={() => setShowNewBudgetSetup(false)}
        />}
      {showEditDialog &&
        <EditBudgetsDialog
          onClose={() => setShowEditDialog(false)}
          budget={budget}
          startDate={uiState.startDate}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          month={DateTime.fromISO(uiState.startDate).toFormat('MM')}
          budgetTreeNodes={budgetTreeNodes}
        />}
    </div>
  );
};

export default BudgetsV2;
