import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { isAcme } from 'isAcme';
import { Map as ImmutableMap } from 'immutable';

export const DIALOG_TYPE = 'MCP_DIALOG';

export const showMCPDialog = createAction(
  createDialog.toString(),
  (id) => mkRootUiData({
    type: DIALOG_TYPE,
    allowNesting: true,
    props: ImmutableMap({
      id,
      title: `Message from ${isAcme ? 'Simplifi' : 'Quicken'}`,
    }),
  })
);
