import React from 'react';
import { List as ImmutableList } from 'immutable';

export const featureState = {
  partial: 'partial',
  available: ' available',
  none: 'none',
};


const featureListWin = ImmutableList([
  {
    id: 'bankingAccount',
    label: 'Banking Accounts',
    description:
  <p>You can add and connect multiple accounts types such as checking, savings, credit card, and cash.</p>,
    features: {
      web: featureState.available,
      win: featureState.available,
    },
  },
  {
    id: 'investmentAccount',
    label: 'Investment Accounts',
    description: <>
      <p>
        To add an investment account, you need to use a Quicken desktop application. Quicken desktop (Mac / Windows)
        supports adding your investment accounts by connecting to your investments & brokerage firms.
      </p>
      <p>
        Once you add investments on your desktop application, you can then sync the account to Quicken web.
        Quicken web will update you on the latest balance any time. Note that this is not supported
        in the Quicken Starter Tier.
      </p>
    </>,
    features: {
      web: featureState.partial,
      win: featureState.available,
    },
  },
  {
    id: 'investmentLoanAccount',
    label: 'Investment or Loan Accounts',
    description: <>
      <p>
        To add an investment or loan account, you need to use a Quicken desktop application. Quicken desktop (Mac / Windows)
        supports adding your investment or loan accounts by connecting to your investments & brokerage firms.
      </p>
      <p>
        Once you add investments or loans on your desktop application, you can then sync the account to Quicken web.
        Quicken web will update you on the latest balance any time. Note that this is not supported
        in the Quicken Starter Tier.
      </p>
    </>,
    features: {
      web: featureState.partial,
      win: featureState.available,
    },
  },
  {
    id: 'assetLiabilityAccount',
    label: 'Asset and Liability Accounts',
    description: <>
      <p>
        To add an online loan or asset account, you need to use Quicken desktop. Quicken desktop (Mac / Windows)
        supports adding your loan and asset accounts by connecting to your financial institution.
      </p>
      <p>
        You can sync the account to Quicken web. Quicken web can then show you the latest balance any time. Note that this is not
        supported in the Quicken Starter Tier.
      </p>
    </>,
    features: {
      web: featureState.partial,
      win: featureState.available,
    },
  },
  {
    id: 'billsIncomeRemainder',
    label: 'Bills and Income Reminders',
    description: <>
      <p>
        You can create reminders for your bills and income. <span style={{ whiteSpace: 'nowrap' }}>These will help </span>
        you to keep track of multiple bills and their payment dates in one place.
      </p>
      <p>
        You can also view your projected cash flow to find out how the bills impact your account balance.
      </p>
    </>,
    features: {
      web: featureState.available,
      win: featureState.available,
    },
  },
  {
    id: 'billCenterDashboard',
    label: 'Bill center dashboard and PDF download of bills',
    description: <>
      <p>
        You can only link your utility bills to Quicken using the Quicken desktop application (Mac / Windows).
      </p>
      <p>
        Once linked, Quicken desktop and Quicken web can automatically fetch the latest bill due
        date and amount from your biller.
      </p>
    </>,
    features: {
      web: featureState.partial,
      win: featureState.available,
    },
  },
  {
    id: 'monthlyBudget',
    label: 'Monthly Budget',
    description: <>
      <p>Quicken scans your transactions to create a budget based on all your regularly recurring expenses.</p>
      <p>You can customize these recommendations so they are tailored to your lifestyle and priorities.</p>
    </>,
    features: {
      web: featureState.available,
      win: featureState.available,
    },
  },
  {
    id: 'annualBudget',
    label: 'Annual Budget (12 Month Budget)',
    description:
  <p>
    To create an annual budget in which you can edit amounts for multiple months and categories in one place,
    you will have to use Quicken desktop.
  </p>,
    features: {
      web: featureState.none,
      win: featureState.available,
    },
  },
  {
    id: 'trackInvestments',
    label: 'Track Investments',
    description: <>
      <p>
        On Quicken desktop, you can track both liquid and retirement holdings including brokerage, 401(k)s, IRAs,
        options, bonds, ETFs, and mutual funds.
      </p>
      <p>
        You can compare your portfolio versus target allocations (Windows only), get a historical snapshot and take
        advantage of other amazing features. This functionality is only available on the desktop
        applications at this time.
      </p>
    </>,
    features: {
      web: featureState.none,
      win: featureState.available,
    },
  },
  {
    id: 'reports',
    label: 'Reports',
    description: <>
      <p>
        Quicken reports display information about your finances in a clear, easy-to-read tabular format. To give
        you a visual summary of your finances, Quicken can also display your data as bar graphs or pie charts.
      </p>
      <p>
        You can see some of these spending reports in Quicken web, but more options are available in Quicken desktop.
      </p>
    </>,
    features: {
      web: featureState.partial,
      win: featureState.available,
    },
  },
  {
    id: 'debtReduction',
    label: 'Debt Reduction',
    description: <>
      <p>
        Debt reduction planner helps you track your principal and interest separately and perform what-if
        analysis with ability to vary payment scenarios.
      </p>
      <p>
        This functionality is only available on the desktop applications at this time.
      </p>
    </>,
    features: {
      web: featureState.none,
      win: featureState.available,
    },
  },
]);

const featureListMac = ImmutableList([
  {
    id: 'bankingAccount',
    label: 'Banking Accounts',
    description:
  <p>Ability to add and connect multiple accounts types  like Checking, savings, credit card & Cash.</p>,
    features: {
      web: featureState.available,
      mac: featureState.available,
    },
  },
  {
    id: 'investmentAccount',
    label: 'Investment Accounts',
    description: <>
      <p>
        If you want to add an investment account, you will have to do that in Quicken desktop. Quicken desktop (Mac / Windows)
        supports adding your investment accounts by connecting to your investments & brokerage firms.
      </p>
      <p>
        You can then sync the account to Quicken web to see the latest balance any time on the web application.
        Note that this is not supported in Quicken Starter Tier.
      </p>
    </>,
    features: {
      web: featureState.partial,
      mac: featureState.available,
    },
  },
  {
    id: 'investmentLoanAccount',
    label: 'Investment or Loan Accounts',
    description: <>
      <p>
        If you want to add an investment or loan account, you will have to do that in Quicken desktop. Quicken desktop (Mac / Windows)
        supports adding your investment or loan accounts by connecting to your investments & brokerage firms.
      </p>
      <p>
        You can then sync the account to Quicken web to see the latest balance any time on the web application.
        Note that this is not supported in Quicken Starter Tier.
      </p>
    </>,
    features: {
      web: featureState.partial,
      mac: featureState.available,
    },
  },
  {
    id: 'assetLiabilityAccount',
    label: 'Asset and Liability Accounts',
    description: <>
      <p>
        If you want to add an online loan / asset account, you will have to do that in Quicken desktop.
        Quicken desktop (Mac / Windows) supports adding your loan / asset accounts by connecting to your financial institution.
      </p>
      <p>
        You can then sync the account to Quicken web to see the latest balance any time on the web application.
        Note that this is not supported in Quicken Starter Tier.
      </p>
    </>,
    features: {
      web: featureState.partial,
      mac: featureState.available,
    },
  },
  {
    id: 'billsIncomeRemainder',
    label: 'Bills and Income Reminders',
    description:
  <p>
    You can create reminders for your bills and income.
    These will help you to keep track of multiple bills and their payment date at one place.
    You can also see your projected cash flow to see how the bills impact your account balance.
  </p>,
    features: {
      web: featureState.available,
      mac: featureState.available,
    },
  },
  {
    id: 'billCenterDashboard',
    label: 'Bill center dashboard and PDF download of bills',
    description:
  <p>You can link your utility bills to Quicken.
    Once linked, Quicken will automatically fetch the latest latest bill due date and
    amount you’re your biller website and update. For now you can do this only in Quicken desktop (Mac / Windows).
  </p>,
    features: {
      web: featureState.partial,
      mac: featureState.available,
    },
  },
  {
    id: 'monthlyBudget',
    label: 'Monthly Budget',
    description:
  <p>
    Quicken scans your transactions to create a budget based on all your regularly recurring expenses.
    You can customize these recommendations so they are tailored to your lifestyle and priorities.
  </p>,
    features: {
      web: featureState.available,
      mac: featureState.available,
    },
  },
  {
    id: 'annualBudget',
    label: 'Annual Budget (12 Month Budget)',
    description:
  <p>
    To create an annual budget where you can edit amounts for multiple months and categories at one place,
    you will have to use Quicken desktop.
  </p>,
    features: {
      web: featureState.none,
      mac: featureState.available,
    },
  },
  {
    id: 'trackInvestments',
    label: 'Track Investments',
    description: <>
      <p>
        On Quicken desktop (Windows / Mac), you can track both liquid and retirement holdings including brokerage,
        401(k)s, IRAs, options, bonds, ETFs, and mutual funds.
      </p>
      <p>
        Analyse your portfolio versus target allocations (Windows only) and get a historical snapshot and many more amazing functionalities.
      </p>
    </>,
    features: {
      web: featureState.none,
      mac: featureState.available,
    },
  },
  {
    id: 'reports',
    label: 'Reports',
    description:
  <p>
    Quicken reports display information about your finances in a clear, easy-to-read tabular format.
    You can see some of these spending reports in Quicken web and more in Quicken desktop.
  </p>,
    features: {
      web: featureState.partial,
      mac: featureState.available,
    },
  },
  {
    id: 'debtReduction',
    label: 'Debt Reduction',
    description:
  <p>
    Debt reduction planner helps you track your principal and interest separately and perform what-if
    analysis with ability to vary payment scenarios.
    To do this, you have to use Quicken desktop (Windows). This is not available on the Mac.
  </p>,
    features: {
      web: featureState.none,
      mac: featureState.none,
    },
  },
]);

export const featureList = {
  win: featureListWin,
  mac: featureListMac,
};
