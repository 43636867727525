import { makeStyles } from 'tss-react/mui';


export const useStyles = makeStyles()((theme) => ({
  action: {
    margin: theme.spacing(1),
    width: '100%',
  },
  actionSecondary: {
    margin: theme.spacing(1),
    width: '100%',
    color: 'white',
    borderColor: 'white',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
  closeButton: {
    color: 'white',
    right: 12,
    padding: 8,
    marginTop: -8,
    marginRight: -4,
    position: 'absolute',
  },
  icon: {
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: '16px',
    marginTop: theme.spacing(3),
  },
  muiMessage: {
    padding: 0,
  },
  root: {
    padding: 0,
    width: '375px',
  },
  toast: {
    backgroundColor: 'black',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    margin: `${theme.spacing(1)} ${theme.spacing(1)} 0px`,
    textAlign: 'center',
  },
  message: {
    color: 'white',
    margin: theme.spacing(1),
    textAlign: 'center',
  },
}));
