import * as React from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  Location,
  NavigateFunction,
} from 'react-router-dom';

interface RouterProps {
  location: Location;
  navigate: NavigateFunction;
  params: string | Record<string, string | undefined>;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const withRouterLegacy = <P,>(Component: React.ComponentType<P & { router: RouterProps }>) => {
  const ComponentWithRouterProp = (props: P) => {
    const location: Location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  };

  return ComponentWithRouterProp;
};

export default withRouterLegacy;
