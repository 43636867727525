import { createAction } from 'redux-actions';
import { Map as ImmutableMap } from 'immutable';

import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Dataset } from 'companion-app-components/flux/datasets/datasetsTypes';

export const DIALOG_TYPE_SHARE_FINANCES = 'SHARE_FINANCES_DIALOG';
export const DIALOG_TYPE_INVITE_SENT = 'INVITE_SENT_DIALOG';
export const DIALOG_TYPE_MANAGE_MEMBERS = 'MANAGE_MEMBERS_DIALOG';
export const DIALOG_TYPE_CONFIRMATION_NEW = 'CONFIRMATION_NEW_DIALOG';

export const showShareFinancesDialog = createAction(
  createDialog.toString(),
  (props) => mkRootUiData({
    type: DIALOG_TYPE_SHARE_FINANCES,
    id: DIALOG_TYPE_SHARE_FINANCES,
    props: ImmutableMap(props),
  }),
);

export const showInviteSentDialog = createAction(
  createDialog.toString(),
  (props) => mkRootUiData({
    type: DIALOG_TYPE_INVITE_SENT,
    id: DIALOG_TYPE_INVITE_SENT,
    props: ImmutableMap(props),
  }),
);

export const showManageMembersDialog = createAction(
  createDialog.toString(),
  (props: { dataset: Dataset }) => mkRootUiData({
    type: DIALOG_TYPE_MANAGE_MEMBERS,
    id: DIALOG_TYPE_MANAGE_MEMBERS,
    props: ImmutableMap(props),
  }),
);

export const showConfirmationDialog = createAction(
  createDialog.toString(),
  (props) => mkRootUiData({
    type: DIALOG_TYPE_CONFIRMATION_NEW,
    id: DIALOG_TYPE_CONFIRMATION_NEW,
    props: ImmutableMap(props),
  }),
);
