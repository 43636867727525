// BASE
import React from 'react';
import { compose } from 'redux';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';


// ACTIONS, SELECTORS, UTILS
import { formatNumber } from 'components/QuickenControls/AmountField';

// MUI COMPONENTS
import Typography from '@mui/material/Typography';

const propTypes = {
  transactions: PropTypes.object,
  currency: PropTypes.string,
  showCents: PropTypes.bool,
  name: PropTypes.string,
  disableDate: PropTypes.bool,
};

// STYLES HOOK
const useStyles = makeStyles()({
  transactionListRow: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const MiniTxnList = (props) => {
  // PROPS
  const { transactions, currency, showCents, name, disableDate } = props;

  // STYLES ============================================================================
  const { classes } = useStyles(props);

  const txnNodes = [];

  transactions.forEach((txn) => {
    txnNodes.push(
      <div
        key={`miniTxnList:${name}:row:${txn.id}${txn.amount}`}
        className={classes.transactionListRow}
      >
        {!disableDate &&
          <Typography
            style={{ flex: 1 }}
            variant="body2"
          >
            {DateTime.fromISO(txn.postedOn).toFormat('MMM dd')}
          </Typography>}
        <Typography
          variant="body2"
          style={{ flex: 3.2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {txn.payee}
        </Typography>
        <Typography
          style={{ flex: 1.2, textAlign: 'right' }}
          variant="body2"
        >
          {formatNumber(txn.amount.toFixed(showCents ? 2 : 0), currency, ' 0,0')}
        </Typography>
      </div>
    );
  });

  return txnNodes;
};

MiniTxnList.propTypes = propTypes;
export default compose(
)(MiniTxnList);
