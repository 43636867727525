import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Map as ImmutableMap } from 'immutable';

export const DIALOG_TYPE = 'EDIT_REMINDER_DIALOG';

export const editReminderDialog = createAction(
  createDialog.toString(),
  (txn, context, freeToSpendId, type, otherProps) => mkRootUiData({
    type: DIALOG_TYPE,
    allowNesting: true,
    props: ImmutableMap({
      txn,
      context,
      freeToSpendId,
      type,
      otherProps,
    }),
  })
);
