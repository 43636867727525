import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

let objectStringMap = ImmutableMap();


//
// inUseFn takes name and cacheString, and returns if that object is still in use
//
export const getOrCreateStringFromObject = (name, cacheObject, maxSize = 5, inUseFn) => {

  const objectStringList = objectStringMap.get(name) || ImmutableList();

  if (inUseFn && objectStringList?.size > (maxSize + 1)) {
    garbageCollectStringMap(name, inUseFn);
  }

  const cacheItem = objectStringList.find((item) => allItemsEqual(cacheObject, item.cacheObject));
  if (cacheItem) return cacheItem.cacheString;
  const cacheString = uuidv4();
  objectStringMap = objectStringMap.set(name, objectStringList.push({ cacheObject, cacheString }));
  return cacheString;
};

const allItemsEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  return !keys1.some((key) => (obj2[key] !== obj1[key]));
};

function garbageCollectStringMap(name, inUseFn) {
  const newList = objectStringMap.get(name)?.filter((cacheItem) =>
    inUseFn(name, cacheItem.cacheString));
  objectStringMap = objectStringMap.set(name, newList);
}
