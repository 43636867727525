import { handleActions, combineActions } from 'redux-actions';

import { getLogger } from 'companion-app-components/utils/core';
import { resourceStoreTypes, resourceUpserters } from 'companion-app-components/flux/core';

import * as actions from './categoryGroupListsActions';

const log = getLogger('data/categoryGroupLists/categoryGroupListsReducer.js');

export const CATEGORY_GROUP_LISTS_REDUCER_KEY = 'categoryGroupListsStore';

const initialState = resourceStoreTypes.mkQcsSyncResourceStore();
const categoryGroupListsReducer = handleActions({
  [combineActions(
    actions.getCategoryGroupListsAction,
    actions.createCategoryGroupListsAction,
  )]: (state) => {
    log.log('getCategoryGroupListsAction || createCategoryGroupListsAction...');
    return state.merge({ isLoading: true });
  },
  [combineActions(
    actions.getCategoryGroupListsSuccessAction,
    actions.createCategoryGroupListsSuccessAction,
  )]: (state, { payload }) => {
    log.log('getCategoryGroupListsSuccessAction || createCategoryGroupListsSuccessAction...\n', payload);
    return resourceUpserters.upsertResources(state, payload);
  },
  [combineActions(
    actions.getCategoryGroupListsFailureAction, 
    actions.createCategoryGroupListsFailureAction
  )]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

}, initialState);

export default categoryGroupListsReducer;
