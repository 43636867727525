
import React, { FC, useState, ChangeEvent } from 'react';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { List } from 'immutable';

import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChartRounded';

import { chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';

import QIconButton from 'components/QIconButton';
import QTip from 'components/QuickenControls/QTip';
import AmountField from 'components/QuickenControls/AmountField';
import CategoryReviewPopupCard from 'components/CategoryReviewPopupCard';

import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface SuggestedBudgetItemProps {
  item?: Record<string, any>;
  onChange: (id: string | ChangeEvent<HTMLInputElement>) => void;
  selected: boolean;
  accountIds?: List<string> | null;
  currency?: string;
  header?: boolean;
  classesExternal?: Record<string, any>;
}

const SuggestedBudgetItem: FC<SuggestedBudgetItemProps> = ({ item = { id: 'header' }, currency, accountIds, selected, onChange, header, classesExternal }) => {

  const [catReviewAnchorEl, setCatReviewAnchorEl] = useState(null);
  const [showIcons, setShowIcons] = useState(false);

  const { classes } = useStyles();

  const constructName = () => {
    if (header) {
      return ['Category', ''];
    }
    let str = chartOfAccountsSelectors.getCoaStringSelector(undefined, item.coa);
    let parentStr = '';
    const catParts = str.split(':');
    if (catParts && catParts.length > 1) {
      parentStr = `(${catParts[catParts.length - 2]})`;
      str = catParts[catParts.length - 1];
    }

    return [str, parentStr];
  };

  const nameArray = constructName();

  return (
    <div
      className={classNames(classesExternal?.categoryRow, classes.categoryRow)}
      onMouseEnter={() => setShowIcons(true)}
      onMouseLeave={() => setShowIcons(false)}
    >
      <Checkbox
        checked={selected}
        id={`suggested-categories-checkbox-${nameArray[0]}${nameArray[1]}`}
        onChange={header ? onChange : () => onChange(item.key || item.coa.id)}
      />
      <div className={classes.categoryNameWrapper}>
        <Typography
          key={`editBudgetCats:str1 ${item.id}`}
          variant={header ? 'subtitle1' : 'body2'}
          className={header ? classes.headerText : classes.categoryName}
        >
          {nameArray[0]}
        </Typography>
        <Typography
          key={`editBudgetCats:str2 ${item.id}`}
          variant="body2"
          className={classes.categoryNameParent}
        >
          {nameArray[1]}
        </Typography>
      </div>
      <div
        className={classes.categoryAmount}
      >
        {header &&
          <Typography
            variant={header ? 'subtitle1' : 'body2'}
            className={header ? classes.headerText : classes.categoryName}
          >
            Amount
          </Typography>}
        {!header &&
          <QTip
            title="You can edit the default amount after you add it to the budget"
          >
            <AmountField
              id={`budgetAmt:${nameArray[0]}`}
              value={Math.abs(item.monthlyAverage)}
              currencySymbol={currency}
              currencyGap
              disableUnderline
              className={header ? null : classes.amountInputStyle}
              omitCents
            />
          </QTip>}
      </div>
      <div className={classNames(classes.iconHolder, showIcons ? '' : classes.hide)}>
        {!header &&
          <>
            <QTip
              title="Category Details Card..."
            >
              <QIconButton
                aria-label="Show Budget Item Info"
                onClick={(e) => setCatReviewAnchorEl(e.currentTarget)}
                id={`row-${item.id}-delete-item`}
                size="small"
              >
                <BarChartIcon
                  className={classes.barChartIcon}
                />
              </QIconButton>
            </QTip>
            <CategoryReviewPopupCard
              anchorEl={catReviewAnchorEl}
              open
              onClose={() => setCatReviewAnchorEl(null)}
              coaIds={[item.coa.id]}
              classes={{
                root: classes.categoryReviewCardRoot,
              }}
              currency={currency}
              accountIds={accountIds}
            />
          </>}
      </div>
    </div>
  );
};

export default SuggestedBudgetItem;
