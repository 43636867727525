// @flow
import type { Node } from 'react';
import { Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

import { COMPONENT_WRAPPER_KEY } from './ComponentWrapper';
import { FUNCTION_WRAPPER_KEY } from './FunctionWrapper';

// =============================================== //
// ============== Shared Components ============== //
// =============================================== //
type SharedComponentProps = {
  id: string,
  name: string,
  type: ?string,
  component: Node | Array<Node>,
  description: ?string | ?Node,
  searchTags: ?Array<string>,
  examples: ?Array<Object>,
  defaultState: ?Object,
  componentPropTypes: ?Object,
};
export type SharedComponent = RecordOf<SharedComponentProps>;
export const mkSharedComponent: RecordFactory<SharedComponentProps> =
  Record({
    id: 'NO_ID_COMPONENT',
    name: 'UNNAMED_COMPONENT',
    type: COMPONENT_WRAPPER_KEY,
    searchTags: [],
    defaultState: {},
    component: null,
    componentPropTypes: undefined,
    description: undefined,
    examples: undefined,
  });

// =============================================== //
// ================== Functions ================== //
// =============================================== //
type SharedFunctionProps = {
  id: string,
  name: string,
  func: (any) => any,
  type: ?string,
  description: ?string | ?Node,
  searchTags: ?Array<string>,
  parameters: ?Array<string>,
  examples: ?Array<any>,
};
export type SharedFunction = RecordOf<SharedFunctionProps>;
export const mkSharedFunction: RecordFactory<SharedFunctionProps> =
  Record({
    id: 'NO_ID_FUNCTION',
    name: 'UNNAMED_FUNCTION',
    type: FUNCTION_WRAPPER_KEY,
    searchTags: [],
    func: () => null,
    parameters: undefined,
    description: undefined,
    examples: undefined,
  });
