// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import TableCell from '@mui/material/TableCell';
import { withStyles } from 'tss-react/mui';
import type { Theme } from '@mui/material';

export const border = '1px solid #e1e1e1';
export const fontWeight = 500;


export const StickyTableCell = withStyles(TableCell, (theme: Theme) => ({
  head: {
    left: 0,
    position: 'sticky',
    align: 'left',
    zIndex: '2',
    backgroundColor: theme.palette.common.white,
  },
  body: {
    left: 0,
    position: 'sticky',
    backgroundColor: theme.palette.common.white,
    minWidth: '450px',
    border,
    zIndex: '10',
  },
}));

export const StickyTableHead = withStyles(TableCell, (theme: Theme) => ({
  head: {
    left: 0,
    position: 'sticky',
    zIndex: '3',
    backgroundColor: theme.palette.common.white,
  },
  body: {
    left: 0,
    position: 'sticky',
    backgroundColor: theme.palette.common.white,
    minWidth: '450px',
    border,
    zIndex: '3',
  },
}));

const styles = ({ palette, components }) => ({
  head: {
    minWidth: '150px',
    border,
  },
  tableContainer: {
    border,
    overflowY: 'auto',
    height: '65vh',
    padding: 0,
  },
  cell: {
    minWidth: '100px',
    border,
  },
  cellTotals: {
    bottom: 0,
  },
  cellAmounts: {
    padding: 0,
  },
  viewRolloverWrapper: {
    color: palette.black,
    fontWeight: 600,
    fontSize: 14.5,
    lineHeight: '17px',
  },
  rolloverMainHead: {
    fontSize: 14,
    fontWeight: 600,
    display: 'flex',
    '& div:last-child': {
      marginLeft: 'auto',
    },
  },
  closeButton: {
    padding: 0,
  },
  rolloverDate: {
    margin: '8px 0',
  },
  rolloverTable: {
    textAlign: 'right',
    fontWeight: 400,
    borderCollapse: 'separate',
    borderSpacing: '0 15px',
    backgroundColor: palette.white,
    '& tr:last-child': {
      boxShadow: 'inset 0px 1px 0px',
      height: 50,
    },
  },
  rollOverOperation: {
    width: 30,
  },
  rollOverName: {
    width: 100,
  },
  rollOverAmounts: {
    width: 120,
    textAlign: 'right',
  },
  rollOverEdit: {
    width: 70,
    color: palette.primary.main,
    fontWeight: 500,
    textAlign: 'center',
  },
  rolloverDatedon: {
    fontSize: 13,
    lineHeight: '15px',
    color: palette.others.titleFaded,
  },
  buttonWrapper: {
    textAlign: 'right',
    marginTop: 5,
  },
  input: {
    color: palette.black,
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: 0,
    fontSize: components.register.fontSize.default,
    width: 100,
    alignItems: 'right',
    '& input': {
      padding: '0 5px',
      fontSize: components.register.fontSize.default,
      border: `1px solid ${components.yearlyBudget.inputBorder}`,
      borderRadius: 5,
      height: 28, // IE-11 needs this
      '&::-ms-clear ': {
        display: 'none',
      },
    },
  },
});

export default styles;
