import React, { useEffect, useState, useMemo } from 'react';

import { useTheme, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import * as Docs from 'containers/SharedComponentsPage/DocsUtils';

import objectTree from '../objectTree';

export const THEME_WRAPPER_KEY = 'THEME';

const ThemeDocs = (_props) => {
  const theme = useTheme();

  const [darkMode, setDarkMode] = useState();
  const [muiTheme, setMUITheme] = useState();

  useEffect(() => {
    setMUITheme(createTheme({
      palette: {
        mode: darkMode ? 'dark' : 'light',
      },
    }));
  }, [darkMode]);

  const themeTree = useMemo(() => objectTree('theme', theme), [theme]);

  return (
    <Box maxWidth={'100%'} m={4}>
      <Docs.Header>Theme Docs</Docs.Header>

      <Docs.Section>
        <Docs.SectionHeader>Theme</Docs.SectionHeader>
        <Docs.Note>click to copy</Docs.Note>

        <Docs.SectionContent>MUI has native theme support for dark mode theme.palette.mode = dark / light</Docs.SectionContent>
        <FormControlLabel
          label={`dark mode = ${theme.palette.mode}`}
          control={
            <Switch
              onChange={(_event, value) => setDarkMode(value)}
            />
          }
        />

        <Box display="flex">
          <Box display="flex" flexDirection="column" m={2}>
            <Typography variant="caption">
              current app theme
            </Typography>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              defaultExpanded={['']}
            >
              {themeTree}
            </TreeView>
          </Box>

          <Box display="flex" flexDirection="column" m={2}>
            <Typography variant="caption">
              default base MUI theme - source of truth
            </Typography>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              defaultExpanded={['']}
            >
              {objectTree('muiTheme', muiTheme)}
            </TreeView>
          </Box>
        </Box>
      </Docs.Section>

      <Docs.Spacer />

      <Docs.Section>
        <Docs.SectionHeader>Typography</Docs.SectionHeader>
        <Docs.SectionContent>
          {
            Object.entries(theme.typography)
              .filter(([_key, value]) => typeof value === 'object')
              .map(([key, value]) => <div key={key}><Typography variant={key}>{key}: Text &nbsp;&nbsp; size: {value.fontSize}</Typography></div>)
          }
        </Docs.SectionContent>
      </Docs.Section>

      <Docs.Section>
        <Docs.SectionHeader>Shadows</Docs.SectionHeader>
        <Docs.SectionContent>
          {
            theme.shadows?.map((shadow, index) => (
              <Paper key={`shadow-${shadow}`} style={{ boxShadow: shadow, textAlign: 'center', marginTop: theme.spacing(index / 2), padding: theme.spacing(2) }}>
                theme.shadows[{index}]
              </Paper>
            ))
          }
        </Docs.SectionContent>
      </Docs.Section>

      <Docs.Section>
        <Docs.SectionHeader>Colors</Docs.SectionHeader>

        <Docs.SectionContent>
          <Docs.SectionSubheader>Palette</Docs.SectionSubheader>
          {Object.entries(theme.palette.colorScaleShouldNeverBeAccessedDirectly).map(([group, colors]) => (
            <Box display="flex" key={group}>
              {Object.entries(colors).map(([name, color]) => (
                <Box
                  key={name}
                  bgcolor={color}
                  width={160}
                  height={40}
                  border={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {group}:{name}
                </Box>
              ))}
            </Box>
          ))}
        </Docs.SectionContent>

      </Docs.Section>

    </Box>
  );
};

export default ThemeDocs;
