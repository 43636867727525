// CORE
import React, { useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

// MUI
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

import { createMemorizedRule, updateMemorizedRule } from 'companion-app-components/flux/memorized-rules/memorizedRulesActions';
import { mkMemorizedRule } from 'companion-app-components/flux/memorized-rules/memorizedRulesTypes';
import { getMemorizedRuleApplicableTransactions } from 'companion-app-components/flux/memorized-rules/memorizedRulesSelectors';

// COMPONENTS
import PayeeAutocomplete from 'components/PayeeAutocomplete';
import CategoryField from 'components/QuickenControls/CategoryField';
import QButton from 'components/QButton';
import StaticTransactionList from 'components/StaticTransactionList';
import { bindPromiseAction } from 'utils/actionHelpers';

const useStyles = makeStyles()((theme) => ({
  txnListItem: {
    gridTemplateColumns: '1.5fr 2fr 2fr 3fr 1.5fr',
  },
  redText: {
    color: theme.palette.number.negative,
  },
  staticPayee: {
    marginTop: theme.spacing(4),
  },
}));

const MemorizedRuleForm = React.memo((props) => {
  const { memorizedRule, fromTxn, onClose, formikRef, setViewExistingTransactions, viewExistingTransactions, ...otherProps } = props;
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const [applyToTransactions, setApplyToTransactions] = useState(Boolean(fromTxn));

  const formik = useFormik({
    initialValues: {
      ...memorizedRule,
    },
    onSubmit: (values) => {
      const payload = mkMemorizedRule({
        clientId: uuidv4().toUpperCase(),
        ...values,
        payee: values.payee.trim(),
      });
      if (values.id) {
        dispatch(updateMemorizedRule(payload, {
          undo: { userMessage: 'Category rule updated.' },
          props: { memorizedRule, applyToTransactions },
        }));
      } else {
        bindPromiseAction(dispatch, createMemorizedRule)(payload, {
          undo: { userMessage: 'Category rule created.' },
          props: { memorizedRule: {}, applyToTransactions },
        }).then(() => {
          onClose(undefined, 'create-complete');
        }).catch(() => {
          onClose(undefined, 'create-error');
        });
      }
      onClose?.(undefined, 'submit');
    },
  });
  useImperativeHandle(formikRef, () => formik, [formik]);

  useEffect(() => {
    formik.registerField('payee', { validate: (value) => value ? undefined : 'required' });
    formik.registerField('coa', { validate: (value) => value ? undefined : 'required' });
  }, [formik]);

  const memorizedRuleApplicableTransactions = useSelector((state) => getMemorizedRuleApplicableTransactions(state, formik.values, memorizedRule?.id ? memorizedRule : {}));

  if (viewExistingTransactions) {
    return (
      <Box>
        <Typography variant="body2">
          {'Existing transactions that will be set to category'}&nbsp;<b>{formik.values.coa?.fullPathName}</b>
        </Typography>
        <Box marginTop={1}>
          <StaticTransactionList
            txns={memorizedRuleApplicableTransactions.valueSeq()}
            listItemProps={{ className: classes.txnListItem }}
            classes={{ category: classes.redText }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit} {...otherProps}>
      <Typography color="textSecondary" variant="caption">Create a rule to use a specific category in the future</Typography>
      <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'} marginTop={4}>
        <Box flex={1} marginRight={1}>
          <Typography variant="body1">If payee name is</Typography>
          {
            (fromTxn || memorizedRule?.id) ?
              <Typography className={classes.staticPayee} variant={'body1'}>
                {formik.values.payee}
              </Typography>
              :
              <PayeeAutocomplete
                id="payee"
                inputValue={formik.values.payee || ''}
                onInputChange={(event, value, _reason) => (event || value) && formik.setFieldValue('payee', value)}
                onBlur={formik.handleBlur}
                onFocus={(event) => event.target.select()}
                textFieldProps={{
                  // autoFocus: true,
                  label: 'Select a Payee',
                  error: Boolean((formik.touched.payee || formik.submitCount) && formik.errors.payee),
                  helperText: ((formik.touched.payee || formik.submitCount) && formik.errors.payee) ? formik.errors.payee : ' ',
                  variant: 'outlined',
                  margin: 'normal',
                  fullWidth: true,
                }}
              />
          }
        </Box>
        <Box flex={1} marginLeft={1} paddingRight={4}>
          <Typography variant="body1">Then use this category</Typography>
          <CategoryField
            id="coa"
            value={formik.values.coa}
            onChange={(coa) => formik.setFieldValue('coa', coa)}
            editable
            createEnabled
            allowBlank
            margin="normal"
            textFieldVariant="outlined"
            label="Category"
            error={Boolean((formik.touched.coa || formik.submitCount) && formik.errors.coa)}
            textFieldProps={{
              placeholder: 'Select a Category',
              helperText: (formik.touched.coa || formik.submitCount) ? formik.errors.coa : ' ',
            }}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} marginTop={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(applyToTransactions)}
              onChange={(_event, value) => setApplyToTransactions(value)}
              disabled={memorizedRuleApplicableTransactions.size === 0}
            />
          }
          label={`Apply to all past transactions (${memorizedRuleApplicableTransactions.size})`}
        />
        <QButton
          disabled={memorizedRuleApplicableTransactions.size === 0}
          onClick={() => setViewExistingTransactions(true)}
        >
          view transactions
        </QButton>
      </Box>

    </form>
  );
});

MemorizedRuleForm.propTypes = {
  memorizedRule: PropTypes.object,
  onClose: PropTypes.func,
  formikRef: PropTypes.object,
  fromTxn: PropTypes.bool,
  viewExistingTransactions: PropTypes.bool,
  setViewExistingTransactions: PropTypes.func,
};

export default MemorizedRuleForm;
