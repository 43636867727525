
export const styles = ({ palette, components }) => ({
  dialogRoot: {
    minWidth: 650,
    padding: 30,
    paddingTop: 10,
    minHeight: 500,
    // height: 'calc(75% - 96px)', // from MUI, ensures a full height dialog always
  },
  dividerRoot: {
    backgroundColor: palette.cloudyGrey,
  },
  header: {
    padding: '15px 0px 15px 0px',
  },
  title: {
    marginBottom: 10,
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 0 30px 0',
  },
  fieldWrapper: {
    flex: 0,
    minWidth: 120,
    padding: 8,
    background: palette.shinyGrey,
    border: `solid 1px ${components.register.fieldBorderColor}`,
    borderRadius: 3,
    marginRight: 6,
  },
  amountInputStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 22,
  },
  categoryReviewCardRoot: {
    marginTop: 15,
    marginBottom: 20,
  },
  categoryReviewTxList: {
    maxHeight: 250,
  },
  monthlyAverage: {
    background: '#fff0f3',
    padding: 10,
    textAlign: 'center',
  },
});
