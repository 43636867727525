import { makeStyles } from 'tss-react/mui';
import styled from 'styled-components';

export const BankImagesGrid = styled.div`

`;

export const BankImageCell = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
`;


export const useStyles = makeStyles()((theme) => ({
  addNonConnectedAccount: {
    position: 'absolute',
    bottom: 0,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
  },
  bottomType: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: 52,
    borderTop: `solid 1px ${theme.palette.greyScaleDeprecated[5]}`,
    margin: `${theme.spacing(4)} ${theme.spacing(2)} 0`,
  },
  bottomTypeQuicken: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: 52,
    borderTop: `solid 1px ${theme.palette.greyScaleDeprecated[5]}`,
    margin: `${theme.spacing(4)} ${theme.spacing(2)} 0`,
    paddingTop: theme.spacing(2),
  },
  belowSearchText: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  overFlowScroll: {
    overflowY: 'scroll',
  },
  cancelButton: {
    marginRight: 20,
    marginBottom: 10,
  },
  doMoreQuicken: {
    margin: `0 0 ${theme.spacing(3)} ${theme.spacing(3)}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  doMoreQuickenButton: {

  },
  addNonConnectedAccountQuicken: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(),
    color: theme.palette.greyScaleDeprecated[2],
  },
  dropDown: {
    maxHeight: '320px',
    overflowY: 'scroll',
  },
  manualButtonQuicken: {
    textTransform: 'none',
    textDecoration: 'underline',
    fontSize: 14,
    fontWeight: 500,
    '& div': {
      margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
      borderRadius: theme.shape.borderRadius * 3,
    },
  },
  manualButton: {
    marginLeft: theme.spacing(1),
  },
  popularButtonRoot: {
    backgroundColor: theme.palette.grey.level1,
    border: 'none',
    borderRadius: 7,
    '&:hover': {
      border: '1px solid #4D96EF',
      boxShadow: '0 0 4px #4D96EF',
    },
    fontWeight: 400,
    height: '100%',
    lineHeight: 1.25,
    textTransform: 'none',
    width: '100%',
  },
  popularButtonStartIcon:
  {
    marginLeft: 0,
  },
  popularCopy: {
    padding: '12px 0',
  },
  pupularButtonIconOnly: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    borderRadius: 7,
    '&:hover': {
      border: '1px solid #4D96EF',
      boxShadow: '0 0 4px #4D96EF',
    },
  },
  popularGrid: {
    display: 'grid',
    gridTemplateColumns: '166px 166px 166px 166px',
    gridAutoRows: 64,
    gridGap: 16,
    justifyContent: 'center',
  },
  popularGridIconButtonStyle: {
    display: 'grid',
    gridTemplateColumns: '120px 120px 120px 120px 120px',
    gridAutoRows: 64,
    gridGap: 16,
    justifyContent: 'center',
  },
  progress: {
    display: 'block',
    margin: '0 auto',
  },
  root: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    margin: '24px',
    position: 'relative',
    overflow: 'hidden',
  },
  searchBar: {
    borderRadius: 12,
    boxShadow: `1px 2px 5px -2px ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.3)}`,
  },
  statusContainer: {
    minHeight: '200px',
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: -5,
    paddingBottom: 8,
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  cantFindInstitution: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    maxHeight: 50,
    '& button > p': {
      color: theme.palette.link.main,
    },
    '& button': {
      verticalAlign: 'initial',
    },
    '&:hover': {
      background: theme.palette.greyScaleDeprecated[7],
    },
  },
  cantFindDivider: {
    height: 32,
    maxHeight: 32,
  },
  iconBank: {
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    border: '1px solid rgba(151,151,151,0.4)',
  },
  iconFds: {
    height: '64px',
    width: '102px',
  },
  smIconPlaid: {
    height: '48px',
    width: '48px',
  },
  mdIconPlaid: {
    height: '64px',
    width: '64px',
  },
  searchResultItem: {
    padding: '6px 0 6px 0',
  },
}));
