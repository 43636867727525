
// CORE
import React from 'react';
import PropTypes from 'prop-types';

import { scheduledTransactionsActions, scheduledTransactionsTypes, scheduledTransactionsSelectors } from 'companion-app-components/flux/scheduled-transactions';

import { makeStyles } from 'tss-react/mui';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

// Data
import { useSelector, useDispatch } from 'react-redux';
import { removeDialog } from 'data/rootUi/actions';

// Custom
import StdDialog from 'components/Dialogs/StdDialog';
import QButton from 'components/QButton';
import useQData from 'components/QData/useQData';


const useStyles = makeStyles()((_theme) => ({
  content: {
    width: 520,
    padding: 24,
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24,
    paddingLeft: 12,
    backgroundColor: 'unset',
  },
  buttonGroup: {
    display: 'flex',
  },
}));


const DeleteReminderDialog = (props) => {
  const { classes } = useStyles();

  const { txn, wrapperId } = props;
  const dispatch = useDispatch();

  const { qDataPerformTransactionAction, qDataDeleteTransactions } = useQData(wrapperId);
  const model = useSelector((state) => scheduledTransactionsSelectors.getScheduledTransactionById(state, txn.stModelId));

  function onClose() {
    dispatch(removeDialog());
  }

  // data stuff
  const actOnScheduledTxn = (action) => {
    const actionObj = {
      id: txn.id,
      action,
      overdueAction: action,
      instanceDate: txn.stDueOn,
    };
    qDataPerformTransactionAction(actionObj);
  };
  const deleteInstance = () => {
    if (txn.source === 'SCHEDULED_TRANSACTION_PENDING') {
      actOnScheduledTxn('skip');
    } else {
      qDataDeleteTransactions([txn]);
    }
    onClose();
  };

  const deleteModel = () => {
    if (!model) {
      actOnScheduledTxn('skip');
    } else {
      dispatch(scheduledTransactionsActions.deleteScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
        id: txn.stModelId,
        ...model.toJS(),
        isUserVerified: true,
      })));
    }
    onClose();
  };

  return (
    <StdDialog
      open
      aria-labelledby="survey"
      onClose={onClose}
      title="Delete Reminder"
      sharedcomponentid={'DELETE_REMINDER_DIALOG'}
    >
      <DialogContent className={classes.content}>
        <Typography variant="subtitle1">
          {`Are you sure you want to delete the reminder for "${txn.payee}"?`}
        </Typography>
      </DialogContent>

      <div className={classes.bottomRow}>
        <QButton id={'delete-reminder-series'} onClick={deleteModel}>
          Delete Series
        </QButton>

        <div className={classes.buttonGroup}>
          <QButton id={'delete-reminder-cancel'} variant="outlined" onClick={onClose}>
            Cancel
          </QButton>
          <QButton id={'delete-reminder-delete'} variant="contained" width={140} style={{ marginLeft: 16 }} onClick={deleteInstance}>
            Delete
          </QButton>
        </div>
      </div>
    </StdDialog>
  );
};

DeleteReminderDialog.defaultProps = {
  txn: {},
};

DeleteReminderDialog.propTypes = {
  txn: PropTypes.object,
  wrapperId: PropTypes.string,
};

export default DeleteReminderDialog;
