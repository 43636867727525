import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';


import updateVersionMac from 'assets/help/updateVersionMac.png';
import updateVersionWin from 'assets/help/updateVersionWin.png';

export const UpgradeVersion = (props) => {

  const { clientType, sku, minBuildNumber } = props.helpProps;

  const imgScale = '90%';

  const isWin = !(clientType === 'MAC');
  const imgSrc = isWin ? updateVersionWin : updateVersionMac;

  let myMinBuildNumber = minBuildNumber;
  if (isWin) {
    myMinBuildNumber = minBuildNumber.split('.').slice(2).reduceRight((x, val) => `${val}.${x}`, '');
    myMinBuildNumber = `R${myMinBuildNumber.slice(0, myMinBuildNumber.length - 1)}`;
  }
  return (
    <div key="review-help-content">
      <div>
        {isWin && 'Are you on the latest version?'}
        {!isWin && 'Thanks for trying Quicken on the Web!'}
      </div>
      <Typography variant="body2" style={{ marginTop: 15 }}>
        {'Before using Quicken for the Web, we suggest you update to the latest desktop version: '}
        <b>{sku} {myMinBuildNumber} or higher.</b>
      </Typography>
      <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
        <div
          style={{ flex: 2 }}
        >
          <Typography
            style={{ padding: 10, fontSize: '14px' }}
            variant="body2"
          >
            {'Update to the latest version of Quicken desktop to get the best web experience.'}
          </Typography>
          <Typography
            style={{ padding: 10, fontSize: '14px' }}
            variant="body2"
          >
            {'To get the latest desktop version select '}
            <br />
            <b>
              {`${!isWin ? 'Quicken' : 'Help'} -> Check for Updates`}
            </b>
          </Typography>
          <Typography
            style={{ padding: 10, fontSize: '14px' }}
            variant="body2"
          >
            <b> {'If you do not update desktop first,'} </b>
            {' your synced data file could be negatively impacted when you next sync on desktop.'}
          </Typography>
        </div>
        <div
          style={{ textAlign: 'center', flex: 1 }}
        >
          <img
            alt="upgrade version"
            src={imgSrc}
            style={{ flex: 1, marginLeft: 10, width: imgScale, height: imgScale }}
          />
        </div>
      </div>
    </div>
  );
};

UpgradeVersion.propTypes = {
  helpProps: PropTypes.object,
};
