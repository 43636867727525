import React, { useCallback } from 'react';
import { Set } from 'immutable';
import { useLocation } from 'react-router-dom';

import { transactionsTypes } from 'companion-app-components/flux/transactions';

import useQPreferences from 'components/QPreferences/useQPreferences';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog/Dialog';
import MonthlyCalendar from './MonthlyCalendar';
import { transactionCalendarStyles } from './styles';

const useStyles = makeStyles()(transactionCalendarStyles as Record<string, any>);

interface TransactionCalendarProps {
  onClose: () => void;
  navFn: (txn: transactionsTypes.CashFlowTransaction) => void;
  inDialog: boolean;
  year: string;
  month: string;
  setDate: (year: string, month: string) => void;
  accountIds: Set<Record<string, string>>;
}

const TransactionCalendar: React.FC<TransactionCalendarProps> = (props) => {
  const { classes } = useStyles();
  const { inDialog, year, month, setDate, navFn, onClose } = props;

  const { datasetPreferences } = useQPreferences();
  const { accountsToShow } = datasetPreferences.calendarTransaction;

  const location = useLocation();
  const accountIds =
    location.search?.includes('displayNode=all') ?
      (accountsToShow || Set<Record<string, string>>()) :
      props.accountIds ?? Set<Record<string, string>>();

  const setCalendarDate = useCallback((yearValue, monthValue) => {
    if (setDate) {
      setDate(yearValue, monthValue);
    }
  }, [setDate]);

  const closeAndExit = useCallback(() => onClose && onClose(), [onClose]);

  const cachedNavFn = useCallback(
    (txn: transactionsTypes.CashFlowTransaction) => { navFn(txn); closeAndExit(); }, [navFn, closeAndExit],
  );

  const MonthCalendar = (
    <MonthlyCalendar
      onClose={closeAndExit}
      accountIds={accountIds}
      navFn={cachedNavFn}
      month={month}
      year={year}
      setDate={setCalendarDate}
      showCloseButton={inDialog}
    />
  );

  if (inDialog) {
    return (
      <Dialog
        classes={{ paper: classes.container }}
        open
        maxWidth="xl"
      >    
        {MonthCalendar}
      </Dialog>
    );
  }

  return (
    <div className={classes.fullScreen}>
      {MonthCalendar}
    </div>
  );
};

export default TransactionCalendar;
