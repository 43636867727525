export const styles = ({ palette }) => ({
  button: {
    width: 50,
    height: 50,
    textAlign: 'center',
  },
  hide: {
    display: 'none',
  },
  show: {
    display: 'block',
  },
  loadingBlock: { 
    margin: 'auto', 
    width: 'max-content',
  },
  mainImage: {
    margin: 'auto',
    textAlign: 'center',
  },
  mainImageText: {
    margin: 'auto',
    textAlign: 'center',
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  loadingCircle: {
    display: 'block',
    margin: 'auto',
    position: 'relative',
    color: palette.cloudyGrey,
  },
  loadingCircle2: {
    position: 'relative',
    color: palette.cloudyGrey,
  },
  cloudIcon: {
    width: 44,
    height: 44,
    color: palette.slateGrey,
  },
});
