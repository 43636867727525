
import React, { FC, useState, useRef } from 'react';
import { List } from 'immutable';
import { useUnmount } from 'react-use';
import { makeStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';

import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { transactionListSelectors } from 'companion-app-components/flux/transaction-list';

import QButton from 'components/QButton';
import SearchBox from 'components/SearchBox';
import StdDialog from 'components/Dialogs/StdDialog';
import TransactionSelectList from 'components/TransactionSelectList';

import { styles } from './styles';

const useStyle = makeStyles()(styles as Record<string, any>);

type TxnsType = null | transactionsTypes.CashFlowTransaction | List<transactionsTypes.CashFlowTransaction>;

interface SelectTransactionDialogProps {
  open: boolean;
  onClose: () => void;
  onTransactionSelected: (txns?: TxnsType) => void;
  transactionListSelector: () => void;
  selectorProps: Record<string, any>;
  title: string;
  subtitle: string;
  buttonText: string;
  disableAdd: boolean;
  filterByType: () => void;
  multiSelect: boolean;
}

const SelectTransactionDialog: FC<SelectTransactionDialogProps> = ({ onClose, selectorProps, title, buttonText, disableAdd, subtitle, onTransactionSelected, open,
  transactionListSelector, filterByType, multiSelect, ...otherProps }) => {

  const [selectedTxn, setSelectedTxn] = useState<TxnsType>(multiSelect ? List() : null);
  const [processing, setProcessing] = useState(false);
  const [search, setSearch] = useState(null);

  const autoTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { classes } = useStyle();

  useUnmount(() => {
    if (autoTimeout.current) {
      clearTimeout(autoTimeout.current);
    }
  });

  // This is the function by which a transaction selected from the virtualList in ScheduleList component is stored in
  // this component's state. Couldn't think of a cleaner way to back-pass updates to state. The nested ID checks are
  // used to grab parent ID values, as each transaction row has many child components, but the ID we need is in the
  // parent row.
  const selectTxnFunc = (event) => {
    const selectTxn = JSON.parse(event.currentTarget.id);
    let currentTxn = selectedTxn;
    if (multiSelect) {
      // @ts-expect-error: Type 'never[]' is missing the following properties from type 'CashFlowTransaction'
      currentTxn = currentTxn || [];
      if (currentTxn?.size > 0 && currentTxn?.find((t) => t.id === selectTxn.id)) {
        setSelectedTxn(currentTxn.filter((t) => t.id !== selectTxn.id));
      } else {
        // @ts-expect-error: Type 'CashFlowTransaction | List<CashFlowTransaction> | null' must have a '[Symbol.iterator]()' method that returns an iterator.
        setSelectedTxn(List([...currentTxn, selectTxn]));
      }
      return;
    }
    // @ts-expect-error: Property 'id' does not exist on type 'List<CashFlowTransaction>
    if (currentTxn && (currentTxn.id === selectTxn.id)) {
      setSelectedTxn(null);
    } else {
      setSelectedTxn(selectTxn);
    }
  };

  const handleComplete = () => {
    if (onTransactionSelected) {
      onTransactionSelected(selectedTxn);
    }
    setProcessing(true);
    autoTimeout.current = setTimeout(() => setProcessing(false), 5000);
  };

  const onCloseFunc = () => {
    onClose?.();
    setSearch(null);
    setSelectedTxn(null);
    setProcessing(false);
  };

  const derivedSelector = transactionListSelector || transactionListSelectors.getLesserBilledTransactions;

  return (
    <StdDialog
      title={title || 'Select Transaction'}
      open={Boolean(open)}
      fullWidth
      maxWidth="md"
      className={classes.dialogRoot}
      onClose={onCloseFunc}
      sharedcomponentid={'SELECT_TRANSACTION_DIALOG'}
      {...otherProps}
    >
      <div className={classes.headerSection}>
        <Typography className={classes.subtitle}>
          {subtitle || 'Select or search for a transaction'}
        </Typography>

        <SearchBox
          onSearch={(searchParam) => {
            setSearch(searchParam);
          }}
          autoSearch
          autoFocus
          collapsable={false}
          initialValue={search}
          placeholder="Search Transactions"
          classes={{
            root: classes.searchBar,
            input: classes.searchInput,
          }}
        />
      </div>
      <DialogContent className={classes.greyGrid}>
        <TransactionSelectList
          clickFunc={selectTxnFunc}
          // @ts-expect-error:  Property 'id' does not exist on type 'List<CashFlowTransaction>'.
          highlight={selectedTxn?.map?.((t) => t.id) || selectedTxn?.id}
          filter={search}
          onReset={() => setSearch(null)}
          transactionListSelector={derivedSelector}
          selectorProps={selectorProps}
          filterByType={filterByType}
          multiSelect={multiSelect}
        />

        {processing &&
          <div className={classes.processing}>
            <CircularProgress size={64} className={classes.spinner} />
          </div>}
      </DialogContent>

      <div className={classes.buttonBottoms}>
        <QButton
          type="submit"
          id="create-transaction"
          variant="contained"
          disabled={!selectedTxn || processing}
          onClick={handleComplete}
          style={{ marginRight: 10 }}
        >
          { buttonText || 'CREATE' }
        </QButton>

        {!disableAdd &&
          <Typography variant="body2" className={classes.manualLine}>
            {"Couldn't Find?"}
            <Button
              className={classes.manualButton}
              id="add-manually-transaction"
              onClick={() => onTransactionSelected && onTransactionSelected()}
            >
              ADD MANUALLY
            </Button>
          </Typography>}
      </div>
    </StdDialog>
  );
};

export default SelectTransactionDialog;
