/* eslint react/prop-types: 0 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTheme } from '@emotion/react';
import Downshift from 'downshift';
import HighlightText from 'components/HighlightText';
import compose from 'utils/compose';

import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowDropUpIcon from '@mui/icons-material/ExpandLessRounded';
import ArrowDropDownIcon from '@mui/icons-material/ExpandMoreRounded';
import CircularProgress from '@mui/material/CircularProgress';

const styles = () => ({
  fullWidth: {
    width: '100%',
  },
});


class DownshiftField extends PureComponent {

  constructor(props) {
    super(props);

    this.state = { ignoreFilter: false };
  }

  keyDown = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
    }
  };

  render() {
    const { classes, textFieldProps } = this.props;
    return (
      <>
        {this.props.dataLoading ?
          <TextField
            {...textFieldProps}
            value={this.props.initialInputValue}
            sharedcomponentid={'DOWNSHIFT_FIELD'}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment variant="filled" position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ),
            }}
          />
          :
          <Downshift
            defaultHighlightedIndex={0}
            initialHighlightedIndex={this.props.items.indexOf(this.props.initialItemSelected)}
            initialInputValue={this.props.initialInputValue || (this.props.initialItemSelected ? this.props.itemToString(this.props.initialItemSelected) : '')}
            initialSelectedItem={this.props.initialItemSelected}
            onChange={(item) => this.props.onSelected && this.props.onSelected(item)}
            onInputValueChange={(inputValue) => {
              this.state.ignoreFilter && this.setState({ ignoreFilter: false });
              this.props.onInputValueChange && this.props.onInputValueChange(inputValue);
            }}
            itemToString={this.props.itemToString}
            stateReducer={(state, changes) => {
              let changesApproved = changes;
              switch (changes.type) {
                case Downshift.stateChangeTypes.blurInput:
                case Downshift.stateChangeTypes.mouseUp:
                  if (this.props.allowNonListedItems) {
                    changesApproved = {
                      ...changes,
                      inputValue: state.inputValue,
                    };
                  } else {
                    changesApproved = {
                      ...changes,
                      ...(changes.inputValue === null ? { inputValue: '' } : undefined),
                    };
                  }
                  break;
                default:
                  changesApproved = changes;
              }
              return changesApproved;
            }}
            id={textFieldProps?.id}
          >
            {({ getInputProps, getItemProps, getMenuProps, isOpen, inputValue,
              highlightedIndex, toggleMenu, selectItemAtIndex, getRootProps }) => {
              const inputValueLowerCase = inputValue && inputValue?.toLowerCase();
              const filteredItems = this.state.ignoreFilter ?
                this.props.items :
                this.props.items && this.props.items.filter((item) => !inputValue || this.props.itemToString(item)?.toLowerCase().includes(inputValueLowerCase));
              const { value, ...inputProps } = textFieldProps?.InputProps || {};
              const isOpenValidate = inputProps?.disabled ? false : isOpen;
              return (
                <div
                  className={classNames(textFieldProps?.fullWidth ? classes.fullWidth : undefined, this.props.className)}
                  {...getRootProps({
                    role: 'presentation',
                    onKeyDown: isOpenValidate ? this.keyDown : undefined,
                  }, { suppressRefError: true })}
                >
                  <TextField
                    {...textFieldProps}
                    inputRef={(node) => { this.anchorEl = node; }}
                    InputProps={{
                      ...getInputProps({
                        endAdornment: (
                          <InputAdornment variant="filled" position="end">
                            <IconButton
                              style={{ padding: 0 }}
                              aria-label="toggle options"
                              // make not focusable
                              tabIndex={-1}
                              // make not focusable
                              onFocus={(_event) => this.anchorEl?.focus()}
                              size="large"
                            >
                              {isOpenValidate ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        ...inputProps,
                        onKeyDown: (event) => {
                          if (isOpenValidate) {
                            if (event.key === 'Tab' || event.key === 'Enter') {
                              if (!highlightedIndex && this.props.initialItemSelected !== inputValue) {
                                selectItemAtIndex(0);
                                event.stopPropagation();
                              } else if (highlightedIndex) {
                                selectItemAtIndex(highlightedIndex);
                                event.stopPropagation();
                              }
                            }
                          }
                        },
                        onClick: () => {
                          this.setState({ ignoreFilter: true });
                          toggleMenu();
                        },
                      }),
                    }}
                  />

                  {isOpenValidate && filteredItems && filteredItems.length > 0 &&
                    <Popper
                      open
                      anchorEl={this.anchorEl}
                      placement="bottom-start"
                      style={{ zIndex: this.props.theme.zIndex.modal }}
                    >
                      <div {...getMenuProps({}, { suppressRefError: true })}>
                        <Paper>
                          <MenuList
                            style={{
                              maxHeight: 320,
                              overflowY: 'auto',
                            }}
                          >
                            {filteredItems.map((item, index) => {
                              const itemString = this.props.itemToString(item);
                              return (
                                <MenuItem
                                  {...getItemProps({
                                    index,
                                    key: this.props.itemKey(item),
                                    // sorry we can't override 'id' - downshift uses it for internal purpose
                                    item,
                                    selected: index === highlightedIndex,
                                  })}
                                >
                                  <HighlightText
                                    id={(this.props.indexId && `item_${index}`) || this.props.itemKey(item)}
                                    text={itemString}
                                    searchKeys={[inputValue]}
                                  />
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </Paper>
                      </div>
                    </Popper>}
                </div>
              );
            }}
          </Downshift>}
      </>
    );
  }

}

DownshiftField.defaultProps = {
  itemToString: (item) => item,
  itemKey: (item) => item,
  indexId: false,
};

DownshiftField.propTypes = {
  items: PropTypes.array,
  initialItemSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  initialInputValue: PropTypes.string,
  onSelected: PropTypes.func,
  onInputValueChange: PropTypes.func,
  itemToString: PropTypes.func,
  itemKey: PropTypes.func,
  dataLoading: PropTypes.bool,
  allowNonListedItems: PropTypes.bool,
  indexId: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default compose(
  withTheme,
)(withStyles(DownshiftField, styles, { name: { DownshiftField } }));

