
import React, { FC, memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { usePrevious } from 'react-use';

import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import QButton from 'components/QButton';
import type { Profile } from 'companion-app-components/flux/profile/profileTypes';
import { getLogger } from 'companion-app-components/utils/core';

import winGraphics from 'assets/setup-page/Win.png';
import macGraphics from 'assets/setup-page/Mac.png';
import macIcon from 'assets/setup-page/Mac Icon.png';
import winIcon from 'assets/setup-page/Win Icon.png';
import QTip from 'components/QuickenControls/QTip';
import { isMac, safeRefIn } from 'utils/utils';
import styles from './styles';

const WIDTH_DEFINED = 891;

const log = getLogger('containers/SetupPageWebFirst');

const useStyles = makeStyles()(styles as Record<string, any>);

interface SetupPageWebFirstProps {
  profile?: Profile,
  authSession: Record<string, any>,
  defaultTabIsWindows: boolean,
  onCreateDataset: (platform: string) => void,
}

const SetupPageWebFirst: FC<SetupPageWebFirstProps> = ({
  profile,
  authSession,
  defaultTabIsWindows,
  onCreateDataset,
}) => {
  const { classes } = useStyles();
  
  const [isWindows, setIsWondows] = useState(
    defaultTabIsWindows !== undefined ? defaultTabIsWindows : !isMac(),
  );
  const prevDefaultTabIsWindows = usePrevious(defaultTabIsWindows);

  const userEmail = safeRefIn(profile, ['primaryEmail', 'address']) || '';
  const macDownload = `https://download.quicken.com/mac/Quicken.dmg?email=${userEmail}`;
  const winDownload = `https://download.quicken.com/windows/Quicken.exe?email=${userEmail}`;
  const downloadLink = isWindows ? winDownload : macDownload;
  const stepsImg = isWindows ? winGraphics : macGraphics;
  const suffix = isWindows ? 'for Windows' : 'Mac';

  const selectTab = (argIsWindows: boolean) => {
    setIsWondows(argIsWindows);
  };

  useEffect(() => {
    if (prevDefaultTabIsWindows !== defaultTabIsWindows) {
      setIsWondows(defaultTabIsWindows !== undefined ? defaultTabIsWindows : !isMac());
    }
  }, [prevDefaultTabIsWindows, defaultTabIsWindows]);

  return (
    <Box display="flex" height="100%">
      <Box m="auto" maxWidth={WIDTH_DEFINED}>
        <Typography className={classes.title}>
          Hello.
        </Typography>
        <Typography className={classes.subTitle}>
          Welcome to Quicken!
        </Typography>
        <Typography className={classes.bodyText}>
          {'You can start on the web, and stay on the web (and mobile), but if you want to explore the full features on Quicken Desktop '}
          {'you will eventually want to sync your data into the desktop app.  We need to know which version you plan on using'}
        </Typography>
        <Box width={WIDTH_DEFINED} ml="auto" mr="auto">
          <div className={classes.choiceTabs}>
            <ButtonBase
              className={classNames(classes.tab, isWindows ? 'selected' : 'unselected')}
              onClick={() => selectTab(true)}
            >
              <div>
                <img alt="Windows Icon" src={winIcon} className={classes.imgIcon} />
                <Typography display="inline">
                  WINDOWS
                </Typography>
              </div>
            </ButtonBase>
            <ButtonBase
              className={classNames(classes.tab, isWindows ? 'unselected' : 'selected')}
              onClick={() => selectTab(false)}
            >
              <div>
                <img alt="Mac icon" src={macIcon} className={classes.imgIcon} />
                <Typography display="inline">
                  MAC
                </Typography>
              </div>
            </ButtonBase>
          </div>
          <Box id="content" ml={15} width="auto">
            <Box mt={15}>
              <img alt="graphical steps" src={stepsImg} />
            </Box>
            <div className={classes.footer}>
              <Divider sx={{ mb: 10 }} />
              <div className={classes.bodyText}>
                <Link
                  className={classes.link}
                  onClick={() => window.open('https://www.quicken.com/support/quicken-web-frequently-asked-questions', '_blank')}
                >
                  See this FAQ,
                </Link>
                {' on how to set your accounts up for Sync'}
                <div className={classes.vertBar} />
                <Link
                  className={classes.link}
                  onClick={() => window.open(`https://www.quicken.com/my-account?bearer=${authSession.accessToken}`, '_blank')}
                >
                  Go to My Account
                </Link>
                <div className={classes.vertBar} />
                <Link
                  className={classes.link}
                  onClick={() => {
                    log.log(`Opening: ${downloadLink}`);
                    window.open(downloadLink, '_blank');
                  }}
                >
                  {`Download Quicken for ${isWindows ? 'Windows' : 'Mac'}`}
                </Link>
              </div>
              <div className={classes.startClean}>
                <QTip
                  title={`Creates a new cloud file that you can use on web and mobile, and then load into a new Quicken ${suffix} file when you want to use the desktop`}
                >
                  <QButton
                    onClick={() => onCreateDataset(isWindows ? 'quicken_windows' : 'quicken_mac')}
                  >
                    {`Create New Quicken ${suffix} Dataset`}
                  </QButton>
                </QTip>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(SetupPageWebFirst);
