import { createSelector } from 'reselect';

import {
  getIsRefreshingAll as getAllLoginsAreRefreshing,
  getIsRefreshingSome as getSomeLoginsAreRefreshing,
} from 'data/institutionLogins/selectors';

import { REDUCER_KEY } from './reducer';
import type { AppStore } from './types';

export const getAppStore = (state : any): AppStore => state[REDUCER_KEY];

export const getIsOffline = (state: any) => getAppStore(state).isOffline;

export const getServiceWorkerIsRegistered = (state: any) => getAppStore(state).serviceWorkerIsRegistered;
export const getServiceWorkerIsRegistering = (state: any) => getAppStore(state).serviceWorkerIsRegistering;
export const getServiceWorkerUpdateIsAvailable = (state: any) => getAppStore(state).serviceWorkerUpdateIsAvailable;

export const getAwaitingToAutoRefresh = (state: any) => getAppStore(state).awaitingToAutoRefresh;

export const getUpdateAllFailed = (state: any) => getAppStore(state).updateAllFailed;
export const getUpdateAllMessages = (state: any) => getAppStore(state).updateAllMessages;
export const getDBUpdateTrigger = (state: any) => getAppStore(state).dbUpdateTrigger;
export const getFetchInProgress = (state: any) => getAppStore(state).fetchInProgress;
export const getSessionStartedAt = (state: any) => getAppStore(state).sessionStartedAt;

export const disableRefreshButton = createSelector(
  getAwaitingToAutoRefresh,
  getSomeLoginsAreRefreshing,
  getFetchInProgress,
  (awaitingToAutoRefresh, someLoginsAreRefreshing, fetchInProgress) => awaitingToAutoRefresh || someLoginsAreRefreshing || fetchInProgress,
);

// todo: add other loading/refreshing conditions?
export const isRefreshing = createSelector(
  getAllLoginsAreRefreshing,
  getFetchInProgress,
  (allLoginsAreRefreshing, fetchInProgress) => allLoginsAreRefreshing || fetchInProgress,
);

// makeSelectLocationState expects a plain JS object for the routing state
const makeSelectLocationState = () => {
  let prevRoutingState;
  let prevRoutingStateJS;

  return (state) => {
    const routingState = state.get('route'); // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState;
      prevRoutingStateJS = routingState.toJS();
    }

    return prevRoutingStateJS;
  };
};

export const isSanitized = (state: any) => getAppStore(state).isSanitized;

export const csvImportCompleteByClientId = (state: any) => getAppStore(state).csvImportCompleteByClientId;
export const csvImportCompleteForClientId = (state: any, clientId: string) => (csvImportCompleteByClientId(state) || {}).get(clientId);

export const csvImportErrorByClientId = (state: any) => getAppStore(state).csvImportErrorByClientId;
export const csvImportErrorForClientId = (state: any, clientId: string) => (csvImportErrorByClientId(state) || {}).get(clientId);

export const csvImportFilesInProgress = createSelector(
  csvImportErrorByClientId,
  csvImportCompleteByClientId,
  (state, csvImportFilesByClientId) => csvImportFilesByClientId,
  (csvImportError, csvImportComplete, csvImportFiles) =>
    csvImportFiles.filter((_csvImportFile, clientId) => !csvImportComplete.get(clientId) && !csvImportError.get(clientId)),
);

export const csvImportFilesSucceed = createSelector(
  csvImportCompleteByClientId,
  (state, csvImportFilesByClientId) => csvImportFilesByClientId,
  (csvImportComplete, csvImportFiles) =>
    csvImportComplete.filter((_csvImportFile, clientId) => csvImportFiles.get(clientId)),
);

export const csvImportFilesFailed = createSelector(
  csvImportErrorByClientId,
  csvImportCompleteByClientId,
  (state, csvImportFilesByClientId) => csvImportFilesByClientId,
  (csvImportErrors, csvImportComplete, csvImportFiles) =>
    csvImportErrors.filter((_csvImportFile, clientId) => csvImportFiles.get(clientId) && !csvImportComplete.get(clientId)),
);

export {
  makeSelectLocationState,
};
