// @flow
import { createAction } from 'utils/actionHelpers';

export const getInstitutionLogins = createAction('GET_INSTITUTION_LOGINS');
export const getInstitutionLoginsSuccess = createAction('GET_INSTITUTION_LOGINS_SUCCESS');
export const getInstitutionLoginsFailure = createAction('GET_INSTITUTION_LOGINS_FAILURE');

export const updateInstitutionLogin = createAction('UPDATE_INSTITUTION_LOGIN');
export const updateInstitutionLoginSuccess = createAction('UPDATE_INSTITUTION_LOGIN_SUCCESS');
export const updateInstitutionLoginFailure = createAction('UPDATE_INSTITUTION_LOGIN_FAILURE');

export const deleteInstitutionLogin = createAction('DELETE_INSTITUTION_LOGIN');
export const deleteInstitutionLoginSuccess = createAction('DELETE_INSTITUTION_LOGIN_SUCCESS');
export const deleteInstitutionLoginFailure = createAction('DELETE_INSTITUTION_LOGIN_FAILURE');

export const addInstitutionLoginAndAccounts = createAction('ADD_INSTITUTION_LOGIN_AND_ACCOUNTS');
export const addInstitutionLoginAndAccountsResponse = createAction('ADD_INSTITUTION_LOGIN_AND_ACCOUNTS_RESPONSE');

export const refreshAccountsTriggeredCredentialsRequest = createAction('REFRESH_ACCOUNTS_TRIGGERED_CREDENTIALS_REQUEST');

export const refreshAccountsCredentialsProvided = createAction('REFRESH_ACCOUNTS_CREDENTIALS_PROVIDED');
export const refreshAccountsCredentialsNotProvided = createAction('REFRESH_ACCOUNTS_CREDENTIALS_NOT_PROVIDED');

export const institutionLoginSetCredentialsBlob = createAction('INSTITUTION_LOGIN_SET_CREDENTIALS_BLOB');

export const qcsInstitutionLoginAction = createAction('QCS_INSTITUTION_LOGIN_ACTION');
export const qcsInstitutionLoginActionResponse = createAction('QCS_INSTITUTION_LOGIN_ACTION_RESPONSE');

export const updateChangedInstitutionLogins = createAction('UPDATE_IMPACTED_INSTITUTION_LOGINS');

export const refreshAccounts = createAction('REFRESH_ACCOUNTS');
export const refreshAccountsPrecheck = createAction('REFRESH_ACCOUNTS_PRECHECK');
export const refreshAccountsStatuses = createAction('REFRESH_ACCOUNTS_STATUSES');
export const refreshAccountsCompleted = createAction('REFRESH_ACCOUNTS_COMPLETED');

export const refreshAccountsTriggeredMfa = createAction('REFRESH_ACCOUNTS_TRIGGERED_MFA');

export const refreshAccountsMfaFormSubmit = createAction('REFRESH_ACCOUNTS_MFA_FORM_SUBMIT');
export const refreshAccountsMfaFormSubmitResponse = createAction('REFRESH_ACCOUNTS_MFA_FORM_SUBMIT_RESPONSE');
export const refreshAccountsMfaNotProvided = createAction('REFRESH_ACCOUNTS_MFA_NOT_PROVIDED');
