import { isAcme } from 'isAcme';


const PANEL_WIDTH = 270;
const CENTER_PANEL_WIDTH = 280;
export const styles = ({ palette, shape, components }) => ({

  content: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `solid 1px ${palette.divider}`,
    width: 'min-content',
    height: 420,
  },
  root: {
    boxShadow: `0px 0px 18px ${palette.greyScaleDeprecated[3]}`,
    border: 'solid 1px',
    borderColor: palette.divider,
    background: isAcme ? palette.background.paper : palette.common.white,
    borderRadius: shape.borderRadius * 2,
    overflow: 'hidden',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 24,
    height: 60,
  },
  panelLeft: {
    flex: 0,
    minWidth: PANEL_WIDTH,
    background: isAcme ? palette.background.paper : palette.common.white,
    zIndex: 2,
    padding: '12px 16px',
  },
  panelCenter: {
    minWidth: PANEL_WIDTH,
    borderLeft: 'solid',
    borderLeftWidth: 1,
    borderLeftColor: palette.greyScaleDeprecated[4],
    overflowY: 'auto',

    '&.open': {
      minWidth: CENTER_PANEL_WIDTH,
    },
  },
  panelRight: {
    padding: 8,
    whiteSpace: 'nowrap',
    borderLeft: `1px solid ${palette.divider}`,
    overflowY: 'auto',
    width: 180,
    display: 'flex',
    flexDirection: 'column',
  },
  panelRightHeader: {
    textAlign: 'center',
    padding: 8,
    borderBottom: `1px solid ${palette.divider}`,
    width: '100%',
  },
  fliHeader: {
    margin: '12px 0px 6px',
    fontSize: 16,
  },
  fliLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fliRemove: {
    background: isAcme ? palette.removeButton : palette.greyScaleDeprecated[2],
    color: palette.text.lightContrast,
    padding: 1,
    fontSize: 12,
    '&:hover': {
      background: palette.greyScaleDeprecated[5],
      color: palette.greyScaleDeprecated[2],
    },
  },
  fliItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  slider: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  slideAction: {
    // animation: 'pulse-slider 4s ease-in',
    // animationDelay: '2s',
  },
  openPop: {
    minWidth: PANEL_WIDTH + CENTER_PANEL_WIDTH,
  },
  iconRoot: {
    minWidth: 27,
  },
  iconContainer: {
    color: palette.text.primary,
  },
  pop: {
    borderRadius: shape.borderRadius * 2,
  },
  header: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '4px 16px 4px 16px',
  },
  title: {
    flex: 1,
  },
  actionText: {
    color: palette.secondary.main,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1.25,
    textTransform: 'uppercase',
  },
  buttonArea: {
    textAlign: 'center',
    padding: 'unset',
  },
  button: {
    width: 100,
  },
  itemMenu: {
    '&.selected': {
      background: components.txns.itemContainer,
    },
  },
  numSelected: {
    background: palette.greyScaleDeprecated[6],
    padding: '2px 4px 2px 4px',
    borderRadius: 5,
    fontWeight: 'normal',
    color: palette.text.secondary,
    '&.selected': {
      background: palette.greyScaleDeprecated[4],
    },
  },
  advancedContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    padding: 10,
    minWidth: CENTER_PANEL_WIDTH,
  },
  advancedHeader: {
    margin: '10px 0',
  },
  advancedSubtitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  advancedClearButton: {
    color: palette.link.main,
    letterSpacing: 1.25,
    fontWeight: 500,
  },
  filterOutContainer: {
    textAlign: 'center',
    marginTop: 25,
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },

  '@keyframes pulse-slider': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },

});
