import { isAcme } from 'isAcme';

export const styles = (theme) => ({
  dialogPaper: {
    width: '75%',
    minWidth: 800,
  },
  panelContainer: {
    paddingLeft: 2,
    paddingRight: 2,
    '&.disable': {
      pointerEvents: 'none',
      opacity: '0.6',
    },
  },
  panel: {
    borderLeft: `3px solid ${theme.components.register.fieldBorderColor}`,
    border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
    padding: 24,
    background: isAcme ? theme.palette.background.paper : theme.palette.greyScaleDeprecated[7],
    marginLeft: 0,
    '&.inDialog': {
      borderLeft: 'none',
    },
  },
  ellipsePlaceholder: {
    textOverflow: 'ellipse',
  },
  textField: {
    background: 'transparent',
    flex: 1,
    color: 'black',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingLeft: 2,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 4,
    '&.amountField': {
      maxWidth: theme.spacing(11),
    },
    '&.flex2': {
      flex: 2,
    },
    '&.flex3': {
      flex: 3,
    },
    '& input': {
      paddingLeft: 0,
    },
    '&:focus-within': {
      border: `solid 1px ${theme.components.register.fieldBorderColor}`,
      background: theme.palette.greyScaleDeprecated[7],
      '&:hover': {
        background: theme.palette.greyScaleDeprecated[7],
      },
    },
    '&:hover': {
      background: theme.components.register.fieldHoverColor,
    },
  },
  inputStyle: {
    width: '100%',
    '& input': {
      fontSize: theme.components.register.fontSize.default,
      lineHeight: theme.components.register.fontSize.default,
      paddingBottom: '4px',
    },
  },
  splitsContainer: {
    width: '100%',
    border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
    borderRadius: 4,
    marginLeft: 0,
    marginRight: 0,
    background: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
    '&.sidebyside': {
      marginBottom: !isAcme && 24,
      float: 'right',
      display: 'inline-block',
    },
  },
  bankPayeeLink: {
    display: 'inline',
  },
  bankInfoText: {
    fontSize: 12,
    color: theme.palette.greyScaleDeprecated[3],
  },
  bankInfoContainer: {
    padding: 10,
  },
  statementName: {
    color: theme.palette.primary.main,
  },
  rightCol: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  splitsOnly: {
    width: 'inherit',
  },
  splitsDetailsParent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  webFirstDetails: {
    width: '40%',
  },
  detailsContainer: {
    // marginTop: isAcme ? 10 : 34,
    width: '40%',
    marginLeft: 0,
    marginRight: 0,
    background: isAcme ? theme.palette.background.paper : theme.palette.greyScaleDeprecated[7],
  },
  link: {
    marginLeft: 5,
    verticalAlign: 'inherit',
    cursor: 'pointer',
    color: theme.palette.linkColor,
    '&:active': {
      background: theme.palette.greyScaleDeprecated[5],
    },
    '&:hover': {
      fontWeight: 'bold',
    },
    width: 'auto',
    textAlign: 'left',
    justifyContent: 'left',
  },
  splitField: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 7,
    paddingRight: 0,
    background: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
    '&.flex2': {
      flex: 2,
    },
    '&.flex3': {
      flex: 3,
    },
    '&.pointer': {
      cursor: 'pointer',
    },
  },
  splitTitleIcon: {
    flex: '0',
    color: theme.palette.greyScaleDeprecated[2],
    margin: 'auto',
    backgroundColor: 'transparent',
  },
  splitTitle: {
    // flex: '1',
    fontSize: theme.components.register.fontSize.large,
  },
  switch: {
    marginLeft: 10,
  },

  rowIcons: {
    width: '72px',
    color: theme.palette.greyScaleDeprecated[3],
    background: 'none',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  pointer: {
    cursor: 'pointer',
  },
  listRow: {
    display: 'flex',
    alignItems: 'stretch',
    background: theme.components.register.splitsBackground,
    padding: '5px 10px',
    minHeight: theme.components.register.splitRowHeight,
  },
  addIconButton: {
    color: theme.palette.secondary.main,
  },
  headerRow: {
    display: 'flex',
    alignItems: 'stretch',
    backgroundColor: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
    padding: '6px 0px 6px 5px',
  },
  detailsRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    float: 'right',
  },
  footerRow: {
    display: 'flex',
    alignItems: 'stretch',
    marginBottom: '7px',
    background: theme.palette.greyScaleDeprecated[7],
    marginTop: 6,
  },
  buttons: {},
  buttonHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    borderTop: `solid 1px ${theme.components.register.headerBorder}`,
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  buttonsLeft: {
    width: 'fit-content',
    textAlign: 'left',
    borderRight: `1px solid ${isAcme ? 'unset' : theme.palette.greyScaleDeprecated[5]}`,
    borderLeft: `1px solid ${isAcme ? 'unset' : theme.palette.greyScaleDeprecated[5]}`,
    marginTop: '10px',
    paddingRight: isAcme ? 0 : 10,
    background: isAcme ? 'unset' : theme.palette.greyScaleDeprecated[7],
  },
  buttonsRight: {
    textAlign: 'right',
    marginTop: '10px',
    marginLeft: 10,
    background: isAcme ? 'unset' : theme.palette.greyScaleDeprecated[7],
  },
  transactionTotalLabel: {
    fontSize: '14px',
    borderBottom: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 2,
    paddingTop: 12,
  },
  transactionTotal: {
    fontSize: '14px',
    fontWeight: 500,
    borderBottom: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 2,
    paddingTop: 12,
  },
  switchFill: {
    width: 57,
  },
});


