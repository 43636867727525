// @flow
import { handleActions } from 'redux-actions';
import { mkNotificationsStore } from './notificationsTypes';
import * as actions from './notificationsActions';

const notificationsReducer = handleActions({
  
  [actions.addNotifications]:
    (state, { payload: notifications }) => state.mergeDeep({ notifications }),
  
  [actions.removeNotifications]:
    (state, { payload: notificationKeys }) => state.merge({ notifications: state.notifications.deleteAll(notificationKeys) }),
  
}, mkNotificationsStore());

export const NOTIFICATIONS_REDUCER_KEY = 'notificationsStore';
export default notificationsReducer;
