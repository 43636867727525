import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 60px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Section = styled.div`
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const SectionHeader = styled.div`
  font-size: 36px;
  font-weight: 400;
  width: 100%;
  text-align: left;
  border-bottom: 2px solid ${({ theme }) => theme.palette.border.primary};
  margin-top: 40px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const SectionSubheader = styled.div`
  font-size: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyScaleDeprecated[4]};
  margin-bottom: 10px;
  margin-top: 20px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const SectionContent = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  padding: 0px 10px;
  margin: 10px;
  letter-spacing: 0.01em;
  text-align: left;
  a {
    color: ${({ theme }) => theme.palette.link.main};
    text-decoration-line: none;
  }
  a:hover {
    color: ${({ theme }) => theme.palette.link.dark};
    text-decoration-line: underline;
  }
  i, svg {
    height: 18px;
    width: auto;
   }
`;

export const SectionSpacer = styled.div`
  height: ${({ theme, height }) => height || theme.spacing(2)}px;
`;

export const Spacer = styled.div`
  height: ${({ theme, height }) => height || theme.spacing(8)}px;
`;

export const Note = styled.div`
  width: 100%;
  font-size: 18px;
  background: ${({ theme }) => theme.palette.color5.opacity50};
  border-left: 10px solid ${({ theme }) => theme.palette.color5.opacity100};
  padding: 5px 10px;
  margin: 10px auto;
  color: #333333;
  align-self: center;
  &:before {
    content: "Note: ";
  }
`;

export const Warning = styled.div`
  width: 100%;
  font-size: 18px;
  background: ${({ theme }) => theme.palette.color4.opacity50};
  border-left: 10px solid ${({ theme }) => theme.palette.color4.opacity100};
  padding: 5px 10px;
  margin: 10px auto;
  color: #333333;
  align-self: center;
  &:before {
    content: "Warning: ";
  }
`;

export const CodeHint = styled.div`
  position: absolute;
  top: -25px;
  right: 10px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
  border: 1px solid black;
  border-color: inherit;
  border-bottom-color: transparent;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 12px;
  padding: 2px 5px;
  background: inherit;
  height: 25px;
  display: flex;
`;

const CodeContainer = styled.div`
  position: relative;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.greyScaleDeprecated[6]};
  color: ${({ theme }) => theme.palette.text.primary};
  align-self: center;
  width: 80%;
  font-family: monospace;
  white-space: pre-wrap;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  margin: auto;
  margin-top: 30px;
  padding: 10px;
`;
export const Code = (props) => {
  const { children, hint } = props;

  const [copied, setCopied] = useState(false);

  const handleClick = useCallback((_event) => {
    navigator.clipboard.writeText(children);
    setCopied(true);
  }, [children]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000); 
    }
  }, [copied]);

  return (
    <CodeContainer onClick={handleClick}>
      {(copied || hint) &&
        <CodeHint>{copied ? 'copied' : hint}</CodeHint>}
      {copied &&
        <CodeHint>
          copied
        </CodeHint>}
      {children}
    </CodeContainer>
  );
};
Code.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  hint: PropTypes.string,
};

export class ErrorBoundary extends React.Component {

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (<div>Uh oh, it seems something is broken. Check the Console for errors.</div>);
  }

}
ErrorBoundary.propTypes = {
  children: PropTypes.object,
};













