
// firstCard must be named'firstCard'

export const qCards = {
  firstCard: {
    elementId: 'full-recent-transactions-card',
    edge: 'lefttop',
    width: 400,
    height: 185,
    nudge: { top: 5 },
    title: 'Recent Transactions Card',
    next: 'qcard1',
    content: 'This is one of many dashboard cards that give you information about your finanaces.  The Recent Transactions' +
    ' card shows you recent activity in your accounts.  Clicking anywhere on a dashboard card will take you to more detail.',
  },
  qcard1: {
    elementId: 'full-free-to-spend-card',
    edge: 'left',
    width: 400,
    height: 185,
    title: 'Free to Spend Card',
    prev: 'firstCard',
    next: 'qcard2',
    content: 'Acme identifies your regular income and bills, and helps you determine how much discretionary spending ' +
      'you have left during the month according to your goals.',
  },
  qcard2: {
    elementId: 'full-spending-card',
    edge: 'left',
    width: 200,
    height: 250,
    title: 'Spending Card',
    prev: 'qcard1',
    next: 'qcard3',
    content: 'Here on the Spending Card you can see where you have spent your money this month.  You can interact directly ' +
    'with the graph on the card to dig in to any category for more detail.',
  },
  qcard3: {
    elementId: 'full-spending-over-time-card',
    edge: 'right',
    width: 270,
    height: 180,
    title: 'Spending Over Time Card',
    prev: 'qcard2',
    next: 'qcard4',
    content: 'On this card you can get a quick glance at how your spending has been trending over time.',
  },
  qcard4: {
    elementId: 'application-header-bar',
    edge: 'right',
    width: 284,
    height: 135,
    title: 'Navigation Bar',
    content: 'This is how you access the many features and screens of Acme',
    prev: 'qcard3',
    next: 'qcard4a',
  },
  qcard4a: {
    elementId: 'profile-menu',
    edge: 'rightbottom',
    width: 400,
    height: 140,
    nudge: { top: -8 },
    title: 'Profile Menu',
    prev: 'qcard4',
    next: 'qcard5',
    content: 'Click here to view your information, sign out, or change datasets',
  },
  qcard5: {
    elementId: 'account-drawer-toggle',
    edge: 'righttop',
    width: 400,
    height: 150,
    nudge: { top: -8, left: 4 },
    title: 'Account List Toggle',
    prev: 'qcard4a',
    content: 'The account list shows you all your accounts, and their balances.  Selecting an account in the list will view the transaction list for that account',
  },
};


