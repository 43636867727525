/* eslint-disable */

import { localPreferences, crashReporterInterface, tracker } from 'companion-app-components/utils/core';

// verify (dev mode) - checks value and if value=false then log the message + popup alert; returns value back!
// verify (release mode) - checks value and if value=false then log the message; returns value back!
// assert (dev mode) - checks value and if value=false then log the message + popup alert + track assert; returns value back!
// assert (release mode) - checks value and if value=false then log the message + track assert; returns value back!

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { sprintf } = require('sprintf-js');

const getDescription = (message, ...substitutions) => {
  let description;

  if (typeof message === 'string') {
    if (substitutions?.length) {
      description = sprintf(message, ...substitutions);
    } else {
      description = message;
    }
  } else if (String(message) !== '[object Object]') {
    description = String(message);
  } else {
    description = JSON.stringify(message, null, 2);
  }

  return description;
};

export const verify = (assertion, message, ...substitutions) => {
  if (!assertion) {
    console.assert(assertion, message, ...substitutions); // eslint-disable-line no-console

    const description = getDescription(message, ...substitutions);
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const suppressAssertPopups = localPreferences.getSuppressAssertPopups();
    if (!suppressAssertPopups) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(
        `OK - ignore\
        \nCancel - abort/debug execution\
        \nUse 'dev menu > Crash > Suppress Asserts' to disable popups\
        \n\nassertion: ${description}`,
      )) {
        debugger; // eslint-disable-line no-debugger
        // nodeAssert(assertion, description);
        return 'ignore assert'; // technically it's same as true
      }
    }
/////////////

    tracker.track(tracker.events.assert, { description });
  }
  return assertion;
};

export const assert = (assertion, message, ...substitutions) => {
  const result = verify(assertion, message, ...substitutions);

  if (!result) {
    const description = getDescription(message, ...substitutions) || { name: null, message: null };
    const error = new Error(description);
    error.name = 'Assert';
    crashReporterInterface.reportError(error, (event) => { event.context = error.message; }); // eslint-disable-line no-param-reassign
  }

  return result;
};

if (typeof self === 'object') {
  self.assert = assert;
  self.verify = verify;
}

declare global {
  function assert(assertion, message, ...substitutions): boolean;
  function verify(assertion, message, ...substitutions): boolean;
}