import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { tracker } from 'companion-app-components/utils/core';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { transactionListSelectors } from 'companion-app-components/flux/transaction-list';
import { scheduledTransactionsActions } from 'companion-app-components/flux/scheduled-transactions';

// CUSTOM
import wallet from 'assets/wallet.svg';
import intoWallet from 'assets/into-wallet.svg';
import intoWalletBlue from 'assets/into-wallet-blue.svg';
import outOfWalletBlue from 'assets/wallet-out-blue.svg';
import ZeroStateView from 'components/ZeroStateView';
import QButton from 'components/QButton';
import SelectTransactionDialog from 'components/Dialogs/SelectTransactionDialog';
import { doAccountDiscovery } from 'components/Dialogs/AccountDiscovery/actions';
import RecurringChip from './RecurringChip';

const useStyles = makeStyles()((theme, { isBill }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'calc(100% + 24px)',
    width: '100%',
  },
  recurringRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
  },
  black: {
    color: theme.palette.greyScaleDeprecated[0],
  },
  bolder: {
    color: theme.palette.greyScaleDeprecated[0],
    fontWeight: 500,
  },
  spacedRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listWrapper: {
    marginTop: 8,
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
    height: 'calc(100% - 72px)',
  },
  modelList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'auto',
    height: '100%',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    // marginBottom: 16,
  },
  bottomFade: {
    zIndex: 1,
    position: 'absolute',
    bottom: 0,
    height: 24,
    width: '100%',
    backgroundImage: `linear-gradient(${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[7], 0)}, ${theme.palette.greyScaleDeprecated[7]})`,
  },

  show: {
    opacity: 1,
    transition: 'opacity 0.4s ease',
    color: theme.palette.primary.main,
  },
  hide: {
    opacity: 0,
    transition: 'opacity 0.4s ease',
  },
  chip: {
    overflow: 'hidden',
    width: '100%',
    margin: 8,
    height: 80,
    borderRadius: 8,
    // backgroundColor: isBill ? theme.applyOpacityToHex(theme.palette.secondary.main, 0.8) : theme.applyOpacityToHex(theme.palette.number.positive, 0.7),
    padding: '16px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.link.main,
    border: `1px solid ${theme.palette.link.main}`,
    position: 'relative',
    transition: '0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.greyScaleDeprecated[6],
    },
  },
  slash: {
    position: 'absolute',
    left: -10,
    height: 92,
    width: 52,
    backgroundColor: isBill ? theme.applyOpacityToHex(theme.palette.secondary.dark, 0.2) : theme.applyOpacityToHex(theme.palette.number.positive, 0.6),
    transform: 'rotate(-12deg)',
  },
  icon: {
    height: 48,
    width: 48,
    borderRadius: 8,
    padding: 8,
    zIndex: 1,
    backgroundColor: isBill ? theme.applyOpacityToHex(theme.palette.secondary.dark, 0.6) : theme.applyOpacityToHex(theme.palette.number.positive, 1),
  },
  wallet: {
    width: 'auto',
    height: 128,
  },
  renderAdd: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.link.main,
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(2),
  },
  zeroStateConnectAccountCornerButton: {
    position: 'absolute',
    bottom: -66,
  },
}));


// Functional Component
// - - - - - - - - - - - - - - - - - - - - - - - - - -
export default function RecurringPage(props) {
  const { scheduledTxns, isBill, style, institutionLoginId, showBackButtonInSelectTransactions, resetToAddFi, isGSF } = props;
  const { classes } = useStyles({ isBill });
  const dispatch = useDispatch();

  const accountIds = useSelector((state) => institutionLoginId ? accountsSelectors.getAccountIdsForInstitutionLoginId(state, { institutionLoginId }) : null);
  const possibleRecurringTransactions = useSelector((state) => transactionListSelectors.getLesserBilledTransactions(state, { isBill }))
    .filter((txn) => txn?.coa?.type !== 'BALANCE_ADJUSTMENT');  // removing Opening Balance

  const [add, setAdd] = useState(false);

  // mixpanel state things
  const [numAdded, setNumAdded] = useState(0);
  const [keepers, setKeepers] = useState(new Set());
  const [seen, setSeen] = useState(new Set());

  const latestCapture = useRef();
  const capture = {
    total: scheduledTxns.size,
    keep: keepers.size,
    added: numAdded,
  };
  latestCapture.current = capture;

  // send mixpanel data on unmount
  useEffect(() => () => {
    const { total, keep, added } = latestCapture.current;
    if (isBill) {
      tracker.track(tracker.events.addedNewBill, { number: added });
      tracker.track(tracker.events.confirmedBill, { number: keep });
      tracker.track(tracker.events.removedBill, { number: total - keep });
    } else {
      tracker.track(tracker.events.addedNewIncome, { number: added });
      tracker.track(tracker.events.confirmedIncome, { number: keep });
      tracker.track(tracker.events.removedIncome, { number: total - keep });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newKeepers = keepers;
    let newSeen = seen;
    scheduledTxns.forEach((model) => {
      if (!seen.has(model.id)) {
        newSeen = newSeen.add(model.id);
        if (model.isUserVerified) {
          newKeepers = newKeepers.add(model.id);
        }
      }
    });
    setKeepers(newKeepers);
    setSeen(newSeen);
  }, [scheduledTxns]); // eslint-disable-line react-hooks/exhaustive-deps

  const addSchedule = (currentTxn) => {
    if (isGSF) {  // need to handle multiSelect
      currentTxn.forEach((t) => dispatch(scheduledTransactionsActions.createBillScoutSchedule({ id: t.id })));
      setNumAdded(numAdded + currentTxn.size);
    } else {
      dispatch(scheduledTransactionsActions.createBillScoutSchedule({ id: currentTxn.id }));
      setNumAdded(numAdded + 1);
    }
    setAdd(false);
    setTimeout(() => dispatch(scheduledTransactionsActions.getScheduledTransactions()), 100);
  };

  const reportSchedule = useCallback((id, keep) => {
    if (keep) {
      setKeepers(keepers.add(id));
    } else {
      setKeepers(keepers.delete(id));
    }
  }, [keepers]);

  const closeDialog = () => {
    setAdd(false);
  };

  const selectorProps = { isBill, accountIds };

  const doAdd = useCallback(() => setAdd(true), []);
  const handleKeyDown = useCallback((e) => { if (e.keyCode === 13) doAdd(); }, [doAdd]);

  const zeroState = useMemo(() => {
    if (possibleRecurringTransactions.size === 0) {
      return (
        <ZeroStateView
          icon={isBill ? outOfWalletBlue : intoWalletBlue}
          primary={`We didn't find any ${isBill ? 'expense' : 'income'} transactions`}
          secondary={<span>{'We use this info to keep Simplifi up to date.'}<br />{`Connect ${isBill ? 'an account with recurring bills & transfers' : 'an account with recurring income'} or set up later.`}</span>}
          button={<QButton variant={'outlined'} onClick={() => dispatch(doAccountDiscovery({ initiator: 'getting started', mode: 'GSF' }))}>connect another account</QButton>}
          primaryTypographyProps={{ variant: 'h5' }}
          secondaryTypographyProps={{ variant: 'body1' }}
          iconSize={{ width: 100, height: 'auto' }}
        />
      );
    }
    return (
      <>
        <ZeroStateView
          icon={isBill ? outOfWalletBlue : intoWalletBlue}
          primary={`We didn't find any recurring ${isBill ? 'bills' : 'income'}.`}
          secondary={<span>{'We use this info to keep Simplifi up to date.'}<br />{'Add now or continue to set up later.'}</span>}
          button={<QButton variant={'outlined'} onClick={doAdd}>{`Add ${isBill ? 'bill' : 'income'}`}</QButton>}
          primaryTypographyProps={{ variant: 'h5' }}
          secondaryTypographyProps={{ variant: 'body1' }}
          iconSize={{ width: 100, height: 'auto' }}
        />
        <QButton
          onClick={resetToAddFi}
          variant={'default'}
          className={classes.zeroStateConnectAccountCornerButton}
        >
          connect another account
        </QButton>
      </>
    );
  }, [isBill, doAdd, possibleRecurringTransactions, resetToAddFi, dispatch, classes.zeroStateConnectAccountCornerButton]);

  const addButton = useMemo(() => (
    <div className={classes.renderAdd}>
      <Button
        id={isBill ? 'add-bill-button' : 'add-income-button'}
        onClick={doAdd}
        onKeyDown={handleKeyDown}
        startIcon={<AddIcon />}
        color={'inherit'}
      >
        {isBill ? 'Add bill' : 'Add income'}
      </Button>
    </div>
  ), [classes.renderAdd, isBill, doAdd, handleKeyDown]);

  const selectTransactionButtonText = useMemo(() => {
    if (!isGSF) return null;
    return isBill ? 'Add Bill' : 'Add Income';
  }, [isGSF, isBill]);

  return (
    <div className={classes.recurringRoot}>
      {scheduledTxns.size > 0 &&
        <img src={isBill ? wallet : intoWallet} alt={'wallet'} className={classes.wallet} />}
      <div className={classes.root} style={style}>
        {scheduledTxns.size === 0 ?
          zeroState
          :
          <>
            <div className={classes.spacedRow} style={{ marginTop: -4 }}>
              <Typography variant="body1" className={classes.grey}>
                We found the following recurring&nbsp;{isBill ? 'bills and transfers' : 'income'}.&nbsp;Deselect
                any you don&apos;t want tracked as recurring. We&apos;ll use this to keep Simplifi up
                to date.
              </Typography>
            </div>
            <div className={classes.listWrapper}>
              <div className={classes.modelList}>
                {scheduledTxns.toIndexedSeq().map((model, index) =>
                  <RecurringChip isGSF={isGSF} model={model} index={index} key={model.id} isBill={isBill} reportFunc={reportSchedule} />)}
                {addButton}
              </div>
              <div className={classes.bottomFade} />
            </div>
          </>}
        <SelectTransactionDialog
          open={add}
          title={isBill ? 'Select bills & transfers' : 'Select income'}
          subtitle={isBill ? 'Select only if the items are recurring bills or transfers' : 'Select only if the items are recurring sources of income'}
          disableAdd
          onTransactionSelected={addSchedule}
          onBack={closeDialog}
          onClose={closeDialog}
          selectorProps={selectorProps}
          showBackButton={showBackButtonInSelectTransactions}
          hideBackdrop
          multiSelect={isGSF}
          buttonText={selectTransactionButtonText}
        />
      </div>
    </div>
  );
}

RecurringPage.defaultProps = {
  isBill: false,
};

RecurringPage.propTypes = {
  isBill: PropTypes.bool,
  isGSF: PropTypes.bool,
  scheduledTxns: PropTypes.object,
  style: PropTypes.object,
  institutionLoginId: PropTypes.string,
  showBackButtonInSelectTransactions: PropTypes.bool,
  resetToAddFi: PropTypes.func,
};
