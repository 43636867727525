import React, { FC, useState, useEffect, useRef } from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { tracker } from 'companion-app-components/utils/core';
import { authActions } from 'companion-app-components/flux/auth';
import { tagsActions } from 'companion-app-components/flux/tags';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { categoriesActions } from 'companion-app-components/flux/categories';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { scheduledTransactionsSelectors } from 'companion-app-components/flux/scheduled-transactions';
import { getCategoryGroupsAction } from 'companion-app-components/flux/category-groups/categoryGroupsActions';
import { getCategoryGroupListsAction } from 'companion-app-components/flux/category-group-lists/categoryGroupListsActions';
import { entitlementsSelectors } from 'companion-app-components/flux/entitlements';

import QCard from 'components/QCard';
import useQDialogs from 'components/QDialogs/useQDialogs';
import WFBudgetSetup from 'components/Dialogs/WFBudgetSetup';
import { doWFBillsScout } from 'components/Dialogs/WfBillScout/actions';
import { doAccountDiscovery } from 'components/Dialogs/AccountDiscovery/actions';

import { staticConfig } from './config';
import GettingStartedModulePage from './GettingStartedModulePage';

const GettingStartedWebFirst: FC = () => {

  const [setupStep, setSetupStep] = useState(0);
  const [startedResourceAddNum, setStartedResourceAddNum] = useState<string | boolean | number>(false);
  const [stepCompletedSuccessfully, setStepCompletedSuccessfully] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dialogAlert = useQDialogs();

  const locationRef = useRef(location);

  const tier = useSelector(entitlementsSelectors.getTopTierEntitlement);
  const accountsById = useSelector(accountsSelectors.getAccountsById);
  const scheduledTransactions = useSelector(scheduledTransactionsSelectors.getScheduledTransactions);
  const showScheduledTransactions = useSelector(featureFlagsSelectors.getFeatureFlags).get('scheduledTransactions');

  const isDeluxeUser = () => {
    if (!tier || !tier.id) {
      return undefined;
    }
    return ['US_QUICKEN_DELUXE', 'CA_QUICKEN_DELUXE'].includes(tier.id);
  };

  const hasAccounts = () => accountsById.size > 0;

  const getConfig = () => staticConfig(hasAccounts(), stepCompletedSuccessfully, showScheduledTransactions, isDeluxeUser());

  const getSetupState = (locationParam) => {
    const query = queryString.parse(locationParam.search);
    if (query) {
      const navPageIndex = getConfig().findIndex((rec) => rec.page === query.page);
      if (navPageIndex >= 0) {
        setSetupStep(navPageIndex);
      }
    }
  };

  useEffect(() => {
    getSetupState(window.location);

    const timeout = setTimeout(() => {
      dispatch(categoriesActions.getCategories());
      dispatch(getCategoryGroupListsAction());
      dispatch(getCategoryGroupsAction());
      dispatch(tagsActions.getTags());
    }, 1000);

    return () => clearTimeout(timeout);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (locationRef.current.search !== location.search) {
      locationRef.current = location;
      getSetupState(location);
    }
    if (startedResourceAddNum !== false) {
      switch (setupStep) {
        case 0:
          // @ts-expect-error: Operator '>' cannot be applied to types 'number' and 'string | number | boolean'.
          if (accountsById.size > startedResourceAddNum) {
            setStepCompletedSuccessfully(true);
            setStartedResourceAddNum(false);
          }
          break;
        case 1: {
          const nextVerifiedSchTxns = scheduledTransactions.size && scheduledTransactions.filter((x) => x.isUserVerified);
          // @ts-expect-error: Property 'size' does not exist on type '0'.
          if (nextVerifiedSchTxns.size > startedResourceAddNum) {
            setStepCompletedSuccessfully(true);
            setStartedResourceAddNum(false);
          }
          break;
        }
        default:
          break;
      }
    }
  }, [location, accountsById, scheduledTransactions]); // eslint-disable-line react-hooks/exhaustive-deps

  const onButtonClick = (_e, value) => {
    switch (value) {
      case 'addAccount':
        tracker.track(tracker.events.wizardAddAccount);
        setStartedResourceAddNum(accountsById.size);
        dispatch(doAccountDiscovery({ initiator: 'getting started' }));
        break;
      case 'setUpBills':
        tracker.track(tracker.events.wizardBillscout);
        setStartedResourceAddNum(scheduledTransactions.filter((x) => x.isUserVerified).size);
        dispatch(doWFBillsScout());
        break;
      case 'createBudget':
        tracker.track(tracker.events.wizardAddBudget);
        setStartedResourceAddNum('budgetPending');
        break;
      default:
        break;
    }
  };

  const closeToDashboard = (noSkip?: boolean) => {
    if (noSkip) {
      dialogAlert(
        '',
        'Do you want to sign out?',
        (b) => {
          if (b.btnPressed === 'Yes') {
            tracker.track(tracker.events.wizardWFClose);
            dispatch(authActions.authLogout({ 
              reason: 'AUTH_LOGOUT_USER_INITIATED',
            }, { 
              context: 'GettingStartedWebFirst',
            }));
          }
        },
        ['Yes', 'No'],
      );
    } else {
      navigate('/');
    }
  };

  const advanceFlow = () => {
    if (setupStep < (getConfig().length - 1)) {
      setStepCompletedSuccessfully(false);
      setSetupStep(setupStep + 1);
    } else {
      // Skip the budgets section
      if (!stepCompletedSuccessfully) {
        tracker.track(tracker.events.skipBudgetFlow);
      }
      closeToDashboard();
    }
  };

  const stepVal = accountsById.size > 0 ? setupStep : 0;
  const configRecord = getConfig()[stepVal];
  const skipText = (setupStep < (getConfig().length - 1)) ? 'Next' : 'Done';
  const renderNext = !configRecord.noSkip; //  && (this.state.setupStep < (this.getConfig().length - 1));
  const successQCard = stepCompletedSuccessfully && configRecord.successQCard;

  return (
    <>
      {successQCard &&
        <QCard
          name="setup"
          cards={successQCard}
          open
          initialCard="firstCard"
        />}
      <GettingStartedModulePage
        onCompleted={renderNext ? advanceFlow : null}
        config={configRecord}
        onClose={() => closeToDashboard(configRecord.noSkip)}
        skipText={skipText}
        onButtonClick={onButtonClick}
        showSuccess={stepCompletedSuccessfully}
      />
      {startedResourceAddNum === 'budgetPending' &&
        <WFBudgetSetup
          onClose={() => {
            setStepCompletedSuccessfully(true);
            setStartedResourceAddNum(false);
          }}
        />}
    </>
  );
};

export default GettingStartedWebFirst;
