// @flow
import { fromJS } from 'immutable';
import camelCase from 'lodash/camelCase';

import store from 'companion-app-components/utils/redux-store';
import { featureFlagsActions } from 'companion-app-components/flux/feature-flags';
import { localPreferences, loggerConfigurator } from 'companion-app-components/utils/core';

import * as defaultFlags from './flagsQuicken.json';

const setFeatureFlags = (flags, meta) => {
  const camelCasedFlags = {};
  Object.entries(flags).forEach(([key, value]) => {
    camelCasedFlags[camelCase(key)] = fromJS(value);
  });
  store.dispatch(featureFlagsActions.getFeatureFlagsSuccess(camelCasedFlags, meta));
  applyFeatureFlags(camelCasedFlags);
};

const applyFeatureFlags = (flags) => {
  let { loggingLevel, debug } = flags;

  // default for dev
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  debug = true;
  loggingLevel = 0;
///////////

  if (loggingLevel != null) {
    loggerConfigurator.setFeatureFlagLoggingLevel(loggingLevel);
  }

  if (debug != null) {
    localPreferences.setDebug(debug);
  }
};

export function initializeFeatureFlags() {
  setFeatureFlags(defaultFlags.default, { context: 'init' });
}
