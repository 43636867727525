import React from 'react';
import PropTypes from 'prop-types';

import DialogContent from '@mui/material/DialogContent';

import StdDialog from 'components/Dialogs/StdDialog';

export const DIALOG_TYPE_EMPTY_DIALOG = 'DIALOG_TYPE_EMPTY_DIALOG';

const EmptyDialog = (props) => {
  const { content, dialogId, ...otherProps } = props;

  return (
    <StdDialog
      sharedcomponentid={'DIALOG_TYPE_EMPTY_DIALOG'}
      {...otherProps}
    >
      <DialogContent>
        {content}
      </DialogContent>
    </StdDialog>
  );
};

EmptyDialog.propTypes = {
  content: PropTypes.any,
  dialogId: PropTypes.string, // set automatically for uncontrolled dialog
};

export default EmptyDialog;
