import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { getLogger } from 'companion-app-components/utils/core';
import * as actions from 'data/apiUtil/actions';

import { ImageGalleryStyled } from './styledComponents';

const log = getLogger('components/AccountsList/index.tsx');

interface DocumentBrowserProps {
  documents: { id: string }[];
  onClose: () => void;
  selectedIds: () => void;
}

type ImagesState = {
  id: string;
  url: string;
  name: string;
}[];

const DocumentBrowser: FC<DocumentBrowserProps> = ({ documents, onClose, selectedIds }) => {

  const [images, setImages] = useState<ImagesState>([]);

  const dispatch = useDispatch();

  const reqReturn = (err, data, id) => {
    if (err) {
      log.log(err);
      // error 400 means the docID has been deleted
      if (err.response?.status === 400) {
        setImages((prevImages) => [
          ...prevImages,
          { id, url: 'unavailable', name: 'unavailable' },
        ]);
      }
    } else {
      setImages((prevImages) => {
        if (!prevImages.find((item) => item.id === id)) {
          return [...prevImages, { id, url: data.data.url, name: data.data.name }];
        }
        return prevImages;
      });
    }
  };

  const processProps = (docs) => {
    log.log('Process Props', docs);

    if (docs && docs.length > 0) {
      docs.forEach((docObj) => {
        const path = `documents/${docObj.id}/original`;
        const obj = {
          reqId: docObj.id,
          path,
          data: null,
          verb: 'get',
          cb: reqReturn,
        };
        log.log('Make API Call to:', obj);
        dispatch(actions.makeQCSApiCall(obj));
      });
    } else {
      setImages([]);
      onClose();
    }
  };

  useEffect(() => {
    processProps(documents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  return (
    <Dialog
      fullWidth
      open
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
        View Attachments
        <IconButton
          title="Close"
          aria-label="Close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ImageGalleryStyled
        id="qimg-gallery"
        images={images}
        selectedImages={selectedIds}
      />
    </Dialog>
  );
};

export default DocumentBrowser;
