// @flow
import { handleActions } from 'redux-actions';
import { hash } from 'immutable';
import { coreActions } from 'companion-app-components/flux/core-actions';

import * as actions from './actions';
import { mkPayeeListStore } from './types';

const reducer = handleActions({

  [coreActions.sanitize]: (state) =>
    state.set('payeesByAccountId', state.payeesByAccountId.map((resources) => resources.mapEntries(([id, resource]) =>
      [hash(id).toString(), resource.mergeDeep({
        name: hash(resource.name).toString(),
        catString: hash(resource.catString).toString(),
        tagString: hash(resource.tagString).toString(),
        ...(resource.txn && {
          txn: {
            payee: hash(resource.txn.payee).toString(),
            memo: hash(resource.txn.memo).toString(),
            ...(resource.txn.check && {
              check: {
                memo: hash(resource.txn.check.memo).toString(),
              },
            }),
            ...(resource.txn.cpData && {
              cpData: {
                payee: hash(resource.txn.cpData.payee).toString(),
                inferredPayee: hash(resource.txn.cpData.inferredPayee).toString(),
                memo: hash(resource.txn.cpData.memo).toString(),
              },
            }),
          },
        }),
      })]))),

  [actions.updatePayeeListAsync]: (state, data) => {
    setTimeout(() => {
      data.asyncDispatch(actions.updatePayeeList(data.payload));
    }, 3000);
    return state;
  },

  [actions.updatePayeeList]: (state) => state,
  [actions.createPayeeList]: (state) => state,

  [actions.updatePayeeListSuccess]: (state, { payload: data }) => upsertResources(state, data),
  [actions.createPayeeListSuccess]: (state, { payload: data }) => upsertResources(state, data),

  [actions.updatePayeeListFailure]:
    (state, { payload: _error }) => state,
  [actions.createPayeeListFailure]:
    (state, { payload: _error }) => state,

}, mkPayeeListStore());

export const REDUCER_KEY = 'payeeListStore';
export default reducer;


export function upsertResources(state, payeesByAccountId) {

  let resources = state.payeesByAccountId;

  payeesByAccountId.forEach((res, key) => {
    resources = resources.set(key, res);
  });

  return state.set('payeesByAccountId', resources).set('loadPending', false);
}
