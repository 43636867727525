
import { combineActions, handleActions } from 'redux-actions';

import { resourceUpserters } from 'companion-app-components/flux/core';
import { getBillerAccountsForLoginID } from 'companion-app-components/flux/biller-accounts/billerAccountsActions';

import { keepLogin } from './utils';
import * as actions from './actions';
import { mkBillerLoginStore, BillerLoginStore, RefreshStatusEnum } from './types';
import { deleteBillerLogin } from './actions';

const reducer = handleActions({

  [actions.getBillerLogins]: (state: BillerLoginStore) => state,

  [actions.getBillerLoginsSuccess]:
    (state, { payload: data }) => resourceUpserters.upsertResources(state, data),

  [actions.getBillerLoginSuccess]: // successful poll
    (state, { payload: data, asyncDispatch }) => {
      const login = data?.resources[0] || null;
      const loginStatus = data?.resources[1] || null;
      const MFA = data?.resources[2] || null;

      if (keepLogin(login, loginStatus, MFA)) { // credentials valid
        return resourceUpserters.upsertResource(state, login);
      }
      asyncDispatch(deleteBillerLogin(login));
      return state;
    },

  [actions.startRediscovery]: (state, { payload: login }) => resourceUpserters.upsertResource(state, login),

  [actions.rediscoverBillerLogin]: // on rediscover, don't delete login
    (state, { payload: data }) => {
      const login = data?.resources[0] || null;
      return resourceUpserters.upsertResource(state, login);
    },

  [actions.discoverBillerSuccess]: // when login discovery completes
    (state: BillerLoginStore, { payload: billerLogin, asyncDispatch }) => {
      if (billerLogin?.refreshStatus === RefreshStatusEnum.REFRESHED) {
        asyncDispatch(getBillerAccountsForLoginID(billerLogin?.id));
      }
      return state;
    },

  [combineActions(
    actions.updateBillerLoginSuccess,
    actions.deleteBillerLoginSuccess,
  )]: (state, { payload: billerLoginToUpsert }) => resourceUpserters.upsertResource(state, billerLoginToUpsert),

  [actions.getBillerLoginsFailure]: (state :BillerLoginStore, { payload: error }) => resourceUpserters.completeWithError(state, error),

  [actions.discoverBillerFailure]: (state :BillerLoginStore, { payload: error }) => resourceUpserters.completeWithError(state, error),

}, mkBillerLoginStore());

export const REDUCER_KEY = 'billerLoginsStore';
export default reducer;
