import { takeLatest } from 'redux-saga/effects';
import Axios from 'axios';
import { getEnvironmentConfig, getLogger } from 'companion-app-components/utils/core';
import AxiosFactory from 'utils/axiosFactory';

import * as actions from './actions';

const log = getLogger('data/apiUtil/apiCall.js');

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


// ====================================================
// MAKE QCS API CALL
//
// reqId = supplied id that is returned with the callback
// path = right side of the slash after the qcs path
// data = data to pass with the api call
// verb = get, put, post, delete
// cb = callback function when the call is completed
//
// cb is called with (error, response)
//
export function* makeQCSApiCall({ payload: data }) {
  const { reqId, path, callData, verb, cb } = data;

  const qcsAxios = AxiosFactory.get('qcs');

  const requestUrl = `${getEnvironmentConfig().services_url}/${path}`;

  // ASYNC CALL HERE
  qcsAxios[verb](requestUrl, callData).then((response) => {
    cb(null, response, reqId);
  }).catch((response) => {
    cb(response, null, reqId);
  });
}

export function* makeGeneralApiCall({ payload: data }) {
  const { reqId, path, callData, verb, cb, headers } = data;

  const apiAxios = Axios.create();
  delete apiAxios.defaults.headers.common['Content-type'];
  delete apiAxios.defaults.headers[verb?.toLowerCase()]['Content-Type'];


  // ASYNC CALL HERE
  //
  apiAxios[verb](path, callData, { headers }).then((response) => {
    cb(null, response, reqId);
  }).catch((response) => {
    cb(response, null, reqId);
  });
}

function* initializer() {
  log.log('INITIALIZE QCS API CALL');
}
// ====================================================
// ACTION WATCHERS to trigger SAGAS calls

export function* getQCSApiActionWatcher() {
  yield takeLatest(actions.makeQCSApiCall, makeQCSApiCall);
}
export function* getGeneralApiActionWatcher() {
  yield takeLatest(actions.makeGeneralApiCall, makeGeneralApiCall);
}

// ====================================================
// EXPORTS

export default [
  initializer,
  getQCSApiActionWatcher,
  getGeneralApiActionWatcher,
];
