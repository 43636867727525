import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
  autoGrowInput: {
    minWidth: theme.spacing(1),
    width: 'fit-content',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: 12,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: theme.palette.divider,
    outline: 'none',
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  singleLine: {
    // single line
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& br': {
      display: 'none',
    },
  },
}));

const QInputSpan = (props) => {
  const { classes, cx } = useStyles();
  const { defaultValue, onChange, multiline, autoFocus, ...otherProps } = props;
  const elementRef = useRef();
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    if (elementRef.current && value === defaultValue) {
      elementRef.current.textContent = defaultValue;
      if (autoFocus) {
        elementRef.current.focus();
      }
    }
  }, [value, defaultValue, autoFocus]);

  return (
    <span
      className={cx(...[classes.autoGrowInput, ...(multiline ? [] : [classes.singleLine])])}
      sharedcomponentid={'Q_INPUT_SPAN'}
      role="textbox"
      contentEditable
      ref={elementRef}
      onInput={(event) => {
        setValue(event.target.textContent);
        onChange?.(event.target.textContent);
      }}
      onFocus={(event) => {
        let sel;
        let range;
        if (window.getSelection && document.createRange) {
          range = document.createRange();
          range.selectNodeContents(event.currentTarget);
          sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (document.body.createTextRange) {
          range = document.body.createTextRange();
          range.moveToElementText(event.currentTarget);
          range.select();
        }
      }}
      {...otherProps}
    />
  );
};

QInputSpan.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  multiline: PropTypes.bool,
};

export default QInputSpan;
