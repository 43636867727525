import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import { getLogger } from 'utils/logger';

import {
  dispatchToProps,
  generateName,
  generateResetUiState,
  generateSetUiState,
  stateToProps,
} from './utils';

// const log = getLogger('components/UIState/index.js');

// HOW TO USE UISTATE
//
// This component is a WRAPPER component, meaning that it will intercept calls to your component, and possibly make
// changes to props that are sent to your component.  It will also do its own work as well.  This component will
// manage UIState for your component.  Since UIState is mapped as a property to your component, changes to UIState
// will generate a render to your component.
//
// The basic usage is:
//
// a) import->   import UIState from 'components/UIState';
// b) set up a config record used to initialize the wrapper component
//
//  const uiStateConfig = {
//    name: (props) => `TxRegId:${props.account.id}`,  // Either a FUNCTION or a STRING
//    state: (props) => (new RegisterUIState({         // Either a FUNCTION or an OBJECT
//      // your initial state info goes here, used to initialize/reset a component state record
//      data1: 1,
//      data2: {a: 3, b: 5},
//    })),
//    persist: false,
//  };
//
// If name and/or state is passed in as a function, then their values will be evaluated when needed based
// on the component properties.  This can then be used to support multiple state records per component when
// a component may need different states to represent different instances based on properties.  This can also be
// used to support persistence across sessions, in which initial state is derived.  NOTE, however this does require
// that state preferences be accesses synchronously.  The name or state functions must return a value.
//
// c) set up wrapper in export default (bottom of component)
//
//    // compose allows me to have more than 1 wrapper, ORDER IS IMPORTANT, props flow top to bottom
//
//    export default compose(
//      UIState(uiStateConfig),
//      connect(mapStateToProps, mapDispatchToProps),
//    )(MyComponentName);
//

// Config is passed in by using component when composing wrapper
//
export default (config) => (WrappedComponent) => {
  class uiState extends Component {
    constructor(props) {
      super(props);

      this.config = config;

      if (typeof this.config.name === 'function') {
        this.uiStateName = (newProps) => this.config.name(newProps);
      } else {
        const stateName = generateName(config.name);
        this.uiStateName = () => stateName;
      }

      this.initState = (newProps) => (
        (typeof this.config.state === 'function')
          ? this.config.state(newProps)
          : this.config.state);
    }

    UNSAFE_componentWillMount() {
      if (!this.config.persist || !this.props.uiState.get(this.uiStateName(this.props))) {
        this.props.add(this.initState(this.props), this.uiStateName(this.props));
      }
    }

    // check for new state record since config is dependent upon props
    UNSAFE_componentWillReceiveProps(newProps) {
      if (typeof this.config.name === 'function' &&
        this.uiStateName(newProps) !== this.uiStateName(this.props)) {

        // TV put this conditional back to prevent INIT's from occurring if the record
        // already exists
        if (!this.props.uiState.get(this.uiStateName(newProps))) {
          this.props.add(this.initState(newProps), this.uiStateName(newProps));
        }
        if (!this.config.persist) {
          this.props.delete(this.uiStateName(this.props));
        }
      }
    }

    componentWillUnmount() {
      if (!this.config.persist) {
        this.props.delete(this.uiStateName(this.props));
      }
    }

    render() {

      const resetUiState = generateResetUiState(
        this.props.reset,
        this.uiStateName(this.props),
        () => this.initState(this.props),
      );
      const setUiState = generateSetUiState(this.props.set, this.uiStateName(this.props));
      const uiStateProps = {
        resetUiState,
        resetUIState: resetUiState, // avoid case-sensitive typos
        setUiState,
        setUIState: setUiState, // avoid case-sensitive typos
        uiStateName: this.uiStateName(this.props), // name of component's state slice
      };

      // wrapped component with its props, the state from HOC and uiStateProps
      return (
        <WrappedComponent
          {...{
            ...this.props,
            uiState: this.props.uiState.get(this.uiStateName(this.props)) || this.initState(this.props),
            ...uiStateProps,
          }}
        />
      );
    }
  }

  uiState.propTypes = {
    add: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
    uiState: PropTypes.object.isRequired,
  };

  // the HOC itself is wrapped in connect
  return connect(stateToProps, dispatchToProps)(uiState);
};
