// @flow
import { createAction } from 'redux-actions';

export const stdFormAdd = createAction('STD_FORM_ADD');
export const stdFormDelete = createAction('STD_FORM_DELETE');

export const stdFormSubmitted = createAction('STD_FORM_SUBMITTED');
export const stdFormSubmitSuccess = createAction('STD_FORM_SUBMIT_SUCCESS');
export const stdFormSubmitFailure = createAction('STD_FORM_SUBMIT_FAILURE');
export const stdFormCancelled = createAction('STD_FORM_CANCELLED');
