const bodyHeight = 460;

export const WFBillScoutStyles = (theme) => {
  const { spacing, palette, typography } = theme;
  return ({
    dialog: {
      maxWidth: 850,
      minWidth: 750,
    },
    qCard: {
      zIndex: 5000,
    },
    headline: {
      padding: spacing(0, 3.5, 1, 0.75),
    },
    headlineTitle: {
      ...typography.h6,
    },
    headlineCaption: {
      ...typography.subtitle1,
    },
    body: {
      flex: 1,
      height: bodyHeight,
      padding: `${spacing(2)} ${spacing(3.25)}`,
    },
    bodyTxnSelector: {
      paddingTop: 0,
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    actions: {
      backgroundColor: palette.common.white,
      margin: spacing(-2, 3.25, 0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: 80,
      borderTop: `1px solid ${palette.secondary.main}`,
      position: 'relative',
    },
    wideButton: {
      padding: spacing(0.75, 4),
    },
    backButton: {
      marginRight: spacing(2),
    },
    skipButton: {
      marginRight: spacing(2),
    },
    continueButton: {
      color: `${palette.greyScaleDeprecated[7]} !important`,
    },
    searchBarRoot: {
      display: 'inline-block',
      margin: `0 0 ${spacing()}`,
    },
    searchBar: {
      paddingLeft: 4,
      borderRadius: 4,
      margin: 0,
      '&.open': {
        width: 196,
      },
    },
    searchInput: {
      fontSize: '14px',
      lineHeight: 1.43,
      fontWeight: 'normal',
    },
    searchIcon: {
      color: palette.secondary.main,
    },
    cancelIcon: {
      color: palette.greyScaleDeprecated[3],
    },
    editScheduledTransactionLabel: {
      ...typography.caption,
      textAlign: 'left',
      width: '100%',
      display: 'inline-block',
    },
    manualLine: {
      ...typography.body2,
      textAlign: 'left',
      width: '100%',
    },
    manualAddLine: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      color: theme.palette.secondary.main,
    },
    manualButton: {
      color: theme.palette.secondary.main,
      marginLeft: 6,
    },
    helperLine: {
      ...typography.caption,
      fontWeight: typography.fontWeightMedium,
      textAlign: 'left',
      width: '100%',
    },
    backLine: {
      textAlign: 'left',
      width: '100%',
    },
  });
};

export const ScheduleTransactionSelectorStyles = ({ spacing, palette, typography }) => ({
  zeroRoot: {
    margin: '0 auto',
  },
  waiterRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  waiterTitle: {
    ...typography.subtitle1,
    marginTop: 0,
  },
  waiterText: {
    ...typography.body2,
  },
  waiterCircularRoot: {
    textAlign: 'center',
  },
  scheduleTxnSelectorRoot: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
    marginTop: spacing(),
    maxHeight: bodyHeight,
    minHeight: 400,
  },
  selectTitle: {
    ...typography.body2,
    width: spacing(8),
    textAlign: 'center',
  },
  scheduleTxnSelectorWrapper: {
    background: palette.common.white,
    flex: 1,
    maxHeight: '100%',
    overflow: 'scroll',
    width: '100%',
    borderTop: `1px solid ${palette.secondary.main}`,
  },
  standardRow: {
    '&:nth-child(odd)': {
      backgroundColor: palette.greyScaleDeprecated[6],
    },
  },
  tableCellRoot: {
    borderBottom: 'none',
  },
  tableCell: {
    padding: `${spacing(1.5)} ${spacing(1)} ${spacing(1.5)}`,
    height: spacing(11.75),
  },
  tableCellAvatar: {
    height: spacing(11.75),
    padding: spacing(2),
    paddingRight: spacing(0.5),
    maxWidth: spacing(25),
  },
  tableCellActions: {
    height: spacing(11.75),
    padding: `${spacing(2)} ${spacing(1)} ${spacing(2)} !important`,
  },
  tableCellContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableCellAvatarContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableCellPayeeContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: spacing(25),
  },
  tableCellAmountContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: spacing(18),
  },
  tableCellAccountContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: spacing(20),
  },
  tableCellActionsContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    maxWidth: spacing(18),
  },
  cellTitle: {
    ...typography.body1,
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'left',
    width: '100%',
  },
  cellCaption: {
    ...typography.caption,
    display: 'block',
  },
  avatar: {
    borderRadius: 3,
  },
  selectIcon: {
    color: palette.secondary.main,
    border: `1px solid ${palette.greyScaleDeprecated[3]}`,
    borderRadius: '50%',
  },
  selectedIcon: {
    color: palette.secondary.main,
  },
  bottomActionsTitleContainer: {
    flexDirection: 'column',
    padding: spacing(2, 0, 2.5),
    borderTop: `2px solid ${palette.grey['300']}`,
  },
  collapseArea: {
    width: '100%',
  },
  collapseAreaWrapper: {
    width: '100%',
  },
  missingTypeTitleTop: {
    ...typography.subtitle1,
    fontWeight: typography.fontWeightLight,
    display: 'inline-block',
  },
  bottomActionsRoot: {
    justifyContent: 'space-evenly',
    paddingTop: spacing(2),
  },
  actionContainer: {
    padding: `${spacing(1)} ${spacing(1)} ${spacing(4)} ${spacing(2)}`,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    maxWidth: '100%',
  },
  addMissingContainer: {
    padding: `${spacing(1)} ${spacing(1)} ${spacing(4)} ${spacing(2)}`,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    maxWidth: '100%',
  },
  wideButton: {
    padding: spacing(0.75, 2.5),
    borderColor: palette.grey.A200,
    marginRight: spacing(),
    '&:last-child': {
      marginLeft: spacing(),
    },
  },
});
