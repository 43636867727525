import * as React from 'react';
import { FC, memo } from 'react';

interface AnnualViewCategoryLabelProps {
  label: string;
}

const AnnualViewCategoryLabel: FC<AnnualViewCategoryLabelProps> = ({ label }) => {
  if (!label.includes(':')) { 
    return <> {label} </>;
  }

  const arrDisplayLabel = label.split(':');
  const lastDiaplayLabel = arrDisplayLabel.pop();
  return (
    <>
      {arrDisplayLabel.join(': ')}: <strong>{lastDiaplayLabel}</strong>
    </>
  );
};

export default memo(AnnualViewCategoryLabel);
