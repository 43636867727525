import React, { FC, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import noop from 'lodash/noop';
import Dropzone from 'react-dropzone';
import { makeStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';

import { getLogger } from 'companion-app-components/utils/core';
import { transactionsTypes } from 'companion-app-components/flux/transactions';

import useQDialogs from 'components/QDialogs/useQDialogs';

import * as actions from 'companion-app-components/flux/documents/documentsActions';
import * as documentSelectors from 'companion-app-components/flux/documents/documentsSelectors';
import { mkDocument } from 'companion-app-components/flux/documents/documentsTypes';
import { makeQCSApiCall, makeGeneralApiCall } from 'data/apiUtil/actions';

import { FileName, ModalDocDiv, DropzoneStyled, HiddenInput, UploadFileList } from './styledComponents';
import { styles } from './styles';

const log = getLogger('components/AddAndBrowseDocuments/index.tsx');

const { MAX_TX_ATTACHMENTS } = documentSelectors;

const useStyle = makeStyles()(styles as Record<string, any>);

/* TODO
Word of caution when working with previews

  Important: react-dropzone doesn't manage dropped files. You need to destroy the object URL yourself
  whenever you don't need the preview by calling window.URL.revokeObjectURL(file.preview); to avoid memory leaks.
  Support
*/

const UNAVAILABLE = 'unavailable';
const UNAVAILABLE_NAME = '-unavailable-';

interface AddAndBrowseDocumentsProps {
  id: string;
  onChange: (attachment: any[], flag?: boolean) => void;
  txn: transactionsTypes.CashFlowTransaction;
  onSizeChange: () => void;
  lastTabName: string;
}

type DocumentType = {
  id: string;
  url?: string;
  name?: string, 
  document?: any;
  clientId?: string;
  isLoading?: boolean; 
};

const AddAndBrowseDocuments: FC<AddAndBrowseDocumentsProps> = (props) => {

  const [images, setImages] = useState<DocumentType[]>([]);
  const [imagesLoading, setImagesLoading] = useState<DocumentType[]>([]);
  const [sizeChanged, setSizeChanged] = useState(false);
  const [forceRender, setForceRender] = useState(false);

  const accepted = useRef<DocumentType[]>([]);
  const checkRecordsCount = useRef(0);
  const txnAttachments = useRef<DocumentType[]>(props.txn.attachments || []);

  const { classes } = useStyle();
  const dispatch = useDispatch();
  const { dialogAlert } = useQDialogs();

  const documents = useSelector((state) => documentSelectors.getDocuments(state));

  const reqReturn = ({ err, data, id, document }) => {
    if (err) {
      log.log(err, 'error');
      // error 400 means the docID has been deleted
      if (!err.response || err.response.status === 400) {
        setImages((prevImages) => [
          ...prevImages,
          { id, url: UNAVAILABLE, name: UNAVAILABLE_NAME, document },
        ]);
        setSizeChanged(true);
      }
    } else {
      setImages((prevImages) => {
        if (!prevImages.find((item) => item.id === id)) {
          return [...prevImages, { id, url: data.data.url, document }];
        }
        return prevImages;
      });
      setSizeChanged(true);
    }
  };

  const processProps = () => {
    log.log('Process Props', props, images);

    const currentImages = images || [];
    const newImages:DocumentType[] = [];

    if (props.txn.attachments && props.txn.attachments.length > 0) {
      props.txn.attachments.forEach((docObj) => {
        const document = documents.find((x) => x.id === docObj.id);
        const alreadyHere = currentImages.find((x) => x.id === docObj.id);

        if (document && !alreadyHere) {
          const path = `documents/${docObj.id}/original`;
          const obj = {
            reqId: docObj.id,
            path,
            data: null,
            verb: 'get',
            cb: (err, data, id) => reqReturn({ err, data, id, document }),
          };
          log.log('Make API Call to:', obj);
          dispatch(makeQCSApiCall(obj));
        } else if (alreadyHere) {
          newImages.push(alreadyHere);
        } else {
          newImages.push({ id: docObj.id, url: UNAVAILABLE, name: UNAVAILABLE_NAME, document });
          setSizeChanged(true);
        }
      });
    }
    setImages(newImages);
  };

  useEffect(() => {
    processProps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.txn.attachments]);

  useEffect(() => {
    if (sizeChanged) {
      setSizeChanged(false);
      if (props.onSizeChange) {
        props.onSizeChange();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeChanged]);

  const fileSent = (err, data, reqObj) => {
    if (err) {
      dialogAlert(
        'Error saving an attachment',
        `Error: ${err}`,
        noop,
        ['Got it'],
      );
    } else {
      // need id, url, document
      log.log('File Sent ', reqObj);

      // @ts-expect-error: Argument of type 'DocumentType[]' is not assignable to parameter of type 'ConcatArray<never>'.
      const attachments: DocumentType[] = [].concat(txnAttachments.current || []);
      attachments.push({ id: reqObj.id });
      txnAttachments.current.push({ id: reqObj.id });

      props.onChange(attachments);
      setImagesLoading((prevImagesLoading) => prevImagesLoading.filter((x) => x.document.clientId !== reqObj.document.clientId));
    }
  };

  const updateFn = (doc, file) => {
    const data = {
      reqId: { id: doc.id, clientId: doc.clientId, name: file.name, document: doc },
      path: doc.url,
      callData: file,
      verb: 'put',
      cb: fileSent,
      headers: { 'Content-Type': doc.contentType },
    };

    log.log(`Sending File for ${file.name}`, data);
    dispatch(makeGeneralApiCall(data));
  };

  const checkRecords = (restart = false) => {

    checkRecordsCount.current = restart ? 0 : checkRecordsCount.current + 1;
    log.log('Add Document: checking records', accepted.current, checkRecordsCount.current);

    const newAccepted: DocumentType[] = [];
    accepted.current.forEach((rec) => {
      const newDoc = documents.find((x) => x.clientId === rec.clientId);
      if (newDoc?.url) {
        log.log('Found document in store ', newDoc);
        updateFn(newDoc, rec);
      } else if (!newDoc) {
        newAccepted.push(rec);
      }
    });

    accepted.current = newAccepted;

    if (accepted.current.length > 0) {
      if (checkRecordsCount.current < 240) {  // wait up to 2 minutes

        setTimeout(() => {
          setForceRender(!forceRender);
        }, 1000);

      } else {
        dialogAlert(
          'It appears there was an issue uploading your attachment',
          'The upload timed out, you can try again.  You may want to check your network connection',
          noop,
          ['Got it'],
        );
      }
    }
  };

  useEffect(() => {
    checkRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRender]);

  const onUpload = (acceptedFiles) => {
    //
    // upload files to QCS
    //
    log.log('UPLOAD', acceptedFiles);
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((rec) => {
        // eslint-disable-next-line no-param-reassign
        rec.clientId = uuidv4().toUpperCase();
        const newDoc = mkDocument(
          { 
            name: rec.name,
            documentSize: rec.size,
            clientId: rec.clientId,
            contentType: rec.type,
          },
        );

        newDoc.set('updateFn', updateFn);
        log.log('Create Document ', newDoc, acceptedFiles);
        // @ts-expect-error: Property 'id' is missing in type
        setImagesLoading(imagesLoading.concat([{ isLoading: true, document: newDoc }]));
        dispatch(actions.createDocument(newDoc));
        accepted.current = acceptedFiles;
        checkRecords(true);
      });
    }
  };

  const onDrop = (acceptedFiles) => {  // , rejectedFiles <= NOT USED!
    if (acceptedFiles.length + accepted.current.length > MAX_TX_ATTACHMENTS) {
      dialogAlert(
        'Too many attachments',
        `Sorry, you can only add ${MAX_TX_ATTACHMENTS} items per transaction.`,
        noop,
        ['Got it'],
        'warning',
      );
    } else {
      onUpload(acceptedFiles);
    }
  };

  const deleteFileEntry = (id) => {
    const document = documents.find((x) => x.id === id);
    dialogAlert(
      'Delete Attachment',
      `This will delete the attachment ${document ? document.name : UNAVAILABLE_NAME}.  Are you sure?`,
      (retObj) => {

        if (retObj.btnPressed === 'Delete') {
          
          // dispatch(actions.deleteDocument({ id }));  // leaves it orphaned, not cleaning up unused document id's
          // delete it from the transactions attachments list
          // create a new attachments array for the transaction
          
          // @ts-expect-error: Argument of type 'DocumentType[]' is not assignable to parameter of type 'ConcatArray<never>'.
          const attachments = [].concat(txnAttachments.current || []);
          const docIndex = attachments.findIndex((x: { id : string }) => x.id === id);
          if (docIndex >= 0) {
            attachments.splice(docIndex, 1);
          }
          txnAttachments.current = attachments;
          props.onChange(attachments, false);  // do an autosave in this case
        }
      },
      ['Cancel', 'Delete'],
      'delete',
    );
  };

  const clickPic = (file) => {
    if (file.url !== UNAVAILABLE) {
      window.open(file.url, '_blank');
    }
  };

  const imagesToDisplay = images.concat(imagesLoading);
  const showGeneralLoading = imagesToDisplay.length === 0 && images.length > 0;

  return (
    <div>
      <ModalDocDiv 
        autoFocus
      >
        <div className={classes.boxDiv}>
          <Typography className={classes.attachmentTypography}>
            Attachments
          </Typography>

          <div 
            className="dropzone" 
            id={props.id}
          >
            <Dropzone
              onDrop={onDrop}
              accept="app/*, image/*, application/*, text/*"
            >
              {({ getRootProps, getInputProps }) => (
                <DropzoneStyled
                  className="custom"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <CloudUploadIcon />
                  <Typography 
                    className={classes.attachTitle} 
                    variant="subtitle2"
                  >
                    Upload
                  </Typography>
                </DropzoneStyled>
              )}
            </Dropzone>
          </div>
        </div>
        <div className={classes.previewWrapper}>
          {showGeneralLoading &&
            <CircularProgress
              size={32}
              color="primary"
            />}
          <UploadFileList>
            { imagesToDisplay.length > 0 &&
            imagesToDisplay.map((f, index) =>
              (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`${f.name}${index}`}>
                  {/* @ts-expect-error : The expected type comes from property 'onClick' which is declared here on type 'IntrinsicAttributes & ButtonBaseOwnProps */}
                  <ButtonBase
                    focusRipple
                    classes={{ root: classes.root }}
                    onClick={f.isLoading ? null : () => clickPic(f)}
                  >
                    {f.isLoading &&
                    <CircularProgress
                      color="primary"
                    />}
                    <FileName>
                      {f.url === UNAVAILABLE ? UNAVAILABLE_NAME : f.document.name}
                    </FileName>
                    {!f.isLoading &&
                    <DeleteIcon
                      className={classes.deleteIcon}
                      onClick={(e) => { 
                        e.stopPropagation(); 
                        deleteFileEntry(f.id); 
                      }}
                    />}
                  </ButtonBase>
                </li>))}
          </UploadFileList>
        </div>
        <HiddenInput
          autoFocus
          name={props.lastTabName}
        >
        </HiddenInput>
      </ModalDocDiv>
    </div>
  );
};

export default AddAndBrowseDocuments;
