import * as React from 'react';
import { useSelector } from 'react-redux';

import Avatar from '@mui/material/Avatar';

import { datasetSharesSelectors } from 'companion-app-components/flux/dataset-shares';

import { stringAvatar } from './utils';

interface ProfileAvatarProps {
  datasetId: string;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ datasetId }) => {

  const sharedWith = useSelector((state) => datasetSharesSelectors.getSharesByDatasetId(state, datasetId)).first();
  if (sharedWith) {
    return (
      <Avatar 
        {...stringAvatar(`${sharedWith.granteeFirstName?.trim()} ${sharedWith.granteeLastName?.trim()}`)} 
      />
    );
  }
  return null;
};

export default ProfileAvatar;
