import React, { useState, useEffect, FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import cn from 'classnames';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import { getLogger } from 'companion-app-components/utils/core';
import type { Profile } from 'companion-app-components/flux/profile/profileTypes';

// TODO: uncomment if dataset creating is needed in future
// import QButton from 'components/QButton';
// import QTip from 'components/QuickenControls/QTip';
// import { hasWebFirstEntitlements } from 'data/entitlements/entitlementsSelectors';
import { isMac } from 'utils/utils';

import winGraphics from 'assets/setup-page/Win.png';
import macGraphics from 'assets/setup-page/Mac.png';
import macIcon from 'assets/setup-page/Mac Icon.png';
import winIcon from 'assets/setup-page/Win Icon.png';

import { styles } from './styles';

const log = getLogger('containers/SetupPageCompanion');

const stepsWin = [
  'Open Quicken Classic for Windows',
  'Click on Get Started on the Mobile & Web tab',
  'Click Done after selecting all your accounts',
  'Click on Sync Now, or do a One Step Update anytime to sync',
];

const stepsMac = [
  'Open Quicken Classic Mac',
  'Click on Preferences in the Quicken menu',
  'Click on Mobile, Web & Alerts in preferences window',
  'Turn Sync On',
];

const useStyle = makeStyles()(styles as Record<string, any>);

interface SetupPageCompanionProps {
  profile?: Profile;
  authSession: Record<string, any>;
  defaultTabIsWindows: boolean;
  // TODO: uncomment if dataset creating is needed in future
  // onCreateDataset: (type: string) => void;
}

const SetupPageCompanion: FC<SetupPageCompanionProps> = ({
  profile,
  authSession,
  defaultTabIsWindows,
  // TODO: uncomment if dataset creating is needed in future
  // onCreateDataset,
}) => {
  const [isWindows, setIsWindows] = useState(defaultTabIsWindows !== undefined ? defaultTabIsWindows : !isMac());

  const { classes } = useStyle();

  // TODO: uncomment if dataset creating is needed in future
  // const allowCreateDataset = useSelector(hasWebFirstEntitlements);
  const userEmail = profile?.primaryEmail?.address || '';
  const macDownload = `https://download.quicken.com/mac/Quicken.dmg?email=${userEmail}`;
  const winDownload = `https://download.quicken.com/windows/Quicken.exe?email=${userEmail}`;
  const downloadLink = isWindows ? winDownload : macDownload;

  const steps = isWindows ? stepsWin : stepsMac;
  const stepsImg = isWindows ? winGraphics : macGraphics;
  const suffix = isWindows ? 'for Windows' : 'Mac';

  const showBoth = true;

  useEffect(() => {
    if (defaultTabIsWindows !== undefined) {
      setIsWindows(defaultTabIsWindows);
    }
  }, [defaultTabIsWindows]);

  return (
    <div className={classes.setupWrapper}>
      <div className={classes.mAuto}>
        <Typography className={classes.title}>
          Hello.
        </Typography>
        <Typography className={classes.subTitle}>
          It looks like you have not synced your accounts yet
        </Typography>
        <Typography className={classes.bodyText}>
          {`To sync your accounts from Quicken Classic ${suffix}:`}
        </Typography>
        <div className={cn(classes.tabWrapper, classes.mAuto)}>
          <div className={classes.choiceTabs}>
            {(showBoth || isWindows) && (
              <ButtonBase
                className={cn(classes.tab, isWindows ? 'selected' : 'unselected')}
                onClick={() => setIsWindows(true)}
                disabled={!showBoth}
              >
                <div>
                  <img alt="Windows Icon" src={winIcon} className={cn(classes.dInline, classes.platformImg)} />
                  <Typography className={classes.dInline}>WINDOWS</Typography>
                </div>
              </ButtonBase>
            )}
            {(showBoth || !isWindows) && (
              <ButtonBase
                className={cn(classes.tab, isWindows ? 'unselected' : 'selected')}
                onClick={() => setIsWindows(false)}
                disabled={!showBoth}
              >
                <div>
                  <img alt="Mac icon" src={macIcon} className={cn(classes.dInline, classes.platformImg)} />
                  <Typography className={classes.dInline}>MAC</Typography>
                </div>
              </ButtonBase>
            )}
          </div>
          <div id="content" className={classes.stepWrapper}>
            <div id="steps" className={classes.stepSection}>
              {steps.map((step, index) => (
                <div key={`setup-step: ${step}`} className={classes.stepBox}>
                  <div className={classes.steps}>
                    <Typography className={classes.stepHeading}>{`Step ${index + 1}`}</Typography>
                    {index !== steps.length - 1 && <div className={classes.stepDivider} />}
                  </div>
                  <Typography className={classes.bodyText}>{step}</Typography>
                </div>
              ))}
            </div>
            <div className={classes.stepImg}>
              <img alt="graphical steps" src={stepsImg} />
            </div>
            <div className={classes.footer}>
              <Divider className={classes.divider} />
              <div className={classes.bodyText}>
                <Link
                  className={classes.link}
                  onClick={() => window.open('https://www.quicken.com/support/quicken-web-frequently-asked-questions', '_blank')}
                >
                  See this FAQ,
                </Link>
                &nbsp;on how to set your accounts up for Sync
                <div className={classes.vertBar} />
                <Link
                  className={classes.link}
                  onClick={() => window.open(`https://www.quicken.com/my-account?bearer=${authSession.accessToken}`, '_blank')}
                >
                  Go to My Account
                </Link>
                <div className={classes.vertBar} />
                <Link
                  className={classes.link}
                  onClick={() => {
                    log.log(`Opening: ${downloadLink}`);
                    window.open(downloadLink, '_blank');
                  }}
                >
                  {`Download Quicken Classic for ${isWindows ? 'Windows' : 'Mac'}`}
                </Link>
              </div>
              {/* TODO: uncomment if dataset creating is needed in future */}
              {/* {
                allowCreateDataset &&
                <div className={classes.startClean}>
                  <QTip
                    title={`Creates a new cloud file that you can use on web and mobile, and then load into a Quicken ${suffix} when you want to use the desktop`}
                  >
                    <QButton onClick={() => onCreateDataset(isWindows ? 'quicken_windows' : 'quicken_mac')}>
                      {`OR... Create New Quicken ${suffix} Dataset`}
                    </QButton>
                  </QTip>
                </div>
              } */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupPageCompanion;
