import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime, Interval } from 'luxon';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import SDatePicker from 'components/SDatePicker';

import QButton from '../QButton';

const useStyles = makeStyles()((theme) => {
  const outerDay = {
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    padding: 0,
    width: '100%',
    cursor: 'pointer',
    border: '1px solid transparent',
    '&.start-date': {
      borderTopLeftRadius: `${theme.shape.borderRadius * 2}px !important`,
      borderBottomLeftRadius: `${theme.shape.borderRadius * 2}px !important`,
    },
    '&.end-date': {
      borderTopRightRadius: `${theme.shape.borderRadius * 2}px !important`,
      borderBottomRightRadius: `${theme.shape.borderRadius * 2}px !important`,
    },
    '&:focus': {
      outline: 'none',
    },
  };
  const innerDay = {
    width: 32,
    height: 32,
    padding: 6,
    textAlign: 'center',
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius * 2,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.today': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.partialMonth': {
      color: theme.palette.text.secondary,
    },
    '&.hidden': {
      color: 'transparent !important',
    },
  };
  return ({
    monthContainer: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr',
      rowGap: '8px',
      '& > :nth-child(7n)': {
        overflow: 'hidden',
        borderTopRightRadius: `${theme.shape.borderRadius * 2}px`,
        borderBottomRightRadius: `${theme.shape.borderRadius * 2}px`,
      },
      '& > :nth-child(7n + 1)': {
        overflow: 'hidden',
        borderTopLeftRadius: `${theme.shape.borderRadius * 2}px`,
        borderBottomLeftRadius: `${theme.shape.borderRadius * 2}px`,
      },
    },
    dayBase: {
      width: 32,
      height: 32,
      padding: 6,
      color: theme.palette.greyScaleDeprecated[4],
    },
    outerDay,
    outerDayDisabled: {
      backgroundColor: theme.palette.background.default,
      padding: 0,
      width: '100%',
      border: '1px solid transparent',
    },
    innerDayDisabled: {
      width: 32,
      height: 32,
      padding: 6,
      textAlign: 'center',
      ...theme.typography.body2,
      color: theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius * 2,
    },
    innerDay,
    dayHover: {
      ...outerDay,
      backgroundColor: theme.palette.background.primary,
    },
    daySelected: {
      ...innerDay,
      background: `${theme.palette.primary.main} !important`,
      color: theme.palette.greyScaleDeprecated[7],
      borderRadius: theme.shape.borderRadius * 2,
    },
    dayOfTheWeek: {
      width: 33,
      height: 32,
      padding: 6,
      textAlign: 'center',
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    },
    monthContainerDiv: {
      minHeight: 315,
      height: 315,
      maxHeight: 315,
    },
    datesContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingBottom: 12,
      borderBottom: `1px solid ${theme.palette.border.primary}`,
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      '&.center': {
        alignItems: 'center',
      },
    },
    startDate: {
      display: 'flex',
      flexDirection: 'column',
      '&.center': {
        alignItems: 'center',
      },
      flex: 1,
      background: 'skyblue',
    },
    startDateRange: {
      display: 'flex',
      flexDirection: 'column',
      '&.center': {
        alignItems: 'center',
      },
      flex: 1,
      background: 'skyblue',
      borderRight: `2px solid ${theme.palette.greyScaleDeprecated[4]}`,
      marginRight: 15,
    },
    endDate: {
      display: 'flex',
      flexDirection: 'column',
      '&.center': {
        alignItems: 'center',
      },
      flex: 1,
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      paddingTop: 15,
      marginTop: 10,
      borderTop: `1px solid ${theme.palette.border.primary}`,
    },
    monthsContainer: {
      display: 'flex',
    },
    root: {
      padding: 16,
      borderRadius: 8,
      boxShadow: theme.shadows[5],
      background: theme.palette.greyScaleDeprecated[7],
      zIndex: 5000,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    monthControlContainer: {
      position: 'relative',
      width: '100%',
      marginTop: 10,
      '& > :first-child': {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    clearDate: {
      background: theme.palette.primary.main,
      color: theme.palette.greyScaleDeprecated[7],
      marginLeft: 5,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    clearDateIcon: {
      fontSize: '1rem',
      padding: 2,
    },
    monthChangeButton: {
      all: 'unset',
      border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
      color: theme.palette.greyScaleDeprecated[1],
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
      transition: '0.2s',
      '&:hover': {
        background: theme.palette.greyScaleDeprecated[6],
      },
    },
    monthChangeButtonDisabled: {
      all: 'unset',
      border: 'none',
      color: theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      transition: '0.2s',
    },
    dash: {
      margin: 8,
      marginBottom: 0,
    },
  });
});

// ======================================================================================================== //
//                                              CalendarDay                                                 //
// ======================================================================================================== //

const CalendarDay = (props) => {
  const { startDate, endDate, dateISO, hoverDate, onMouseEnter, onClick, partialMonth, showPartialMonthDays, disabled } = props;
  const day = DateTime.fromISO(dateISO);
  const { classes, cx } = useStyles();

  const includeInHover = endDate ? (startDate && day >= startDate && day <= endDate) : (startDate && day >= startDate && day <= hoverDate);
  const isStartDate = (startDate && startDate.equals(day));
  const isEndDate = (endDate && endDate.equals(day));
  const isHoverDate = (hoverDate && hoverDate.equals(day));
  const isToday = DateTime.local().startOf('day').equals(day);

  const handleHover = useCallback(() => {
    startDate && onMouseEnter && onMouseEnter(dateISO);
  }, [startDate, onMouseEnter, dateISO]);

  const handleClick = useCallback(() => {
    onClick && onClick(dateISO);
  }, [onClick, dateISO]);

  if (partialMonth && !showPartialMonthDays) {
    return (<div className={classes.dayBase} />);
  }

  return (
    <button
      disabled={disabled}
      type="button"
      className={disabled ?
        classes.outerDayDisabled
        :
        cx(
          includeInHover ? classes.dayHover : classes.outerDay,
          isStartDate ? 'start-date' : '',
          isEndDate || (!endDate && isHoverDate) ? 'end-date' : '',
          isToday ? 'today' : '',
        )}
      onMouseEnter={handleHover}
      onClick={handleClick}
    >
      <div
        className={disabled ?
          classes.innerDayDisabled
          :
          cx(
            isStartDate || isEndDate || (!endDate && isHoverDate) ? classes.daySelected : classes.innerDay,
            isToday ? 'today' : '',
            partialMonth && !isStartDate && !isEndDate && showPartialMonthDays ? 'partialMonth' : '',
          )}
      >
        {day.day}
      </div>
    </button>
  );
};
CalendarDay.propTypes = {
  dateISO: PropTypes.string.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  hoverDate: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onClick: PropTypes.func,
  showPartialMonthDays: PropTypes.bool,
  partialMonth: PropTypes.bool,
  disabled: PropTypes.bool,
};

// ======================================================================================================== //
//                                              MonthView                                                   //
// ======================================================================================================== //

const MonthView = (props) => {
  const { hoverDate, month, startDate, endDate, onClickDay, onHoverDay, showPartialMonthDays, disableFutureDates } = props;

  const { classes } = useStyles();

  const newDays = useMemo(() => {
    const numPrevDays = month.weekday === 7 ? 0 : month.weekday;
    const retDays = [];
    let dayTicker = month.startOf('month').minus({ days: numPrevDays });
    const numPostDays = (numPrevDays + month.daysInMonth) % 7 === 0 ? 0 : 7 - ((numPrevDays + month.daysInMonth) % 7);
    const totalDays = numPrevDays + month.daysInMonth + numPostDays;
    for (let i = 0; i < totalDays; i++) {
      let disableDay = false;
      if (disableFutureDates) {
        if (DateTime.local().startOf('day') < dayTicker.startOf('day')) {
          disableDay = true;
        }
      }

      const tickerISO = dayTicker.toISO();
      retDays.push(
        <CalendarDay
          disabled={disableDay}
          dateISO={tickerISO}
          onClick={onClickDay}
          onMouseEnter={onHoverDay}
          startDate={startDate}
          endDate={endDate}
          hoverDate={hoverDate}
          key={`date-picker-day-${tickerISO}`}
          partialMonth={i < numPrevDays || i >= (numPrevDays + month.daysInMonth)}
          showPartialMonthDays={showPartialMonthDays}
        />
      );
      dayTicker = dayTicker.plus({ days: 1 });
    }
    return retDays;
  }, [month, disableFutureDates, onClickDay, onHoverDay, startDate, endDate, hoverDate, showPartialMonthDays]);
  return (
    <div className={classes.monthContainerDiv}>
      <Typography align="center" variant="subtitle1">{`${month.monthLong} ${month.year}`}</Typography>
      <div className={classes.monthContainer}>
        <div className={classes.dayOfTheWeek}>Su</div>
        <div className={classes.dayOfTheWeek}>Mo</div>
        <div className={classes.dayOfTheWeek}>Tu</div>
        <div className={classes.dayOfTheWeek}>We</div>
        <div className={classes.dayOfTheWeek}>Th</div>
        <div className={classes.dayOfTheWeek}>Fr</div>
        <div className={classes.dayOfTheWeek}>Sa</div>
        {newDays}
      </div>
    </div>
  );
};
MonthView.propTypes = {
  month: PropTypes.object.isRequired,
  hoverDate: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onClickDay: PropTypes.func,
  onHoverDay: PropTypes.func,
  showPartialMonthDays: PropTypes.bool,
  disableFutureDates: PropTypes.bool,
};

// ======================================================================================================== //
//                                              DatePicker                                                  //
// ======================================================================================================== //

const DateRangePicker = (props) => {
  const { onSelect, anchorEl, onClose, range, popoverProps, showPartialMonthDays, id, startingDate, endingDate, disableFutureDates } = props;

  const [startDate, setStartDate] = useState(startingDate || null);
  const [endDate, setEndDate] = useState(endingDate || null);
  const [activeMonth, setActiveMonth] = useState(DateTime.local().startOf('month'));
  const [nextActiveMonth, setNextActiveMonth] = useState(DateTime.local().plus({ months: 1 }).startOf('month'));
  const [hoverDate, setHoverDate] = useState(null);

  const rangeFocus = startDate?.isValid ? 'end' : 'start';

  const { classes } = useStyles();

  const handleClickDay = (dayISO) => {
    const day = DateTime.fromISO(dayISO);
    if (!range) {
      onSelect(day);
    } else if (rangeFocus === 'start') {
      if (endDate && (day > endDate)) {
        setStartDate(endDate);
        setEndDate(day);
      } else {
        setStartDate(day);
      }
    } else if (rangeFocus === 'end') {
      if (day < startDate) {
        setStartDate(day);
      } else {
        setEndDate(day);
      }
    }
  };

  const handleHoverDay = useCallback((hoveredISO) => {
    setHoverDate(hoveredISO ? DateTime.fromISO(hoveredISO) : null);
  }, []);

  const handleSelect = useCallback((_e) => {
    const finalRange = Interval.fromDateTimes(startDate, endDate.endOf('day'));
    onSelect(finalRange);
  }, [onSelect, startDate, endDate]);

  const handleMonthChange = (increment) => (_e) => {
    setActiveMonth(activeMonth.plus({ months: increment }).startOf('month'));
    if (range) setNextActiveMonth(nextActiveMonth.plus({ months: increment }).startOf('month'));
  };

  const handleInputChange = (focus) => (date) => {
    if (focus === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleInputFocus = (event) => event.target.select();

  const handleClearDate = (focus) => (e) => {
    e?.stopPropagation();
    if (focus === 'start') {
      setStartDate(null);
    } else if (focus === 'end') {
      setEndDate(null);
    }
  };

  const keyboardButtonProps = useMemo(() => ({ classes: { root: classes.clearDateIcon } }), [classes.clearDateIcon]);

  const disableRightArrow = useMemo(() => DateTime.local().startOf('month').equals(activeMonth) && disableFutureDates, [activeMonth, disableFutureDates]);

  useEffect(() => {
    if (!startingDate && !endingDate) {
      setStartDate(null);
      setEndDate(null);
      setHoverDate(null);
    }
  }, [id, startingDate, endingDate]);

  useEffect(() => {
    setHoverDate(null);
  }, [anchorEl]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...popoverProps}
      id={id || 'date-range-picker'}
    >
      <div sharedcomponentid={'DATE_RANGE_PICKER'} className={classes.root}>
        {range &&
          <div style={{ width: '100%' }}>
            <Typography variant={'caption'}>Custom</Typography>
            <div className={classes.datesContainer}>
              <SDatePicker
                id="date-range-picker-start"
                value={startDate}
                onChange={handleInputChange('start')}
                label={'Start date'}
                textFieldProps={{
                  onFocus: handleInputFocus,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment
                        position="end"
                      >
                        <IconButton
                          id={'clear-start-date'}
                          aria-label="clear start date"
                          onClick={handleClearDate('start')}
                          {...keyboardButtonProps}
                        >
                          <ClearIcon fontSize={'inherit'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                disableOpenPicker
              />
              <Typography className={classes.dash} variant={'body2'}> - </Typography>
              <SDatePicker
                id="date-range-picker-end"
                value={endDate}
                onChange={handleInputChange('end')}
                label={'End date'}
                textFieldProps={{
                  onFocus: handleInputFocus,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment
                        position="end"
                      >
                        <IconButton
                          id={'clear-end-date'}
                          aria-label="clear end date"
                          onClick={handleClearDate('end')}
                          {...keyboardButtonProps}
                        >
                          <ClearIcon fontSize={'inherit'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                minDate={startDate?.isValid ? startDate : undefined}
                disableOpenPicker
              />
            </div>
          </div>}

        <div className={classes.monthControlContainer}>
          <div>
            <button type="button" onClick={handleMonthChange(-1)} className={classes.monthChangeButton}>
              <ChevronLeftIcon />
            </button>
            <button disabled={disableRightArrow} type="button" onClick={handleMonthChange(1)} className={disableRightArrow ? classes.monthChangeButtonDisabled : classes.monthChangeButton}>
              <ChevronRightIcon />
            </button>
          </div>
        </div>

        <div className={classes.monthsContainer}>
          <MonthView
            disableFutureDates={disableFutureDates}
            hoverDate={hoverDate}
            month={activeMonth}
            startDate={startDate}
            endDate={endDate}
            onClickDay={handleClickDay}
            onHoverDay={handleHoverDay}
            range={range}
            showPartialMonthDays={showPartialMonthDays}
          />
          {range &&
            <>
              <div style={{ width: 30 }} />
              <MonthView
                disableFutureDates={disableFutureDates}
                hoverDate={hoverDate}
                month={nextActiveMonth}
                startDate={startDate}
                endDate={endDate}
                onClickDay={handleClickDay}
                onHoverDay={handleHoverDay}
                range={range}
                showPartialMonthDays={showPartialMonthDays}
              />
            </>}
        </div>
        {range &&
          <div className={classes.footer}>
            <QButton variant="outlined" onClick={onClose}>cancel</QButton>
            <div style={{ width: 12 }} />
            <QButton
              variant="contained"
              disabled={!(startDate?.isValid) || !(endDate?.isValid) || startDate > endDate}
              onClick={handleSelect}
            >
              Apply Dates
            </QButton>
          </div>}
      </div>
    </Popover>
  );
};
DateRangePicker.defaultProps = {
  format: 'MM/dd/yyyy',
};

DateRangePicker.propTypes = {
  anchorEl: PropTypes.object,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  range: PropTypes.bool,
  popoverProps: PropTypes.object,
  startPlaceholder: PropTypes.string,
  endPlaceholder: PropTypes.string,
  format: PropTypes.string,
  showPartialMonthDays: PropTypes.bool,
  id: PropTypes.string,
  startingDate: PropTypes.object,
  endingDate: PropTypes.object,
  disableFutureDates: PropTypes.bool,
};
export default DateRangePicker;

