
import styled from 'styled-components';
import { isAcme } from 'isAcme';
import { sharedStyles } from '../sharedStyles';

export const RegHeader = styled.div`
  margin-top: 0px;
  transition: height 1s ease;
`;

export const HdrListItem = styled.div`
`;

export const ColumnField = styled.div`
  display: inline-block;
  font-weight: 500;
`;

export const styles = (theme) => ({

  ...sharedStyles(theme),

  regColumnsHeader: {
    display: 'flex',
    alignItems: 'stretch',
    fontWeight: 500,
    color: theme.palette.greyScaleDeprecated[0],
    paddingTop: 8,
    paddingBottom: 5,
    background: isAcme ? theme.palette.background.paper : theme.palette.greyScaleDeprecated[7],
    paddingLeft: theme.components.register.rowInnerPadding,
    paddingRight: theme.components.register.rowInnerPadding,
    borderBottom: 'solid 1px',
    borderBottomColor: theme.components.register.headerBorder,
    width: '100%',
    '&::-webkit-scrollbar-button': {
      display: 'none',
      color: '#fafaf9',
      opacity: 0,
    },
    overflowX: 'hidden',
  },

  regIcon: {
    color: theme.palette.text.primary,
  },
  nop: {
    display: 'none',
  },
  regColumnsHeaderWrapper: {
    background: theme.components.register.headerBackground,
    paddingLeft: theme.components.register.rowInnerPadding,
    paddingRight: theme.components.register.rowInnerPadding,
  },
  inserting: {
    borderLeft: 'solid 2px #333',
    borderRadius: 0,
  },
  reviewedIcon: {
    height: 20,
    width: 20,
    color: theme.palette.greyScaleDeprecated[3],
    cursor: 'pointer',
    '&.reviewed': {
      color: theme.palette.green1,
    },
    '&.notreviewed': {
      color: theme.palette.gray5,
    },
  },

});
