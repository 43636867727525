import { stringToColor } from 'containers/App/Header/ProfileMenu/utils';
import { applyOpacityToHex } from 'themes/themeUtils';

export const stringAvatar = (name: string) => {
  const nameParts = name.split(' ');
  return {
    sx: {
      bgcolor: applyOpacityToHex(stringToColor(name), 0.6),
      fontSize: 16,
      width: 45, 
      height: 45,
      color: 'shadowGrey',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    children: nameParts.length > 1 
      ? `${nameParts[0][0]}${nameParts[1][0]}` 
      : `${nameParts[0][0]}`,
  };
};

export const membersOptions = [{
  label: 'Resend Invitation',
  action: 'resend',
  types: ['INVITED', 'ABANDONED'],
  eventName: 'resendInvitation', 
}, {
  label: 'Revoke Invitation',
  action: 'revoke',
  types: ['INVITED'],
  eventName: 'revokeInvitation',
}, {
  label: 'Remove Member',
  action: 'remove',
  types: ['ACCEPTED', 'ACTIVE', 'ABANDONED'],
  eventName: 'removeAccess',
}];

export const capitalizeString = (str: string) => str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
