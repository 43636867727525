
// Help Functional Components
import { HelpReviewed } from './components/helpReviewed';
import { UpgradeVersion } from './components/upgradeVersion';



// ADD NEW HELP COMPONENTS HERE.  IN THE FUTURE, WE WILL ALSO
// PROVIDE A WAY TO BROWSE ALL HELP COMPONENTS
//
export const helpMap = {
  windowsReviewed: {
    component: HelpReviewed,
    title: 'Review your downloaded transactions from the web',
    props: {},
    infoUrl: null,
    maxWidth: 900,
  },
  upgradeVersion: {
    component: UpgradeVersion,
    title: 'Update Quicken desktop for the best web experience',
    props: { clientType: 'WIN', sku: 'Premier', releaseNumber: 'R99' },
    infoUrl: null,
    maxWidth: 600,
    btns: [
      {
        label: 'Exit Quicken Web',
        action: () => window.location.replace('/logout'),
      },
      {
        label: 'Use Now Anyway',
        action: null,
      },
    ],
  },
};
