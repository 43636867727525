import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Map as ImmutableMap } from 'immutable';

export const DIALOG_TYPE = 'WF_BUDGET_SETUP_ACTION';

export const doWFBudgetSetup = createAction(
  createDialog.toString(),
  (id) => mkRootUiData({
    type: DIALOG_TYPE,
    allowNesting: true,
    props: ImmutableMap({
      id,
      title: '',
    }),
  })
);
