
import preferencesReducer from 'companion-app-components/flux/preferences/reducer';

import { initialPreferencesState } from './types';

const localReducer = (state = initialPreferencesState) => state;

const reducerMerged = (state, action) => {
  const state1 = localReducer(state, action);
  const state2 = preferencesReducer(state1, action);
  return state2;
};

export default reducerMerged;
