// import { getLogger } from 'utils/logger';
// import { createSelector } from 'reselect';
import { CATEGORY_GROUP_LISTS_REDUCER_KEY } from './categoryGroupListsReducer';

export const getStore = (state) => state[CATEGORY_GROUP_LISTS_REDUCER_KEY];

export const getLoadPending = (state) => getStore(state).loadPending;

export const getIsLoading = (state) => getStore(state).isLoading;

export const getLastSyncDate = (state) => getStore(state).lastSyncDate;

export const getCategoryGroupLists = (state) => getStore(state).resourcesById;

