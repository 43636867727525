import { isEqualWith } from 'lodash';
import { handleActions, combineActions } from 'redux-actions';

import { coreActions } from 'companion-app-components/flux/core-actions';
import { transactionsActions as actions, transactionsReducer as transactionsReducerBit } from 'companion-app-components/flux/transactions';
import { getLogger } from 'companion-app-components/utils/core';

import { initReductions, addReduction, clearReductions } from 'data/optimalCacheManager';

import { TransactionStore } from './types';

const log = getLogger('data/transactions/reducer.js');

let previousState;

const transactionsReducerPre = handleActions({}, TransactionStore({ resourceName: 'TRANSACTIONS' }));

// compareFunc that ignores 'modifiedAt' to prevent unnecessary updates and also treats null & undefined as equivalent
const txnCompare = (val1, val2, key) => (key === 'modifiedAt' || (!val1 && !val2)) ? true : undefined;

const handleReductions = (state, transactions) => {
  let newState = state;

  // If the UPSERT is more than 10 transactions, then don't optimize, but force selectors to recalculate
  const doOptimize = (transactions?.length <= 10);
  if (doOptimize) {
    // Initialize the reduction using optimalCacheManager
    const upsertId = new Date().valueOf();
    newState = initReductions(newState, upsertId);
    transactions.forEach((transaction) => {
      const oldTransaction = previousState?.resourcesById.get(transaction.id || transaction.clientId);
      const newTransaction = transaction.isDeleted ? transaction : newState.resourcesById.get(transaction.id || transaction.clientId);

      if (!isEqualWith(oldTransaction?.toJS(), newTransaction?.toJS?.(), txnCompare)) {
        log.debug('\nnew txn data: SUBMITTING UPSERT');
        newState = addReduction(newState, oldTransaction, newTransaction, upsertId);
      } else {
        log.debug('\nequivalent txn: SKIPPING UPSERT');
      }
    });
  } else {
    newState = clearReductions(newState);
  }

  return newState;
};

const transactionsReducerPost = handleActions(
  {
    [coreActions.sanitize]: (state) =>
      state.set('lastReductions', initReductions(state.set('lastReductions', null)).lastReductions), // TODO: init lastReductions in Record prototype

    [combineActions(
      actions.batchTransactions,
      actions.batchTransactionsSuccess,
      actions.applyTransactionsChanges,
      actions.performTransactionActionSuccess,
    )]: (state, action) => handleReductions(state, action.payload),

    [combineActions(
      actions.createTransaction,
      actions.createTransactionSuccess,
      actions.updateTransaction,
      actions.updateTransactionSuccess,
      actions.deleteTransaction,
      actions.deleteTransactionSuccess,
    )]: (state, action) => handleReductions(state, [action.payload]),

    [actions.getTransactionsSuccess]: (state, { payload }) => handleReductions(state, payload.resources),
  },
  TransactionStore({ resourceName: 'TRANSACTIONS' }),
);

// chain reducers
export const reducer = (state, action) => {
  let newState = state;
  previousState = state;
  newState = transactionsReducerPre(newState, action);
  newState = transactionsReducerBit(newState, action);
  newState = transactionsReducerPost(newState, action);
  return newState;
};

export default reducer;
