
export const styles = (theme) => ({

  root: {
  },
  tab: {
    position: 'relative',
    zIndex: 1,
    whiteSpace: 'nowrap',
    display: 'inline-block',
    color: theme.components.categoryCard.tabTextColor,
  },
  innerTab: {
    position: 'relative',
    display: 'inline-block',
    padding: '7px 29px 8px',
    color: 'inherit',
    textDecoration: 'none',
    margin: '-7px -1px',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500,
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: '.5em',
      zIndex: -1,
      borderBottom: 'none',
      borderRadius: '5px 15x 0px 0px',
      background: theme.components.categoryCard.tabBackground,
      boxShadow: '0 2px hsla(0,0%,100%,.5) inset',
      transform: 'perspective(3px) rotateX(2deg)',
      transformOrigin: 'bottom',
    },
    '&.selected': {
      color: theme.components.categoryCard.tabTextColorSelected,
      '&::before': {
        background: theme.components.categoryCard.tabBackgroundSelected,
        borderColor: '#ddd',
      },
    },
    closeIcon: {
      color: '#red',
    },
  },
});

