import React, { useState, useEffect } from 'react';
import { usePrevious } from 'react-use';

import QTip from 'components/QuickenControls/QTip';
import QTypography from 'components/MUIWrappers/QTypography';

import PayeeFieldEditable from './PayeeFieldEditable';

interface PayeeFieldProps {
  editable: boolean;
  value: Record<string, any>;
  clickable: boolean;
  classNameWhenNotEditable: string;
  tooltip: boolean;
}

type PayeeFieldState = {
  value: Record<string, any>;
};

const PayeeField: React.FC<PayeeFieldProps> = (props) => {
  const [payeeFieldState, setPayeeFieldState] = useState<PayeeFieldState>({
    value: props.value,
  });

  const prevPayee = usePrevious(props.value.payee);

  useEffect(() => {
    if (props.value.payee !== prevPayee) {
      setPayeeFieldState((prevState) => ({
        ...prevState,
        value: props.value,
      }));
    }
  }, [props.value, prevPayee]);

  return (
    <>
      {props.editable && <PayeeFieldEditable {...props} />}
      {!props.editable &&
        <QTip
          wrapOnly
          title={props.tooltip ? payeeFieldState.value.payee : null}
        >
          <QTypography
            variant="body2"
            clickable={props.clickable}
            thinFont
            className={props.classNameWhenNotEditable}
          >
            {payeeFieldState.value.payee}
          </QTypography>
        </QTip>}
    </>
  );
};

export default PayeeField;
