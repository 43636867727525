// @flow
import { List, Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

type FormDialogPropsProps = {
  title: string,
  content: string,
  label: string,
  isPassword: boolean,
  buttons: List<string>,
  onClose: ?() => void,
}
export type FormDialogProps = RecordOf<FormDialogPropsProps>;
export const mkFormDialogProps: RecordFactory<FormDialogPropsProps> =
  Record({
    title: 'Alert',
    content: '',
    label: '',
    isPassword: false,
    buttons: List(['Yes', 'No']),
    onClose: null,
  });

export const DIALOG_TYPE = 'DIALOG_FORM';
