
// CORE
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';

import { scheduledTransactionsUtils, scheduledTransactionsActions } from 'companion-app-components/flux/scheduled-transactions';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowForwardIcon from '@mui/icons-material/ChevronRightRounded';
import MenuButton from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/CompareArrowsRounded';
import UnlinkIcon from '@mui/icons-material/LinkOffRounded';
import AddIcon from '@mui/icons-material/Add';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';

// Custom
import SelectTransactionDialog from 'components/Dialogs/SelectTransactionDialog';


const useStyles = makeStyles()((theme) => ({
  // root spacing
  row: {
    height: 78,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '8px 0',
    backgroundColor: theme.palette.greyScaleDeprecated[6],
    borderRadius: 8,
    padding: 16,
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `0 1px 3px 0 ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.2)}`,
    },
    '&:focus': {
      boxShadow: `0 1px 3px 0 ${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[0], 0.2)}`,
    },
  },
  radioRow: {
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: theme.palette.color7.opacity30,
    },
    '&:focus': {
      backgroundColor: theme.palette.color7.opacity30,
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    '&:hover': {
      backgroundColor: theme.palette.greyScaleDeprecated[5],
    },
    '&:focus': {
      backgroundColor: theme.palette.greyScaleDeprecated[5],
    },
  },
  connectedRow: {
    cursor: 'default',
    height: 92,
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    border: `1px solid ${theme.palette.greyScaleDeprecated[4]}`,
    '&:hover': {
      border: `1px solid ${theme.palette.greyScaleDeprecated[3]}`,
    },
  },

  radio: {
    marginRight: 16,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },

  selectedChip: {
    height: 62,
    maxWidth: 200,
    padding: '8px 16px',
    margin: '12px 8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.greyScaleDeprecated[6],
    borderRadius: 8,
  },
  blueText: {
    padding: '2px 8px',
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.link.main,
    borderRadius: 4,
  },

  itemLine: {
    height: 48,
    width: 172,
  },
  menuIcon: {
    marginRight: 16,
  },
  red: {
    color: theme.palette.number.negative,
  },
  lineBreak: {
    width: 'calc(100% - 16px)',
    height: 1,
    backgroundColor: theme.palette.greyScaleDeprecated[4],
    margin: '0 8px',
  },

  menuItem: {
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  fullWidth: {
    width: '100%',
  },
  addIcon: {
    width: 19,
    height: 19,
    padding: 1,
    position: 'absolute',
    top: 12,
    right: 16,
    backgroundColor: theme.palette.greyScaleDeprecated[0],
    color: theme.palette.greyScaleDeprecated[7],
    borderRadius: 4,
  },
  addLine: {
    paddingTop: 6,
    width: '100%',
  },
}));

const anchorOrigin = {
  horizontal: 'right',
  vertical: 'center',
};
const transformOrigin = {
  vertical: 'center',
  horizontal: 'center',
};



// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Main Component                     - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function AccountRow(props) {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const { account, models, selectedModel, updateAccountMap, radio, staged, setStagedAccount, setEditAccount } = props;

  const [anchorEl, setAnchorEl] = useState(null); // text-field reference set onClick
  const [showSelect, setShowSelect] = useState(false); // either false, true, or the client of the model ID that was created

  useEffect(() => {
    if (showSelect) {
      const createdModel = models.find((model) => model.clientId === showSelect);
      if (createdModel) {
        selectItem(createdModel);
        setShowSelect(false);
      }
    }
  }, [models]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => setAnchorEl(null);

  const selectItem = (model) => {
    updateAccountMap(account.id, model);
    handleClose();
  };

  const edit = (e) => {
    e.stopPropagation();
    setEditAccount(account);
  };
  const editKey = (e) => {
    if (e.key === 'Escape') {
      e.target.blur();
      e.stopPropagation();
    }
    if (e.key === 'Enter') {
      setEditAccount(account);
    }
  };

  const unlink = (e) => {
    e.stopPropagation();
    updateAccountMap(account.id, null);
  };
  const unlinkKey = (e) => {
    if (e.key === 'Escape') {
      e.target.blur();
      e.stopPropagation();
    }
    if (e.key === 'Enter') {
      updateAccountMap(account.id, null);
    }
  };

  const startSelect = () => setShowSelect(true);
  const closeSelect = () => setShowSelect(false);

  const selectTransaction = (transaction) => {
    const clientId = uuidv4().toUpperCase();
    if (transaction && transaction.id) {
      dispatch(scheduledTransactionsActions.createBillScoutSchedule({ clientId, id: transaction.id }));
    }
    setShowSelect(clientId);
    setTimeout(() => setShowSelect(false), 3000);
  };

  const handleClick = (e) => {
    if (radio) {
      setStagedAccount?.(account);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };
  const handleKeyDown = (e) => {
    // if (selectedModel) return;
    if (e.key === 'Escape') {
      e.target.blur();
      e.stopPropagation();
    }
    if (e.key === 'Enter') {
      if (radio) {
        setStagedAccount?.(account);
      } else {
        setAnchorEl(e.currentTarget);
      }
    }
  };


  const stopEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const RenderElement = selectedModel ? 'div' : ButtonBase;
  const elementProps = selectedModel ? {} : {
    disableRipple: !radio,
    focusRipple: radio,
    onClick: handleClick,
    onKeyDown: handleKeyDown,
    tabIndex: 0,
  };

  // ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
  // ### - - -                     Main render                     - - - ###
  // ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
  return (
    <RenderElement
      className={cx(classes.row, radio && classes.radioRow, staged && classes.selectedRow, Boolean(selectedModel) && classes.connectedRow)}
      {...elementProps}
    >
      {radio &&
        <Radio
          checked={staged}
          value={account}
          name={`radio-button-${account.name}`}
          tabIndex={-1}
          className={classes.radio}
        />}

      <div className={classes.column}>
        <Typography variant="subtitle1" noWrap>
          {account.nickName || 'nickname'}
        </Typography>

        <Typography variant="body2" className={classes.subtitle} noWrap>
          {account.name}
        </Typography>
      </div>

      {!radio && (selectedModel ?
        <>
          {/* Selected chip */}
          <div className={classes.selectedChip}>
            <Typography variant="body2" noWrap className={classes.fullWidth}>
              Series: {selectedModel?.transaction?.payee || 'Unknown'}
            </Typography>

            <Typography variant="caption" className={classes.blueText}>
              Connected
            </Typography>

          </div>

          {/* 3-dot menu */}
          <IconButton onClick={handleClick} onKeyDown={handleKeyDown} size="large">
            <MenuButton />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(anchorEl)}
          >
            {/* edit */}
            <MenuItem
              onClick={edit}
              onKeyDown={editKey}
              id="edit-option"
              className={classes.itemLine}
            >
              <EditIcon className={classes.menuIcon} />
              <Typography>
                Edit details
              </Typography>
            </MenuItem>

            <div className={classes.lineBreak} />

            {/* unlink */}
            <MenuItem
              onClick={unlink}
              onKeyDown={unlinkKey}
              id="unlink-option"
              className={cx(classes.itemLine, classes.red)}
            >
              <UnlinkIcon className={classes.menuIcon} />
              <Typography className={classes.red}>
                Unlink
              </Typography>
            </MenuItem>
          </Menu>
        </>
        :
        <>
          {/* forward arrow */}
          <ArrowForwardIcon />

          <Menu
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(anchorEl)}
            onClick={stopEvent}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            getContentAnchorEl={undefined}
          >
            {models?.toIndexedSeq().map((model) => (
              <MenuItem key={model.id} className={classes.menuItem} onClick={() => selectItem(model)}>
                <Typography variant="subtitle2" noWrap className={classes.fullWidth}>
                  {model.transaction?.payee}
                </Typography>
                <Typography variant="caption" noWrap>
                  {scheduledTransactionsUtils.frequencyAliasFromRecurrence(model.recurrence)}
                </Typography>
              </MenuItem>
            ))}

            <div className={classes.lineBreak} />

            <MenuItem className={classes.menuItem} onClick={startSelect}>
              <Typography variant="caption" noWrap className={classes.addLine}>
                {'Don\'t find your bill?'}
              </Typography>
              <Typography variant="caption" noWrap>
                {'Create new from transaction'}
              </Typography>

              <AddIcon className={classes.addIcon} />
            </MenuItem>
          </Menu>
        </>)}

      {showSelect &&
        <SelectTransactionDialog
          open={Boolean(showSelect)}
          onClose={closeSelect}
          onTransactionSelected={selectTransaction}
          disableAdd
        />}
    </RenderElement>
  );
}

AccountRow.defaultProps = {
  account: {},
  staged: false,
};

AccountRow.propTypes = {
  account: PropTypes.object,
  models: PropTypes.object,
  selectedModel: PropTypes.object,
  updateAccountMap: PropTypes.func,
  staged: PropTypes.bool,
  setStagedAccount: PropTypes.func,
  radio: PropTypes.bool,
  setEditAccount: PropTypes.func,
};

export default AccountRow;
