import { takeEvery, select, call } from 'redux-saga/effects';

import { resourceStoreTypes, resourceSagaUtils } from 'companion-app-components/flux/core';
import { authSelectors } from 'companion-app-components/flux/auth';
import { localPreferences } from 'companion-app-components/utils/core';

import * as actions from './mcRulesActions';
import { mkMCRulesOperands, JobStatusEnum, mkMCRuleMessage } from './mcRulesTypes';
import * as mcRulesSelectors from './mcRulesSelectors';

const mkResourceConfig = (props) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'MC Rules',
  resourceBaseUrl: '/mc-rules',
  ...props,
});

function* mcRulesExecuteActionWatcher() {
  yield takeEvery(actions.mcRulesExecute, mcRulesExecute);
}
function* mcRulesExecute(action) {
  const authSession = yield select(authSelectors.getAuthSession);
  const mcpTestModeItem = localPreferences.getMCPTestMode();
  const config = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'MC Rules',
    resourceBaseUrl: `/mc-rules/execute${mcpTestModeItem === 'true' ? '?query=test==true' : ''}`,
    successAction: actions.mcRulesExecuteSuccess,
    failureAction: actions.mcRulesExecuteFailure,
    transformResourceWithResponse: (resource, response) => mkMCRulesOperands(response && response.data),
    axiosConfig: {
      headers: {
        'qcs-user-id': authSession && authSession.qcsId,
      },
    },
  });
  yield call(resourceSagaUtils.qcsSyncCreateResource, config, action);
}

function* mcRulesExecuteSuccessActionWatcher() {
  yield takeEvery(actions.mcRulesExecuteSuccess, mcRulesExecuteSuccessAction);
}
function* mcRulesExecuteSuccessAction(action) {
  if (action.payload && action.payload.jobId) {
    const dataItems = yield select(mcRulesSelectors.fetchDataItemsForOperands, action.payload.operands);
    const config = mkResourceConfig({
      resourceBaseUrl: `/mc-rules/execution-jobs/${action.payload.jobId}/submit-data`,
      successAction: actions.mcRulesSubmitDataSuccess,
      failureAction: actions.mcRulesSubmitDataFailure,
      transformResourceWithResponse: (resource, response) => mkMCRulesOperands(response && response.data),
    });
    const authSession = yield select(authSelectors.getAuthSession);
    yield call(resourceSagaUtils.qcsSyncCreateResource, config, actions.mcRulesSubmitData({ dataItems }, {
      axiosConfig: {
        headers: {
          'qcs-user-id': authSession && authSession.qcsId,
        },
      },
    }));
  }
}

function* mcRulesSubmitDataSuccessActionWatcher() {
  yield takeEvery(actions.mcRulesSubmitDataSuccess, mcRulesSubmitDataSuccess);
}
function* mcRulesSubmitDataSuccess(action) {
  if (action.payload && action.payload.jobId) {
    const config = mkResourceConfig({
      resourceBaseUrl: `/mc-rules/execution-jobs/${action.payload.jobId}/poll`,
      successAction: actions.mcRulesPollSuccess,
      failureAction: actions.mcRulesPollFailure,
      pollConfig: resourceStoreTypes.mkPollConfig({
        isComplete: (response) => response && response.data && (response.data.jobStatus === JobStatusEnum.COMPLETED || response.data.jobStatus === JobStatusEnum.FAILED),
      }),
      transformResponseToResources: (response) => response && response.data && response.data.messages && response.data.messages.map((message) => mkMCRuleMessage(message)),
    });
    yield call(resourceSagaUtils.qcsSyncPollResource, config, actions.mcRulesPoll());
  }
}

function* mcMessagesTrackActionWatcher() {
  yield takeEvery(actions.mcMessagesTrack, mcMessagesTrack);
}
function* mcMessagesTrack(action) {
  const config = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'MC Messages',
    resourceBaseUrl: `/mc-messages/${action.payload && action.payload.id}/track`,
    successAction: actions.mcMessagesTrackSuccess,
    failureAction: actions.mcMessagesTrackFailure,
  });
  yield call(resourceSagaUtils.qcsSyncCreateResource, config, action);
}

export default [
  mcRulesExecuteActionWatcher,
  mcRulesExecuteSuccessActionWatcher,
  mcRulesSubmitDataSuccessActionWatcher,
  mcMessagesTrackActionWatcher,
];
