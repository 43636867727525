// @flow
import { createSelector } from 'reselect';

import { REDUCER_KEY } from './reducer';
import type { RootUiStore } from './types';

export const getRootUiStore = (state: any): RootUiStore => state[REDUCER_KEY];
export const getRootUi = (state: RootUiStore) => getRootUiStore(state);
export const getActiveModals = (state: RootUiStore) => getRootUiStore(state).activeModals;

export const getActiveModalsAsJS = createSelector(
  getActiveModals,
  (activeModals) => activeModals.toJS()
);


