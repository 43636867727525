// @flow
import { combineActions, handleActions } from 'redux-actions';
import { Set, hash } from 'immutable';

import { resourceUpserters, resourceStoreTypes } from 'companion-app-components/flux/core';
import { coreActions } from 'companion-app-components/flux/core-actions';
import type { RefreshResponse } from 'companion-app-components/flux/accounts';

import { accountDiscoveryCredentialsFormSubmitResponse } from 'components/Dialogs/AccountDiscovery/actions';
import { loadedInstitutionLoginsCredentialBlobs, saveInstitutionLoginsCredentialBlobs } from 'data/persist/actions';

import * as actions from './actions';
import { mkInstitutionLoginsStore } from './types';
import type { InstitutionLoginsStore } from './types';

const reducer = handleActions({

  [coreActions.sanitize]: (state) =>
    state.set('resourcesById', state.resourcesById.map((resource) => resource.merge({
      name: hash(resource.name).toString(),
    }))),

  [coreActions.persistDataLoadComplete]: (state: InstitutionLoginsStore) => state.merge({
    isLoading: false,
    isRefreshingAll: false,
    idsRefreshing: Set(),
    idsRefreshingWithUserActionsPending: Set(),
    idsRefreshingWithCancelledUserActions: Set(),
  }),

  [actions.getInstitutionLogins]: (state :InstitutionLoginsStore) => state,

  [actions.getInstitutionLoginsSuccess]:
    (state, { payload: data }) => resourceUpserters.upsertResources(state, data),

  // Called when a CRUD operation is successful on transactions. The success
  // action is triggered by the Sagas.
  [combineActions(
    actions.updateInstitutionLoginSuccess,
    actions.deleteInstitutionLoginSuccess
  )]: (state, { payload: institutionLoginToUpsert }) => resourceUpserters.upsertResource(state, institutionLoginToUpsert),

  [actions.getInstitutionLoginsFailure]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

  [combineActions(
    actions.updateInstitutionLoginFailure,
    actions.deleteInstitutionLoginFailure,
  )]: (state, { payload: error, asyncDispatch }) => {
    asyncDispatch(actions.getInstitutionLogins());
    return resourceUpserters.resetResources(state, error);
  },

  [accountDiscoveryCredentialsFormSubmitResponse]: {
    next(state, { payload: { institutionLogin } }) {
      if (!state.resourcesById.get(institutionLogin.id)) {
        // if this was a new institutionLogin (not an update), add to store
        return state.merge({ resourcesById: state.resourcesById.set(institutionLogin.id, institutionLogin) });
      }
      return state;
    },
  },

  [actions.refreshAccounts]: (state, { payload: data }) => {
    if (data && data.institutionLoginId) {
      return state.set('idsRefreshing', state.idsRefreshing.add(data.institutionLoginId));
    }
    return state.set('isRefreshingAll', true);
  },

  [actions.refreshAccountsStatuses]: (
    state :InstitutionLoginsStore,
    { payload: refreshStatuses, asyncDispatch }: { payload: RefreshResponse, asyncDispatch: any }
  ) => {
    let { credentialBlobsForRefreshingLogins, credentialsForRefreshingLogins } = state;
    refreshStatuses.institutionLogins.forEach((login) => {
      if (login.credentialsBlob) {
        credentialBlobsForRefreshingLogins = credentialBlobsForRefreshingLogins.set(login.id, login.credentialsBlob);
        credentialsForRefreshingLogins = credentialsForRefreshingLogins.delete(login.id);
      }
    });

    if (credentialBlobsForRefreshingLogins !== state.credentialBlobsForRefreshingLogins) {
      // action to persist the credentials blobs to persistent storage
      asyncDispatch(saveInstitutionLoginsCredentialBlobs(credentialBlobsForRefreshingLogins));
    }

    return state.merge({ credentialBlobsForRefreshingLogins, credentialsForRefreshingLogins });
  },

  [actions.refreshAccountsTriggeredMfa]:
    (state: InstitutionLoginsStore, { payload: id }) =>
      state.set('idsRefreshingWithUserActionsPending', state.idsRefreshingWithUserActionsPending.add(id)),

  [actions.refreshAccountsCredentialsProvided]:
    (state: InstitutionLoginsStore, { payload: { institutionLogin } }) => {
      let { credentialsForRefreshingLogins } = state;
      if (institutionLogin.credentials != null) {
        credentialsForRefreshingLogins =
          credentialsForRefreshingLogins.set(institutionLogin.id, institutionLogin.credentials);
      }

      return state.merge({
        idsRefreshingWithUserActionsPending: state.idsRefreshingWithUserActionsPending.delete(institutionLogin.id),
        credentialsForRefreshingLogins,
      });
    },

  [combineActions(
    actions.refreshAccountsCredentialsNotProvided,
    actions.refreshAccountsMfaNotProvided
  )]: (state: InstitutionLoginsStore, { payload: id }) => (
    state.merge({
      idsRefreshingWithCancelledUserActions: state.idsRefreshingWithCancelledUserActions.add(id),
      idsRefreshingWithUserActionsPending: state.idsRefreshingWithUserActionsPending.delete(id),
    })
  ),

  [actions.refreshAccountsMfaFormSubmitResponse]: {
    next(state: InstitutionLoginsStore, { payload: { institutionLoginId } }) {
      return state.set('idsRefreshingWithUserActionsPending',
        state.idsRefreshingWithUserActionsPending.delete(institutionLoginId));
    },
    throw(state: InstitutionLoginsStore, { payload: error }) {
      const { institutionLoginId } = error;
      return state.merge({
        idsRefreshingWithCancelledUserActions: state.idsRefreshingWithCancelledUserActions.add(institutionLoginId),
        idsRefreshingWithUserActionsPending: state.idsRefreshingWithUserActionsPending.delete(institutionLoginId),
      });
    },
  },

  [actions.refreshAccountsCompleted]:
    (state :InstitutionLoginsStore, { payload }) => {
      const { institutionLoginId } = payload;
      if (institutionLoginId) {
        return state.merge({
          idsRefreshing: state.idsRefreshing.remove(institutionLoginId),
          idsRefreshingWithUserActionsPending: state.idsRefreshingWithUserActionsPending.remove(institutionLoginId),
          idsRefreshingWithCancelledUserActions: state.idsRefreshingWithCancelledUserActions.remove(institutionLoginId),

          credentialsForRefreshingLogins: state.credentialsForRefreshingLogins.remove(institutionLoginId),
        });
      }
      return state.merge({
        isRefreshingAll: false,

        idsRefreshing: state.idsRefreshing.clear(),
        idsRefreshingWithUserActionsPending: state.idsRefreshingWithUserActionsPending.clear(),
        idsRefreshingWithCancelledUserActions: state.idsRefreshingWithCancelledUserActions.clear(),

        credentialsForRefreshingLogins: state.credentialsForRefreshingLogins.clear(),
      });
    },

  [actions.addInstitutionLoginAndAccountsResponse]: {
    next: (state: InstitutionLoginsStore, { payload: { institutionLogin } }) => {
      let newState = resourceUpserters.upsertResource(state, institutionLogin);
      newState = newState.set('idsRefreshing', state.idsRefreshing.add(institutionLogin.id));

      return newState;
    },
  },

  [actions.institutionLoginSetCredentialsBlob]: (state :InstitutionLoginsStore, { payload, asyncDispatch }) => {
    const { institutionLoginId, credentialsBlob } = payload;

    let { credentialBlobsForRefreshingLogins, credentialsForRefreshingLogins } = state;
    credentialBlobsForRefreshingLogins = credentialBlobsForRefreshingLogins.set(institutionLoginId, credentialsBlob);
    credentialsForRefreshingLogins = credentialsForRefreshingLogins.delete(institutionLoginId);

    // action to persist the credentials blobs to persistent storage
    asyncDispatch(saveInstitutionLoginsCredentialBlobs(credentialBlobsForRefreshingLogins));

    return state.merge({ credentialBlobsForRefreshingLogins, credentialsForRefreshingLogins });
  },

  // action to load data into redux from persistent storage
  [loadedInstitutionLoginsCredentialBlobs]: (
    state :InstitutionLoginsStore, { payload: credentialsBlobs }
  ) => {
    let { credentialBlobsForRefreshingLogins, credentialsForRefreshingLogins } = state;
    credentialBlobsForRefreshingLogins = credentialsBlobs;
    credentialsForRefreshingLogins = credentialsForRefreshingLogins.clear();

    return state.merge({ credentialBlobsForRefreshingLogins, credentialsForRefreshingLogins });
  },

  [actions.qcsInstitutionLoginActionResponse]: {
    next(state, { payload: institutionLogin }) {
      return resourceUpserters.upsertResource(state, institutionLogin, false);
    },
    throw(state, _error) {
      return state;
    },
  },

  [actions.updateChangedInstitutionLogins]: (state, { payload: institutionLogins }) =>
    resourceUpserters.upsertResources(state, resourceStoreTypes.mkQcsSyncResourcesPayload({ resources: institutionLogins })),

}, mkInstitutionLoginsStore());

export const REDUCER_KEY = 'institutionLoginsStore';
export default reducer;
