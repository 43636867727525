export const styles = (theme) => ({
  paperWidthMd: {
    width: '40%',
    overflow: 'visible',
  },
  paperWidthSm: {
    overflow: 'visible',
    padding: '10px 20px 10px 20px',
  },
  panel: {
    padding: '10px 20px 10px 20px',
    background: theme.palette.greyScaleDeprecated[7],
  },
  inputFields: {
    fontSize: '16px',
    paddingBottom: 2,
    textAlign: 'left',
  },
  inputFieldsBold: {
    fontSize: '16px',
    fontWeight: 800,
    paddingBottom: 2,
    textAlign: 'left',
  },
  labelBold: {
    fontWeight: 500,
    fontSize: '15px',
    marginLeft: 0,
  },
  container: {
    marginBottom: 2,
    marginLeft: 24,
    paddingRight: 24,
    background: theme.palette.greyScaleDeprecated[7],
    overflow: 'auto',
  },
  formGroup: {
    alignItems: 'start',
    marginTop: 20,
    marginBottom: 20,
  },
  formControlLabel: {
    marginRight: 0,
    marginLeft: 0,
    flexDirection: 'row-reverse',
    '&.bottomSpace': {
      marginBottom: 20,
    },
  },
  txList: {
    minWidth: 470,
    flex: 1,
    '&.right': {
      paddingLeft: 2,
      borderLeft: 'solid',
      borderLeftWidth: '1px',
      borderLeftColor: theme.palette.blue1,
    },
  },

  buttonHolder: {
    textAlign: 'right',
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
    background: theme.palette.greyScaleDeprecated[7],
    display: 'flex',
  },

  buttonHolderWithTotal: {
    textAlign: 'right',
    marginRight: 5,
    background: theme.palette.greyScaleDeprecated[7],
    display: 'flex',
    minHeight: 42,
    height: '100%',
    marginTop: 10,
    marginBottom: 10,
  },

  markAllLabel: {
    paddingLeft: 30,
    paddingTop: 12,
  },

  separator: {
    paddingTop: 10,
  },

  divider: {
    height: 2,
    backgroundColor: theme.palette.greyScaleDeprecated[3],
  },
});


