
import { qCards as overviewPage } from './overviewPage';
import { qCards as transactionPage } from './transactionPage';


export const qCards = {

  overviewPage,
  transactionPage,

};
