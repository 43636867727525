import React, { FC, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import store from 'companion-app-components/utils/redux-store';
import { getLogger } from 'companion-app-components/utils/core';
import { datasetsActions, datasetsSelectors, datasetsTypes } from 'companion-app-components/flux/datasets';
import { authActions, authTypes, authSelectors } from 'companion-app-components/flux/auth';
import { profileSelectors } from 'companion-app-components/flux/profile';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { entitlementsSelectors } from 'companion-app-components/flux/entitlements';
import type RootState from 'companion-app-components/utils/redux-store/rootState';

import GettingStartedWebFirst from 'containers/SetupPage/GettingStartedWebFirst';
import SetupPageCompanion from './SetupPageCompanion';
import SetupPageWebFirst from './SetupPageWebFirst';

const log = getLogger('containers/SetupPage');

const desktopClients = 'quicken_windows, quicken_mac';
const cloudClients = 'quicken_webapp';

const SetupPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newDatasetClientId, setNewDatasetClientId] = useState<null | undefined | string>(null);

  const profile = useSelector((state: RootState) => profileSelectors.getProfile(state));
  const authSession = useSelector((state: RootState) => authSelectors.getAuthSession(state));
  const datasetId = useSelector((state: RootState) => authSelectors.getDatasetId(state));
  const datasetsById = useSelector((state: RootState) => datasetsSelectors.getDatasetsById(state));
  const isLoadPendingDatasets = useSelector((state: RootState) => datasetsSelectors.getLoadPending(state));
  const isLoadPendingFeatureFlags = useSelector((state: RootState) => featureFlagsSelectors.getLoadPending(state));
  const isLoadPending = isLoadPendingDatasets || isLoadPendingFeatureFlags;
  const webFirstEnabled = useSelector((state: RootState) => entitlementsSelectors.hasWebFirstEntitlements(state));
  const accountsById = useSelector((state: RootState) => accountsSelectors.getAccountsById(state));
  const accountsLoadPending = useSelector((state: RootState) => accountsSelectors.getLoadPending(state));
  const disableNewUserWebFirst = useSelector((state: RootState) =>
    featureFlagsSelectors.getFeatureFlags(state)).get('disableNewUserWebFirst');
  const entitledDatasetsLoadPending = useSelector((state: RootState) => datasetsSelectors.getEntitledDatasetsLoadPending(state));

  const dispatchCreateDatasetAction = (dataset: datasetsTypes.Dataset) => dispatch(datasetsActions.createDataset(dataset));
  const dispatchAuthSelectDatasetAction = (data) => dispatch(authActions.authSelectDataset(data));

  const createNewDataset = (type: string) => {
    if (!type || desktopClients.indexOf(type) === -1) {
      log.log('Create Dataset Failed, you must pass a type of "quicken_windows" or "quicken_mac"');
    }
    let newDataset = store && profile && datasetsSelectors.makeNewDataset(store.getState(), profile);
    newDataset = newDataset && newDataset.set('platform', type === 'quicken_windows' ? 'QWIN_MWF' : 'QMAC_MWF');

    if (newDataset) {
      dispatchCreateDatasetAction(newDataset);
      setNewDatasetClientId(newDataset.clientId);
    }
  };

  // 1. Still loading, return nothing
  if (isLoadPending || entitledDatasetsLoadPending) {
    return null;
  }

  // 2. new dataset was created? then open it and navigate to getting started
  if (newDatasetClientId) {
    const newDataSet = datasetsById.find((dataset) => newDatasetClientId === dataset.clientId);
    if (newDataSet && newDataSet.id) {
      setNewDatasetClientId(undefined);
      dispatchAuthSelectDatasetAction(authTypes.AuthSelectDatasetPayload({
        dataset: newDataSet,
        reload: false,
        location: '/getting-started-webfirst',
      }));
    }
    return null;
  }

  const datasetObject = datasetId && datasetsById && datasetsById.get(datasetId);
  const createdByClientId = (datasetObject && datasetObject.createdByClientId) || 'unknown';
  const hasDisableRemoteAccess = datasetObject && datasetObject.disableRemoteAccess;
  // This indicates the dataset has been created by Windows or Mac, we cannot ascertain if it is unused,
  // or just unsynced.  In this case they get the "companion" setup page to either instruct them how to
  // sync their desktop accounts, or they can start from scratch with a web-created (web first) dataset
  const useCompanion = (desktopClients.indexOf(createdByClientId) !== -1)
    || !webFirstEnabled || hasDisableRemoteAccess || disableNewUserWebFirst;

  if (!hasDisableRemoteAccess) {
    // 3. if dataset and it is created by cloud, then go to webfirst setup
    if (cloudClients.indexOf(createdByClientId) !== -1) {
      return <GettingStartedWebFirst />;
    }
    if (!isLoadPending && !useCompanion && datasetsById.size === 0 && webFirstEnabled) {
      navigate('platformSelector', { replace: true });
    } else if (!accountsLoadPending && accountsById?.size >= 1) {
      navigate('/', { replace: true });
    }
  }

  return (
    <>
      {useCompanion &&  // dataset is from the desktop
        <SetupPageCompanion
          profile={profile}
          authSession={authSession}
          defaultTabIsWindows={createdByClientId === 'quicken_windows'}
          // TODO: uncomment if dataset creating is needed in future
          // onCreateDataset={createNewDataset}
        />}
      {!useCompanion &&  // no dataset
        <SetupPageWebFirst
          profile={profile}
          authSession={authSession}
          defaultTabIsWindows={createdByClientId === 'quicken_windows'}
          onCreateDataset={createNewDataset}
        />}
    </>
  );
};

export default memo(SetupPage);
