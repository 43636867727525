import history from 'components/History';
const queryString = require('query-string');

// add a query parameter to the current location and then push the same location
export const addQuery = (query) => {
  const currentLocation = queryString.parse(window.location.search);
  const newLocation = Object.assign(currentLocation, queryString.parse(query));
  history.replace(queryString.stringify(newLocation));
};

// remove the specified query parameters from the current location and then push the same location
export const removeQuery = (...queryNames) => {
  const { pathname } = window.location;
  const queryParms = queryString.parse(window.location.search);
  queryNames.forEach((q) => delete queryParms[q]);
  const newLocation = `${pathname}${queryString.stringify(queryParms)}`;
  history.replace(newLocation);
};

export const getQueryByName = (location, name) => {
  const query = queryString.parse(location.search);
  const value = query[name] || null;
  return value;
};
