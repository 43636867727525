import React from 'react';
import PropTypes from 'prop-types';

class LocalErrorBoundary extends React.PureComponent {
  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  render() {
    if (this.state.hasError) {
      return this.props.FallbackComponent?.() || null;
    }
    return this.props.children;
  }
}

LocalErrorBoundary.propTypes = {
  FallbackComponent: PropTypes.func,
  children: PropTypes.any,
};

export default LocalErrorBoundary;
