// @flow
import * as React from 'react';

import Box from '@mui/material/Box';

type Props = {
  children: React.node,
};

const StdDialogWizardContent = (props: Props) => {
  const { children, ...other } = props;

  return (
    <Box display="flex" flex="1" flexDirection="column" {...other}>
      { children }
    </Box>
  );
};

export default StdDialogWizardContent;
