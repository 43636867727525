// @flow
import Budgets from 'containers/BudgetsV2';
import InvestmentHoldings from 'containers/InvestmentHoldings/loadable';
import OverviewPage from 'containers/OverviewPage/loadable';

import { userIsAuthenticatedWithDataset, isAuthenticatedOrElse } from 'utils/auth';
import { isAcme } from 'isAcme';

import Settings from 'containers/Settings/loadable';
import SetupPage from 'containers/SetupPage/loadable';
import GettingStartedWebFirst from 'containers/SetupPage/GettingStartedWebFirst';
import NewReports from 'containers/NewReports/loadable';
import Transactions from 'containers/Transactions/loadable';
import ScheduledTransactions from 'containers/ScheduledTransactions/loadable';
import NotFoundPage from 'containers/NotFoundPage/loadable';
import OfflinePage from 'containers/OfflinePage/loadable';
import ErrorPage from 'containers/ErrorPage/loadable';
import GettingStarted from 'containers/GettingStarted/loadable';
import PlatformSelector from 'containers/PlatformSelector/loadable';
import TermsPage from 'containers/Legal/TermsPage/loadable';
import PrivacyPage from 'containers/Legal/PrivacyPage/loadable';
import Acknowledge from 'containers/Legal/Acknowledgements/loadable';
import FetchSubscriptionPage from 'containers/FetchSubscriptionPage/loadable';
import ProfilePage from 'containers/ProfilePage/loadable';
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
import SharedComponentsPage from 'containers/SharedComponentsPage/loadable';
/////////

import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import { qCards } from './QCards/qCardIndex';
import * as navIcons from './navIcons';
import Login from './Login';
import Logout from './Logout';
import useShareAccept from './useShareAccept';

const ProtectedBudgets = userIsAuthenticatedWithDataset(Budgets);
const ProtectedDashboard = userIsAuthenticatedWithDataset(OverviewPage);
const ProtectedInvestmentHoldings = userIsAuthenticatedWithDataset(InvestmentHoldings);
const ProtectedScheduledTransactions = userIsAuthenticatedWithDataset(ScheduledTransactions);
const ProtectedSettingsComponent = userIsAuthenticatedWithDataset(Settings);
const ProtectedNewReports = userIsAuthenticatedWithDataset(NewReports);
const ProtectedTransactions = userIsAuthenticatedWithDataset(Transactions);
const ProtectedProfile = userIsAuthenticatedWithDataset(ProfilePage);

const GettingStartedPage = isAuthenticatedOrElse(NotFoundPage, GettingStarted);
const PlatformSelectorPage = isAuthenticatedOrElse(PlatformSelector, NotFoundPage);


export const navMainOrder = [
  '', // dashboard
  'transactions',
  'reports',
  'spending',
  'budgets',
  'net-income',
  'bills-and-income',
  'investments',
];

export type FeaturePage = {
  path: string,
  component: any,
  exact: boolean,
  title: string,
  datasetNotNeeded?: boolean,
  authNotNeeded?: boolean,
  nav?: ?'main' | 'sub',
  alt?: ?string,
  img?: ?any,
  featureFlag?: ?string,
  id?: ?string,
  open?: ?boolean,
  accountsShowing?: ?boolean,
  exclusiveProject?: ?string,
  hideNav?: ?boolean,
  qCards?: ?any,
  pathParams?: ?string,
};

/*
 *
 * ADD YOUR FEATURE PAGE HERE
 *
 * Required:
 * path
 * component
 * title        - STRING: title used in the QTip and used for the automation ID
 *
 * Optional:
 * exact        - BOOL: used for path matching in Route
 * nav          - STRING: if in navbar, which section.  'main' or 'sub'
 * alt          - STRING: alt text for icon image
 * img          - STRING: image src string for icon button
 * featureFlag  - STRING: feature flag name if feature is to be shown/hidden based on flag
 * id           - automation id if DIFFERENT from `nav-menu-${morphed(title)}`  - morphed = lower case and spaces as dashes, remove '/'s
 * open         - BOOL: automatially open or close the account list
 * hideOnTiers  - ARRAY of string: Hide this page based on subscription tiers
 * pathParams   - ARRAY of strings: each string in the array represents a possible combination of params to add to the path
 *                  exact MUST be true (meaning # of params must be exact as well) to avoid defaulting to root path, see reports path below for example
 */
const defaultPage: FeaturePage = {
  title: '',
  exact: true,
  open: true,
  nav: 'main',
  alt: 'icon',
  img: BlockOutlinedIcon,
  datasetNotNeeded: false,
  authNotNeeded: false,
  accountShowing: true,
  hideNav: false,
  hideOnTiers: [],
  addFIInitiator: 'app',
  pathParams: '',
};

export const featurePages: Array<FeaturePage> = [
  {
    ...defaultPage,
    alt: undefined,
    path: '/login',
    component: Login,
    exact: true,
    title: 'Login',
    datasetNotNeeded: true,
    authNotNeeded: true,
  },
  {
    ...defaultPage,
    path: '/logout',
    component: Logout,
    exact: true,
    title: 'Logout',
    datasetNotNeeded: true,
    authNotNeeded: true,
  },
  {
    ...defaultPage,
    path: '/budgets',
    component: ProtectedBudgets,
    exact: true,
    nav: 'main',
    title: 'Budgets',
    alt: 'pencil, calculator, and paper',
    img: navIcons.navIconBudgets,
    featureFlag: 'budgets',
  },
  {
    ...defaultPage,
    path: '/',
    component: ProtectedDashboard,
    exact: true,
    open: true,
    nav: 'main',
    title: 'Dashboard',
    alt: 'easel showing abstract chart',
    img: navIcons.navIconDashboardQuicken,
    qCards: qCards.overviewPage,
    showSetupBar: true,
  },
  {
    ...defaultPage,
    path: '/investments',
    component: ProtectedInvestmentHoldings,
    exact: true,
    open: true,
    nav: 'main',
    title: 'Investments',
    alt: 'bank vault with open door',
    img: navIcons.navIconInvestmentsQuicken,
    featureFlag: 'investmentSummaryCard',
    hideOnTiers: ['US_QUICKEN_STARTER', 'CA_QUICKEN_CASHMANAGER'],
    useSelectedAccount: true,
    preserveDisplayNode: !isAcme,
    noScroll: false,
  },
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    ...defaultPage,
    path: '/docs',
    component: SharedComponentsPage,
    nav: 'main',
    exact: true,
    title: 'Docs',
    alt: 'Docs',
    img: BuildOutlinedIcon,
    pathParams: '/:itemId?',
  },
///////////
  {
    ...defaultPage,
    path: '/settings',
    component: ProtectedSettingsComponent,
    nav: 'main',
    exact: true,
    title: 'Settings',
    alt: 'settings icon',
    img: navIcons.navIconSettingsQuicken,
    pathParams: '/:settingSelected?/:itemSelected?',
  },
  {
    ...defaultPage,
    path: '/bills-and-income',
    component: ProtectedScheduledTransactions,
    exclusiveProject: 'quicken',
    exact: true,
    alt: 'arrow clock',
    img: navIcons.navIconRecurring,
    title: 'Bills and Income',
    featureFlag: 'scheduledTransactions',
  },
  {
    ...defaultPage,
    path: '/setup',
    component: SetupPage,
    exclusiveProject: 'quicken',
    exact: true,
    title: 'Setup',
    datasetNotNeeded: true,
    accountShowing: false,
  },
  {
    ...defaultPage,
    path: '/reports',
    component: ProtectedNewReports,
    exact: true,
    nav: 'main',
    title: 'Reports',
    alt: 'various spending reports and charts',
    img: navIcons.navIconReports,
    featureFlag: 'reports',
    // to enable different number of params, we have to use exact=true and repeat them
    pathParams: '/:tabName?/:groupBy?/:layoutView?',
    subMenuItems: [
      {
        path: '/reports/spending/by-category',
        title: 'Spending',
      },
      {
        path: '/reports/net-income/over-time',
        title: 'Net Income',
      },
      {
        path: '/reports/income-vs-spending/over-time',
        title: 'Income Vs. Expense',
      },
    ],
  },
  {
    ...defaultPage,
    path: '/transactions',
    component: ProtectedTransactions,
    exact: true,
    open: true,
    nav: 'main',
    title: 'Transactions',
    alt: 'ledger of printed transactions',
    img: navIcons.navIconTransactionsQuicken,
    useSelectedAccount: true,
    preserveDisplayNode: true,
    noScroll: false,
    qCards: qCards.transactionPage,
    accountShowing: true,
  },
  {
    ...defaultPage,
    path: '/profile',
    component: ProtectedProfile,
    exact: true,
    nav: 'main',
    title: 'Profile',
    alt: 'user profile and datasets',
  },
  {
    ...defaultPage,
    path: '/terms-of-use',
    component: TermsPage,
    exclusiveProject: 'acme',
    exact: true,
    title: 'Terms of Use',
    authNotNeeded: true,
  },

  {
    ...defaultPage,
    path: '/privacy',
    component: PrivacyPage,
    exclusiveProject: 'acme',
    exact: true,
    title: 'Privacy',
    authNotNeeded: true,
  },
  {
    ...defaultPage,
    path: '/acknowledgements',
    exclusiveProject: 'acme',
    component: Acknowledge,
    exact: true,
    title: 'Acknowledgements',
    authNotNeeded: true,
  },
  {
    ...defaultPage,
    exact: true,
    path: '/getting-started-webfirst',
    exclusiveProject: 'quicken',
    component: GettingStartedWebFirst,
    title: 'Getting Started',
    ignoreZeroState: true,
  },
  {
    ...defaultPage,
    exact: true,
    path: '/error',
    component: ErrorPage,
    title: 'Error',
    datasetNotNeeded: true,
    authNotNeeded: true,
  },
  {
    ...defaultPage,
    exact: true,
    path: '/oops',
    component: NotFoundPage,
    title: 'Oops',
    datasetNotNeeded: true,
    authNotNeeded: true,
  },
  {
    ...defaultPage,
    path: '/fetch-subscription',
    component: FetchSubscriptionPage,
    title: 'Fetch Subscription',
    datasetNotNeeded: true,
    authNotNeeded: true,
  },
  {
    ...defaultPage,
    path: '/getting-started',
    component: GettingStartedPage,
    title: 'Getting Started',
    datasetNotNeeded: true,
    authNotNeeded: true,
    exclusiveProject: 'quicken',
  },
  {
    ...defaultPage,
    path: '/platformSelector',
    component: PlatformSelectorPage,
    title: 'Select a platform',
    datasetNotNeeded: true,
    exclusiveProject: 'quicken',
  },
  {
    ...defaultPage,
    exact: true,
    path: '/offline',
    component: OfflinePage,
    title: 'Offline page',
    datasetNotNeeded: false,
    authNotNeeded: true,
  },
  {
    ...defaultPage,
    path: '/share-accept',
    component: useShareAccept,
    exact: true,
    title: 'Share Accept',
    datasetNotNeeded: true,
    authNotNeeded: true,
  },

  // UP UP UP ^^^ - insert new components above that line - NotFoundPage have to be the last one -----------------------
  {
    ...defaultPage,
    exact: true,
    path: '',
    component: NotFoundPage,
    title: 'Not Found Page',
    datasetNotNeeded: true,
    authNotNeeded: true,
  },
  // NO! NO! NO! - please don't add any components here - NotFoundPage have to be the last one -------------------------
];

export const findFeaturePageByPath = (path) => {
  const keys = path.split('/');
  const featurePagesMatches = featurePages
    .map((featurePage) => {
      const levels = featurePage.path.split('/');
      const levelsMatch = levels.reduce((matched, currentLevel, index) =>
        keys[index] === currentLevel ? matched + 1 : matched, 0);
      return { levelsMatch, levels, featurePage };
    })
    .sort((item1, item2) => item2.levelsMatch - item1.levelsMatch);
  const featurePageBestMatch = featurePagesMatches[0];
  const featurePageFound = featurePageBestMatch.levelsMatch === featurePageBestMatch.levels.length ?
    featurePageBestMatch.featurePage : undefined;
  return featurePageFound;
};
