import { createAction } from 'redux-actions';

export const mcRulesExecute = createAction('MC_RULES_EXECUTE', (payload) => payload, (payload, meta) => meta);
export const mcRulesExecuteSuccess = createAction('MC_RULES_EXECUTE_SUCCESS');
export const mcRulesExecuteFailure = createAction('MC_RULES_EXECUTE_FAILURE');

export const mcRulesSubmitData = createAction('MC_RULES_SUBMIT_DATA', (payload) => payload, (payload, meta) => meta);
export const mcRulesSubmitDataSuccess = createAction('MC_RULES_SUBMIT_DATA_SUCCESS');
export const mcRulesSubmitDataFailure = createAction('MC_RULES_SUBMIT_DATA_FAILURE');

export const mcRulesPoll = createAction('MC_RULES_POLL', (payload) => payload, (payload, meta) => meta);
export const mcRulesPollSuccess = createAction('MC_RULES_POLL_SUCCESS');
export const mcRulesPollFailure = createAction('MC_RULES_POLL_FAILURE');

export const mcMessagesTrack = createAction('MC_MESSAGES_TRACK', (payload) => payload, (payload, meta) => meta);
export const mcMessagesTrackSuccess = createAction('MC_MESSAGES_TRACK_SUCCESS');
export const mcMessagesTrackFailure = createAction('MC_MESSAGES_TRACK_FAILURE');
