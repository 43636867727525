import React, { FC } from 'react';

import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import QButton from 'components/QButton';

interface TitleHeaderProps {
  classes: Record<string, any>;
  dialogTitle: string;
  create: boolean;
  isMacDataset: boolean;
}

export const TitleHeader: FC<TitleHeaderProps> = ({ classes, dialogTitle, isMacDataset, create = false }) => {
  let subText;
  if (create) {
    if (isMacDataset) {
      subText = 'Here is a starter budget to get you going.  You can budget specific categories, ' +
        ' either from our suggestions list, or pick exactly the one to add from the full category list.';
    } else {
      subText = 'Here is a starter budget for you to fill out. You can enter just an income and expense amount, or you ' +
        'can budget specific categories, either from our suggestions list, or pick exactly the one to add from the ' +
        'full category list.';
    }
  } else {
    subText = 'This is your existing budget. You may choose to update the amount, add or remove categories.' +
      '  Changes you make will be automatically applied to the remaining months for the year.';
  }
  return (
    <div
      className={classes.header}
    >
      <Typography
        variant="h5"
        className={classes.title}
      >
        {dialogTitle}
      </Typography>
      <Typography
        style={{ paddingRight: 8 }}
        variant="body1"
      >
        {subText}
      </Typography>
    </div>
  );
};

interface AddCategoryBlockProps {
  classes: Record<string, any>;
  onAddCategory: () => void;
  onSuggestedCategories: () => void;
}

export const AddCategoryBlock: FC<AddCategoryBlockProps> = ({ classes, onAddCategory, onSuggestedCategories }) => (
  <div
    className={classes.addCategoryBlock}
  >
    <QButton
      variant="contained"
      id="edit-budgets-suggested-categories"
      className={classes.addCategoryButton}
      aria-label="Show Suggested Categories"
      onClick={onSuggestedCategories}
      classes={{ conButton: classes.addButtonPrimary }}
    >
      <AddIcon /> Suggestions
    </QButton>
    <QButton
      variant="contained"
      id="edit-budgets-add-category"
      className={classes.addCategoryButton}
      aria-label="Add Category"
      onClick={onAddCategory}
      classes={{ conButton: classes.addButtonSecondary }}
    >
      <AddIcon /> Add Category
    </QButton>
  </div>
);
