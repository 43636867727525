import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import { tracker } from 'companion-app-components/utils/core';
import { accountsSelectors, accountsUtils } from 'companion-app-components/flux/accounts';

import { withStyles } from 'tss-react/mui';
import compose from 'utils/compose';
import { connect } from 'react-redux';

import { getBalancesByAccountId } from 'data/accountBalances/selectors';
import { getReconcileInfoForAccount } from 'data/reconcileList/selectors';

import QDialogs from 'components/QDialogs';

import { styles } from './styles';
import { ReconcileSetup } from './reconcileSetup';
import ReconcileWorkspace from './reconcileWorkspace';

// ==============================================================
//
// Presents the form to bulk edit a group of transactions
//
//
export class ReconcilePanel extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      statementEndDate: null,
      statementEndBalance: null,
      showSetup: true,
      useOnline: false,
    };

    if (!this.props.reconcileInfo) {
      this.closeAndExit();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  componentDidMount() {
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInitialFocus = () => {
  };

  closeAndExit = (status) => {

    if (status && status !== 'canceled') {
      //
      // send mixpanel event
      //
      tracker.track(tracker.events.reconcileAnAccount, {
        method: this.state.useOnline ? 'Online Balance' : 'Paper Statement',
        status,
      });
    }
    this.props.onClose();
  };

  onSave = (data) => {
    //
    // send mixpanel event
    //
    tracker.track(tracker.events.reconcileAnAccount, {
      method: data.useOnline ? 'Online Balance' : 'Paper Statement',
      status: 'Started',
    });

    this.setState(
      {
        useOnline: data.useOnline,
        showSetup: false,
        statementEndDate: data.statementEndDate,
        statementEndBalance: data.statementEndBalance,
      }
    );
  };


  render() {

    const { classes } = this.props;
    const { showSetup } = this.state;

    if (!this.props.reconcileInfo) {
      this.props.dialogAlert(
        'There are no transactions to reconcile',
        '',
        null,
        ['Got It'],
        'info',
      );
      return null;
    }
    return (
      <>
        {showSetup &&
          <ReconcileSetup
            accountBalances={this.props.accountBalances}
            account={this.props.accountRecord}
            isOnline={accountsUtils.isActiveConnectedAccountFromId(this.props.accountId)}
            lastReconciledBalance={this.props.reconcileInfo.get('registerReconciledBalance')}
            onSave={this.onSave}
            onClose={this.closeAndExit}
            classes={classes}
          />}
        {!showSetup &&
          <ReconcileWorkspace
            account={this.props.accountRecord}
            statementEndDate={this.state.statementEndDate}
            statementEndBalance={Number(this.state.statementEndBalance)}
            onClose={this.closeAndExit}
            classes={classes}
          />}
      </>
    );
  }
}

ReconcilePanel.propTypes = {
  accountId: PropTypes.string,
  onClose: PropTypes.func,
  classes: PropTypes.object,
  accountBalances: PropTypes.object,
  accountRecord: PropTypes.object,
  reconcileInfo: PropTypes.object,

  dialogAlert: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    accountBalances: getBalancesByAccountId(state).get(ownProps.accountId),
    accountRecord: accountsSelectors.getAccountsById(state).get(ownProps.accountId),
    reconcileInfo: getReconcileInfoForAccount(state,
      {
        accountId: ownProps.accountId,
        statementDate: accountsUtils.isActiveConnectedAccountFromId(ownProps.accountId) ? null : DateTime.now().toFormat('yyyy-MM-dd'),
        statementEndingBalance: 0,
      }),
  };
}

function mapDispatchToProps() {
  return {
  };
}

// These are prop injectors
export default compose(
  QDialogs(),
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(ReconcilePanel, styles));

