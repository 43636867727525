
export const tooltipStyles = (theme) => {
  const baseColor = theme.palette.greyScaleDeprecated[7];
  const arrowTransform = (rotate) => `rotate(${rotate}deg) translate(50%, -50%)`;

  return ({
    tooltip: {
      position: 'relative',
      display: 'flex',
      backgroundColor: 'transparent',
      margin: 0,
      padding: 0,
    },
    paper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(3)}`,
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: baseColor,
    },
    arrow: {
      backgroundColor: baseColor,
      border: `1px solid ${theme.palette.divider}`,
      borderLeft: 'none',
      borderBottom: 'none',
      width: 10,
      height: 10,
      position: 'absolute',
    },
    popper: {
      '&[x-placement*="bottom"] $arrow': {
        top: 2,
        transform: arrowTransform(-45),
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 2,
        transform: arrowTransform(135),
      },
      // '&[x-placement*="right"] $arrow': {},
      // '&[x-placement*="left"] $arrow': {},
      // '&[x-placement*="bottom-end"] $arrow': {},
      // '&[x-placement*="bottom-start"] $arrow': {},
      // '&[x-placement*="left-end"] $arrow': {},
      // '&[x-placement*="left-start"] $arrow': {},
      // '&[x-placement*="right-end"] $arrow': {},
      // '&[x-placement*="right-start"] $arrow': {},
      // '&[x-placement*="top-end"] $arrow': {},
      // '&[x-placement*="top-start"] $arrow': {},
    },
  });
};










