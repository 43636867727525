import React, { useState, useEffect, RefObject } from 'react';

import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';

import styles from './styles';

const useStyles = makeStyles()(styles);

interface PayeeSpanProps {
  initialValue: string;
  variant: string;
  onBlur: (text: string) => void;
  className: string;
  style: Record<string, any>;
  onChange?: (text: string) => void;
}

const PayeeSpan: React.FC<PayeeSpanProps> = (props) => {
  const { initialValue, onBlur, className, variant = 'body1', style = {}, onChange, ...otherProps } = props;
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const [payee, setPayee] = useState(initialValue);
  const [spanRef] = useState<RefObject<HTMLSpanElement>>(React.createRef());
  let update = true; // controls how to handle the blur (don't update on escape)

  const handleChange = () => {
    if (onChange && spanRef && spanRef.current) {
      onChange(spanRef.current.innerText);
    }
  };

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 8: // backspace
        break;
      case 13: // enter
        if (spanRef?.current) {
          spanRef.current.blur();
        }
        break;
      case 27: // escape
        update = false;
        if (spanRef?.current) {
          spanRef.current.blur();
        }
        break;
      default:
        break;
    }
  };

  const handlePaste = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // @ts-expect-error TS(2551) - FIXME: Property 'clipboardData' does not exist on type 'Window & typeof globalThis'
    const clipboardData = e.clipboardData || window.clipboardData;
    const text = clipboardData && clipboardData.getData('Text'); // only keep textData
    window.document.execCommand('insertText', false, text);
  };

  const handleBlur = () => {
    if (!spanRef.current) { return; }

    spanRef.current.scrollLeft = 0;
    if (update) {
      setPayee(spanRef.current.innerText);
      if (onBlur) {
        onBlur(spanRef.current.innerText);
      }
    } else {
      spanRef.current.textContent = payee;
    }
  };

  useEffect(() => {
    setPayee(initialValue);
    if (spanRef?.current) {
      spanRef.current.textContent = initialValue;
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const node = spanRef && spanRef.current;
    if (node && onChange) {
      node.addEventListener('DOMSubtreeModified', handleChange);
    }
    return () => {
      if (node) {
        node.removeEventListener('DOMSubtreeModified', handleChange);
      }
    };
  }, [spanRef, onChange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <span
      id="payeeSpan"
      role="textbox"
      tabIndex={0}
      ref={spanRef}
      contentEditable
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onPaste={handlePaste}
      className={cx(classes.root, className)}
      style={{
        ...theme.typography[variant],
        ...style,
      }}
      {...otherProps}
    />
  );
};

export default PayeeSpan;
