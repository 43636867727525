const styles = ({ spacing, typography, components, palette, applyOpacityToHex }) => ({
  dialogTitleRoot: {
    margin: 0,
    padding: `${spacing(3)} ${spacing(7.5)} ${spacing(3)} ${spacing(3)}`,
    backgroundColor: components.register.headerBackground,
  },
  dialogCloseButton: {
    position: 'absolute',
    right: spacing(1),
    top: spacing(2),
  },
  dialogContentRoot: {
    margin: 0,
    padding: spacing(3),
  },
  dialogContentText: {
    color: applyOpacityToHex(palette.black, 0.6),
    '& strong': {
      fontWeight: typography.fontWeightMedium,
    },
  },
  dialogActionsRoot: {
    margin: 0,
    padding: spacing(3),
  },
  dialogActionsButton: {
    margin: `0 ${spacing(1)}`,
  },
});

export default styles;
