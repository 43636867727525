import { formatNumber } from 'components/QuickenControls/AmountField';
import { getSharedCurrency as utilsGetSharedCurrency } from 'data/transactions/utils';

export const getObjectSharedCurrency = (objectWithCurrency) => {
  let currency = null;
  objectWithCurrency.forEach((x) => {
    const tc = x.currency;
    if (tc !== currency) {
      currency = (!currency ? tc : 'N/A');
    }
  });
  return currency;
};

export const getAccountsSharedCurrency = (accountIds, accountsById) => {
  const accounts = accountIds.map((id) => accountsById.get(id));
  return getObjectSharedCurrency(accounts);
};

// takes an array of lists, if you only have 1 list pass it as [txnList]
export const getSharedCurrency = (txnsArray, accountsById) =>
  utilsGetSharedCurrency(txnsArray, accountsById);

export const getAccountIdsCurrency = (accountId, accountsById) => {
  const account = accountsById.get(accountId);
  if (account) {
    return account.get('currency');
  }
  return 'N/A';
};

export const getTxnCurrency = (txn) => getAccountIdsCurrency(txn.accountId);

export const formatAmount = (val, currencySymbol, omitCents = false) =>
  formatNumber(val, currencySymbol, omitCents ? '0,0' : '0,00.00');

export const formatTxnAmount = (txn, omitCents) =>
  formatAmount(txn.amount, getTxnCurrency(txn), omitCents);

