
// CORE
import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { scheduledTransactionsActions, scheduledTransactionsSelectors } from 'companion-app-components/flux/scheduled-transactions';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

// Data
import { useSelector, useDispatch } from 'react-redux';
import { getIdsRefreshing } from 'data/institutionLogins/selectors';
import { removeDialog } from 'data/rootUi/actions';

// CUSTOM
import { getLogger } from 'companion-app-components/utils/core';
import QButton from 'components/QButton';
import QCheck from 'components/QCheck';
import RecurringPage from 'components/GettingStartedAcme/Recurring/RecurringPage';
import { DIALOG_TYPE } from './actions';
import StdDialog from '../StdDialog';

const log = getLogger('BillScoutPresentPage');


const useStyles = makeStyles()((theme) => ({
  root: {
    width: 768,
  },
  contentRoot: {
    margin: '32px 24px 24px',
    height: 359,
  },
  grey: {
    color: theme.palette.greyScaleDeprecated[1],
  },
  centeredSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addSection: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    padding: '20px 24px 28px',
    backgroundColor: theme.palette.greyScaleDeprecated[6],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hiddenAdd: {
    opacity: 0,
    transition: 'opacity 1s ease 0.5s',
  },

  // Recurring stuff below
  recurringList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '4px',
  },
  recurringCard: {
    width: 198,
    height: 260,
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bolder: {
    fontWeight: 500,
    color: theme.palette.greyScaleDeprecated[0],
  },
  centerWrapper: {
    height: 415,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bottomRow: {
    padding: '12px 24px 36px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));


const BillScoutDialog = (props) => {
  const { classes } = useStyles();
  const { institutionLoginId, accountClientIds } = props;
  const dispatch = useDispatch();

  const accounts = useSelector((state) => accountsSelectors.getAccountsForInstitutionLoginId(state, { institutionLoginId }));

  // find accounts by recently added clientIds (will disappear after refresh)
  const filteredAccountIds = useMemo(() => {
    let ret = accounts;
    if (accountClientIds && accountClientIds.length) {
      ret = accounts.filter((account) => account?.id && accountClientIds.includes(account.clientId));
    }
    return ret.map((account) => account.id).toArray();
  }, [accountClientIds, accounts]);

  const [accountSnapshot, setAccountSnapshot] = useState([]);

  // use account snapshot if available to get scheduledTransactions
  const { income, bills } = useSelector((state) =>
    scheduledTransactionsSelectors.getAccountFilteredBillsAndIncome(state, accountSnapshot?.length ? accountSnapshot : filteredAccountIds));

  const scheduleLoading = useSelector(scheduledTransactionsSelectors.getIsLoading);
  const institutionsRefreshing = useSelector(getIdsRefreshing);
  const banksLoading = !institutionsRefreshing.isEmpty();

  const dataRef = useRef();
  const autoClose = useRef();
  const autoProceed = useRef();

  const [loading, setLoading] = useState('agg');
  const [state, setState] = useState('waiter');

  // snapshot accounts when they still have clientIds, because clientIds are cleared after a refresh
  useEffect(() => {
    if (filteredAccountIds && filteredAccountIds.length > accountSnapshot?.length) {
      setAccountSnapshot(filteredAccountIds);
    }
  }, [filteredAccountIds]); // eslint-disable-line react-hooks/exhaustive-deps


  // # # - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - # # //
  //                       Navigation functions and UI logic                         //
  // # # - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - # # //
  function onClose() {
    dispatch(removeDialog(DIALOG_TYPE));
  }
  function doAutoProceed() {
    const { current } = dataRef;
    if (current.state === 'waiter') { // still on waiter, so proceed
      // decide which page to proceed to
      if (current.showIncome) {
        setState('income');
      } else if (current.showBills) {
        setState('bills');
      } else {
        setState('final');
      }
    }
  }

  let showIncome = false;
  let showBills = false;
  if (!loading) {
    showIncome = income.size > 0;
    showBills = bills.size > 0;

    // capture state values in ref
    dataRef.current = { showIncome, showBills, state };
    // auto-close if no models
    if (!showIncome && !showBills) {
      log.info('No models found, closing dialog');
      if (!autoClose.current) {
        autoClose.current = setTimeout(onClose, 800);
      }
    } else if (state === 'waiter' && !autoProceed.current) {
      // auto-proceed from the waiting screen after models are in
      autoProceed.current = setTimeout(doAutoProceed, 1800);
    }
  }

  // make sure everything loads in the right order
  useEffect(() => {
    if (loading === 'agg' && !banksLoading) {
      if (scheduleLoading) {
        setLoading('ST');
      } else {
        setTimeout(() => dispatch(scheduledTransactionsActions.getScheduledTransactions()), 1800);
      }
    }
    if (loading === 'ST' && !scheduleLoading) {
      setLoading(false);
    }
  }, [loading, banksLoading, scheduleLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // cleanup timeouts on unmount
  useEffect(() => () => {
    if (autoProceed.current) clearTimeout(autoProceed);
    if (autoClose.current) clearTimeout(autoClose);
  }, []);


  const proceed = () => {
    switch (state) {
      case 'waiter':
        if (showIncome) {
          setState('income');
        } else if (showBills) {
          setState('bills');
        } else {
          setState('final');
        }
        break;
      case 'income':
        if (showBills) {
          setState('bills');
        } else {
          setState('final');
        }
        break;
      case 'bills':
        setState('final');
        break;
      case 'final':
        onClose();
        break;
      default:
        setState('final');
    }
  };

  const onBack = () => {
    switch (state) {
      case 'income':
        setState('waiter');
        break;
      case 'bills':
        if (showIncome) {
          setState('income');
        } else {
          setState('waiter');
        }
        break;
      case 'final':
        if (showBills) {
          setState('bills');
        } else if (showIncome) {
          setState('income');
        } else {
          setState('waiter');
        }
        break;
      default:
        setState('waiter');
    }
  };

  // # # - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - # # //
  //                                  Renderers                                      //
  // # # - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - # # //
  const renderBillScoutWaiter = () =>
    <div className={classes.centerWrapper} id="waiterSize">
      <Typography variant="h6">
        {loading ? 'Hang Tight!' : 'All Right!'}
      </Typography>
      <Typography variant="body1" className={classes.grey}>
        {loading ? "We're processing your transactions." : "We've got your data." }
      </Typography>
      <div className={classes.recurringList}>
        {loading ?
          <CircularProgress size={80} style={{ margin: 20 }} />
          :
          <QCheck style={{ margin: 38 }} size={44} />}
        <Typography variant="body1" className={classes.grey}>
          {loading ? 'This should only take a few moments.' : "Let's continue"}
        </Typography>
      </div>
    </div>;

  const renderIncome = () =>
    <div key="Income_Page" className={classes.contentRoot}>
      <RecurringPage
        key="Income"
        scheduledTxns={income}
        institutionLoginId={institutionLoginId}
      />
    </div>;

  const renderBills = () =>
    <div key="Bills_Page" className={classes.contentRoot}>
      <RecurringPage
        key="Bills"
        scheduledTxns={bills}
        isBill
        institutionLoginId={institutionLoginId}
      />
    </div>;

  const renderFinalRedirect = () =>
    <div className={classes.centerWrapper}>
      <Typography variant="h6">
        {"You're all set!"}
      </Typography>
      <div className={classes.recurringList}>
        <QCheck style={{ margin: 16 }} size={44} />
      </div>
    </div>;

  // # # - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - # # //
  //                                   Main Return                                   //
  // # # - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - # # //
  return (
    <StdDialog
      open
      title="Confirm recurring income and bills"
      onClose={onClose}
      showBackButton={state !== 'waiter'}
      onBack={onBack}
      sharedcomponentid={'BILL_SCOUT_DIALOG'}
      fullWidth
      maxWidth="md"
    >
      <div className={classes.root}>
        {state === 'waiter' &&
        renderBillScoutWaiter()}
        {state === 'income' &&
        renderIncome()}
        {state === 'bills' &&
        renderBills()}
        {state === 'final' &&
        renderFinalRedirect()}
        <div className={classes.bottomRow}>
          {state === 'waiter' &&
            <QButton
              variant="outlined"
              onClick={onClose}
            >
              Skip
            </QButton>}
          <QButton
            variant="contained"
            onClick={proceed}
            disabled={Boolean(loading)}
            width={120}
            style={{ marginLeft: 24 }}
          >
            Continue
          </QButton>
        </div>
      </div>
    </StdDialog>

  );

};

BillScoutDialog.defaultProps = {
};

BillScoutDialog.propTypes = {
  institutionLoginId: PropTypes.string,
  accountClientIds: PropTypes.array,
};

export default BillScoutDialog;
