import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import compose from 'utils/compose';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';

import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { ScheduledTransaction } from 'companion-app-components/flux/scheduled-transactions/scheduledTransactionsTypes';
import { STTypeNamesEnum } from 'companion-app-components/flux/scheduled-transactions/scheduledTransactionsUtils';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import CheckCircle from '@mui/icons-material/CheckCircle';
import QButton from 'components/QButton';
import QTip from 'components/QuickenControls/QTip';
import AmountField, { ShowSignEnum, ShowColorEnum } from 'components/QuickenControls/AmountField';
import { userFriendlyTxnNames } from 'data/scheduledTransactions/scheduledTransactionsUtils';
import { ScheduleTransactionSelectorStyles as styles } from 'components/Dialogs/WfBillScout/styles';
import zeroStateIcon from 'assets/zero-state-images/safebox.svg';
import ZeroStateView from 'components/ZeroStateView';
import DialogHoc, { dialogActions } from 'components/DialogHoc';


export const INCOME_STEP = 'INCOME';
export const BILLS_STEP = 'EXPENSE';
export const ALL_STEP = 'ALL';

const actions = {
  [`${BILLS_STEP}`]: 'Missing any other bills? find them from:',
  [`${INCOME_STEP}`]: 'Missing any other income?',
  [`${ALL_STEP}`]: 'Missing any other reminder?',
};

interface ScheduleTransactionSelectorProps {
  scheduledTransactions: ImmutableList<ScheduledTransaction>;
  allUpcomingTxns: ImmutableMap<string, ImmutableList<Record<string, any>>>;
  onGoBack: () => void;
  setDialog: (title: string, message: string, calbackFun: (dailog: Record<string, any>) => void, dependency: string[]) => void;
  deleteScheduleTransaction: (schTxn: ScheduledTransaction) => void;
  setEditScheduledTransaction: (schTxn: ScheduledTransaction) => void;
  createEmptyScheduleTransaction: () => void;
  selected: ImmutableList<any>;
  setSelectedItems: (type: string, selectedItems: ImmutableList<Record<string, any>>) => void;
  openTransactionSelector: () => void;
  showFindingRecurring: boolean;
  type: string;
  reviewCurrentStep: boolean;
  hideAddManually: boolean;
  baseId: (action: string) => string;
}

const useStyles = makeStyles()(styles as Record<string, any>);

interface CellTitleProps {
  id: string;
  title: string;
  parentClasses: Record<string, any>
}

const CellTitle = ({ id, title, parentClasses }: CellTitleProps) => (
  <QTip title={title} wrapOnly>
    <span id={id} className={parentClasses.cellTitle}>{title}</span>
  </QTip>
);

interface CellCaptionProps {
  id: string;
  caption: string;
  parentClasses: Record<string, any>
}

const CellCaption = ({ id, caption, parentClasses }: CellCaptionProps) => (
  <span id={id} className={parentClasses.cellCaption}>{caption}</span>
);

const ScheduleTransactionSelector: React.FC<ScheduleTransactionSelectorProps> = ({
  scheduledTransactions,
  allUpcomingTxns,
  onGoBack,
  setDialog,
  deleteScheduleTransaction,
  setEditScheduledTransaction,
  createEmptyScheduleTransaction,
  selected,
  setSelectedItems,
  openTransactionSelector,
  showFindingRecurring,
  type,
  reviewCurrentStep,
  hideAddManually,
  baseId,
}) => {

  const categoriesById = useSelector(categoriesSelectors.getCategoriesById);
  const accountsById = useSelector(accountsSelectors.getAccountsById);
  const { classes } = useStyles();

  const getSelectedItems = useCallback(() => selected || ImmutableList(), [selected]);

  useEffect(() => {
    const setInitialSelection = () => {
      const newSelectedList = getSelectedItems().toSet().toList();
      setSelectedItems(type, newSelectedList);
    };

    setInitialSelection();
  }, [scheduledTransactions, getSelectedItems, setSelectedItems, type]);

  const checkItemSelected = (id: string) => {
    const selectedItems = getSelectedItems();
    return selectedItems.findIndex((x) => x === id) >= 0;
  };

  const toggleTransactionSelection = (txnType, id: string) => () => {
    const isSelected = checkItemSelected(id);
    let selectedItems = getSelectedItems();

    if (isSelected) {
      selectedItems = selectedItems.filterNot((x) => x === id);
    } else {
      selectedItems = selectedItems.push(id);
    }

    setSelectedItems(txnType, selectedItems);
  };

  const handleDelete = (scheduledTransaction) => () => {
    setDialog(
      'Bills and Income',
      'Do you want to delete this schedule transaction?',
      (dialog) => {
        if (['YES', dialogActions.ENTER].includes(dialog.action)) {
          deleteScheduleTransaction(scheduledTransaction);
        }
      },
      ['CANCEL', 'YES'],
    );
  };

  const renderRow = (scheduledTransaction, key, reviewCurrStep) => {
    const selectedRow = checkItemSelected(scheduledTransaction.id);
    const upTxn = allUpcomingTxns.get(scheduledTransaction && scheduledTransaction.id);
    const schTxn = userFriendlyTxnNames(scheduledTransaction, categoriesById, accountsById, (upTxn && upTxn.first()));
    const baseID = `scheduled-txn-${key}`;

    return (
      <TableRow
        key={scheduledTransaction.clientId || scheduledTransaction.id}
        id={baseID}
        hover
        className={classes.standardRow}
      >
        <TableCell className={classes.tableCellAvatar} classes={{ root: classes.tableCellRoot }}>
          <div className={classes.tableCellAvatarContent}>
            <Avatar className={classes.avatar}>
              {((scheduledTransaction.transaction || {}).payee || '')[0]}
            </Avatar>
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} classes={{ root: classes.tableCellRoot }}>
          <div className={classes.tableCellPayeeContent}>
            <CellTitle id={`${baseID}-name`} title={schTxn.payeeName} parentClasses={classes} />
            <CellCaption id={`${baseID}-type`} caption={STTypeNamesEnum[schTxn.type]} parentClasses={classes} />
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} classes={{ root: classes.tableCellRoot }}>
          <div className={classes.tableCellAmountContent}>
            <AmountField
              showSign={ShowSignEnum.NEGATIVE_ONLY}
              showColor={ShowColorEnum.POSITIVE_ONLY}
              value={schTxn.amount}
              className={classes.cellTitle}
              id={`${baseID}-amount`}
            />
            <CellCaption id={`${baseID}-next-instance-date`} caption={schTxn.nextDateFormatted} parentClasses={classes} />
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} classes={{ root: classes.tableCellRoot }}>
          <div className={classes.tableCellAccountContent}>
            <CellTitle id={`${baseID}-source-account`} title={schTxn.accountName} parentClasses={classes} />
            <CellCaption id={`${baseID}-frequency`} caption={schTxn.frequency} parentClasses={classes} />
          </div>
        </TableCell>

        <TableCell className={classes.tableCellActions} classes={{ root: classes.tableCellRoot }}>
          <div className={classes.tableCellActionsContent}>
            <IconButton
              onClick={() => { setEditScheduledTransaction(scheduledTransaction); }}
              size="large"
            >
              <Edit />
            </IconButton>
            {reviewCurrStep && (
              <IconButton onClick={handleDelete(scheduledTransaction)} size="large">
                <Delete />
              </IconButton>
            )}
            {!reviewCurrStep && !scheduledTransaction.isUserVerified && (
              <IconButton
                onClick={toggleTransactionSelection(type, scheduledTransaction.id)}
                aria-label="select"
                size="large"
              >
                {selectedRow ? (
                  <CheckCircle className={classes.selectedIcon} />
                ) : (
                  <AddIcon className={classes.selectIcon} />
                )}
              </IconButton>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  };

  interface ButtonInterface {
    id: string;
    onClick: () => void;
    text: string;
    show: boolean;
  }

  const renderAddMissing = (actionType, reviewCurrStep) => {
    const actionTitle = actions[actionType];

    const buttons: ButtonInterface[] = [
      {
        id: baseId('action-back-button'),
        onClick: onGoBack,
        text: 'Suggestions',
        show: true,
      },
      {
        id: baseId('find-transactions-button'),
        onClick: openTransactionSelector,
        text: 'Transactions',
        show: true,
      },
      {
        id: baseId('add-manual-button'),
        onClick: createEmptyScheduleTransaction,
        text: 'Add Manually',
        show: !hideAddManually,
      },
    ].filter((x) => x.show);

    return (
      <Collapse in={reviewCurrStep} classes={{ root: classes.collapseArea, wrapper: classes.collapseAreaWrapper }}>
        <Grid container className={classes.bottomActionsTitleContainer}>
          <Grid item className={classes.missingTypeTitleTop}>{actionTitle}</Grid>
          <Grid item className={classes.bottomActionsRoot}>
            {buttons && buttons.map((button, index) => (
              <React.Fragment key={button.id}>
                {buttons.length === index + 1 && (
                  <span className={classes.missingTypeTitleTop}>or</span>
                )}
                <QButton
                  className={classes.wideButton}
                  id={button.id}
                  variant="outlined"
                  onClick={button.onClick}
                >{button.text}
                </QButton>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Collapse>
    );
  };

  const renderZeroState = (reviewCurrStep) => !showFindingRecurring ? (
    <ZeroStateView
      icon={zeroStateIcon}
      primary={!reviewCurrStep ? 'We couldn\'t find any suggestion' : 'You don\'t have any transactions to review'}
      classes={{ root: classes.zeroRoot }}
    />
  ) : (
    <div className={classes.waiterRoot}>
      <p className={classes.waiterTitle}>Hang Tight!</p>
      <p className={classes.waiterText}>We are finding your recurring bills and income.</p>
      <div className={classes.waiterCircularRoot}>
        <CircularProgress size={40} style={{ margin: 10 }} />
        <p className={classes.waiterText}>This should only take a few moments.</p>
      </div>
    </div>
  );

  const currentSchTxn = scheduledTransactions.filter((x) => reviewCurrentStep ? x.isUserVerified : !x.isUserVerified);

  return (
    <Grid container className={classes.scheduleTxnSelectorRoot}>
      <Grid item className={classes.scheduleTxnSelectorWrapper}>
        {!currentSchTxn.size ?
          renderZeroState(reviewCurrentStep) :
          <Table>
            <TableBody>
              {
                currentSchTxn && currentSchTxn.size > 0 &&
                currentSchTxn.valueSeq().map((txn, key) => renderRow(txn, key, reviewCurrentStep))
              }
            </TableBody>
          </Table>}
      </Grid>
      <Grid item>
        {renderAddMissing(type, reviewCurrentStep)}
      </Grid>
    </Grid>
  );
};

export default compose(
  DialogHoc(),
)(ScheduleTransactionSelector);
