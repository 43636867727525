// @flow
import * as React from 'react';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fade from '@mui/material/Fade';
import Fab from '@mui/material/Fab';

import QButton from 'components/QButton';
import ReportProblemLink from 'components/ReportProblemLink';

import SafeBoxImage from 'assets/zero-state-images/safebox.svg';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.greyScaleDeprecated[2],
    height: '100%',
    fontSize: 18,
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'none',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  primary: {
    marginBottom: 16,
    fontWeight: 500,
  },
  secondary: {
    maxWidth: '80%',
    textAlign: 'center',
    lineHeight: 'normal',
    marginBottom: 16,
  },
  button: {
    marginBottom: 16,
  },
  reportProblem: {
    marginBottom: 16,
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

type Props = {
  onClick: (Event) => void,
  icon: ?any,
  style: object,

  primary: ?(string | React.Node),
  primaryTypographyProps: ?object,

  secondary: ?(string | React.Node),
  secondaryTypographyProps: ?object,

  children?: React.Node,
  noBreaks: ?boolean,

  textOnTop: ?boolean,
  iconSize: object,
  center: ?boolean,
  // from withStyles
  classes: object,
  className: string,
  size: ?('small' | 'large'),
  button: ?any,
  buttonVariant: ?string,
  report: ?boolean,
  in: ?boolean,
};

// we set this to transparent for now, don't seem to need it and don't want clutter on load
const ZeroStateView = (props: Props) => {
  const {
    children,
    classes,
    icon,
    onClick,
    primary: primaryProp,
    primaryTypographyProps,
    secondary: secondaryProp,
    secondaryTypographyProps,
    textOnTop,
    iconSize,
    className,
    noBreaks,
    size,
    center,
    button,
    buttonVariant,
    in: show = true,
    report,
    ...other
  } = props;

  let primary = primaryProp;
  // $FlowFixMe
  if (primary != null && primary.type !== Typography) {
    primary = (
      <Typography
        variant={size === 'small' ? 'subtitle2' : 'body1'}
        align="center"
        className={classes.primary}
        {...primaryTypographyProps}
      >
        {primary}
      </Typography>
    );
  }

  let secondary = secondaryProp;

  // $FlowFixMe
  if (secondary != null && secondary.type !== Typography) {
    secondary = (
      <Typography
        variant="body2"
        align="center"
        color="textSecondary"
        className={classes.secondary}
        {...secondaryTypographyProps}
      >
        {secondary}
      </Typography>
    );
  }

  return (
    <Fade in={show} mountOnEnter unmountOnExit timeout={500}>
      <div
        className={classNames(classes.root,
          !button && onClick ? classes.clickable : null,
          center ? classes.center : null,
          className)}
        onClick={button ? undefined : onClick}
        role="none"
        style={props.style}
        sharedcomponentid={'ZERO_STATE_VIEW'} // eslint-disable-line
        {...other}
      >
        {textOnTop &&
        <>
          {primary}
          {secondary}
          {!noBreaks &&
            <br />}
        </>}

        {icon &&
          <>
            <img
              alt="nothing to report"
              src={icon}
              width={iconSize?.width ?? (size === 'small' ? 60 : 168)}
              height={iconSize?.height ?? (size === 'small' ? 60 : 168)}
            />
            {!noBreaks &&
              <br />}
          </>}

        {!textOnTop &&
        <>
          {primary}
          {secondary}
        </>}

        {children}

        {button &&
        <Zoom in={Boolean(button)} style={{ transitionDelay: '300ms' }} mountOnEnter unmountOnExit>
          <Fab
            sx={{
              all: 'unset',
              '&:hover, &:focus, &:active': {
                all: 'unset',
              },
            }}
            disableRipple
            disableFocusRipple
          >
            {typeof button === 'object' ?
              button
              :
              <QButton
                variant={buttonVariant || 'contained'}
                onClick={onClick}
                className={classes.button}
              >
                {typeof button === 'boolean' ? 'Try Again' : button}
              </QButton>}
          </Fab>
        </Zoom>}

        {report &&
        <ReportProblemLink
          feedbackSubject={typeof primaryProp === 'string' ? primaryProp : 'report state'}
          className={classes.reportProblem}
        />}
      </div>
    </Fade>
  );
};

ZeroStateView.defaultProps = {
  icon: SafeBoxImage,
  size: 'large',
};

export default withStyles(ZeroStateView, styles);
