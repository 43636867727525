import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

import { localPreferences, getHostConfig } from 'companion-app-components/utils/core';

import Box from '@mui/material/Box';

import LoadingView from 'components/LoadingView';
import ZeroStateView from 'components/ZeroStateView';
import { client } from 'isAcme';
import quickenLogo from 'assets/quicken-logo.svg';

const homepageURL = getHostConfig().homepage_url;

const Login = () => {
  const navigate = useNavigate();

  const [showBadBookmarkError, setShowBadBookmarkError] = useState();
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query.code && query.session_state) {
      if (window === window.top) { // normal app mode (not inside iframe) - bad bookmark
        setShowBadBookmarkError(true);
        navigate('/login', { replace: true }); // trim query params
      } else {
        const remoteSessionState = atob(decodeURIComponent(query.session_state));
        localPreferences.setKeepLoggedIn(Boolean(query.remember_me));
        window.parent?.postMessage({
          type: 'auth',
          code: query.code,
          sessionState: remoteSessionState,
          isIframe: window !== window.top,
        }, window.origin); // send message event to iframe parent
      }
    } else if (window === window.top) { // normal app mode (not inside iframe)
      setShowBadBookmarkError(false);
      navigate('/', { replace: true }); // redirect if not inside iframe
    }
  }, [navigate]);

  return showBadBookmarkError ?
    <>
      <Helmet>
        <title>Login</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content={`Welcome to ${client} personal finance}`} />
      </Helmet>

      <Box p={2}>
        <img
          alt="logo"
          src={quickenLogo}
          height={48}
        />
      </Box>

      <ZeroStateView
        center
        primary="We detected an error with the URL you are using to sign in."
        secondary={
          <>
            If you have this page bookmarked, please replace your bookmark with
            &nbsp;<a href={homepageURL}>{homepageURL}</a>
            .
          </>
        }
        button={`Sign In to ${client}`}
        onClick={() => {
          navigate('/', { replace: true });
        }}
      />
    </> :
    <LoadingView />;
};

export default Login;
