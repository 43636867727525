import React, { ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import { accountsTypes } from 'companion-app-components/flux/accounts';

interface TableAccountRowProps {
  columns: Record<string, any>;
  classes: Record<string, string>;
  account: accountsTypes.AccountProps;
  selected: boolean;
  onChangeAccountsToShow: (id: string)=>void;
}

const TableAccountRow: React.FC<TableAccountRowProps> = (props: TableAccountRowProps) => {
  const { columns, classes, account, onChangeAccountsToShow, selected } = props;

  const onCheckboxChange = useCallback(() => onChangeAccountsToShow(account.id),
    [onChangeAccountsToShow, account]);

  return (
    <TableRow className={classes.tableBodyRow} onClick={onCheckboxChange}>
      {Object.keys(columns).map((key: string) => {
        let cell: ReactNode;

        switch (key) {
          case 'checkBoxCol':
            cell = (
              <div className={classes.checkBoxColContainer}>
                <Checkbox
                  color="default"
                  checked={selected}
                  inputProps={{ 'aria-labelledby': `check-account-${account.id}` }}
                />
              </div>
            );
            break;
          case 'accountCol':
            cell = <div className={classes.accountColContainer}>{account.name}</div>;
            break;
          case 'typeCol':
            cell = <div className={classes.typeColContainer}>{account.type}</div>;
            break;
          case 'descriptionCol':
            cell = <div className={classes.descriptionColContainer}>{account.description}</div>;
            break;
          default: break;
        }

        return (
          <TableCell key={`cell-${account.id}-${key}`} className={classNames(classes.tableBodyCell, classes?.[key])}>
            {cell}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default React.memo(TableAccountRow);
