// @flow
import React from 'react';

import { makeStyles } from 'tss-react/mui';

import ShowMoreText from 'react-show-more-text';

const useStyles = makeStyles()((theme) => ({
  anchor: {
    backgroundColor: theme.applyOpacityToHex(theme.palette.error.main, 0.1),
    float: 'right',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: theme.applyOpacityToHex(theme.palette.error.main, 0.25),
    },
  },
  errorBox: {
    backgroundColor: theme.applyOpacityToHex(theme.palette.error.main, 0.1),
    border: `1px solid ${theme.applyOpacityToHex(theme.palette.error.main, 0.5)}`,
    borderRadius: '5px',
    marginBottom: 12,
    padding: '3px 5px',
  },
  errorStatusCode: {
    ...theme.typography.caption,
    paddingTop: theme.spacing(1),
  },
  errorText: {
    ...theme.typography.caption,
  },
  spacer: {
    paddingTop: theme.spacing(0.5),
  },
}));

type Props = {
  error: any,
}

const ErrorContentView = (props: Props) => {
  const { error } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.errorBox}>
      <ShowMoreText
        lines={2}
        more="Show More"
        less="Show Less"
        className={classes.errorText}
        anchorClass={classes.anchor}
        expanded={false}
      >
        {error.text}
        {error.statusCode &&
          <>
            <br className={classes.spacer} />
            <span className={classes.errorStatusCode}>
              {` Care Code: ${error.statusCode}`}
            </span>
          </>}
      </ShowMoreText>
    </div>
  );
};

export default ErrorContentView;
