import { Map as ImmutableMap } from 'immutable';
import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { DIALOG_TYPE as DIALOG_TYPE_WF_BILLS_SCOUT } from './types';

export const doWFBillsScout = createAction(
  createDialog.toString(),
  () => mkRootUiData({
    id: 'DIALOG_WEBFIRST_BILLS_SCOUT',
    type: DIALOG_TYPE_WF_BILLS_SCOUT,
    allowNesting: false,
  }),
);

export const doWFBillsScoutBillIncomeFlow = createAction(
  createDialog.toString(),
  (payload) => mkRootUiData({
    id: 'DIALOG_WEBFIRST_BILLS_SCOUT_INCOME_FLOW',
    type: DIALOG_TYPE_WF_BILLS_SCOUT,
    allowNesting: false,
    props: ImmutableMap({
      ...payload,
      billsIncomeFlow: true,
    }),
  }),
);
