import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'utils/compose';
import { useSelector, shallowEqual } from 'react-redux';

import { authSelectors } from 'companion-app-components/flux/auth';

import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/CheckRounded';
import HelpIcon from '@mui/icons-material/HelpOutlineRounded';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import withStdForm from 'data/StdForm';
import StdFormDialog from 'components/Dialogs/StdFormDialog';
import QTooltip from 'components/QTooltip';
import { WFDesktopFeatures as styles } from 'components/Dialogs/WFDesktopFeatures/styles';
import { featureList, featureState } from './featuresConfig';
import SingleFeatureModal from './SingleFeatureModal';
import DownloadButton from './DownloadButton';


const OSLabel = {
  mac: 'Mac',
  win: 'Windows',
};
const WFDesktopFeatures = (props) => {
  const { classes } = props;

  const datasetObject = useSelector((state) => authSelectors.getCurrentDataset(state), shallowEqual);
  const isMac = datasetObject && datasetObject.platform === 'QMAC_MWF';

  const [OSValue, setOSValue] = useState(isMac);
  const [featureModal, setFeatureModal] = useState(undefined);

  const OSBrandKey = OSValue ? 'mac' : 'win';
  const OSBrand = OSLabel[OSBrandKey];

  const FAQDesktopLink = 'https://www.quicken.com/quicken-tips';
  const FAQLink = 'https://www.quicken.com/support/quicken-web-frequently-asked-questions';
  const FAQMobileLink = 'https://www.quicken.com/how-use-quicken-mobile-companion-app';

  const renderTitle = () =>
    <div className={classes.headlineTitle}>Do more with Quicken Desktop</div>;
  const renderTooltip = (title, content) => (
    <>
      <div className={classes.tooltipTitle}>{title}</div>
      <div className={classes.tooltipContent}>{content}</div>
    </>
  );
  const renderFeatureIcon = (featureValue) => {
    let className;
    if (featureValue === featureState.available) {
      className = classes.checkAvailable;
    } else if (featureValue === featureState.partial) {
      className = classes.checkPartial;
    } else {
      className = classes.checkNone;
    }
    return featureValue && <CheckIcon className={className} />;
  };

  const onURLOpener = (url) => () => window.open(url, '_blank');
  const openModal = (featureValue) => () => setFeatureModal(featureValue);
  const closeModal = () => setFeatureModal(undefined);

  return (
    <StdFormDialog
      className={classes.dialog}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'xl',
      }}
      showCloseButton
      title={renderTitle()}
      dialogId="WFDesktopFeatures"
    >
      <Grid container className={classes.root}>
        <p>
          Your Quicken is available on Desktop (Win & Mac), Mobile, & Web. <br /> <br />
          As a companion to your desktop software, you can now access key features of your Quicken
          desktop software from any computer via the web. Keep your finances continually up to date:
          your accounts sync across desktop, web, and mobile. While you can use Quicken on the go
          with the mobile and web app, you can do much more with the desktop apps as listed below.
        </p>
        <div className={classes.OSSelectorRoot}>
          <Grid component="label" className={classes.OSSelector}>
            <span className={classNames({ [classes.OSSelectorFadeLabel]: OSValue })}>WIN</span>
            <Switch
              checked={OSValue}
              onChange={() => setOSValue(!OSValue)}
              classes={{
                thumb: classes.switchThumb,
                track: classes.switchTrack,
              }}
            />
            <span className={classNames({ [classes.OSSelectorFadeLabel]: !OSValue })}>MAC</span>
          </Grid>
        </div>
        <Paper className={classes.featuresRoot}>
          <Grid container className={classes.featuresHolder}>
            <Grid item className={classes.featuresTable}>
              <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableCell}>Features</TableCell>
                    <TableCell className={classes.tableCell} align="center">Quicken on the Web</TableCell>
                    <TableCell className={classes.tableCell} align="center">Quicken {OSBrand}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {featureList[OSBrandKey] && featureList[OSBrandKey].map(({ features, ...feature }) => (
                    <TableRow key={feature.id} className={classes.tableRow}>
                      <TableCell className={classNames(classes.tableCell)} component="th" scope="row">
                        <div className={classes.cellFeature}>
                          <span>{feature.label}</span>
                          <QTooltip content={renderTooltip(feature.label, feature.description)}>
                            <IconButton onClick={openModal(feature)} size="large">
                              <HelpIcon className={classes.helpIcon} />
                            </IconButton>
                          </QTooltip>
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {renderFeatureIcon(features.web)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {renderFeatureIcon(features[OSBrandKey])}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item className={classes.rightContent}>
              <DownloadButton overwriteOS={OSBrand} />
              <div className={classes.linkGroup}>
                <Link onClick={onURLOpener(FAQDesktopLink)} className={classes.link}>Learn more about Quicken Desktop</Link>
                <Link onClick={onURLOpener(FAQLink)} className={classes.link}>How to sync with desktop?</Link>
                <Link onClick={onURLOpener(FAQMobileLink)} className={classes.link}>How to access Quicken Mobile?</Link>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.legendRoot}>
          <span className={classes.legendText}><CheckIcon className={classes.checkAvailable} /> Available</span>
          <span className={classes.legendText}><CheckIcon className={classes.checkPartial} /> Partial/View only</span>
          <span className={classes.legendText}><CheckIcon className={classes.checkNone} /> Not available</span>
        </div>
      </Grid>
      <SingleFeatureModal onClose={closeModal} feature={featureModal} />
    </StdFormDialog>
  );
};

WFDesktopFeatures.propTypes = {
  // from withStyles
  classes: PropTypes.object,
};

export default compose(
  withStdForm({ type: 'WFDesktopFeatures' }),
)(withStyles(WFDesktopFeatures, styles));
