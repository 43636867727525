// @flow

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import { removeDialog } from 'data/rootUi/actions';

import StdDialog from 'components/Dialogs/StdDialog';
import QPanelButton from 'components/QPanelButton';
import RenameRuleDialogForm from 'components/Forms/RenameRuleDialogForm';
import MemorizedRuleDialogForm from 'components/Forms/MemorizedRuleDialogForm';
import RenamingRulesSettingsSimplifiIcon from 'assets/checklist-rules.svg';

export const DIALOG_TYPE_ADD_RULE = 'DIALOG_TYPE_ADD_RULE';

const AddRuleDialog = React.memo((props) => {
  const { onClose, dialogId, open, ...otherProps } = props;
  const dispatch = useDispatch();

  const [viewExistingTransactions, setViewExistingTransactions] = useState();

  const onCloseProxy = useCallback((_event, reason) => {
    onClose?.(reason);
    if (open === undefined && dialogId) { // uncontrolled version of dialog
      dispatch(removeDialog(dialogId));
    }
  }, [dispatch, dialogId, onClose, open]);

  const [slideIndex, setSlideIndex] = useState(0);

  let dialogTitle = 'Add a new rule';
  if (slideIndex === 1) dialogTitle = 'New rename rule';
  if (slideIndex === 2) dialogTitle = 'New category rule';
  if (viewExistingTransactions) dialogTitle = 'Existing transactions';

  return (
    <StdDialog
      title={dialogTitle}
      dialogId={dialogId}
      open={open}
      onClose={onCloseProxy}
      disableBackdropClick
      disableEscapeKeyDown
      showBackButton={Boolean(slideIndex)}
      onBack={() => viewExistingTransactions ? setViewExistingTransactions(false) : setSlideIndex(0)}
      sharedcomponentid={'DIALOG_TYPE_ADD_RULE'}
      maxWidth="md"
      fullWidth
      {...otherProps}
    >
      {
        slideIndex === 0 &&
          <DialogContent>
            <Box display="flex" minHeight={400}>
              <img style={{ height: 95 }} src={RenamingRulesSettingsSimplifiIcon} alt="checklist" />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                alignContent="stretch"
                paddingLeft={4}
                minWidth={530}
              >

                <Box display="flex" alignItems="center" mb={2} mt={1}>
                  <Typography component={Box} color="textSecondary" style={{ marginRight: 8 }}>
                    <TimerRoundedIcon color="textSecondary" />
                  </Typography>
                  <Typography variant="body1">
                    Rules can also be created when you edit an existing transaction.
                  </Typography>
                </Box>

                <Typography variant="body2" color="textSecondary">
                  When you start with a blank rule, it helps to know exactly how your payee name appears when it comes in from the bank.
                </Typography>

                <Box padding={2} />

                <QPanelButton
                  id={'add-payee-rule'}
                  onClick={() => setSlideIndex(1)}
                  title="New payee name rule"
                  subtitle="Set keywords to rename payees so new transactions appear how you want"
                />
                <QPanelButton
                  id={'add-category-rule'}
                  onClick={() => setSlideIndex(2)}
                  title="New category rule"
                  subtitle="Set a default category for new transactions from a specific payee"
                />

              </Box>
            </Box>
          </DialogContent>
      }

      {slideIndex === 1 && (
        <Box>
          <RenameRuleDialogForm
            renameRule={{}}
            onClose={onCloseProxy}
            viewExistingTransactions={viewExistingTransactions}
            setViewExistingTransactions={setViewExistingTransactions}
          />
        </Box>
      )}

      {slideIndex === 2 && (
        <Box>
          <MemorizedRuleDialogForm
            memorizedRule={{}}
            onClose={onCloseProxy}
            viewExistingTransactions={viewExistingTransactions}
            setViewExistingTransactions={setViewExistingTransactions}
          />
        </Box>
      )}
    </StdDialog>
  );
});

AddRuleDialog.propTypes = {
  renameRule: PropTypes.object,
  transaction: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool, // set manually for controlled dialog
  dialogId: PropTypes.string, // set automatically for uncontrolled dialog
};

export default AddRuleDialog;
