import React from 'react';
import { localPreferences } from 'companion-app-components/utils/core';

/////////////////////////////////////////////
if (localPreferences.getWhyDidYouRender()) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render'); // eslint-disable-line global-require
  // TODO trackExtraHooks cannot set property of #<Object> which has only a getter
  // const ReactRedux = require('react-redux'); // eslint-disable-line global-require
  const { virtualIncludeAll, ...localWDYRSettings } = localPreferences.getWDYRSettings();

  const wdyrSettings = {
    ...localWDYRSettings,
    ...(virtualIncludeAll && { include: [/.*/] }),
    // trackExtraHooks: [
    //   [ReactRedux, 'useSelector'],
    // ],
    // hotReloadBufferMs: 500,
    // include: [
    //   /.*/,
    // ],
    // exclude: [
    //   /.*/,
    // ],
    // titleColor
    // diffNameColor
    // diffPathColor
  };
  whyDidYouRender(React, wdyrSettings);
}
/////////
