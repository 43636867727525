import { useEffect, useRef } from 'react';

const useEvent = (eventType, eventListener, element = window) => {
  const listenerRef = useRef();
  
  useEffect(() => {
    listenerRef.current = eventListener;
  }, [eventListener]);

  useEffect(() => {
    const localListener = (event) => listenerRef.current?.(event);
    element?.addEventListener?.(eventType, localListener);
    return () => {
      element?.removeEventListener?.(eventType, localListener);
    };
  }, [element, eventType]);
};
export default useEvent;
