import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Immutable from 'immutable';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTimeout } from 'react-use';
import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { formatAmount } from 'components/QCurrency/core';
import { filterCalendarTxnBasedOnPreferences } from 'data/transactions/selectors';
import TransactionLabel from '../TransactionLabel';
import { calDayStyles } from '../../styles';



const useStyles = makeStyles()(calDayStyles as Record<string, any>);

const maxTxnsDefault = 3;
const hoverDelay = 500;

interface CalDayProps {
  day: number;
  date: any;
  currentMonth: boolean;
  currentDay: boolean;
  txnList: Record<string, any>;
  setPopper: (ref: string | undefined | null, obj: Record<string, any> | null) => void;
  balances: Record<string, any>;
  onTxnsModalOpen: (date: Record<string, any>, obj: Record<string, any> | undefined, amount: string | undefined) => void;
}

const CalDay:React.FC<CalDayProps> = (props: CalDayProps) => {
  const { classes, cx } = useStyles();
  const dayRef = useRef<any>();

  const { day, currentMonth, currentDay, txnList, setPopper, date, balances, onTxnsModalOpen } = props;
  const filteredTxnList: Immutable.List<transactionsTypes.CashFlowTransaction> =
    useSelector((state) => filterCalendarTxnBasedOnPreferences(state, { txnList }));
  const [isReady, cancelTimeout, resetTimeout] = useTimeout(hoverDelay);
  const [hoveringContent, setHoveringContent] = useState<string>('');

  const showDailyBalances = Boolean(balances);

  const maxTxns = showDailyBalances ? maxTxnsDefault - 1 : maxTxnsDefault;
  const showMoreLabel = useMemo(() => filteredTxnList?.size > maxTxns, [filteredTxnList, maxTxns]);

  const isReadyFlag = isReady();

  const { positiveBalance, negativeBalance } = classes;
  const getBalanceClass =
    useCallback((amount: number) =>
      (amount >= 0.0 ? positiveBalance : negativeBalance), [positiveBalance, negativeBalance]);

  const onMouseEnter = useCallback(() => {
    setHoveringContent('full');
  }, [setHoveringContent]);

  const onMouseLeaveParent = useCallback(() => {
    if (hoveringContent) {
      setHoveringContent('');
    }
  }, [hoveringContent, setHoveringContent]);

  const txnsTotal: number = useMemo(() =>
    filteredTxnList && filteredTxnList.reduce((acc, txn) => acc + parseFloat(txn.amount ?? 0), 0), [filteredTxnList]);

  const cachedOnTxnsModalOpen = useCallback(() =>
    onTxnsModalOpen &&
    onTxnsModalOpen(date, filteredTxnList, formatAmount(txnsTotal)),
  [onTxnsModalOpen, date, txnsTotal, filteredTxnList]);
  
  const smallTxnList = useMemo(() =>
    showMoreLabel ?
      filteredTxnList.slice(0, maxTxns) :
      filteredTxnList, [showMoreLabel, filteredTxnList, maxTxns]);
  const dailyBalancesClassNames =
    cx(classes.dailyBalanceLabel,
      { [classes.dailyBalanceLabelFloating]: showMoreLabel },
      { [classes.negativeBalance]: Math.sign(balances?.total) < 0 });

  const renderedSmallTxnList = useMemo(() => smallTxnList &&
    smallTxnList.map((txn) => <TransactionLabel key={txn.id} txn={txn} />), [smallTxnList]);

  const renderedFilteredTxnList = useMemo(() =>
    filteredTxnList && filteredTxnList.map((txn) => <TransactionLabel key={txn.id} txn={txn} />), [filteredTxnList]);

  const renderedBalanceList = useMemo(() => balances && [...balances.accountsBalances.keys()].map((key) => {
    const { balance } = balances.accountsBalances.get(key);
    const balanceClass = getBalanceClass(balance);
    return (
      <div key={key} className={classes.balanceLabelRoot}>
        <span className={classes.balanceAccountLabel}>{balances.accountsBalances.get(key).name}</span>
        <span className={cx(balanceClass, classes.balanceAmountLabel)}>{formatAmount(balance)}</span>
      </div>
    );
  }), [balances, getBalanceClass, classes, cx]);

  const renderBalancePopper = useCallback(() => {
    const totalClass = getBalanceClass(balances?.total);

    return (
      <>
        <div className={classes.popperTxnList}>
          {renderedBalanceList && renderedBalanceList}
        </div>
        <Divider className={classes.balanceDivider} />
        <div className={classes.balanceLabelRoot}>
          <strong>Balance</strong>
          <strong className={totalClass}>{formatAmount(balances?.total)}</strong>
        </div>
      </>
    );
  }, [balances, classes, getBalanceClass, renderedBalanceList]);

  const renderTxnPopper = useCallback(() => (
    filteredTxnList.size > 0 ? (
      <>
        <div className={classes.popperTxnList}>
          {renderedFilteredTxnList}
        </div>
        <Divider className={classes.balanceDivider} />
        <div className={classes.balanceLabelRoot}>
          <strong>Total</strong>
          <strong>{formatAmount(txnsTotal)}</strong>
        </div>
      </>
    ) : <div className={classes.popperTxnEmptyList}>No transactions</div>
  ), [classes, filteredTxnList, txnsTotal, renderedFilteredTxnList]);

  const popoverContents = useCallback(() => ({
    header: <header className={classes.popperHeader}>{date.toLocaleString(DateTime.DATE_HUGE)}</header>,
    transactions: renderTxnPopper(),
    balance: renderBalancePopper(),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [classes, date, renderTxnPopper, renderBalancePopper, filteredTxnList]);

  useEffect(() => {
    const isHovering = Boolean(hoveringContent);
    if (!isHovering) {
      cancelTimeout();
    }

    if (isHovering && isReady() === null) {
      resetTimeout();
    }

    if (isHovering) {
      if (isReady() && hoveringContent === 'full') {
        setPopper(dayRef.current, { hoveringContent, content: popoverContents() });
      }
    }
  }, [isReadyFlag, isReady, hoveringContent, cancelTimeout, resetTimeout, popoverContents, setPopper]);

  return (
    <Grid
      component="div"
      ref={dayRef}
      className={cx(classes.day, { [classes.notCurrentMonth]: !currentMonth })}
      onClick={cachedOnTxnsModalOpen}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeaveParent}
    >
      <header className={classes.header}>
        <span className={cx(classes.dayIndicator, { [classes.currentDay]: currentDay })}>{day}</span>
      </header>
      {smallTxnList && (
        <div className={classes.txnWrapper}>
          {renderedSmallTxnList && renderedSmallTxnList}
          {showMoreLabel && (
            <TransactionLabel key="more-label" showMoreLabel={showMoreLabel} extraTxns={filteredTxnList.size - maxTxnsDefault} />
          )}
          {showDailyBalances && (
            <div
              id={`balance-label-${date.toISODate()}`}
              data-hover="balance-label"
              className={dailyBalancesClassNames}
            >{formatAmount(balances.total)}
            </div>
          )}
        </div>
      )}
    </Grid>
  );
};

export default React.memo(CalDay);
