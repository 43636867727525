// @flow
import { Record, OrderedSet, Map as ImmutableMap } from 'immutable';
import type { RecordFactory, RecordOf } from 'immutable';
import { DateTime } from 'luxon';

type AppStoreProps = {
  sessionStartedAt: string,
  storeCreatedAt: string,
  isOffline: boolean,
  serviceWorkerIsRegistered: boolean,
  serviceWorkerIsRegistering: boolean,
  serviceWorkerUpdateIsAvailable: boolean,
  awaitingToAutoRefresh: boolean,
  updateAllFailed: ?boolean,
  updateAllMessages: ?OrderedSet<string>,
  isSanitized: ?bool,
  csvImportCompleteByClientId: ?ImmutableMap,
  csvImportErrorByClientId: ?ImmutableMap,
  dbUpdateTrigger: boolean,
  fetchInProgress: boolean,
}
export type AppStore = RecordOf<AppStoreProps>;
export const mkAppStore: RecordFactory<AppStoreProps> =
  Record({
    sessionStartedAt: DateTime.utc().toISO(),
    storeCreatedAt: DateTime.utc().toISO(),
    isOffline: false,
    serviceWorkerIsRegistered: false,
    serviceWorkerIsRegistering: false,
    serviceWorkerUpdateIsAvailable: false,
    awaitingToAutoRefresh: false,
    updateAllFailed: undefined,
    updateAllMessages: undefined,
    isSanitized: undefined,
    csvImportCompleteByClientId: ImmutableMap(),
    csvImportErrorByClientId: ImmutableMap(),
    dbUpdateTrigger: undefined, // dev only hack to trigger DB updates for wdyr
    fetchInProgress: undefined,
  });

