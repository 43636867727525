// @flow
import React, { useLayoutEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  content: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      WebkitBoxShadow: '0 0 1px rgba(245, 245, 245, 0.5)',
    },
  },
  divider: {
    backgroundColor: theme.palette.grey.level3,
  },
}));

type Props = {
  children: React.node,
  height?: number | string,
  minHeight?: number | string,
  useContainerFlexHeight?: boolean,
};

const StdDialogContent = (props: Props) => {
  const { children, height, minHeight, useContainerFlexHeight, ...other } = props;

  const [requiresScrolling, setRequiresScrolling] = useState(false);
  const { classes } = useStyles();

  const contentRef = useRef();
  useLayoutEffect(() => {
    const { current } = contentRef;
    setRequiresScrolling(current.scrollHeight > current.clientHeight);
  }, [setRequiresScrolling]);

  const heightValue = height || ((minHeight || useContainerFlexHeight) ? '0px' : '100%');

  return (
    <>
      <Box
        className={classes.content}
        display="flex"
        flex="1 1 auto"
        flexDirection="column"
        height={heightValue}
        ref={contentRef}
        mt={3}
        mx={3}
        mb={1}
        {...other}
      >
        { children }
      </Box>
      {requiresScrolling && <Divider className={classes.divider} />}
    </>
  );
};

export default StdDialogContent;
