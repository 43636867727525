
// BASE
import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';

// CUSTOM COMPONENTS
import QCloseBox from 'components/QCloseBox';
import CategoryReviewCard from 'components/CategoryReviewCard';
import QTabsHorizontal from 'components/QTabsHorizontal';

// SELECTORS, ACTIONS, UTILS

import { formatNumber } from 'components/QuickenControls/AmountField';

// MUI COMPONENTS
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

// PATH RELATIVE IMPORTS
import { styles } from './styles';

// PROP TYPES
const propDefaults = {
  currency: 'USD',
  numMonthsDefault: '3_MONTHS_PRIOR',
};
const propTypes = {
  coa: PropTypes.object,
  coaIds: PropTypes.array,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  currency: PropTypes.string,
  accountIds: PropTypes.object,  // null means all accounts
  onSetValueFromGraph: PropTypes.func,
  numMonthsDefault: PropTypes.string,
  open: PropTypes.bool,
  excludeCategoryInTooltip: PropTypes.bool,
  rootStyle: PropTypes.object, // styles applied to root div inside popup
};

// STYLES HOOK CREATION
const useStyles = makeStyles()(styles);

const CategoryReviewPopupCard = (props) => {

  // PROPS ============================================================================
  const { anchorEl, onClose, coa, coaIds, currency, accountIds, onSetValueFromGraph, numMonthsDefault, open, excludeCategoryInTooltip, ...other } = props;

  // STATE ============================================================================
  const [format, setFormat] = useState(['history']);
  const [coaString, setCoaString] = useState('Category Name');
  const [averageCalc, setAverageCalc] = useState(0);
  const [tabsToUse, setTabsToUse] = useState(['History', 'Transactions', 'Recurring']);

  // SELECTORS ==========================================================================
  const categoriesById = useSelector((state) => categoriesSelectors.getCategoriesById(state), shallowEqual);
  const showScheduled = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('scheduledTransactions'));

  // EFFECTS ============================================================================

  useEffect(() => {
    setTabsToUse(showScheduled ? ['History', 'Transactions', 'Recurring'] : ['History', 'Transactions']);
  }, [showScheduled]);

  useEffect(() => {
    if (coaIds) {
      if (coaIds.length > 1) {
        setCoaString('Multiple Categories');
        // get cat records for given coaIds
        const cats = categoriesById.filter((x) => coaIds.includes(x.id));
        // get all parents in the list
        const parents = cats.filter((x) => !x.parentId || x.parentId === '0');
        // if only one parent, see if all non-parents are all of it's children
        if (parents.size === 1) {
          const notKids = cats.filter((x) => x.parentId !== parents.first().id);
          // Note, notKids will also include the single parent category
          // if there are no children of other parents, check if ALL this parents kids are included
          if (notKids.size === 1) {  // only has the parent
            const targetKids = cats.filter((x) => x.parentId === parents.first().id);
            if (targetKids.size === (cats.size - 1)) {
              setCoaString(chartOfAccountsSelectors.getCoaStringSelector(undefined, { type: 'CATEGORY', id: parents.first().id }));
            }
          }
        }
      } else {
        setCoaString(chartOfAccountsSelectors.getCoaStringSelector(undefined, { type: 'CATEGORY', id: coaIds[0] }));
      }
    } else {
      setCoaString(coa ? chartOfAccountsSelectors.getCoaStringSelector(undefined, coa) : 'Multiple Categories');
    }
  }, [coa, categoriesById, coaIds]);
  // STYLES ============================================================================
  const { classes } = useStyles(props);

  // INTERNAL FUNCTIONS

  const tabClick = (item) => {
    setFormat([item?.toLowerCase()]);
  };
  const onAverageCalc = (avg) => {
    setAverageCalc(avg);
  };

  // RENDER ============================================================================
  return (
    <>
      {anchorEl &&
        <Popover
          anchorEl={anchorEl}
          open={open}
          id={`catReviewPopup:${coa ? coa.id : 'multiple'}`}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <div
            className={classes.root}
            style={other.rootStyle}
          >
            <QCloseBox
              onClose={onClose}
              classes={{ closeIcon: classes.closeIcon }}
            />

            <div className={classes.header}>

              <CategoryReviewPopupHeader
                classes={classes}
                coaString={coaString}
                valueString={`Monthly Average: ${formatNumber(Math.abs(averageCalc), currency, '0,0')}`}
              />

              <QTabsHorizontal
                tabs={tabsToUse}
                selected={format[0]}
                onClick={tabClick}
              />
            </div>

            <CategoryReviewCard
              {...other}
              coa={coa}
              coaIds={coaIds}
              coaTitle={coaString}
              numMonths={numMonthsDefault}
              classes={{ transactionList: classes.transactionList, root: classes.reviewCardRoot }}
              currency={currency}
              accountIds={accountIds}
              format={format}
              onSetValueFromGraph={onSetValueFromGraph}
              onAverageCalc={onAverageCalc}
              excludeCategoryInTooltip={excludeCategoryInTooltip}
            />
          </div>
        </Popover>}
    </>
  );
};

CategoryReviewPopupCard.propTypes = propTypes;
CategoryReviewPopupCard.defaultProps = propDefaults;
export default (CategoryReviewPopupCard);

/*
 * SUBCOMPONENTS **************************************
 */

/*
 * CategoryReviewPopupHeader
 */
const headerPropTypes = {
  classes: PropTypes.object,
  coaString: PropTypes.string,
  valueString: PropTypes.string,
};
const CategoryReviewPopupHeader = (props) => {

  const { classes, coaString, valueString } = props;

  const lastColon = coaString.lastIndexOf(':');
  let catString1 = '';
  let catString2 = coaString;
  if (lastColon > 0) {
    catString1 = coaString.substr(0, lastColon);
    catString2 = `: ${coaString.slice(lastColon + 1)}`;
  }
  return (
    <div className={classes.headerContent} onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} role="presentation">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="body1">
          {catString1}
        </Typography>
        <Typography style={{ fontWeight: 500, marginLeft: 3 }} variant="body1">
          {catString2}
        </Typography>
      </div>
      <Typography variant="body2" className={classes.avgCalc}>
        {valueString}
      </Typography>
    </div>
  );
};

CategoryReviewPopupHeader.propTypes = headerPropTypes;
