
import React, { FC } from 'react';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

import TaskWidget from './taskWidget';
import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

type TaskItem = {
  name: string;
  title: string;
  completed: boolean;
};

interface TaskListProgressProps {
  setupItems: TaskItem[]; 
  open: boolean;
  onOpenToggle?: (event: React.MouseEvent<HTMLButtonElement>, open: boolean) => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement>, name: string) => void;
  titleOnTop: boolean;
  name?: string;
  selected: string;
}

const TaskListProgress: FC<TaskListProgressProps> = ({ setupItems, open, onOpenToggle, onClick, titleOnTop, name, selected }) => {
  
  const { classes } = useStyles();
  const rootClassname = classNames(classes.root, { closed: !open }, { [classes.rootDashboardHeight]: !titleOnTop }, { [classes.modalRootOverwrite]: titleOnTop });

  return (
    <div style={{ textAlign: 'center', marginLeft: 16, marginRight: 16 }}>
      {onOpenToggle &&
        <ButtonBase
          onClick={(e) => onOpenToggle(e, !open)}
          className={classes.toggleButton}
        >
          <div className={classes.toggleSwitch} />
        </ButtonBase>}
      <div
        id={`${name || 'default'}-setup-task-bar`}
        className={rootClassname}
      >
        {titleOnTop &&
        <div className={classNames(classes.title, 'ontop')}>
          <Typography className={classes.titleText}>
            SET UP YOUR QUICKEN CLASSIC
          </Typography>

        </div>}
        <div
          className={classNames(classes.container)}
        >
          {!titleOnTop &&
            <div className={classes.title}>
              <Typography className={classes.titleText}>
                SET UP YOUR QUICKEN CLASSIC
              </Typography>
            </div>}

          <div className={classes.widgetHolder}>
            {setupItems.map((x) =>
              <TaskWidget
                key={`task-widget-${x.name}`}
                title={x.title}
                completed={Boolean(x.completed)}
                selected={selected === x.name}
                onClick={onClick}
                name={x.name}
              />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskListProgress;
