export const overviewLayoutVersion = '1.0';

export const overviewCards = {
  // QUICKEN CARDS
  transactions: {
    label: 'Recent Transactions',
    name: 'transactions',
    xs: 12,
    lg: 6,
    titleNav: '/transactions?displayNode=all',
    defaultOrder: 1,
  },
  upcoming: {
    label: 'Upcoming',
    name: 'upcoming',
    xs: 12,
    lg: 6,
    titleNav: '/bills-and-income',
    defaultOrder: 2,
  },
  spendingCats: {
    label: 'Spending by Category',
    name: 'spendingCats',
    xs: 12,
    lg: 6,
    titleNav: '/reports/spending/by-category/total',
    defaultOrder: 3,
  },
  budgets: {
    label: 'Budgets',
    name: 'budgets',
    xs: 12,
    lg: 6,
    titleNav: '/budgets',
    defaultOrder: 4,
  },
  spendingTime: {
    label: 'Spending Over Time',
    name: 'spendingTime',
    xs: 12,
    lg: 6,
    titleNav: '/reports/spending/by-none/over-time',
    defaultOrder: 6,
  },
  investments: {
    label: 'Investment Summary',
    name: 'investments',
    xs: 12,
    md: 12,
    lg: 6,
    xl: 3,
    titleNav: '/investments?displayNode=INVESTMENT',
    defaultOrder: 7,
  },
  netIncome: {
    label: 'Net Income Over Time',
    name: 'netIncome',
    xs: 12,
    lg: 6,
    titleNav: '/reports/net-income/over-time',
    defaultOrder: 8,
  },
  investmentsMovers: {
    label: 'Investment Top Movers',
    name: 'investmentsMovers',
    xs: 12,
    lg: 6,
    titleNav: '/investments?displayNode=INVESTMENT',
    defaultOrder: 9,
  },
  topPayees: {
    label: 'Top Payees this Month',
    name: 'topPayees',
    xs: 12,
    lg: 6,
    xl: 3,
    titleNav: '/reports/spending/by-payee/total',
    defaultOrder: 10,
  },
  incomeTime: {
    label: 'Income By Month',
    name: 'incomeTime',
    xs: 12,
    lg: 6,
    titleNav: '/reports/income/by-none/over-time',
    defaultOrder: 12,
  },
  calendar: {
    label: 'Calendar',
    name: 'calendar',
    xs: 12,
    lg: 6,
    titleNav: '/transactions?displayNode=all&displayMode=calendar',
    defaultOrder: 13,
  },
};

export const overviewLayout = [
  {
    key: 'transactions',
    value: true,
  },
  {
    key: 'spendingCats',
    value: true,
  },
  {
    key: 'budgets',
    value: true,
  },
  {
    key: 'spendingTime',
    value: true,
  },
  {
    key: 'investments',
    value: true,
  },
  {
    key: 'netIncome',
    value: true,
  },
  {
    key: 'investmentsMovers',
    value: true,
  },
  {
    key: 'topPayees',
    value: true,
  },
  {
    key: 'calendar',
    value: true,
  },
];
