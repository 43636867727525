import React from 'react';
import { makeStyles } from 'tss-react/mui';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { isOldEdge } from 'utils/utils';
import StdDialog from 'components/Dialogs/StdDialog';
import useQPreferences from 'components/QPreferences/useQPreferences';
import QAmountField from 'components/QAmountField';
import { defaultDatasetPreferences } from 'data/preferences/defaults';
import { tracker } from 'companion-app-components/utils/core';
import { calendarPrefsStyles } from '../../styles';

const useStyles = makeStyles()(calendarPrefsStyles as Record<string, any>);

const isIe = require('is-iexplorer') || isOldEdge();  // eslint-disable-line

interface CalPrefsProps {
  onClose: () => void;
}

interface CalPrefFields {
  label: any;
  field: string;
  id: string;
}

type CalPrefData = CalPrefFields[];


export const CAL_PREFS_DATA: CalPrefData = [
  {
    label: 'Show transactions',
    field: 'showTransactions',
    id: 'cal-pref-show-txns',
  },
  {
    label: 'Show bill and income reminders',
    field: 'showScheduleTransactions',
    id: 'cal-pref-show-schedule-txns',
  },
  {
    label: (labelProps) => {
      const { showAmountField, amount, onChange, classes } = labelProps;
      return (
        <div className={classes.exceedAmountRoot}>
          <span>Show only transactions greater than{showAmountField ? ':' : ''}</span>
          {showAmountField && (
            <div className={classes.exceedAmountField}>
              <QAmountField
                name="exceedingAmountField"
                id="cal-pref-exeeding-amount-field"
                editable
                value={amount}
                onSubmit={onChange}
                onBlur={onChange}
                showSign
              />
            </div>
          )}
        </div>
      );
    },
    field: 'exceedingAmount',
    id: 'cal-pref-show-exceeding-amount',
  },
  {
    label: 'Show daily balances',
    field: 'showDailyBalances',
    id: 'cal-pref-show--daily-balances',
  },
];

const initialExceedingAmount = 100;

const CalPrefsModal: React.FC<CalPrefsProps> = ({ onClose }) => {
  const { classes } = useStyles();
  const { datasetPreferences, setDatasetPreference } = useQPreferences();
  const onCLose = () => {
    if (onClose) onClose();
  };

  function onExceedingAmountSave(event: Record<string, any>) {
    if (event && event.target) {
      const exceedingAmount = parseInt(event.target.value, 10);
      setDatasetPreference({ calendarTransaction: { exceedingAmount } });
      tracker.track(tracker.events.calendarPrefSave, {
        type: 'exceedingAmountValue',
        value: exceedingAmount,
      });
    }
  }

  function onHandleCheck(field: string) {
    return (event: Record<string, any>, checked: boolean) => {
      if (event && event.target) {
        let newValue : number | null | boolean;
        if (field !== 'exceedingAmount') {
          newValue = checked;
        } else {
          newValue = checked ? initialExceedingAmount : null;
        }
        setDatasetPreference({ calendarTransaction: { [field]: newValue } });
        tracker.track(tracker.events.calendarPrefSave, {
          type: field,
          value: newValue,
        });
      }
    };
  }

  const onResetDefaults = () => {
    setDatasetPreference({
      calendarTransaction: {
        ...defaultDatasetPreferences.calendarTransaction,
        accountsToShow: datasetPreferences.calendarTransaction.accountsToShow,
      },
    });
    tracker.track(tracker.events.calendarPrefResetDefault);
  };

  function renderFields() {
    const prefs = datasetPreferences.calendarTransaction;

    if (prefs) {
      const elements = CAL_PREFS_DATA.map((pref) => {
        const fieldValue = pref.field !== 'exceedingAmount' ? prefs[pref.field] : prefs[pref.field] !== null;
        return (
          <div className={classes.fieldRoot} key={`CalPrefsItem-${pref.field}`}>
            <div className={classes.fieldLabel}>
              {
                pref.field !== 'exceedingAmount' ?
                  pref.label : (
                    <pref.label
                      showAmountField={fieldValue}
                      amount={prefs[pref.field]}
                      onChange={onExceedingAmountSave}
                      classes={classes}
                    />
                  )
              }
            </div>
            <Switch
              color="primary"
              checked={fieldValue}
              value={fieldValue ? 'on' : 'off'}
              onChange={onHandleCheck(pref.field)}
              id={pref.id}
            />
          </div>
        );
      });
      return elements;
    }


    return null;
  }

  return (
    <StdDialog
      open
      classes={isIe ? { paper: classes.dialogPaper } : {}}
      onClose={onCLose}
      title="Calendar Preferences"
    >
      <DialogContent>
        <FormControl component="fieldset">
          <FormGroup>
            {renderFields()}
          </FormGroup>
        </FormControl>
        <Divider />
      </DialogContent>
      <div className={classes.buttonArea}>
        <Button
          color="primary"
          onClick={onResetDefaults}
          id="txn-pref-reset-to-defaults"
        >
          Reset To Defaults
        </Button>
        <Button
          color="primary"
          onClick={onCLose}
          id="txn-pref-done"
        >
          Done
        </Button>
      </div>

    </StdDialog>
  );
};


export default React.memo(CalPrefsModal);
