
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

const useStyles = makeStyles()((theme) => ({
  denseMargin: {
    margin: '8px 0',
  },
  labelRoot: {
    color: theme.palette.text.primary,
  },
  fieldRoot: {
    padding: '24px 8px 8px',
    paddingTop: '24px !important',
    paddingBottom: '8px !important',
    borderRadius: 4,
  },
  input: {
    padding: '8px 6px',
  },
}));


function BoxField(props) {
  const { classes, cx } = useStyles();
  const { label, value, className, placeholder, onChange, fullWidth, InputProps, InputLabelProps, margin, ...otherProps } = props;

  const handleChange = (e) => onChange?.(e.target.value);

  const dense = margin === 'dense';

  return (
    <FormControl
      variant="filled"
      margin={margin}
      classes={{ root: cx(dense && classes.denseMargin, className) }}
      fullWidth={fullWidth}
    >
      <InputLabel
        {...InputLabelProps}
        shrink
        classes={{ ...InputLabelProps?.classes, root: classes.labelRoot }}
      >
        {label}
      </InputLabel>

      <OutlinedInput
        {...otherProps}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        classes={{
          ...otherProps?.classes,
          input: cx(classes.input, otherProps?.classes?.input),
          root: cx(classes.fieldRoot, otherProps?.classes?.root),
        }}
      />
    </FormControl>
  );
}

BoxField.defaultProps = {
  margin: 'normal',
};


BoxField.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  // textField props
  fullWidth: PropTypes.bool,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  margin: PropTypes.string,
};

export default BoxField;
