import styled from 'styled-components';

export const DialogTimeout = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;

export const styles = (theme) => ({

  textContent: {
    color: theme.palette.greyScaleDeprecated[0],
    margin: 'auto',
    padding: 0,
  },
  subtitle: {
    width: '50%',
    textAlign: 'center',
    margin: 'auto',
  },
  centerContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '75%',
  },
  textArea: {
    color: theme.palette.greyScaleDeprecated[0],
  },
  titleArea: {
    background: theme.palette.greyScaleDeprecated[6],
  },
  root: {
    padding: 24,
  },
});

