// @flow
import { Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

export const INVESTMENT_SYMBOLS_KEY = 'investmentSymbolsStore';

type InvestmentSymbolProps = {
  id: ?string,
  symbol: ?string,
  name: ?string,
  exchange: ?string,
};

export type InvestmentSymbol = RecordOf<InvestmentSymbolProps>;
export const mkInvestmentSymbol: RecordFactory<InvestmentSymbolProps> =
  Record({
    id: undefined,
    symbol: undefined,
    name: undefined,
    exchange: undefined,
  });
