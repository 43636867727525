/** 
 * Balance mismatch information is stored in preference under  balanceMismatchPrefs
 * below is an example of the object stored
 * "balanceMismatchPrefs": {
    "byId": {
        "401438755799342336": {
            "mismatchAmount_user_modified_at": "2023-10-25T05:38:48.698804Z[UTC]",
            "mismatchAmount": "-20.00",
        },
         "455438755799345696": {
            "mismatchAmount_user_modified_at": "2023-10-29T05:38:48.698804Z[UTC]",
            "mismatchAmount": "-50.00",
        }
    }
} 
*/

export function initBalMismatchPrefs(accountId, deltaAmount, balanceMismatchPrefs) {
  try {
    let bmPrefs = balanceMismatchPrefs;

    // clear the preference if amount is zero
    if (deltaAmount === Number(0).toFixed(2)) {
      const keysList = Object.keys(bmPrefs.byId).filter((key) => !key.includes('user_modified_at'));
      if (keysList.includes(accountId)) {
        if (keysList.length > 1) {
          bmPrefs.byId[accountId] = null;
        } else {
          bmPrefs = null;
        }
      }
    } else {
      const prefsById = bmPrefs ? bmPrefs.byId : {};
      bmPrefs = bmPrefs || {};

      if (!prefsById[accountId]) {
        prefsById[accountId] = {};
      }

      prefsById[accountId].mismatchAmount = deltaAmount;
      bmPrefs.byId = prefsById;
    }
    return bmPrefs;
  } catch (e) {
    return null;
  }
}
