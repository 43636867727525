// @flow
import { REDUCER_KEY } from './reducer';
import type { StdFormsStore } from './types';

export const getStdFormsStore = (state: any): StdFormsStore => state[REDUCER_KEY];
export const getStdFormById = (state: any) => getStdFormsStore(state).stdFormsById;

export const getStdForm = (state: any, id: string) => getStdFormById(state).get(id);

export const hasBeenCancelled = (state: any, id: string) => {
  const stdForm = getStdForm(state, id);
  return stdForm != null ? stdForm.cancelled : false;
};

export const hasSubmitSucceeded = (state: any, id: string) => {
  const stdForm = getStdForm(state, id);
  return stdForm != null ? stdForm.submitSucceeded : false;
};

export const hasSubmitFailed = (state: any, id: string) => {
  const stdForm = getStdForm(state, id);
  return stdForm != null ? stdForm.submitFailed : false;
};

export const getSubmitResponseData = (state: any, id: string) => {
  const stdForm = getStdForm(state, id);
  return stdForm != null ? stdForm.submitResponseData : false;
};

