import React from 'react';
import Typography from '@mui/material/Typography';


import reviewedHelpLeft from 'assets/help/isReviewedExplanationC2R.png';
import reviewedHelpRight from 'assets/help/isReviewedExplanationNONC2R.png';

export const HelpReviewed = () => {

  const scale = '50%';
  const imgScale = '90%';
  const minWidth = 300;

  return (
    <div key="review-help-content">
      <div>
        Now you can review your newly downloaded transactions from any web browser.  Make edits and mark
        items as reviewed and your work is saved back to Quicken desktop the next time you sync.
      </div>
      <div style={{ marginTop: 25, display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
        <div
          style={{ textAlign: 'center', flex: 1, width: scale, height: scale, minWidth }}
        >
          <img
            alt="reviewed flag help"
            src={reviewedHelpRight}
            style={{ flex: 1, width: imgScale, height: imgScale, minWidth }}
          />
          <Typography
            style={{ padding: 10, fontSize: '14px', textAlign: 'left' }}
            variant="body1"
          >
            Checking a transaction on web will &quot;Mark as Reviewed&quot; on desktop (and remove the download icon)
          </Typography>
        </div>
        <div
          style={{ textAlign: 'center', flex: 1, width: scale, height: scale, minWidth }}
        >
          <img
            alt="reviewed flag help"
            src={reviewedHelpLeft}
            style={{ flex: 1, marginLeft: 10, width: imgScale, height: imgScale, minWidth }}
          />
          <Typography
            style={{ padding: 10, fontSize: '14px', textAlign: 'left' }}
            variant="body1"
          >
            Checking a transaction on web will &quot;Accept&quot; on desktop (and move it into the register)
          </Typography>
        </div>
      </div>
    </div>
  );
};
