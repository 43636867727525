import type { Profile } from 'companion-app-components/flux/profile/profileTypes';

import { applyOpacityToHex } from 'themes/themeUtils';

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

export const stringAvatar = (name: string) => {
  const nameParts = name.split(' ');
  return {
    sx: {
      bgcolor: applyOpacityToHex(stringToColor(name), 0.6),
      width: 28,
      height: 28,
      fontSize: 12,
      marginLeft: '16px',
      color: 'shadowGrey',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    children: nameParts.length > 1 
      ? `${nameParts[0][0]}${nameParts[1][0]}` 
      : `${nameParts[0][0]}`,
  };
};

export const getFullName = (userProfile?: Profile) => {
  if (!userProfile) {
    return '';
  }
  let fullName = '';
  if (userProfile.firstName && userProfile.lastName) {
    fullName = `${userProfile.firstName} ${userProfile.lastName}`;
  } else if (userProfile.primaryAddress && userProfile.primaryAddress.fullName) {
    fullName = userProfile.primaryAddress.fullName;
  }
  return fullName;
};

export const isEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
