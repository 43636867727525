import React, { useEffect, useState, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { OrderedMap } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import SimplifiIcon from 'assets/simplifi-logo.svg';
import QButton from 'components/QButton';

import { enablePushNotifications } from 'swPushNotifications';
import { addNotifications, removeNotifications } from 'data/notifications/notificationsActions';
import { mkNotification } from 'data/notifications/notificationsTypes';

import {
  allowPushNotificationsPromptToBeDisable,
  getActivePwaNotification,
  setActivePwaNotification,
  trackBrowserPushNotifications,
  trackPushNotifications,
  trackPushNotificationsDismissed,
  trackPushNotificationsPrompted,
} from './PwaHelpers';
import { useStyles } from './styles';

type Props = {
  onDismiss: (boolean) => void,
  onEnable: () => void,
}

const PushNotificationContent = forwardRef((props: Props, ref) => {
  const { onDismiss, onEnable } = props;
  const classes = useStyles();

  useEffect(() => {
    trackPushNotificationsPrompted();
  }, []);

  const allowDisable = allowPushNotificationsPromptToBeDisable();

  return (
    <SnackbarContent
      className={classes.root}
      classes={{
        message: classes.muiMessage,
      }}
      ref={ref}
      message={
        <Paper className={classes.toast} elevation={3} square={false}>
          <IconButton
            className={classes.closeButton}
            aria-label="Dismiss"
            id="snackbar-dismiss"
            type="button"
            onClick={() => onDismiss(false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <img className={classes.icon} src={SimplifiIcon} alt="" />
          <Typography className={classes.title} variant="h6">Enable Push Notifications</Typography>
          <Typography className={classes.message} variant="body1">
            Receive notifications from Simplifi in your notification center
            even when Simplifi is not open!
          </Typography>
          <div className={classes.actions}>
            <QButton
              className={classes.actionSecondary}
              id="buttton-dismiss"
              onClick={() => onDismiss(allowDisable)}
              variant="outlined"
            >
              Not Now
            </QButton>
            <QButton
              className={classes.action}
              id="button-enable"
              onClick={() => onEnable()}
              variant="contained"
            >
              Enable
            </QButton>
          </div>
        </Paper>
      }
    />
  );
});

const PushNotificationPrompt = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);

  useEffect(() => {

    const handleDismiss = (id: string, disable: boolean) => {
      trackPushNotificationsDismissed(disable);
      dispatch(removeNotifications([id]));
      setActivePwaNotification(null);
    };

    const handleEnable = async (id: string) => {
      trackPushNotifications();
      dispatch(removeNotifications([id]));

      const enabled = await enablePushNotifications();
      trackBrowserPushNotifications(enabled);

      setActivePwaNotification(null);
    };

    // Do not show a PWA notification if one is already being shown. But, try again in a minute for up to 5 minutes.
    // Otherwise; won't show till at least next hard refresh.
    //
    if (getActivePwaNotification()) {
      if (count < 5) {
        setInterval(() => {
          setCount(count + 1);
        }, 60000);
      }
      return;
    }
    setActivePwaNotification('PWA_PUSH_NOTIFICATIONS_PROMPT');

    const id = uuidv4();
    dispatch(addNotifications(OrderedMap([
      [
        id,
        mkNotification({
          id,
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
          snackbarContent: PushNotificationContent,
          snackbarContentProps: {
            id,
            onClose: () => handleDismiss(id, false),
            onDismiss: (disable) => handleDismiss(id, disable),
            onEnable: () => handleEnable(id),
          },
          autoHideDuration: 30000,
        }),
      ],
    ])));
  }, [count, dispatch]);

  return null;
};

export default PushNotificationPrompt;
