import React, { FC, useState, useEffect, useCallback, ReactNode } from 'react';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { getLogger } from 'companion-app-components/utils/core';

import QTip from 'components/QuickenControls/QTip';
import cannotDisplayImg from 'assets/checknum_details.svg';

import { styles } from './styles';
import { SubTitle, HiddenInput, ImageDiv, ThumbDiv, ViewPort, Thumbs } from './styledComponents';

const log = getLogger('components/QuickenControls/QImageGallery/index.tsx');

const useStyle = makeStyles()(styles as Record<string, any>);

type ImagesState = {
  id: string;
  url: string;
  name: string;
}[];

interface QImageGalleryProps {
  images: ImagesState;
  action: string;
  closeFn: () => void;
  selectedImages: (images: string[]) => void;
}

const QImageGallery: FC<QImageGalleryProps> = ({ images: propImages, action, closeFn, selectedImages }) => {
  const [currIndex, setCurrIndex] = useState(0);
  const [images, setImages] = useState(propImages.map((obj) => ({ ...obj, selected: 'off' })));
  const [validImages, setValidImages] = useState<string[]>([]);
  const [errorImages, setErrorImages] = useState<string[]>([]);

  const { classes } = useStyle();

  useEffect(() => {
    if (propImages) {
      const newImages = propImages.map((obj) => ({ ...obj, selected: 'off' }));
      setImages(newImages);
      setCurrIndex(Math.min(currIndex, newImages.length));
    }
  }, [propImages, currIndex]);

  const markValid = useCallback((url) => {
    setValidImages((prev) => [...prev, url]);
  }, []);

  const markError = useCallback((url) => {
    setErrorImages((prev) => [...prev, url]);
  }, []);

  const numSelected = useCallback(() => images.filter((i) => i.selected === 'on').length, [images]);

  const processSelected = useCallback(() => {
    if (selectedImages) {
      const ids = images.filter((i) => i.selected === 'on').map((i) => i.id);
      selectedImages(ids);
    }
  }, [images, selectedImages]);

  const clickThumb = (index) => setCurrIndex(index);

  const clickPic = (url) => window.open(url, '_blank');

  const processKey = (e) => {
    log.log('KEY');
    if (e.key === 'Escape' && closeFn) {
      e.preventDefault();
      closeFn();
    }
  };

  const getField = () => {
    const imageDivs: ReactNode[] = [];
    const thumbs: ReactNode[] = [];

    if (images.length > 0) {
      images.forEach((i, index) => {
        const isValidImage = validImages.includes(i.url);
        const isErrorImage = errorImages.includes(i.url);
        const extension = i.name.slice(i.name.lastIndexOf('.'));
        const isUnavailable = i.url === 'unavailable';
        const errText = isUnavailable
          ? 'This document is no longer available'
          : `This document type (${extension}) cannot be displayed, click to download`;

        if (currIndex === index) {
          imageDivs.push(
            <div key={`img-${i.url}${i.id}`}>
              <ButtonBase
                onClick={() => clickPic(i.url)}
                style={{ display: 'block', margin: 'auto' }}
                disabled={isUnavailable}
              >
                <div>
                  {isValidImage && (
                    <ImageDiv src={isValidImage ? i.url : cannotDisplayImg} className="show" />
                  )}
                  {isErrorImage && !isUnavailable && <CloudDownloadIcon className={classes.cloudIcon} />}
                  {!isValidImage && !isErrorImage && (
                    <CircularProgress size={25} className={classes.loadingCircle2} />
                  )}
                  <Typography
                    className={classNames(
                      isValidImage ? classes.hide : classes.show,
                      classes.mainImageText,
                    )}
                    variant="h5"
                  >
                    {isErrorImage || isUnavailable ? errText : 'Loading...'}
                  </Typography>
                </div>
              </ButtonBase>
            </div>,
          );
        }
        thumbs.push(
          <ThumbDiv
            key={`img-thumb${i.url}`}
            className={classNames(currIndex === index ? 'selected' : '')}
          >
            <QTip title={`${i.name}`}>
              <ButtonBase onClick={() => clickThumb(index)} className={classes.button}>
                <img
                  alt="select item"
                  onLoad={() => markValid(i.url)}
                  onError={() => markError(i.url)}
                  src={i.url}
                  className={isValidImage ? classes.show : classes.hide}
                />
                <Typography
                  className={classNames(isValidImage ? classes.hide : classes.show)}
                >
                  {extension}
                </Typography>
              </ButtonBase>
            </QTip>
          </ThumbDiv>,
        );
      });
    } else {
      imageDivs.push(
        <div key="thumbkey" className={classes.loadingBlock}>
          <CircularProgress size={52} className={classes.loadingCircle} />
          <Typography>Retrieving attachments...</Typography>
        </div>,
      );
    }

    return (
      <div>
        {images.length > 0 && <SubTitle>Click image to open full screen in a separate tab</SubTitle>}
        <ViewPort>{imageDivs}</ViewPort>
        <Divider />
        <div style={{ display: 'flex' }}>
          <Thumbs>{thumbs}</Thumbs>
          {numSelected() > 0 && (
            <Button color="primary" onClick={processSelected}>
              {action || 'Action'}
            </Button>
          )}
        </div>
        <HiddenInput autoFocus onKeyDown={processKey}></HiddenInput>
      </div>
    );
  };

  return getField();
};

export default QImageGallery;
