import { put, select, takeLatest, delay } from 'redux-saga/effects';
import { DateTime } from 'luxon';

import { getEnvironmentConfig, crashReporterInterface } from 'companion-app-components/utils/core';
import { authActions, authSelectors } from 'companion-app-components/flux/auth';
import { entitlementsActions, entitlementsSelectors } from 'companion-app-components/flux/entitlements';
import { datasetsSelectors, datasetsActions } from 'companion-app-components/flux/datasets';

export function* getEntitlementsSuccessActionWatcher() {
  yield takeLatest([datasetsActions.getDatasetEntitlementsSuccess, 
    entitlementsActions.getEntitlementsSuccess], function* getEntitlementsSuccess(_action) {
    yield delay(3000);
    const currentDatasetId = yield select(authSelectors.getDatasetId);
    const datasets = yield select(datasetsSelectors.getEntitledDatasets);
    const selectedDataset = currentDatasetId ? datasets.find(({ id }) => id === currentDatasetId) : undefined;
    const entitlement = selectedDataset?.entitlements;
    const entitledDatasetsLoadPending = yield select(datasetsSelectors.getEntitledDatasetsLoadPending);
    if (!entitlement && !entitledDatasetsLoadPending) {
      const entitlements = yield select(entitlementsSelectors.getEntitlements);
      const activeEntitlements = yield select(entitlementsSelectors.getActiveEntitlements);
      const localTime = DateTime.local();
      crashReporterInterface.reportError(Error('no active entitlement found'), (event) => event.addMetadata('custom', {
        entitlement,
        activeEntitlements,
        entitlements,
        localTime,
      }));
      yield put(authActions.authLogout({ reason: 'AUTH_LOGOUT_UNAUTHORIZED' }, { context: 'no entitlement quicken' }));
      window.location.replace(getEnvironmentConfig()?.pick_your_plan_url ?? '');
    }
  });
}

export default [
  getEntitlementsSuccessActionWatcher,
];
