/* eslint-disable react/forbid-foreign-prop-types */

import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import * as Docs from 'containers/SharedComponentsPage/DocsUtils';
import { getParameterNames } from '../utils';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    textAlign: 'center',
  },
  playgroundInput: {
    margin: theme.spacing(1),
  },
}));

export const FUNCTION_WRAPPER_KEY = 'FUNCTION';

const FunctionWrapper = (props) => {
  const { item, showDetails } = props;
  const { classes } = useStyles();
  const params = useMemo(() => item.parameters || getParameterNames(item.func), [item]);

  const [itemId, setItemId] = useState(item.id);

  useEffect(() => {
    setItemId(item.id);
  }, [item]);

  if (item.id !== itemId) {
    return null;
  }

  return (
    <Box className={classes.root}>
      {showDetails ?
        <Docs.Header>{item.name}</Docs.Header>
        :
        <Typography className={classes.header} variant={'h3'}>{item.name}</Typography>}
      {item.description &&
        <Docs.Section>
          <Docs.SectionHeader>Description</Docs.SectionHeader>
          {typeof item.description === 'string' ?
            <Typography variant="body1">
              {item.description}
            </Typography>
            :
            item.description}
        </Docs.Section>}
      {showDetails &&
        <Docs.Section>
          <Docs.SectionHeader>Parameters</Docs.SectionHeader>
          <Docs.Section>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {params.map((param) => (<TableCell key={`header-cell-${param}`}>{param}</TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {params.map((param, index) => <TableCell key={`param-cell-${param}`}>{index}</TableCell>)}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Docs.Section>
        </Docs.Section>}
      {item.examples &&
      <Docs.Section>
        <Docs.SectionHeader>Examples</Docs.SectionHeader>
        {item.examples.map((example) => <div>{example}</div>)}
      </Docs.Section>}
    </Box>
  );

};

FunctionWrapper.propTypes = {
  item: PropTypes.object,
  showDetails: PropTypes.bool,
};

export default FunctionWrapper;
