import styled from 'styled-components';
import { isAcme } from 'isAcme';

import { isMobile, isTablet } from 'react-device-detect';

import { sharedStyles } from '../sharedStyles';

import { MIN_TX_HEIGHT } from '../TransactionPage/styles';

export { MIN_TX_HEIGHT };

export const RegTxListRow = styled.div`
  display: flex;
  align-items: stretch;
  padding-left: ${({ theme }) => theme.components.register.rowInnerPadding}px;
  padding-right: ${({ theme }) => theme.components.register.rowInnerPadding}px;
  border-bottom: solid 1px ${({ theme }) => isAcme ? theme.palette.divider : theme.palette.greyScaleDeprecated[6]};

  &.flashTx {
    animation: pulseTx 0.3s ease-in;
  }
  @keyframes pulseTx {
    0%, 100% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(10px);
    }
    70% {
      transform: translateY(20px);
    }
  }
  &.comfortable {
    min-height: ${MIN_TX_HEIGHT.comfortable}px;
  }
  &.normal {
    min-height: ${MIN_TX_HEIGHT.normal}px;
  }
  &.compact {
    min-height: ${MIN_TX_HEIGHT.compact}px;
  }
  &.comfortable-ie {
    height: ${MIN_TX_HEIGHT.comfortable}px;
    overflow: 'hidden';
  }
  &.normal-ie {
    height: ${MIN_TX_HEIGHT.normal}px;
    overflow: 'hidden';
  }
  &.compact-ie {
    height: ${MIN_TX_HEIGHT.compact}px;
    overflow: 'hidden';
  }
`;

export const RegListItem = styled.div`
  &.popAmount {
    animation: pulse 0.5s ease-in;
  }
  @keyframes pulse {
    0%, 100% {
      transform: scale(1) translateX(0px);
    }
    50% {
      transform: scale(1.5) translateX(10px);
    }
  }
`;

export const RegTxList = styled.div`
  margin-top: 0;
  opacity: 1;
  font-size: 12px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  background: ${({ theme }) => isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7]};

  border-bottom: none;
  border-width: 1px;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.palette.greyScaleDeprecated[5]};
  border-bottom-color: ${({ theme }) => theme.palette.greyScaleDeprecated[5]};

  &.appear {
    opacity: 1;
    transition: all 0.5s ease;
  }
  &:hover {
    background: ${isMobile || isTablet ? '' : ({ theme }) => theme.components.register.rowHoverColor};
    transition: .5s ease;
    borderRadius: 3px;
    cursor: ${isAcme ? 'pointer' : 'default'};
  }
  &.editable {
    background: ${({ theme }) => isAcme ? theme.palette.background.default : theme.components.register.editRowColor};
    &:hover: {
      background: ${({ theme }) => theme.components.register.editRowColor};
    }
  }
  &.pending {
  }
  &.expense {
    border-left: 2px solid ${({ theme }) => theme.components.amountField.negativeColor2};
  }
  &.income {
    border-left: 2px solid ${({ theme }) => theme.components.amountField.positiveColor2};
  }
`;

export const styles = (theme) => ({
  ...sharedStyles(theme),

  matchStateIcon: {
    display: 'flex',
    fontSize: '20px',
    '&.bank': {
      cursor: 'default',
      color: theme.palette.blue3,
    },
    '&.manual': {
      cursor: 'default',
      color: theme.palette.blue3,
    },
    '&.matched': {
      cursor: 'default',
      color: theme.palette.blue1,
    },
    '&.scheduled': {
      cursor: 'default',
      color: theme.palette.teal2,
    },
  },

  regPendingText: {
    color: theme.components.register.pendingTextColor,
  },
  reviewed: {
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.greyScaleDeprecated[3],
    '&.not-reviewed': {
      color: theme.palette.blue6,
      fontSize: 'small',
    },
    '&.reviewed': {
      opacity: 1,
      color: theme.palette.gray5,
      fontSize: 'small',
    },
    '&.cancel': {
      opacity: 1,
      color: theme.palette.greyScaleDeprecated[3],
    },
    '&.save': {
      opacity: 0.8,
      color: theme.palette.green1,
    },
  },
  notReviewed: {
    cursor: 'pointer',
  },
  regInput: {
    color: theme.palette.greyScaleDeprecated[0],
    paddingBottom: '0px',
    paddingTop: 0,
    marginTop: '0px',
    fontSize: theme.components.register.fontSize.default,
    width: '100%',
    '& input': {
      padding: '0px',
      paddingBottom: '0px',
      fontSize: theme.components.register.fontSize.default,
      height: 24, // IE-11 needs this
      '&::-ms-clear ': {
        display: 'none',
      },
    },
  },
  buttonRoot: {
    width: 'inherit',
    height: '100%',
    display: 'block',
  },
  iconButton: {
    fontSize: '20px',
    padding: '6px',
  },
  due: {
    color: theme.palette.other.dueTxn,
  },
  overdue: {
    color: theme.palette.other.overdueTxn,
  },
  notdue: {
    color: theme.palette.greyScaleDeprecated[3],
    opacity: 0.90,
  },
  editHover: {
    '&:hover': {
      background: theme.components.register.fieldHoverColor,
    },
    '&:active': {
      border: `solid 1px ${theme.components.register.fieldBorderColor}`,
    },
  },
  dateField: {
    fontSize: theme.components?.register?.fontSize?.default,
  },
  pending: {
    color: theme.components?.register?.pendingTextColor,
  },
  bankOwnedPendingText: {
    fontStyle: 'italic',
    opacity: 0.60,
  },
  bankPendingText: {
    opacity: 0.60,
  },
  bankPending: {
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.greyScaleDeprecated[3],
    fontSize: '16px',
  },
});
