// @flow
import { List, Map as ImmutableMap } from 'immutable';

import * as types from './types';

export const transformResponseToInstitutions = (response: any) =>
  response.data?.map((institution) => transformApiDataToInstitution(institution, null));

export const transformResponseToInstitution = ({ data }: any, action: ?any) =>
  transformApiDataToInstitution(data, action);

export const transformSearchResponseToInstitutions = (response: any) =>
  List(response.data?.map(({ id, name, url }) => types.mkInstitution({ id, name, url })));

const transformApiDataToInstitution = (data: any) => (
  types.mkInstitution({
    id: data.id,
    name: data.name,
    url: data.url,

    logo: data.logo,
    logoUrl: data.logoUrl,
    logoStyle: data.logoStyle,

    aggregator: data.aggregator,
    ofx: data.ofx ? types.mkInstitutionOfxData({
      bid: data.ofx.bid,
      pid: data.ofx.pid,
      name: data.ofx.name,
      contact_url: data.ofx.contact ? data.ofx.contact.url : '',
      contact_phone: data.ofx.contact ? data.ofx.contact.phone : '',
      registraction_url: data.ofx.dcRegistrationUrl,
    }) : null,
    fds: data.fds ? types.mkInstitutionFdsData({
      id: data.fds.id,
      name: data.fds.name,

      contact_url: data.fds.contact ? data.fds.contact.url : '',
      contact_phone: data.fds.contact ? data.fds.contact.phone : '',
      contact_email: data.fds.contact ? data.fds.contact.email : '',

      login_url: data.fds.loginUrl,
      channelData: transformApiChannelDataToChannel(data.fds, 'FDS_DEFAULT', data.fds.form?.fieldEncryptionKey),

      ewcLive: data.fds ? data.fds?.isEwcLive : false,
    }) : null,
    fdp: data.fdp ? types.mkInstitutionFdpData({
      id: data.fdp.id,
      name: data.fdp.name,

      contact_url: data.fdp.contact ? data.fdp.contact.url : '',
      contact_phone: data.fdp.contact ? data.fdp.contact.phone : '',
      contact_email: data.fdp.contact ? data.fdp.contact.email : '',

      login_url: data.fdp.loginUrl,
      channelData: transformApiChannelDataToChannel(data.fdp, undefined, data.fdp.fieldEncryptionKey),
      channelDataByType: data.fdp.channels ? ImmutableMap(
        data.fdp.channels.map((channel) => ([
          channel.channelType,
          transformApiChannelDataToChannel(channel, undefined, data.fdp.fieldEncryptionKey),
        ])),
      ) : undefined,
    }) : null,
    plaid: data.plaid ? types.mkInstitutionPlaidData({
      id: data.plaid.id,
      name: data.plaid.name,
    }) : null,
  })
);

const transformApiChannelDataToChannel = (channelData, defaultChannel, encryptionData) => (
  types.mkInstitutionChannelData({
    type: channelData.channelType || defaultChannel,
    authType: channelData.authType,
    formFields: channelData.form ? List(channelData.form.fields.map((field) => (
      types.mkInstitutionFormField({
        key: field.cpId,
        name: field.name,
        type: field.type,
        displayOrder: field.displayOrder,
        displayLabel: field.displayLabel,
        helpText: field.helpText,
        maxLength: field.maxLength,
        encrypt: field.encrypt,
      })
    ))) : null,
    formEncryption: channelData.form?.signature ? types.mkInstitutionFormEncryption(encryptionData) : undefined,
    formSignature: channelData.form?.signature,
    formSignatureAlgorithm: channelData.form?.signatureAlgorithm,
    partnerAuth: channelData.partnerAuth ? types.mkInstitutionPartnerAuth({
      intuitProperty: channelData.partnerAuth.intuitProperty,
      offeringRedirectUri: channelData.partnerAuth.offeringRedirectUri,
      partnerAuthUrl: channelData.partnerAuth.partnerAuthUrl,
      partnerUid: channelData.partnerAuth.partnerUid,
      fields: List(channelData.partnerAuth.fields.map((field) => (
        types.mkInstitutionFormField({
          key: field.cpId,
          name: field.name,
          type: field.type,
          displayOrder: field.displayOrder,
          displayLabel: field.displayLabel,
          helpText: field.helpText,
          maxLength: field.maxLength,
          encrypt: field.encrypt,
        })
      ))),
    }) : null,
  })
);
