import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CircularProgress from '@mui/material/CircularProgress';
import Bolt from '@mui/icons-material/Bolt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PriorityHigh from '@mui/icons-material/PriorityHigh';

import RootState from 'companion-app-components/utils/redux-store/rootState';
import { getLogger, tracker } from 'companion-app-components/utils/core';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import * as preferencesSelectors from 'companion-app-components/flux/preferences/selectors';
import { ShowBalance } from 'companion-app-components/flux/preferences/types';

import { getQcsAggregationAction } from 'data/aggErrors/errors';
import {
  getInstitutionLoginsById,
  getIsRefreshingAll as getIsAllInstitutionLoginsRefreshing,
  getIdsRefreshing as getInstitutionLoginIdsRefreshing,
} from 'data/institutionLogins/selectors';

import type { UseStylesType } from 'components/AccountCard/types';
import { authSelectors } from 'companion-app-components/flux/auth';
import { createErrorDialog } from 'data/aggErrors/actions';
import QIconButton from 'components/QIconButton';
import AmountField from 'components/QuickenControls/AmountField';
import QTip from 'components/QuickenControls/QTip';
import Dump from 'components/Dump';
import type { DisplayOptions } from '../types';
import { PrimaryTextLine, SecondaryTextLine, styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

const log = getLogger('components/AccountList/AccountRow/index.ts');

const MAX_LEVEL_TO_EXPAND_COLLAPSE = 2;

interface AccountRowProps {
  id: string;
  node: Record<string, any>;
  displayOptions?: DisplayOptions;
  dense: boolean;
  omitCents: boolean;
  showColors: boolean;
  balanceType: string;
  onClick: any;
  onExpandCollapse: any;
  selectedNodeId: any;
  style?: Record<string, any>;
}

const AccountRow: React.FC<AccountRowProps> = (props) => {
  const dispatch = useDispatch();
  const { classes, theme }: UseStylesType = useStyles();
  const institutionLoginsById = useSelector((state: RootState) => getInstitutionLoginsById(state));
  const isAllInstitutionLoginsRefreshing = useSelector((state: RootState) => getIsAllInstitutionLoginsRefreshing(state));
  const institutionLoginsIdsRefreshing = useSelector((state: RootState) => getInstitutionLoginIdsRefreshing(state));
  const accountColorsById = useSelector((state: RootState) => preferencesSelectors.accountColorsById(state));
  const featureFlags = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state));
  const isWebFirstDataset = useSelector((state: RootState) => authSelectors.getIsWebfirstDataset(state));

  const getBalance = (node) => {
    switch (props.balanceType) {
      case 'online':
        return node.sumOnlineAccountBalances;
      case ShowBalance.BANK_REPORTED:
        return node.sumCPOnlineAccountBalances;
      case 'today':
      case ShowBalance.INCLUDE_PENDING:
        return node.sumCurrentAccountBalances;
      case 'final':
        return node.sumFinalAccountBalances;
      default:
        return node.sumEndingAccountBalances;
    }
  };

  const handleAggAction = (aggAction) => {
    const institutionId = aggAction?.institutionLogin && aggAction?.institutionLogin.institutionId
      ? aggAction.institutionLogin?.institutionId
      : '0';
    if (aggAction.actionWithConfirmation) {
      tracker.track(tracker.events.fiLoginFixItFlowStart,
        {
          type: aggAction.type,
          id: aggAction.id,
          institution_id: institutionId,
          agg_status: aggAction.status,
          agg_status_code: aggAction.statusCode || 'null',
          location: 'account list',
          cancellable: true,
        });
      dispatch(isWebFirstDataset ? aggAction.actionWithConfirmation : createErrorDialog(aggAction));
    } else if (aggAction.action) {
      tracker.track(tracker.events.fiLoginFixItFlowStart,
        {
          type: aggAction.type,
          id: aggAction.id,
          institution_id: institutionId,
          agg_status: aggAction.status,
          agg_status_code: aggAction.statusCode || null,
          location: 'account list',
        });
      dispatch(aggAction.action);
    }
  };

  const renderPrimaryText = (node, omitCents, selClass, iconSpace) => {
    let depth = node.depth === 2 && node.isLeaf === true ? 3 : node.depth;
    const balance = getBalance(node);
    const negClass = (getBalance(node) < 0 ? classes.negativeBalDark : classes.positiveBalDark);

    if (node.depth === 1 && node.displayOrder === 0) {
      depth = 0;
    }
    const levelClass = classes[`listItemTextL${depth}`];
    const isClosedAccount = node?.account?.isClosed;

    return (
      <PrimaryTextLine>
        <span
          style={{ width: `${node.displayLabel.length + 2}ch`, marginRight: isClosedAccount ? 0 : undefined, overflow: 'hidden' }}
          className={classNames(levelClass, 'left', selClass)}
        >
          <Dump obj={node} />
          <QTip
            title={node.displayLabel}
            wrapOnly
          >
            <Box
              sx={{ width: '100%' }}
              className={classNames(levelClass, 'left', selClass)}
            >
              <Box component="span" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' }}>
                {isClosedAccount ? <i>{node.displayLabel}</i> : node.displayLabel}
              </Box>
              {iconSpace}
            </Box>
          </QTip>
        </span>
        {isClosedAccount &&
          <i className={classes.closedText}>(Closed)</i>}
        <AmountField
          id={`${props.id}-balance`}
          value={balance}
          className={classNames(levelClass, selClass, negClass)}
          omitCents={omitCents}
          currencySymbol={node.currency}
        />
      </PrimaryTextLine>
    );
  };

  const renderSecondaryText = (node, institutionLogin) => {
    log.log('Rendering secondary text');
    if (!node.account) {
      // secondary text line only exists when we have an account
      return null;
    }

    let lastSuccessfulRefreshOn = '';
    if (node.account.aggregators && node.account.aggregators.size > 0) {
      const aggregator = node.account.aggregators.get(0);
      if (aggregator.lastRefreshSuccessfulAt) {
        const lastRefreshSuccessAt = DateTime.fromISO(aggregator.lastRefreshSuccessfulAt);
        lastSuccessfulRefreshOn = lastRefreshSuccessAt.toLocaleString();
      }
      log.log('LastSuccessfulRefresh', aggregator.lastRefreshSuccessfulAt, lastSuccessfulRefreshOn);
    }

    return (
      <SecondaryTextLine>
        <div>
          {institutionLogin ? `${institutionLogin.name}` : ''}
        </div>
        <div title="refresh-date">
          {lastSuccessfulRefreshOn}
        </div>
      </SecondaryTextLine>
    );
  };

  const renderAggAction = (institutionLogin, aggStatus, aggStatusCode, depth) => {
    if (institutionLogin != null) {
      if (isAllInstitutionLoginsRefreshing) {
        return null;
      }
      if (institutionLoginsIdsRefreshing.has(institutionLogin.id)) {
        return (
          <QIconButton
            disabled
            aria-label="Refreshing"
            classes={{ root: classes.refreshingProgress }}
            size="small"
          >
            <CircularProgress size={20} sx={{ color: theme.palette.blue5 }} />
          </QIconButton>
        );
      }
      const aggAction = getQcsAggregationAction(institutionLogin, aggStatus, aggStatusCode, featureFlags, null, isWebFirstDataset);
      return (
        <QIconButton
          aria-label="aggregation action"
          classes={{
            root: classes[`listItemIconButtonL${depth}`],
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleAggAction(aggAction);
          }}
          size="small"
        >
          {aggAction.type === 'ERROR'
            ? <PriorityHigh classes={{ root: classes.errorIcon }} />
            : isWebFirstDataset && <Bolt classes={{ root: classes.infoIcon }} />}
        </QIconButton>
      );
    }
    return null;
  };

  const isLeafNode = props.node.depth === 2 && props.node.isLeaf === true;
  let depth = isLeafNode ? 3 : props.node.depth;
  if (props.node.depth === 1 && props.node.displayOrder === 0) {
    depth = 0;
  }

  let aggStatus = null;
  let aggStatusCode = null;
  if (props.node.account && props.node.account.aggregators) {
    const aggregator = props.node.account.aggregators.get(0);

    aggStatus = aggregator.aggStatus;
    aggStatusCode = aggregator.aggStatusCode;
  }

  const nodeHasFixedExpansion = props.node.depth > MAX_LEVEL_TO_EXPAND_COLLAPSE;
  const nodeIsExpandable = props.node.children && props.node.expandable && !nodeHasFixedExpansion;
  const newNodeId = props.node.id === 'HIDDEN' ? `${props.node.id}_${props.node.type}` : props.node.id;
  const selClass = props.selectedNodeId === newNodeId ? 'selected' : '';

  const institutionLogin = !(props.node.account && props.node.account.institutionLoginId && institutionLoginsById) ? null :
    institutionLoginsById.get(props.node.account.institutionLoginId);

  const drawerTheme = theme.components.accountDrawer;

  const expandable = props.node.children && props.node.expandable && !nodeHasFixedExpansion;

  const hasAggAction = (aggStatus != null && aggStatus !== 'OK') || (institutionLogin?.optionalAction);

  const iconSpace = (
    <ListItemSecondaryAction
      sx={{ right: (drawerTheme.listPaddingRight - drawerTheme.iconSize) / 2 }}
    >
      {expandable &&
        <QIconButton
          aria-label="Expand/Collapse"
          classes={{
            root: classes[`listItemIconButtonL${depth}`],
          }}
          onClick={(e) => {
            e.stopPropagation();
            props.onExpandCollapse(props.displayOptions);
          }}
        >
          {props.displayOptions && props.displayOptions.expand ? <ExpandLess /> : <ExpandMore />}
        </QIconButton>}
      {hasAggAction && renderAggAction(institutionLogin, aggStatus, aggStatusCode, depth)}
    </ListItemSecondaryAction>
  );

  const primaryText = renderPrimaryText(props.node, props.omitCents, selClass, iconSpace);

  return (
    <div role="list">
      <ListItem
        button
        className={classNames(selClass, nodeIsExpandable && 'expandable', classes[`listItemL${depth}`])}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick(props.node);
        }}
        key={`${props.node.id}`}
        id={props.id}
      >
        {props.node.account && props.showColors &&
          <Box
            className={classes.accountBar}
            sx={{ background: accountColorsById[props.node.account.id] }}
          />}

        <ListItemText
          disableTypography
          primary={primaryText}
          secondary={!props.dense ? renderSecondaryText(props.node, institutionLogin) : null}
          className={classNames(selClass, classes.listItemText)}
          sx={{ width: '100%' }}
        />
      </ListItem>
      {props.node.children && props.node.expandable &&
        <Collapse
          classes={{
            root: classes.collapseContainer,
          }}
          in={nodeHasFixedExpansion || (props.displayOptions && props.displayOptions.expand)}
          unmountOnExit
        >
          {props.node.children
            .map((child, i) => (
              <AccountRow
                {...props}
                id={`${props.id}-${child.displayLabel}-${i}`}
                node={child}
                displayOptions={
                  props.displayOptions && props.displayOptions.children ? props.displayOptions.children.get(child.id) : null
                }
                key={child.id}
                style={{ transitionDelay: `${i * 0.05}s` }}
              />
            ))}
        </Collapse>}
    </div>
  );
};

export default AccountRow;
