
export const styles = ({ palette, components }) => ({
  dialogRoot: {
    minWidth: 650,
    padding: 30,
    paddingTop: 10,
    minHeight: 500,
    height: 'calc(100% - 96px)', // from MUI, ensures a full height dialog always
  },
  header: {
    padding: '15px 0px 15px 0px',
  },
  title: {
    marginBottom: 10,
  },
  budgetSummary: {
    borderBottomStyle: 'solid',
    borderBottomColor: palette.cloudyGrey,
    borderBottomWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: palette.cloudyGrey,
    borderTopWidth: 1,
    padding: '15px 5px 15px 5px',
  },
  summaryHeader: {
    flex: 1.5,
    marginTop: 'auto',
    marginBottom: 'auto',
    fontWeight: 500,
  },
  summaryItem: {
    flex: 1,
  },
  summaryItemHeader: {
    color: palette.koalaGrey,
  },
  summaryItemBody: {
    fontWeight: 600,
    '&.positive': {
      color: components.amountField.positiveColor,
    },
    '&.negative': {
      color: components.amountField.negativeColor,
    },
  },
  addCategoryBlock: {
    borderBottomStyle: 'solid',
    borderBottomColor: palette.cloudyGrey,
    borderBottomWidth: 1,
    padding: 10,
    marginBottom: 16,
  },
  addCategoryButton: {
    marginRight: 10,
  },
  addButtonPrimary: {
    background: '#dbeaff',
    color: '#0d29b7',
    '&:hover': {
      color: 'white',
    },
  },
  addButtonSecondary: {
    color: '#0d29b7',
    '&:hover': {
      color: 'white',
    },
    background: '#f2f8fe',
  },
  qCard: {
    zIndex: 5000,
  },
});
