const styles = ({ spacing }) => ({
  boxFeedbackIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    minWidth: 100,
    gap: spacing(2),
    padding: spacing(1),
  },
  thankYouDialogWrapper: {
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
    minWidth: 550,
    minHeight: 250,
  },
});

export default styles;
