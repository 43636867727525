import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import QButton from 'components/QButton';
import StdDialog from 'components/Dialogs/StdDialog';
import { removeDialog } from 'data/rootUi/actions';
import ThankYouCallout from 'assets/sync-sentiments/thank-you-callout.svg';
import { DIALOG_TYPE_SYNC_SENTIMENTS_THANK_YOU } from './actions';
import styles from './styles';

const useStyles = makeStyles()(styles);

const SyncSentimentThankYouDialog = ({ dialogId }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const handleThankYouClose = () => dispatch(removeDialog(dialogId));

  return (
    <StdDialog
      open
      title="Quicken"
      onClose={handleThankYouClose}
      sharedcomponentid={DIALOG_TYPE_SYNC_SENTIMENTS_THANK_YOU}
    >
      <DialogContent className={classes.thankYouDialogWrapper}>
        <img
          src={ThankYouCallout}
          alt="Thank You Callout"
          width={145}
        />
        <Typography
          variant="subtitle1"
          align="center"
        >
          We appreciate your feedback.
        </Typography>
      </DialogContent>
      <DialogActions>
        <QButton
          id="sync-sentiments-close-thankyou-btn"
          aria-label="Close Thank You"
          onClick={handleThankYouClose}
        >
          Close
        </QButton>
      </DialogActions>
    </StdDialog>
  );
};

SyncSentimentThankYouDialog.propTypes = {
  dialogId: PropTypes.string,
};

export default React.memo(SyncSentimentThankYouDialog);
