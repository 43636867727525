import { Record } from 'immutable';
import type { RecordOf } from 'immutable';

import { localPreferences } from 'companion-app-components/utils/core';

const dataKey = 'share-accept-data';

export type DatasetShareAcceptProps = {
  granteeEmail?: string;
  isGranteeNewUser: boolean;
  datasetId?: string;
  used: number;
};

export type DatasetShareAccept = RecordOf<DatasetShareAcceptProps>;

export const mkDatasetShareAcceptData = Record<DatasetShareAcceptProps>({
  isGranteeNewUser: false,
  granteeEmail: undefined,
  datasetId: undefined,
  used: 1,
});

export const getDatasetShareAcceptData = (): DatasetShareAccept | null => {
  const data = localPreferences.getItem(dataKey);
  return data ? mkDatasetShareAcceptData(data) : null;
};

export const setDatasetShareAcceptData = (data: DatasetShareAccept) => {
  localPreferences.setItem(dataKey, {
    granteeEmail: data.granteeEmail,
    isGranteeNewUser: data.isGranteeNewUser,
    datasetId: data.datasetId,
    used: data.used,
  });
};

export const removeDatasetShareAcceptData = () => {
  localPreferences.removeItem(dataKey);
};
