import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';

const QSkeleton = (props) => {
  const { show, children, ...otherProps } = props;
  if (show) {
    return (
      <Skeleton {...otherProps}>
        {children || null}
      </Skeleton>
    );
  }
  return children || null;
};

QSkeleton.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node || PropTypes.array,
};

export default QSkeleton;
