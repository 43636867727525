import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ExpandMoreRounded';
import { makeStyles } from 'tss-react/mui';

import { capitalizePayee } from 'companion-app-components/flux/rename-rules/renameRulesUtils';
import { getPayeesNameList } from 'data/payees/selectors';

const useStyles = makeStyles()((_theme) => ({
  capitalize: {
    textTransform: 'capitalize',
    backgroundColor: _theme.palette.background.default, // safari hack for disabled Autocomplete - https://quickenteam.atlassian.net/browse/QWA-8531
  },
}));

const PayeeAutocomplete = React.memo((props) => {
  const { textFieldProps, inputValue, ...autoCompleteProps } = props;
  const { classes } = useStyles();

  const payees = useSelector(getPayeesNameList).toArray?.();
  return (
    <Autocomplete
      id="payee"
      sharedcomponentid={'PAYEE_AUTOCOMPLETE'}
      options={payees}
      getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
      autoComplete
      autoHighlight
      clearOnEscape
      freeSolo
      openOnFocus
      disableClearable
      forcePopupIcon
      popupIcon={<ArrowDropDownIcon />}
      renderInput={(params) =>
        <TextField
          {...params}
          label="Payee"
          margin="normal"
          placeholder="Search all Payees"
          variant="outlined"
          {...textFieldProps}
        />}
      {...autoCompleteProps}
      // inputValue={inputValue.toLowerCase()} // we don't want to loose cursor position when changing first letter of the word
      inputValue={inputValue}
      onInputChange={(event, value, reason) =>
        autoCompleteProps.onInputChange?.(event, capitalizePayee(value), reason)}
      classes={{
        input: classes.capitalize,
        ...autoCompleteProps.classes,
      }}
    />
  );
});

PayeeAutocomplete.propTypes = {
  textFieldProps: PropTypes.object,
  inputValue: PropTypes.string,
};

export default PayeeAutocomplete;
