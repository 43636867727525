export const styles = ({ palette }) => ({
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 0,
    paddingBottom: 0,
    border: 'solid 1px #555',
    background: 'white',
    marginBottom: 12,
    marginTop: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1024,
    transition: 'all .5s ease, border 0s linear 0s',
    overflow: 'hidden',

    '&.closed': {
      overflow: 'hidden',
      height: 0,
      marginBottom: 0,
      paddingBottom: 0,
      paddingTop: 0,
      borderBottomWidth: 0,
      transition: 'all .5s ease, border 0s linear 0.5s',
    },
  },
  modalRootOverwrite: {
    maxWidth: 'auto',
    minWidth: 'auto',
    border: 'none',
    borderBottom: `1px solid ${palette.silver}`,
  },
  rootDashboardHeight: {
    height: 70,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 70,
  },
  title: {
    marginTop: 'auto',
    marginBottom: 'auto',
    borderRight: 'solid 1px',
    paddingRight: 10,
    borderRightColor: '#555',
    '&.ontop': {
      paddingBottom: 15,
      borderRight: 'none',
    },
  },
  titleText: {
    display: 'inline-block',
    fontSize: '16px',
    fontWeight: 500,
    color: '#4e4e4e',
  },
  widgetHolder: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  taskWidget: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 'auto',
    marginBottom: 'auto',
    verticalAlign: 'middle',
    flex: 1,
  },
  toggleButton: {
    marginBottom: -11,
  },
  toggleSwitch: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 71,
    height: 8,
    border: 'solid 2px #555',
    background: '#fff',
    borderRadius: 3,
  },
});
