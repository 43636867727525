import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { useDispatch } from 'react-redux';

import { transactionListSelectors } from 'companion-app-components/flux/transaction-list';

import { removeDialog } from 'data/rootUi/actions';

import useQData from 'components/QData/useQData';
import SelectTransactionDialog from 'components/Dialogs/SelectTransactionDialog';

const MatchTxnDialog = (props) => {

  const { txn, wrapperId } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const { qDataMatchTransactions } = useQData(wrapperId);

  const selectorProps = { matchTxn: txn, accountIds: List([txn.accountId]) };

  const onClose = () => {
    dispatch(removeDialog());
    setOpen(false);
  };

  // data stuff
  const matchOverdueTransaction = (targetTxn) => {
    qDataMatchTransactions([targetTxn, txn]);
    onClose();
  };

  return (
    <SelectTransactionDialog
      open={open}
      title="Link Transaction"
      subtitle={`Choose transaction to link to ${txn.payee}`}
      buttonText="Link"
      disableAdd
      onClose={onClose}
      onTransactionSelected={matchOverdueTransaction}
      transactionListSelector={transactionListSelectors.getSortedTransactionsForAccountIds}
      selectorProps={selectorProps}
      sharedcomponentid={'MATCH_TXN_DIALOG'}

    />
  );
};

MatchTxnDialog.defaultProps = {
  txn: {},
  wrapperId: '',
};

MatchTxnDialog.propTypes = {
  txn: PropTypes.object,
  wrapperId: PropTypes.string,
};

export default MatchTxnDialog;
