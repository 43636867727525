const styles = (theme) => ({
  root: {
    outlineWidth: 0,
    outlineStyle: 'none',
    minWidth: 16,
    maxWidth: 160,
    padding: theme.spacing(0, 0.625),
    width: 'fit-content',
    color: `${theme.palette.grey.level0} !important`,
    borderRadius: 3,
    verticalAlign: 'middle',
    border: '1px solid transparent',
    overflow: 'hidden',

    display: 'block',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.applyOpacityToHex(theme.palette.grey.level0, 0.1),
    },

    '&:focus': {
      display: 'flex',
      border: `1px solid ${theme.applyOpacityToHex(theme.palette.primary.main, 0.6)}`,
      backgroundColor: theme.palette.grey.level7,
    },
  },
});

export default styles;
