// @flow
import React, { useLayoutEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useStyles } from './styles';

const getMaskedNumberForInfo = (number) => {
  if (!number) {
    return '';
  }
  const charactersNum = number.length >= 4 ? 4 : number.length;
  const lastCharacters = number && number.slice(-charactersNum);
  const last4Characters = (lastCharacters && !Number.isNaN(Number(lastCharacters))) ? 'X'.repeat(4 - charactersNum) + lastCharacters : 'XXXX';
  return ` ••••${last4Characters}`;
};

type Props = {
  name: String,
  type: String,
  cpName: String,
  cpNumber: String,
}

const AccountRow = (props: Props) => {
  const { name, type, cpName, cpNumber } = props;

  const nameRef = useRef();
  const [showNameTip, setShowNameTip] = useState();

  const { classes } = useStyles();

  let additionalNameInfo = (name !== cpName) ? cpName : '';
  additionalNameInfo += getMaskedNumberForInfo(cpNumber);

  useLayoutEffect(() => {
    setShowNameTip(nameRef.current && (nameRef.current.offsetWidth < nameRef.current.scrollWidth));
  }, []);

  return (
    <Box display="flex" flex="1" mb={1}>
      <Box display="flex" maxWidth="2%" className={classes.cell}>
        &bull;
      </Box>
      <Box flexBasis="49%" maxWidth="30%" className={classes.cell} style={{ overflow: 'hidden' }}>
        <Box display="flex" flex="1" flexDirection="column" style={{ overflow: 'hidden' }}>
          <Tooltip title={showNameTip ? name : ''}>
            <Typography variant="body2" noWrap ref={nameRef}>
              {name}
            </Typography>
          </Tooltip>
          <Typography
            variant="caption"
            className={classes.accDetails}
          >
            {additionalNameInfo}
          </Typography>
        </Box>
      </Box>
      <Box flexBasis="23%" maxWidth="20%" className={classes.cell}>
        <Typography variant="body2">
          {type}
        </Typography>
      </Box>
      <Box flexBasis="24%" maxWidth="48%" className={classes.cell}>
        &nbsp;
      </Box>
    </Box>
  );
};

export default AccountRow;
