const styles = ({ defaults, palette, typography }) => ({
  root: {
    position: 'relative', // insures that header's drop shadow is on top, not obscured
    zIndex: 0,
    minWidth: defaults.content.pageMinWidth,
    backgroundColor: palette.background.default,
  },
  barSection: {
    backgroundColor: palette.greyScaleDeprecated[7],
  },
  content: {
    margin: '2px auto',
    width: '100%',
    padding: `20px ${defaults.content.paddingLeftMobile}px`,
    '@media (min-width: 769px)': {
      padding: `20px ${defaults.content.paddingLeft}px`,
    },
  },
  transactionsHeader: {
    ...typography.body1,
    color: palette.greyScaleDeprecated[2],
  },
  transactionsBarSentinel: {
    height: 1,
    visibility: 'hidden',
  },
  breadCrumbWrapper: {
    padding: '10px 20px 0',
  },
  pathButton: {
    ...typography.body2,
    background: 'transparent',
    border: 'none',
    outline: 0,
    color: palette.blue3,
    textTransform: 'none',
    minWidth: 10,
    marginTop: 7,
    padding: 0,
    cursor: 'pointer',
    '&:disabled': {
      color: palette.gray2,
    },
    '&.isLastLeaf': {
      cursor: 'default',
    },
  },
  pathDivider: {
    ...typography.body2,
    padding: '6px 6px 6px 5px',
  },
});

export default styles;
