import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Chip from '@mui/material/Chip';

import { getEnvironmentConfig } from 'companion-app-components/utils/core';
import StdDialog from 'components/Dialogs/StdDialog';
import AxiosFactory from 'utils/axiosFactory';
import { dispatchSimpleNotification } from 'data/notifications/notificationsUtils';

const useStyles = makeStyles()((_theme) => ({
  dense: {
    padding: '0 !important',
    margin: '0 !important',
    minHeight: '0 !important',
  },
  expansionPanelSummary: {
    flexDirection: 'row-reverse',
    padding: '0 !important',
    margin: '0 !important',
    minHeight: '0 !important',
  },
}));

const RequestDialog = (props) => {
  const { classes } = useStyles();
  const { path, query, onHandleClose, ...otherProps } = props;
  const [method, setMethod] = useState(props.method || 'POST');
  const [url, setURL] = useState(props.url || `${getEnvironmentConfig().services_url}`);
  const [body, setBody] = useState(props.body);
  const [response, setResponse] = useState();
  const [disableSend, setDisableSend] = useState(false);

  useEffect(() => setMethod(props.method), [props.method]);
  useEffect(() => setBody(props.body), [props.body]);

  useEffect(() => {
    const customURL = new URL(props.url || path || '', props.url ? undefined : `${getEnvironmentConfig().services_url}`);
    if (query) {
      Object.entries(query).forEach(([key, value]) => customURL.searchParams.append(key, value));
    }
    setURL(customURL.href);
  }, [path, query, props.url]);

  const sendRequest = () => {
    dispatchSimpleNotification('request sent');
    setResponse(undefined);
    setDisableSend(true);
    const qcsAxios = AxiosFactory.get('qcs');
    switch (method) {
      case 'POST':
        qcsAxios
          .post(url, body)
          .then((axiosResponse) => setResponse(axiosResponse))
          .catch((error) => setResponse({ status: error.message, ...error.response }))
          .finally(setDisableSend(false));
        break;
      case 'PUT':
        qcsAxios
          .put(url, body)
          .then((axiosResponse) => setResponse(axiosResponse))
          .catch((error) => setResponse({ status: error.message, ...error.response }))
          .finally(setDisableSend(false));
        break;
      case 'DELETE':
        qcsAxios
          .delete(url)
          .then((axiosResponse) => setResponse(axiosResponse))
          .catch((error) => setResponse({ status: error.message, ...error.response }))
          .finally(setDisableSend(false));
        break;
      case 'GET':
      default:
        qcsAxios
          .get(url, body)
          .then((axiosResponse) => setResponse(axiosResponse))
          .catch((error) => setResponse({ status: error.message, ...error.response }))
          .finally(setDisableSend(false));
    }
  };

  return (
    <StdDialog
      title="Send Request"
      {...otherProps}
      onClose={(event, reason) => {
        if (reason !== 'ENTER_KEY_DOWN') onHandleClose(event, reason);
      }}
    >
      <DialogContent>
        <Box display="flex" sx={{ pt: 1 }}>
          <TextField
            onChange={(event) => setMethod(event.target.value)}
            value={method}
            label="Method"
            variant="outlined"
            style={{ width: 200 }}
            onKeyDown={(event) => {
              event.stopPropagation();
              if (event.key === 'Enter') {
                sendRequest();
              }
            }}
          />
          <TextField
            autoFocus
            onFocus={(event) => event.target.select()}
            onChange={(event) => setURL(event.target.value)}
            value={url}
            fullWidth
            label="URL"
            variant="outlined"
            style={{ width: 800 }}
            onKeyDown={(event) => {
              event.stopPropagation();
              if (event.key === 'Enter') {
                sendRequest();
              }
            }}
          />
        </Box>
        <br />
        <TextField
          onChange={(event) => setBody(event.target.value)}
          value={body}
          fullWidth
          label="Body"
          variant="outlined"
          multiline
          onKeyDown={(event) => event.stopPropagation()}
        />

        <Collapse in={Boolean(response)}>
          <br />

          <Accordion
            elevation={0}
            classes={{
              root: classes.dense,
              expanded: classes.dense,
            }}
          >
            <AccordionSummary
              id="advance-dropdown-summary"
              classes={{
                root: classes.expansionPanelSummary,
                expanded: classes.dense,
              }}
              expandIcon={<ExpandMoreIcon style={{ float: 'left' }} />}
            >
              Response status: {response?.status}
            </AccordionSummary>

            <AccordionDetails className={classes.noPadding}>
              <Box display="flex" flexWrap="wrap">
                {response?.headers && Object.entries(response?.headers).map(([key, value]) => (
                  <Chip
                    key={key}
                    size="small"
                    variant="outlined"
                    label={`${key}: ${value}`}
                    style={{ margin: 4 }}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>

          <TextField
            InputLabelProps={{ shrink: Boolean(response?.data) }}
            inputProps={{ readOnly: true }}
            value={response?.data ? JSON.stringify(response?.data, null, 2) : ''}
            fullWidth
            label="Response Body"
            variant="outlined"
            multiline
          />

        </Collapse>
      </DialogContent>

      <DialogActions>
        <Button
          id="send-request"
          disabled={disableSend}
          onKeyDown={(event) => event.stopPropagation()}
          onClick={(event) => {
            event.stopPropagation();
            sendRequest();
          }}
        >
          Send
        </Button>
      </DialogActions>
    </StdDialog>
  );
};

RequestDialog.propTypes = {
  method: PropTypes.string,
  url: PropTypes.string,
  path: PropTypes.string,
  query: PropTypes.object,
  body: PropTypes.string,
  open: PropTypes.bool,
  onHandleClose: PropTypes.func,
};

export default RequestDialog;
