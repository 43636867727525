import React, { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import { debounce } from 'lodash';

import { getLogger } from 'companion-app-components/utils/core';

import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVertRounded';
import { IconButtonOwnProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import QIconButton from 'components/QIconButton';
import QTip from 'components/QuickenControls/QTip';
import QMenuList from 'components/QMenu/QMenuList';
import { style } from './style';

const log = getLogger('components/QuickenControls/QMenu/index.tsx');

type MenuOption = {
  divider?: boolean;
  isSubheader?: boolean;
  customRender?: React.ReactNode;
  label: string;
  value: string;
  nop?: boolean;
};

export interface QMenuProps {
  onChange: (value: string, extraId?: string | null, noClose?: boolean) => void;
  options: MenuOption[];
  menuIcon?: React.ElementType;
  menuCloseIcon?: React.ElementType;
  menuStyle?: React.CSSProperties;
  open?: boolean;
  onClose?: () => void;
  subMouseEntered: () => void;
  openOnHover?: boolean;
  name: string;
  title?: string;
  menuIconButtonSize?: IconButtonOwnProps['size'];
  buttonRef?: (x: HTMLElement | null) => void;
  customTriggerClass?: string;
  autoFocus?: boolean;
  customTrigger?: React.ReactNode;
  anchorOrigin?: any;
  transformOrigin?: any;
}

interface QMenuHandles {
  initMenu: (event: React.MouseEvent<HTMLElement>, delay: boolean) => void;
}

const useStyles = makeStyles()(style);
let idCounter = 1;

const QMenu = forwardRef<QMenuHandles, QMenuProps>((props, ref) => {

  const { classes, theme } = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const [extraId, setExtraId] = useState<string | null>(null);
  const [anchorHover, setAnchorHover] = useState<HTMLElement | null>(null);
  const [myId, setMyId] = useState(idCounter);

  const menuRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    idCounter += 1;
    setMyId(idCounter);
  }, []);

  const open = Boolean(anchorEl) || Boolean(props.open);

  const myLog = useCallback((...args) => {
    log.debug('QMenu', props.name, args);
  }, [props]);

  const onEntered = () => {
    const e = document.getElementById(`long-menu-wrapper_${myId}`);
    menuRef.current = e;
    menuRef.current?.focus();
    myLog('MENU ENTERED', myId);
  };

  const onExiting = () => {
    menuRef.current = null;
  };

  const onExited = () => {
    myLog('EXITED ', props.name, value);

    if (props.onChange && value !== null) {
      props.onChange(value, extraId);
    }
    setValue(null);
    setExtraId(null);
    setAnchorHover(null);
    setAnchorEl(null);
  };

  // mouse left the icon/trigger for opening a submenu
  const mouseLeftSubMenuTrigger = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 500);
  };

  // request to close this menu
  const handleRequestClose = useCallback(() => {
    myLog('REQUEST CLOSE', props.name);
    setAnchorEl(null);
    if (props.onClose) {
      props.onClose();
    }
  }, [props, myLog]);

  // item from this menu was clicked on
  const handleClick = useCallback((menuValue: string, menuExtraId: string | null, noClose?: boolean) => {
    if (noClose && props.onChange) {
      props.onChange(menuValue, menuExtraId, noClose);
      return;
    }
    setValue(menuValue);
    setExtraId(menuExtraId);
    if (!noClose) handleRequestClose();
  }, [props, handleRequestClose]);

  // show this menu (the trigger was clicked or hovered)
  const initMenu = (event: React.MouseEvent<HTMLElement>, delay = false) => {
    const target = event.currentTarget;
    event.stopPropagation();
    if (delay) {
      setTimeout(() => setAnchorEl(target), 500);
    } else {
      setAnchorEl(target);
    }
  };

  useImperativeHandle(ref, () => ({
    initMenu,
  }));

  const buttonRefFunc = (x: HTMLElement | null) => {
    if (open) {
      setAnchorEl(x);
    }
    if (props.buttonRef) {
      props.buttonRef(x);
    }
  };

  const debounceHover = debounce((currTarget) => setAnchorHover(currTarget), 100);

  const hover = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const currTarget = e.currentTarget;
    debounceHover(currTarget);
  }, [debounceHover]);

  const MenuIcon = !open ? (props.menuIcon || MoreVertIcon) : (props.menuCloseIcon || props.menuIcon || MoreVertIcon);
  const subMenuStyle = props.openOnHover ? classes.subMenu : { };

  const listItemsData = useMemo(() => props?.options.map((item) =>
    <QMenuList
      key={`qmenulist_item_${JSON.stringify(item.value)}_${item.label}`}
      item={item}
      subMenuType={QMenu}
      hover={hover}
      subMouseEntered={props.subMouseEntered}
      onChange={props.onChange}
      classes={classes}
      anchorHover={anchorHover}
      name={props.name}
      handleClick={handleClick}
      theme={theme}
    />), [props.options, props.subMouseEntered, props.onChange, classes, anchorHover, props.name, theme, handleClick, hover]);

  return (
    <>
      {props.customTrigger &&

        <QTip title={props.title}>
          <ButtonBase
            onClick={initMenu}
            ref={(x) => buttonRefFunc(x)}
            id={`qmenu_open_${props.name}`}
            autoFocus={props.autoFocus}
            focusRipple
            className={props.customTriggerClass || ''}
          >
            {props.customTrigger}
          </ButtonBase>
        </QTip>}
      {!props.customTrigger &&
        <div
          onMouseEnter={props.openOnHover ? (e) => initMenu(e, true) : undefined}
          onMouseLeave={props.openOnHover ? mouseLeftSubMenuTrigger : undefined}
          style={{ ...subMenuStyle, ...props.menuStyle }}
        >
          <QTip title={props.title}>
            <QIconButton
              id={`qmenu_open_${props.name}`}
              aria-label="More"
              aria-owns={open ? `long-menu_${myId}` : null}
              aria-haspopup="true"
              onClick={initMenu}
              component="div"
              size={props.menuIconButtonSize || null}
              autoFocus={props.autoFocus}
            >
              <MenuIcon
                className={classes.iconColor}
              />
            </QIconButton>
          </QTip>
        </div>}

      <div
        onMouseEnter={props.subMouseEntered}
      >
        <Menu
          className={classes.menuItem}
          id={`long-menu_${myId}`}
          open={open}
          anchorEl={anchorEl || anchorEl}
          anchorOrigin={props.anchorOrigin || { horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={props.transformOrigin || { horizontal: 'left', vertical: 'top' }}
          onKeyDown={(e) => {
            if (
              ['Enter', 'Escape', 'ArrowUp', 'ArrowDown', 'Tab'].includes(e.key)
            ) {
              e.stopPropagation();
            }
            if (e.key === 'Escape') {
              handleRequestClose();
            }
          }}
          PaperProps={{
            id: `long-menu-wrapper_${myId}`,
            className: classes.paperStyling,
          }}
          onBackdropClick={(e) => {
            e.stopPropagation();
            handleRequestClose();
            e.preventDefault();
          }}
          TransitionProps={{
            onExited,
            onExiting,
            onEntered,
          }}
        >
          {listItemsData}
        </Menu>
      </div>
    </>
  );
});

export default QMenu;
