import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import StdDialog from 'components/Dialogs/StdDialog';
import { theme } from 'themes/themeQuicken';

const WhatsNewView = ({ classes, removeDialog, pageData, dispatchShowReleaseNotesDialog }) => {
  const [activePage, setActivePage] = React.useState(0);

  const handleClose = () => {
    removeDialog();
    setTimeout(() => {
      setActivePage(0);
    }, 500);
  };

  const handleNext = () => {
    setActivePage((curActivePage) => curActivePage + 1);
  };

  const handlePrev = () => {
    setActivePage((curActivePage) => curActivePage - 1);
  };

  const handleDone = () => {
    handleClose();
  };

  if (!pageData?.pages) { return null; }

  return (
    <StdDialog
      open
      onClose={removeDialog}
      title={`What's New in Quicken Classic (v${process.env?.APP_VERSION})`}
      sharedcomponentid="WHATS_NEW"
      titleClasses={classes.dialogTitle}
      PaperProps={{
        sx: {
          maxWidth: 800,
          maxHeight: 700,
          width: '75vw',
          height: '90vh',
        },
      }}
      colorBar={theme.palette.primary.main}
    >
      <DialogContent className={classes.dialogContent}>
        <iframe
          title="What's New in Quicken Classic"
          src={`/whats-new${process.env?.APP_VERSION}/${pageData.pages[activePage]}`}
          className={classes.iFrame}
        />
      </DialogContent>
      <Divider sx={{ mx: 4 }} />
      <DialogActions>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Button onClick={dispatchShowReleaseNotesDialog}>
            Open Release Notes
          </Button>
          <Box display="flex" alignItems="center">
            {pageData.pages.map((page, number) => (
              <FiberManualRecordIcon
                key={page}
                fontSize="small"
                color={number === activePage ? 'primary' : 'disabled'}
                sx={{ cursor: 'pointer' }}
                onClick={() => setActivePage(number)}
              />
            ))}
          </Box>
          <span>
            <Button
              onClick={handlePrev}
              disabled={activePage <= 0}
              sx={{ minWidth: 90 }}
            >
              {activePage > 0 ? 'Previous' : ''}
            </Button>
            {activePage === pageData.count - 1 ? (
              <Button onClick={handleDone}>Done</Button>
            ) : (
              <Button onClick={handleNext}>Next</Button>
            )}
          </span>
        </Box>
      </DialogActions>
    </StdDialog>
  );
};

WhatsNewView.propTypes = {
  classes: PropTypes.object,
  removeDialog: PropTypes.func,
  pageData: PropTypes.object,
  dispatchShowReleaseNotesDialog: PropTypes.func,
};

export default React.memo(WhatsNewView);
