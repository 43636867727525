import React, { useState, useEffect, useRef, useCallback, ReactNode, FC } from 'react';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import QButton from 'components/QButton';
import QIconButton from 'components/QIconButton';

import { POINTER_SIZE, styles } from './styles';

const REFRESH_INTERVAL = 250;
const REFRESH_INTERVAL_SMALL = 100;

const useStyle = makeStyles()(styles as Record<string, any>);

interface QCardProps {
  open: boolean;
  cards: Record<string, any>;
  initialCard: string;
  onClose: () => void;
  name: string;
  children: ReactNode;
}

const QCard: FC<QCardProps> = ({
  open: initialOpen,
  initialCard,
  cards,
  onClose,
  name,
  children,
}) => {

  const [open, setOpen] = useState(initialOpen);
  const [qCardCoords, setQCardCoords] = useState<Record<string, any>>({});
  const [activeElement, setActiveElement] = useState<HTMLElement | null>(null);
  const [currentCard, setCurrentCard] = useState(initialCard);

  const intervalTimer = useRef<null | ReturnType<typeof setInterval>>(null);
  const intervalTimeout = useRef(REFRESH_INTERVAL);
  const positionCheck = useRef<null | ReturnType<typeof setInterval>>(null);

  const { classes } = useStyle();

  const calcCoords = (edge, qCard, coords) => {
    const pointerSize = POINTER_SIZE;
    let { left, top } = coords;

    // we try to place the qCard at it's preferred location
    switch (edge) {
      case 'left':
        left = coords.left - qCard.width - 8;
        top = coords.top + ((coords.bottom - coords.top) / 2 - qCard.height / 2);
        break;
      case 'right':
        left = coords.right + 8;
        top = coords.top + ((coords.bottom - coords.top) / 2 - qCard.height / 2);
        break;
      case 'righttop':
        left = coords.right + 8;
        top = coords.top;
        break;
      case 'rightbottom':
        left = coords.right + 8;
        top = coords.bottom - qCard.height + pointerSize * 1.5;
        break;
      case 'lefttop':
        left = coords.left - qCard.width - 8;
        top = coords.top;
        break;
      case 'leftbottom':
        left = coords.left - qCard.width - 8;
        top = coords.bottom - qCard.height + pointerSize * 1.5;
        break;
      case 'top':
        break;
      case 'bottom':
        break;
      default:
        break;
    }

    if (qCard.nudge) {
      left += qCard.nudge.left || 0;
      top += qCard.nudge.top || 0;
    }

    // don't let the QCard go off the left edge of the screen
    if (left < 0) left = 0;

    // Avoid the Qcard to go off the screen
    const maxTopAllowed = window.innerHeight - qCard.height;
    if (top < 0) {
      top = 0;
    } else if (top > maxTopAllowed) {
      top = maxTopAllowed;
    }

    return { left, top, right: left + qCard.width, bottom: top + qCard.height };
  };

  const getQCardCoords = (el, qCard) => {
    if (!qCard) return null;
    let edge = qCard.edge || 'left';
    if (el) {
      const coords = el.getBoundingClientRect();
      let coordsObj = calcCoords(edge, qCard, coords);

      if (coordsObj.left < 0) {
        edge = edge.replace('left', 'right');
        coordsObj = calcCoords(edge, qCard, coords);
      } else if (coordsObj.right > window.innerWidth) {
        edge = edge.replace('right', 'left');
        coordsObj = calcCoords(edge, qCard, coords);
      }

      return { ...coords, ...coordsObj, edge };
    }
    return null;
  };

  const updateQCardCoords = (el = activeElement) => {
    if (cards) {
      const qCard = cards[currentCard];
      const newCoords = getQCardCoords(el, qCard);
      if (qCardCoords?.top !== newCoords?.top || qCardCoords?.left !== newCoords?.left) {
        setQCardCoords(newCoords);
      }
    }
  };

  useEffect(() => {
    positionCheck.current = setInterval(() => {
      updateQCardCoords();
    }, REFRESH_INTERVAL);
    return () => {
      if (positionCheck.current) {
        clearInterval(positionCheck.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCard]); 

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkCardPosition = () => { 
    updateQCardCoords();
  };

  const updateActiveElement = (el) => {
    if (activeElement) {
      activeElement.classList.remove(classes.highlight);
    }
    if (el) {
      el.classList.add(classes.highlight);
    }
    setActiveElement(el);
    updateQCardCoords(el);
  };

  const checkCardUpdate = useCallback(() => {
    if (cards) {
      const qCard = cards[currentCard];
      if (qCard && open) {
        const activeEle = document.getElementById(qCard.elementId);
        if (activeEle !== activeElement) {
          updateActiveElement(activeEle);
          if (!activeEle && qCard.next) {
            setCurrentCard(qCard.next);
          } else if (!qCard.next) {
            if (onClose) {
              onClose();
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, currentCard, open, onClose]); 

  useEffect(() => {
    if (open && cards) {
      checkCardPosition();
    }
  }, [open, cards, checkCardPosition]);

  useEffect(() => {
    if (cards) {
      setTimeout(checkCardUpdate, 10);
    }
  }, [cards, checkCardUpdate]);

  useEffect(() => {
    if (initialOpen !== open) {
      if (!initialOpen && intervalTimer.current) {
        clearInterval(intervalTimer.current);
      }
      if (initialOpen && !intervalTimer.current) {
        intervalTimer.current = setInterval(checkCardPosition, intervalTimeout.current);
      }
      if (initialOpen) {
        setCurrentCard(initialCard);
      }
      if (!initialOpen && activeElement) {
        activeElement.classList.remove(classes.highlight);
        setActiveElement(null);
      }
      setOpen(initialOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOpen, initialCard, cards, activeElement, classes.highlight, checkCardPosition]);

  const moveCard = (direction, qCard) => {
    const nextCard = qCard[direction];

    if (nextCard) {
      const nextCardData = cards[nextCard];
      const activeEle = document.getElementById(nextCardData.elementId);

      if (nextCardData?.scrollIntoView) {
        updateActiveElement(activeEle);
        intervalTimeout.current = REFRESH_INTERVAL_SMALL;
        activeEle?.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'auto' });
        updateQCardCoords(activeEle);
        return;
      }
      updateActiveElement(activeEle);
      setCurrentCard(nextCard);
    }
  };

  const closeClick = () => {
    if (activeElement) {
      activeElement.classList.remove(classes.highlight);
    }
    if (onClose) {
      onClose();
    }
  };

  const qCard = cards?.[currentCard];
  const showQCard = Boolean(open && Object.keys(qCardCoords ?? {}).length && qCard);

  return (
    <div
      id={`QCard-Wrapper-${name || 'default'}-${showQCard ? 'showCard' : 'off'}`}
      className={classNames({ [classes.wrapperOff]: !showQCard })}
    >
      {showQCard && (
        <div
          key="qcard-template"
          className={classNames(classes.qcard, qCardCoords?.edge)}
          style={{
            left: qCardCoords?.left,
            top: qCardCoords?.top,
            width: qCard?.width,
            height: qCard?.height,
          }}
        >
          {onClose && typeof onClose === 'function' && (
            <div className={classes.closeBox}>
              <QIconButton size="small-target" onClick={closeClick}>
                <CloseIcon className={classes.closeIcon} id="qcard-closex" />
              </QIconButton>
            </div>
          )}
          <div className={classes.contentArea}>
            <Typography variant="h6" className={classes.title}>
              {qCard?.title}
            </Typography>
            <Typography variant="body1" className={classes.content}>
              {qCard?.content}
            </Typography>
          </div>
          <div className={classes.buttonArea}>
            {qCard?.prev && (
              <QButton onClick={() => moveCard('prev', qCard)} className={classes.buttonText}>
                {'< Prev'}
              </QButton>
            )}
            {qCard?.next && (
              <QButton onClick={() => moveCard('next', qCard)} className={classes.buttonText}>
                {'Next >'}
              </QButton>
            )}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default QCard;
