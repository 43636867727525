import { Info } from 'luxon';
import { Map as ImmutableMap } from 'immutable';

import { STTypeEnum, STType } from 'companion-app-components/flux/scheduled-transactions/scheduledTransactionsTypes';
import {
  weekDays,
  negatorFromType,
  frequencyAliasTypes,
} from 'companion-app-components/flux/scheduled-transactions/scheduledTransactionsUtils';
import { categoriesSelectors } from 'companion-app-components/flux/categories';

const getConsecutiveMap = (max: number, label: string[]) => ImmutableMap([...Array(max).keys()]
  .map((x) => {
    const index = x + 1;
    return [`${index}`, `${index} ${index > 1 ? label[1] || '' : label[0] || ''}`];
  }));

export const weeksIntervalMap = getConsecutiveMap(10, ['week', 'weeks']); // 1 week to 10 weeks

export const monthsIntervalMap = getConsecutiveMap(12, ['month', 'months']);// 1 month to 12 month

export const yearsIntervalMap = getConsecutiveMap(15, ['year', 'years']);// 1 year to 15 years

// Week Days -- Monday, Tuesday...
export const byDayMap = ((shortDays: string[], longDays: string[]) =>
  ImmutableMap(shortDays.map((short, index) =>
    [short, longDays[index]])))(weekDays, Info.weekdays());

// Month days 1 to max
export const getByMonthDaysMap = (max: number) =>
  ImmutableMap([...Array(max).keys()].map((i) => {
    const n = i + 1;
    return [`${n}`, `${n}${['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'}`];
  }));

export const frequenciesEditableInterval: string[] = [
  frequencyAliasTypes.dayQuicken,
  frequencyAliasTypes.weeklyQuicken,
  frequencyAliasTypes.monthlyQuicken,
  frequencyAliasTypes.twiceAMonthQuicken,
  frequencyAliasTypes.yearlyQuicken,
  frequencyAliasTypes.twiceAYearQuicken,
];

export const hasByMonthDayOrLastDay = (virtualRecurrence) =>
  Object.keys(virtualRecurrence).includes('byMonthDayOrLastDay')
    && virtualRecurrence.byMonthDayOrLastDay !== null && virtualRecurrence.byMonthDayOrLastDay !== undefined;

export const setVirtualAmount = (schTxn, amount) => {
  const negator = negatorFromType(schTxn.type);
  return Math.abs(amount) * negator;
};

export const filterFn = (coa: { id: string, type: STType }, type: STType) => {
  if (!type) { return true; }

  if (type === STTypeEnum.BILL || type === STTypeEnum.EXPENSE) {
    return categoriesSelectors.isExpenseCat(null, coa.id) || coa.type === 'BALANCE_ADJUSTMENT' || coa.type === 'UNCATEGORIZED';
  }
  if (type === STTypeEnum.SUBSCRIPTION) {
    return categoriesSelectors.isExpenseCat(null, coa.id) || coa.type === 'UNCATEGORIZED';
  }
  if (type === STTypeEnum.INCOME) {
    return categoriesSelectors.isIncomeCat(null, coa.id) || coa.type === 'UNCATEGORIZED';
  }
  if (type === STTypeEnum.TRANSFER) {
    return coa.type === 'ACCOUNT' || coa.type === 'BALANCE_ADJUSTMENT';
  }
  return true;
};

export const dayMap = {
  SU: 0, // Sunday
  MO: 1, // Monday
  TU: 2, // Tuesday
  WE: 3, // Wednesday
  TH: 4, // Thursday
  FR: 5, // Friday
  SA: 6,  // Saturday
};

export const US_DATE_FORMAT = 'M/d/yyyy'; // valid for 'MM/dd/yyyy' format also
export const LONG_MONTH_DATE_FORMAT = 'LL/dd';
export const SHORT_DATE_FORMAT = 'MM/dd';
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
