/*
 * Quicken Simple Amount Field (QAmountField)
 */

// BASE
import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import numeral from 'numeral';

// CUSTOM COMPONENTS

// SELECTORS, ACTIONS, UTILS
import getSymbolFromCurrency from 'currency-symbol-map';

// MUI COMPONENTS
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

// PATH RELATIVE IMPORTS
import { styles } from './styles';

const propTypes = {
  editable: PropTypes.bool,
  InputProps: PropTypes.object,
  onSubmit: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func, // returns an event with target.value as the updated value.
  // Would love to change it to just return the value, but we need it this way as long as we want to keep using formik (it wants an event)
  value: PropTypes.number,
  currency: PropTypes.string,
  submitOnBlur: PropTypes.bool,
  hideCurrency: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  textFieldProps: PropTypes.object,
  styleProps: PropTypes.object,
  typographyVariant: PropTypes.string,
  removeSpacing: PropTypes.bool,
  size: PropTypes.string,
  wrapperStyles: PropTypes.object, // styling for the wrapper div so that input can fill a parent container
  noNegatives: PropTypes.bool,
  textFieldVariant: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  decimals: PropTypes.number, // default to 2, replace omitCents with 0

  expectedSign: PropTypes.string, // (positive, negative) default to negative
  showSign: PropTypes.string, // enum (positive, negative, both)
  showColor: PropTypes.string, // enum (positive, negative, both)
  disabled: PropTypes.bool,
};

export const expectedSignEnum = Object.freeze({
  positive: 'positive',
  negative: 'negative',
});
export const showSignEnum = Object.freeze({
  positive: 'positive',
  negative: 'negative',
  both: 'both',
});
export const showColorEnum = Object.freeze({
  positive: 'positive',
  negative: 'negative',
  both: 'both',
});

// STYLES HOOK CREATION
const useStyles = makeStyles()(styles);

const preserveCaretPosition = (e, offset = 0) => {
  const caretPosition = e?.target?.selectionStart + offset;
  e.persist();
  setTimeout(() => e.target?.setSelectionRange?.(caretPosition, caretPosition), 0);
};

const QAmountField = (props) => {

  // PROPS ============================================================================
  const { removeSpacing, typographyVariant, editable, InputProps, value, currency, hideCurrency, onSubmit, onBlur, disableUnderline,
    submitOnBlur, autoFocus, id, textFieldProps, styleProps, onChange, size, wrapperStyles, noNegatives,
    textFieldVariant, margin, className, showSign, decimals, expectedSign, showColor, ...otherProps } = props;

  const expectedSignInternal = noNegatives ? expectedSignEnum.positive : props.expectedSign;

  // STATE ============================================================================
  const [fieldValue, setFieldValue] = useState('0');
  const [hasFocus, setHasFocus] = useState(false);

  // SELECTORS ==========================================================================
  const currencySymbol = hideCurrency ? '' : getSymbolFromCurrency(currency || 'USD');

  // EFFECTS ============================================================================
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!hasFocus) {
      const newValue = editable ? renderEditableValue(value, !hasFocus) : renderStaticValue(value);
      setFieldValue(newValue);
    }
  }, [value, hasFocus, expectedSignInternal]);

  // STYLES ============================================================================
  const { classes } = useStyles(props);

  // FORMATTING FUNCTIONS

  const applySign = (val, formatString) => {
    const newVal = Number(val);
    const negativeSign = noNegatives ? '' : '-';

    const sign = Math.sign(newVal);
    let signChar = sign >= 0 ? '' : negativeSign;
    // by default, show sign if different than expected
    if (expectedSignInternal === expectedSignEnum.positive) {
      signChar = newVal < 0 ? '-' : '';
    } else if (expectedSignInternal === expectedSignEnum.negative) {
      signChar = newVal > 0 ? '+' : '';
    }
    // if showSign provided, show based on that
    if (showSign && newVal !== 0) {
      signChar = '';
      if (newVal > 0) {
        signChar = ((showSign === showSignEnum.positive) || (showSign === showSignEnum.both)) ? '+' : '';
      } else if (newVal < 0) {
        signChar = ((showSign === showSignEnum.negative) || (showSign === showSignEnum.both)) ? '-' : '';
      }
    }
    if (newVal === 0) { // no sign
      signChar = '';
    }
    return { sign: signChar, numberString: numeral(Math.abs(newVal)).format(formatString) };
  };

  const renderStaticValue = (val) => {
    const formatString = decimals === 0 ? '0,00' : `0,00.${'0'.repeat(decimals)}`;
    const parts = applySign(val, formatString);
    return removeSpacing ? `${parts.sign}${currencySymbol}${parts.numberString}` : `${parts.sign}${currencySymbol} ${parts.numberString}`;
  };
  const renderEditableValue = (val, withComma = false) => {
    const commaString = withComma ? '0,00' : '00';
    const formatString = decimals === 0 ? commaString : `${commaString}.${'0'.repeat(decimals)}`;
    const parts = applySign(val, formatString);
    return `${parts.sign}${parts.numberString}`;
  };

  // INTERNAL FUNCTIONS
  const handleKeyDown = (e) => {
    const legalChars = '0123456789,';

    if (noNegatives && (e.key === '-')) { // Only allow negative when noNegatives is false
      e.preventDefault();
      return;
    }

    let firstVal;
    let caretOffset;
    let killUpdate;
    let newValue = fieldValue;
    let selection = '';

    switch (e.key) {
      // allow normal stuff
      case 'Backspace':
        if (onChange) preserveCaretPosition(e, -1);
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'ArrowDown':
        break;
      // submit on enter/tab
      case 'Enter':
      case 'Tab':
        if (onSubmit) {
          onSubmit({ target: { value: validateField(fieldValue) } });
        }
        break;
      case '.': // only allow one period
        // don't allow if no decimals
        if (decimals === 0) e.preventDefault();
        // if period already exists, make sure it's in the selection to replace it
        if (fieldValue.indexOf('.') !== -1) { // has a period
          selection = fieldValue.substring(e.target.selectionStart, e.target.selectionEnd);
          if ((selection.indexOf('.') !== -1)) { // in current selection
            preserveCaretPosition(e, 1);
          } else {
            e.preventDefault();
          }
        }
        break;
      case ',':
        // only allow commas if the cursor isn't already next to a comma
        selection = fieldValue.substring(e.target.selectionStart, e.target.selectionEnd);
        if (selection && (selection.indexOf(',') !== -1)) {
          // replacing a comma, let it roll
          break;
        } else if (fieldValue.substring(e.target.selectionStart - 1, e.target.selectionEnd + 1).indexOf(',') !== -1) {
          // next to a comma
          e.preventDefault();
        }
        break;
      case '-':
      case '+':
        caretOffset = 0;
        killUpdate = false;
        firstVal = fieldValue?.[0];
        if (firstVal) {
          selection = fieldValue.substring(e.target.selectionStart, e.target.selectionEnd);
          if (selection && (selection?.length > 0) && // valid selection and
            (((selection.indexOf('-') !== -1) || (selection.indexOf('+') !== -1)) // contains a sign to override
            || (selection?.length === e.target.value?.length)) // or is the entirety of the field
          ) {
            // Happy, let the replace do it's thing
          } else {
            e.preventDefault();
            if (firstVal === '-' || firstVal === '+') { // sign already exists
              if (e.key === firstVal) {
                // same sign - do nothing
                killUpdate = true;
              } else {
                // replace with new sign
                newValue = `${e.key}${fieldValue?.slice(1, fieldValue?.length)}`;
                setFieldValue(newValue);
              }
            } else { // sign not in the string, so add it
              newValue = `${e.key}${fieldValue}`;
              setFieldValue(newValue);
              caretOffset = 1;
            }
            // reset caret position after update
            if (!killUpdate) {
              preserveCaretPosition(e, caretOffset);
              if (onChange) {
                onChange?.({ target: { value: validateField(newValue) } });
              }
            }
          }
        }
        break;
      case 'a':
      case 'c':
      case 'v':
        if (!(e.metaKey || e.ctrlKey)) { // allow copy and paste and select
          e.preventDefault();
        }
        break;
      default:
        if (legalChars.indexOf(e.key) === -1) { // prevent illegal characters
          e.preventDefault();
        } else if ((e.target.selectionStart === 0) && (e.target.selectionEnd === 0)) { // if at the front
          firstVal = fieldValue?.[0];
          if (firstVal && (firstVal === '-' || firstVal === '+')) { // and there is a sign
            e.preventDefault();
            // move new entry behind sign;
            newValue = `${firstVal}${e.key}${fieldValue.slice(1, fieldValue?.length)}`;
            setFieldValue(newValue);
            preserveCaretPosition(e, 2);
            if (onChange) {
              onChange?.({ target: { value: validateField(newValue) } });
            }
          }
        }
        break;
    }
  };
  const handleFocus = (e) => {
    if (e && e.currentTarget) {
      e.currentTarget.select();
    }
    setHasFocus(true);
  };
  const handleChange = (e) => {
    if (e && e.target) {
      if (e.target.value.split('.')?.length - 1 > 1) { // if multiple periods
        // don't save
        preserveCaretPosition(e, -1);
      } else {
        // standard save
        setFieldValue(e.target.value);
        // if controlled, update form with validated/formatted value
        onChange?.({ target: { value: validateField(e.target.value) } });
      }
    }
  };
  const handleBlur = (e) => {
    const func = submitOnBlur ? onSubmit : onBlur;
    if (func) {
      e.target.value = validateField(fieldValue);
      func(e);
    }
    setHasFocus(false);
  };
  const validateField = (val) => {
    const currentString = String(val).replace(/,/g, '');
    let newVal = Number.isNaN(Number(currentString)) ? 0 : Number(currentString);
    if (!(currentString.includes('-') || currentString.includes('+'))) { // if no strict signage, apply sign
      newVal *= expectedSignInternal === expectedSignEnum.negative ? -1 : 1;
    }
    return newVal.toFixed(decimals);
  };
  const handlePaste = (e) => {
    if (e?.clipboardData) {
      e.preventDefault();
      const textData = (e.originalEvent || e)?.clipboardData?.getData?.('Text');
      if (textData) {
        // sanitize data to paste
        window.document.execCommand('insertText', false,
          textData.trim()?.replace(/[^0-9,.(-)+]/g, ''));
      }
    }
  };

  // RENDER ============================================================================

  let colorClassName;
  const currentVal = Number(validateField(fieldValue));
  if (currentVal !== 0 && showColor) {
    if (currentVal > 0 && ((showColor === showColorEnum.positive) || (showColor === showColorEnum.both))) {
      colorClassName = classes.positive;
    } else if (currentVal < 0 && ((showColor === showColorEnum.negative) || (showColor === showColorEnum.both))) {
      colorClassName = classes.negative;
    }
  }

  return (
    <div id={id} sharedcomponentid={'QAmountField'} className={className} style={{ ...wrapperStyles }}>
      {!editable &&
        <Typography
          variant={'body1'}
          {...otherProps}
          style={{ ...styles, ...styleProps }}
        >
          {fieldValue}
        </Typography>}

      {editable &&
        <TextField
          {...otherProps}
          {...textFieldProps}
          onChange={handleChange}
          value={fieldValue}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onPaste={handlePaste}
          autoFocus={autoFocus}
          size={size}
          variant={textFieldVariant}
          margin={margin}
          InputLabelProps={{
            classes: {
              root: classes.noWrap,
            },
          }}
          InputProps={{
            ...InputProps,
            classes: {
              root: colorClassName,
              ...(InputProps?.classes),
            },
            ...(disableUnderline && { disableUnderline: true }),
            className: classes.inputField,
            inputProps: {
              className: classes.inputField,
              maxLength: 20,
            },
            startAdornment: (
              <InputAdornment
                classes={{ root: classes.adornment }}
                disableTypography
                position="start"
                sx={{ color: props.disabled ? 'text.disabled' : 'text.primary' }}
              >
                {currencySymbol}
              </InputAdornment>
            ),
          }}
        />}
    </div>
  );
};

QAmountField.propTypes = propTypes;

QAmountField.defaultProps = {
  expectedSign: expectedSignEnum.negative,
  decimals: 2,
};

export default (QAmountField);


