const styles = (theme) => ({
  input: {},
  root: {},
  cancelIcon: {
    color: theme.palette.removeButton,
  },
  buttonFontSize: {
    fontSize: '20px',
  },
  searchIconButton: {
    margin: 5,
    color: theme.palette.slateGrey,
    cursor: 'pointer',
  },
  searchIcon: {
    margin: 3,
  },
  iconHolder: {
    lineHeight: '5px',
    '&:hover': {
      transform: 'scale(1.1)',
      transition: 'all 0.15s ease-in-out',
    },
    '&:active': {
      transform: 'scale(1)',
      transition: 'all 0.15s ease-in-out',
    },
  },
  smartSearch: {
    width: 32,
    marginRight: 'auto',
    marginLeft: '0px',
    height: 32,
    minHeight: 32,
    padding: 0,
    border: 'none',
    borderWidth: 1,
    borderRadius: 0,
    marginTop: 1,
    marginBottom: 'auto',
    borderColor: theme.palette.koalaGrey,
    fontSize: theme.components.register.fontSize.default,
    color: theme.components.txns.smartSearch,
    '&.open': {
      width: 300,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:focus-within': {
      color: theme.palette.primary.main,
    },
    transition: 'width 0.5s ease',
  },
  smartSearchInput: {
    fontSize: theme.components.register.fontSize.default,
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    padding: '0px 5px 0px 5px',
    height: 'inherit',
    '&::placeholder': {
      color: theme.palette.slateGrey,
      opacity: 1,
      fontWeight: 'normal',
    },
    '&::-ms-clear ': {
      display: 'none',
    },
    '&.hide': {
      display: 'none',
    },
    '&.show': {
      display: 'block',
    },
  },
});

export default styles;
