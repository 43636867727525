
import { isAcme } from 'isAcme';

export const qCards = {
  firstCard: {
    elementId: 'application-header-bar',
    edge: 'right',
    width: 284,
    height: 135,
    title: 'Navigation Bar',
    next: 'qcard2',
    content: `This is how you access the many features and screens of ${isAcme ? 'Simplifi' : 'Quicken'}`,
  },
  qcard2: {
    elementId: 'account-drawer-toggle',
    edge: 'righttop',
    width: 400,
    height: 150,
    nudge: { top: -8, left: 4 },
    title: 'Account List Toggle',
    prev: 'qcard4a',
    next: 'qcard3',
    content: 'The account list shows you all your accounts, and their balances.  Selecting an account in the list will view the transaction list for that account',
  },
  qcard3: {
    elementId: 'txn-register-add-txn-button',
    edge: 'lefttop',
    width: 400,
    height: 150,
    nudge: { top: 2 },
    title: 'Add A New Transaction',
    prev: 'qcard2',
    next: 'qcard4',
    content: 'You can add a manual transaction to the transaction list by selecting the add button',
  },
  qcard4: {
    elementId: 'qmenu_open_txpref',
    edge: 'left',
    width: isAcme ? 300 : 400,
    height: isAcme ? 150 : 175,
    title: 'Settings Menu',
    prev: 'qcard3',
    content: 'You can set many preferences for how you view your transactions, including customizing which columns to view.' +
    `${isAcme ? '' : ' You can also find account specific actions such as Reconcile here'}`,
  },
};


