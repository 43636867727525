import React from 'react';

import LockIcon from '@mui/icons-material/LockRounded';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  const { spacing } = theme;
  return ({
    root: {
      alignItems: 'center',
      borderLeft: '1px solid rgba(151,151,151,0.4)',
      display: 'flex',
    },
    lock: {
      color: theme.palette.grey.level7,
      marginLeft: spacing(1),
    },
    text: {
      color: theme.palette.greyScaleDeprecated[2],
      marginLeft: spacing(1) - 2,
    },
  });
});

const TertiaryTrust = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <LockIcon className={classes.lock} />
      <Typography className={classes.text} color="secondary" variant="body2">
        Secured, encrypted connections.
      </Typography>
      <br />
    </div>
  );
};

export default TertiaryTrust;
