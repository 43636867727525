/* eslint-disable no-lonely-if */
// @flow
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';


import { getLogger, tracker } from 'companion-app-components/utils/core';

import StdDialog from 'components/Dialogs/StdDialog'; // eslint-disable-line import/no-named-as-default-member
import StdDialogWizardContent from 'components/Dialogs/StdDialogWizardContent';

import * as institutionsActions from 'data/institutions/actions';
import { getAggregatorFromChannel, getDefaultChannel, getTrackingIdFromInstitution } from 'data/institutions/utils';
import { removeDialog } from 'data/rootUi/actions';

import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { useFetchOnce } from 'companion-app-components/hooks/utils';

import { getPreferencesV2 } from 'companion-app-components/flux/preferences-v2/preferencesV2Actions';
import * as preferencesV2Selectors from 'companion-app-components/flux/preferences-v2/preferencesV2Selectors';

import { bindPromiseAction } from 'utils/actionHelpers';
import 'utils/interfaces/assert';

import FiIcon, { FiIconSize } from './FiIcon';

import AggActionIntro from './AggActionIntro';
import Authenticator from './Authenticator';
import InstitutionPicker from './InstitutionPicker';
import InstitutionAccountsUpdater from './InstitutionAccountsUpdater';
import NonConnectedAccount from './NonConnectedAccount';
import SendInstitutionRequest from './SendInstitutionRequest';
import type { AccountDiscoveryDialogProps } from './types';
import WidgetAuthenticator from './WidgetAuthenticator';
import IntuitConsent from './IntuitConsent';

import * as actions from './actions';
import * as selectors from './selectors';

const log = getLogger('AccountDiscovery');

type Props = AccountDiscoveryDialogProps & {
  dialogId: string,
};

function getParamsBasedOnMode(mode, aggregator, institutionLogin) {
  switch (mode) {
    case 'ACCOUNT-REDISCOVERY':
      return {
        cpSetupMode: aggregator !== 'FINICITY' && aggregator !== 'PLAID' ? 'DISCOVER_ACCOUNTS_ONLY' : 'DISCOVER_AND_ADD_ACCOUNTS',
        fullAddAccountsDialog: true,
        dialogTitle: 'Reconnect account',
        page: institutionLogin.institutionId ? 'AUTHENTICATOR' : 'SEARCH',
      };
    case 'ADD-FI-MANUAL':
      return {
        cpSetupMode: 'DISCOVER_ACCOUNTS_ONLY',
        fullAddAccountsDialog: true,
        dialogTitle: 'Add manual account',
        page: 'ADD_NON_CONNECTED_ACCOUNT',
      };
    case 'MIGRATE-INSTITUTION-LOGIN':
      return {
        cpSetupMode: 'DISCOVER_AND_ADD_ACCOUNTS',  // TODO: Is this the correct mode?
        fullAddAccountsDialog: true,
        dialogTitle: 'Re-Authenticate',
        page: 'AUTHENTICATOR',
      };
    case 'PROVIDE-CREDENTIALS':   // TODO: This is going away with EWC-Live
      return {
        cpSetupMode: 'PROVIDE_CREDENTIALS',
        fullAddAccountsDialog: false,
        dialogTitle: 'Provide credentials',
        page: 'AUTHENTICATOR',
      };
    case 'PROVIDE-MFA':
      return {
        cpSetupMode: 'PROVIDE-MFA',
        fullAddAccountsDialog: false,
        dialogTitle: 'Multi-factor authentication',
        page: 'AUTHENTICATOR',
      };
    case 'RE-AUTHENTICATE':
      return {
        cpSetupMode: 'UPDATE_CREDENTIALS',
        fullAddAccountsDialog: true,
        dialogTitle: 'Re-Authenticate',
        page: 'AUTHENTICATOR',
      };
    case 'UPDATE-CREDENTIALS':
      return {
        cpSetupMode: 'UPDATE_CREDENTIALS',
        fullAddAccountsDialog: false,
        dialogTitle: 'Update credentials',
        page: 'AUTHENTICATOR',
      };
    case 'ADD-FI':
    case 'UPDATE-FI':
    case 'GSF':
    default:
      return {
        cpSetupMode: 'DISCOVER_ACCOUNTS_ONLY',
        fullAddAccountsDialog: true,
        dialogTitle: 'Add your account',
        page: 'SEARCH',
      };
  }
}

function getDefaultAggregator(defaultAggregatorOverride, defaultAggregator) {
  if (defaultAggregatorOverride && defaultAggregatorOverride !== 'USE_PREFERENCE_SERVICE') {
    return defaultAggregatorOverride;
  }
  return defaultAggregator ? defaultAggregator.toUpperCase() : 'FDS';
}

function getInitialState(addFiDefaultProvider, mode, institutionLogin, mfaChallenge, fiAggregator) {
  let aggregator = null;
  if (mode === 'ADD-FI' || mode === 'GSF' || (mode === 'UPDATE-FI' && !institutionLogin?.institutionId)) {
    if (mode !== 'UPDATE-FI' && institutionLogin) {
      log.warn('The institutionLogin is ignored when in ADD_FI mode');
      institutionLogin = null;  // eslint-disable-line no-param-reassign
    }
    aggregator = getDefaultAggregator(addFiDefaultProvider, fiAggregator);
  } else if (!institutionLogin) {
    assert(true, 'The institutionLogin is required when not in ADD_FI mode');
  } else {
    aggregator = (mode === 'MIGRATE-INSTITUTION-LOGIN' && institutionLogin.fiMigrationInfo)
      ? getAggregatorFromChannel(institutionLogin.fiMigrationInfo.toChannel || institutionLogin.channel)
      : getAggregatorFromChannel(institutionLogin.channel);
  }
  if (mode !== 'PROVIDE-MFA' && mfaChallenge != null) {
    log.warn('The mfaChallenge is ignored when not in PROVIDE-MFA mode');
    mfaChallenge = null;  // eslint-disable-line no-param-reassign
  }

  const paramsBasedOnMode = getParamsBasedOnMode(mode, aggregator, institutionLogin);
  return {
    aggregator,
    mode,
    cpSetupMode: paramsBasedOnMode.cpSetupMode,
    fullAddAccountsDialog: paramsBasedOnMode.fullAddAccountsDialog,
    institutionLogin,
    page: paramsBasedOnMode.page,
    dialogTitle: paramsBasedOnMode.dialogTitle,
    mfaChallenge,
  };
}

function getTrackingAggregatorProperty(aggregator) {
  if (aggregator === 'FDP' || aggregator === 'FDS') {
    return `INTUIT_${aggregator}`;
  }
  return aggregator;
}

const AccountDiscoveryWizard = (props: Props) => {
  const { afterCloseAction, aggAction, onClose, error, dialogId, initiator = 'unknown', mode = 'ADD-FI' } = props;
  const dispatch = useDispatch();

  const fiAggregator = useSelector((state) => preferencesV2Selectors.getPreferenceValue(state, 'fi-aggregator'));
  const addFiDefaultProvider = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'addFiDefaultProvider'));

  const initialState = useMemo(
    () => getInitialState(addFiDefaultProvider, mode, props?.institutionLogin, props.mfaChallenge, fiAggregator),
    [addFiDefaultProvider, mode, props?.institutionLogin, props.mfaChallenge, fiAggregator],
  );

  const isAuthenticationDone = useSelector((state) => selectors.getIsAuthenticationDone(state, { scope: dialogId }));
  const isAccountUpsertingDone = useSelector((state) => selectors.getIsAccountUpsertingDone(state, { scope: dialogId }));

  const accountDiscoveryData = useSelector((state) => selectors.getAccountDiscoveryData(state, { scope: dialogId }));
  const institution = useSelector((state) => selectors.getInstitution(state, { scope: dialogId }));

  const [initialPage] = useState(initialState.page);
  // eslint-disable-next-line no-unused-vars
  const [, setAddedAccountClientIds] = useState(null);
  const [aggregator, setAggregator] = useState(initialState.aggregator);
  const [cpSetupMode, setCpSetupMode] = useState(initialState.cpSetupMode);
  const [dialogTitle, setDialogTitle] = useState(initialState.dialogTitle);
  const [fullAddAccountsDialog, setFullAddAccountsDialog] = useState(initialState.fullAddAccountsDialog);
  const [isClosing, setIsClosing] = useState(false);
  const [instiutionRequestStartingValue, setInstitutionRequestStartingValue] = useState(initialState.aggregator);
  const [page, setPage] = useState(initialState.page);

  const pageRef = useRef();
  const theme = useTheme();

  const addingNewFi = useMemo(() => mode === 'ADD-FI' || mode === 'GSF', [mode]);
  const fiIconSize = useMemo(() => FiIconSize(institution, 'md'), [institution]);

  const trackingProperties = useMemo(() => ({
    ...(aggAction && { type: aggAction.type, id: aggAction.id }),
    aggregator: getTrackingAggregatorProperty(aggregator),
    initiator,
    ...(institution && { institution_id: getTrackingIdFromInstitution(institution), institution_name: institution.name }),
    mode,
  }), [aggAction, aggregator, initiator, institution, mode]);

  const manualTrackingProperties = useMemo(() => ({
    ...trackingProperties,
    institution_id: '0',
    institution_name: 'manual',
  }), [trackingProperties]);

  // initialize state including store state in redux
  const initializeOrReinitializeState = useCallback(async (onBack = false) => {

    // note: we need to setup/re-initialize our store before setting any of our local state
    dispatch(actions.accountDiscoverySetup(
      {
        institutionLogin: mode !== 'MIGRATE-INSTITUTION-LOGIN' ? initialState?.institutionLogin : undefined,
        mfaChallenge: initialState.mfaChallenge,

        institutionLoginIdBeingMigrated: mode === 'MIGRATE-INSTITUTION-LOGIN' ? initialState?.institutionLogin.id : undefined,
      },
      { scope: dialogId },
    ));

    setCpSetupMode(initialState.cpSetupMode);
    setDialogTitle(!aggAction ? initialState.dialogTitle : aggAction.title);
    setFullAddAccountsDialog(initialState.fullAddAccountsDialog);
    setPage(!aggAction ? initialState.page : 'AGG_ACTION_INTRO');
    setAggregator(initialState.aggregator);

    if (!onBack) {
      let institutionId;
      let channel;
      if (mode !== 'MIGRATE-INSTITUTION-LOGIN') {
        institutionId = initialState?.institutionLogin?.institutionId;
        channel = initialState?.institutionLogin?.channel;
      } else if (initialState?.institutionLogin.fiMigrationInfo) {
        institutionId = initialState?.institutionLogin.fiMigrationInfo.toInstitutionId;
        channel = initialState?.institutionLogin.fiMigrationInfo.toChannel;
      } else {
        institutionId = initialState?.institutionLogin.institutionId;
        const fetchInstitution = bindPromiseAction(dispatch, institutionsActions.getInstitution);
        const response = await fetchInstitution(
          { id: institutionId },
          { axiosConfig: { params: { aggregator: initialState.aggregator } } },
        );
        channel = getDefaultChannel(response.resource);
      }
      if (institutionId) {
        const fetchInstitution = bindPromiseAction(dispatch, institutionsActions.getInstitution);
        const response = await fetchInstitution(
          { id: institutionId },
          { axiosConfig: { params: { aggregator: initialState.aggregator } } },
        );
        dispatch(actions.institutionSelected({
          institution: response.resource,
          channel,
        }, { scope: dialogId }));
      }
    }
    tracker.track(addingNewFi ? tracker.events.addFIStart : tracker.events.fixFIStart,
      {
        ...(aggAction && { type: aggAction.type, id: aggAction.id }),
        aggregator: getTrackingAggregatorProperty(initialState.aggregator),
        initiator,
        mode,
      });
  }, [addingNewFi, aggAction, dialogId, dispatch, initialState, initiator, mode]);

  useFetchOnce(getPreferencesV2, preferencesV2Selectors, true);

  // initialize or re-initialize state (including redux) upon component mount (or when props change, though shouldn't happen)
  useEffect(() => {
    initializeOrReinitializeState();
    return () => dispatch(actions.accountDiscoveryCleanup(null, { scope: dialogId }));
  }, [dialogId, dispatch, initializeOrReinitializeState]);

  const handleAddNonConnectedAccount = () => {
    setDialogTitle('Add manual account');
    setPage('ADD_NON_CONNECTED_ACCOUNT');
    tracker.track(addingNewFi ? tracker.events.addFISelect : tracker.events.fixFISelect,
      {
        ...(aggAction && { type: aggAction.type, id: aggAction.id }),
        ...manualTrackingProperties,
      });
  };

  const handleAggActionSubmit = () => {
    setPage(initialPage);
  };

  const handleOpenInstitutionRequest = (value) => {
    setDialogTitle('Send request');
    setPage('SEND_INSTITUTION_REQUEST');
    setInstitutionRequestStartingValue(value);
    tracker.track(tracker.events.requestFISelected(trackingProperties));
  };

  const handleSendInstitutionRequest = ({ institutionName, loginURL }) => {
    tracker.track(tracker.events.requestFISubmit, {
      ...trackingProperties,
      institution_id: '0',
      institution_name: institutionName,
      institution_url: loginURL,
    });
  };

  const handleClose = (e, reason) => {
    if (pageRef.current?.handleClose) {
      pageRef.current.handleClose(reason);
    }

    if (reason !== 'SUBMIT_SUCCESS') {
      tracker.track(addingNewFi ? tracker.events.addFICancel : tracker.events.fixFICancel,
        {
          ...trackingProperties,
          step: page?.toLowerCase(),
          ...(error && {
            error_code: error.error_code,
            agg_status_code: error.agg_status_code,
            agg_status_detail: error.agg_status_detail,
          }),
        });
    }
    setIsClosing(true);

    if (reason === 'SUBMIT_SUCCESS' && afterCloseAction) {
      dispatch(afterCloseAction);
    }
    onClose?.();
    setTimeout(() => dispatch(removeDialog(dialogId)), 2000);
  };

  const handleCancel = () => {
    if ((mode === 'ADD-FI' || mode === 'UPDATE-FI' || mode === 'GSF') && (page !== 'SEARCH' && page !== 'BILL_SCOUT')) {
      tracker.track(addingNewFi ? tracker.events.addFICancel : tracker.events.fixFICancel,
        {
          ...trackingProperties,
          step: page?.toLowerCase(),
          reinitializing: true,
          ...(error && {
            error_code: error.status,
            agg_status_code: error.statusCode,
            // TODO: agg_status_detail: error.statusCodeDetail,
          }),
        });
      initializeOrReinitializeState(true);
    } else {
      handleClose(null, 'CANCELLED');
    }
  };

  const handleBackOrCancel = () => {
    if (pageRef.current?.handleBack) {
      pageRef.current.handleBack();
    } else {
      handleCancel();
    }
  };

  const handlePageClose = () => {
    handleClose(null, 'CANCELLED');
  };

  const handleReset = () => initializeOrReinitializeState();

  const switchToPage = (logText, logData, title, pageName) => {
    log.debug(logText, logData);
    setDialogTitle(title);
    setPage(pageName);
  };

  switch (page) {
    case 'SEARCH':
      if (institution) {
        switchToPage('AccountDiscovery switching to INTUIT_CONSENT page', institution, 'Add your account', 'INTUIT_CONSENT');
      }
      break;
    case 'AUTHENTICATOR':
      if (isAuthenticationDone) {
        if (accountDiscoveryData) {
          switchToPage('AccountDiscovery switching to ACCOUNTS page', accountDiscoveryData, 'Add your accounts', 'ACCOUNTS');
          // We track authenticator completed here instead of in the authenticator cause completion isn't done until
          // QCS finishes and returns accountDiscoveryData (in which case - authenticator may never get control again).
          //
          tracker.track(addingNewFi ? tracker.events.addFIAuthenticated : tracker.events.fixFIAuthenticated,
            {
              ...trackingProperties,
              updating: Boolean(accountDiscoveryData.activeAccounts.length || accountDiscoveryData.deadAccounts.length),
            });
        } else if (!isClosing) {
          handleClose(null, 'SUBMIT_SUCCESS');
        }
      }
      break;
    case 'ACCOUNTS':
      if (isAccountUpsertingDone && !isClosing) {
        handleClose(null, 'SUBMIT_SUCCESS');
      }
      break;
    case 'ADD_NON_CONNECTED_ACCOUNT':
      if (isAccountUpsertingDone) {
        if (!isClosing) {
          handleClose(null, 'SUBMIT_SUCCESS');
        }
      }
      break;
    default:
      break;
  }

  // if we are in the process of closing, do not render anything
  if (isClosing) {
    return null;
  }

  const renderAggActionIntro = () => (
    <AggActionIntro
      aggAction={aggAction}
      fiBrandingIndent={fiIconSize.width + (2 * parseInt(theme.spacing(3), 10))}
      onSubmit={handleAggActionSubmit}
    />
  );

  const renderSearch = () => (
    <InstitutionPicker
      aggregator={aggregator}
      onAddUnconnectedAccount={handleAddNonConnectedAccount}
      onOpenInstitutionRequest={handleOpenInstitutionRequest}
      scope={dialogId}
      submitButtonLabel="Continue"
      trackingProperties={trackingProperties}
      updateMode={!addingNewFi}
    />
  );

  const renderAuthenticator = () => {
    if (aggregator === 'FINICITY' || aggregator === 'PLAID') {
      if (institution) {
        return (
          <WidgetAuthenticator
            aggregator={aggregator}
            fiBrandingIndent={fiIconSize.width + (2 * parseInt(theme.spacing(3), 10))}
            institution={institution}
            onBack={handleCancel}
            ref={pageRef}
            scope={dialogId}
            trackingProperties={trackingProperties}
            updateMode={!addingNewFi}
          />
        );
      }
    } else if (institution) {
      return (
        <Authenticator
          aggregator={aggregator}
          cpSetupMode={cpSetupMode}
          fullAddAccountsDialog={fullAddAccountsDialog}
          handleClose={handlePageClose}
          hideInitialPartnerAuthIntro={!!aggAction}
          institution={institution}
          onCancel={handleBackOrCancel}
          provideForRefreshAccounts={mode === 'PROVIDE-CREDENTIALS' || mode === 'PROVIDE-MFA'}
          ref={pageRef}
          scope={dialogId}
          trackingProperties={trackingProperties}
          updateMode={!addingNewFi}
        />
      );
    }
    return (
      <Box display="flex" minHeight="200px">
        <CircularProgress size={100} style={{ margin: 'auto' }} />
      </Box>
    );
  };

  const renderAccounts = () => {
    if (institution && accountDiscoveryData) {
      return (
        <InstitutionAccountsUpdater
          cancelButtonLabel="Cancel"
          disconnect={mode === 'MIGRATE-INSTITUTION-LOGIN' ? true : undefined}
          onCancel={handleBackOrCancel}
          onClose={handlePageClose}
          onReset={handleReset}
          onSubmit={setAddedAccountClientIds}
          addIgnoredAccounts={aggregator === 'FINICITY' || aggregator === 'PLAID'}
          scope={dialogId}
          submitButtonLabel="Submit"
          trackingProperties={trackingProperties}
          updateMode={!addingNewFi}
        />
      );
    }
    return (
      <Box display="flex" minHeight="200px">
        <CircularProgress size={100} style={{ margin: 'auto' }} />
      </Box>
    );
  };



  const renderFiBranding = () => {
    const AggActionIcon = aggAction && (aggAction.type === 'ERROR' ? ErrorOutlineIcon : OfflineBoltOutlinedIcon);
    return (
      <Box display="flex">
        <Box mt={3} mx={3}>
          <FiIcon aggregator={aggregator} institution={institution} size="md" />
          {AggActionIcon &&
            <AggActionIcon
              sx={{
                backgroundColor: 'white',
                border: 'solid 1px lightgrey',
                borderRadius: '50%',
                color: aggAction.type === 'ERROR' ? 'error.main' : 'color5.opacity100',
                display: 'flex',
                ml: 'auto',
                position: 'relative',
                right: '-8px',
                top: '-16px',
                zIndex: '10',
              }}
            />}
        </Box>
        <Box display="flex" flexDirection="column" mt={3}>
          <Typography variant={'subtitle1'}>{institution?.name}</Typography>
          {fullAddAccountsDialog &&
            <a href={institution?.url || ''} style={{ textDecoration: 'none' }} target="_blank">
              <Typography variant="body2" sx={{ color: () => theme.palette.greyScaleDeprecated[2] }}>
                {institution?.url}
              </Typography>
            </a>}
        </Box>
      </Box>
    );
  };

  const showFiBranding = page !== 'SEARCH' && page !== 'ADD_NON_CONNECTED_ACCOUNT' && page !== 'SEND_INSTITUTION_REQUEST';

  return (
    <StdDialog
      open={!isClosing}
      dialogId={dialogId}
      disableBackdropClick={fullAddAccountsDialog}
      disableEnforceFocus
      disableEscapeKeyDown={fullAddAccountsDialog}
      fullWidth
      hideBackdrop={mode === 'GSF'}
      maxWidth={fullAddAccountsDialog ? 'md' : 'xs'}
      onBack={handleBackOrCancel}
      onClose={handleClose}
      showCloseButton
      showBackButton={fullAddAccountsDialog && page !== 'AGG_ACTION_INTRO' && page !== 'SEARCH' && mode !== 'ADD-FI-MANUAL'}
      title={dialogTitle}
    >
      <StdDialogWizardContent minHeight={fullAddAccountsDialog ? '500px' : '400px'}>
        {showFiBranding && renderFiBranding()}
        {page === 'AGG_ACTION_INTRO' && renderAggActionIntro()}
        {page === 'SEARCH' && renderSearch()}
        {page === 'AUTHENTICATOR' && renderAuthenticator()}
        {page === 'ACCOUNTS' && renderAccounts()}
        {page === 'ADD_NON_CONNECTED_ACCOUNT' &&
          <NonConnectedAccount
            cancelButtonLabel="Back"
            onCancel={handleBackOrCancel}
            scope={dialogId}
            submitButtonLabel="Continue"
            trackingProperties={manualTrackingProperties}
          />}
        {page === 'SEND_INSTITUTION_REQUEST' &&
          <SendInstitutionRequest
            handleReset={handleReset}
            startingValue={instiutionRequestStartingValue}
            onSendInstitutionRequest={handleSendInstitutionRequest}
          />}
        {page === 'INTUIT_CONSENT' && <IntuitConsent 
          onContinue={() => switchToPage('AccountDiscovery switching to AUTHENTICATOR page', institution, 'Add your account', 'AUTHENTICATOR')} 
        />}
      </StdDialogWizardContent>
    </StdDialog>
  );
};

export default AccountDiscoveryWizard;
