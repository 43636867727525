import React, { FC, useState, useEffect, useCallback, useMemo, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';

import RootState from 'companion-app-components/utils/redux-store/rootState';
import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { getPreferences } from 'companion-app-components/flux/preferences/actions';
import { getSharedPreferencesByPath } from 'companion-app-components/flux/preferences/selectors';

import ErrorBoundary from 'components/ErrorBoundary';
import TransactionRegister from 'components/TransactionRegister';

import { getCoords, regFieldsOrderDefault } from './utils';

const uniqueName = uuidv4();

interface EmbeddedRegisterProps {
  name: string;
  accountIds: string[]; 
  overrideTxns?: transactionsTypes.CashFlowTransaction[]; 
  regFieldsOrder?: ImmutableList<string>;
  fitToWindow?: boolean;
  hideFooter?: boolean;
  sizeToContent?: boolean;
  reminderSetting?: string;
  zeroStateMessage?: string;
  zeroStateStyle?: CSSProperties;
  calendar?: boolean;
  webFirstCatUncategorize?: boolean;
  scrollToId?: string;
  onTxnUpdate?: () => void;
  sortBy?: string;
  sortOrder?: string;
  elevation?: number;
  style?: CSSProperties;
  className?: string;
  getInsideRef?: () => void;
  hideDividers?: boolean;
  hideSearch?: boolean;
  hideColumnsHeader?: boolean;
  bottomMargin: number;
  doubleAmounts?: boolean;
  editDownloaded?: boolean;
  deleteDownloaded?: boolean;
  splitsInDialog?: boolean;
  autoSave?: boolean;
  registerComfort?: string;
  wrapText?: boolean;
  longCats?: boolean;
  showCurrencySymbol?: boolean;
  showAccountColors?: boolean;
  currency?: string;
  addNewTransactionRef?: () => void;
  onSortChange?: (newSortBy: string, newSortOrder: string) => void;
}

const EmbeddedRegister: FC<EmbeddedRegisterProps> = ({
  name,
  accountIds,
  overrideTxns,
  regFieldsOrder,
  fitToWindow,
  hideFooter = true,
  sizeToContent,
  zeroStateMessage,
  zeroStateStyle = {},
  calendar,
  scrollToId,
  onTxnUpdate,
  elevation,
  style,
  className,
  getInsideRef,
  onSortChange,
  hideDividers = true,
  hideSearch = true,
  hideColumnsHeader,
  bottomMargin,
  doubleAmounts,
  editDownloaded,
  deleteDownloaded,
  splitsInDialog,
  autoSave,
  wrapText,
  longCats,
  showCurrencySymbol,
  showAccountColors,
  addNewTransactionRef,
  webFirstCatUncategorize,
  currency = 'USD',
  reminderSetting = '999',
  registerComfort = 'normal',
  sortBy: initialSortBy = 'date',
  sortOrder: initialSortOrder = 'descending',
}) => {

  const [regWinHeight, setRegWinHeight] = useState(0);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const datasetPreferences = useSelector((state: RootState) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] }));

  useEffect(() => {
    if (!datasetPreferences) {
      dispatch(getPreferences({ section: 'shared', group: 'dataset' }));
    }
  }, [datasetPreferences, dispatch]);

  const calcRegisterHeight = useCallback(() => {
    const node = document.getElementById(name);
    if (node) {
      const { top } = getCoords(node);
      return window.innerHeight - top - bottomMargin;
    }
    return window.innerHeight;
  }, [name, bottomMargin]);

  const updateDimensions = useCallback((force = false) => {
    if (force || fitToWindow) {
      setRegWinHeight(calcRegisterHeight());
    }
  }, [fitToWindow, calcRegisterHeight]);

  useEffect(() => {
    const handleResize = () => updateDimensions();
    window.addEventListener('resize', handleResize);
    setTimeout(updateDimensions, 100);

    return () => window.removeEventListener('resize', handleResize);
  }, [updateDimensions]);

  useEffect(() => {
    if (getInsideRef) {
      getInsideRef();
    }
  }, [getInsideRef]);

  const cardStyleCached = useMemo(() => memoizeOne((obj) => obj, isEqual), []);

  const regRef = () => {
    if (fitToWindow) {
      setTimeout(() => {
        updateDimensions();
      }, 100);
    }
  };

  const navigateTo = useCallback((accountId, txnId) => {
    navigate(`/transactions?displayNode=${accountId}&txnId=${txnId}`, { replace: true });
  }, [navigate]);

  // eslint-disable-next-line consistent-return
  const handleSortChange = useCallback((newSortBy, newSortOrder) => {
    if (onSortChange) {
      return onSortChange(newSortBy, newSortOrder);
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  }, [onSortChange]);

  const { transactionRegister: regPrefs } = datasetPreferences;

  return (
    <ErrorBoundary>
      <Card
        id={name || uniqueName}
        elevation={elevation}
        style={cardStyleCached({
          height: fitToWindow ? `${regWinHeight || calcRegisterHeight()}px` : 'inherit',
          ...style,
        })}
        className={className}
      >
        <TransactionRegister
          wrapperId={name || uniqueName}
          accountIds={accountIds}
          overrideTxns={overrideTxns}
          doubleAmounts={doubleAmounts === undefined ? regPrefs.doubleAmounts : doubleAmounts}
          editDownloaded={editDownloaded === undefined ? regPrefs.editDownloaded : editDownloaded}
          deleteDownloaded={deleteDownloaded === undefined ? regPrefs.deleteDownloaded : deleteDownloaded}
          splitsInDialog={splitsInDialog === undefined ? regPrefs.splitsInDialog : splitsInDialog}
          autoSave={autoSave === undefined ? regPrefs.autoSave : autoSave}
          registerComfort={registerComfort === undefined ? regPrefs.registerComfort : registerComfort}
          wrapText={wrapText === undefined ? regPrefs.wrapText : wrapText}
          autoSearch={false}
          longCats={longCats === undefined ? regPrefs.longCats : longCats}
          continuousCreate={false}
          showCurrencySymbol={showCurrencySymbol === undefined ? regPrefs.showCurrencySymbol : showCurrencySymbol}
          showAccountColors={showAccountColors === undefined ? regPrefs.showAccountColors : showAccountColors}
          currency={currency || 'USD'}
          getRef={regRef}
          navFn={navigateTo}
          showControls
          hideDividers={hideDividers}
          hideSearch={hideSearch}
          hideColumnsHeader={hideColumnsHeader}
          noNew
          regFieldsOrder={regFieldsOrder || regFieldsOrderDefault}
          hideFooter={hideFooter}
          sizeToContent={sizeToContent}
          zeroStateMessage={zeroStateMessage}
          zeroStateStyle={zeroStateStyle}
          reminderSetting={reminderSetting}
          calendar={calendar}
          sortOrder={sortOrder}
          sortBy={sortBy}
          onSortChange={handleSortChange}
          addNewTransactionRef={addNewTransactionRef}
          webFirstCatUncategorize={webFirstCatUncategorize}
          scrollToId={scrollToId}
          onTxnUpdate={onTxnUpdate}
        />
      </Card>
    </ErrorBoundary>
  );
};

export default EmbeddedRegister;
