// @flow
import { createAction } from 'utils/actionHelpers';

export const getInvestmentAccounts = createAction('GET_INVESTMENT_ACCOUNTS');
export const getInvestmentAccountsSuccess = createAction('GET_INVESTMENT_ACCOUNTS_SUCCESS');
export const getInvestmentAccountsFailure = createAction('GET_INVESTMENT_ACCOUNTS_FAILURE');

export const createInvestmentAccount = createAction('CREATE_INVESTMENT_ACCOUNT');
export const createInvestmentAccountSuccess = createAction('CREATE_INVESTMENT_ACCOUNT_SUCCESS');
export const createInvestmentAccountFailure = createAction('CREATE_INVESTMENT_ACCOUNT_FAILURE');

export const updateInvestmentAccount = createAction('UPDATE_INVESTMENT_ACCOUNT');
export const updateInvestmentAccountSuccess = createAction('UPDATE_INVESTMENT_ACCOUNT_SUCCESS');
export const updateInvestmentAccountFailure = createAction('UPDATE_INVESTMENT_ACCOUNT_FAILURE');

export const batchInvestmentAccounts = createAction('BATCH_INVESTMENT_ACCOUNTS');
export const batchInvestmentAccountsSuccess = createAction('BATCH_INVESTMENT_ACCOUNTS_SUCCESS');
export const batchInvestmentAccountsFailure = createAction('BATCH_INVESTMENT_ACCOUNTS_FAILURE');

export const deleteInvestmentAccount = createAction('DELETE_INVESTMENT_ACCOUNT');
export const deleteInvestmentAccountSuccess = createAction('DELETE_INVESTMENT_ACCOUNT_SUCCESS');
export const deleteInvestmentAccountFailure = createAction('DELETE_INVESTMENT_ACCOUNT_FAILURE');
