// @flow
import { Record, Map as ImmutableMap } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

// ================================================================================================
// Payee List STore
// ================================================================================================

type PayeeListProps = {
  payeesByAccountId: ImmutableMap<string, any>,
  isLoading: boolean,
};
export type PayeeList = RecordOf<PayeeListProps>;

export const mkPayeeListStore: RecordFactory<PayeeListProps> =
  Record({
    payeesByAccountId: ImmutableMap(),
    isLoading: false,
  });

type PayeeProps = {
  id: string,
  name: string,
  amountString: string,
  catString: string,
  tagString: string,
  txn: ?Map,
  count: number,
};

export type Payee = RecordOf<Payee>;
export const mkPayee: RecordFactory<PayeeProps> =
  Record({
    id: null,
    name: null,
    amountString: null,
    catString: null,
    tagString: null,
    txn: null,
    count: 0,
  });

