import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { getLogger, tracker } from 'companion-app-components/utils/core';
import { scheduledTransactionsActions, scheduledTransactionsTypes, scheduledTransactionsSelectors } from 'companion-app-components/flux/scheduled-transactions';
import { chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';
import { getIsLoading } from 'companion-app-components/flux/biller-accounts/billerAccountsSelectors';

// DATA
import store from 'store';

// CUSTOM COMPONENTS
import QButton from 'components/QButton';
import ZeroStateView from 'components/ZeroStateView';
import zeroStateIcon from 'assets/zero-state-images/safebox.svg';
import bankIcon from 'assets/nav-menu/accounts.svg';
import { autoAdjustAmountEnum } from 'components/BillPresentment/BillAmountField';

import AccountRow from './accountRow';
import EditPage from './editPage';

const log = getLogger('BillPresentment/AccountsPage');

const useStyles = makeStyles()((theme) => ({
  // root spacing
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 200,
  },
  header: {
    display: 'flex',
    height: 80,
    width: '100%',
    alignItems: 'center',
    marginBottom: 16,
  },
  brandingIcon: {
    height: 66,
    width: 104,
    border: `1px solid ${theme.palette.greyScaleDeprecated[4]}`,
    borderRadius: 8,
    backgroundColor: theme.palette.greyScaleDeprecated[6],
    marginRight: 32,
  },

  review: {
    paddingRight: 32,
    marginBottom: 8,
    flexShrink: 0,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 412,
    paddingBottom: 12,
    overflow: 'auto',
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: 59,
      left: 0,
      height: 12,
      width: '100%',
      backgroundImage: `linear-gradient(${theme.applyOpacityToHex(theme.palette.greyScaleDeprecated[7], 0)}, ${theme.palette.greyScaleDeprecated[7]})`,
    },
  },
  actions: {
    height: 48,
    paddingBottom: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  showSelect: {
    height: 59,
    overflow: 'hidden',
  },
  hideSelect: {
    height: 0,
    overflow: 'hidden',
    transition: 'height 0.6s ease',
  },

  hideReview: {
    height: 0,
    overflow: 'hidden',
  },
  showReview: {
    height: 79,
    overflow: 'hidden',
    transition: 'height 0.6s ease',
  },
  spinner: {
    alignSelf: 'center',
    margin: 36,
  },

  noShrink: {
    flexShrink: 0,
  },
  selectedBox: {
    background: theme.palette.color7.opacity30,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 8,
    marginBottom: 16,
  },
  bold: {
    fontWeight: 500,
    color: theme.palette.greyScaleDeprecated[0],
  },
}));

const mixPanelAmount = (series) => {
  switch (series?.transaction?.autoAdjustAmount) {
    case autoAdjustAmountEnum.BALANCE:
      return 'statement';
    case autoAdjustAmountEnum.MINIMUM:
      return 'minimum';
    default:
      return 'custom';
  }
};


// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
// ### - - -                  Main Component                     - - - ###
// ### - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ###
function AccountsPage(props) {

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { accounts, biller, series, complete, setComplete, onRestart, editAccount, setEditAccount, accountMap, updateAccountMap, mixpanelProps } = props;

  const scheduledTxns = useSelector(scheduledTransactionsSelectors.getUnconnectedBills);
  const loading = useSelector(getIsLoading);

  const [stagedAccount, setStagedAccount] = useState(null); // radio - current account row selected

  const excludedModels = useMemo(() => accountMap.map((model) => model?.id).toSet(), [accountMap]);
  const modelState = useMemo(() =>
    scheduledTxns.filter((model) => !excludedModels.has(model.id)), [scheduledTxns, excludedModels]);

  const updateMapAndCheckEdit = (id, modelToMap) => {
    updateAccountMap(id, modelToMap);
    if (modelToMap) {
      const modelTxn = modelToMap.transaction;
      const state = store.getState();
      const isCredit = series.transaction && chartOfAccountsSelectors.isCreditBill(state, modelTxn?.accountId, modelTxn?.coa);
      if (isCredit) {
        setEditAccount(accounts.get(id));
      }
    }
  };

  const autoFinish = (model) => {
    if (accounts?.size === 1 && !complete) {
      setComplete(true);
      const newModel = scheduledTransactionsTypes.mkScheduledTransaction({
        autoAdjustAmount: autoAdjustAmountEnum.BALANCE,
        autoAdjustDueOn: true,
        ...(model?.toJS ? model?.toJS() : model),
        transaction: model.transaction,
        billPresentmentAccountId: accounts.first().id,
        providerBillerId: biller?.id,
      });
      dispatch(scheduledTransactionsActions.updateScheduledTransaction(newModel));

      tracker.track(tracker.events.addBillerComplete, {
        ...mixpanelProps,
        bills_found: 1,
        bills_linked: 1,
        amount_selected: mixPanelAmount(model),
        date_selected: model?.transaction?.autoAdjustDueOn ? 'due date' : 'pay date',
        model_category: chartOfAccountsSelectors.getCoaStringSelector(undefined, model.transaction?.coa),
      });
    }
  };

  const handleContinue = () => {
    if (accounts?.size) {
      if (!(accountMap?.size) && stagedAccount) {
        const modelTxn = series.transaction;
        const state = store.getState();
        const isCredit = series.transaction && chartOfAccountsSelectors.isCreditBill(state, modelTxn?.accountId, modelTxn?.coa);
        if (isCredit) {
          setEditAccount(stagedAccount);
        } else {
          updateAccountMap(stagedAccount.id, series);
        }
      } else {
        accountMap.forEach((model, accountId) => {
          if (model?.id) {
            log.log('update model: ', model?.id, ' with accountId: ', accountId);

            const newModel = scheduledTransactionsTypes.mkScheduledTransaction({
              autoAdjustAmount: autoAdjustAmountEnum.BALANCE,
              autoAdjustDueOn: true,
              ...(model.toJS ? model.toJS() : model),
              transaction: model.transaction,
              billPresentmentAccountId: accountId,
              providerBillerId: biller?.id,
            });
            dispatch(scheduledTransactionsActions.updateScheduledTransaction(newModel));
          }
        });
        setComplete(true);

        tracker.track(tracker.events.addBillerComplete, {
          ...mixpanelProps,
          bills_found: accounts.size,
          bills_linked: accountMap.size,
        });
      }
    } else {
      onRestart?.();
    }
  };

  const isMapped = (account) => accountMap.has(account.id);

  let buttonText = 'CONTINUE';
  if (!accounts?.size) {
    buttonText = 'RESTART';
  } else if (accountMap?.size) {
    buttonText = 'CONNECT';
  }
  return (
    <div className={classes.root}>
      {loading ?
        <CircularProgress size={40} className={classes.spinner} />
        :
        <>
          {Boolean(editAccount) &&
            <EditPage
              account={editAccount}
              series={accountMap?.size ? accountMap.get(editAccount.id) : series}
              updateAccountMap={updateAccountMap}
              setEditAccount={setEditAccount}
              autoFinish={autoFinish}
            />}

          {!editAccount && (accounts?.size ?
            <>
              <div className={classes.header}>
                <img
                  alt="Bank Icon"
                  className={classes.brandingIcon}
                  src={biller?.logo || bankIcon}
                />

                <Typography variant="h5">
                  {biller?.name}
                </Typography>
              </div>

              {Boolean(accountMap?.size) &&
              <>
                <Typography variant="body1" className={classes.review}>
                  {accountMap?.size > 1 ?
                    'Woohoo! The following accounts are connected.'
                    :
                    'Yay! Your account is now connected!'}
                </Typography>

                <div className={classes.list}>
                  {accounts.filter(isMapped).toIndexedSeq().map((account) =>
                    <AccountRow
                      key={account.id}
                      account={account}
                      selectedModel={accountMap?.get(account.id)}
                      updateAccountMap={updateMapAndCheckEdit}
                      setEditAccount={setEditAccount}
                    />)}
                </div>
              </>}

              {(accountMap?.size !== accounts?.size) &&
                <>
                  <Typography variant="body1" className={classes.review}>
                    {accountMap?.size ?
                      'Click on any of the below to connect the account to your series in Simplifi. You can also connect these later.'
                      :
                      `We found ${accounts?.size} bills with this biller. Select the account you want to link for ${series?.transaction?.payee}`}
                  </Typography>

                  <div className={classes.list}>
                    {accounts.filter((x) => !isMapped(x)).toIndexedSeq().map((account) =>
                      <AccountRow
                        key={account.id}
                        account={account}
                        models={modelState}
                        staged={stagedAccount === account}
                        setStagedAccount={setStagedAccount}
                        updateAccountMap={updateMapAndCheckEdit}
                        radio={!accountMap?.size}
                      />)}
                  </div>
                </>}
            </>
            :
            <ZeroStateView
              primary="This login has no unconnected accounts"
              secondary="Try another biller or login."
              icon={zeroStateIcon}
            />)}
        </>}

      {!editAccount &&
        <div className={classes.actions}>
          <QButton
            id="submitButton"
            type="submit"
            variant="contained"
            onClick={handleContinue}
            disabled={!stagedAccount}
          >
            {buttonText}
          </QButton>
        </div>}
    </div>
  );
}

AccountsPage.defaultProps = {
};

AccountsPage.propTypes = {
  accounts: PropTypes.object,
  biller: PropTypes.object,
  series: PropTypes.object,
  complete: PropTypes.bool,
  setComplete: PropTypes.func,
  onRestart: PropTypes.func,
  mixpanelProps: PropTypes.object,

  editAccount: PropTypes.object,
  setEditAccount: PropTypes.func,
  accountMap: PropTypes.object,
  updateAccountMap: PropTypes.func,
};

export default AccountsPage;
