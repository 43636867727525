import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

const investmentTransactions = (account) => {
  const postedOnDate = DateTime.local().minus({ days: 1 });
  const postedOn = postedOnDate.toISODate();

  const defaultProps = () => ({
    source: 'QCS_REFRESH',
    accountId: account?.id,
    postedOn,
    payee: 'Aggregated Txn 2900',
    coa: {
      type: 'UNCATEGORIZED',
    },
    acquisitionOn: DateTime.local().minus({ months: 1 }).toISODate(),
    currency: 'USD',
    state: 'CLEARED',
    knownCategoryId: '4500900000',
    cpData: {
      id: uuidv4(),
      postedOn,
      txnOn: postedOn,
      payee: 'Aggregated Txn 1',
      inferredPayee: 'Aggregated Txn 1',
      inferredCoa: {
        type: 'UNCATEGORIZED',
      },
      cpCategoryId: '1207',
    },
    type: 'INVESTMENT',
    securityId: 'QEXP-2',
    securityIdType: 'CUSIP',
    // localSecurityId,
  });

  return [{
    ...defaultProps(),
    investmentTxnType: 'BUY',
    units: 11,
    pricePerUnit: -12.34,
    commission: 0.12,
    costBasis: 11 * -12.34 - 0.12,
    amount: 11 * -12.34 - 0.12,
    securityType: 'STOCK',
    symbol: 'AAPL',
    securityName: 'Apple Inc',
    cpData: {
      ...defaultProps().cpData,
      amount: 11 * -12.34 - 0.12,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'SELL',
    units: 2,
    pricePerUnit: 56.78,
    commission: 0.10,
    costBasis: 2 * -11.22 - 0.05,
    amount: 2 * 56.78 - 0.10,
    securityType: 'STOCK',
    symbol: 'INTU',
    securityName: 'Intuit Inc',
    cpData: {
      ...defaultProps().cpData,
      amount: 2 * 56.78 - 0.10,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'STOCK_DIVIDEND',
    units: 2,
    securityType: 'STOCK',
    symbol: 'INTU',
    securityName: 'Intuit Inc',
    cpData: {
      ...defaultProps().cpData,
      units: 2,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'REINVEST_DIVIDEND',
    securityType: 'STOCK',
    symbol: 'INTU',
    securityName: 'Intuit Inc',
    units: 10,
    commission: -0.10,
    amount: 10 * -11.22 - 0.10,
    cpData: {
      ...defaultProps().cpData,
      amount: 10 * -11.22 - 0.10,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'REINVEST_INTEREST',
    securityType: 'STOCK',
    symbol: 'AMZN',
    securityName: 'Amazon Inc',
    units: 2,
    commission: -0.01,
    amount: 2 * -3.4 - 0.01,
    cpData: {
      ...defaultProps().cpData,
      amount: 2 * -3.4 - 0.01,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'REINVEST_CAPITAL_GAIN_LONG',
    securityType: 'STOCK',
    symbol: 'APPL',
    securityName: 'Apple Inc',
    units: 3,
    commission: -0.10,
    amount: 3 * -5.06 - 0.02,
    cpData: {
      ...defaultProps().cpData,
      amount: 3 * -5.06 - 0.02,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'REINVEST_CAPITAL_GAIN_SHORT',
    securityType: 'STOCK',
    symbol: 'INTU',
    securityName: 'Intuit Inc',
    units: 10,
    commission: -0.10,
    amount: 10 * -11.22 - 0.10,
    cpData: {
      ...defaultProps().cpData,
      amount: 10 * -11.22 - 0.10,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'INCOME_DIVIDEND',
    symbol: 'INTU',
    units: 0,
    amount: 123.45,
    cpData: {
      ...defaultProps().cpData,
      amount: 123.45,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'INCOME_INTEREST',
    symbol: 'PYPL',
    units: 0,
    amount: 11.22,
    cpData: {
      ...defaultProps().cpData,
      amount: 11.22,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'CAPITAL_GAIN_LONG',
    symbol: 'TSLA',
    units: 0,
    amount: 22.33,
    cpData: {
      ...defaultProps().cpData,
      amount: 22.33,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'CAPITAL_GAIN_SHORT',
    symbol: 'GOOG',
    units: 0,
    amount: 44.55,
    cpData: {
      ...defaultProps().cpData,
      amount: 44.55,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'INCOME_MISCELLANEOUS',
    symbol: 'FB',
    units: 0,
    amount: 55.66,
    cpData: {
      ...defaultProps().cpData,
      amount: 55.66,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'ADD_SHARES',
    symbol: 'F',
    units: 12,
    cpData: {
      ...defaultProps().cpData,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'REMOVE_SHARES',
    symbol: 'F',
    units: -10,
    cpData: {
      ...defaultProps().cpData,
    },
  }, {
    ...defaultProps(),
    investmentTxnType: 'BUY_BONDS',
    symbol: 'F',
    units: 20000,
    amount: -19877.0000,
    securityId: 'QEXP-55',
    securityIdType: 'CUSIP',
    cpData: {
      ...defaultProps().cpData,
    },
  }, {
    source: 'QCS_REFRESH',
    accountId: account?.id,
    postedOn,
    payee: 'Some Payee',
    coa: {
      type: 'UNCATEGORIZED',
      id: '0',
    },
    amount: -12.34,
    state: 'CLEARED',
    matchState: 'NOT_MATCHED',
    cpData: {
      id: uuidv4(),
      cpPendingId: uuidv4(),
      postedOn,
      txnOn: postedOn,
      amount: -12.34,
      inferredPayee: 'Some Payee',
      inferredCoa: {
        type: 'UNCATEGORIZED',
        id: '0',
      },
      cpCategoryId: '2002',
    },
    type: 'CASH_FLOW',
  }];
};

export default investmentTransactions;
