
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { resourceStoreTypes, resourceSagaUtils } from 'companion-app-components/flux/core';
import { getLogger, crashReporterInterface } from 'companion-app-components/utils/core';
import { subscribeForBumpUpdates } from 'companion-app-components/flux/bump';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { accountsUtils, accountsSelectors } from 'companion-app-components/flux/accounts';
import { tagsActions } from 'companion-app-components/flux/tags';
import { transactionsActions as actions, transactionsTransformers as transformers, transactionsUtils } from 'companion-app-components/flux/transactions';
import { chartOfAccountsUtils } from 'companion-app-components/flux/chart-of-accounts';
import { scheduledTransactionsActions } from 'companion-app-components/flux/scheduled-transactions';
import { preProcessUpdate } from 'companion-app-components/flux/transactions-processing/preProcess';


import { updatePayeeListSuccess } from 'data/payees/actions';
import { getPayeesForAccounts } from 'data/payees/selectors';
import { isUncategorizedTxn } from 'data/transactions/utils';
import * as transactionsActions from 'data/transactions/actions';

import { getTransactionById, getTransactionsByAccountId, getTransactionsById } from './selectors';

const log = getLogger('transactions/sagas');

// ====================================================
// AUTOCATEGORIZE TRANSACTIONS
//
// reviews recently retrieved transactions and looks for uncategorized transactions that have a match in the
// payee list, and will automatically categorize using the provided suggestion
//
export function* autoCategorizeTransactions(action) {

  const featureFlags = yield select(featureFlagsSelectors.getFeatureFlags);

  if (!featureFlags?.get('autoCategorizeUncategorized')) {
    return;
  }
  const { payload: txnsToCheck } = action;
  const payeeList = yield select(getPayeesForAccounts, { accountIds: null });

  let txnsToUpdate = txnsToCheck.map((txn) =>
    (isUncategorizedTxn(txn) && txn.payee && payeeList.get(txn.payee?.toLowerCase())) ? txn : null);

  txnsToUpdate = txnsToUpdate.filter((txn) => (txn !== null && !accountsUtils.isLoanAccountFromId(txn.accountId)));
  txnsToUpdate = txnsToUpdate.map((txn) => {
    const payeeEntry = payeeList.get(txn.payee?.toLowerCase());
    if (payeeEntry && !transactionsUtils.isSplitTxn(payeeEntry.txn) && !transactionsUtils.isTransferTxn(payeeEntry.txn)) {
      return txn.set('coa', payeeEntry.txn.coa).set('split', payeeEntry.txn.split);
    }
    return null;
  });
  txnsToUpdate = txnsToUpdate.filter((txn) => txn !== null);

  const txnsByAccountId = yield select(getTransactionsByAccountId);

  log.debug('Auto categorizing transactions pre ', txnsToUpdate);

  const txnPack = preProcessUpdate({
    txns: txnsToUpdate,
    txnsByAccountId,
    featureFlags,
  });

  log.debug('Auto categorizing transactions ', txnPack);

  if (txnPack.allTxnsToUpdate.length > 0) {
    yield put(actions.updateTransaction(txnPack.allTxnsToUpdate));
  }
}

// ====================================================
//
// UPDATE TRANSACTION CATEGORIES FOR PAYEE
//
// reviews recently retrieved transactions and looks for uncategorized transactions that have a match in the
// payee list, and will automatically categorize using the provided suggestion
//
export function* updateTransactionCategoriesForPayee(action) {

  const { coa, payee, priorCoa } = action.payload;
  const transactionsById = yield select(getTransactionsById, {});

  // console.log("UPDATE CATS ", payee, priorCoa, coa);
  let txnsToUpdate = transactionsById.filter((txn) => (txn.payee === payee) && chartOfAccountsUtils.coasAreEqual(txn.coa, priorCoa));
  txnsToUpdate = txnsToUpdate.map((txn) => txn.set('coa', coa));

  const txnsByAccountId = yield select(getTransactionsByAccountId);
  const featureFlags = yield select(featureFlagsSelectors.getFeatureFlags);

  const txnPack = preProcessUpdate({
    txns: txnsToUpdate,
    txnsByAccountId,
    featureFlags,
  });

  log.debug('Updating Transaction Categories by Payee ', txnPack);

  if (txnPack.allTxnsToUpdate.length > 0) {
    yield put(actions.updateTransaction(txnPack.allTxnsToUpdate, { context: 'register', undo: { userMessage: 'Transaction(s) updated.' } }));
  }
}

//---------------------------------------------------
let lastGetTransactionsPayloads = [];

export function* storePayload(data) {
  log.debug('Store Payload', data);
  lastGetTransactionsPayloads = lastGetTransactionsPayloads.concat(data.payload.resources);
}

export function* triggerAutoCategorize() {
  if (lastGetTransactionsPayloads.length > 0) {
    yield put(transactionsActions.autoCategorizeTransactions(lastGetTransactionsPayloads));
    lastGetTransactionsPayloads = [];
  }
}
//---------------------------------------------------


// ====================================================
// PERFORM TRANSACTION ACTION
// a saga for utilizing the transactions/{id}/action or transactions/{id}/action/{otherId}
// pass in an action api object
//
// {
//   id: txnId to impact
//   action: 'ACCEPT' || 'SKIP' || 'MATCH' || 'UNMATCH'   See QCS Documentation for updates
//   otherId: if two IDs are involved in action (e.g. match)
// }
//
export function* performTransactionAction(actionData) {
  const config = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'transaction action',
    resourceBaseUrl: (action) => `/transactions/${action.payload.id}/${action.payload.action?.toLowerCase()}${action.payload.otherId ? `/${action.payload.otherId}` : ''}`,
    successAction: actions.performTransactionActionSuccess,
    failureAction: actions.performTransactionActionFailure,
    getLastSyncDate: () => null,
    transformResourceToRequestData: (resource) => {
      const { id, otherId, action, ...other } = resource;
      return { ...other };
    },
  });
  yield call(resourceSagaUtils.qcsSyncUpdateResource, config, actionData);
}

// =================================================================================================
// Bump Updaters
// =================================================================================================

subscribeForBumpUpdates('transactions',
  (resources) => bumpDumpUpdater(resources),
  () => bumpNudgeUpdater());

function* bumpDumpUpdater(resources) {
  const process = yield select(featureFlagsSelectors.getFeatureFlag, 'bumpProcessTxnUpdates');
  if (process) {
    yield put(actions.applyTransactionsChanges(
      transformers.transformQcsTransactionsToTransactions(resources),
      { context: 'BUMP_CHANGE_DUMP' }
    ));
  }
}

function* bumpNudgeUpdater() {
  const process = yield select(featureFlagsSelectors.getFeatureFlag, 'bumpProcessTxnUpdates');
  if (process) {
    yield put(actions.getTransactions(undefined, { context: 'BUMP_CHANGE_NUDGE' }));
  }
}

// ====================================================
// ACTION WATCHERS to trigger SAGAS calls

function* ReportActionFailure(actionData) {
  const { response, request } = actionData?.payload;
  if (response && request) {
    if ((response.status === 400) && (/match/.exec(request?.responseURL)?.[0] === 'match')) {

      const firstId = /(\d+)(?=\/match\/)/.exec(request?.responseURL)?.[0];
      const secondId = /\/match\/(\d+)/.exec(request?.responseURL)?.[1];
      const firstTxn = yield select(getTransactionById, firstId)?.set?.('memo', null);
      const secondTxn = yield select(getTransactionById, secondId)?.set?.('memo', null);
      const firstAccount = yield select(accountsSelectors.getAccountById, firstTxn?.accountId);
      const secondAccount = yield select(accountsSelectors.getAccountById, secondTxn?.accountId);
      crashReporterInterface.reportError(Error('Match Failed! (details)'), (event) => event.addMetadata('custom', {
        description: 'ID is deleted?',
        txn1: firstTxn?.toJS?.() || null,
        txn2: secondTxn?.toJS?.() || null,
        account1: firstAccount?.toJS?.() || null,
        account2: secondAccount?.toJS?.() || null,
      }));
    }
  }
}
export function* transactionActionFailureWatcher() {
  yield takeEvery(actions.performTransactionActionFailure, ReportActionFailure);
}

export function* getTransactionsSuccessActionWatcher() {
  yield takeLatest([
    actions.getTransactionsSuccess,
  ], storePayload);
}

export function* updatePayeeListSuccessActionWatcher() {
  yield takeLatest([
    updatePayeeListSuccess,
  ], triggerAutoCategorize);
}

export function* autoCategorizeTransactionsActionWatcher() {
  yield takeLatest(transactionsActions.autoCategorizeTransactions, autoCategorizeTransactions);
}

export function* updateTransactionCategoriesForPayeeActionWatcher() {
  yield takeLatest(transactionsActions.updateTransactionCategoriesForPayeeAction, updateTransactionCategoriesForPayee);
}

export function* deleteTagActionWatcher() {
  yield takeEvery(tagsActions.deleteTag, function* deleteTag(action) {
    if (action?.meta?.txns?.size) {
      yield put(actions.batchTransactions(action.meta.txns));
    }
  });
}

// action watcher to handle action on sechduled transaction instance(Transaction)
export function* getTransactionsActionSuccessWatcher() {
  yield takeLatest(actions.performTransactionActionSuccess, handleSchTransPending);
}

// check if the action was for a schedule txns instance get ScheduleTxn for QCS
function* handleSchTransPending(load) {
  if (load?.payload && load.payload[0]?.stModelId) {
    yield put(scheduledTransactionsActions.getScheduledTransactions());
  }
}

// ====================================================
// EXPORTS

export default [
  autoCategorizeTransactionsActionWatcher,
  updatePayeeListSuccessActionWatcher,
  updateTransactionCategoriesForPayeeActionWatcher,
  getTransactionsSuccessActionWatcher,
  getTransactionsActionSuccessWatcher,
  deleteTagActionWatcher,
  transactionActionFailureWatcher,
];
