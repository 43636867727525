// @flow
import * as React from 'react';

import Box from '@mui/material/Box';
import { Form } from 'formik';

type Props = {
  children: React.node,
  onSubmit: (values: any, actions: any) => void,
};

const StdDialogFormik = (props: Props) => {
  const { children, onSubmit, ...other } = props;

  return (
    <Box component={Form} display="flex" flex="1" flexDirection="column" onSubmit={onSubmit} {...other}>
      { children }
    </Box>
  );
};

export default StdDialogFormik;
