import React from 'react';
import { Popover, Typography } from '@mui/material';

interface SearchHelpBoxProps {
  classes: {
    searchHelpContainer?: string;
    searchHelpTitle?: string;
    searchHelpSubtitle?: string;
    helpText?: string;
  };
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const SearchHelpBox: React.FC<SearchHelpBoxProps> = ({ classes, anchorEl, onClose }) => (
  <Popover
    id="search-help-popover"
    open
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <div className={classes.searchHelpContainer}>
      <Typography className={classes.searchHelpTitle} variant="h6">
        Search Tips
      </Typography>
      <Typography className={classes.searchHelpSubtitle} variant="subtitle1">
        Searching for Amounts
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {`Amount searches that start with '-' or '+' will restrict to expenses or income`}
      </Typography> {/* eslint-disable-line */}
      <Typography className={classes.helpText} variant="body2">
        {'"23" will search for 23.00 or -23.00'}
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'"-23.50" will search for -23.50'}
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'"-23ish" will search for values roughly around -23.00'}
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'"~-23" will also search for values roughly around -23.00'}
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'"+>1000" will search for income transactions greater than 1000.00'}
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'"+<1000" will search for income transactions less than 1000.00'}
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'"-&50 .to. 100" will search for expense transactions between 50.00 and 100.00'}
      </Typography>
      <Typography className={classes.searchHelpSubtitle} variant="subtitle1">
        Searching for Dates
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'"02/03/2020" will search for transactions dated 02/03/2020'}
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'"02/03/2020ish" will search for transactions dated around 02/03/2020'}
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'">02/03/2020" will search for transactions on or past 02/03/2020'}
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'"<02/03/2020" will search for transactions on or before 02/03/2020'}
      </Typography>
      <Typography className={classes.helpText} variant="body2">
        {'"&02/03/2020 .to. 03/10/2020" will search for transactions between 02/03 and 03/10/2020'}
      </Typography>

    </div>
  </Popover>
);

export default SearchHelpBox; 
