
import React, { FC } from 'react';
import classNames from 'classnames';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { makeStyles } from 'tss-react/mui';

const outlineColor = '#ccc';
const taskHeight = 42;

const useStyles = makeStyles()(() => ({
  container: {
    width: 194,
    height: taskHeight,
    display: 'flex',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 'auto',
    marginBottom: 'auto',
    verticalAlign: 'middle',
    flex: 1,
  },
  taskFrame: {
    width: 155,
    height: taskHeight,
    background: 'white',
    borderRadius: 20,
    borderColor: outlineColor,
    borderWidth: 1,
    borderStyle: 'solid',
    flex: 1,
    '&.selected': {
      background: 'rgba(17, 102, 192, 0.2)',
    },
  },
  iconHolder: {
    flex: 0,
    minWidth: 24,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  checkIcon: {
    width: 24,
    height: 24,
    color: outlineColor,
    '&.completed': {
      color: 'green',
    },
  },
  connector: {
    flex: 0,
    minWidth: 20,
    height: 1,
    background: outlineColor,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  btnText: {
    lineHeight: `${taskHeight - 3}px`,
    fontSize: '16px',
    fontWeight: 500,
    color: '#1166b0',
    '&.completed': {
      color: '#555',
    },
  },
}));

interface TaskWidgetProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>, name: string) => void;
  completed: boolean;
  selected: boolean;
  title: string;
  name: string;
}

const TaskWidget: FC<TaskWidgetProps> = ({ onClick, completed, selected, title, name }) => {
  const { classes } = useStyles();
  const CheckIcon = completed ? CheckCircleIcon : CheckCircleOutline;
  const colorClass = (completed ? ' completed ' : '') + (selected ? ' selected' : '');

  return (
    <div className={classes.container}>
      <div className={classes.iconHolder}>
        <CheckIcon className={classNames(classes.checkIcon, colorClass)} />
      </div>
      <div className={classes.connector} />
      <ButtonBase onClick={(e) => onClick(e, name)}>
        <div className={classNames(classes.taskFrame, colorClass)}>
          <Typography className={classNames(classes.btnText, colorClass)}>
            {title}
          </Typography>
        </div>
      </ButtonBase>
    </div>
  );
};

export default TaskWidget;
