import { List, Map } from 'immutable';
import { getColumnDefaults } from 'components/TransactionRegister/transactionsConfig';

const defaultQUICKENPreferences =
  {

    transactionRegister: {
      doubleAmounts: false,   // pffffhhhhttttt on Kelly and Kristen
      editDownloaded: true,
      deleteDownloaded: true,  // this comes with lots of warnings, so right now default on
      autoSave: true,
      splitsInDialog: false,
      autoSearch: false,
      longCats: false,
      continuousCreate: false,
      showCurrencySymbol: false,
      wrapText: false,
      showAccountColors: false,
      currencySymbol: '$',
      registerComfort: 'normal',
      regFieldsSort: {
        cashFlow: List(getColumnDefaults()),
        credit: List(getColumnDefaults('credit')),
      },
    },

    calendarTransaction: {
      showTransactions: true,
      showScheduleTransactions: true,
      showDailyBalances: true,
      exceedingAmount: null,
      accountsToShow: null,
      dashboard: {
        showTransactions: true,
        showScheduleTransactions: true,
      },
    },

    accountsPrefs: null,
    accountBarBalanceType: 'today',
    accountBarShowCents: true,
    accountDrawerDefaultOpen: true,
    accountNodePrefs: new Map({}),
    projectedOpen: false,
    upcomingOpen: true,

    overviewPageLayout: List(),
    qCardsOffByDefault: null,
    autoRefresh: true,
    kioskmode: false,
    dateRangeProjectedBalances: 'thirty',
    currencySymbol: '$',
    theme: null,
  };

export const defaultDatasetPreferences = defaultQUICKENPreferences;

export const defaultUserPreferences = {
  webApp: {
    windowsReviewedWarningEnabled: true,
  },
};

export function regFieldsSortType(regFieldsSort, rawType) {
  const insvestmentTypes = ['INVESTMENT', 'BROKERAGE', 'RETIREMENT', 'OTHER_INVESTMENT'];
  const type = insvestmentTypes.includes(rawType) ? 'investment' : rawType?.toLowerCase();
  if (regFieldsSort && (type in regFieldsSort)) {
    return type;
  }
  return 'cashFlow';
}
