
import { REDUCER_KEY } from './reducer';
import type { BillerLoginStore } from './types';


export const getBillerLoginStore = (state: any): BillerLoginStore => state[REDUCER_KEY];

export const getBillerLoginsById = (state: any) => getBillerLoginStore(state).resourcesById;
export const getLoadPending = (state: any) => getBillerLoginStore(state).loadPending;
export const getLastSyncDate = (state: any) => getBillerLoginStore(state).lastSyncDate;

export const getBillerLoginForId = (state: any, id: string) => getBillerLoginsById(state).get(id);

export const getDiscoveringLogin = (state: any) => getBillerLoginStore(state).discovering;
