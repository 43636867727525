
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';

const readOnlyProps = { disableUnderline: true, readOnly: true };

const useStyles = makeStyles()(() => ({
  root: {
    paddingLeft: 14,
  },
  label: {
    left: 14,
  },
}));

function ReadOnlyField(props) {
  const { classes, cx } = useStyles();
  const { label, value, className, ...otherProps } = props;

  return (<TextField
    margin="normal"
    variant="standard"
    {...otherProps}
    label={label}
    value={value}
    InputProps={readOnlyProps}
    classes={{ root: cx(classes.root, className) }}
    InputLabelProps={{
      classes: { formControl: classes.label },
      focused: false,
    }}
  />);
}

ReadOnlyField.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
};

export default memo(ReadOnlyField);
