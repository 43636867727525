import React, { useMemo, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import StdDialog from 'components/Dialogs/StdDialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { removeDialog } from 'data/rootUi/actions';

import QButton from 'components/QButton';
import ScheduledTransactionLightForm from 'components/Forms/ScheduledTransactionLightForm';

const ScheduledTransactionLightEditDialog = (props) => {
  const { scheduledTransaction: scheduledTransactionProp, ...otherProps } = props;
  const scheduledTransaction = useMemo(() => scheduledTransactionProp?.toJS?.() || scheduledTransactionProp || {},
    [scheduledTransactionProp]);

  const formInterfaceRef = useRef();
  const dispatch = useDispatch();

  const onHandleClose = useCallback((event, reason) => {
    if (reason === 'SUBMIT_BUTTON' || reason === 'ENTER_KEY_DOWN') { // update
      if (formInterfaceRef && formInterfaceRef.current.submit) {
        formInterfaceRef.current.submit();
      }
    } else if (formInterfaceRef && formInterfaceRef.current.remove) {
      formInterfaceRef.current.remove();
      dispatch(removeDialog());
    }
  }, [formInterfaceRef, dispatch]);

  return (
    <StdDialog
      title="New Recurring"
      showCloseButton
      open
      maxWidth="xs"
      sharedcomponentid={'ADD_SCHEDULED_LIGHT_DIALOG'}
      {...otherProps}
      onClose={onHandleClose}
    >
      <>
        <DialogContent>
          <ScheduledTransactionLightForm
            scheduledTransaction={scheduledTransaction}
            renderProps={(formInterface) => { formInterfaceRef.current = formInterface; }}
            submitted={() => dispatch(removeDialog())}
          />
        </DialogContent>

        <DialogActions>
          <QButton
            id="scheduled-transaction-light-cancel"
            variant="outlined"
            onClick={(event) => onHandleClose(event, 'CANCEL_BUTTON')}
          >
            Cancel
          </QButton>

          <QButton
            id="scheduled-transaction-light-submit"
            variant="contained"
            type="submit"
            onClick={(event) => onHandleClose(event, 'SUBMIT_BUTTON')}
          >
            Create
          </QButton>
        </DialogActions>
      </>
    </StdDialog>
  );
};

ScheduledTransactionLightEditDialog.propTypes = {
  open: PropTypes.bool,
  // onHandleClose: PropTypes.func,
  scheduledTransaction: PropTypes.object,
};

export default ScheduledTransactionLightEditDialog;
