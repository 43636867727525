import styled from 'styled-components';

import QImageGallery from '../QuickenControls/QImageGallery';
import { SmallWhiteButton } from '../StyledComponents/ButtonsAll';
import { ModalDiv } from '../StyledComponents/HomePageAll';

export const ModalDocDiv = styled(ModalDiv)`
  width: 75%;
  min-height: 75%;
  max-height: 93%;
  top: 41px;
  left: 12.5%;
`;

export const DocCancelButton = styled(SmallWhiteButton)`
  margin-top: 0px;
`;

export const ImageGalleryStyled = styled(QImageGallery)`
  background-color: ${({ theme }) => theme.palette.silverGrey};
`;
