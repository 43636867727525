import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';

import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { withStyles } from 'tss-react/mui';

import QButton from 'components/QButton';
import StdDialog from 'components/Dialogs/StdDialog';
import { removeDialog } from 'data/rootUi/actions';

export const styles = () => ({
  textField: {
    marginTop: '10px',
    width: '60%',
  },
});

class FormDialog extends React.Component {

  state = {
    value: '',
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      this.handleClose(this.props.buttons[this.props.buttons.length - 1]);
    }
  };

  handleClose = (btn) => {
    this.props.removeDialog(this.props.dialogId);
    this.props.onClose?.({ btnPressed: btn, text: this.state.value });
  };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  renderButtons = () => {

    const btns = [];
    this.props.buttons?.forEach((btn) => btns.push(
      <QButton id={`formdlg:${btn}`} key={`formdlg:${btn}`} onClick={() => this.handleClose(btn)}>
        {btn}
      </QButton>
    ));
    return btns;
  };

  render() {

    const textFieldType = this.props.password ? 'password' : 'text';

    return (
      <StdDialog
        open
        onClose={this.handleClose}
        showCloseButton={false}
        title={this.props.title}
        sharedcomponentid={'DIALOG_FORM'}
      >
        <DialogContent>
          <DialogContentText>
            {this.props.content}
          </DialogContentText>
          <TextField
            className={this.props.classes.textField}
            autoFocus
            margin="dense"
            id="name"
            label={this.props.label}
            fullWidth
            value={this.state.value}
            onChange={this.handleChange}
            type={textFieldType}
            InputProps={{
              onKeyDown: this.onKeyDown,
              inputProps: {
                maxLength: 214, // max that RSA 2048 encryption could do
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          {this.renderButtons()}
        </DialogActions>
      </StdDialog>
    );
  }
}

FormDialog.propTypes = {
  dialogId: PropTypes.string,

  onClose: PropTypes.func,
  buttons: PropTypes.array,
  content: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  password: PropTypes.bool,

  classes: PropTypes.object,
  removeDialog: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    removeDialog: (props) => dispatch(removeDialog(props)),
  };
}

export default compose(
  connect(null, mapDispatchToProps),
)(withStyles(FormDialog, styles, { name: 'FormDialog' }));


