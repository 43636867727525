import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles()((_theme, { placeholder }) => ({
  forPlaceholder: {
    '& input': {
      minWidth: `${placeholder?.length || 6}ch !important`,
    },
  },
}));

const AutoTagsField = React.memo((props) => {
  const { value: tags, defaultValue: defaultTags, onChange, id, normalize, separator, ...otherProps } = props;
  const { classes, cx } = useStyles({ placeholder: otherProps.placeholder });

  const [uncontrolledTags, setUncontrolledTags] = useState(defaultTags);
  const [inputValue, setInputValue] = useState('');

  const onInputChange = useCallback((event, value, reason) => {
    if (reason === 'input') {
      const valueNormalized = normalize ? normalize(value) : value;
      const newTags = valueNormalized.split(separator);
      const newInputValue = newTags.pop();
      setInputValue(newInputValue);
      if (newTags.length) {
        const allTags = [...(tags || uncontrolledTags), ...newTags];
        if (uncontrolledTags) {
          setUncontrolledTags(allTags);
        }
        onChange?.(event, allTags, reason);
      }
    }
  }, [onChange, tags, uncontrolledTags, setInputValue, separator, normalize]);

  const onChangeLocal = useCallback((event, value, reason) => {
    if (uncontrolledTags) {
      setUncontrolledTags(value);
    }
    onChange?.(event, value, reason);
  }, [onChange, uncontrolledTags, setUncontrolledTags]);

  const onBlur = useCallback((event) => {
    if (event.target.value) {
      const allTags = [...(tags || uncontrolledTags), event.target.value];
      if (uncontrolledTags) {
        setUncontrolledTags(allTags);
      }
      onChange?.(event, allTags, 'blur');
      setInputValue('');
    }
  }, [onChange, setInputValue, tags, uncontrolledTags]);

  return (
    <Autocomplete
      id={id}
      multiple
      options={tags || uncontrolledTags}
      value={tags || uncontrolledTags}
      //  getOptionLabel={getOptionLabel}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="filled"
            label={option}
            id={`keyword-${index}`}
            deleteIcon={<DeleteIcon id={`delete-keyword-${index}`} />}
            {...getTagProps({ index })}
          />
        ))}
      inputValue={inputValue || ''}
      onInputChange={onInputChange}
      onChange={onChangeLocal}
      onBlur={onBlur}
      open={false}
      disableClearable
      forcePopupIcon={false}
      renderInput={(params) => (
        <TextField
          {...params}
          sharedcomponentid={'AUTO_TAGS_FIELD'}
          label="Tags"
          placeholder="+ tag"
          {...otherProps}
          className={cx(classes.forPlaceholder, otherProps.className)}
        />
      )}
    />
  );
});

AutoTagsField.defaultProps = {
  separator: ' ',
  className: '',
};

AutoTagsField.propTypes = {
  value: PropTypes.array,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
  id: PropTypes.any,
  separator: PropTypes.any,
  normalize: PropTypes.func,
  className: PropTypes.string,
};

export default AutoTagsField;
