// @flow
import { List, Map as ImmutableMap, Record, Set } from 'immutable';
import type { RecordFactory, RecordOf } from 'immutable';

import type { QcsSyncResourceStoreProps } from 'companion-app-components/flux/core/resourceStoreTypes';

import ExtendableError from 'es6-error';

type InstitutionLoginAggregatorProps = {
  channel: ?string,
  cpId: ?string,

  aggStatus: ?string,
  aggStatusCode: ?string,
  aggStatusDetail: ?string,

  lastStatusUpdatedAt: ?string,

  lastRefreshSuccessfulAt: ?string,
  lastRefreshAttemptedAt: ?string,
}
export type InstitutionLoginAggregator = RecordOf<InstitutionLoginAggregatorProps>;
export const mkInstitutionLoginAggregator: RecordFactory<InstitutionLoginAggregatorProps> =
  Record({
    channel: null,
    cpId: null,

    aggStatus: null,
    aggStatusCode: null,
    aggStatusDetail: null,

    lastStatusUpdatedAt: null,

    lastRefreshSuccessfulAt: null,
    lastRefreshAttemptedAt: null,
  });

type CredentialProps = {
  key: string,
  value: string,
};
export type Credential = RecordOf<CredentialProps>;
export const mkCredential :RecordFactory<CredentialProps> =
  Record({
    key: 'unknown',
    value: 'unknown',
  });

type FiMigrationInfoProps = {
  toInstitutionId: string,
  toChannel: string,
}
export type FiMigrationInfo = RecordOf<FiMigrationInfoProps>;
export const mkFiMigrationInfo :RecordFactory<FiMigrationInfoProps> =
  Record({
    toInstitutionId: 'unknown',
    toChannel: 'unknown',
  });

type InstitutionLoginProps = {
  id: ?string,
  name: ?string,

  institutionId: string,
  brandingId: ?string,
  cpInstitutionId: ?string,

  ewcLive: boolean,

  cpSetupMode: null
    | 'DISCOVER_ACCOUNTS_ONLY' | 'DISCOVER_AND_ADD_ACCOUNTS' | 'DISCOVER_AND_REPLACE_ACCOUNTS' | 'SKIP_ACCOUNT_DISCOVERY'
    | 'UPDATE_CREDENTIALS',
  channel: ?string,
  credentials: ?List<Credential>,

  aggregators: ?List<InstitutionLoginAggregator>,

  optionalAction?: string,
  fiMigrationInfo?: FiMigrationInfo,

  isDeleted: boolean,
};
export type InstitutionLogin = RecordOf<InstitutionLoginProps>;
export const mkInstitutionLogin :RecordFactory<InstitutionLoginProps> =
  Record({
    id: undefined,
    name: 'unknown',

    institutionId: undefined,
    brandingId: undefined,
    cpInstitutionId: undefined,

    ewcLive: false,

    cpSetupMode: undefined,
    channel: undefined,
    credentials: undefined,

    aggregators: undefined,

    optionalAction: undefined,
    fiMigrationInfo: undefined,

    isDeleted: false,
  });

type InstitutionLoginsStoreProps = QcsSyncResourceStoreProps & {
  isRefreshingAll: boolean,
  idsRefreshing: Set<string>,

  idsRefreshingWithUserActionsPending: Set<string>,
  idsRefreshingWithCancelledUserActions: Set<string>,

  credentialBlobsForRefreshingLogins: ImmutableMap<string, string>,
  credentialsForRefreshingLogins: ImmutableMap<string, List<Credential>>,
}
export const mkInstitutionLoginsStore :RecordFactory<InstitutionLoginsStoreProps> =
  Record({
    resourcesById: ImmutableMap(),
    lastSyncDate: null,

    loadPending: true,
    isLoading: false,

    isRefreshingAll: false,
    idsRefreshing: Set(),

    idsRefreshingWithUserActionsPending: Set(),
    idsRefreshingWithCancelledUserActions: Set(),

    credentialBlobsForRefreshingLogins: ImmutableMap(),
    credentialsForRefreshingLogins: ImmutableMap(),
  });
export type InstitutionLoginsStore = RecordOf<InstitutionLoginsStoreProps>;

export type getInstitutionsPayload = {
  institutionLogins: Array<InstitutionLogin>,
  replace: boolean,
  loadPending: boolean,
  lastSyncDate: string,
}

// ================================================================================================
// MfaChallenge & MfaResponse
// ================================================================================================

type MfaAnswerChoiceProps = {
  displayText: string,
}
export type MfaAnswerChoice = RecordOf<MfaAnswerChoiceProps>;
export const mkMfaAnswerChoice: RecordFactory<MfaAnswerChoiceProps> =
  Record({
    displayText: 'unknown',
  });

type MfaChallengeItemProps = {
  type: string,

  key: string,
  sequence: number,

  base64Image: ?string,
  question: string,
  answerChoices: ?List<MfaAnswerChoice>,
}
export type MfaChallengeItem = RecordOf<MfaChallengeItemProps>;
export const mkMfaChallengeItem: RecordFactory<MfaChallengeItemProps> =
  Record({
    type: 'QUESTION',

    key: '0',
    sequence: 0,

    base64Image: null,
    question: 'unknown',
    answerChoices: null,
  });


type MfaChallengeProps = {
  institutionLoginId: string,
  challenges: List<MfaChallengeItem>,

  submitUrl: string,
}
export type MfaChallenge = RecordOf<MfaChallengeProps>;
export const mkMfaChallenge: RecordFactory<MfaChallengeProps> =
  Record({
    institutionLoginId: '0',
    challenges: List(),

    submitUrl: '',
  });


type MfaAnswerProps = {
  id: string,
  value: string,
};
export type MfaAnswer = RecordOf<MfaAnswerProps>;
export const mkMfaAnswer: RecordFactory<MfaAnswerProps> =
  Record({
    id: '0',
    value: 'unknown',
  });


type MfaResponseProps = {
  institutionLoginId: string,
  answers: List<MfaAnswer>,
  submitUrl: string,
}
export type MfaResponse = RecordOf<MfaResponseProps>;
export const mkMfaResponse: RecordFactory<MfaResponseProps> =
  Record({
    institutionLoginId: '0',
    answers: List(),
    submitUrl: 'need_to_set_submit_url',
  });

export class RefreshAccountsMfaFormSubmitError extends ExtendableError {
  constructor(message: String, institutionLoginId: String) {
    super(message);
    this.institutionLoginId = institutionLoginId;
  }
}

// ================================================================================================
// RefreshResponse -
// ================================================================================================

export type RefreshAccountsCompletedReason =
  'DONE_PROCESSING | NO_ACCOUNTS_TO_REFRESH | FAILED_WHEN_SUBMITTING | FAILED_WHEN_POLLING | TIMEOUT_WHILE_PROCESSING';

type RefreshAggregatorProps = {
  channel: ?string,

  isProcessing: boolean,
  aggStatus: ?string,
  cpAggStatus: ?string,
  cpAggStatusDetail: ?string,

  mfaChallenges: ?any,
}
export type RefreshAggregator = RecordOf<RefreshAggregatorProps>;
export const makeRefreshAggregator: RecordFactory<RefreshAggregatorProps> =
  Record({
    channel: undefined,

    isProcessing: false,
    aggStatus: undefined,
    cpAggStatus: undefined,
    cpAggStatusDetail: undefined,

    mfaChallenges: undefined,
    accounts: undefined,
  });

type RefreshInstitutionLoginProps = {
  id: string,

  isProcessing: boolean,
  status: string,

  credentialsBlob: ?string,
  submitUrl: ?string,

  aggregators: ?List<RefreshAggregator>,
}
export type RefreshInstitutionLogin = RecordOf<RefreshInstitutionLoginProps>;
export const makeRefreshInstitutionLogin: RecordFactory<RefreshInstitutionLoginProps> =
  Record({
    id: '0',

    isProcessing: false,
    status: 'OK',

    credentialsBlob: undefined,
    submitUrl: undefined,

    aggregators: undefined,
  });

type RefreshResponseProps = {
  jobId: string,

  isProcessing: boolean,
  status: string,

  institutionLogins: List<RefreshInstitutionLogin>
}
export type RefreshResponse = RecordOf<RefreshResponseProps>;
export const makeRefreshResponse: RecordFactory<RefreshResponseProps> =
  Record({
    jobId: '0',

    isProcessing: false,
    status: 'OK',

    institutionLogins: List(),
  });
