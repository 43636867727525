// CORE
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';

// DATA
import PaperAirplane from 'assets/paper-airplane-flying.svg';
import blueThumbs from 'assets/blue-thumbs.svg';

// LOCAL
import isAcme from 'isAcme';
import QButton from 'components/QButton';
import { DialogActions } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
  },
  confirmationRoot: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  airplaneImage: {
    height: 168,
    width: 'auto',
    margin: theme.spacing(2),
  },
  inputFields: {
    margin: `${theme.spacing(2)} 0`,
  },
  dialogActions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}));

const SendInstitutionRequest = (props) => {
  const { onSendInstitutionRequest, startingValue, handleReset } = props;
  const { classes } = useStyles();

  const [institutionName, setInstitutionName] = useState(startingValue || '');
  const [loginURL, setLoginURL] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = () => {
    onSendInstitutionRequest({ institutionName, loginURL });
    setShowConfirmation(true);
  };

  if (showConfirmation) {
    return (
      <Box className={classes.confirmationRoot}>
        <img src={blueThumbs} alt={'thumbs-up'} className={classes.airplaneImage} />
        <Box marginTop={1} marginBottom={4}>
          <Typography variant={'body1'}>Your request has been submitted.</Typography>
        </Box>
        <QButton variant={'contained'} onClick={handleReset}>got it</QButton>
      </Box>
    );
  }


  return (
    <>
      <Box className={classes.root}>
        <Box display={'flex'} alignItems={'center'}>
          <img className={classes.airplaneImage} src={PaperAirplane} alt={'paper-airplane'} />
          <Box display={'flex'} flexDirection={'column'} marginTop={2} marginLeft={2}>
            <Typography variant={'body1'}>
              Enter the details below to send a request to {isAcme ? 'Simplifi' : 'Quicken'}.
            </Typography>
            <TextField
              value={institutionName}
              onChange={(e) => setInstitutionName(e.target.value)}
              variant={'outlined'}
              label={'Financial institution'}
              className={classes.inputFields}
            />
            <TextField
              value={loginURL}
              onChange={(e) => setLoginURL(e.target.value)}
              variant={'outlined'}
              label={'Login URL'}
              className={classes.inputFields}
            />
          </Box>
        </Box>
      </Box>
      <DialogActions className={classes.dialogActions}>
        <QButton
          onClick={handleSubmit}
          variant={'contained'}
          disabled={institutionName.length === 0 || loginURL.length === 0}
        >
          request
        </QButton>
      </DialogActions>
    </>
  );
};
SendInstitutionRequest.propTypes = {
  onSendInstitutionRequest: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  startingValue: PropTypes.string,
};

export default SendInstitutionRequest;
