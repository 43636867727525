import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as preferencesActions from 'companion-app-components/flux/preferences/actions';
import * as preferencesSelectors from 'companion-app-components/flux/preferences/selectors';

import compose from 'utils/compose';

export default () => (WrappedComponent) => {
  class QPreferences extends Component {

    setDatasetPreference = (prefObj) => {
      this.props.setPreference({
        section: 'shared',
        group: 'dataset',
        preference: { webApp: prefObj },
      }, { context: 'QPreferences:setDatasetPreference' });
    };

    setUserPreference = (prefObj) => {
      this.props.setPreference({
        section: 'shared',
        group: 'user',
        preference: { webApp: prefObj },
      }, { context: 'SetupPageAcme:setUserPreference' });
    };

    render() {

      const qPrefProps = {
        setDatasetPreference: this.setDatasetPreference,
        setUserPreference: this.setUserPreference,
        datasetPreferences: this.props.datasetPreferences,
        userPreferences: this.props.userPreferences,
        sharedPreferences: this.props.sharedDatasetPreferences,
        sharedUserPreferences: this.props.sharedUserPreferences,
        accountPreferencesById: this.props.accountPreferencesById,
      };

      // wrapped component with its props, the state from HOC and uiStateProps
      return (
        <WrappedComponent
          {...{
            ...this.props,
            ...qPrefProps,
          }}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      datasetPreferences: preferencesSelectors.getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] }),
      userPreferences: preferencesSelectors.getSharedPreferencesByPath(state, { group: 'user', path: ['webApp'] }),
      sharedUserPreferences: preferencesSelectors.getSharedPreferencesByPath(state, { group: 'user' }),
      sharedDatasetPreferences: preferencesSelectors.getSharedPreferencesByPath(state, { group: 'dataset' }),
      accountPreferencesById: preferencesSelectors.accountPreferencesById(state),
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      // { section, group, preference }
      setPreference: (payload, meta) => dispatch(preferencesActions.setPreference(payload, meta)),
      getSharedPreferences: () => {
        dispatch(preferencesActions.getPreferences({ section: 'shared', group: 'user' }));
        dispatch(preferencesActions.getPreferences({ section: 'shared', group: 'dataset' }));
      },
    };
  }

  QPreferences.propTypes = {
    setPreference: PropTypes.func,
    datasetPreferences: PropTypes.object,
    userPreferences: PropTypes.object,
    sharedDatasetPreferences: PropTypes.object,
    sharedUserPreferences: PropTypes.object,
    accountPreferencesById: PropTypes.object,
  };

  // the HOC itself is wrapped in connect
  // return connect(mapStateToProps, mapDispatchToProps)(QData);

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
  )(QPreferences);
};
