// @flow
import * as React from 'react';

import { Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

type NotificationDialogPropsProps = {
  title: string,
  content: string | React.Node,
  buttonLabel: ?string,
  onClose: ?() => void,

  dialogProps: ?Object,
}
export type NotificationDialogProps = RecordOf<NotificationDialogPropsProps>;
export const mkNotificationDialogProps: RecordFactory<NotificationDialogPropsProps> =
  Record({
    title: 'Notification',
    content: '',
    buttonLabel: 'OK',
    onClose: null,

    dialogProps: null,
  });

export const DIALOG_TYPE = 'DIALOG_NOTIFICATION';

