// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { noop } from 'lodash';

import { accountsActions, accountsTypes, accountsUtils } from 'companion-app-components/flux/accounts';
import { transactionsActions, transactionsTypes } from 'companion-app-components/flux/transactions';
import { tracker } from 'companion-app-components/utils/core';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { Field, Form, Formik } from 'formik';
import type { FormikBag } from 'formik';

import ReactDatePicker from 'react-datepicker';

import StdDialogActions from 'components/Dialogs/StdDialogActions';
import AccountTypeSelector from 'components/Formik/AccountTypeSelector';
import AmountField, { ShowSignEnum } from 'components/QuickenControls/AmountField';

import { isAcme } from 'isAcme';

import * as selectors from '../selectors';


type Props = {
  scope: string,
  trackingProperties: Object,
  onCancel: () => void,
};

const useStyles = makeStyles()((theme) => ({
  accountName: {
    marginTop: 12,
    display: 'flex',
    width: 550,
  },
  subtitle: {
    color: theme.palette.greyScaleDeprecated[2],
  },
  accountTypeFormControl: {
    marginTop: 16,
    display: 'flex',
    width: 550,
  },
  inputLabel: {
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    paddingLeft: 2,
    paddingRight: 3,
  },
}));

const NonConnectedAccount = (props: Props) => {
  const { scope, trackingProperties } = props;

  const dispatch = useDispatch();

  const isSubmitting = useSelector((state) => selectors.getIsSubmitting(state, { scope }));

  const { classes } = useStyles();

  const handleSubmit = (values, _actions) => {

    const accountParams = {
      clientId: uuidv4().toUpperCase(),

      name: values.accountName,
      subType: values.accountType.qcsSubType,
      type: values.accountType.qcsType,

      balanceAsOf: 0.0,
      balanceAsOfOn: '1970-01-01',
    };

    const accountToUpsert = accountsTypes.mkAccount(values.accountType.qcsType, accountParams);

    const openingBalanceAsOf = (values.statementBalance && Number(parseFloat(values.statementBalance).toFixed(2))) || 0.0;
    const openingBalanceAsOfOn = DateTime.fromFormat(values.statementDate, 'D').toISODate() || '1970-01-01';

    new Promise((resolve, reject) => dispatch(accountsActions.batchAccounts([accountToUpsert], { resolve, reject, scope })))
      .then((response) => {
        if (!isAcme || openingBalanceAsOf !== 0.0) {
          const amountSign = accountsUtils.balanceSignForType(values?.accountType?.qcsType);
          const accountId = response?.data?.items?.[0]?.resource?.id;
          if (accountId) {
            const newTxn = new transactionsTypes.CashFlowTransaction({
              accountId,
              payee: 'Opening Balance',
              coa: {
                type: 'BALANCE_ADJUSTMENT',
                id: '0',
              },
              state: 'RECONCILED',
              isReviewed: true,
              amount: openingBalanceAsOf * amountSign,
              postedOn: openingBalanceAsOfOn,
              clientId: uuidv4().toUpperCase(),
              isExcludedFromReports: true,
              isExcludedFromF2S: true,
            });
            dispatch(transactionsActions.createTransaction(newTxn, { context: 'addAccounts', scope }));
          }
        }
      })
      .catch(noop);

    tracker.track(tracker.events.addFIComplete, ({
      ...trackingProperties,
      accounts_added: 1,
      accounts_ignored: 0,
      accounts_linked: 0,
      accounts_changed_name: 0,
      accounts_changed_type: 0,
      accounts_changed_subtype: 0,
      accounts_already_connected: 0,
    }));
  };

  const renderForm = (formikBag: FormikBag) => {
    const {
      handleSubmit: formikHandleSubmit,
      errors,
      touched,
      isValid,
      values,
    } = formikBag;

    let statementDateLabel = 'Statement Ending Date';
    let statementBalanceLabel = 'Statement Ending Balance';

    const { accountType } = values;
    if (accountType) {
      if (accountType.id === 'LIABILITY') {
        statementBalanceLabel = 'Liability Amount';
        statementDateLabel = 'Date to start tracking';
      }
      if (accountType.id === 'OTHER_ASSET') {
        statementBalanceLabel = 'Asset value';
        statementDateLabel = 'Date to start tracking';
      }
    }

    return (
      <Box component={Form} display="flex" flex="1" flexDirection="column" justifyContent="space-between" mt={3} onSubmit={formikHandleSubmit}>
        <Box display="flex" flex="1" flexDirection="column" mt={3} mx={3}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            You need to enter your transactions manually for this account.
          </Typography>
          <Field
            name="accountName"
            label="Account Name"
            variant="outlined"
            className={classes.accountName}
            inputProps={{
              id: 'manual_account_name',
            }}
            validate={(value) => value && value.length > 0 ? undefined : 'required'}
          >
            {({ field }) => (
              <TextField
                {...field}
                className={classes.accountName}
                label="Account Name"
                InputProps={{
                  autoComplete: 'off',
                  maxLength: 64,
                  inputProps: {
                    maxLength: 64,
                  },
                }}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          </Field>

          <div className={classes.accountTypeFormControl}>
            <AccountTypeSelector
              fullWidth
              id="manual_account_type"
              name="accountType"
              showManualTypes
            />
          </div>


          {values.accountType && (!isAcme || values.accountType.qcsType !== 'INVESTMENT') &&
            <Box display="flex" justifyContent="space-between" mt={2} width="552px">
              <Field
                name="statementBalance"
              >
                {({ field }) => (
                  <AmountField
                    {...field}
                    variant="outlined"
                    showSign={ShowSignEnum.NEVER}
                    hideCurrencyString
                    style={{ width: 268 }}
                    editable
                    omitCents
                    currencySymbol="USD"
                    amountType="amount"
                    id="schedtxn-amount"
                    marginProp={'dense'}
                    textFieldVariant={'outlined'}
                    label={statementBalanceLabel}
                    autoComplete="off"
                    InputProps={{ id: 'manual_ending_balance' }}
                  />
                )}
              </Field>

              <Field
                name="statementDate"
                validate={(value) => {
                  let errorMessage;
                  if (!value || !value.length) {
                    errorMessage = 'required';
                  } else if (!DateTime.fromFormat(value, 'D').isValid) {
                    errorMessage = 'Invalid date format. Enter date in mm/dd/yyyy format.';
                  }
                  return errorMessage;
                }}
              >
                {({ field }) => {
                  const date = DateTime.fromFormat(field.value, 'D');
                  const selected = date.isValid ? date.toJSDate() : new Date();
                  return (
                    <ReactDatePicker
                      id="manual_ending_date"
                      selected={selected}
                      onChange={(jsDate) => formikBag.setFieldValue('statementDate', DateTime.fromJSDate(jsDate).toFormat('D'))}
                      maxDate={new Date()}
                      allowSameDay
                      popperPlacement="top-end"
                      customInput={
                        <TextField
                          InputProps={{
                            ...field,
                            classes: {},
                            autoComplete: 'off',
                            style: {
                              width: 268,
                            },
                          }}
                          label={statementDateLabel}
                          variant="outlined"
                          margin="dense"
                          error={Boolean(errors.statementDate && touched.statementDate)}
                          helperText={touched.statementDate && errors.statementDate}
                        />
                      }
                    />
                  );
                }}
              </Field>
            </Box>}
        </Box>
        <StdDialogActions
          primaryId="manual_done_btn"
          primaryDisabled={!isValid || isSubmitting || !values.accountType || !values.accountName}
          primaryLabel="Continue"
        />
      </Box>
    );
  };

  const initialVals = { accountName: '', statementDate: DateTime.local().toFormat('D'), statementBalance: '0' };
  return (
    <Formik
      initialValues={initialVals}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {renderForm}
    </Formik>
  );
};

export default NonConnectedAccount;
