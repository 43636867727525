/* eslint-disable react/forbid-foreign-prop-types */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Dump from 'components/Dump';
import QButton from 'components/QButton';
import * as Docs from 'containers/SharedComponentsPage/DocsUtils';

import { convertToLegiblePropTypes } from '../utils';

const useStyles = makeStyles()((theme) => ({
  componentContainer: {
    border: '1px dotted lightgray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
  },
  exampleContainer: {
    margin: '40px 0',
    padding: 10,
    boxShadow: theme.shadows[4],
    width: '100%',
  },
  exampleHeader: {
    marginBottom: 20,
  },
  exampleInfoHeader: {
    borderBottom: `1px solid ${theme.palette.border.primary}`,
    lineHeight: 1.1,
    marginBottom: 5,
    marginTop: 10,
  },
  exampleInfoContainer: {
  },
  expansionRoot: {
    marginTop: 10,
  },
  root: {
    position: 'relative',
    width: '80%',
  },
  header: {
    textAlign: 'center',
  },
}));

export const COMPONENT_WRAPPER_KEY = 'COMPONENT';

const ComponentWrapper = (props) => {
  const { item, showDetails } = props;
  const { classes } = useStyles();

  const [itemId, setItemId] = useState(item.id);
  const [sharedstate, setsharedstate] = useState({ ...item.defaultState });
  const [exampleStates, setExampleStates] = useState(item.examples?.map((_e) => ({ ...item.defaultState })) || []);

  const setSharedStateWrapper = useCallback((newStateValues) => {
    setsharedstate({ ...sharedstate, ...newStateValues });
  }, [sharedstate]);

  const setExampleStateWrapper = useCallback((exampleIndex) => (newStateValues) => {
    setExampleStates(exampleStates.map((exs, index) => index === exampleIndex ? ({ ...exs, ...newStateValues }) : exs));
  }, [exampleStates]);

  useEffect(() => {
    setItemId(item.id);
    setsharedstate({ ...item.defaultState });
    setExampleStates(item.examples?.map((_e) => ({ ...item.defaultState })) || []);
  }, [item]);

  const renderObject = useCallback((object) => (
    <Typography style={{ whiteSpace: 'pre' }}>
      {Object.entries(object || {}).map(([key, val]) => `\t${key}: ${val},`).join('\n')}
    </Typography>
  ), []);

  // const propTypesRender = useMemo(() => {
  //   const propTypes = convertToLegiblePropTypes(item.propTypes);
  //   return (
  //     <div>
  //       <Typography variant={"subtitle1"} >Prop Types</Typography>
  //       <Typography variant={"body1"} style={{ whiteSpace: 'pre' }} >
  //         {"{\n"}
  //         {Object.entries(propTypes || {}).map(([key, val]) => `\t${key}: ${val},`).join("\n")}
  //         {"\n}"}
  //       </Typography>
  //     </div>
  //   )
  // }, [item])

  const eatClick = useCallback((e) => e.stopPropagation(), []);
  if (item.id !== itemId) return null;

  if (item.children?.length === 0) {  //  JUST show the title of the page and a zero state message if the page currently has no children
    return (
      <div>
        <Typography
          variant={'h1'}
          style={{ textAlign: 'center' }}
        >
          {item.name}
        </Typography>
        <Typography
          variant={'h5'}
          style={{ textAlign: 'center', marginTop: 10 }}
        >
          This page contains no components
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {showDetails ?
        <Docs.Header>{item.name}</Docs.Header>
        :
        <Typography className={classes.header} variant={'h3'}>{item.name}</Typography>}
      {item.description &&
      <Docs.Section>
        {typeof item.description === 'string' ?
          <Typography variant={'body1'}>
            {item.description}
          </Typography>
          :
          item.description}
      </Docs.Section>}
      {1 === 0 && item?.importName &&
      <>
        <Typography variant={'caption'}>
          {item?.importName}
        </Typography>
        <QButton
          variant={'caption'}
          onClick={(event) => {
            navigator.clipboard.writeText(item?.importName);
            event.stopPropagation();
          }}
        >
          Copy import
        </QButton>
      </>}
      <Dump obj={convertToLegiblePropTypes(item.componentPropTypes)} />
      {showDetails && item.examples ? item.examples?.map((ex, index) => (
        <Card key={`example-${ex.label}`} className={classes.exampleContainer}>
          <div className={classes.exampleHeader}>
            <Typography variant={'h4'}>
              {ex.label}
            </Typography>
            <Typography variant={'body1'}>
              {ex.description}
            </Typography>
          </div>
          <div className={classes.componentContainer}>
            {React.createElement(item.component, {
              sharedstate,
              setsharedstate: setSharedStateWrapper,
              state: exampleStates[index],
              setstate: setExampleStateWrapper(index),
              exampleProps: ex.props,
            })}
          </div>
          <Accordion className={classes.expansionRoot}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Example Specifics</AccordionSummary>
            <AccordionDetails>
              <div className={classes.exampleInfoContainer}>
                <Typography className={classes.exampleInfoHeader} variant={'subtitle1'}>Props</Typography>
                {renderObject(ex.props)}
                <Typography className={classes.exampleInfoHeader} variant={'subtitle1'}>State</Typography>
                {renderObject(exampleStates[index])}
                <Typography className={classes.exampleInfoHeader} variant={'subtitle1'}>Shared State</Typography>
                {renderObject(sharedstate)}
              </div>
            </AccordionDetails>
          </Accordion>
        </Card>
      ))
        :
        (
          <Card className={classes.exampleContainer}>
            <div role={'presentation'} onClick={eatClick} className={classes.componentContainer}>
              {React.createElement(item.component, {
                state: sharedstate,
                setstate: setSharedStateWrapper,
                sharedstate,
                setsharedstate: setSharedStateWrapper,
              })}
            </div>
          </Card>
        )}
    </div>
  );

};

ComponentWrapper.propTypes = {
  item: PropTypes.object,
  showDetails: PropTypes.bool,
};

export default ComponentWrapper;
