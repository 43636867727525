import React, { ReactNode, useCallback, useMemo } from 'react';
import { Map as ImmutableMap } from 'immutable';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';
import { scheduledTransactionsSelectors } from 'companion-app-components/flux/scheduled-transactions';
import RootState from 'companion-app-components/utils/redux-store/rootState';


import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AlarmOn from '@mui/icons-material/AlarmOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getTransactionsByAccountId } from 'data/transactions/selectors';
import { formatAmount } from 'components/QCurrency/core';
import QTip from 'components/QuickenControls/QTip';
import QMenu from 'components/QMenu';
import useQData from 'components/QData/useQData';

import {
  getCalendarTxnType, getTxnStateInfo, isUnacceptedScheduledTxn,
  nextScheduledInstanceAfterDate, transactionEditsNotAllowed, isNextScheduledInstance,
} from 'data/transactions/utils';
import {
  APP_STRING_SPLIT,
  APP_STRING_NO_PAYEE,
} from 'utils/constants';

const defaultMenuOption = [{ label: 'Go to Register', value: 'REGISTER' }];

const schTxnsIsNextMenuOption = [
  { label: 'Enter this reminder', value: 'ACCEPT' },
  { label: 'Ignore this reminder', value: 'SKIP' },
  { label: 'Go to Bills', value: 'BILLS' },
];

const schTxnsMenuOption = [
  { label: 'Enter this and all previous overdue reminders for this Payee as Paid', value: 'ACCEPT' },
  { label: 'Ignore this and all previous overdue reminders for this Payee', value: 'SKIP' },
  { label: 'Go to Bills', value: 'BILLS' },
];

const schTxnsGrossPaycheckMenuOption = [
  { label: 'Go to Bills', value: 'BILLS' },
];

interface TableTxnRowProps {
  columns: Record<string, any>;
  classes: Record<string, string>;
  txn: transactionsTypes.CashFlowTransaction;
  onGoToRegister: (txn: transactionsTypes.CashFlowTransaction) => void;
  onGoToBills: (stModelId: string) => void;
}

interface MenuOption { label: string; value: string; }

const TableTxnRow: React.FC<TableTxnRowProps> = (props) => {
  const { columns, classes, txn, onGoToRegister, onGoToBills } = props;

  const incomeCOAIds = useSelector(categoriesSelectors.getIncomeCOAIds);
  const accountsById = useSelector(accountsSelectors.getAccountsById);
  const transactionsByAccountId: ImmutableMap<string, transactionsTypes.CashFlowTransaction> = useSelector(getTransactionsByAccountId);
  const accountTransactions = transactionsByAccountId?.get(txn.accountId);
  const scheduledTransactions = useSelector(scheduledTransactionsSelectors.getScheduledTransactions);
  const category = useSelector((state: RootState) =>
    txn.coa && chartOfAccountsSelectors.getCoaStringSelector(state, txn.coa, true));

  const { qDataPerformTransactionAction } = useQData('calendarTxnModal_TxPage');

  const type = useMemo(() => getCalendarTxnType(txn, incomeCOAIds), [txn, incomeCOAIds]);
  const recurringStatus = useMemo(() => getTxnStateInfo(txn), [txn]);
  const isUnacceptedSchTxn = useMemo(() => isUnacceptedScheduledTxn(txn), [txn]);
  const nextInstance = useMemo(() => accountTransactions && txn.stModelId &&
    nextScheduledInstanceAfterDate(DateTime.now().toISODate(), accountTransactions, txn.stModelId),
  [accountTransactions, txn]);
  const isNext = useMemo(() => nextInstance && nextInstance.id === txn.id, [nextInstance, txn]);
  const isUpcoming = isNextScheduledInstance(txn, scheduledTransactions);

  let menuOptions : MenuOption[] | null = null;
  let reminderIconClass: string | null = null;
  let qtipTitle: string | null = null;

  const scheduledTransactionsById = useSelector(scheduledTransactionsSelectors.getScheduledTransactions);
  const stRecord = scheduledTransactionsById.find(
    (item) => item.id === txn.stModelId,
  );
  const isTypeGrossPaycheck = Boolean(stRecord?.type === 'GROSS_PAYCHECK');

  if (isTypeGrossPaycheck) {
    menuOptions = schTxnsGrossPaycheckMenuOption;
  } else if (isUnacceptedSchTxn) {
    // if this scheduled instance contains transfers to unsynced accounts, then do not allow the user to act
    menuOptions = !transactionEditsNotAllowed(txn) && isUpcoming ? schTxnsIsNextMenuOption : schTxnsMenuOption;
  }

  // @ts-expect-error Property 'calTxnLabelTypes' does not exist on type 'typeof transactionsTypes'.
  const { income, expense, overdue, normal } = transactionsTypes.calTxnLabelTypes;

  switch (type) {
    case income:
    case expense:
      if (recurringStatus.status === 'DUE') {
        qtipTitle = 'This reminder is due';
        reminderIconClass = classes.dueType;
      } else {
        if (isNext) {
          qtipTitle = 'This will be the next instance that becomes due';
        } else {
          qtipTitle = 'This reminder is not yet due';
          menuOptions = null;
        }
        reminderIconClass = classes.notDue;
      }
      break;
    case overdue:
      qtipTitle = 'This reminder is overdue';
      reminderIconClass = classes.overdueType;
      break;
    case normal:
    default:
      reminderIconClass = null;
  }

  if (reminderIconClass && category === '[Unsynced Account]') {
    menuOptions = schTxnsGrossPaycheckMenuOption;
  }

  const onMenuChange = useCallback((action: string) => {
    if (action === 'BILLS') {
      onGoToBills(txn.stModelId);
      return;
    }
    if (action === 'REGISTER') {
      onGoToRegister(txn);
      return;
    }

    const actionObj = {
      id: txn.id,
      action,
      overdueAction: action,
      instanceDate: txn.stDueOn,
    };
    qDataPerformTransactionAction(actionObj);
  }, [onGoToBills, onGoToRegister, txn, qDataPerformTransactionAction]);

  return (
    <TableRow className={classes.tableBodyRow}>
      {Object.keys(columns).map((key) => {
        let cell: ReactNode | null = null;

        switch (key) {
          case 'reminderCol':
            cell = (
              <div className={classes.reminderColContainer}>
                {reminderIconClass && (
                  <QTip
                    title={qtipTitle}
                    wrapId={`qtip-${txn.id}-recurring`}
                  >
                    <AlarmOn className={classNames(classes.reminderIcon, reminderIconClass)} />
                  </QTip>
                )}
              </div>
            );
            break;
          case 'payeeCol':
            cell = <div className={classes.payeeColContainer}>{txn.payee || APP_STRING_NO_PAYEE}</div>;
            break;
          case 'accountCol':
            cell = <div className={classes.accountColContainer}>{accountsById?.get(txn.accountId)?.name}</div>;
            break;
          case 'categoryCol':
            cell = (
              <div className={classes.categoryColContainer}>
                {txn.split ? APP_STRING_SPLIT : category}
              </div>
            );
            break;
          case 'amountCol':
            cell = <div className={classes.amountColContainer}>{formatAmount(txn.amount)}</div>;
            break;
          case 'optionsCol': {
            const customTrigger = (
              <ExpandMoreIcon className={classes.expandIcon} />
            );
            cell = (
              <div className={classes.optionsColContainer}>
                <QMenu
                  customTrigger={customTrigger}
                  name={`qmenu-${txn.id}-recurring`}
                  title={qtipTitle}
                  menuIconButtonSize="small"
                  options={menuOptions || defaultMenuOption}
                  onChange={onMenuChange}
                  customTriggerClass={classes.expandIconRoot}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                />
              </div>
            );
          }
            break;
          default: break;
        }

        return (
          <TableCell key={`cell-${txn.id}-${key}`} className={classNames(classes.tableBodyCell, classes?.[key])}>
            {cell}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default React.memo(TableTxnRow);
