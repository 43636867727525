// @flow
import { createSelector } from 'reselect';

import { List } from 'immutable';

import { REDUCER_KEY } from './reducer';
import type { InstitutionsStore } from './types';

export const getInstitutionStore = (state: any): InstitutionsStore => state[REDUCER_KEY];
export const getInstitutionsById = (state: any) => getInstitutionStore(state).resourcesById;
export const getPopularInstitutionIds = (state: any) => getInstitutionStore(state).popularIds;

export const getInstitution = (state: any, id: string) => {
  const institutionsById = getInstitutionsById(state);
  return institutionsById.get(id);
};

export const getPopularInstitutions = createSelector(
  getInstitutionsById,
  getPopularInstitutionIds,
  (institutionsById, popularIds) => {
    if (!popularIds) {
      return null;
    }
    const popularInstitutions = popularIds.reduce((list, id) => {
      const institution = institutionsById.get(id);
      if (institution) {
        list.push(institution);
      }
      return list;
    }, []);
    if (popularInstitutions.length === 0) {
      return null;
    }
    return List(popularInstitutions);
  }
);
