import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { authActions } from 'companion-app-components/flux/auth';

class Logout extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  UNSAFE_componentWillMount() {
    this.props.authLogout({ reason: 'AUTH_LOGOUT_ROUTE' }, { context: 'url' });
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  authLogout: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    authLogout: (data) => dispatch(authActions.authLogout(data)),
  };
}

export default connect(null, mapDispatchToProps)(Logout);
