//-------------------------------------------------------
// Render Tx Footer
//
// Pagination uses: https://github.com/AdeleD/react-paginate
//
import React from 'react';
import PropTypes from 'prop-types';
import QTip from 'components/QuickenControls/QTip';
import { Line } from 'rc-progress';
import isAcme from 'isAcme';

export const TxFooter = (props) => {
  const { theme } = props;

  if (props.hideFooter || isAcme) {
    return (
      <div
        style={{ height: 2, overflow: 'hidden' }}
        ref={props.footerRef}
      />
    );
  }

  const metrics = props.transactionListMetrics;

  const txnReviewedPercentage = ((metrics.reviewedCount + 1) / (metrics.numTxns + 1)) * 100;
  let gradeText;
  let strokeColor;

  switch (true) {

    case (txnReviewedPercentage < 25):
      gradeText = 'You have some work to do';
      strokeColor = theme.palette.personalDark;
      break;

    case (txnReviewedPercentage < 50):
      gradeText = 'You are making good progress';
      strokeColor = theme.palette.caringDark;
      break;

    case (txnReviewedPercentage < 75):
      gradeText = 'Over halfway there, keep reviewing!';
      strokeColor = theme.palette.optimisticDark;
      break;

    case (txnReviewedPercentage < 100):
      gradeText = 'Just about reviewed them all, bring it home!';
      strokeColor = theme.palette.positiveLight;
      break;

    case (txnReviewedPercentage >= 100):
      gradeText = 'All transactions reviewed, great work!';
      strokeColor = theme.palette.positiveDark;
      break;

    default:
      gradeText = 'Error';
      strokeColor = theme.palette.groundedDark;
  }

  return (
    <div
      className={props.classes.txFooter}
      ref={props.footerRef}
    >
      {!props.hideSearch && (metrics.numTxns > 0) &&
        <QTip title={`Transactions marked reviewed, (${metrics.reviewedCount}/${metrics.numTxns}) ${gradeText}`}>
          <Line
            className={props.classes.progressBar}
            percent={txnReviewedPercentage}
            strokeWidth={2}
            trailWidth={2}
            strokeColor={strokeColor}
          >
          </Line>
        </QTip>}
    </div>
  );
};

TxFooter.propTypes = {
  hideSearch: PropTypes.bool,
  hideFooter: PropTypes.bool,
  footerRef: PropTypes.func.isRequired,
  classes: PropTypes.object,
  transactionListMetrics: PropTypes.object,
  theme: PropTypes.object,
};

