import React from 'react';
import { DateTime } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';

import { datasetsSelectors } from 'companion-app-components/flux/datasets';
import { authActions, authTypes } from 'companion-app-components/flux/auth';

import DialogContent from '@mui/material/DialogContent';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import StdDialog from 'components/Dialogs/StdDialog';

const SelectDatasetDialog = (props) => {
  const { ...otherProps } = props;

  const dispatch = useDispatch();
  const datasets = useSelector(datasetsSelectors.getActiveSortedDatasets);

  return (
    <StdDialog
      title="Select Finances"
      showCloseButton={false}
      sharedcomponentid={'SELECT_DATASET_DIALOG'}
      {...otherProps}
    >
      <DialogContent>
        <List>
          {datasets.toIndexedSeq().map((dataset) => (
            <ListItem
              key={dataset.id}
              button
              onClick={() => dispatch(authActions.authSelectDataset(authTypes.AuthSelectDatasetPayload({ dataset })))}
            >
              <ListItemText
                primary={dataset.name}
                secondary={DateTime.fromISO(dataset.lastSyncedAt).isValid
                && `last used ${DateTime.fromISO(dataset.lastSyncedAt).toLocaleString(DateTime.DATETIME_MED)}`}
              />
            </ListItem>
          ))}
        </List>

      </DialogContent>
    </StdDialog>
  );
};

export default SelectDatasetDialog;
