import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Map as ImmutableMap } from 'immutable';

export const DIALOG_TYPE = 'INDIVIDUAL_CATEGORY_ANNUAL_BUDGET';

export const showIndividualCategoryAnnualBudget = createAction(
  createDialog.toString(),
  (budgetId, categoryId, clickedMonthDate, allCategoryIdsOfBudget, isEverythingElse, displayLabel) => mkRootUiData({
    type: DIALOG_TYPE,
    allowNesting: true,
    props: ImmutableMap({
      budgetId,
      categoryId,
      clickedMonthDate,
      allCategoryIdsOfBudget,
      isEverythingElse,
      displayLabel,
    }),
  })
);
