import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { authActions, authSelectors, authTypes } from 'companion-app-components/flux/auth';
import { datasetsSelectors } from 'companion-app-components/flux/datasets';

import { setDatasetShareAcceptData, mkDatasetShareAcceptData } from 'data/app/helper';

interface QueryParams {
  datasetId?: string;
  granteeEmail?: string;
  granteeId?: string;
}

// Query Parameters
//   - granteeEmail
//   - new (is grantee email a new user - e.g. requires create)
//   - datasetId
//
// Case 1: User Exists
//   Case 1a: A User is Currently Signed-In and Is Same as Grantee
//     - Switch Datasets
//   Case 1b:
//     - Add sign-in variables to local-storage
//     Case 1b1: User is Signed In
//       - Logout (which will re-direct to sign-in afterwards)
//     Case 1a2: User is Not Signed In
//       - Redirect to '/' to force sign-in
// Case 2: User Needs to be Created
//   - Add create variables to local-storage
//   Case 2a: A user is Currently Signed In
//     - Logout (which will re-direct to sign-in afterwards)
//   Case 2b: A user is Not Signed In
//     - Redirect to '/' to force sign-in

const useQueryParams = (): QueryParams => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return {
    datasetId: searchParams.get('datasetId') || undefined,
    granteeEmail: searchParams.get('granteeEmail') || undefined,
    granteeId: searchParams.get('granteeId') || undefined,
  };
};

const useShareAccept = () => {
  const dispatch = useDispatch();
  const urlQuery = useQueryParams();

  const isAuthenticated = useSelector(authSelectors.isAuthenticated);
  const authQcsId = useSelector(authSelectors.getQCSId);
  const datasetsById = useSelector(datasetsSelectors.getResourcesById);

  const startNewSignInOrSignUp = useCallback(() => {
    // Note: action must cause a hard refresh to reload the AuthDialog component
    if (isAuthenticated) {
      // Case 1b1 or 2a: User is signed-in, so logout to start new sign-in process
      dispatch(authActions.authLogout({ reason: 'ACCEPT_SHARE' }, { context: 'dataset sharing' }));
    } else {
      // Case 1b2 or 2b: User is not signed-in, so just redirect to root page to start new sign-in process
      window.location.href = '/';
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    const { datasetId, granteeEmail, granteeId } = urlQuery;
    if (granteeEmail && datasetId) {
      if (granteeId) {
        // Case 1: User Exists
        if (granteeId && isAuthenticated && granteeId === authQcsId) {
          // Case 1a: A User is Currently Signed-In and Is Same as Grantee
          const sharedDataset = datasetsById.get(datasetId);
          if (sharedDataset) {
            authActions.authSelectDataset(authTypes.AuthSelectDatasetPayload({ dataset: sharedDataset }));
          } else {
            // TODO: What to do about this corner case?
          }
        } else {
          // Case 1b: User is not authenticated or user is different from authenticated user
          setDatasetShareAcceptData(mkDatasetShareAcceptData({ granteeEmail: decodeURIComponent(granteeEmail), datasetId }));
          startNewSignInOrSignUp();
        }
      } else {
        // Case 2: User needs to be created
        setDatasetShareAcceptData(mkDatasetShareAcceptData({ 
          isGranteeNewUser: true,
          granteeEmail: decodeURIComponent(granteeEmail), 
          datasetId,
        }));
        startNewSignInOrSignUp();
      }
    } else {
      // TODO: What to do about this corner case?
    }
  }, [urlQuery, datasetsById, isAuthenticated, authQcsId, startNewSignInOrSignUp]);

};

export default useShareAccept;
