// @flow
import { Record, Map as ImmutableMap } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

export const MC_RULES_REDUCER_KEY = 'mcRulesStore';

export const OperandsEnum = Object.freeze({
  PRODUCT_YEAR: 'productYear',
  CLIENT_TYPE: 'clientType',
  VERSION: 'version',
  RELEASE: 'release',
});
export type Operands = $Values<typeof OperandsEnum>; // eslint-disable-line no-undef

export const DataTypeEnum = Object.freeze({
  STRING: 'STRING',
  NUMERIC: 'NUMERIC',
  LIST: 'LIST',
});
export type DataType = $Values<typeof DataTypeEnum>; // eslint-disable-line no-undef

export const OperandTypeEnum = Object.freeze({
  PRE_DEFINED: 'PRE_DEFINED',
  USER_DEFINED: 'USER_DEFINED',
});
export type OperandType = $Values<typeof OperandTypeEnum>; // eslint-disable-line no-undef

type MCRulesOperandType = {
  id: string,
  name: string,
  displayName: string,
  dataType: DataType,
  operandType: OperandType,
};
export type MCRulesOperand = RecordOf<MCRulesOperandType>;
const mkMCRulesOperand: RecordFactory<MCRulesOperandType> = Record({
  id: undefined,
  name: undefined,
  displayName: undefined,
  dataType: undefined,
  operandType: undefined,
});

export const JobStatusEnum = Object.freeze({
  NEW: 'NEW',
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
});
export type JobStatus = $Values<typeof JobStatusEnum>; // eslint-disable-line no-undef

type MCRulesOperandsType = {
  jobId: string,
  jobStatus: JobStatus,
  operands: ?ImmutableMap<string, MCRulesOperandType>,
};
export type MCRulesOperands = RecordOf<MCRulesOperandsType>;
const mkMCRulesOperandsDefaults: RecordFactory<MCRulesOperandsType> = Record({
  jobId: undefined,
  jobStatus: undefined,
  operands: undefined,
});

export const mkMCRulesOperands = (jsObj: Object): MCRulesOperands => mkMCRulesOperandsDefaults({
  ...jsObj,
  operands: jsObj.operands && ImmutableMap(jsObj.operands.map((jsOperand) => [jsOperand.id, mkMCRulesOperand(jsOperand)])),
});

export const HookEnum = Object.freeze({
  FILE_OPEN: 'FILE_OPEN',
  OSU: 'OSU',
  CLOSE_QUICKEN: 'CLOSE_QUICKEN',
});
export type Hook = $Values<typeof HookEnum>; // eslint-disable-line no-undef

export const MessageTypeEnum = Object.freeze({
  POP_UP: 'POP_UP',
  TOAST: 'TOAST',
});
export type MessageType = $Values<typeof MessageTypeEnum>; // eslint-disable-line no-undef

export const RuleContextEnum = Object.freeze({
  USER: 'USER',
  DATASET: 'DATASET',
});
export type RuleContext = $Values<typeof RuleContextEnum>; // eslint-disable-line no-undef

type RuleGroupType = {
  id: string,
  name: string,
  displayName: string,
  canUserOptOut: boolean,
};
export type RuleGroup = RecordOf<RuleGroupType>;
const mkRuleGroup: RecordFactory<RuleGroupType> = Record({
  id: undefined,
  name: undefined,
  displayName: undefined,
  canUserOptOut: undefined,
});

type MCRuleMessageType = {
  id: string,
  messageText: ?string,
  messageUrl: ?string,
  hook: Hook,
  type: MessageType,
  contentWidth: number,
  contentHeight: number,
  reDisplayCount: number,
  canUserDismiss: boolean,
  variantName: ?string,
  ruleGroup: RuleGroup,
  ruleId: string,
  ruleName: string,
  ruleContext: RuleContext,
};
export type MCRuleMessage = RecordOf<MCRuleMessageType>;
const mkMCRuleMessageDefaults: RecordFactory<MCRuleMessageType> =
  Record({
    id: undefined,
    messageText: undefined,
    messageUrl: undefined,
    hook: undefined,
    type: undefined,
    contentWidth: undefined,
    contentHeight: undefined,
    reDisplayCount: undefined,
    canUserDismiss: undefined,
    variantName: undefined,
    ruleGroup: undefined,
    ruleId: undefined,
    ruleName: undefined,
    ruleContext: undefined,
  });
export const mkMCRuleMessage = (jsObj: Object): MCRuleMessage => mkMCRuleMessageDefaults({
  ...jsObj,
  ruleGroup: jsObj.ruleGroup && mkRuleGroup(jsObj.ruleGroup),
});

type MCMessageTrackType = {
  id: ?string,
  isRemindMeLater: ?boolean,
  reDisplayCount: number,
  ruleContext: RuleContext,
};
export type MCMessageTrack = RecordOf<MCMessageTrackType>;
export const mkMCMessageTrack: RecordFactory<MCMessageTrackType> = Record({
  id: undefined,
  isRemindMeLater: undefined,
  reDisplayCount: undefined,
  ruleContext: undefined,
});
