import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Select, MenuItem, DialogContent, DialogActions } from '@mui/material';
import cn from 'classnames';
import { DateTime } from 'luxon';

import QButton from 'components/QButton';
// import QTextButton from 'components/QTextButton';
import StdDialog from 'components/Dialogs/StdDialog';
import AmountField from 'components/QuickenControls/AmountField';

import { DIALOG_TYPE } from './actions';
import {
  updateMonthlyBudgetAmount,
  getBudgetAmountOfCurrentMonth,
  handleMonthlyItemsUpdate,
  processDataToUpdateBudgetItems,
  generateApplyOptions,
} from './utils';

const YearlyBudgetEditView = ({ classes, theme, removeDialog, annualBudgetDataOfCategory, updateBudgetItems, categoryBudgetDetails, showCalculateAverageView, directlyCloseCalculateAverageView, setHasAmountSaved, selectedMonthDate }) => {
  const [displayAvarageView, setDisplayAvarageView] = useState(showCalculateAverageView);
  const [budgetCategoryData, setBudgetCategoryData] = useState(JSON.parse(JSON.stringify(annualBudgetDataOfCategory)));
  const { displayLabel, budgetItems, negate } = budgetCategoryData;
  const { 
    // monthlyAvarage, 
    budgetEndDate, 
  } = categoryBudgetDetails;
  const [budgetAmount, setBudgetAmount] = useState(
    getBudgetAmountOfCurrentMonth(annualBudgetDataOfCategory, DateTime.fromISO(selectedMonthDate))
  );

  const applyOptions = useMemo(
    () => generateApplyOptions(selectedMonthDate, budgetEndDate),
    [selectedMonthDate, budgetEndDate]
  );
  const [applyMode, setApplyMode] = useState(applyOptions[0].value);

  const renderDialogHead = () => (
    <Typography className={classes.headingStyle}>
      {!displayAvarageView && 'Edit Yearly Budget'}
      {displayAvarageView && 'Calculate Average Budget'}
    </Typography>
  );

  const renderAllMonthView = () => (
    <>
      <div className={classes.qScrollStyle}>
        <Typography variant="subtitle1" textAlign="center" pt={1}>
          {annualBudgetDataOfCategory.displayLabel}
        </Typography>
        {/* TODO : Needed after confirmation  */}
        {/* <Typography className={classes.subHeadTextStyle}>
          Monthly average: {formatNumber(monthlyAvarage)}
        </Typography> */}
        <Typography
          variant="subtitle2"
          textAlign="center"
          sx={{ borderBottom: '1px solid #ccc', lineHeight: '0.05em', m: '20px 0 30px' }}
        >
          <span className={classes.yearText}>{`${DateTime.fromISO(budgetEndDate).toFormat('yyyy')} (FY)`}</span>
        </Typography>
        <div className={classes.flexColumn}>
          {budgetItems
            .sort((firstItem, secondItem) => DateTime.fromISO(firstItem.startDate) - DateTime.fromISO(secondItem.startDate))
            .map((monthly) => (
              <div key={DateTime.fromISO(monthly.startDate).valueOf()} className={cn(classes.flexMontlyItem, monthly.isToDate ? classes.hideMonthlyItem : undefined)}>
                <Typography className={classes.monthLabel}>
                  {DateTime.fromISO(monthly.startDate).toFormat('MMM')}
                </Typography>
                <AmountField
                  value={monthly.isIncome ? monthly.amount : monthly.amount * -1}
                  className={classes.input}
                  editable
                  hideCurrencyString
                  disableUnderline={theme.components.register.noUnderline}
                  maxChars={monthly.amount < 0 ? 10 : 9}
                  onChange={(e) => setBudgetCategoryData(handleMonthlyItemsUpdate(e.target.value, monthly.id, budgetCategoryData))}
                />
              </div>
            ))}
        </div>
        <div className={cn(classes.calcButtonWrapper, classes.textCenter)}>
          {/* TODO hide calculate average */}
          {/* <QTextButton
            title={'CALCULATE AVERAGE'}
            onClick={() => setDisplayAvarageView(true)}
            className={cn(classes.headingStyle, classes.buttonTextColor, classes.subHeadTextStyle)}
          /> */}
        </div>
      </div>
    </>
  );

  const renderAverageView = () => (
    <>
      <div className={classes.qScrollStyle}>
        <Typography className={cn(classes.headingStyle, classes.textCenter)}>
          {displayLabel}
        </Typography>
        <br />
        <div>
          <div className={classes.flexWrapper}>
            <Typography className={classes.budgetTexts}>
              Budget $
            </Typography>
            <AmountField
              value={budgetAmount}
              onChange={(e) => setBudgetAmount(e.target.value)}
              className={classes.input}
              editable
              hideCurrencyString
              maxChars={budgetAmount < 0 ? 10 : 9}
              disableUnderline={theme.components.register.noUnderline}
            />
          </div>
          <div className={classes.flexWrapper}>
            <Typography className={classes.budgetTexts}>
              Apply
            </Typography>
            <Select
              className={classes.applySelectBox}
              value={applyMode}
              onChange={({ target }) => setApplyMode(target.value)}
              variant={'outlined'}
              displayEmpty
            >
              {applyOptions.map((option) =>
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
            </Select>
          </div>
          {/* TODO : Needed after confirmation */}
          {/* <div className={classes.flexWrapper}>
            <Typography className={classes.budgetTexts}>
              Monthly Average:
            </Typography>
            <Typography>
              {formatNumber(monthlyAvarage)}
            </Typography>
          </div> */}
        </div>
      </div>
    </>
  );

  const handleOkClick = () => {
    const selectedApplyMode = DateTime.fromISO(selectedMonthDate);
    setBudgetCategoryData(updateMonthlyBudgetAmount(applyMode, budgetCategoryData, budgetAmount, selectedApplyMode));
    if (directlyCloseCalculateAverageView) {
      handleUpdateClick();
    } else {
      setDisplayAvarageView(false);
    }
  };

  const renderDialogFooter = (okHandler, cancelHandler) => (
    <>
      <QButton
        variant={'text'}
        onClick={cancelHandler}
        color="primary"
      >
        {'CANCEL'}
      </QButton>
      <QButton
        variant={'text'}
        color="primary"
        onClick={okHandler}
      >
        {'OK'}
      </QButton>
    </>
  );

  const handleUpdateClick = () => {
    const budgetItemsToUpdate = processDataToUpdateBudgetItems(budgetItems, annualBudgetDataOfCategory.budgetItems, negate);
    if (budgetItemsToUpdate.length) {
      updateBudgetItems(budgetItemsToUpdate);
      setHasAmountSaved(true);
    }
    removeDialog();
  };

  return (
    <StdDialog
      open
      onClose={removeDialog}
      title={renderDialogHead()}
      titleClasses={classes.dialogTitle}
      sharedcomponentid={DIALOG_TYPE}
    >
      <DialogContent className={classes.dialogContentStyle}>
        {!displayAvarageView && renderAllMonthView()}
        {displayAvarageView && renderAverageView()}
      </DialogContent>
      <DialogActions className={classes.actionsWrapper}>
        {!displayAvarageView && renderDialogFooter(handleUpdateClick, removeDialog)}
        {displayAvarageView && renderDialogFooter(handleOkClick, directlyCloseCalculateAverageView ? removeDialog : () => setDisplayAvarageView(false))}
      </DialogActions>
    </StdDialog>
  );
};

YearlyBudgetEditView.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
  removeDialog: PropTypes.func,
  annualBudgetDataOfCategory: PropTypes.object,
  updateBudgetItems: PropTypes.func,
  categoryBudgetDetails: PropTypes.object,
  setHasAmountSaved: PropTypes.func,
  showCalculateAverageView: PropTypes.bool,
  directlyCloseCalculateAverageView: PropTypes.bool,
  selectedMonthDate: PropTypes.string,
};

export default YearlyBudgetEditView;
