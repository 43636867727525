//
// SELECTORS, these are functions designed to work directly on and with the
// redux data store
//

import { createCachedSelector, LruObjectCache } from 're-reselect';
// import { getLogger } from 'utils/logger';
import { DateTime } from 'luxon';
import { Map as ImmutableMap } from 'immutable';

import { accountsSelectors } from 'companion-app-components/flux/accounts';

import { getTransactionsByAccountId } from 'data/transactions/selectors';
import { getBalancesByAccountId } from 'data/accountBalances/selectors';
import { transactionsSortFunction, isUnacceptedScheduledTxn } from 'data/transactions/utils';


// getReconcileInfoForAccount
//
// get a list of transactions for the given account that
// is not in the pending or reconciled state.  Also returns the balance information
// for the given account (either onlineBalance, or balance as of the provided date).
// if the provided date string is 'today', it will give the currentBalance
// if the provided date string is null, it will use the online balance and return the onlineBalanceAsOfOn date
//

export const getReconcileInfoForAccount = createCachedSelector(
  (state, props) => props.accountId,
  (state, props) => props.statementDate,
  (state, props) => props.statementEndingBalance,
  getTransactionsByAccountId,
  accountsSelectors.getAccountsById,
  getBalancesByAccountId,
  (
    accountId,
    statementDate,
    statementBalance,
    transactionsByAccountIds,
    accountsById,
    balancesById,
  ) => {

    const acctBalances = balancesById.get(accountId);
    let reconcileTxns = transactionsByAccountIds.get(accountId);
    if (!reconcileTxns) {
      return null;
    }

    reconcileTxns = reconcileTxns.toList().filter((txn) => !isUnacceptedScheduledTxn(txn));
    const account = accountsById.get(accountId);

    if (!account || !reconcileTxns || !acctBalances) {
      return null;
    }

    let statementEndingBalance = account.onlineBalance;
    let statementEndingBalanceDate = account.onlineBalanceAt;

    if (statementDate !== null) {
      statementEndingBalanceDate = statementDate;
      statementEndingBalance = statementBalance;
    }

    reconcileTxns = reconcileTxns.filter((x) => !isUnacceptedScheduledTxn(x));
    reconcileTxns = reconcileTxns.sort(transactionsSortFunction).reverse();

    let registerBalanceAtStatementDate = acctBalances.endingBalanceNoRecurring;

    // listTransactions(reconcileTxns);

    // prefer the BANK postedOn date
    reconcileTxns.forEach((x) => {
      const dateToUse = x.cpData && x.cpData.postedOn ? x.cpData.postedOn : x.postedOn;
      if (DateTime.fromISO(dateToUse) > DateTime.fromISO(statementEndingBalanceDate)) {
        registerBalanceAtStatementDate -= Number(x.amount);
      }
    });

    let registerReconciledBalance = acctBalances.endingBalanceNoRecurring;
    reconcileTxns.forEach((txn) => {
      if (txn.state !== 'RECONCILED') {
        registerReconciledBalance -= Number(txn.amount);
      }
    });

    // remove transactions after the statement date from consideration
    // for online acct, use bank posted date if it exists
    reconcileTxns = reconcileTxns.filter((txn) =>
      (txn.state === 'RECONCILED') ||
      (DateTime.fromISO(txn.cpData && txn.cpData.postedOn ? txn.cpData.postedOn : txn.postedOn) <= DateTime.fromISO(statementEndingBalanceDate)));

    let clearedBalance = registerBalanceAtStatementDate;

    reconcileTxns.forEach((txn) => {
      if (txn.state === 'PENDING') {
        clearedBalance -= Number(txn.amount);
      }
    });

    // Now remove reconciled transactions for the transactions list
    reconcileTxns = reconcileTxns.filter((txn) =>
      (txn.state !== 'RECONCILED'));

    // listTransactions(reconcileTxns);

    // we now calculate the clearedBalance, that is, all transactions that are
    // we have no choice but to assume any unsynced transactions (older) are all reconciled
    // we need the actual register balance at the statementEndDate, and start clearedBalance from there, then
    // remove uncleared transactions

    return ImmutableMap({
      reconcileTxns,
      registerReconciledBalance,
      statementEndingBalance,
      statementEndingBalanceDate,
      clearedBalance,
    });
  },
)({
  keySelector: (_, props) => `${props.accountId}-${props.statementDate ? DateTime.fromISO(props.statementDate).toFormat('yyyy-MM-dd') : 'online'}`,
  cacheObject: new LruObjectCache({ cacheSize: 5 }),
});

/*
function listTransactions(txns) {
  txns.forEach((txn) => {
    console.log(txn.postedOn, txn.cpData && txn.cpData.postedOn ? txn.cpData.postedOn : 'manual', txn.payee, txn.amount, txn.state);
  })
}
*/
