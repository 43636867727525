import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Done';
import DoneIcon from '@mui/icons-material/DoneAll';
import styled from 'styled-components';

const CheckWrap = styled.div`
  @keyframes Check {
    0% {
        transform: scale(0.6, 0.6) translateX(0);
        color: #000000;
        opacity: 0.3;
      }
      20% {
        color: #000000;
        opacity: 0.6;
      }
      100% {
        transform:scale(1, 1) translateX(5px);
        color: ${({ theme }) => theme.palette.link.main};
        opacity: 1;
      }
    }
  animation: Check 0.6s forwards;
  position: absolute;
`;

const DoneWrap = styled.div`
  opacity: 0;
  @keyframes Done {
    0% {
      transform: scale(0.6, 0.6);
      color: #000000;
      opacity: 0;
    }
    100% {
      transform:scale(1, 1);
      color: ${({ theme }) => theme.palette.link.main};
      opacity: 1;
    }
  }
  animation: Done 0.4s forwards 0.2s;
  position: absolute;
`;

class QCheck extends PureComponent {
  render() {
    const { size, style } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          height: size,
          width: size,
          ...style,
        }}
      >
        <CheckWrap>
          <CheckIcon
            style={{
              height: size,
              width: size,
            }}
          />
        </CheckWrap>
        <DoneWrap>
          <DoneIcon
            style={{
              height: size,
              width: size,
            }}
          />
        </DoneWrap>
      </div>
    );
  }
}

QCheck.propTypes = {
  style: PropTypes.object,
  size: PropTypes.number,
};

export default QCheck;
