import React, { PureComponent } from 'react';
import numeral from 'numeral';
import classNames from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';
import QTypography from 'components/MUIWrappers/QTypography';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import { noNaN, exists, isBrowser } from 'utils/utils';
import { quickenAmountToString } from 'data/transactions/utils';
import InputAdornment from '@mui/material/InputAdornment';
import QTip from 'components/QuickenControls/QTip';


const styles = (theme) => ({
  inputField: {
    fontWeight: 400,
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  red: {
    color: `${theme.palette.number.negative} !important`,
    whiteSpace: 'nowrap',
  },
  green: {
    color: `${theme.palette.number.positive} !important`,
    whiteSpace: 'nowrap',
  },
  black: {
    color: `${theme.palette.greyScaleDeprecated[0]} !important`,
    whiteSpace: 'nowrap',
  },
  white: {
    color: theme.palette.greyScaleDeprecated[7],
    whiteSpace: 'nowrap',
  },
  primary: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
  secondary: {
    color: theme.palette.secondary.main,
    whiteSpace: 'nowrap',
  },
  error: {
    color: theme.palette.primary.error,
    whiteSpace: 'nowrap',
  },
  amountadornment: {
    marginLeft: 0,
    marginRight: 4,
  },
});

export function formatNumber(val, currencySymbol = 'USD', fmtString = '0,0', showSign = ShowSignEnum.NEGATIVE_ONLY) {
  return internalFormatNumber(val, getSymbolFromCurrency(currencySymbol || 'N/A') || '', fmtString, showSign);
}

export const ShowSignEnum = Object.freeze({
  NEGATIVE_ONLY: 'negative-only',
  POSITIVE_ONLY: 'positive-only',
  NEVER: 'never',
  ALWAYS: 'always',
});

export const ShowColorEnum = Object.freeze({
  MANUAL: 'manual',
  NEGATIVE_ONLY: 'negative-only',
  POSITIVE_ONLY: 'positive-only',
  ALWAYS: 'always',
});

function internalFormatNumber(val, currencyString, fmtString, showSign = ShowSignEnum.NEGATIVE_ONLY) {
  const value = noNaN(round(Number(val)));
  let sign = '';
  switch (showSign) {
    case ShowSignEnum.POSITIVE_ONLY:
      sign = Number(value) > 0 ? '+' : '';
      break;
    case ShowSignEnum.NEVER:
      sign = '';
      break;
    case ShowSignEnum.ALWAYS:
      sign = Number(value) > 0 ? '+' : '-';
      if (Number(value) === 0) sign = '';
      break;
    default:
    case ShowSignEnum.NEGATIVE_ONLY:
      sign = Number(value) < 0 ? '-' : '';
  }
  return `${sign}${currencyString}${numeral(Math.abs(Number(value))).format(fmtString)}`;
}

export function round(value, decimals = 2) {
  const roundedValue = Math.round(Number(`${value}e${decimals}`));
  return Number(`${roundedValue}e-${decimals}`);
}

export class AmountField extends PureComponent {
  constructor(props) {
    super(props);

    const currencyString = (getSymbolFromCurrency(props.currencySymbol || 'USD') || '').trim();
    this.state = {
      currencyString: !props.hideCurrencyString ? currencyString : '',
    };
    this.proposedSign = props.proposedSign;

    const val = this.valueFromProps(props);
    this.state.value = val;
    this.initialValue = val;

    this.selection = {
      start: false,
      end: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Number.isNaN(Number(nextProps.value)) || !Number.isNaN(Number(nextProps.updateValue))) {
      let newVal = nextProps.value || nextProps.updateValue || '';

      newVal = this.validateField(String(newVal), true);

      if (Number(newVal) !== Number(this.state.value)) {
        this.setState({ value: newVal });
      }
    }
    if (nextProps.proposedSign !== this.proposedSign && !nextProps.lockInitialProposedSign) {
      this.proposedSign = nextProps.proposedSign;
    }
    if (nextProps.currencySymbol !== this.props.currencySymbol) {
      this.setState({ currencyString: (getSymbolFromCurrency(nextProps.currencySymbol || 'USD') || '').trim() });
    }
    if (nextProps.editable && nextProps.editable !== this.props.editable) {
      const val = this.valueFromProps(nextProps);
      this.setState({ value: val });
    }
  }

  componentDidUpdate() {
    if (this.textInput) {
      const { selectionStart, selectionEnd } = this.textInput;
      const update = (this.selection.start !== false && this.selection.start !== selectionStart) ||
        (this.selection.end !== false && this.selection.end !== selectionEnd);

      if (update && !isBrowser('safari')) {
        this.textInput.setSelectionRange(this.selection.start, this.selection.end);
      }
    }
  }

  valueFromProps = (props) => {
    let val = numeral(props.value || props.updateValue || '');
    if (props.editable && val === 0) {
      val = '';
    } else {
      val = val.format(`${props.showSign === ShowSignEnum.ALWAYS ? '+-' : ''}0,00.00`);
      val = this.validateField(val, true);
    }
    return val;
  };

  onBlur = (e, wasEnter) => {
    if (this.validateField(e.target.value).trim().length > 0) {
      let newVal = this.validateField(e.target.value);

      const valChanged = Number(newVal) !== Number(this.initialValue);
      const absValChanged = Math.abs(Number(newVal)) !== Math.abs(Number(this.initialValue));

      if (this.props.onBlur) {
        if (valChanged) {
          newVal = quickenAmountToString(newVal, absValChanged && (this.proposedSign || Math.sign(this.initialValue)));
        }
        e.target.value = newVal;
        this.props.onBlur(e, this.props.amountType, wasEnter);
      }

      this.setState({ value: newVal });
    } else if (e.target.value === '' && this.props.onBlur) {
      this.props.onBlur(e, this.props.amountType, wasEnter);
    }

    if (this.props.submitOnBlur && this.props.onSubmit) {
      this.props.onSubmit();
    }
  };

  setInputRef = (el) => {
    this.textInput = el;
    if (this.props.inputRef) {
      this.props.inputRef(el);
    }
  };

  setFocus = () => {
    this.textInput.focus();
  };

  handleChange = (e) => {
    if (this.textInput) {
      this.selection = {
        start: this.textInput.selectionStart,
        end: this.textInput.selectionEnd,
      };
    }

    let newVal = this.validateField(e.target.value);
    this.setState({ value: newVal });

    if (this.props.useAbsoluteValue) {
      // make negative
      newVal = `-${newVal}`;
    }

    e.target.value = newVal;

    if (this.props.onChange) {
      this.props.onChange(e, this.props.amountType);
    }
  };

  validateField = (value, noStrip = false) => {
    // only allow 2 decimals in amount field, and only 1 decimal point
    // need to give each field their own handler, and then have a default handler

    let str = '';
    let newVal = noStrip ? value : value.replace('$', '').trim();

    const legalChars = this.props.amountType === 'amount' || this.props.amountType === 'balance' ? '0123456789.-+' : '0123456789.';

    // strip out illegal characters (only numbers and decimals)
    for (let i = 0; i < newVal.length; i += 1) {
      str += legalChars.indexOf(newVal[i]) < 0 ? '' : newVal[i];
    }

    newVal = str;

    // This code will ensure there are only 2 digits to the right of the decimal
    if (str) {
      if (str.indexOf('.') >= 0 && str.length > 1) {
        if (str.length - str.indexOf('.') > 3 || value.indexOf('.') !== value.lastIndexOf('.')) {
          newVal = parseFloat(Number(str).toFixed(2)).toString();
        }
      }
    }

    return newVal;
  };

  handleFocus = (e) => {
    const numCharsToStrip = (this.state.currencyString ? this.state.currencyString.length + 1 : 0);

    const { target } = e;
    setTimeout(() => {
      if (this.props.showAmountAdornment) {
        target.select();
      } else {
        target.setSelectionRange(numCharsToStrip, 99);
      }
    }, 0);

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
    this.initialValue = this.state.value;
  };

  // deduce the string for the static value state
  renderStaticValue = (value, currencyString, omitCents, showSign) => {
    if (!exists(value) || (this.props.zeroIsBlank && noNaN(value) === 0)) {
      return <span>{'\u00A0'}</span>; // Danger, u00A0 may be removed from browser support.
    }
    return internalFormatNumber(
      value,
      `${currencyString}${(currencyString && this.props.currencyGap) ? ' ' : ''}`,
      `0,0${omitCents ? '' : '.00'}${this.props.compact ? 'a' : ''}`,
      showSign,
    );
  };

  handleKeyPress = (event) => {
    if (event.key === '.' && this.state.value.indexOf('.') >= 0) {
      // this is so that you can reset a selection with a period if a period is already selected
      const subStr = this.state.value.substring(this.textInput.selectionStart, this.textInput.selectionEnd);
      const hasPeriod = subStr.indexOf('.') >= 0;
      if (!hasPeriod) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
    if (event.key === 'Enter') {
      // if user hits Enter, treat like TAB and treat as a blur to capture the value
      const e = { target: { value: `${this.state.currencyString} ${this.state.value}` } };
      this.onBlur(e, true);

      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  };

  tooltip() {
    if (this.props.compact/* || this.props.omitCents */) {
      return internalFormatNumber(this.props.value, this.state.currencyString, '0,0.00', this.props.showSign);
    }
    return '';
  }

  render() {
    const {
      currencySymbol,
      inputRef,
      showColor,
      color,
      classes,
      editable,
      thinFont,
      lockInitialProposedSign,
      amountType,
      value,
      updateValue,
      useAbsoluteValue,
      zeroIsBlank,
      omitCents,
      currencyGap,
      className,
      proposedSign,
      disableUnderline,
      submitOnBlur,
      onSubmit,
      showAmountAdornment,
      autoFocus,
      compact,
      hideCurrencyString,
      variant,
      textFieldVariant,
      marginProp,
      label,
      maxChars,
      showSign,
      ...other
    } = this.props;

    const { currencyString } = this.state;

    const Wrap = compact ? QTip : React.Fragment;
    const wrapProps = compact ? { title: this.tooltip() } : {};

    if (editable) {
      return (
        <TextField
          {...other}
          className={classNames(classes.inputField, this.props.className)}
          sharedcomponentid={'AMOUNT_FIELD'}
          value={`${showAmountAdornment ? '' : `${currencyString}${currencyString ? ' ' : ''}`}${this.state.value}`}
          onChange={this.handleChange}
          autoFocus={autoFocus}
          label={label}
          margin={marginProp}
          onFocus={this.handleFocus}
          onBlur={this.onBlur}
          inputRef={this.setInputRef}
          onKeyDown={this.handleKeyPress}
          variant={textFieldVariant}
          InputProps={{
            ...this.props.InputProps,
            ...(disableUnderline && { disableUnderline: true }),
            inputProps: {
              className: classes.inputField,
              maxLength: maxChars || (13 + (currencyString ? currencyString.length : 0) + (showSign === ShowSignEnum.NEVER ? 0 : 1)),
            },
            startAdornment: showAmountAdornment ? (
              <InputAdornment classes={{ root: classes.amountadornment }} disableTypography position="end">
                {currencyString}
              </InputAdornment>
            ) : (
              ''
            ),
          }}
        />
      );
    }

    let colorClass;
    switch (showColor) {
      case ShowColorEnum.POSITIVE_ONLY:
        colorClass = value > 0 ? classes.green : undefined;
        break;
      case ShowColorEnum.NEGATIVE_ONLY:
        colorClass = value < 0 ? classes.red : undefined;
        break;
      case ShowColorEnum.ALWAYS:
        if (value < 0) {
          colorClass = classes.red;
        } else if (value > 0) {
          colorClass = classes.green;
        }
        break;
      case ShowColorEnum.MANUAL:
        colorClass = classes[color];
        break;
      default:
        assert(false, `unknown color mode '${showColor}'`);
    }
    return (
      <Wrap
        {...wrapProps}
      >
        <QTypography
          {...other}
          variant={variant || 'inherit'}
          component="span"
          sharedcomponentid={'AMOUNT_FIELD'}
          className={classNames(...[classes.inputField, this.props.className, ...(colorClass ? [colorClass] : [])])}
        >
          {this.renderStaticValue(value, currencyString, omitCents, showSign)}
        </QTypography>
      </Wrap>
    );
  }
}

AmountField.defaultProps = {
  amountType: 'amount',
  showColor: ShowColorEnum.MANUAL,
  textFieldVariant: 'standard',
};

AmountField.propTypes = {
  editable: PropTypes.bool,
  className: PropTypes.string, // applied to root of textField, use 'classes.inputField' if you want to style the inner input
  style: PropTypes.object,
  InputProps: PropTypes.object,
  onKeyDown: PropTypes.func,
  zeroIsBlank: PropTypes.bool,
  onChange: PropTypes.func,
  amountType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.object,
  updateValue: PropTypes.string,
  useAbsoluteValue: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
  inputRef: PropTypes.func,
  thinFont: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  currencySymbol: PropTypes.string,
  currencyGap: PropTypes.bool,
  omitCents: PropTypes.bool,
  proposedSign: PropTypes.number,
  lockInitialProposedSign: PropTypes.bool,
  showSign: PropTypes.string, // ShowSignEnum
  showColor: PropTypes.string, // ShowColorEnum
  color: PropTypes.string,
  disableUnderline: PropTypes.bool,
  submitOnBlur: PropTypes.bool,
  onSubmit: PropTypes.func,
  showAmountAdornment: PropTypes.bool,
  compact: PropTypes.bool,
  hideCurrencyString: PropTypes.bool,
  variant: PropTypes.string,
  textFieldVariant: PropTypes.string,
  marginProp: PropTypes.string,
  label: PropTypes.string,
  maxChars: PropTypes.number,
};

export default withStyles(AmountField, styles);
