
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// CUSTOM
import { clrFieldOptions } from 'components/TransactionRegister/transactionsConfig';
import ReadOnlyField from 'components/Transactions/ReadOnlyField';

const menuItems = clrFieldOptions.options.filter((option) => option.menu).map((option) =>
  <MenuItem key={option.state} value={option.state}>
    {option.label}
  </MenuItem>);


function StatusField(props) {
  const { status, onChange, className, readOnly } = props;

  const currentOption = clrFieldOptions?.options?.find((option) => option.state === status);

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  let field = (
    <FormControl className={className} margin="normal" variant="outlined">
      <InputLabel id="status-label">
        Status
      </InputLabel>
      <Select
        label="Status"
        labelId="status-label"
        id="status-select"
        value={status}
        onChange={handleChange}
        variant="outlined"
      >
        {menuItems}
      </Select>
    </FormControl>
  );

  if (readOnly) {
    field = (
      <ReadOnlyField
        label="Status"
        value={currentOption?.label || status}
        className={className}
      />
    );
  }

  return field;
}

StatusField.propTypes = {
  status: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default StatusField;
