import type { RecordFactory, RecordOf } from 'immutable';
import { Record } from 'immutable';

export type PersistStoreProps = {
  loadedPersistedDatasetData: boolean
};
export type PersistStore = RecordOf<PersistStoreProps>;
export const mkPersistStore: RecordFactory<PersistStoreProps> =
  Record({
    loadedPersistedDatasetData: false,
  });

