import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';

export const StyledButton = withStyles(Button, ({ palette }: { palette: Record<string, any> }) => ({
  root: {
    '&.MuiButton-outlined': {
      color: palette.gray6,
      WebkitTapHighlightColor: 'transparent',
      textTransform: 'none',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '1rem',
    },
    fontWeight: 400,
    border: `1px solid ${palette.gray5}`,
    '&:hover': {
      borderColor: palette.gray6,
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)({
  paddingLeft: '4px',
  paddingRight: '4px',
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledTypography = styled(Typography)({
  paddingLeft: '8px',
  paddingRight: '8px',
  textAlign: 'left',
});
