const styles = () => ({
  dialogTitle: {
    zIndex: 2,
    boxShadow: '0px 8px 8px -12px #111',
  },
  dialogContent: {
    padding: 0,
  },
  iFrame: {
    opacity: 1,
    width: '100%',
    height: '100%',
  },
});

export default styles;
