import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { profileSelectors } from 'companion-app-components/flux/profile';
import { authSelectors } from 'companion-app-components/flux/auth';
import { entitlementsSelectors } from 'companion-app-components/flux/entitlements';

import QButton from 'components/QButton';
import { safeRefIn } from 'utils/utils';

const OSLabel = {
  mac: 'Mac',
  win: 'Windows',
};

const useStyles = makeStyles()(() => ({
  downloadLink: {
    display: 'flex',
    flex: 1,
  },
}));

const DownloadButton = (props) => {
  const { classes } = useStyles(props);

  const profile = useSelector(profileSelectors.getProfile);
  const datasetObject = useSelector((state) => authSelectors.getCurrentDataset(state), shallowEqual);
  const isCanadaUser = useSelector(entitlementsSelectors.hasCanadaEntitlements);

  let isMac = datasetObject && datasetObject.platform === 'QMAC_MWF';

  if (props.overwriteOS) {
    isMac = props.overwriteOS === OSLabel.mac;
  }

  const OSBrand = OSLabel[isMac ? 'mac' : 'win'];
  const userEmail = safeRefIn(profile, ['primaryEmail', 'address']) || '';
  const macDownload = `https://download.quicken.com/mac/Quicken.dmg?email=${userEmail}`;
  const winDownload = isCanadaUser ?
    `https://download.quicken.com/windows/Quicken-Canada.exe?email=${userEmail}` :
    `https://download.quicken.com/windows/Quicken.exe?email=${userEmail}`;
  const downloadLink = isMac ? macDownload : winDownload;

  const onURLOpener = (url) => () => {
    if (props.onClick) {
      props.onClick();
    }
    window.open(url, '_blank');
  };

  return (
    <div className={classes.downloadLink}>
      <QButton
        onClick={onURLOpener(downloadLink)}
        variant="contained"
      >
        DOWNLOAD QUICKEN {OSBrand}
      </QButton>
    </div>
  );
};


DownloadButton.propTypes = {
  overwriteOS: PropTypes.string,
  onClick: PropTypes.object,
};

export default DownloadButton;
