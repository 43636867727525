// @flow
import React from 'react';

import { createAction } from 'redux-actions';
import { Map as ImmutableMap } from 'immutable';

import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { DIALOG_TYPE as DIALOG_CONFIRMATION, mkConfirmationDialogProps } from 'components/Dialogs/ConfirmationDialog/types';

import ContentView from './ContentView';

export const createErrorDialog = createAction(
  createDialog.toString(),
  (error) => mkRootUiData({
    type: DIALOG_CONFIRMATION,
    allowNesting: false,
    props: ImmutableMap(mkConfirmationDialogProps({
      title: error.title,
      content: (<ContentView error={error} />),
      confirmLabel: error.actionLabel,
      confirmAction: error.action,
      unregisterContentViewOnClose: true,
    })),
  })
);

