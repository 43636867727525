import * as React from 'react';
import { useMemo } from 'react';
import { useRendersCount, usePrevious } from 'react-use';
import { makeStyles } from 'tss-react/mui';

import Badge from '@mui/material/Badge';

import { localPreferences } from 'companion-app-components/utils/core';

const useStyles = makeStyles()(() => ({
  badge: {
    opacity: 0.3,
    marginLeft: 15,
  },
  root: {
    width: '-webkit-fill-available',
    position: 'absolute',
  },
}));

const rendersTotals = {};

interface RendersCountProps {
  id: string;
  children?: any;
  anchorOrigin?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' };
  classes?: Record<string, string>;
}

const RendersCount: React.FC<RendersCountProps> = (props) => {
//////////////////////////////////////////////
  const { children, id, anchorOrigin, classes: restClasses, ...rest } = props;
  const { classes } = useStyles();
  const rendersCount = useRendersCount();
  const rendersCountPrev = usePrevious(rendersCount);
  const rendersTotal = useMemo(() => {
    if (id) {
      const rendersDelta = rendersCount - (rendersCountPrev ?? 0);
      rendersTotals[id] = (rendersTotals[id] ?? 0) + rendersDelta;
    }
    return rendersTotals[id];
  }, [rendersCount, rendersCountPrev, id]);

  const shouldDisplayBadge = localPreferences.getItem('render-count') ?? true;

  if (shouldDisplayBadge) {
    return (
      <Badge
        badgeContent={rendersTotal ? `${rendersCount} (${rendersTotal})` : rendersCount}
        color="primary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
          ...anchorOrigin,
        }}
        classes={{ badge: classes.badge, root: classes.root, ...restClasses }}
        {...rest}
      >
        {children}
      </Badge>
    );
  }
///////////
  return props.children;
};

export default RendersCount;
