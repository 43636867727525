
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/InfoRounded';

import QPopper from 'components/QPopper';

const useStyles = makeStyles()((theme) => ({
  infoIcon: {
    color: theme.palette.infoIcon,
    marginLeft: 8,
    '&:hover': {
      dropShadow: theme.shadows[2],
    },
  },
  labelRoot: {
    marginRight: 0,
  },
  popup: {
    backgroundColor: theme.palette.greyScaleDeprecated[0],
    color: theme.palette.greyScaleDeprecated[7],
    width: 290,
  },
  whiteText: {
    color: theme.palette.text.lightContrast,
  },
  greenCheck: {
    color: `${theme.palette.number.positive} !important`,
  },
}));


function ReviewField(props) {
  const { classes, cx } = useStyles();
  const { value, onChange, className } = props;

  const [infoRef, setInfoRef] = useState(null);

  const handleChange = () => {
    onChange?.(!value);
  };

  const toggleInfo = (e) => {
    // console.log('event', e);
    e?.preventDefault();
    if (infoRef) {
      setInfoRef(null);
    } else if (e) {
      setInfoRef(e.target);
    }
  };


  return (
    <>
      <FormControlLabel
        control={<Checkbox classes={{ checked: classes.greenCheck }} />}
        label={
          <>
            Reviewed
            <InfoIcon
              onClick={toggleInfo}
              className={classes.infoIcon}
            />
          </>
        }
        labelPlacement="end"
        onChange={handleChange}
        checked={Boolean(value)}
        classes={{ root: cx(classes.labelRoot, className) }}
        id="review-toggle"
      />

      <QPopper
        anchorEl={infoRef}
        open={Boolean(infoRef)}
        placement="bottom"
        className={classes.popup}
        onClickAway={toggleInfo}
        dark
      >
        <Typography variant="body2" className={classes.whiteText}>
          Mark transactions as reviewed that you recognize and are set to the right category.
          (This is done automatically when you change categories or manually enter a transaction.)
        </Typography>
        <br />
        <Typography variant="body2" className={classes.whiteText}>
          This feature is turned on or off with the Reviewed column on the transaction table.
        </Typography>
      </QPopper>
    </>
  );
}

ReviewField.defaultProps = {
};

ReviewField.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default memo(ReviewField);
