export const checkAndTriggerWhatsNewDialog = ({
  isUserPrefLoading,
  userPreferences,
  setUserPreference,
  currentVersion,
  hasWhatsNewJSON,
  showReleaseNotes,
  dispatchShowReleaseNotesDialog,
  dispatchShowWhatsNewDialog,
}) => {
  if (isUserPrefLoading === false && userPreferences?.whatsNewViewed !== currentVersion) {
    if (showReleaseNotes && hasWhatsNewJSON === false) {
      dispatchShowReleaseNotesDialog();
    } else {
      dispatchShowWhatsNewDialog();
    }
    setUserPreference({ whatsNewViewed: currentVersion });
  }
};
