import { DateTime, Interval } from 'luxon';

import { APP_STRING_NOT_APPLICABLE } from 'utils/constants';

export const getOwnerName = (grantorFirstName?: string, grantorLastName?: string) => {
  if (!grantorFirstName && !grantorLastName) {
    return APP_STRING_NOT_APPLICABLE;
  }
  const firstName = grantorFirstName || '';
  const lastName = grantorLastName || '';

  return `${firstName} ${lastName}`;
};

export const formatLastSyncedDate = (date: string) => {
  const originalDateTime = DateTime.fromISO(date);
  return originalDateTime.toFormat('MM/dd/yy, HH:mm');
};

export const checkIfInviteExpired = (date?: string) => {
  if (!date) { return false; }
  
  const dtNow = DateTime.local();
  const dtLastSent = DateTime.fromISO(date);
  const diff = Interval.fromDateTimes(dtLastSent, dtNow);
  const diffHours = diff.length('hours');
  if (diffHours > 24) {
    return true;
  }
  return false;
};

export const checkIfWebfirstDataset = (platform?: string) => 
  platform ? ['QWIN_MWF', 'QMAC_MWF'].includes(platform) : false;
