import { greyScale } from 'themes/themeQuicken';

const styles = (theme) => ({
  headerSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  headerSection: {
    backgroundColor: greyScale[7],
  },
  labelSpace: {
    fontWeight: 500,
    letterSpacing: 2,
  },
  amtTxt: {
    textAlign: 'left',
  },
  datePickerWrapper: {
    maxWidth: 170,
    '& > div:first-child > div:first-child': {
      display: 'block',
      '& > div:first-child': {
        display: 'block',
      },
    },
  },
  categories: {
    inputField: {
      '& input': {
        '&::-ms-clear ': {
          display: 'none',
        },
      },
    },
  },
  tags: {
    paddingBottom: 4,
  },
  buttonsSection: {
    background: greyScale[7],
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    bottom: 0,
    padding: theme.spacing(2, 0),
    margin: theme.spacing(0, 2.5),
    position: 'sticky',
  },
  formSection: {
    padding: 20,
  },
  fatButton: {
    width: 160,
    height: 36,
    backgroundColor: theme.palette.secondary.main,
    color: greyScale[7],
    borderRadius: 20,
    marginRight: 10,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  dialogFatButton: {
    width: 116,
    height: 36,
    backgroundColor: theme.palette.secondary.main,
    color: greyScale[7],
    borderRadius: 20,
    marginRight: 10,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  disabledSave: {
    color: `${greyScale[7]} !important`,
    opacity: 0.5,
  },
  cancelButton: {
    marginLeft: 10,
  },
  underlineOverride: {
    '&:before': {
      borderBottom: `1px solid ${greyScale[5]}`,
    },
    '&&&&:hover:before': {
      borderBottomWidth: '1px',
    },
  },
  inputRight: {
    padding: '0 0 0 12px',
  },
  inputLeft: {
    padding: '0 12px 0 0',
  },
  payeeInput: {
    fontSize: 20,
    fontWeight: 500,
  },
  ellipser: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  defaultOverride: {
    color: greyScale[0],
    cursor: 'default',
    borderBottom: `0.5px solid ${greyScale[5]}`,
  },
  mainSplitTxnsContainer: {
    backgroundColor: greyScale[6],
    padding: 10,
  },
  discardText: {
    color: greyScale[2],
  },
});

export default styles;
