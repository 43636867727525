// CORE
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { boxShadowsToDropShadows } from 'themes/themeBase';
import QButton from 'components/QButton';

const arrowSize = 12;
const useStyles = makeStyles()((theme) => ({
  popper: {
    zIndex: theme.zIndex.modal,
    filter: boxShadowsToDropShadows(theme.shadows[16]),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.lightContrast,
    borderRadius: theme.shape.borderRadius * 4,
    width: 450,
    padding: theme.spacing(2),
    '&[data-popper-placement*="left"] .arrow': {
      right: 0,
      marginRight: (-2 * arrowSize),
      marginTop: theme.shape.borderRadius,
      marginBottom: theme.shape.borderRadius,
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '&[data-popper-placement*="top"] .arrow': {
      bottom: 0,
      marginBottom: (-2 * arrowSize) + 1,
      marginLeft: theme.shape.borderRadius,
      marginRight: theme.shape.borderRadius,
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '&[data-popper-placement*="right"] .arrow': {
      left: 0,
      marginLeft: (-2 * arrowSize),
      marginTop: theme.shape.borderRadius,
      marginBottom: theme.shape.borderRadius,
      borderLeftColor: 'transparent',
      borderTopColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '&[data-popper-placement*="bottom"] .arrow': {
      top: 0,
      marginTop: (-2 * arrowSize) + 1,
      marginLeft: theme.shape.borderRadius,
      marginRight: theme.shape.borderRadius,
      borderLeftColor: 'transparent',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
    },
  },
  arrow: {
    zIndex: theme.zIndex.tooltip,
    position: 'absolute',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: arrowSize,
  },
  button: {
    color: theme.palette.text.lightContrast,
    borderColor: theme.palette.text.lightContrast,
  },
}));

const GuidedTooltip = (props) => {
  const { onClickAway, anchorEl, open, className, style, modifiers, placement, title, body, cancelLabel, continueLabel, onCancel, onContinue, noArrow, ...otherProps } = props;
  const { classes, cx } = useStyles();

  const [arrowRef, setArrowRef] = useState();

  const content = useMemo(() => (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      {!noArrow &&
      <div
        className={cx(classes.arrow, 'arrow')}
        ref={(ref) => setArrowRef(ref)}
      />}
      <Typography variant="subtitle2" color="inherit">
        {title}
      </Typography>
      <Typography variant="body2" color="inherit">
        {body}
      </Typography>
      <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
        {
          cancelLabel &&
          <QButton className={classes.button} onClick={onCancel}>
            {cancelLabel}
          </QButton>
        }
        {
          continueLabel &&
          <QButton className={classes.button} variant="outlined" onClick={onContinue}>
            {continueLabel}
          </QButton>
        }
      </Box>
    </Box>
  ), [noArrow, body, cancelLabel, classes, continueLabel, onCancel, onContinue, title, cx]);

  return anchorEl && open ? (
    <Popper
      className={classes.popper}
      keepMounted={false}
      sharedcomponentid={'GUIDED_TOOLTIP'}
      placement={placement}
      open={open}
      anchorEl={anchorEl}
      modifiers={[{
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'right', 'bottom', 'left'],
        },
      }, {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      }]}
      {...otherProps}
    >
      {onClickAway ?
        <ClickAwayListener
          onClickAway={onClickAway}
        >
          {content}
        </ClickAwayListener>
        :
        content}
    </Popper>
  ) : null;
};
GuidedTooltip.defaultProps = {
  open: false,
  anchorEl: null,
};
GuidedTooltip.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  modifiers: PropTypes.object,
  placement: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top']),
  title: PropTypes.string,
  body: PropTypes.string,
  cancelLabel: PropTypes.string,
  continueLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  onClickAway: PropTypes.func,
  noArrow: PropTypes.bool,
};

export default GuidedTooltip;
