import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createGlobalState } from 'react-use';

import { getBuildConfig } from 'companion-app-components/utils/core';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

export const useGlobalCobrowseRequired = createGlobalState();

const Cobrowse = () => {
  const featureFlagsError = useSelector(featureFlagsSelectors.getError);
  const featureFlagsLoadPending = useSelector(featureFlagsSelectors.getLoadPending);
  const cobrowse = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'cobrowse'));

  const [cobrowseRequired] = useGlobalCobrowseRequired();

  useEffect(() => {
    if ((!featureFlagsLoadPending || featureFlagsError) && cobrowse && cobrowseRequired && !window.CobrowseIO) {
      /* eslint-disable */
      try {
        (function(w, t, c, p, s, e) {
          p = new Promise(function(r) {
            w[c] = {
              client: function() {
                return p
              }
            };
            s = document.createElement(t);
            s.async = 1;
            s.src = 'https://js.cobrowse.io/CobrowseIO.js';
            e = document.getElementsByTagName(t)[0];
            e.parentNode.insertBefore(s, e);
            s.onload = function() {
              r(w[c]);
            };
          });
          return p;
        })(window, 'script', 'CobrowseIO')
          .then(function() {
          try {
            window.CobrowseIO.license = getBuildConfig()?.cobrowse_license;
            window.CobrowseIO.start?.();
          } catch (e) {
            assert(false, e);
          }
        })
          .catch((error) => assert(false, error));
      } catch (error) {
        assert(false, error);
      }
      /* eslint-enable */
    }
  }, [featureFlagsLoadPending, featureFlagsError, cobrowse, cobrowseRequired]);

  // const authSession = useSelector(getAuthSession);
  // useEffect(() => {
  //   if ((!featureFlagsLoadPending || featureFlagsError) && cobrowse && isQuicken && window.CobrowseIO) {
  //     window.CobrowseIO.customData = {
  //       user_name: "Joe Blogs",
  //       user_id: authSession.qcsId,
  //       user_email: "joe@example.com",
  //     }
  //   }
  // }, [authSession.qcsId, featureFlagsLoadPending, featureFlagsError, cobrowse]);

  return null;
};

export default Cobrowse;


