import { Record } from 'immutable';

export const AccountNode = Record({
  id: null,

  depth: 0,
  isLeaf: 0,
  displayLabel: '',
  displayOrder: 0,
  expandable: true,
  svgIconId: '',
  routeTo: null,


  account: null,
  children: null,

  allAccountIds: null,
  sumCurrentAccountBalances: 0.0,
  sumEndingAccountBalances: 0.0,
  sumOnlineAccountBalances: 0.0,
  sumCPOnlineAccountBalances: 0.0,
  sumCPAvailableAccountBalances: 0.0,
  sumFinalAccountBalances: 0.0,
  sumGoalsAccountBalances: 0.0,
  currency: null,
  earliestTransactionDate: null,
  hide: false,
  type: '',
});

export const accountTypes = {
  banking: 'BANKING',
  investment: 'INVESTMENT',
  liabilities: 'LIABILITIES',
  goals: 'GOALS',
  other: 'OTHER',
  separate: 'SEPARATE',
  hidden: 'HIDDEN',
};
