import * as preferencesSelectors from 'companion-app-components/flux/preferences/selectors';

import store from 'store';
import { createSelector } from 'reselect';
import { getThemeByKey } from 'themes/themeBase';

export const getCurrentTheme = createSelector(
  (state) => (preferencesSelectors.getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] }) || {}).theme,
  (themeKey) => getThemeByKey(themeKey),
);

// don't use this method, unless you know what you are doing
export const dangerouslyGetTheme = () => {
  const state = store.getState();
  const datasetPreferences = state && preferencesSelectors.getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] });
  const themeKey = datasetPreferences && datasetPreferences.theme;
  return getThemeByKey(themeKey);
};
