import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getLogger, tracker } from 'companion-app-components/utils/core';
import { authSelectors } from 'companion-app-components/flux/auth';

import { getMixpanelUserData, getMixpanelDefaultProperties } from 'data/mixpanel/mixpanelSelectors';

const log = getLogger('utils/mixpanel.js');

const Mixpanel = () => {
  const authSession = useSelector(authSelectors.getAuthSession);
  const mixpanelUserData = useSelector(getMixpanelUserData);
  const mixpanelDefaultProps = useSelector(getMixpanelDefaultProperties);

  useEffect(() => {
    log.debug('\nmixpanel identify: \n', { userId: authSession.qcsId, mixpanelDefaultProps, mixpanelUserData });
    tracker.setDefaultProperties(mixpanelDefaultProps);
    if (authSession.qcsId) {
      tracker.identify(authSession.qcsId);
      tracker.updateProfile(mixpanelUserData);
    }
  }, [authSession.qcsId, mixpanelUserData, mixpanelDefaultProps]);

  return null;
};

export default Mixpanel;


