
// firstCard must be named'firstCard'

export const qCards = {
/*
  firstCard: {
    elementId: 'addFiButton',
    edge: 'righttop',
    width: 350,
    height: 230,
    nudge: { top: 5, left: -5 },
    title: 'Add Accounts',
    next: 'qCard2',
    content: 'Use the Add Account feature to add all of your financial accounts. This includes savings, investments, ' +
      'debts, and even cash. Tracking all of your accounts will give you the most accurate view of your finances. ' +
      'Note: You can collapse the accounts column if you need more space on the main page',
  },
 */
  firstCard: {
    elementId: 'application-header-bar',
    edge: 'right',
    width: 285,
    height: 200,
    title: 'Navigation Bar',
    content: 'From the Navigation bar, you can access all of your important pages. This includes transactions, ' +
      'spending, budgets, income, and settings. Select an icon to view the corresponding page.',
    prev: 'firstCard',
    next: 'qCard3',
  },
  qCard3: {
    elementId: 'app-main-feature-frame',
    edge: 'righttop',
    width: 350,
    height: 200,
    nudge: { top: 0, left: 70 },
    title: 'Dashboard',
    content: 'The Dashboard provides you with an overall guide to your finances. You can review such information as ' +
      'recent transactions, spending over time, and top spending categories. If you track investments, ' +
      'that information will also be displayed. ',
    prev: 'firstCard',
    next: 'qCard4',
  },
  qCard4: {
    elementId: 'full-recent-transactions-card',
    edge: 'right',
    width: 350,
    height: 185,
    title: 'Recent Transactions',
    prev: 'qCard3',
    next: 'qCard5',
    content: 'Use Recent Transactions to take a quick look at your last few expenses or deposits. It will help you ' +
      'check, at a glance, to see if expected or unexpected transactions have come through. ' +
      'Select any transaction for more information.',
  },
  qCard5: {
    elementId: 'full-spending-card',
    edge: 'left',
    width: 350,
    height: 160,
    title: 'Top Spending Categories',
    prev: 'qCard4',
    next: 'qCard6',
    content: 'Use this chart to get an overview of your spending for the month. Select any category ' +
      'to see your transactions in that category.',
  },
  qCard6: {
    elementId: 'full-spending-over-time-card',
    edge: 'right',
    width: 250,
    height: 180,
    title: 'Spending Over Time',
    prev: 'qCard5',
    content: 'This chart provides a month-by-month breakdown of your spending. Budgeting is a good way ' +
      'to keep your spending steady from month to month.',
  },
};
