// @flow
import { handleActions } from 'redux-actions';
import { List } from 'immutable';

import { resourceUpserters, resourceStoreTypes } from 'companion-app-components/flux/core';

import * as actions from './actions';

import { InstitutionsStore, mkInstitutionsStore } from './types';

const reducer = handleActions({

  [actions.getInstitution]:
    (state :InstitutionsStore) => state,

  [actions.getInstitutionResponse]: {
    next(state: InstitutionsStore, { payload }: { payload: resourceStoreTypes.QcsSyncResourcesPayload }) {
      return resourceUpserters.upsertResource(state, payload?.resources?.[0]);
    },
  },

  [actions.getPopularInstitutionsSuccess]:
    (state: InstitutionsStore, { payload }: { payload: resourceStoreTypes.QcsSyncResourcesPayload }) => {
      // have to manually set 'replaceCollection' to false
      //  because upsertResources defaults to replacing if no getLastSyncDate provided
      let newState: InstitutionsStore = resourceUpserters.upsertResources(state, payload.set('replaceCollection', false));
      newState = newState.set('popularIds', List(payload.resources.map((resource) => resource.id)));
      return newState;
    },

}, mkInstitutionsStore());

export const REDUCER_KEY = 'institutionsStore';
export default reducer;
