// Base
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import FlagIcon from '@mui/icons-material/Flag';
import ListItemIcon from '@mui/material/ListItemIcon';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// CUSTOM
import { flagFieldOptions } from 'components/TransactionRegister/transactionsConfig';

const useStyles = makeStyles()(() => ({
  flagRoot: {
    minWidth: 160,
  },
  inputRoot: {
    height: 56,
    boxSizing: 'border-box',
    borderRadius: '12px !important',
  },
}));

const menuItems = flagFieldOptions?.map((item) =>
  <MenuItem key={`flagmenu:${item.label}`} value={item.value} id={`flag-color-${item.value}`}>
    {item.value === 'NONE' ?
      'None'
      :
      <ListItemIcon>
        <FlagIcon
          style={{
            fontSize: 20,
            color: item.color,
          }}
        />
      </ListItemIcon>}
  </MenuItem>);

const FlagField = memo((props) => {
  const { value, onChange, className, label, margin, ...otherProps } = props;

  const { classes, cx } = useStyles();

  const handleChange = (e) => {
    onChange?.(e.target.value);
  };

  const labelId = 'flag-label';

  return (
    <FormControl variant="outlined" margin={margin} className={cx(classes.flagRoot, className)}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id="flag-field"
        value={value || 'NONE'}
        onChange={handleChange}
        label={label}
        classes={{ root: classes.inputRoot }}
        {...otherProps}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
});

FlagField.defaultProps = {
  label: 'Follow-up flag',
  margin: 'normal',
};

FlagField.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
};

export default FlagField;
