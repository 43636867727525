import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Map as ImmutableMap } from 'immutable';

export const DIALOG_TYPE = 'DELETE_REMINDER_DIALOG';

export const showDeleteReminderDialog = createAction(
  createDialog.toString(),
  (txn, wrapperId = '') => mkRootUiData({
    type: DIALOG_TYPE,
    allowNesting: true,
    props: ImmutableMap({
      txn,
      wrapperId,
    }),
  })
);
