import * as React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface MainNavItemProps {
  nav: Record<string, any>;
  classes: Record<string, any>;
  iconSize: number;
  selected: string;
  displayNode?: string;
  closeHeader: () => void;
  iconRef?: React.LegacyRef<HTMLSpanElement> | null;
  canOpenSubMenu?: boolean;
  handleTracking: (arg0: string) => void;
  disableClick?: boolean;
  renderSubMenuItems?: Record<string, any>[];
}

export const MainNavItem = React.forwardRef<HTMLSpanElement, MainNavItemProps>((props, ref) => {
  const {
    nav,
    classes,
    selected,
    displayNode,
    iconSize,
    closeHeader,
    iconRef,
    canOpenSubMenu,
    handleTracking,
    disableClick = false,
    renderSubMenuItems = null,
  } = props;
  const IconComponent = nav.img;
  const aId = nav.id || `nav-menu-${(nav.title || 'default')}`?.toLowerCase().replace(' ', '-').replace('/', '');
  let { path } = nav;
  const cutSelected = (selected) ? `/${selected.split('/')[1]}` : '';
  const hasSubMenu = nav?.subMenuItems?.length >= 1;

  if (nav.useSelectedAccount) {
    path = `${path}?displayNode=${displayNode}`;
  }

  const subMenuShown = React.useRef(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleParentHover = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const onMenuItemClick = () => {
    handleTracking(path);
    setTimeout(() => {
      subMenuShown.current = false;
      setAnchorEl(null);
      closeHeader();
    }, 500);
  };

  const onLeaveParentMenu = () => {
    setTimeout(() => {
      if (!subMenuShown.current) {
        setAnchorEl(null);
      }
    }, 100);
  };

  const onSubMenuMouseOver = () => {
    subMenuShown.current = true;
  };

  return (
    <>
      <ButtonBase
        onClick={onMenuItemClick}
        className={classNames(classes.navMenuItem, cutSelected === nav.path && classes.selectedItem)}// classes.navButton}
        to={disableClick ? null : path}
        // @ts-expect-error: No overload matches this call.
        component={disableClick ? null : NavLink}
        id={aId}
        ref={ref}
        onMouseEnter={!hasSubMenu ? null : handleParentHover}
        onMouseLeave={!hasSubMenu ? null : onLeaveParentMenu}
      >
        {/* Below will keep the full nav item w/ darker background color when selected */}
        <span ref={iconRef}>
          <IconComponent
            title={nav.alt}
            fill="currentColor"
            height={iconSize}
            width={iconSize}
          />
        </span>
        <Typography
          style={{ marginLeft: 24, textAlign: 'left' }}
          className={cutSelected === nav.path ? classes.selectedNavText : classes.navText}
        >
          {nav.title}
          {hasSubMenu && <ChevronRightIcon className={classes.mainNavChevronRightIcon} />}
        </Typography>
      </ButtonBase>
      {hasSubMenu && canOpenSubMenu && (
        <Menu
          id="main-nav-sub-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onMenuItemClick}
          MenuListProps={{ onMouseLeave: onMenuItemClick, onMouseEnter: onSubMenuMouseOver }}
          autoFocus={false}
          className={classes.mainNavSubMenu}
        >
          {renderSubMenuItems || nav.subMenuItems.map((subMenuItem) => (
            <MenuItem
              key={subMenuItem.title}
              onClick={onMenuItemClick}
              to={subMenuItem.path}
              component={NavLink}
            >
              {subMenuItem.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
});
