import * as React from 'react';
import { FC, forwardRef, useState, useRef, useImperativeHandle, ReactNode } from 'react';
import Menu from '@mui/material/Menu';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import IconMenuItem from './IconMenuItem';

interface NestedMenuItemProps {
  parentMenuOpen: boolean;
  label: string;
  rightIcon: ReactNode;
  leftIcon?: ReactNode;
  children: ReactNode;
  className: string;
  tabIndex: number;
  ContainerProps?: Record<string, any>;
  MenuProps: Record<string, any>;
  MenuItemProps: Record<string, any>;
  disabled: boolean;
}

const NestedMenuItem: FC<NestedMenuItemProps> = forwardRef<HTMLLIElement, NestedMenuItemProps >(({
  parentMenuOpen,
  label,
  rightIcon = <ArrowRightIcon />,
  leftIcon = null,
  children,
  className,
  tabIndex: tabIndexProp,
  ContainerProps: ContainerPropsProp = {},
  MenuProps,
  disabled = false,
  ...MenuItemProps
}, ref) => {
  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;
  const menuItemRef = useRef<HTMLLIElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TODO: fix later
  useImperativeHandle(ref, () => menuItemRef.current);
  const containerRef = useRef<HTMLDivElement | null>(null);
  useImperativeHandle(containerRefProp, () => containerRef.current);
  const menuContainerRef = useRef<HTMLDivElement | null>(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = (e) => {
    setIsSubMenuOpen(true);
    ContainerProps?.onMouseEnter?.(e);
  };

  const handleMouseLeave = (e) => {
    setIsSubMenuOpen(false);
    ContainerProps?.onMouseLeave?.(e);
  };

  // Check if any immediate children are active
  const isSubmenuFocused = () => {
    if (menuContainerRef?.current?.children) {
      const active = containerRef.current?.ownerDocument.activeElement ?? null;
      // eslint-disable-next-line no-restricted-syntax
      for (const child of menuContainerRef.current.children) {
        if (child === active) { return true; }
      }
    }
    return false;
  };

  const handleFocus = (e) => {
    if (e.target === containerRef.current) {
      setIsSubMenuOpen(true);
    }
    ContainerProps?.onFocus?.(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') { return; }

    if (isSubmenuFocused()) { e.stopPropagation(); }

    const active = containerRef.current?.ownerDocument.activeElement;
    if (e.key === 'ArrowLeft' && isSubmenuFocused()) {
      containerRef.current?.focus();
    }

    if (
      e.key === 'ArrowRight' &&
      e.target === containerRef.current &&
      e.target === active
    ) {
      const firstChild = menuContainerRef.current?.children[0] as HTMLElement | null;
      firstChild?.focus();
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;
  // Root element must have a `tabIndex` attribute for keyboard navigation
  let tabIndex;
  if (!disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <div
      {...ContainerProps}
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
      role="menu"
    >
      <IconMenuItem
        MenuItemProps={MenuItemProps}
        className={className}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: TODO: fix later
        ref={menuItemRef}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        label={label}
      />

      <Menu
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        style={{ pointerEvents: 'none' }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 310 }}
        elevation={4}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
        {...MenuProps}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </Menu>
    </div>
  );
});

export default NestedMenuItem;
