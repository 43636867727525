import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import ThreeStateOption from 'components/ThreeStateOption';
import QTextButton from 'components/QTextButton';

import { isAcme } from 'isAcme';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const SHOW_BILLS_SUBSCRIPTION_FILTER = false;

const AdvancedPane = (props) => {

  const { advancedItems, onChange, classes } = props;

  const handleChange = (key, value) => {
    onChange(advancedItems.set(key, value));
  };

  const handleClearBillsAndSubscriptions = useCallback(() => {
    onChange(advancedItems.set('isBillOrSubscription', undefined));
  }, [onChange, advancedItems]);

  const handleClearTransactions = useCallback(() => {
    let updatedItems = advancedItems.set('isExcludedFromReports', undefined);
    updatedItems = updatedItems.set('isExcludedFromF2S', undefined);
    updatedItems = updatedItems.set('isReviewed', undefined);
    onChange(updatedItems);
  }, [advancedItems, onChange]);

  const showBillsClear = useMemo(() => advancedItems.get('isBillOrSubscription') !== undefined, [advancedItems]);
  const showTransactionsClear = useMemo(() => advancedItems.get('isExcludedFromReports') !== undefined ||
    advancedItems.get('isExcludedFromF2S') !== undefined || advancedItems.get('isReviewed') !== undefined, [advancedItems]);

  return (
    <div className={classes.advancedContainer}>
      <Typography className={classes.advancedHeader} variant={'caption'}>Show only</Typography>
      {SHOW_BILLS_SUBSCRIPTION_FILTER && 
      <div>
        <div className={classes.advancedSubtitle}>
          <Typography variant={'body1'}>Bills & Subscriptions</Typography>
          {showBillsClear &&
            <QTextButton
              className={classes.advancedClearButton}
              onClick={handleClearBillsAndSubscriptions}
              title={'CLEAR'}
            />}
        </div>
        <ThreeStateOption
          onChange={(val) => handleChange('isBillOrSubscription', val)}
          value={advancedItems.isBillOrSubscription}
          onOffText="Bills"
          idPrefix={'bills_and_subscriptions'}
        />
      </div>}
      <div style={{ height: 15 }} />
      <div className={classes.advancedSubtitle}>
        <Typography variant={'body1'}>Transactions</Typography>
        {showTransactionsClear &&
          <QTextButton
            className={classes.advancedClearButton}
            onClick={handleClearTransactions}
            title={'CLEAR'}
          />}
      </div>
      {isAcme &&
        <>
          <ThreeStateOption
            onChange={(val) => handleChange('isExcludedFromReports', val)}
            value={advancedItems.isExcludedFromReports}
            onOffText="Ignored in reports"
            idPrefix={'is_excluded_from_reports'}
          />
          <Divider />
          <ThreeStateOption
            onChange={(val) => handleChange('isExcludedFromF2S', val)}
            value={advancedItems.isExcludedFromF2S}
            onOffText="Ignored in spending plan"
            idPrefix={'is_excluded_from_free_to_spend'}
          />
          <Divider />
        </ >}
      <div className={classes.advancedGroup}>
        <ThreeStateOption
          onChange={(val) => handleChange('isReviewed', val)}
          value={advancedItems.isReviewed}
          onOffText="Reviewed"
          idPrefix={'reviewed'}
        />
      </div>
    </div>
  );
};

AdvancedPane.propTypes = {
  advancedItems: PropTypes.object,
  onChange: PropTypes.func,
  classes: PropTypes.object,
};

export default AdvancedPane;
