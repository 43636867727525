import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UAParser from 'ua-parser-js';
import { makeStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

import { localPreferences } from 'companion-app-components/utils/core';

import QButton from 'components/QButton';

const userAgentParser = new UAParser();
const isMobileDevice = window && userAgentParser.getDevice().type === 'mobile';

const useStyles = makeStyles()((theme) => ({
  content: {
    minWidth: 252,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (min-width: 464px)': {
      margin: '6px 12px',
    },
    maxHeight: 'calc(100% - 24px)',
  },
  badges: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.secondary.dark,
    margin: 12,
    marginTop: 18,
  },
  continue: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 12,
  },
  description: {
    maxWidth: 396,
    margin: 6,
    '@media (min-width: 464px)': {
      margin: '6px 12px',
    },
    '@media (min-width: 580px)': {
      margin: '6px 24px',
    },
  },
  appLinksWrapper: {
    height: 40, 
    margin: 10,
  },
  appLink: {
    display: 'inline-block',
    overflow: 'hidden',
    background: 'url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2013-10-01&kind=iossoftware&bubble=ios_apps) no-repeat',
    width: 135,
    height: 40,
  },
  zoomTransition: {
    transitionDelay: '400ms',
  },
}));

const MobileDirectDialog = (props) => {
  const { isAuthenticated } = props;
  const { classes } = useStyles();
  const [showMobileMessage, setShowMobileMessage] = useState(isAuthenticated && isMobileDevice);
  const mobileWarningShown = localPreferences.getMobileWarningShown();

  useEffect(() => {
    setShowMobileMessage(isAuthenticated && isMobileDevice);
  }, [isAuthenticated]);

  const proceed = () => {
    setShowMobileMessage(false);
    localPreferences.setMobileWarningShown(true);
  };

  return ((showMobileMessage && !mobileWarningShown) ?
    <Dialog open classes={{ paper: classes.content }}>

      <Typography variant="h6" align="center" className={classes.title}>
        It looks like you are coming from a mobile device!
      </Typography>

      <Typography variant="body1" align="center" className={classes.description}>
        {'Our web app is great, but it isn\'t optimized for mobile sized screens.'}
        <br />
        We suggest you download our iOS or Android app for the best experience.
      </Typography>

      <div className={classes.badges}>
        <Zoom in className={classes.zoomTransition}>
          <Fab
            sx={{
              all: 'unset',
              '&:hover, &:focus, &:active': {
                all: 'unset',
              },
            }}
            disableRipple
            disableFocusRipple
          >
            <div className={classes.appLinksWrapper}>
              <a
                alt="Download on the AppStore"
                href="https://apps.apple.com/us/app/quicken/id701067522?mt=8"
                className={classes.appLink}
              >
              </a>
            </div>
          </Fab>
        </Zoom>
        <Zoom in className={classes.zoomTransition}>
          <Fab
            sx={{
              all: 'unset',
              '&:hover, &:focus, &:active': {
                all: 'unset',
              },
            }}
            disableRipple
            disableFocusRipple
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.quicken.qm2014&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                height={60}
              />
            </a>
          </Fab>
        </Zoom>
      </div>

      <div className={classes.continue}>
        <QButton
          onClick={proceed}
        >
          Continue Anyway
        </QButton>
      </div>
    </Dialog>
    :
    null);
};

MobileDirectDialog.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default MobileDirectDialog;
