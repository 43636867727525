import React, { PureComponent } from 'react';
import Fab from '@mui/material/Fab';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

class Debug extends PureComponent {

  constructor(props) {
    super(props);
    this.state = { showDetails: false };
  }

  render() {
    return (
      <>
        <ClickAwayListener onClickAway={() => this.setState({ showDetails: false })}>
          <>
            <Popper
              open={this.state.showDetails}
              anchorEl={this.state.anchorEl}
              placement="left-end"
              disablePortal={false}
              modifiers={[{
                name: 'flip',
                options: {
                  fallbackPlacements: ['top', 'right', 'bottom', 'left'],
                },
              }, {
                name: 'arrow',
                options: {
                  element: this.state.anchorEl,
                },
              }]}
              style={{
                zIndex: 9999,
              }}
            >
              <Paper
                style={{
                  overflowY: 'scroll',
                  maxWidth: '90vw',
                  maxHeight: '90vh',
                  padding: 20,
                  opacity: 0.9,
                }}
              >
                <h4>
                  {this.props.that && this.props.that.constructor.name}
                </h4>

                {this.props.dump &&
                <>
                  <hr />
                  dump:
                  <pre>
                    {JSON.stringify(this.props.dump, 0, 2)}
                  </pre>
                </>}

                <hr />
                state:
                <pre>
                  {this.props.that && JSON.stringify(this.props.that.state, 0, 2)}
                </pre>

                <hr />
                ui state:
                <pre>
                  {this.props.that && this.props.that.props && JSON.stringify(this.props.that.props.uiState, 0, 2)}
                </pre>

                <hr />
                props:
                <pre>
                  {this.props.that && JSON.stringify(this.props.that.props, 0, 2)}
                </pre>

              </Paper>
            </Popper>

            <Fab
              size="small"
              style={{
                opacity: 0.3,
                position: 'absolute',
                right: 10,
                bottom: 10,
                zIndex: 9999,
              }}
            >
              <BugReportOutlinedIcon
                onClick={(event) => this.setState({ showDetails: !this.state.showDetails, anchorEl: event.currentTarget })}
              />
            </Fab>
          </>
        </ClickAwayListener>
      </>
    );
  }

}

Debug.propTypes = {
  that: PropTypes.object,
  dump: PropTypes.array,
};

export default Debug;

