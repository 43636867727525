export const styles = (theme) => ({
  root: {
    display: 'block',
    padding: theme.spacing(1),
  },
  deleteIcon: {
    color: theme.components.addAndBrowserDocuments.deleteIconColor,
    cursor: 'pointer',
    transition: '.3s ease',
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      transform: 'scale(1.25)',
    },
  },
  attachTitle: {
    color: theme.palette.black,
    textTransform: 'uppercase',
    letterSpacing: 1.25,
  },
  thumbRail: {
    textAlign: 'center',
    flex: 1,
    margin: 'auto',
  },
  boxDiv: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'unset',
    padding: theme.spacing(1, 0.5),
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: `1px solid ${theme.palette.silverGrey}`,
    borderRadius: theme.shape.borderRadius,
  },
  attachmentTypography: {
    marginLeft: theme.spacing(1), 
    display: 'flex', 
    alignSelf: 'flex-start', 
    flexDirection: 'column', 
    whiteSpace: 'nowrap',
  },
  previewWrapper: {
    textAlign: 'center', 
    flex: 1, 
    margin: 'auto', 
  },
});
