// @flow
import * as React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

type Props = {
  icon?: React.node,
  label?: String,
};

const StdDialogProgress = (props: Props) => {
  const { icon, label } = props;
  const theme = useTheme();


  return (
    <Box display="flex" flexDirection="column" height="0px" style={{ transform: 'translate(0, 48px)' }}>
      <CircularProgress size={130} style={{ margin: 'auto' }} />
      {label &&
        <Box alignContent="center" alignSelf="center" color="#666666" display="flex" pt={3}>
          {icon &&
            <Box component={icon} htmlColor={theme.palette.link.main} style={{ marginRight: '12px' }} />}
          <Typography>
            {label}
          </Typography>
        </Box>}
    </Box>
  );
};

export default StdDialogProgress;
