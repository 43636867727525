import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect';
import _ from 'lodash';

// compares 2 objects deep (obj1.obj and obj2.obj) or shallow (obj1.obj and obj2.obj) based on 'obj.deep' flag
export const flexEqual = (obj1, obj2) => {
  let isEqual;

  if (obj1 && obj2 && Object.prototype.hasOwnProperty.call(obj1, 'obj') && Object.prototype.hasOwnProperty.call(obj2, 'obj')) {
    if (obj1.deep && obj2.deep) {
      isEqual = JSON.stringify(obj1.obj) === JSON.stringify(obj2.obj); // deep equal
    } else {
      isEqual = obj1.obj === obj2.obj; // shallow equal
    }
  } else {
    isEqual = obj1 === obj2; // standard equal
  }

  return isEqual;
};
// selector creator that supports configurable deep or shallow keys comparison
export const createFlexEqualSelector = createSelectorCreator(
  defaultMemoize,
  flexEqual
);

// arrays shallow compare
export const arraysEqualShallow = (array1, array2) => (!array1 && !array2)
  || (array1 && array2 && array1.length === array2.length && array1.every((element, index) => element === array2[index]));
export const createArraysEqualShallowSelector = createSelectorCreator(
  defaultMemoize,
  arraysEqualShallow
);

export function createTrimSelector(selector, trimFunc) {
  let dataTrimmedCache;
  return createSelector(
    selector,
    (dataReal) => {
      const dataTrimmedReal = dataReal.map(trimFunc);
      if (!_.isEqual(dataTrimmedReal, dataTrimmedCache)) {
        dataTrimmedCache = dataTrimmedReal;
      }
      return dataTrimmedCache;
    },
  );
}

export function createValueCache(getValue) {
  let cachedValue;
  return (...params) => {
    const value = getValue(...params);
    cachedValue = _.isEqual(cachedValue, value) ? cachedValue : value;
    return cachedValue;
  };
}
