import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

interface TableHeaderProps {
  columns: Record<string, any>;
  classes: Record<string, string>;
  selectedAccounts: number;
  totalRows: number;
  onChangeAccountsToShow: (accountsToShowSelection: string) => void;
}

const TableHeader: React.FC<TableHeaderProps> = (props: TableHeaderProps) => {
  const { classes, columns, selectedAccounts, totalRows, onChangeAccountsToShow } = props;
  const isIndeterminate = useMemo(() => selectedAccounts > 0 && selectedAccounts < totalRows, [totalRows, selectedAccounts]);
  const isChecked = useMemo(() => totalRows > 0 && selectedAccounts === totalRows, [totalRows, selectedAccounts]);
  const onChange = useCallback(() => onChangeAccountsToShow(isChecked ? 'CLEAR' : 'ALL'), [onChangeAccountsToShow, isChecked]);
  return (
    <TableHead>
      <TableRow>
        {Object.keys(columns).map((key: string) => {
          const column = columns[key];
          return (
            <TableCell key={key} className={classNames(props.classes.resetCell, props.classes?.[key])}>
              <div className={classes?.[`${key}Container`]}>
                {column.label ? column.label : (
                  <Checkbox
                    color="default"
                    indeterminate={isIndeterminate}
                    checked={isChecked}
                    onChange={onChange}
                  />)}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};


export default React.memo(TableHeader);
