import { Map as ImmutableMap } from 'immutable';
import * as sessionStorageEx from 'utils/sessionStorageEx';
import { localPreferences } from 'companion-app-components/utils/core';

import { entitlementsTypes } from 'companion-app-components/flux/entitlements';

export const loadEntitlements = () => {
  const entitlementsStore = localPreferences.getEntitlements() || sessionStorageEx.getEntitlements();
  if (entitlementsStore) {
    entitlementsStore.resourcesById = ImmutableMap(entitlementsStore.resourcesById).map(
      (entitlement) => entitlementsTypes.mkEntitlement(entitlement as entitlementsTypes.Entitlement),
    );
  }
  return entitlementsStore;
};

export const saveEntitlements = (entitlementsStore) => {
  sessionStorageEx.setEntitlements(entitlementsStore);
  if (localPreferences.getKeepLoggedIn()) {
    localPreferences.setEntitlements(entitlementsStore);
  } else {
    localPreferences.removeEntitlements();
  }
};

export const clearEntitlements = () => {
  sessionStorageEx.removeEntitlemets();
  localPreferences.removeEntitlements();
};
