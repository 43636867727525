import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { tooltipStyles as styles } from './styles';


const useStylesArrow = makeStyles()(styles);

export default function QTooltip(props) {
  const showArrow = false; // TODO fix arrow position
  const { arrow, paper, ...classes } = useStylesArrow();
  const [arrowRef, setArrowRef] = React.useState(null);

  return (
    <Tooltip
      classes={classes}
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      {...props}
      title={
        <Paper className={paper}>
          {props.content}
          {showArrow && <span className={arrow} ref={setArrowRef} />}
        </Paper>
      }
    />
  );
}

QTooltip.propTypes = {
  content: PropTypes.node,
};
