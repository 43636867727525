// @flow
import { createAction } from 'utils/actionHelpers';

// expect object { institutionId, idType = null }
export const getInstitution = createAction('INSTITUTION_GET_REQUEST');
export const getInstitutionResponse = createAction('INSTITUTION_GET_RESPONSE');

export const getPopularInstitutions = createAction('INSTITUTION_GET_POPULAR_INSTITUTIONS_REQUEST');
export const getPopularInstitutionsSuccess = createAction('INSTITUTION_GET_POPULAR_INSTITUTIONS_SUCCESS');
export const getPopularInstitutionsFailure = createAction('INSTITUTION_GET_POPULAR_INSTITUTIONS_FAILURE');

export const searchForInstitutions = createAction('SEARCH_FOR_INSTITUTIONS');
export const searchForInstitutionsSuccess = createAction('SEARCH_FOR_INSTITUTIONS_SUCCESS');
export const searchForInstitutionsFailure = createAction('SEARCH_FOR_INSTITUTIONS_FAILURE');


