import React from 'react';
import TreeItem from '@mui/lab/TreeItem';

function isColor(color) {
  const { style } = new Option();
  style.color = color;
  return style.color === color || /^#[0-9A-Fa-f]{3,6}$/i.test(color);
}

export const objectTree = (key, value, path = '') => {
  let treeItem;

  if (value && typeof value === 'function') {
    treeItem = <React.Fragment key={key} />;
  } else if (value && Array.isArray(value)) {
    treeItem =
      <TreeItem key={path} nodeId={path} label={`${key}[${value.length}]`}>
        {value.map((entryValue, index) => objectTree(undefined, entryValue, `${path}[${index}]`))}
      </TreeItem>;
  } else if (value && typeof value === 'object') {
    treeItem =
      <TreeItem key={path} nodeId={path} label={key || '[object]'}>
        {Object.entries(value).map(([entryKey, entryValue]) => objectTree(entryKey, entryValue, `${path}.${entryKey}`))}
      </TreeItem>;
  } else {
    treeItem =
      <TreeItem
        key={path}
        nodeId={path}
        label={`${key ? `${key}: ` : ''} ${value}`}
        endIcon={isColor(value) ? <div style={{ backgroundColor: value, width: 16, height: 16, border: '1px dotted grey' }} /> : undefined}
        onClick={() => navigator.clipboard.writeText(path)}
      />;
  }

  return treeItem;
};

export default objectTree;
