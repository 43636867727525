import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

import { accountsSelectors } from 'companion-app-components/flux/accounts';
import store from 'companion-app-components/utils/redux-store';

export const getPendingTransactionMockData = () => {
  const account = accountsSelectors.getCashAccounts(store.getState()).first();
  const postedOnDate = DateTime.local().minus({ days: 1 });
  const postedOn = postedOnDate.toISODate();
  const cpId = uuidv4();
  return {
    inTesting: true,
    accountId: account?.id,
    pendingAggregationType: 'CURRENT_PENDING_LIST',
    cpData: {
      balance: -56.78,
      balanceAt: DateTime.local().toISO(),
    },
    transactions: [{
      source: 'QCS_REFRESH_BANK_PENDING',
      accountId: account?.id,
      postedOn,
      payee: 'Safeway Grocceries',
      coa: {
        type: 'UNCATEGORIZED',
        id: '0',
      },
      amount: -56.78,
      state: 'PENDING',
      matchState: 'NOT_MATCHED',
      cpData: {
        id: cpId,
        cpPendingId: cpId,
        postedOn,
        txnOn: postedOn,
        amount: -56.78,
        inferredPayee: 'Safeway Grocceries',
        inferredCoa: {
          type: 'UNCATEGORIZED',
          id: '0',
        },
        cpCategoryId: '2002',
      },
      type: 'CASH_FLOW',
    }],
  };
};

export const getPostedTransactionMockData = () => {
  const account = accountsSelectors.getCashAccounts(store.getState()).first();
  const postedOnDate = DateTime.local().minus({ days: 1 });
  const postedOn = postedOnDate.toISODate();
  const cpId = uuidv4();
  return {
    inTesting: true,
    accountId: account?.id,
    pendingAggregationType: 'CURRENT_PENDING_LIST',
    cpData: {
      balance: -56.78,
      balanceAt: DateTime.local().toISO(),
    },
    transactions: [{
      source: 'QCS_REFRESH',
      accountId: account?.id,
      postedOn,
      payee: 'Safeway Grocceries',
      coa: {
        type: 'UNCATEGORIZED',
        id: '0',
      },
      amount: -56.78,
      state: 'CLEARED',
      matchState: 'NOT_MATCHED',
      cpData: {
        id: cpId,
        cpPendingId: cpId,
        postedOn,
        txnOn: postedOn,
        amount: -56.78,
        inferredPayee: 'Some Payee',
        inferredCoa: {
          type: 'UNCATEGORIZED',
          id: '0',
        },
        cpCategoryId: '2002',
      },
      type: 'CASH_FLOW',
    }, {
      source: 'QCS_REFRESH',
      accountId: account?.id,
      postedOn,
      payee: 'Safeway Gas',
      coa: {
        type: 'UNCATEGORIZED',
        id: '0',
      },
      amount: -12.34,
      state: 'CLEARED',
      matchState: 'NOT_MATCHED',
      cpData: {
        id: `${cpId}1`,
        cpPendingId: `${cpId}1`,
        postedOn,
        txnOn: postedOn,
        amount: -12.34,
        inferredPayee: 'Safeway Gas',
        inferredCoa: {
          type: 'UNCATEGORIZED',
          id: '0',
        },
        cpCategoryId: '2002',
      },
      type: 'CASH_FLOW',
    }, {
      source: 'QCS_REFRESH',
      accountId: account?.id,
      postedOn,
      payee: 'Safeway Refund',
      coa: {
        type: 'UNCATEGORIZED',
        id: '0',
      },
      amount: +56.78,
      state: 'CLEARED',
      matchState: 'NOT_MATCHED',
      cpData: {
        id: `${cpId}2`,
        cpPendingId: `${cpId}2`,
        postedOn,
        txnOn: postedOn,
        amount: +56.78,
        inferredPayee: 'Safeway Refund',
        inferredCoa: {
          type: 'UNCATEGORIZED',
          id: '0',
        },
        cpCategoryId: '2002',
      },
      type: 'CASH_FLOW',
    }],
  };
};
