import { createSelector } from 'reselect';
import _ from 'lodash';

import { localPreferences } from 'companion-app-components/utils/core';
import { authSelectors } from 'companion-app-components/flux/auth';
import { entitlementsSelectors } from 'companion-app-components/flux/entitlements';

import { clientType } from 'isAcme';
import { getActiveSubscription } from 'data/subscriptions/subscriptionsSelectors';
import { getSubscriptionTrackingProps } from 'data/subscriptions/helpers';
import { createValueCache } from 'utils/selectorsHelpers';

export const getMixpanelUserData = createSelector(
  authSelectors.getAuthSession,
  getActiveSubscription,
  createValueCache((authSession, subscription) => ({
    qcs_id: authSession.qcsId ?? 'unknown',
    ...(authSession.datasetId && { dataset_id: authSession.datasetId }),
    ...(authSession.scope && { scope: authSession.scope }),
    ...(authSession.groups && { groups: authSession.groups }),
    ...(subscription?.active !== undefined && { subscriber: subscription.active ? 'Subscribed' : 'Not Subscribed' }),
    ...(subscription?.productLineName && { product_line: subscription.productLineName }),
    ...(subscription?.tierName && { tier: subscription.tierName }),
    ...(subscription?.isInTrialPeriod != null && { subscription_state: subscription.isInTrialPeriod ? 'trial' : 'paid' }),
    ...(subscription?.renewalFrequency != null && {
      subscription_term: subscription.renewalFrequency === 'P1M' ? 'monthly' : 'annual',
    }),
    last_client: clientType,
    simplifi_web_app_version: process.env.APP_VERSION,
  })),
);

export const getMixpanelDefaultProperties = createSelector(
  authSelectors.getAuthSession,
  getActiveSubscription,
  entitlementsSelectors.getEntitlements,
  createValueCache((authSession, subscription, entitlements) => {
    const defaultProps = {};

    defaultProps.qcs_id = authSession?.qcsId ?? 'unknown';
    defaultProps.dataset_id = authSession?.datasetId;
    defaultProps.dataset_platform = authSession?.datasetPlatform;
    defaultProps.dataset_created_by = authSession?.datasetCreatedByClientId;
    defaultProps.scope = authSession?.scope;
    defaultProps.groups = authSession?.groups;

    const subscriptionProps = getSubscriptionTrackingProps(subscription);
    Object.keys(subscriptionProps).forEach((propName) => {
      defaultProps[_.snakeCase(propName)] = subscriptionProps[propName];
    });

    defaultProps.entitlements = entitlements && entitlements
      .filter((entitlement) => entitlement.active)
      .map((entitlement) => entitlement.id)
      .toIndexedSeq()
      .toArray();

    defaultProps.persist = localPreferences.getPersist();
    defaultProps.keep_logged_in = localPreferences.getKeepLoggedIn();

    return defaultProps;
  }),
);

