import { createAction as reduxActionsCreator } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Map as ImmutableMap } from 'immutable';

import { createAction } from 'utils/actionHelpers';

export const DIALOG_TYPE = 'BILL_PRESENTMENT_DIALOG';

// Resource actions

export const billerCredentialsSubmit = createAction('BILLER_CREDENTIALS_SUBMIT');
export const billerCredentialsResponse = createAction('BILLER_CREDENTIALS_RESPONSE');

// Dialog Actions

export const doBillPresentment = reduxActionsCreator(
  createDialog.toString(),
  (series, initiator) => mkRootUiData({
    type: DIALOG_TYPE,
    id: `BPS-${series?.id}`,
    props: ImmutableMap({
      series,
      initiator,
    }),
  }),
  (_payload, meta) => meta,
);
