import { Record } from 'immutable';

import { mkPreferencesStore } from 'companion-app-components/flux/preferences/types';

import { defaultDatasetPreferences, defaultUserPreferences } from './defaults';

export const Preferences = Record(
  {
    shared: {
      user: {
        ...defaultUserPreferences,
      },
      dataset: {
        webApp: {
          ...defaultDatasetPreferences,
        },
      },
    },
    /* these are not yet supported
    client: {
      user: {
      },
      dataset: {
      },
      global: {
      },
    },
    qcs: {},
    */
  },
);

export const PreferencesStore = Record({
  isLoading: undefined,
  loadPending: {
    shared: {
      user: true,
      dataset: true,
    },
  },
  preferences: new Preferences(),
  error: undefined,
});

export const initialPreferencesState = mkPreferencesStore({ preferences: new Preferences() });
