import { isImmutable } from 'immutable';

import { chartOfAccountsTypes } from 'companion-app-components/flux/chart-of-accounts';
import { transactionsTypes } from 'companion-app-components/flux/transactions';

export function sumSplitRows(items) {

  let sum = 0;
  items.forEach((x) => {
    sum += Number(x.amount);
  });
  return Number(sum.toFixed(2));
}

export const splitTxnRemainder = (txn) =>
  Number((Number(txn.amount) - sumSplitRows(txn.split.items)).toFixed(2));


export function addRemainderToSplit(txn, remainder) {

  const itemIndex = txn.split.items.findIndex((item) =>
    ((item.coa && item.coa.type === 'UNCATEGORIZED') && Number(item.coa.id) === 0));

  if (itemIndex !== -1) {
    // getting some bugsnag errors QWA-7095 where items.get is not a function, using assert to find case in QA
    assert(txn.split.items.get, 'Split Items not immutable: SP-105/TV - Please tell Tim what you just did');
    if (isImmutable(txn.split.items)) {
      let item = txn.split.items.get(itemIndex);
      item = item.set('amount', Number(item.amount) + remainder);
      const newItems = txn.split.items.set(itemIndex, item);
      return txn.setIn(['split', 'items'], newItems);
    }
  }
  // need to create the uncategorized row
  const newItem = transactionsTypes.mkSplitItem(
    {
      amount: remainder,
      coa: chartOfAccountsTypes.mkChartOfAccount({ id: 0, type: 'UNCATEGORIZED' }),
    }
  );
  const newTxn = txn.setIn(['split', 'items'], txn.split.items.push(newItem));

  return newTxn;
}
