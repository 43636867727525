import React from 'react';
import { makeStyles } from 'tss-react/mui';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import QButton from 'components/QButton';

import * as Docs from '../DocsUtils';

const useStyles = makeStyles()((_theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 100,
  },
  header: {
    textAlign: 'center',
    width: '100%',
  },
  backToTop: {
    width: '100%',
    textAlign: 'center',
  },
}));

export const INFO_WRAPPER_KEY = 'INFO';

const Info = (_props) => {

  const { classes } = useStyles();

  return (
    <div className={classes.root} id={'info-root'}>
      <Docs.Header className={classes.header}>
        QuickIndex
      </Docs.Header>

      <Docs.Section>
        <Docs.SectionHeader>
          Overview
        </Docs.SectionHeader>
        <Docs.SectionContent>
          Welcome to the Quickindex. This is where we keep track of shared components/styling/<a href={'/docs/THEME'}>themes</a>/etc. When in the index you will always have the <a href={'#tree-view'}>Tree View</a> on your left
          and a <a href={'#focus-pages'}>Focus Page</a> on the right, what you are reading now is a focused page. You can always return to this page by selecting &quot;Info&quot; in the tree view.
          If you have any questions not answered here or suggestions for improvements please contact Mike Landolfi.
        </Docs.SectionContent>
      </Docs.Section>

      <Docs.Section>
        <Docs.SectionHeader>
          Navigating the index
        </Docs.SectionHeader>
        <Docs.SectionContent>

          <Docs.SectionSubheader id={'tree-view'}>
            Tree View
          </Docs.SectionSubheader>
          The index is cataloged into a tree-view on the left. You can click specific components in the tree view to be taken to its <a href={'#component-page'}>component page</a>.
          <Docs.SectionSpacer />
          If there is an arrow next to an item (<ChevronRightIcon />/<KeyboardArrowDownIcon />) then it is a grouping of other items, a group header. Groups can be revealed/hidden by clicking the arrow or the header itself.
          Clicking a group header will also display all the components grouped under it on one page, this is called <a href={'#component-list-view'}>component list view</a>. This is useful to see all the options for a type of component.
          <Docs.SectionSpacer />
          You can also search through the index using the search field at the top of the catalog. This searches components based on their names and keywords associated with components.
          It will also auto expand and show components in groups where the group header contains part of the search. For example you can find the <a href={'/docs/DATE_RANGE_PICKER'}>{'<DateRangePicker />'}</a> component
          by searching &quot;date&quot; or &quot;range&quot;, but it can also be found by searching the keyword &quot;popper&quot;. Also if you search &quot;inputs&quot; it will appear along with all other input components.
          The search is not case-sensitive.

          <Docs.SectionSubheader id={'component-list-view'}>
            Component List View
          </Docs.SectionSubheader>
          Component list views allow you to see a group of components all on one page for ease of browsing. To see a group of components you can click the group header in the <a href={'#tree-view'}>Tree View</a>.
          To see all components in the index simply click the <a href={'docs/COMPONENTS'}>&quot;Components&quot;</a> group header.
          <Docs.SectionSpacer />
          When viewing components in the list view you will only see the default example. To see more about the component and its usage you can click the container around the component to be
          brought to the <a href={'component-page'}>component page</a>

        </Docs.SectionContent>
      </Docs.Section>

      <Docs.Section>
        <Docs.SectionHeader id={'focus-pages'}>
          Focus Pages
        </Docs.SectionHeader>
        <Docs.SectionContent>

          <Docs.SectionSubheader id={'info-page'}>
            Info Page
          </Docs.SectionSubheader>
          You are currently focused on the info page.

          <Docs.SectionSubheader id={'info-page'}>
            Theme Page
          </Docs.SectionSubheader>
          For specifics related to our theme usage throughout the app, find in <a href={'#tree-view'}>Tree View</a> or click <a href={'/docs/THEME'}>here</a>

          <Docs.SectionSubheader id={'info-page'}>
            Assets Page
          </Docs.SectionSubheader>
          Displays all assets currently being used in the app to avoid adding duplicate items, find in <a href={'#tree-view'}>Tree View</a> or click <a href={'/docs/ASSETS'}>here</a>

          <Docs.SectionSubheader id={'component-list-page'}>
            Component List Page
          </Docs.SectionSubheader>
          See <a href={'#component-list-view'}>Component List View</a> section for more about this focus page. Click <a href={'/docs/COMPONENTS'}>here</a> to see all the components currently in the index.

          <Docs.SectionSubheader id={'component-page'}>
            Component Page
          </Docs.SectionSubheader>
          A component page shows usage and design information about a specific reusable component. Each component page will have the name of the component a centered at the top.
          Some components will have a short description of the component. After the description there will be at least one example usage of the component. If there are multiple
          examples then each one will have a title and sometimes a description explaining the specific example, often the title of the example is sufficient.

        </Docs.SectionContent>
      </Docs.Section>

      <Docs.Section>
        <Docs.SectionHeader>Creating Docs</Docs.SectionHeader>
        <Docs.SectionContent>
          We have added several utility components to help write docs quickly and efficiently.
        </Docs.SectionContent>
        <Docs.SectionContent>

          <Docs.SectionSubheader>Importing</Docs.SectionSubheader>
          <Docs.Code>import * as Docs from &apos;containers/SharedComponentsPage/DocsUtils&apos;</Docs.Code>

          <Docs.SectionSubheader>List of Utility Components</Docs.SectionSubheader>
          <Docs.Code>
            {'<Docs.Header>Page Header</Docs.Header>\n'}
            {'<Docs.Section>Defining a section of the Docs, just a basic container</Docs.Section>\n'}
            {'<Docs.SectionHeader>Header of a section</Docs.SectionHeader>\n'}
            {'<Docs.SectionSubheader>Subheader of a section</Docs.SectionSubheader>\n'}
            {'<Docs.SectionContent>For paragraphs of text</Docs.SectionContent>\n'}
            {'<Docs.SectionSpacer>For spacing inside sections</Docs.SectionSpacer>\n'}
            {'<Docs.Spacer>For spacing in general, 4x as big as SectionSpacer</Docs.Spacer>\n'}
            {'<Docs.Note>For notes that are important to know</Docs.Note>\n'}
            {'<Docs.Warning>A note but red</Docs.Warning>\n'}
            {'<Docs.Code>For blocks of code</Docs.Code>\n'}
            {'<Docs.ErrorBoundary>For wrapping components that could potentially break</Docs.ErrorBoundary>\n'}
          </Docs.Code>

          <Docs.SectionSubheader>Utility Components Being Used</Docs.SectionSubheader>
          <Docs.Header>{'Docs.Header'}</Docs.Header>
          <Docs.Section>
            <Docs.SectionHeader>{'Docs.SectionHeader'}</Docs.SectionHeader>
            <Docs.SectionSubheader>{'Docs.SectionSubheader'}</Docs.SectionSubheader>
            <Docs.SectionContent>{'Docs.SectionContent'}</Docs.SectionContent>
            <Docs.SectionSpacer>{'Docs.SectionSpacer'}</Docs.SectionSpacer>
            <Docs.SectionContent>{'Docs.SectionContent'}</Docs.SectionContent>
          </Docs.Section>

          <Docs.Spacer>{'Docs.Spacer'}</Docs.Spacer>
          <Docs.Note>{'Docs.Note, it adds the "Note"'}</Docs.Note>
          <Docs.Spacer>{'Docs.Spacer'}</Docs.Spacer>
          <Docs.Warning>{'Docs.Warning, it adds the "Warning"'}</Docs.Warning>
          <Docs.Spacer>{'Docs.Spacer'}</Docs.Spacer>
          <Docs.Code hint={'added with the "hint" prop'}>
            {'Docs.Code, text is copied on click'}
          </Docs.Code>

          <Docs.SectionSubheader>Adding a New Doc/Component</Docs.SectionSubheader>

          <Docs.SectionContent>
            You have to import your new doc component into the config.js file and insert it into the configChildren list. The new doc must have a unique id.
            The common practice is to convert the name into CAPITALIZED_SNAKE_CASE. That will make your new docs page accessible from the <a href={'#tree-view'}>Tree View</a>.
            <Docs.Code hint={'config.js'}>
              {`export const configChildren = [
  {
    name: 'Info',
    id: 'INFO',
    type: INFO_WRAPPER_KEY,
  },
  {
    name: 'Name of your Doc',
    id: 'NAME_OF_YOUR_DOC',
    type: [wrapper key for your doc]
  },
  ...
  componentsConfig,
];`}
            </Docs.Code>
            <Docs.SectionSpacer />
            Currently there are 2 types docs pages available for implementation, Component & Function. They can be created by using the factory functions,
            mkSharedComponent & mkSharedFunction respectively. Components should be added into the most sensible file/category inside the ComponentConfigs folder
            (we align our organization with Material-UI, seen in the left-side menu <a target={'_blank'} href={'https://material-ui.com/components/box/'}>here</a>).
            Functions should be added into the utilitiesConfig file.
            <Docs.SectionSpacer />
            If adding a new component you can also add the custom prop &quot;sharedcomponentid&quot; onto the root of the component and set it to the CAPITALIZED_SNAKE_CASE id of
            the newly added component. This will allow the component to be discovered with the <a href={'#find-shared-components'}>Find Shared Components</a> tool.
          </Docs.SectionContent>
        </Docs.SectionContent>
      </Docs.Section>

      <Docs.Section id={'find-shared-components'}>
        <Docs.SectionHeader>Finding Shared Components</Docs.SectionHeader>
        <Docs.SectionContent>
          You also have the ability to reveal all the shared components on any given page of the app. To do so enter the Dev Menu in the
          bottom right (wrench icon) and select the option &quot;Find Shared Components&quot;. This will outline and highlight all shared components
          on the current page in light green. This will also work inside of dialogs, and if the dialog itself is shared it will highlight
          the dialog title.
          <Docs.SectionSpacer />
          Try it now to highlight this usage of QButton =&gt; <QButton variant={'outlined'}>Find Me</QButton>
          <Docs.SectionSpacer />
          Once highlighted, hovering the component will reveal it&apos;s sharedcomponentid and holding shift + clicking will bring you to the specific Docs page.
          <Docs.Warning>This does not work for every component</Docs.Warning>
        </Docs.SectionContent>
      </Docs.Section>

      <a href={'#info-root'} className={classes.backToTop}>back to top</a>
    </div>
  );
};

export default Info;
