let globalCaches = new Map();

export function getGlobalCache(name) {
  return globalCaches.get(name);
}

export function setGlobalCache(name, cache) {
  globalCaches.set(name, cache);
}

export function clearGlobalCaches() {
  globalCaches = new Map();
}

