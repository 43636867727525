import React from 'react';
import { makeStyles } from 'tss-react/mui';

import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export const styles = () => ({
  root: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
});

const useStyles = makeStyles()(styles);
const QTextButton = (props) => {

  const { onClick, title, variant, ...other } = props;
  const { classes } = useStyles();

  return (
    <ButtonBase sharedcomponentid={'Q_TEXT_BUTTON'} onClick={onClick}>
      <Typography
        variant={variant || 'caption'}
        className={classes.root}
        {...other}
      >
        { title }
      </Typography>
    </ButtonBase>
  );
};
QTextButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  variant: PropTypes.string,
};

export default QTextButton;
