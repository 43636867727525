// @flow

import { OperandsEnum, MC_RULES_REDUCER_KEY } from './mcRulesTypes';
import type { MCRulesOperand } from './mcRulesTypes';

const getStore = (state) => state[MC_RULES_REDUCER_KEY];
export const getLoadPending = (state) => getStore(state).loadPending;
export const getIsLoading = (state) => getStore(state).isLoading;
export const getMessages = (state) => getStore(state).resourcesById;
export const getMessageById = (state, id) => getMessages(state).get(id);

let mcpClientType: string;
/////////////////////////////////////
///////////////////////////
////////
mcpClientType = 'QUICKEN_WEB';
/////////

export const fetchDataItemsForOperands = (_state, operands: Array<MCRulesOperand>) => {
  const dataItems = [];
  operands.forEach((operand: MCRulesOperand) => {
    let dataItem;
    switch (operand.name) {
      case OperandsEnum.PRODUCT_YEAR:
        dataItem = {
          operandName: operand.name,
          operandValue: 'SUBSCRIPTION',
        };
        break;
      case OperandsEnum.CLIENT_TYPE:
        dataItem = {
          operandName: operand.name,
          operandValue: mcpClientType,
        };
        break;
      case OperandsEnum.VERSION:
        dataItem = {
          operandName: operand.name,
          operandValue: String(process.env.APP_VERSION),
        };
        break;
      default:
    }
    if (dataItem) {
      dataItems.push(dataItem);
    }
  });

  return dataItems;
};
