import * as React from 'react';
import { get } from 'lodash';
import { Field, FormikProps, FormikValues } from 'formik';

import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { STTypeEnum } from 'companion-app-components/flux/scheduled-transactions/scheduledTransactionsTypes';
import { chartOfAccountsTypes } from 'companion-app-components/flux/chart-of-accounts';

import CategoryField from 'components/QuickenControls/CategoryField';
import { filterFn } from './utils';

interface RecrCategoryFieldProps {
  classes: Record<string, string>;
  name: string;
  formikProps: FormikProps<FormikValues>;
}

const RecrCategoryField: React.FC<RecrCategoryFieldProps> = ({ classes, name, formikProps }) => (
  <Field
    name={name}
    validate={(coa) => {
      let error: string = '';
      switch (formikProps.values.type) {
        case STTypeEnum.INCOME:
          if (!coa || (coa.type !== chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT && coa.type !== chartOfAccountsTypes.CoaTypeEnum.CATEGORY) ||
            (coa.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY && !categoriesSelectors.isIncomeCat(null, coa.id))) {
            error = 'valid income category required';
          }
          break;
        case STTypeEnum.TRANSFER:
          if (!coa || (coa.type !== chartOfAccountsTypes.CoaTypeEnum.ACCOUNT && coa.type !== chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT)) {
            error = 'valid account required';
          }
          break;
        case STTypeEnum.BILL:
        case STTypeEnum.EXPENSE:
          if (!coa || (coa.type !== chartOfAccountsTypes.CoaTypeEnum.CATEGORY && coa.type !== chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT && coa.type !== chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED)) {
            error = 'valid bill category required';
          }
          break;
        case STTypeEnum.SUBSCRIPTION:
          if (!categoriesSelectors.isExpenseCat(null, coa.id) && coa.type !== chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED) {
            error = 'valid subscription category required';
          }
          break;
        default:
      }
      return error;
    }}
  >
    {({ field, form: { touched, errors } }) => (
      <CategoryField
        {...field}
        onChange={(coa) => formikProps.setFieldValue(field.name, coa)}
        error={Boolean(get(touched, field.name) && get(errors, field.name))}
        label={formikProps.values.type === STTypeEnum.TRANSFER ? 'Account' : 'Category'}
        editable
        createEnabled
        longCats={false}
        margin="dense"
        autoFocus={false}
        fontSize="16px"
        inputStyle={classes.categories}
        filterFn={(coa) => filterFn(coa, formikProps.values.type)}
        InputProps={{
          classes: { underline: classes.underlineOverride },
          id: 'transaction-input',
        }}
        textFieldProps={{
          helperText: get(touched, field.name) && get(errors, field.name),
        }}
        transferOnly={formikProps.values.type === STTypeEnum.TRANSFER}
        stType={formikProps.values.type}
      />
    )}
  </Field>
);

export default RecrCategoryField;
