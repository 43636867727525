export const styles = ({ components }) => ({
  buttonRoot: {
    outline: 'none !important',
    fontSize: 'unset',
  },
  refreshButtonDisabled: {
    opacity: 0.6,
  },
  disabledIcon: {
    opacity: 0.75,
  },
  refreshDivButton: {
    color: components.navigation.iconColor,
    opacity: components.navigation.rowOpacity,
    justifyContent: 'unset',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'none',
    alignItems: 'center',
    textAlign: 'center',
    outline: 'none',
    padding: `${components.navigation.iconVerticalSpacing}px 32px ${components.navigation.iconVerticalSpacing}px 16px`,
    width: components.navigation.barWidth + components.accountDrawer.width,
    '&:hover': {
      opacity: 1,
      color: `${components.navigation.iconHoverColor} !important`,
      background: `${components.navigation.hoverBackground} !important`,
      cursor: 'pointer',
    },
    transition: components.navigation.selectedIconAnimation,
  },
  navText: {
    color: components.navigation.textColor,
  },
  disabledRefreshDivButton: {
    pointerEvents: 'none',
  },
});
