import React from 'react';
import PropTypes from 'prop-types';

// TODO: Commented lines will be enabled once api for consent is ready

// import { useSelector } from 'react-redux';

import StdDialogActions from 'components/Dialogs/StdDialogActions';
import StdDialogContent from 'components/Dialogs/StdDialogContent';

import { getEnvironmentConfig } from 'companion-app-components/utils/core';
// import { profileSelectors } from 'companion-app-components/flux/profile';

// import { saveConsent } from './utils';

const IntuitConsent = ({ onContinue }) => {
  
  // const profileDetails = useSelector((state) => profileSelectors.getProfile(state));
  
  const handleOnclick = () => {
    // saveConsent(profileDetails?.id);
    onContinue();
  };

  return (
    <>
      <StdDialogContent flexDirection="row" useContainerFlexHeight>
        <iframe 
          src={getEnvironmentConfig().intuit_consent_url} 
          title="Intuit Consent"
          width="100%"
        />
      </StdDialogContent>
      <StdDialogActions
        primaryId="continueButton"
        primaryLabel={'CONTINUE'}
        primaryOnClick={handleOnclick}
      />
    </>
  );
};

IntuitConsent.propTypes = {
  onContinue: PropTypes.func.isRequired,
};

export default IntuitConsent;
