import * as React from 'react';
import { FC, useState } from 'react';
import { DateTime } from 'luxon';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import AmountField, { formatNumber } from 'components/QuickenControls/AmountField';
import QButton from 'components/QButton';

import { BudgetItemType } from 'companion-app-components/flux/budgets/budgetsTypes';

import styles from './styles';

type ObjMonthlyAmountsType = {
  budgetAmount: number; 
  actualAmount: number;
  startDate: string;
};

interface RolloverBalanceCalculationViewProps {
  objMonthlyAmounts?: ObjMonthlyAmountsType;
  hidePopperAndContents: () => void; 
  handleRolloverAmountSave: (budgetItem: BudgetItemType, objMonthlyAmounts: ObjMonthlyAmountsType, calculatedBalance: number) => void;
  rolloverAmountForDialog: number;
  rolloverTableData: Record<string, any>[];
  editedRolloverAmount: number | null;
  setEditedRolloverAmount: (amount: number) => void;
  colorBalance: string;
  enableBudgetRolloverEdit: boolean;
  displayLabel?: string;
  budgetItem?: BudgetItemType;
}

const useStyles = makeStyles()(styles as Record<string, any>);

const RolloverBalanceCalculationView: FC<RolloverBalanceCalculationViewProps> = ({ 
  displayLabel = '', 
  objMonthlyAmounts = {}, 
  budgetItem, 
  hidePopperAndContents, 
  handleRolloverAmountSave,
  rolloverAmountForDialog,
  rolloverTableData,
  editedRolloverAmount,
  setEditedRolloverAmount,
  colorBalance,
  enableBudgetRolloverEdit,
}) => {
  const { classes } = useStyles();
  
  const [showRolloverEdit, setShowRolloverEdit] = useState(false);

  const amountFormat = '0,00.00';

  const calculateBalance = (
    { budgetAmount, actualAmount }: { budgetAmount: number, actualAmount: number }, 
    rolloverAmountPassed: number, 
    negate: number,
  ) => {
    if (negate > 0) { // Income category
      return rolloverAmountPassed - budgetAmount + actualAmount;
    } 
    return rolloverAmountPassed + budgetAmount - actualAmount;
  };

  // eslint-disable-next-line no-unused-vars
  const handleEditResetClick = () => {
    if (showRolloverEdit) {
      setEditedRolloverAmount(rolloverAmountForDialog);
    }
    setShowRolloverEdit(!showRolloverEdit);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TODO: fix later
  const selectedDate = DateTime.fromISO(objMonthlyAmounts?.startDate);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: TODO: fix later
  const calculatedBalance = calculateBalance(objMonthlyAmounts, editedRolloverAmount, budgetItem?.negate);
  
  return (
    <div className={classes.viewRolloverWrapper}>
      <div className={classes.rolloverMainHead}>
        <Typography variant="subtitle1" color="inherit">
          {displayLabel}
        </Typography>
        <div>
          <IconButton
            className={classes.closeButton}
            aria-label="Close"
            id="rollover-dialog-close"
            onClick={hidePopperAndContents}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.rolloverDate}>
        <Typography variant="subtitle2" color="inherit">
          {selectedDate.toFormat('MMMM')} {selectedDate.year}
        </Typography>
      </div>
      <table className={classes.rolloverTable}>
        {rolloverTableData.map((rolloverTableCell) => (
          <tr key={rolloverTableCell.key}>
            <td className={classes.rollOverOperation}>{rolloverTableCell.operation}</td>
            <td className={classes.rollOverName}>
              {rolloverTableCell.key}
              <div className={classes.rolloverDatedon}>
                {rolloverTableCell.datedOn}
              </div>
            </td>
            <td className={classes.rollOverAmounts}>
              {
                showRolloverEdit && rolloverTableCell.key === 'Rollover' &&
                <AmountField 
                  className={classes.input}
                  value={formatNumber(editedRolloverAmount, false, amountFormat)}
                  editable
                  hideCurrencyString
                  onChange={(e) => setEditedRolloverAmount(Number(e.target.value) || 0)}
                />
              }
              {
                (!showRolloverEdit || rolloverTableCell.key !== 'Rollover') && rolloverTableCell.key !== 'Balance' && 
                formatNumber(rolloverTableCell.value, false, amountFormat)
              }
              {
                rolloverTableCell.key === 'Balance' && 
                <span style={{ color: calculatedBalance < 0 ? colorBalance : undefined }}>
                  {formatNumber(calculatedBalance, false, amountFormat)}
                </span>
              }
            </td>
            <td className={classes.rollOverEdit}>
              {enableBudgetRolloverEdit && rolloverTableCell.edit && (
                <Button
                  variant="text"
                  aria-label="edit/reset"
                  onClick={handleEditResetClick}
                  onKeyDown={handleEditResetClick}
                  sx={{ textTransform: 'unset' }}
                >
                  {showRolloverEdit ? 'Reset' : 'Edit'}
                </Button>
              )}
            </td>
          </tr>
        ))}
      </table>
      <div className={classes.buttonWrapper}>
        <QButton
          variant="text"
          onClick={() => budgetItem && handleRolloverAmountSave(budgetItem, objMonthlyAmounts as ObjMonthlyAmountsType, calculatedBalance)}
          color="primary"
          disabled={rolloverAmountForDialog === editedRolloverAmount}
        >
          SAVE
        </QButton>
        <QButton
          variant="text"
          color="primary"
          onClick={hidePopperAndContents}
        >
          CANCEL
        </QButton>
      </div>
    </div>
  );
};

export default RolloverBalanceCalculationView;
