import { bumpInterface } from 'companion-app-components/flux/bump';
import { getEnvironmentConfig } from 'companion-app-components/utils/core';
import store from 'companion-app-components/utils/redux-store';
import { authActions } from 'companion-app-components/flux/auth';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { entitlementsSelectors } from 'companion-app-components/flux/entitlements';

import { getIsOffline } from 'data/app/selectors';

bumpInterface.bumpInterfaceInit({
  create: () => {
    const socket = new WebSocket(getEnvironmentConfig().websocket_qcs_url);
    socket.onclose = bumpInterface.onClose;
    socket.onerror = bumpInterface.onError;
    socket.onmessage = bumpInterface.onMessage;
    socket.onopen = bumpInterface.onOpen;
    return socket;
  },
  isOffline: () => getIsOffline(store.getState()),
  reconnectTimeout: () => {
    const state = store.getState();
    return entitlementsSelectors.isAuthenticatedWithDataSetId(state) && featureFlagsSelectors.getFeatureFlag(store.getState(), 'webSocketQcs');
  },
  reAuth: () => store.dispatch(authActions.invalidateAccessToken(undefined, { context: 'WS auth failed' })),
});
