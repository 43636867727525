import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { scheduledTransactionsTypes } from 'companion-app-components/flux/scheduled-transactions';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

// custom
import QButton from 'components/QButton';
import plannedSpendingZero from 'assets/planned-spending-zero.svg';
import { autoAdjustAmountEnum } from 'components/BillPresentment/BillAmountField';


const useStyles = makeStyles()((theme) => ({
  // root spacing
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: 200,
  },
  leftSide: {
    width: 92,
    minWidth: 92,
    paddingRight: 16,
    flexShrink: 1,
  },
  img: {
    width: 76,
    height: 76,
    marginTop: 12,
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  blueBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: 16,
    marginRight: 12,
    backgroundColor: theme.palette.color7.opacity30,
    borderRadius: 12,
  },
  greyText: {
    color: theme.palette.text.secondary,
  },
  description: {
    padding: '24px 0 32px',
  },
  radioHeader: {
    paddingBottom: 8,
  },

  section: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },

  actions: {
    height: 48,
    paddingBottom: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

function EditPage(props) {

  const { account, series, setEditAccount, updateAccountMap, autoFinish } = props;

  const { classes } = useStyles();

  const [amount, setAmount] = useState(series?.autoAdjustAmount || autoAdjustAmountEnum.BALANCE);
  const [date, setDate] = useState(series?.autoAdjustDueOn);

  const amountChange = (event) => {
    setAmount(event.target.value);
  };
  const dateChange = (event) => {
    setDate(event.target.value === 'false' ? false : Boolean(event.target.value));
  };

  const finish = () => {
    const newModel = scheduledTransactionsTypes.mkScheduledTransaction({
      ...(series?.toJS ? series?.toJS() : series),
      transaction: series.transaction,
      billPresentmentAccountId: account.id,
      autoAdjustAmount: amount,
      autoAdjustDueOn: Boolean(date),
    });
    autoFinish(newModel);
    updateAccountMap(account.id, newModel);
    setEditAccount(null);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.leftSide}>
          <img
            alt="Edit Icon"
            className={classes.img}
            src={plannedSpendingZero}
          />
        </div>

        <div className={classes.rightSide}>
          <div className={classes.row}>
            {/* Account Box */}
            <div className={classes.blueBox}>
              <Typography variant="body2" className={classes.greyText}>
                Account
              </Typography>
              <Typography variant="subtitle2">
                {account.nickName || 'nickname'}
              </Typography>
              <Typography variant="caption">
                {account.name}
              </Typography>
            </div>

            {/* Series Box */}
            <div className={classes.blueBox}>
              <Typography variant="body2" className={classes.greyText}>
                Series
              </Typography>
              <Typography variant="subtitle2">
                {series?.transaction?.payee || 'nickname'}
              </Typography>
              <Typography variant="caption">
                Bill
              </Typography>
            </div>
          </div>

          <Typography varriant="body1" className={classes.description}>
            The amount and date of your reminders will be updated automatically when your biller issues a new bill.
          </Typography>

          <Typography varriant="subtitle1" className={classes.radioHeader}>
            When a new bill arrives, update reminder to
          </Typography>

          {/* Field selections */}
          <div className={classes.row}>
            {/* Amount */}
            <div className={classes.section}>
              <Typography variant="body2" className={classes.greyText}>
                Amount
              </Typography>

              <RadioGroup aria-label="Amount" name="Amount" value={amount} onChange={amountChange}>
                <FormControlLabel
                  control={<Radio />}
                  label="Statement balance"
                  id="statement-balance"
                  value={autoAdjustAmountEnum.BALANCE}
                />

                <FormControlLabel
                  control={<Radio />}
                  label="Min. payment due"
                  id="min-due"
                  value={autoAdjustAmountEnum.MINIMUM}
                />

                <FormControlLabel
                  control={<Radio />}
                  label="Don't auto update"
                  id="amount-no-auto-update"
                  value={autoAdjustAmountEnum.NONE}
                />
              </RadioGroup>
            </div>

            {/* Date */}
            <div className={classes.section}>
              <Typography variant="body2" className={classes.greyText}>
                Date
              </Typography>

              <RadioGroup aria-label="Amount" name="Amount" value={date} onChange={dateChange}>
                <FormControlLabel
                  control={<Radio />}
                  label="Due date"
                  id="due-date"
                  value
                />

                <FormControlLabel
                  control={<Radio />}
                  label="Don't auto update"
                  id="date-no-auto-update"
                  value={false}
                />

              </RadioGroup>
            </div>
          </div>

        </div>
      </div>

      <div className={classes.actions}>
        <QButton
          id="submitButton"
          type="submit"
          variant="contained"
          onClick={finish}
        >
          Confirm
        </QButton>
      </div>
    </>
  );

}

EditPage.defaultProps = {
  account: {},
  series: {},
};

EditPage.propTypes = {
  account: PropTypes.object,
  series: PropTypes.object,
  setEditAccount: PropTypes.func,
  updateAccountMap: PropTypes.func,
  autoFinish: PropTypes.func,
};

export default EditPage;
