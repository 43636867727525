/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-foreign-prop-types */
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderedMap } from 'immutable';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useStateList } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { noop } from 'lodash';

import { undoActions, undoTypes } from 'companion-app-components/flux/undo';
import { transactionsActions } from 'companion-app-components/flux/transactions';

import ZeroStateView from 'components/ZeroStateView';
import QButton, { QButtonVariantEnum } from 'components/QButton';
import Dump from 'components/Dump';
import HighlightText from 'components/HighlightText';
import { mkNotification } from 'data/notifications/notificationsTypes';
import * as notificationsActions from 'data/notifications/notificationsActions';

import GreenThumbImage from 'assets/green-thumb.svg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Zoom from '@mui/material/Zoom';
import StepButton from '@mui/material/StepButton';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';

import useLoadState, { LoadState, updateRequiredStates, skeletonStates, tryAgainStates, readyStates } from 'companion-app-components/hooks/utils/useLoadState';

import LoadingLinearProgress from 'components/LoadingLinearProgress';
import ErrorLinearProgress from 'components/ErrorLinearProgress';
import QProgressBar from 'components/QProgressBar/QProgressBar';
import QSkeleton from 'components/QSkeleton';
import LoadingView from 'components/LoadingView';
import QPopper from 'components/QPopper';
import Confetti from 'components/Confetti';
import QSwitch from 'components/QSwitch';
import GuidedTooltip from 'components/GuidedTooltip';
import * as transactionsSelectors from 'data/transactions/selectors';
import StaticTransactionList from 'components/StaticTransactionList';
import { getAllTransactionsById } from 'data/transactions/selectors';

import { mkSharedComponent } from '../Wrappers/factories';

const loadStates = Object.values(LoadState);

const useStyles = makeStyles()(() => ({
  featureView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxWidth: 600,
    flexGrow: 1,
  },
}));

const StaticTransactionListComponent = (props) => {
  const transactions = useSelector(getAllTransactionsById).valueSeq();
  return (
    <StaticTransactionList
      txns={transactions}
      {...props.exampleProps}
    />
  );
};

const UseLoadStateSample = () => {
  const transactionsLoadState = useLoadState(transactionsSelectors);

  const { state, prev, next, currentIndex, setStateAt } = useStateList(loadStates);
  const dispatch = useDispatch();
  const { classes } = useStyles();

  return (
    <Box width="100%">
      <Box display="flex">
        <Box>
          <Box>
            <Button onClick={prev}>Prev</Button>
            <Button onClick={next}>Next</Button>
          </Box>

          <Stepper activeStep={currentIndex} nonLinear orientation="vertical" style={{ minWidth: 300 }}>
            {loadStates.map((label, index) => (
              <Step key={label}>
                <StepButton onClick={() => setStateAt(index)}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>

        {state === LoadState.UNDEFINED && (
          <div>
            <Typography align="center">
              {'Client has never fetched data from the server and not fetching data now.' +
                " It's important to explain that user doesn't see the data not because we lost it or we failed to load," +
                ' but because we never tried to fetch data.' +
                ' - show button that triggers data fetch'}
            </Typography>

            <div className={classes.featureView}>
              <ZeroStateView primary="Not connected" secondary="Need to load data from the server">
                <Zoom in>
                  <Fab
                    sx={{
                      all: 'unset',
                      '&:hover, &:focus, &:active': {
                        all: 'unset',
                      },
                    }}
                    disableRipple
                    disableFocusRipple
                  >
                    <QButton
                      variant="contained"
                      onClick={() => {
                        dispatch(transactionsActions.getTransactions());
                      }}
                    >
                      Load Data
                    </QButton>
                  </Fab>
                </Zoom>
              </ZeroStateView>
            </div>
          </div>
        )}

        {state === LoadState.INITIAL_LOAD && (
          <div>
            <Typography align="center">
              client has no local data and initial data load is in progress - show skeleton or load progress
            </Typography>

            <div className={classes.featureView}>
              <Skeleton>
                <Typography>real data would be shown here</Typography>
              </Skeleton>
              <Skeleton>
                <Typography>real data would be shown here</Typography>
              </Skeleton>
              <Skeleton>
                <Typography>real data would be shown here</Typography>
              </Skeleton>
            </div>
          </div>
        )}

        {state === LoadState.INITIAL_LOAD_ERROR && (
          <div>
            <Typography align="center">
              {"Initial data load failed. We don't have any local data to show and we don't know if server has any data." +
                " - show 'sorry try again' message"}
            </Typography>

            <div className={classes.featureView}>
              <ZeroStateView
                primary="Not connected"
                secondary="Need to load data from the server"
                button
                report
                onClick={() => {
                  dispatch(transactionsActions.getTransactions());
                }}
              />
            </div>
          </div>
        )}

        {state === LoadState.EMPTY && (
          <div>
            <Typography align="center">
              Data fetch request has been succeed, but server has empty data collection. User need to add/setup data to start using
              feature. - show add/setup button
            </Typography>

            <div className={classes.featureView}>
              <ZeroStateView primary="No data" secondary="Need to add some data to start using feature">
                <Zoom in>
                  <Fab
                    sx={{
                      all: 'unset',
                      '&:hover, &:focus, &:active': {
                        all: 'unset',
                      },
                    }}
                    disableRipple
                    disableFocusRipple
                  >
                    <QButton variant="contained" onClick={noop}>
                      Add/Setup Data
                    </QButton>
                  </Fab>
                </Zoom>
              </ZeroStateView>
            </div>
          </div>
        )}

        {state === LoadState.EMPTY_UPDATE && (
          <div>
            <Typography align="center">
              Incremental data update for empty data collection is in progress. User has no local data - trying to fetch some new
              data from the server. - show skeleton or progress
            </Typography>

            <div className={classes.featureView}>
              <Skeleton>
                <Typography>real data would be shown here</Typography>
              </Skeleton>
              <Skeleton>
                <Typography>real data would be shown here</Typography>
              </Skeleton>
              <Skeleton>
                <Typography>real data would be shown here</Typography>
              </Skeleton>
            </div>
          </div>
        )}

        {state === LoadState.EMPTY_UPDATE_ERROR && (
          <div>
            <Typography align="center">
              {'Incremental data update for empty data collection failed.' +
                " User doesn't have any local data and server fetch failed." +
                "  - show 'try again'"}
            </Typography>

            <div className={classes.featureView}>
              <ZeroStateView primary="No data" secondary="Can't fetch data from server" button report onClick={noop} />
            </div>
          </div>
        )}

        {state === LoadState.READY && (
          <div>
            <Typography align="center">we have some data and it is up to date - show corresponding feature</Typography>

            <div className={classes.featureView}>
              <Typography align="center">
                show corresponding feature here - everything is good - data is setup + downloaded and ready
              </Typography>
            </div>
          </div>
        )}

        {state === LoadState.READY_UPDATE && (
          <div>
            <Typography align="center">data is ready and is updating now - progress (optional)</Typography>

            <LoadingLinearProgress />

            <div className={classes.featureView}>
              <Typography align="center">
                show corresponding feature here - everything is good - data is setup + downloaded and ready
              </Typography>
            </div>
          </div>
        )}

        {state === LoadState.READY_UPDATE_ERROR && (
          <div>
            <Typography align="center">data is ready and update failed - try again (optional)</Typography>

            <ErrorLinearProgress />

            <div className={classes.featureView}>
              <Typography align="center">
                show corresponding feature here - everything is good - data is setup + downloaded and ready
              </Typography>
            </div>
          </div>
        )}
      </Box>
      <Divider />
      Load States Presets:
      <Typography>updateRequiredStates = [{updateRequiredStates.join(', ')}] - load data button</Typography>
      <Typography>skeletonStates = [{skeletonStates.join(', ')}] - show skeleton</Typography>
      <Typography>tryAgainStates = [{tryAgainStates.join(', ')}] - try again button</Typography>
      <Typography>readyStates = [{readyStates.join(', ')}] - show feature</Typography>
      <Divider />
      <br />
      Current transactions load state: {transactionsLoadState} (click Refresh to see it changing)
    </Box>
  );
};

const UndoTriggers = () => {
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => {
        const id = uuidv4();
        const undoAction = { type: 'FAKE_UNDO_ACTION', payload: { fake: 'payload' } };
        dispatch(undoActions.addUndo(undoTypes.mkUndo({
          key: id,
          userMessage: 'Some undoable action complete',
          actions: [undoAction],
        })));
      }}
    >
      Run Undoable Action
    </Button>
  );
};

const NotificationsTriggers = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [newId, setNewId] = useState(1);
  const [existingId, setExistingId] = useState(1);

  const addNotification = useCallback(
    (id, anchorOrigin) => {
      const notifications = OrderedMap([
        [
          id,
          mkNotification({
            id,
            anchorOrigin,
            snackbarContent: SnackbarContent,
            snackbarContentProps: {
              message: <Typography>any custom message {id}</Typography>,
              action: <Button onClick={() => dispatch(notificationsActions.removeNotifications([id]))}>click action {id}</Button>,
              style: { backgroundColor: theme.palette.info.light },
            },
          }),
        ],
      ]);
      dispatch(notificationsActions.addNotifications(notifications));
      setNewId((last) => last + 1);
    },
    [theme, dispatch],
  );

  return (
    <>
      <Button
        onClick={() => {
          const id = uuidv4();
          const notifications = OrderedMap([[id, mkNotification({ id, message: 'hello' })]]);
          dispatch(notificationsActions.addNotifications(notifications));
          setNewId((last) => last + 1);
        }}
      >
        simple message
      </Button>

      <Box display="flex" justifyContent="center">
        <Button onClick={() => addNotification(newId, { horizontal: 'left', vertical: 'top' })}>+ left top</Button>
        <Button onClick={() => addNotification(newId, { horizontal: 'center', vertical: 'top' })}>+ center top</Button>
        <Button onClick={() => addNotification(newId, { horizontal: 'right', vertical: 'top' })}>+ right top</Button>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button onClick={() => addNotification(newId, { horizontal: 'left', vertical: 'bottom' })}>+ left bottom</Button>
        <Button onClick={() => addNotification(newId, { horizontal: 'center', vertical: 'bottom' })}>+ center bottom</Button>
        <Button onClick={() => addNotification(newId, { horizontal: 'right', vertical: 'bottom' })}>+ right bottom</Button>
      </Box>

      <Button
        onClick={() => {
          if (existingId < newId) {
            dispatch(notificationsActions.removeNotifications([existingId]));
          }
          setExistingId((last) => (last < newId ? last + 1 : last));
        }}
      >
        - Notification (REMOVE_NOTIFICATIONS action)
      </Button>
    </>
  );
};

const config = {
  name: 'Data Display',
  id: 'DATA_DISPLAY',
  children: [
    mkSharedComponent({
      name: 'Data Load State (useLoadState hook)',
      id: 'use-load-state',
      searchTags: ['data', 'load', 'state', 'view'],
      component: UseLoadStateSample,
    }),
    mkSharedComponent({
      name: 'Notifications',
      id: 'notifications',
      searchTags: ['notifications', 'snackbar'],
      component: NotificationsTriggers,
    }),
    mkSharedComponent({
      name: 'Undo',
      id: 'undo',
      searchTags: ['undo', 'redo'],
      component: UndoTriggers,
    }),
    mkSharedComponent({
      name: 'Zero State View',
      id: 'ZERO_STATE_VIEW',
      searchTags: ['data', 'initial', 'ZeroStateView'],
      componentPropTypes: ZeroStateView.propTypes,
      component: (props) => <ZeroStateView primary={'Primary Text'} secondary={'Secondary Text'} {...props.exampleProps} />,
      examples: [
        {
          label: 'Default',
          description: 'default zero state view',
          props: {},
        },
        {
          label: 'Problem',
          description: 'zero state view with action button and report link',
          props: { button: true, report: true },
        },
        {
          label: 'Small',
          description: 'small version',
          props: { size: 'small' },
        },
        {
          label: 'Custom children + optional button + textOnTop',
          description: 'custom content with text on top of the icon',
          props: {
            children: 'any custom elements before button',
            textOnTop: true,
            button: 'Custom Button Label',
          },
        },
        {
          label: 'Custom Icon',
          description: 'custom icon',
          props: { icon: GreenThumbImage },
        },
        {
          label: 'Only Primary Text',
          description: 'only shows the primary text under the icon',
          props: { secondary: undefined },
        },
        {
          label: 'Only Secondary Text',
          description: 'only shows the secondary text under the icon',
          props: { primary: undefined },
        },
      ],
    }),

    mkSharedComponent({
      name: 'Dump',
      id: 'DUMP',
      searchTags: ['data', 'show', 'json', 'view', 'development'],
      componentPropTypes: Dump.propTypes,
      examples: [
        {
          label: 'Basic usage',
          description: 'used to display data being used by a component',
          props: {},
        },
        {
          label: 'Custom view',
          description: 'passing children to the Dump component allows for a custom view',
          props: { children: <span style={{ background: 'skyblue' }}>custom span inside dump</span> },
        },
      ],
      component: (props) => (
        <Box position={'relative'} width={240} height={120}>
          <Dump obj={{ example: 'object', dump: 'component' }} {...props.exampleProps} />
          Hold &apos;option&apos; to reveal wrench
          <br />
          NOTE: the parent must have non-static positioning
        </Box>
      ),
    }),

    mkSharedComponent({
      name: 'Highlight Text',
      id: 'HIGHLIGHT_TEXT',
      searchTags: ['focus'],
      componentPropTypes: HighlightText.propTypes,
      examples: [
        {
          label: 'Default',
          description: 'basic usage of highlighting text',
          props: {},
        },
        {
          label: 'Custom style',
          description: 'custom styling for highlight to background yellow',
          props: { highlightComponentProps: { style: { backgroundColor: 'yellow' } } },
        },
        {
          label: 'Custom component',
          description: 'using a custom component for the highlighting',
          props: {
            highlightComponentProps: { variant: QButtonVariantEnum.OUTLINED },
            highlightComponent: QButton,
          },
        },
      ],
      component: (props) => <HighlightText text={'Hello New World, or Not'} searchKeys={['hel', 'or']} {...props.exampleProps} />,
    }),
    mkSharedComponent({
      name: 'QProgressBar',
      id: 'Q_PROGRESS_BAR',
      searchTags: ['bar, chart, budget, planned spending'],
      componentPropTypes: QProgressBar.propTypes,
      component: () => <QProgressBar value={50} total={100} />,
    }),

    mkSharedComponent({
      name: 'QSkeleton',
      description: (
        <Box maxWidth={'70%'}>
          {
            'shows a skeleton of the child component while background processes are running, handled using the "show" prop. Click the toggle button to see when show={false}'
          }
        </Box>
      ),
      id: 'Q_SKELETON',
      searchTags: ['loading', 'waiting', 'skeleton'],
      componentPropTypes: QSkeleton.propTypes,
      defaultState: { show: true },
      component: (props) => (
        <Box display={'flex'} flexDirection={'column'} alignitems={'center'}>
          <QButton onClick={() => props.setstate({ show: !props.state.show })}>Toggle Skeleton</QButton>
          <QSkeleton show={props.state.show}>
            <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
          </QSkeleton>
          <QSkeleton show={props.state.show}>
            <Box width={300}>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
            </Box>
          </QSkeleton>
          <QSkeleton show={props.state.show}>
            <QButton>Button</QButton>
          </QSkeleton>
        </Box>
      ),
    }),

    mkSharedComponent({
      name: 'Loading View',
      id: 'LOADING_VIEW',
      searchTags: ['loading', 'waiting', 'skeleton', 'spinner'],
      componentPropTypes: LoadingView.propTypes,
      examples: [
        {
          label: 'Default',
          props: {},
        },
        {
          label: 'Custom Title',
          props: { title: 'Custom Title' },
        },
        {
          label: 'Custom Style & Children',
          props: {
            circularProgressStyle: { color: 'green' },
            children: <QButton variant={'contained'}>child button</QButton>,
          },
        },
      ],
      component: (props) => <LoadingView {...props.exampleProps} />,
    }),

    mkSharedComponent({
      name: 'Guided Tooltip',
      id: 'GUIDED_TOOLTIP',
      searchTags: ['popper', 'tooltip', 'guided', 'information'],
      componentPropTypes: GuidedTooltip.propTypes,
      defaultState: { anchorEl: null, showPopper: false },
      component: (props) => (
        <>
          <Button
            variant="outlined"
            onClick={(e) => {
              props.setstate({ showPopper: !props.state.showPopper, anchorEl: e.target });
            }}
            style={{ minWidth: 300, height: 400 }}
          >
            click me
          </Button>
          {[
            'bottom',
            'left',
            'right',
            'top',
          ].map((placement) => (
            <GuidedTooltip
              key={placement}
              anchorEl={props.state.anchorEl}
              open={props.state.showPopper}
              placement={placement}
              title={`Guidance tooltip title - placement=${placement}`}
              body={'Body text'}
              cancelLabel={'skip'}
              continueLabel={'next'}
              onContinue={(e) => {
                props.setstate({ showPopper: !props.state.showPopper, anchorEl: e.target });
              }}
              onCancel={(e) => {
                props.setstate({ showPopper: !props.state.showPopper, anchorEl: e.target });
              }}
            />
          ))}

        </>
      ),
    }),

    mkSharedComponent({
      name: 'QPopper',
      id: 'Q_POPPER',
      searchTags: ['popup', 'popover', 'tooltip'],
      componentPropTypes: QPopper.propTypes,
      defaultState: { anchorEl: null, showPopper: false },
      component: (props) => (
        <>
          <Button
            style={{ minWidth: 300, height: 200 }}
            variant="outlined"
            onClick={(e) => {
              props.setstate({ showPopper: !props.state.showPopper, anchorEl: e.target });
            }}
          >
            Anchor - click me
          </Button>
          {[
            'bottom-end',
            'bottom-start',
            'bottom',
            'left-end',
            'left-start',
            'left',
            'right-end',
            'right-start',
            'right',
            'top-end',
            'top-start',
            'top',
          ].map((placement) => (
            <QPopper
              key={placement}
              anchorEl={props.state.anchorEl}
              open={props.state.showPopper}
              placement={placement}
              onClickAway={() => props.setstate({ showPopper: false })}
            >
              {placement}
            </QPopper>
          ))}
        </>
      ),
    }),

    mkSharedComponent({
      name: 'Confetti!!',
      id: 'CONFETTI',
      type: 'COMPONENT',
      description: (
        <div>
          Control showing the confetti with the &apos;show&apos; prop. See more options and usages{' '}
          <a target={'_blank'} href={'https://www.npmjs.com/package/canvas-confetti'}>
            here
          </a>
          . Pass different options and globalOptions as props and they will be injected into the confetti.
        </div>
      ),
      searchTags: ['confetti', 'done', 'celebration', 'completion', 'finish'],
      componentPropTypes: Confetti.propTypes,
      defaultState: { show: true },
      examples: [
        {
          label: 'Without children',
          description: 'injects a <canvas /> component and styles are passed directly to that',
          props: {},
        },
        {
          label: 'With children',
          description: 'wraps children in a div and overlays the confetti on the div again using <canvas />',
          props: {
            children: (
              <Box width={200} height={100}>
                lorem ipsum
                <br />
                <QButton variant={'contained'}>Button</QButton>
              </Box>
            ),
          },
        },
        {
          label: 'With custom options for more confetti',
          description: (
            <span>
              See more options{' '}
              <a target={'_blank'} href={'https://www.npmjs.com/package/canvas-confetti'}>
                here
              </a>
            </span>
          ),
          props: { options: { particleCount: 200 } },
        },
        {
          label: 'variant="cannons"',
          description: 'shoots confetti out of two cannons on the bottom',
          props: { variant: 'cannons', children: <Typography variant={'h2'}>Celebration!!</Typography> },
        },
      ],
      component: (props) => (
        <>
          <QSwitch checked={props.state.show} onChange={() => props.setstate({ show: !props.state.show })} /> show
          <Confetti id={'shared-component-example'} show={props.state.show} {...props.exampleProps} />
        </>
      ),
    }),

    mkSharedComponent({
      name: 'StaticTransactionList',
      id: 'StaticTransactionList',
      searchTags: ['static', 'transaction', 'list'],
      componentPropTypes: StaticTransactionList.propTypes,
      // defaultState: { anchorEl: null, showPopper: false },
      component: StaticTransactionListComponent,
      examples: [{
        label: 'default',
        description: 'default static transactions list',
        props: {},
      }, {
        label: 'skeleton',
        description: 'skeleton view support',
        props: { showSkeleton: true },
      }, {
        label: 'customize columns',
        description: 'cherry pick columns: postedOn, payee, amount',
        props: { orderedColumns: ['postedOn', 'payee', 'amount'] },
      }],
    }),
  ],
};

export default config;
