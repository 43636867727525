import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { List, OrderedMap as Map } from 'immutable';
import { DateTime, Interval } from 'luxon';
import { makeStyles } from 'tss-react/mui';

import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { getTransactionsByFilter } from 'data/transactions/selectors';
import { tracker } from 'companion-app-components/utils/core';
import CalTopHeader from './CalTopHeader';
import CalWeekDaysHeader from './CalWeekDaysHeader';
import CalDaysGrid from './CalDaysGrid';
import CalPrefsModal from './CalPrefsModal';
import CalAccountsPrefsModal from './CalAccountsPrefsModal';
import { dateFormat, weekDays, shortWeekDays, yearFormat, monthFormat } from '../utils';
import { monthlyCalendarStyles } from '../styles';
import { CurrMonth } from '../types';

const useStyles = makeStyles()(monthlyCalendarStyles as Record<string, any>);

interface MonthlyCalendarProps {
  accountIds: Set<Record<string, string>>;
  year: string;
  month: string;
  setDate: (year: string, month: string) => void;
  navFn: (txn: transactionsTypes.CashFlowTransaction) => void;
  showCloseButton: boolean;
  onClose: () => void;
}

function getCurrentMonthData(year, month) {
  const curMonthStr = (year && month) ? `${year}-${month}` : DateTime.local().toFormat(dateFormat.full);
  const curMonthToSet = DateTime.fromFormat(curMonthStr, dateFormat.full);
  const prevDaysOffset = weekDays.findIndex((x) => x === curMonthToSet.weekdayLong);
  const startIntervalDate = curMonthToSet.minus({ days: prevDaysOffset });
  const availableRows = prevDaysOffset + curMonthToSet.daysInMonth! > 35 ? 6 : 5; // 42 = 6*7 spaces | 35 = 5*7 spaces
  const endIntervalDate = startIntervalDate.plus({ days: availableRows * 7 });

  return ({
    date: curMonthToSet,
    dateStr: curMonthStr,
    name: curMonthToSet.toFormat(dateFormat.friendly),
    days: curMonthToSet.daysInMonth,
    startIntervalDate,
    endIntervalDate,
  });
}

function reduceMonthTransactions(transactionsData, currentMonthData) {
  const monthInterval: Record<string, any>[] = Interval.fromDateTimes(currentMonthData.startIntervalDate, currentMonthData.endIntervalDate).splitBy({ days: 1 });
  const datesIntervalMap = Map(monthInterval.map((d) => [d.start.toISODate(), List()]));

  return transactionsData.reduce((map, x) => {
    const txnDate = DateTime.fromISO(x.postedOn).toISODate();
    return map.has(txnDate) ? map.set(txnDate, map.get(txnDate).push(x)) : map;
  }, datesIntervalMap);
}

const MonthlyCalendar: React.FC<MonthlyCalendarProps> = (props) => {
  const { classes } = useStyles();
  const { navFn, setDate, year, month, accountIds, showCloseButton, onClose } = props;

  const cachedYear = useMemo(() => year, [year]);
  const cachedMonth = useMemo(() => month, [month]);
  const cachedAccountIds = useMemo(() => accountIds, [accountIds]);
  

  const transactionsData = useSelector((state) => {
    if (!cachedAccountIds || !cachedAccountIds?.size) return Map();
    return getTransactionsByFilter(state, { accountIdsArray: cachedAccountIds });
  });


  const cachedTransactionsData = useMemo(() => transactionsData, [transactionsData]);

  const [curMonth, setCurMonth] = useState<CurrMonth>();
  const [daysTxsMap, setDaysTxsMap] = useState(null);
  const [showCalPrefs, setShowCalPrefs] = useState(false);
  const [showAccountPrefs, setShowAccountPrefs] = useState<boolean | null>(null);

  const updateDateState = useCallback((newMonth, where) => {
    setDate(newMonth.toFormat(yearFormat), newMonth.toFormat(monthFormat));
    tracker.track(tracker.events.calendarOnDateChanged, { date: newMonth.toISODate(), where });
  }, [setDate]);

  const onTransactionClick = useCallback((txn: transactionsTypes.CashFlowTransaction) => {
    if (navFn) navFn(txn);
    tracker.track(tracker.events.calendarOnTransactionClick, { txnId: txn.id, where: 'Transaction Modal Calendar View' });
  }, [navFn]);

  const onPrefsOpen = useCallback(() => {
    setShowCalPrefs(true);
    tracker.track(tracker.events.calendarPrefModalOpen);
  }, [setShowCalPrefs]);

  const onPrefsClose = useCallback(() => {
    setShowCalPrefs(false);
  }, [setShowCalPrefs]);

  const onAccountsPrefsModalOpen = useCallback(() => {
    setShowAccountPrefs(true);
    tracker.track(tracker.events.calendarAccountsPrefsModalOpen);
  }, [setShowAccountPrefs]);

  const onAccountsPrefsModalClose = useCallback(() => {
    setShowAccountPrefs(false);
  }, [setShowAccountPrefs]);

  // mounted or updated
  useEffect(() => {
    const currentMonthData = getCurrentMonthData(cachedYear, cachedMonth);
    setCurMonth(currentMonthData);
    setDaysTxsMap(reduceMonthTransactions(cachedTransactionsData, currentMonthData));
  }, [cachedYear, cachedMonth, cachedTransactionsData]);

  useEffectOnce(() => {
    if (accountIds.size === 0 && showAccountPrefs === null) {
      onAccountsPrefsModalOpen();
    }
  });

  const cachedCurMonth = useMemo(() => curMonth, [curMonth]);
  const cachedDaysTxsMap = useMemo(() => daysTxsMap, [daysTxsMap]);

  return (
    <div className={classes.root}>
      <CalTopHeader
        curMonth={curMonth}
        updateDateState={updateDateState}
        onPrefsOpen={onPrefsOpen}
        onAccountsPrefsModalOpen={onAccountsPrefsModalOpen}
        showCloseButton={showCloseButton}
        onClose={onClose}
      />
      <div className={classes.legendsRoot}>
        <span className={classes.income}>Income</span>
        <span className={classes.expense}>Expense</span>
        <span className={classes.overdue}>Overdue</span>
        <span className={classes.normal}>Transactions</span>
      </div>
      <CalWeekDaysHeader days={shortWeekDays} />
      <CalDaysGrid
        curMonth={cachedCurMonth}
        daysTxsMap={cachedDaysTxsMap}
        onTransactionClick={onTransactionClick}
        accountIds={cachedAccountIds}
      />
      {showCalPrefs && <CalPrefsModal onClose={onPrefsClose} />}
      {showAccountPrefs && <CalAccountsPrefsModal onClose={onAccountsPrefsModalClose} />}
    </div>
  );
};

export default React.memo(MonthlyCalendar);
