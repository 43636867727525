const styles = ({ spacing, palette }) => ({
  gettingStartedModuleRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonArea: {
    marginBottom: spacing(),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  setupButton: {
    borderRadius: 3,
    marginTop: spacing(5),
    color: palette.text.lightContrast,
    backgroundColor: palette.secondary.main,
  },
  head: {
    minWidth: '150px',
  },
  headerContent: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  },
});

export default styles;
