export const checkLocalStorageAccess = (suppressErrors = false) => {
  let localStorageOK = false;
  try {
    const pattern = 'test if we can write some data to localStorage';
    localStorage.setItem('check', pattern);
    const check = localStorage.getItem('check');
    if (check === pattern) {
      localStorage.removeItem('check');
      localStorageOK = true;
    }
  } catch (error: any) {
    if (!suppressErrors) {
      assert(false, error);
    }
    if (error.code === 22 || error.name === 'NS_ERROR_FILE_CORRUPTED') {
      // QuotaExceededError / File error: Corrupted
      localStorage.clear();
      localStorageOK = true;
    }
  }

  return localStorageOK;
};
