import isAcme from 'isAcme';
import { sharedStyles } from 'components/TransactionRegister/sharedStyles';

export const styles = (theme) => ({
  ...sharedStyles(theme),
  helpIcon: {
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.greyScaleDeprecated[2],
  },
  dialogRoot: {
    zIndex: '1501 !important',
  },
  headerContainer: {
    height: isAcme ? undefined : 67,
    background: isAcme ? undefined : theme.palette.grey.level2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px 8px 0px 0px',
    padding: theme.spacing(1),
    borderBottom: isAcme ? `1px solid ${theme.palette.divider}` : 'none',
  },
  headerCheckbox: {
    paddingTop: 2,
  },
  headerCheckboxBorder: {
    border: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey.level3,
  },
  headerLabel: {
    display: 'flex',
    marginLeft: 7,
    fontSize: isAcme ? undefined : '14px',
    fontWeight: isAcme ? undefined : 500,
    color: isAcme ? undefined : theme.palette.text.primary,
  },
  payeePopper: {
    zIndex: theme.zIndex.mobileStepper,
  },
  inputRoot: {
    fontSize: theme.components.register.fontSize.default,
  },
  payeeRoot: {
    height: 20,
    margin: '4px 0',
  },
  autoCompleteRoot: {
    width: '100%',
    overflow: 'hidden',
  },
});
