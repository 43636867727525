
export const styles = (theme) => ({
  resourcesContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  listRoot: {
    padding: 7,
    width: 'inherit',
    border: 'none',
    borderRadius: 5,
    boxSizing: 'unset',
  },
  header: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '4px 16px 4px 16px',
  },
  title: {
    flex: 1,
  },
  actionText: {
    textAlign: 'end',
    flex: 1,
    color: theme.palette.secondary.main,
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
  pickerContainer: {
    height: 'inherit',
    overflowY: 'scroll',
    textAlign: 'center',
  },
});
