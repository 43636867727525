import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((_theme) => ({
  highLight: {
    fontWeight: 500,
  },
  wrapper: {
    whiteSpace: 'pre-wrap',
  },
}));

const HighlightText = (props) => {
  const { text: originalText = '', searchKeys = [], highlightComponent: HighlightComponent, highlightComponentProps, ...rootProps } = props;

  const { classes } = useStyles();
  const [splits, setSplits] = useState([]);

  useEffect(() => {
    let fromIndex = 0;
    let indexFound = 0;
    const text = originalText?.toLowerCase();
    const keys = searchKeys.map((searchKey) => searchKey?.toLowerCase());
    const newSplits = [];
    do {
      const searchResult = keys.reduce((bestResult, key) => { // eslint-disable-line no-loop-func
        if (key) {
          const index = text.indexOf(key, fromIndex);
          if (index >= 0 && (!bestResult || bestResult.index > index)) {
            return { key, index };
          }
        }
        return bestResult;
      }, undefined);
      indexFound = searchResult ? searchResult.index : -1;
      const stringNotMatch = originalText?.substring(fromIndex, indexFound >= 0 ? indexFound : originalText.length);
      newSplits.push({ label: stringNotMatch, highlight: false });
      if (indexFound >= 0) {
        const keyFound = searchResult.key;
        const stringMatch = originalText.substring(indexFound, indexFound + keyFound.length);
        newSplits.push({ label: stringMatch, highlight: true });
        fromIndex = indexFound + keyFound.length;
      }
    } while (indexFound >= 0);
    setSplits(newSplits);
  }, [originalText, searchKeys, HighlightComponent, highlightComponentProps]);

  return (
    <span className={classes.wrapper} sharedcomponentid={'HIGHLIGHT_TEXT'} {...rootProps}>
      {splits.map((split, partIndex) => {
        const key = `${partIndex}-${split.label}`;
        return (split.highlight ?
          <HighlightComponent
            className={classes.highLight}
            {...highlightComponentProps}
            key={key}
          >
            {split.label}
          </HighlightComponent>
          :
          split.label);
      })}
    </span>
  );
};

HighlightText.defaultProps = {
  highlightComponent: 'span',
};

HighlightText.propTypes = {
  text: PropTypes.string,
  searchKeys: PropTypes.array,
  highlightComponent: PropTypes.any,
  highlightComponentProps: PropTypes.object,
};

export default HighlightText;
