const styles = (theme: Object) => ({
  bigQuestion: {
    fontSize: 16,
    paddingBottom: 16,
  },
  challenge: {
    '&:not(:first-child)': {
      marginTop: 24,
    },
  },
  dropdownSpace: {
    paddingLeft: 10,
    paddingRight: 8,
  },
  lgFormContent: {
    marginLeft: 124,
    marginRight: 204,
  },
  formLabel: {
    border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
    '&:hover': {
      background: theme.palette.greyScaleDeprecated[6],
    },
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
  },
  grayText: {
    color: theme.palette.text.secondary,
  },
  iconLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  littleQuestion: {
    paddingBottom: 10,
  },
  menu: {
    maxHeight: 284,
    overflow: 'auto',
    // I realize these are technically non-standard, but I figure it's better to have ideal functionality for some users
    // and standard functionality for others than to have everyone adhere to an undesirable standard (looking at you I.E.).
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      WebkitBoxShadow: '0 0 1px rgba(245, 245, 245, 0.5)',
    },
  },
  messageLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    alignItems: 'center',
    height: 24,
    maxWidth: 620,
    '& svg': {
      alignSelf: 'start',
    },
  },
});

export default styles;
