// @flow
import React from 'react';

import { makeStyles } from 'tss-react/mui';

import bankIcon from 'assets/nav-menu/accounts.svg';

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  containerForButtonStyle: {
    height: '64px',
    width: '102px',
  },
  mdContainerForIconStyle: {
    borderRadius: '50%',
    height: '64px',
    width: '64px',
  },
  smContainerForIconStyle: {
    borderRadius: '50%',
    height: '48px',
    width: '48px',
  },
  genericImageButtonStyle: {
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    border: '1px solid rgba(151,151,151,0.4)',
    borderRadius: '6px',
  },
  img: {
    width: '100%',
    minHeight: '1px',
  },
}));

export function MacroCaseToPascalCase(string) {
  let result = '';

  const words = string?.toLowerCase().split('_');
  for (let i = 0; i < words.length; i++) {
    result += words[i][0].toUpperCase() + words[i].substring(1);
  }

  return result;
}

type Props = {
  institution: any,
  size: 'sm' | 'md',
}

const FiIcon = (({ institution, size = 'md' }: Props) => {
  const { classes, cx } = useStyles();

  let iconSrc;
  let iconAltName;

  const iconClassNames = [];

  if (institution?.logoUrl) {
    iconSrc = institution.logoUrl;
    iconAltName = institution.name;
  } else if (institution?.logo) {
    iconSrc = `data:image/svg;base64, ${institution.logo}`;
    iconAltName = institution.name;
  } else {
    iconSrc = bankIcon;
    iconClassNames.push(classes.genericImageButtonStyle);
    iconAltName = institution?.name || 'Generic Bank Icon';
  }
  if (institution?.logoStyle === 'BUTTON') {
    iconClassNames.push(classes.containerForButtonStyle);
  } else {
    iconClassNames.push(classes[`${size}ContainerForIconStyle`]);
  }

  return (
    <div className={cx(classes.container, iconClassNames)}>
      <img alt={iconAltName} src={iconSrc} className={classes.img} />
    </div>
  );
});

export const FiIconSize = (institution, size = 'md') => {
  if (institution?.logoStyle === 'BUTTON') {
    return { height: 64, width: 102 };
  }
  return size === 'sm'
    ? { height: 48, width: 48 }
    : { height: 64, width: 64 };
};

export default FiIcon;
