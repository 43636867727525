import styled from 'styled-components';
import { isAcme } from 'isAcme';

import { sharedStyles } from '../sharedStyles';

/* ================ REGISTER FIELDS ====================== */

export const EXPANSION_PANEL_HEIGHT = ({ theme }) => theme.components.register.sectionHeaderHeight;

export const MIN_TX_HEIGHT = {
  comfortable: isAcme ? 56 : 46,
  normal: 40,
  compact: 35,
};

// box-shadow: #777 0px 1px 2px;
export const RegisterContainer = styled.div`
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  &.tightmargins {
    margin: 5px 0px 0px 10px;
  }
`;

// TODO Put in Styles file
export const styles = (theme) => ({

  ...sharedStyles(theme),

  txFooter: {
    height: 30,
  },
  classNameProps: {
    height: 18,
    color: theme.palette.greyScaleDeprecated[0],
    marginLeft: 2,
  },
  txSepSummary: {
    width: '100%',
    display: 'block',
    marginBottom: 0,
    background: theme.components.register.sectionHeaderColor,
    cursor: 'pointer',
    color: theme.palette.greyScaleDeprecated[0],
    borderBottom: `solid 1px ${theme.components.register.sectionHeaderBorder}`,
    borderRadius: '0px',
    marginTop: '-1px',
    minHeight: `${theme.components.register.sectionHeaderHeight}px`,
    lineHeight: `${theme.components.register.sectionHeaderHeight}px`,
    '& div': {
      marginTop: 0,
      marginBottom: 0,
    },
    '&.collapsed': {
      background: theme.components.register.sectionHeaderColor,
    },
    paddingLeft: 0,
  },
  txSepIcon: {
    padding: 5,
  },
  txSepHeading: {
    display: 'inline-block',
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.greyScaleDeprecated[0],
    padding: isAcme ? '8px 0px' : '0px 0px',
  },
  clickable: {
    cursor: 'pointer',
  },
  progressBar: {
    width: '50%',
    height: 13,
    marginLeft: 20,
    marginTop: 10,
  },
  loadingStateContainer: {
    display: 'flex',
    width: '100vw',
    height: theme.defaults.content.height,
    justifyContent: 'center',
    alignItems: 'center',
  },
  zeroStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: 120,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    color: theme.palette.greyScaleDeprecated[2],
    margin: 'auto',
  },
  zeroStateTextHeadline: {
    marginBottom: 10,
    fontWeight: 50,
  },
  zeroStateTextBody: {
    textAlign: 'center',
    maxWidth: '80%',
  },

  VL: {
    background: isAcme ? theme.palette.background.paper : theme.palette.greyScaleDeprecated[6],
    // overflow: 'auto',
    '& > div': {
      background: isAcme ? theme.palette.background.paper : theme.palette.greyScaleDeprecated[7],
    },
    overflowX: 'hidden',
    // '&::-webkit-scrollbar-thumb': {
    //   borderRadius: 8,
    //   backgroundColor: 'rgba(0,0,0, 0.2)',
    //   '&:hover': {
    //     backgroundColor: 'rgba(0,0,0, 0.4)',
    //   },
    //   border: '3px solid transparent',
    //   backgroundClip: 'content-box',
    // },
    // '&::-webkit-scrollbar': {
    //   width: 15,
    // },
    // scrollbarWidth: 'thin',
  },
});




