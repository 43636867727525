
import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import { getOrCreateStringFromObject } from 'utils/objectToKeyUtils';

let memoCache = ImmutableMap();
let keyMap = ImmutableMap();

/*
 * memoizeWithKey Functions
 *
 * Memoize functions where you can control both the key, and the size of the cache
 */

export function memoizeWithObjectKey(cacheName, valueFn, objectKey, maxSize) {
  return memoizeWithKey(cacheName, valueFn, getOrCreateStringFromObject(cacheName, objectKey, maxSize));
}


export function memoizeWithKey(cacheName, valueFn, key, maxSize = 10) {

  const thisCache = memoCache.get(cacheName);
  if (thisCache) {
    const index = thisCache.findIndex((x) => x.key === key);
    if (index !== -1) return thisCache.get(index).value;
  }

  const ret = valueFn();
  memoizeWithKeySetCache(cacheName, key, ret, maxSize);
  return ret;
}

/*
 *  MEMOIZE WITH KEYS (multiple) SET CACHE
 */
//
// This allows you to create a SHARED cache, where multiple cache keys refer to the same value
// This is used to save space/memory when you need to store a single object under multiple keys
//
export function memoizeWithKeysSetCache(cacheName, keys, value, maxSize) {

  const mainKey = ImmutableList(keys).first();
  memoizeWithKeySetCache(cacheName, mainKey, value, maxSize);

  // Erase all keys associated with mainKey
  const keysToDelete = keyMap.filter((x) => x === mainKey);
  // console.log("Deleting from keyMap ", keysToDelete);
  keysToDelete.forEach((item, key) => {
    keyMap = keyMap.delete(key);
  });

  // Add keys associated with main Key
  keys.slice(1).forEach((associatedKey) => {
    keyMap = keyMap.set(associatedKey, mainKey);
  });
}

/*
 *  MEMOIZE WITH KEY SET CACHE
 */
export function memoizeWithKeySetCache(cacheName, key, value, maxSize = 10) {

  let thisCache = memoCache.get(cacheName);

  // if this key already exists, remove it
  if (thisCache) {
    const index = thisCache.findIndex((x) => x.key === key);
    if (index !== -1) thisCache = thisCache.splice(index, 1);
  }

  if (!thisCache) thisCache = ImmutableList([]);
  thisCache = thisCache.push({ key, value });
  if (thisCache.size > maxSize) {
    thisCache = thisCache.slice(1);
  }
  memoCache = memoCache.set(cacheName, thisCache);
}

/*
 *  MEMOIZE WITH KEY RETRIEVE CACHE
 */
export function memoizeWithKeyRetrieveCache(cacheName, key) {

  const thisCache = memoCache.get(cacheName);
  if (!thisCache) return undefined;

  let index = thisCache.findIndex((x) => x.key === key);
  if (index !== -1) return thisCache.get(index).value;

  // not found, is this an associated key?
  const associatedKey = keyMap.get(key);
  if (associatedKey) {
    index = thisCache.findIndex((x) => x.key === associatedKey);
    if (index !== -1) return thisCache.get(index).value;
  }
  return null;
}
