
export const styles = (theme) => ({
  loadingStateContainer: {
    display: 'flex',
    width: '100vw',
    height: theme.defaults.content.height,
    justifyContent: 'center',
    alignItems: 'center',
    '&.hoverClass': {
      visibility: 'hide',
    },
    '&:hover': {
      '&.hoverClass': {
        visibility: 'show',
      },
    },
  },
  wrapper: {
    height: '100%',
  },
  zeroStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    color: theme.palette.greyScaleDeprecated[2],
    margin: 'auto',
  },
  zeroStateTextHeadline: {
    marginBottom: 10,
    fontWeight: 500,
  },
  zeroStateTextBody: {
    textAlign: 'center',
    maxWidth: '80%',
  },
  strong: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  mergeTxnsBody: {
    fontSize: '18px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    lineHeight: 1.75,
  },
});
