import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';
const confettiPackage = require('canvas-confetti');

const PRESET_OPTIONS = {
  falling: (conf, otherOptions) => {
    conf({
      origin: { x: 0.5, y: -0.1 },
      spread: 160,
      particleCount: 75,
      ...otherOptions,
    });
  },
  cannons: (conf, otherOptions) => {
    conf({
      particleCount: 5,
      angle: 50,
      spread: 30,
      origin: { x: 0, y: 0.8 },
      startVelocity: 25,
      ...otherOptions,
    });
    conf({
      particleCount: 5,
      angle: 130,
      spread: 30,
      origin: { x: 1, y: 0.8 },
      startVelocity: 25,
      ...otherOptions,
    });
  },
};
const PRESET_DELAYS = {
  falling: 400,
  cannons: 50,
};

const Confetti = (props) => {
  const { id, show, options, globalOptions, style, children, duration, variant, ...otherProps } = props;

  const frameId = useRef();
  const canvasRef = useRef();
  const startTime = useRef();
  const theme = useTheme();

  const frameFunc = useCallback(() => {
    if (canvasRef.current) {
      PRESET_OPTIONS[variant](canvasRef.current.confetti, { colors: theme.palette.confetti, ...options });
    }
    if (startTime.current + duration > DateTime.local().toMillis()) {
      setTimeout(() => {
        frameId.current = requestAnimationFrame(frameFunc);
      }, PRESET_DELAYS[variant]);
    }
  }, [options, theme.palette.confetti, duration, variant]);

  useEffect(() => {
    if (show && canvasRef.current) {
      canvasRef.current.confetti = canvasRef.current?.confetti || confettiPackage.create(canvasRef.current, { resize: true, ...globalOptions });
      startTime.current = DateTime.local().toMillis();
      if (frameId.current) cancelAnimationFrame(frameId.current);
      frameId.current = requestAnimationFrame(frameFunc);
    } else if (frameId.current) {
      cancelAnimationFrame(frameId.current);
      frameId.current = null;
    }
  }, [show, frameFunc, globalOptions]);

  const canvasComponent = useMemo(() => (
    <canvas
      {...otherProps}
      ref={canvasRef}
      id={`confetti-canvas-${id}`}
      sharedcomponentid={'CONFETTI'}
      style={children ?
        {
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 1,
        }
        :
        style}
    />), [id, otherProps, children, style]);

  const containerStyles = useMemo(() => ({ position: children ? 'relative' : 'initial', ...style }), [style, children]);

  if (children) {
    return show ?
      (
        <div sharedcomponentid={'CONFETTI'} {...otherProps} style={containerStyles}>
          {canvasComponent}
          {children}
        </div>
      )
      :
      <div sharedcomponentid={'CONFETTI'}>
          {children}
        </div>;
  }
  return show ? canvasComponent : null;
};
Confetti.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool,
  options: PropTypes.object,
  globalOptions: PropTypes.object,
  duration: PropTypes.number,
  variant: PropTypes.oneOf(['cannons', 'falling']),
};
Confetti.defaultProps = {
  options: {},
  globalOptions: {},
  variant: 'falling',
  duration: 10000,
};

export default Confetti;
