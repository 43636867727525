import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ScheduledTransactionFormQuicken from 'components/ScheduledTransactions/ScheduledTransactionFormQuicken';

interface ScheduledTransactionFormContainerProps {
  classes?: Record<string, any>;
  scheduledTransaction: Record<string, any>;
  onClose: (isFormDirty?: boolean) => void;
  onSubmit: (object?: Record<string, any>) => void;
  deleteUserVerificationFromPayload?: boolean;
  hideDeleteIcon?: boolean;
}

// eslint-disable-next-line react/function-component-definition
const ScheduledTransactionFormContainer: React.FC<ScheduledTransactionFormContainerProps> = (props) => {
  const [isConfirmationDialogOpen, setConfirmationDialog] = React.useState(false);
  const [isFormDirty, setDirtyForm] = React.useState(false);

  return (
    <Dialog
      open={Boolean(props.scheduledTransaction)}
      onClose={() => props?.onClose?.(isFormDirty)}
      aria-labelledby="edit-recurrence-dialog"
    >
      <ScheduledTransactionFormQuicken
        {...props}
        label={(props.scheduledTransaction || {}).id ? 'Edit Series' : 'Add New Series'}
        showCloseButton
        isConfirmationDialogOpen={isConfirmationDialogOpen}
        showConfirmationDialog={setConfirmationDialog}
        setDirtyForm={setDirtyForm}
        isFormDirty={isFormDirty}
      />
    </Dialog>
  );
};

export default ScheduledTransactionFormContainer;
