/* eslint react/prop-types: 0 */
import React from 'react';
import { withStyles } from 'tss-react/mui';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxSelected from 'assets/checkbox/checkboxSelected.inline.svg';
import CheckBoxNotSelected from 'assets/checkbox/checkboxNotSelected.inline.svg';

const styles = (_theme) => ({
  root: {
    padding: 6,
  },
  boxBorder: {
    borderRadius: 4,
    overflow: 'hidden',
    padding: 0,
  },
});


const QCheckbox = ({ size = 24, classes, ...other }) => {

  const viewDelta = (24 - size) / 4;
  const viewBox = `${viewDelta} ${viewDelta} ${24 - viewDelta} ${24 - viewDelta}`;
  return (
    <div className={classes.root}>
      <Checkbox
        classes={{ root: classes.boxBorder }}
        icon={<CheckBoxNotSelected width={size} height={size} viewBox={viewBox} />}
        checkedIcon={<CheckBoxSelected width={size} height={size} viewBox={viewBox} />}
        {...other}
        style={{ padding: 0 }}
      />
    </div>
  );
};

export default withStyles(QCheckbox, styles);
