/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-foreign-prop-types */

const config = {
  name: 'Feedback',
  id: 'FEEDBACK',
  children: [],
};

export default config;
