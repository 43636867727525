/*
 ****************************************************************
 * CACHE FUNCTIONS
 */
const cacheBlob = [];
let MAX_CACHE_SIZE = 10;  // don't abuse this

export function setMaxCacheSize(size) {
  MAX_CACHE_SIZE = size;
}

export function cacheableObject(blob) {
  return getCacheObject(null, blob) || blob;
}

export function getCacheObject(_key, blob = null) {

  let key = _key;
  if (!key && blob) {
    key = makeCacheKey(blob);
  } else {
    return blob;
  }
  const obj = cacheBlob.find((x) => x.key === key);
  if (obj) {
    return obj.blob;
  }
  if (blob) {
    setCacheObject(blob, key);
    return blob;
  }
  return blob;
}

export function setCacheObject(blob, _key = null) {

  const key = _key || makeCacheKey(blob);
  if (!getCacheObject(key)) {
    if (cacheBlob.length > MAX_CACHE_SIZE) {
      // make room by pushing out the oldest cache item
      cacheBlob.slice(1);
    }
    cacheBlob.unshift({
      key,
      blob,
    });
  }
}

export function makeCacheKey(blob) {
  let key = 'key:';
  // console.log("CACHE Make Cache Key ", blob, Array.isArray(blob));

  // if this is an immutable (can iterate) or this is a JS array
  if (blob.size !== undefined || Array.isArray(blob)) {
    blob.forEach((item) => {
      key = `${key}${stringHash(JSON.stringify(item))}`;
    });
  } else {
    key = `${key}${stringHash(JSON.stringify(blob))}`;
  }
  return key;
}

/* eslint-disable no-bitwise */   // because it's FAST
function stringHash(str) {
  let hash = 0;
  let i;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32 bit integer
  }
  return Math.abs(hash).toString(36);
}
