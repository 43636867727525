
export const ALL_THEME_OVERRIDES = {
  // insert new themes below following the pattern of:
  // [THEME_KEY]: THEME,
  muiDefault: {},
  muiDark: { palette: { mode: 'dark' } },
};

export function getThemeOverrideByKey(themeKey) {
  return ALL_THEME_OVERRIDES[themeKey] || {};
}
