import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles()((_theme) => ({
  hookElement: {
    position: 'absolute',
    top: -4,
    left: -4,
    width: 'calc(100% + 8px)',
    height: 'calc(100% + 8px)',
    // background: 'transparent',
    background: 'rgba(4, 255, 0, 0.15)',
    border: '2px solid limegreen',
    borderRadius: 8,
    '&:hover > div': {
      // reusable component wrapper created in hacky dynamic way and doesn't have theme - have to hardcode values
      color: '#3653D5', // theme.palette.primary.main,
      background: '#fff', // theme.palette.background.paper,
      display: 'flex',
    },
  },
}));

const ReusableComponentTag = (props) => {
  const { id } = props;

  const rootRef = useRef(null);

  const { classes } = useStyles();

  const handleClick = (e) => {
    if (e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      window.location.pathname = `/docs/${id}`;
    }
  };

  if (!process.env.NODE_ENV === 'development') return null;

  return (
    <Tooltip enterDelay={0} placement={'top'} arrow title={id}>
      <div role={'presentation'} onClick={handleClick} className={classes.hookElement} ref={rootRef} id={`rct-${id}`} />
    </Tooltip>
  );
};
ReusableComponentTag.propTypes = {
  id: PropTypes.string,
};

export default ReusableComponentTag;
