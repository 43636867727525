
import React, { FC, MouseEvent } from 'react';
import { makeStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';

import QButton from 'components/QButton';
import SetupStatusBar from 'components/SetupStatusBar';
import QCloseBox from 'components/QCloseBox';

import GettingStartedModule from 'components/GettingStartedModule';

import successImage from 'assets/webFirst/accounts-success.png';

import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface GettingStartedModulePageProps {
  onCompleted: (() => void) | null;
  config: Record<string, any>;
  onClose: () => void;
  skipText: string;
  onButtonClick: (event: MouseEvent<HTMLButtonElement>, value: string) => void;
  showSuccess: boolean;
}

const GettingStartedModulePage: FC<GettingStartedModulePageProps> = ({ skipText, showSuccess, config, onClose, onButtonClick, onCompleted }) => {

  const { classes } = useStyles();

  return (
    <Dialog
      open
      hideBackdrop={Boolean(showSuccess && config.successQCard)}
      disableEscapeKeyDown={config.noSkip}
      onClose={onClose}
      maxWidth="md"
      classes={{ paperWidthMd: classes.paperWidthMd }}
      BackdropProps={{ style: { opacity: 0.4 } }}
    >
      <QCloseBox onClose={onClose} id="set-up-your-quicken-close-button" />

      <div style={{ height: '100%', padding: '10px 48px' }}>
        {!showSuccess &&
          <>
            <SetupStatusBar
              classes={{ root: classes.statusBar }}
              titleOnTop
              selected={config.page}
            />
            <GettingStartedModule config={config} />
          </>}
        {showSuccess &&
          <div style={{ textAlign: 'center' }}>
            <Typography className={classes.title} style={{ marginTop: 15 }}>
              Awesome!
            </Typography>
            <Typography className={classes.subTitle}>
              {config.success}
            </Typography>
            <img
              alt="Connection success"
              src={successImage}
              style={{ marginTop: 20 }}
            />
            <Divider
              style={{ margin: 20, marginTop: 45, marginBottom: 45 }}
            />
            <Typography className={classes.successTitle}>
              {config.successSub.title}
            </Typography>
            <Typography className={classes.successSubTitle}>
              {config.successSub.subTitle}
            </Typography>
          </div>}
      </div>

      <div className={classes.buttonArea}>
        {config.buttons.map((btn) =>
          <div key={`SETUPBTN: ${btn.value}`}>
            <QButton
              variant={showSuccess ? null : 'contained'}
              classes={{ conButton: classes.setupButton, root: showSuccess ? classes.skipTextButton : classes.skipSecondaryTextButton }}
              onClick={(e) => onButtonClick(e, btn.value)}
              id={btn.id}
            >
              {btn.label}
            </QButton>
          </div>)}
        {onCompleted &&
          <div>
            <QButton
              variant={showSuccess ? 'contained' : null}
              classes={{ conButton: classes.setupButton, root: showSuccess ? classes.skipSecondaryTextButton : classes.skipTextButton }}
              onClick={onCompleted}
              id={`get-started-${skipText?.toLowerCase()}-button`}
            >
              {skipText}
            </QButton>
          </div>}
      </div>

      {!showSuccess && config.caption &&
        <Typography className={classes.bottomCaption} variant="caption">{config.caption}</Typography>}
    </Dialog>
  );
};

export default GettingStartedModulePage;
