import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { removeDialog } from 'companion-app-components/flux/root-ui/rootUiActions';

import { DIALOG_TYPE_CONFIRMATION_NEW } from './actions';

import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface ConfirmationDialogProps {
  heading: string;
  subHead: string;
  description: string;
  buttons: {
    label: string;
    variant: 'text' | 'outlined' | 'contained';
    action: () => void;
  }[];
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({ heading, subHead, description, buttons }) => {

  const dispatch = useDispatch();
  const { classes, cx } = useStyles();

  const handleClose = () => {
    dispatch(removeDialog(DIALOG_TYPE_CONFIRMATION_NEW));
  };

  return (
    <Dialog
      open
      sx={{
        '& .MuiPaper-root': {
          padding: '0px 30px',
          minWidth: 520,
        },
      }}
    >
      <DialogTitle className={classes.dialogTitleWrapper}>
        <Typography variant="h6" className={classes.title}>
          {heading}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className={classes.closeButton}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogContent className={classes.confirmationContents}>
        <Typography gutterBottom variant="h6" className={cx(classes.weight400, classes.title)}>
          {subHead}
        </Typography>
        <Typography
          gutterBottom
          variant="body2"
          className={classes.textTertiary}
        >
          {description}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        {buttons.map((button) => (
          <Button
            key={button.label}
            onClick={button.action}
            variant={button.variant}
            size="small"
          >
            {button.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
