export const qCards = {
  firstCard: {
    elementId: 'budgetAmt:Miscellaneous Personal Income',
    scrollIntoView: true,
    edge: 'right',
    nudge: { left: 10 },
    width: 284,
    height: 220,
    title: 'Budget Your Income',
    next: 'qcard2',
    content: 'If you want, you can just put an overall amount here for income, or budget specific categories and use ' +
      'this entry to represent additional income you plan to receive.',
  },
  qcard2: {
    elementId: 'budgetAmt:Miscellaneous Personal Expenses',
    scrollIntoView: true,
    edge: 'right',
    nudge: { left: 10 },
    width: 284,
    height: 220,
    title: 'Budget Your Expenses',
    prev: 'firstCard',
    next: 'qcard3',
    content: 'Similarly, you can just put an overall amount here for your anticipated spending, or budget specific ' +
      'categories, and use this entry to represent additional spending you want to plan for.',
  },
  qcard3: {
    elementId: 'edit-budgets-suggested-categories',
    edge: 'leftbottom',
    width: 380,
    height: 200,
    title: 'Choose From the Suggested Categories',
    prev: 'qcard2',
    next: 'qcard4',
    content: 'Click here to see some suggested categories and amounts based on your prior spending, and simply ' +
      'click on any of them to add them to your budget.  You can then easily fine tune the amount.',
  },
  qcard4: {
    elementId: 'edit-budgets-add-category',
    edge: 'leftbottom',
    width: 380,
    height: 200,
    title: 'Budget a Specific Category',
    prev: 'qcard3',
    content: 'Click here to choose a specific category to budget.  You will be shown your historical spending ' +
      'and average spending to help you determine your budget amounts.',
  },
};
