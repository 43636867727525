import { delay, put, takeLatest, takeEvery, select } from 'redux-saga/effects';

import { getLogger } from 'companion-app-components/utils/core';
import { resourceStoreTypes, resourceSagaUtils } from 'companion-app-components/flux/core';
import { authSelectors } from 'companion-app-components/flux/auth';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import * as actions from 'data/syncSentiment/syncSentimentActions';
import { triggerSyncSentimentsDialog } from 'components/Dialogs/SyncSentiments/actions';

const log = getLogger('data/syncSentiment/syncSentimentSagas');

function* checkIfSyncSentimentSurveyRequested() {
  const featureFlags = yield select(featureFlagsSelectors.getFeatureFlags);
  const syncSurveyDelay: number = featureFlags.get('syncSentiment');
  if (syncSurveyDelay > 0) {
    log.log('checkIfSyncSentimentSurveyRequested...');
    const authSession = yield select(authSelectors.getAuthSession);
    if (authSession.syncSurveyRequested) {
      log.log(`Show Sync Sentiment Survey...in ${syncSurveyDelay} msec`);
      yield delay(syncSurveyDelay);

      log.log('Show Sync Sentiment Survey...');
      yield put(triggerSyncSentimentsDialog());
    }
  }
}

function* checkIfSyncSentimentSurveyRequestedActionWatcher(): Generator<*, *, *> {
  yield takeLatest(actions.checkIfSyncSentimentSurveyRequestedAction, checkIfSyncSentimentSurveyRequested);
}

export function* postSyncSentimentSurveyResultActionWatcher(): Generator<*, *, *> {
  yield takeEvery(actions.postSyncSentimentSurveyResultAction,
    resourceSagaUtils.qcsSyncCreateResource,
    resourceStoreTypes.mkQcsSyncResourceConfig({
      resourceName: 'sync sentiment survey',
      resourceBaseUrl: '/users/survey',
      successAction: actions.postSyncSentimentSurveyResultSuccessAction,
      failureAction: actions.postSyncSentimentSurveyResultFailureAction,
    }));
}

export default [
  checkIfSyncSentimentSurveyRequestedActionWatcher,
  postSyncSentimentSurveyResultActionWatcher,
];
