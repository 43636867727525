// @flow
import { handleActions } from 'redux-actions';

import * as actions from './actions';

import { mkStdFormsStore, mkStdForm } from './types';
import type { StdFormsStore } from './types';

const reducer = handleActions({

  [actions.stdFormAdd]:
    (state: StdFormsStore, { payload }: { payload: any }) => {
      const { id, type = 'StdForm' } = payload;
      return state.setIn(['stdFormsById', id], mkStdForm({ id, type }));
    },

  [actions.stdFormDelete]:
    (state: StdFormsStore, { payload: id }: { payload: string }) =>
      state.deleteIn(['stdFormsById', id]),

  [actions.stdFormCancelled]:
    (state: StdFormsStore, { payload: id }: { payload: string }) => (
      state.setIn(['stdFormsById', id, 'cancelled'], true)
    ),

  [actions.stdFormSubmitted]:
    (state: StdFormsStore, { payload: id }: { payload: string }) => (
      state.mergeIn(['stdFormsById', id], {
        submitCancelled: false,
        submitSucceeded: false,
        submitFailed: false,
        submitResponseData: null,
      })
    ),

  [actions.stdFormSubmitSuccess]:
    (state: StdFormsStore, { payload: { id, data } }: { payload: { id: string, data: any} }) => (
      state.mergeIn(['stdFormsById', id], {
        submitSucceeded: true,
        submitResponseData: data,
      })
    ),

  [actions.stdFormSubmitFailure]:
    (state: StdFormsStore, { payload: { id, data } }: { payload: { id: string, data: any} }) => (
      state.mergeIn(['stdFormsById', id], {
        submitFailed: true,
        submitResponseData: data,
      })
    ),

}, mkStdFormsStore());

export const REDUCER_KEY = 'stdFormsStore';
export default reducer;
