import React, { PureComponent } from 'react';

import { getLogger } from 'companion-app-components/utils/core';

import Debug from 'components/Debug';

const logger = getLogger();

export default class QPureComponent extends PureComponent {

  constructor(props) {
    super(props);
    logger.debug(`${this.constructor.name}: constructor`);
  }

  UNSAFE_componentWillMount() {
    logger.debug(`${this.constructor.name}: UNSAFE_componentWillMount`);
  }

  componentDidMount() {
    logger.debug(`${this.constructor.name}: componentDidMount`);
  }

  componentDidUpdate(prevProps, prevState, _snapshot) {
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const propChanges = [];
    Object.entries(this.props || {}).forEach(([key, value]) => {
      const prevValue = prevProps[key];
      if (value !== prevValue) {
        propChanges.push(key);
      }
    });
    const stateChanges = [];
    Object.entries(this.state || {}).forEach(([key, value]) => {
      const prevValue = prevState[key];
      if (value !== prevValue) {
        stateChanges.push(key);
      }
    });
    logger.debug(`${this.constructor.name}: componentDidUpdate caused by changed props: [${propChanges.join(', ')}] or state: [${stateChanges.join(', ')}]`);
/////////////
  }

  componentWillUnmount() {
    logger.debug(`${this.constructor.name}: componentWillUnmount`);
  }

  render(dump) {
    logger.debug(`${this.constructor.name}: render`);
    let component = null;
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    component = <Debug that={this} dump={dump} />;
/////////////
    return component;
  }

}

