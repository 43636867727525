/* eslint-disable import/no-import-module-exports */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'sanitize.css/sanitize.css';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as am4core from '@amcharts/amcharts4/core';

// make sure to initialize react core before importing any of our components (including other react components)
import 'utils/interfaces/tracker';
import 'utils/interfaces/assert';
import 'utils/core';
import 'utils/interfaces/crahReporter';
import 'utils/interfaces/httpClient';
import 'utils/interfaces/bump';
import { getLogger, tracker } from 'companion-app-components/utils/core';

import 'utils/tabAuthExchange'; // exchange auth session with other browser tabs
import 'utils/wdyr'; // instruments/wraps react code, so should always be the first import before render started

import App from 'containers/App';
import CombinedProviders from 'containers/App/CombinedProviders';
import { appSetOnlineStatus } from 'data/app/actions';

import { initializeFeatureFlags } from 'utils/featureFlags/initialize';
import { initializeClientConfigFlags } from 'utils/clientConfigFlags';
import { dispatchEscapeKeyEvent } from 'utils/utils';
import consoleMirror from 'utils/consoleMirror';
import { captureNetworkLogs } from 'utils/networkLogs';
import { authSelectors } from 'companion-app-components/flux/auth';
import ErrorBoundary from 'components/ErrorBoundary';

// Load the favicon and the .htaccess file
import '!file-loader?name=favicon.[ext]!./images/favicon-quicken.ico';
import '!file-loader?name=apple-touch-icon.[ext]!./images/apple-touch-icon-quicken.png';
import 'file-loader?name=[name].[ext]!./.htaccess'; // eslint-disable-line import/extensions

import NavigateSetter from './NavigateSetter';
import createSagas from './sagas';
import store from './store';

// TODO: Import selector for `syncHistoryWithStore`
// import { makeSelectLocationState } from 'containers/App/selectors';
/* eslint-enable import/no-webpack-loader-syntax */

const qcsId = authSelectors.getQCSId(store.getState());
tracker.identify(qcsId); // identify tracker ASAP

consoleMirror.attach();

const log = getLogger('app.js');
log.log(`app version: ${process.env.APP_VERSION} (${process.env.BUILD_NUMBER})`);

captureNetworkLogs(XMLHttpRequest.prototype);

tracker.track(tracker.events.init);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const isIe = require('is-iexplorer');

function nodeListPolyfill() {
  if (typeof NodeList.prototype.forEach === 'function') return false;
  NodeList.prototype.forEach = Array.prototype.forEach;
  return true;
}

nodeListPolyfill();

// ===============================================================================================
// Initialize Launch Darkly (feature-flags)
// ===============================================================================================
initializeFeatureFlags();

// ===============================================================================================
// Initialize Client Config (feature-flags)
// ===============================================================================================
initializeClientConfigFlags();

// ===============================================================================================
// React APP Render
// ===============================================================================================
// const isLoginMode = window !== window.top; // app is inside auth widget iframe
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
function Root() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <NavigateSetter>
          <CombinedProviders>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </CombinedProviders>
        </NavigateSetter>
      </BrowserRouter>
    </Provider>
  );
}

// add global saga's
export const sagaTasks = createSagas(store);

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/App'], () => {
    // root.unmount();
    root.render(<Root />);
  });
}

root.render(<Root />);

// eslint-disable-next-line no-constant-condition
// if (false && process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update'); // eslint-disable-line
//   whyDidYouUpdate(React);
// }

window.onclick = () => {
  tracker.trackActive();
};

//----------------------------------------------------------------------------------------------------------------------
// on resize, trigger an ESC keyup event to get MUI to close things it should before resize
//----------------------------------------------------------------------------------------------------------------------
function handleResize() {
  if (!isIe) dispatchEscapeKeyEvent(); // closes any open MUI things like Popovers
}

function resizeThrottler() {
  if (this.resizeTimeout) {
    clearTimeout(this.resizeTimeout);
  }
  this.resizeTimeout = setTimeout(handleResize, 66);
}

window.addEventListener('resize', resizeThrottler);

// enable amcharts license
am4core.options.commercialLicense = true;

// var installPromptEvent;
// window.addEventListener('beforeinstallprompt', function(event) {
//   event.preventDefault();
//   event.prompt();
//   installPromptEvent = event;
//   console.log('Got BeforeInstallPrompt event');
// });

const handleNetworkChange = (_event) => {
  if (navigator.onLine) {
    log.debug('Application is online');
    store.dispatch(appSetOnlineStatus(true));
  } else {
    log.debug('Application is offline');
    store.dispatch(appSetOnlineStatus(false));
  }
};

window.addEventListener('load', () => {
  window.addEventListener('online', handleNetworkChange);
  window.addEventListener('offline', handleNetworkChange);
  handleNetworkChange();
});

handleNetworkChange();
