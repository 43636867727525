import React, { FC, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';

import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRightRounded';

import { tracker } from 'companion-app-components/utils/core';
import { budgetItemsActions } from 'companion-app-components/flux/budget-items';
import { BudgetNodeTypes } from 'companion-app-components/flux/budgets/budgetsTypes';
import * as budgetsActions from 'companion-app-components/flux/budgets/budgetsActions';

import compose from 'utils/compose';
import QProgressBar from 'components/QProgressBar/QProgressBar';
import QCurrency from 'components/QCurrency';
import Dump from 'components/Dump';


const inputFontSize = 13;

const styles = (theme: Record<string, any>) => ({
  input: {
    ...theme.typography.body2,
    fontSize: inputFontSize,
    width: 100,
    height: 20,
    '& input': {
      minHeight: 9,
      padding: '0 2px 0',
      '-moz-appearance': 'textfield',
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  inputAdornment: {
    ...theme.typography.body2,
    fontSize: `${inputFontSize}px !important`,
    marginRight: 0,
  },
});

interface BudgetNodeProps {
  formatAmount: (amount: number, currency: string, showCent: boolean) => string;
  editInPlace: boolean;
  budget: Record<string, any>;
  node: Record<string, any>;
  style: Record<string, any>;
  showChildLink: boolean;
  index: number;
  isParent: boolean;
}

const useStyles = makeStyles()(styles);

type StateType = {
  nodeState: Record<string, any> | undefined, 
  userTargetAmount: number | undefined | string
};

const BudgetNode: FC<BudgetNodeProps> = ({ node, showChildLink, editInPlace, 
  budget, isParent, index, formatAmount, style }) => {

  const [budgetNodeStates, setBudgetNodeStates] = useState<StateType>({
    nodeState: undefined,
    userTargetAmount: undefined,
  }); 

  const { nodeState, userTargetAmount } = budgetNodeStates;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { classes, theme }: { classes: Record<string, any>, theme: Record<string, any> } = useStyles();

  const updateBudgetItem = useCallback(() => {
    if (!Number.isNaN(userTargetAmount) && nodeState && nodeState.negate) {
      const newTargetAmount = Number(userTargetAmount!) * nodeState.negate;
      if (!Number.isNaN(newTargetAmount) && nodeState.targetAmount !== newTargetAmount) {
        const budgetItem = nodeState.budgetItems && nodeState.budgetItems.size === 1 ?
          nodeState.budgetItems.first() : null;
        if (budgetItem) {
          tracker.track(tracker.events.editBudget, { range: 'current_month' });
          // @ts-expect-error: Argument of type '{ id: any; amount: number; }' is not assignable to parameter of type 'void'.
          dispatch(budgetItemsActions.updateBudgetItem({
            id: budgetItem.id,
            amount: newTargetAmount,
          }, { undo: { userMessage: 'Budget target updated.' } }));
          // QWIN refresh budget items only when budget updated
          // TODO Dummy budget update call. tobe removed later once QWIN fixes the issue.
          dispatch(budgetsActions.updateBudgetAction(budget));
        }
      }
      setBudgetNodeStates({
        nodeState: undefined,
        userTargetAmount: undefined,
      });
    }
  }, [userTargetAmount, nodeState, dispatch, budget]);

  const getProgressBarColor = (isIncome, targetAmount, temp) => {
    const amountLeft = Number.isNaN(temp) ? 0 : Number(temp);
    let overBudget = false;
    if (isIncome && targetAmount < 0 && amountLeft > 0) overBudget = true;
    else if (!isIncome && targetAmount >= 0 && amountLeft < 0) overBudget = true;
    return overBudget ? theme.palette.red2 : theme.palette.green2;
  };

  const getAmountLeft = (showCents, targetTotalAmount, actualAmount) => {
    if (showCents) {
      return targetTotalAmount - actualAmount;
    }
    return targetTotalAmount - Math.round(actualAmount);
  };

  const { currency, showCents } = budget;
  const currencySymbol = getSymbolFromCurrency(currency || 'N/A') || '';
  const isIncome = node.negate > 0;
  const actualAmount = !Number.isNaN(node.actualAmount) ? node.actualAmount * node.negate : undefined;
  const targetAmount = !Number.isNaN(node.targetAmount) ? node.targetAmount * node.negate : undefined;
  const rolloverAmount = !Number.isNaN(node.rolloverAmount) && !Number.isNaN(node.negate) ? node.rolloverAmount * node.negate : 0;
  const targetTotalAmount = !Number.isNaN(targetAmount) ? targetAmount! + rolloverAmount : undefined;
  const amountLeft = !Number.isNaN(targetTotalAmount) && !Number.isNaN(actualAmount) ? getAmountLeft(showCents, targetTotalAmount, actualAmount) : undefined;
  const showLabelAmount = !editInPlace || node.type === BudgetNodeTypes.GROUP || node.type === BudgetNodeTypes.CATEGORY_ROLL_UP;
  const baseLink = '/budgets';
  let navLink = `${baseLink}?displayBudget=${budget.id}`;
  if (showChildLink) {
    navLink = `${navLink}&displayNode=${node.key}`;
  }
  let progressLabel;
  let value: number | null | undefined = null;
  let total: number | null | undefined = null;

  if (!Number.isNaN(actualAmount)) {
    value = showCents ? actualAmount : Math.round(actualAmount!);
  }
  if (!Number.isNaN(targetAmount)) {
    total = showCents ? targetAmount : Math.round(targetAmount!);
  }

  if (value! * total! > 0) {
    progressLabel =
      showCents ?
        `${Math.round((actualAmount! / targetAmount!) * 100)}%` :
        `${Math.round((Math.round(actualAmount!) / Math.round((targetAmount!))) * 100)}%`;
  } else {
    progressLabel = '';
  }

  return (
    <div
      role="presentation"
      style={{
        ...style,
        width: '100%',
        cursor: 'pointer',
        position: 'relative',
      }}
      key={node.key}
      onClick={() => {
        if (showChildLink) {
          navigate(navLink, { replace: true });
        } else if (isParent && node.children && node.children.size > 0) {
          navigate(baseLink, { replace: true });
        }
      }}
    >
      <Dump obj={node} />
      <Typography
        id={`budgets-heading-${index}`}
        variant="body2"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: inputFontSize,
          fontWeight: 500,
        }}
      >
        {`${node.displayLabel.toUpperCase()}`}
        {node.children && node.children.size > 0 &&
        <KeyboardArrowRight id={`budgets-drill-down-arrow-${index}`} />}
      </Typography>

      <span
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <span>
          <Typography
            id={`budgets-actual-amount-heading-${index}`}
            variant="body2"
            style={{ display: 'inline-block', fontSize: inputFontSize, transform: showLabelAmount ? 'none' : 'translateY(-3px)' }}
          >
            {`${!Number.isNaN(actualAmount) && formatAmount(actualAmount!, currency, !showCents)} of`}
            &nbsp;
          </Typography>

          {showLabelAmount ?
            <Typography
              id={`budgets-target-ro-amount-heading-${index}`}
              variant="body2"
              style={{ display: 'inline', fontSize: inputFontSize }}
            >
              {formatAmount(targetAmount!, currency, !showCents)}
            </Typography>
            :
            <FormControl>
              <Input
                id={`budgets-target-amount-heading-${index}`}
                className={classes.input}
                inputProps={{ 'aria-label': 'budget target amount' }}
                value={nodeState && nodeState.key === node.key && !Number.isNaN(userTargetAmount) ?
                  userTargetAmount
                  :
                  (!Number.isNaN(targetAmount) && targetAmount?.toFixed(showCents ? 2 : 0))}
                onChange={(event) => {
                  setBudgetNodeStates({
                    nodeState: node,
                    userTargetAmount: event.target.value,
                  });
                }}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    updateBudgetItem();
                  }
                  event.stopPropagation();
                }}
                onClick={(event) => event.stopPropagation()}
                type="number"
                startAdornment={
                  <InputAdornment
                    position="start"
                    classes={{
                      root: classes.inputAdornment,
                    }}
                    disableTypography
                  >
                    {currencySymbol}
                  </InputAdornment>
                }
                onBlur={updateBudgetItem}
              />
            </FormControl>}

          {!Number.isNaN(rolloverAmount) && rolloverAmount !== 0 &&
          <Typography
            id={`budgets-rollover-amount-${index}`}
            variant="body2"
            style={{ display: 'inline' }}
          >
            {` ${rolloverAmount >= 0 ? '+' : '-'} ${formatAmount(Math.abs(rolloverAmount), currency, !showCents)} (rollover)`}
          </Typography>}
        </span>

        <Typography
          id={`budgets-amount-over-or-left-${index}`}
          variant="body2"
          style={{ display: 'inline', fontSize: inputFontSize }}
        >
          {!Number.isNaN(amountLeft) && amountLeft! * (targetAmount || +1) < 0 ?
            `${formatAmount(Math.abs(amountLeft!), currency, !showCents)} Over` :
            `${formatAmount(Math.abs(amountLeft!), currency, !showCents)} Left`}
        </Typography>
      </span>

      <QProgressBar
        id={`budgets-percentage-${index}`}
        value={value}
        total={total}
        color={getProgressBarColor(isIncome, targetAmount, amountLeft)}
        height={20}
        noShadow
        noRoundCorners
        backgroundColor={theme.palette.shinyGrey}
        text={progressLabel}
      />

    </div>
  );
};

export default compose(
  QCurrency(),
)(BudgetNode);
