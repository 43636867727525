// @flow
import { Record, List, Map as ImmutableMap } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

type RootUiDataProps = {
  id: string,
  type: string,

  allowNesting: boolean,

  // this needs to be an ImmutableMap, couldn't get it to work with flow so just defined as any...
  props: any,
};
export type RootUiData = RecordOf<RootUiDataProps>;
export const mkRootUiData: RecordFactory<RootUiDataProps> =
  Record({
    type: 'UNKNOWN',
    id: '0',

    allowNesting: true,
    props: ImmutableMap(),
  });

type RootUiStoreProps = {
  activeModals: List<RootUiData>,
  queuedModals: List<RootUiData>,
};
export type RootUiStore = RecordOf<RootUiStoreProps>;
export const mkRootUiStore: RecordFactory<RootUiStoreProps> =
  Record({
    activeModals: List(),
    queuedModals: List(),
  });

