import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { categoriesSelectors, categoriesActions } from 'companion-app-components/flux/categories';

// temporary hack until QCS start doing that by itself
const InvestmentCategoriesInit = () => {
  const dispatch = useDispatch();

  const categoriesLoadPending = useSelector(categoriesSelectors.getLoadPending);
  const investmentCategories = useSelector(categoriesSelectors.getInvestmentCategories);
  const investmentTransactions = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'investmentTransactions'));
  useEffect(() => {
    if (investmentTransactions && !categoriesLoadPending && !investmentCategories.size) {
      dispatch(categoriesActions.initCategories(undefined, { axiosConfig: { params: { forcedType: 'INVESTMENT' } } }));
    }
  }, [investmentTransactions, categoriesLoadPending, investmentCategories, dispatch]);

  return null;
};

export default InvestmentCategoriesInit;
