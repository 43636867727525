const styles = (theme) => ({
  base: {
    minWidth: theme.components.accountDrawer.width,
    paddingRight: theme.spacing(1),
    '@media (max-width:960px)': {
      minWidth: ({ prefix }) => prefix === 'dashboard' ? `calc(100vw - ${theme.components.navigation.barWidth}px - ${theme.spacing(7)} + 1px)` : 0,
    },
  },
  root: {
    width: '100%',
    background: theme.components.accountDrawer.background,
    padding: 0,
    borderBottom: 'none',
  },
  rootWithBorderBottom: {
    width: '100%',
    background: theme.components.accountDrawer.background,
    borderBottom: `1px solid ${theme.components.accountDrawer.divider}`,
    padding: 0,
    '&.dim': {
      opacity: 0.3,
    },
  },
  dividerRoot: {
    backgroundColor: theme.components.accountDrawer.divider,
  },
  loadingStateContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  zeroStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 271,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    color: theme.palette.greyScaleDeprecated[2],
  },
  zeroStateTextHeadline: {
    marginBottom: 10,
    fontWeight: 500,
    color: theme.palette.greyScaleDeprecated[7],
  },
  zeroStateTextBody: {
    maxWidth: '80%',
    padding: 16,
    textAlign: 'center',
    color: theme.palette.greyScaleDeprecated[7],
  },
  skeletonWrapper: {
    height: '100%',
    width: '100%',
  },
  skeletonBlock: {
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    borderRadius: 8,
    marginBottom: 10,
    marginLeft: '24px',
    width: '75%',
    height: 30,
    '&.large': {
      marginLeft: '24px',
      width: '85%',
      height: 40,
    },
    '&.small': {
      marginLeft: '24px',
      width: '70%',
      height: 20,
    },
    '&.netWorth': {
      marginLeft: '24px',
      width: '85%',
      height: 60,
    },
  },
  skeletonGroupWrapper: {
    marginBottom: '24px',
  },
  emptyAccountTypeHeader: {
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(1),
    background: theme.components.accountDrawer.l1Color,
    borderRadius: theme.shape.borderRadius * 3,
    paddingLeft: theme.spacing(3),
  },
  emptyAccountTypeAddButton: {
    textAlign: 'left',
    margin: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    color: theme.palette.link.main,
    '&:hover': {
      textDecorationLine: 'underline',
    },
  },
});

export default styles;
