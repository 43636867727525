// BASE
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { tracker } from 'companion-app-components/utils/core';
import { authSelectors } from 'companion-app-components/flux/auth';
import { categoriesActions, categoriesSelectors } from 'companion-app-components/flux/categories';
import { entitlementsSelectors } from 'companion-app-components/flux/entitlements';
import { createBudgetsAction } from 'companion-app-components/flux/budgets/budgetsActions';
import { getBudgets, getIsLoading as getBudgetsLoading } from 'companion-app-components/flux/budgets/budgetsSelectors';
import { mkBudgetRecord } from 'companion-app-components/flux/budgets/budgetsTypes';

// CUSTOM COMPONENTS
import EditBudgetsDialog from 'components/Budgets/EditBudgetsDialog';
import useQDialogs from 'components/QDialogs/useQDialogs';

// SELECTORS, ACTIONS, UTILS
import { getCategoryGroupLists, getIsLoading as getGroupListsIsLoading, getLoadPending as getGroupListsPending }
  from 'data/categoryGroupLists/categoryGroupListsSelectors';

import { getCategoryGroupsById, getIsLoading as getGroupsIsLoading, getLoadPending as getGroupsPending }
  from 'data/categoryGroups/categoryGroupsSelectors';

import { getCategoryGroupListsAction } from 'data/categoryGroupLists/categoryGroupListsActions';
import { getCategoryGroupsAction } from 'data/categoryGroups/categoryGroupsActions';

// MUI COMPONENTS

// PATH RELATIVE IMPORTS
import { qCards } from './qCardsConfig';

// STYLES HOOK

/*
 * WFBudgetSetup Component ***********************************
 */

// PROP TYPES
const propTypes = {
  onClose: PropTypes.func,
  budget: PropTypes.object,
};

const WFBudgetSetup = (props) => {

  // PROPS ============================================================================
  const { budget, onClose } = props;

  // STATE
  const budgetsById = useSelector((state) => getBudgets(state), shallowEqual);
  const budgetsLoading = useSelector((state) => getBudgetsLoading(state), shallowEqual);
  const categoryGroupListsPending = useSelector((state) => getGroupListsPending(state), shallowEqual);
  const categoryGroupsPending = useSelector((state) => getGroupsPending(state), shallowEqual);
  const categoryGroupListsLoading = useSelector((state) => getGroupListsIsLoading(state), shallowEqual);
  const categoryGroupsLoading = useSelector((state) => getGroupsIsLoading(state), shallowEqual);
  const categoryGroupLists = useSelector((state) => getCategoryGroupLists(state), shallowEqual);
  const categoryGroups = useSelector((state) => getCategoryGroupsById(state), shallowEqual);
  const categoriesById = useSelector((state) => categoriesSelectors.getCategoriesById(state), shallowEqual);
  const isCanadianDatasetFlag = useSelector(authSelectors.isCanadianDataset);
  const maybeCanadianDatasetFlag = useSelector(authSelectors.maybeCanadianDataset);
  const hasCanadaEntitlementsFlag = useSelector(entitlementsSelectors.hasCanadaEntitlements);
  const hasUSEntitlementsFlag = useSelector(entitlementsSelectors.hasUSEntitlements);
  const isCanadian = isCanadianDatasetFlag || (maybeCanadianDatasetFlag && hasCanadaEntitlementsFlag && !hasUSEntitlementsFlag);
  const [isNewBudget, setIsNewBudget] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(true);

  // DISPATCHERS ========================================================================
  const dispatch = useDispatch();
  const dispatchCreateBudget = (data) => dispatch(createBudgetsAction([data]));
  const dispatchGetCategoryGroupLists = () => dispatch(getCategoryGroupListsAction());
  const dispatchGetCategoryGroups = () => dispatch(getCategoryGroupsAction());
  const dispatchGetCategories = () => dispatch(categoriesActions.getCategories());

  const { dialogAlert } = useQDialogs();

  // EFFECTS ============================================================================

  // Do only once on mount, get cat groups and lists, as they may not have been loaded
  // when a new dataset was just created
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatchGetCategoryGroups();
    dispatchGetCategoryGroupLists();
    dispatchGetCategories();
  }, []);

  // If there is no budget in existence to use, then we need to create a base budget
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (budgetsById && !budgetsLoading && budgetsById.size === 0 &&
      (!categoryGroupListsPending && categoryGroupLists && categoryGroupLists.size > 0) &&
      (categoriesById && categoriesById.size > 0) &&
      (!categoryGroupsPending && categoryGroups && categoryGroups.size > 0)) {

      // create a budget
      const newBudget = mkBudgetRecord({
        clientId: uuidv4().toUpperCase(),
        name: 'My Budget',
        catGroupListId: categoryGroupLists.first().id,
        currency: isCanadian ? 'CAD' : 'USD',
      });

      setIsNewBudget(true);

      // Now create the budget items for all the budgets set
      dispatchCreateBudget(newBudget);
    }
  }, [budgetsLoading, budgetsById, categoryGroupListsPending, categoryGroupLists,
    categoryGroupsPending, categoryGroups]);

  // detect error state and warn the user
  useEffect(() => {
    if (!categoryGroupsLoading && !categoryGroupListsLoading && !categoryGroupListsPending && !categoryGroupsPending) {
      if ((!categoryGroupLists || categoryGroupLists.size === 0) ||
        (categoryGroups && categoryGroups.size === 0)) {
        dialogAlert(
          'Whoops... sorry about that!',
          'It appears your data has an issue that is not allowing us to create a budget. You can call support and ' +
          'tell them that your dataset does not have category groups within it. This should never happen, but ' +
          'apparently it did in this case.  We are sorry about the inconvenience. This does not impact the normal ' +
          'use of the product, but does prevent budget creation.',
          (retObj) => {
            if (retObj.btnPressed === 'Try Again') {
              dispatchGetCategoryGroups();
              dispatchGetCategoryGroupLists();
              dispatchGetCategories();
            }
          },
          ['Continue', 'Try Again'],
          'error',
        );
      }
    }
  }, [categoryGroupsLoading, categoryGroupListsLoading, categoryGroupsPending,
    categoryGroupListsPending, categoryGroups, categoryGroupLists]);

  // STYLES ============================================================================

  // INTERNAL FUNCTIONS ============================================================================

  const internalOnClose = (uniqueBudgetItems) => {
    if (uniqueBudgetItems > 0) { // has relevant data
      if (isNewBudget) {
        tracker.track(tracker.events.createdBudget);
      } else {
        tracker.track(tracker.events.editedBudget);
      }
    }
    setDialogOpen(false);
    if (onClose) onClose();
  };

  return dialogOpen && budgetsById && budgetsById.size > 0 && (
    <EditBudgetsDialog
      budget={budget || budgetsById.first()}
      onClose={internalOnClose}
      qCards={qCards}
    />
  );
};

WFBudgetSetup.propTypes = propTypes;
export default WFBudgetSetup;
