import * as React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover/Popover';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import { getLogger } from 'companion-app-components/utils/core';

const log = getLogger('NestedMenuItem/selectors.js');

export default class NestedMenuItem extends React.PureComponent {

  anchor = null;

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (event) => {
    event.stopPropagation();
    this.setState({ open: false });
  };

  handleArrowClick = (event) => {
    event.stopPropagation();
    this.setState({
      open: true,
      anchorEl: this.anchor,
    });
  };

  handleKeyDown = () => {
    log.log('KeyDown...');
  };

  handleRequestClose = (event) => {
    log.debug('Handling Request close...');
    this.setState({ open: false });

    if (this.props.onChildRequestClose) {
      this.props.onChildRequestClose(event);
      log.log('Test');
    }

    let closeAll = false;
    if (event instanceof KeyboardEvent) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        closeAll = false;
      }
    }

    // A bit of a hack, but we have no other way of closing the whole tree
    // without cooperating with the parent menu
    if (closeAll) {
      document.querySelectorAll('div[class^="MuiBackdrop-"]').forEach((backdrop) => {
        backdrop.click();
      });
    }
  };

  handleClickAway = (e) => {
    log.debug('Nested Clicked away');
    this.handleRequestClose(e);
  };

  handleBackdropClick = (_e) => {
    log.debug('BackdropClick');
  };

  render() {
    const { children, menuItems, keyString, style, ...other } = this.props;
    const { anchorEl, open } = this.state;

    return (
      <MenuItem
        {...other}
        dense
        onClick={this.props.onClick}
        key={keyString}
      >
        {children}
        <ArrowDropDown
          id={this.props.arrowid}
          style={{ float: 'right', transform: 'rotate(-90deg)', marginLeft: 'auto' }}
          color="primary"
          onClick={this.handleArrowClick}
          tabIndex={0}
        />
        <div
          ref={(el) => { this.anchor = el; }}
          style={{ position: 'absolute', right: 0 }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleRequestClose}
          onBackdropClick={this.handleBackdropClick}
          style={style}
        >
          {React.Children.map(menuItems, (menuItem) => (
            React.isValidElement(menuItem)
              ? React.cloneElement((menuItem), {
                onKeyDown: this.handleKeyDown, ...(menuItem.props),
              })
              : menuItem
          ))}
        </Popover>
      </MenuItem>
    );
  }
}

NestedMenuItem.propTypes = {
  arrowid: PropTypes.string,
  children: PropTypes.any,
  keyString: PropTypes.string,
  menuItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  onChildRequestClose: PropTypes.func,
  style: PropTypes.object,
};
