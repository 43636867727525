import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount, useUnmount } from 'react-use';
import Script from 'react-load-script';

import { getBuildConfig, getCommonConfig, getEnvironmentConfig, getLogger, tracker } from 'companion-app-components/utils/core';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

import Box from '@mui/material/Box';

import type { Institution } from 'data/institutions/types';
import { bindPromiseAction } from 'utils/actionHelpers';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import { transformPlaidAccountsToAccounts } from './transformers';

const log = getLogger('PlaidLink/index.js');

/* eslint-disable camelcase */

type Props = {
  scope: string,

  institution: Institution,
  onClosed: (any) => void,
  onSuccess: (any) => void,
  trackingProperties: any,
  updateMode: boolean,
};

const getChannelFromPlaidEnv = (env) => {
  switch (env) {
    case 'production':
      return 'PLAID';
    case 'development':
      return 'PLAID_DEV';
    case 'sandbox':
      return 'PLAID_SANDBOX';
    default:
      // TODO: Should throw error here!
      return null;
  }
};

const PlaidLink = (props: Props) => {
  const { institution, onClosed, onSuccess, scope, trackingProperties, updateMode } = props;
  const dispatch = useDispatch();

  const LinkHandler = useRef();

  const [linkToken, setLinkToken] = useState(null);
  const [lastSearchTerm, setLastSearchTerm] = useState(null);
  const [, setLinkLoaded] = useState(false);
  const [linkScriptLoaded, setLinkScriptLoaded] = useState(false);

  const [plaidLastError, setPlaidLastError] = useState(null);
  const [plaidRequestId, setPlaidRequestId] = useState(null);

  const institutionLogin = useSelector((state) => selectors.getInstitutionLogin(state, { scope }));
  const plaidEnvOverride = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'plaidEnv'));

  const getPlaidEnv = () => plaidEnvOverride || getBuildConfig().plaid_env;
  const getLinkToken = () => {
    const getAggregationInfo = bindPromiseAction(dispatch, actions.getAggregationInfo);
    getAggregationInfo(
      { institution,
        channel: getChannelFromPlaidEnv(getPlaidEnv()),
        institutionLogin,
      },
      { scope }
    ).then(
      (aggregationInfo) => {
        setLinkToken(aggregationInfo.linkToken);
      },
      () => {
        // TODO: What to do in case of failure?
      }
    );
  };

  useMount(() => getLinkToken());
  useUnmount(() => { LinkHandler.current = null; });

  useEffect(() => {
    if (linkScriptLoaded && linkToken && !LinkHandler.current) {

      let plaidEnv;
      if (institutionLogin) {
        const { channel } = institutionLogin.aggregators.get(0);
        switch (channel) {
          case 'PLAID':
            plaidEnv = 'production';
            break;
          case 'PLAID_DEV':
            plaidEnv = 'development';
            break;
          case 'PLAID_SANDBOX':
            plaidEnv = 'sandbox';
            break;
          default:
            log.error(`Unrecognized ContentProvider Channel for plaid, channel=${channel}`);
            plaidEnv = 'production';
        }
      } else {
        plaidEnv = getPlaidEnv();
      }
      const options = {
        clientName: getCommonConfig().plaid_client_name,
        product: getCommonConfig().plaid_product,
        key: getCommonConfig().plaid_public_key,
        env: plaidEnv,
        token: linkToken,
        webhook: getEnvironmentConfig().plaid_webhook,
        countryCodes: ['US'],

        onSuccess: (token, metadata) => { handleSuccess(getChannelFromPlaidEnv(plaidEnv), token, metadata); },
        onExit: handleExit,
        onEvent: handleEvent,
        onLoad: () => handleLoad,
      };
      log.debug('Creeating Plaid Link Handler', options);

      LinkHandler.current = window.Plaid.create(options);
      LinkHandler.current.open(institution ? institution.plaid.id : undefined);
    }
  });

  const handleEvent = (event, metadata) => {
    log.debug('Plaid Event', event, metadata, LinkHandler.current);
    if (event === 'TRANSITION_VIEW') {
      if (metadata.view_name === 'CONNECTED') {
        // linkHandler.exit();
      }
    }
    switch (event) {
      case 'ERROR':
        handleEventError(metadata);
        break;
      case 'EXIT':
        // handled by the onExit handler
        break;
      case 'HANDOFF':
        handleEventHandoff(metadata);
        break;
      case 'OPEN':
        handleEventOpen(metadata);
        break;
      case 'SEARCH_INSTITUTION':
        // this is fired anytime when searching - usually per keystroke
        setLastSearchTerm(metadata.institution_search_query);
        break;
      case 'SELECT_INSTITUTION':
        handleSelectInstitution(metadata);
        break;
      case 'SUBMIT_CREDENTIALS':
        // handleSubmitCredentials(metadata);
        break;
      case 'SUBMIT_MFA':
        // handleSubmitMfa(metadata);
        break;
      case 'TRANSITION_VIEW':
        handleEventTransitionView(metadata);
        break;
      default:
        break;
    }
  };

  const handleEventError = (metadata) => {
    const { error_code, error_message, institution_id: plaidInstitutionId, institution_name: plaidInstitutionName } = metadata;
    setPlaidLastError({ error_code, error_message });

    // TODO: Not sure what to assign to agg_status_code, need to handle channel and id
    tracker.track(!updateMode ? tracker.events.addFIError : tracker.events.fixFIError,
      {
        ...trackingProperties,
        institution_id: plaidInstitutionId,
        institution_name: plaidInstitutionName,
        error_code: 'unknown',
        agg_status_code: error_code,
        agg_status_detail: error_message,
      });
  };

  const handleEventHandoff = (metadata) => {
    const { institution_id: plaidInstitutionId, institution_name: plaidInstitutionName, link_session_id, request_id } = metadata;

    //  would send this in the onSuccess handler except Plaid doesn't provide the request_id there
    dispatch(actions.logPlaidItemEvent({
      institutionLogin,
      event: {
        name: 'PLAID_SUCCESS',
        institutionId: plaidInstitutionId,
        institutionName: plaidInstitutionName,
        sessionId: link_session_id,
        requestId: request_id,
      },
    }));
  };

  const handleEventOpen = (metadata) => {
    const { link_session_id } = metadata;
    dispatch(actions.logPlaidItemEvent({
      institutionLogin,
      event: {
        name: 'PLAID_OPEN',
        sessionId: link_session_id,
      },
    }));
  };

  const handleEventTransitionView = (metadata) => {
    const { institution_id: plaidInstitutionId, institution_name: plaidInstitutionName, link_session_id, request_id, view_name } = metadata;
    if (request_id) {
      setPlaidRequestId(request_id);
    }

    if (view_name === 'ERROR') {
      // this should be sent during error event, but Plaid didn't give us the request ID so sending here
      dispatch(actions.logPlaidItemEvent({
        institutionLogin,
        event: {
          name: 'PLAID_ERROR',
          ...(plaidLastError && {
            errorCode: plaidLastError.error_code,
            errorMessage: plaidLastError.error_message,
          }),
          ...(plaidInstitutionId && {
            institutionId: plaidInstitutionId,
            institutionName: plaidInstitutionName,
          }),
          requestId: request_id,
          sessionId: link_session_id,
        },
      }));
    }

  };

  const handleExit = (error, metadata) => {
    log.debug('Plaid Link Exit', error, metadata);
    const { institution: plaidInstitution, link_session_id, request_id, status } = metadata;

    dispatch(actions.logPlaidItemEvent({
      institutionLogin,
      event: {
        name: 'PLAID_EXIT',
        ...(error && {
          errorCode: error.error_code,
          errorMessage: error.error_message,
        }),
        exitedAt: status,
        institutionId: plaidInstitution?.institution_id,
        institutionName: plaidInstitution?.name,
        sessionId: link_session_id,
        requestId: request_id || plaidRequestId,
      },
    }));

    if (onClosed) {

      let errorTrackingProperties = {};
      if (error) {
        errorTrackingProperties = {
          error_code: 'unknown',
          agg_status_code: error.error_code,
          agg_status_detail: error.error_message,
        };
      }

      // TODO: Seems to be Plaid bug that if close dialog is clicked on search, they return requires_credentials

      let statusTrackingProperties = null;
      switch (status) {
        case 'institution_not_found':
          statusTrackingProperties = {
            step: 'select fi',
            last_search_term: lastSearchTerm,
          };
          break;
        case 'requires_code':
        case 'requires_device':
        case 'requires_questions':
        case 'requires_selections':
          statusTrackingProperties = {
            step: 'mfa',
          };
          break;
        case 'requires_credentials':
          statusTrackingProperties = {
            step: 'credentials',
          };
          break;
        default:
          statusTrackingProperties = {};
      }

      onClosed({
        trackingProperties: {
          ...statusTrackingProperties,
          ...errorTrackingProperties,
        },
      });
    }
  };

  const handleLoad = () => {
    setLinkLoaded(true);
  };

  const handleScriptLoaded = () => {
    setLinkScriptLoaded(true);
  };

  const handleSelectInstitution = (metadata) => {
    const { institution_id: plaidInstitutionId, institution_name: plaidInstitutionName } = metadata;
    tracker.track(!updateMode ? tracker.events.addFISelect : tracker.events.fixFISelect,
      {
        ...trackingProperties,
        institution_id: plaidInstitutionId,
        institution_name: plaidInstitutionName,
        last_search_term: lastSearchTerm,
      });
  };

  const handleSuccess = (channel, token, metadata) => {
    const { accounts: plaidAccounts, institution: plaidInstitution, link_session_id: linkSessionId } = metadata;
    log.debug('Plaid Link Success', linkSessionId, token, plaidInstitution, plaidAccounts, metadata);

    const cpInstitution = { id: plaidInstitution.institution_id, name: plaidInstitution.name };
    const accounts = transformPlaidAccountsToAccounts(channel, plaidAccounts);

    onSuccess({ channel, token, cpInstitution, accounts });
  };

  function onScriptError() {
    log.error('There was an issue loading the link-initialize.js script');
  }

  return (
    <>
      <Box display="flex" flexDirection="column" m={3} />
      <Script
        url="https://cdn.plaid.com/link/v2/stable/link-initialize.js"
        onError={onScriptError}
        onLoad={handleScriptLoaded}
      />
    </>
  );
};

/* eslint-enable camelcase */

export default PlaidLink;
