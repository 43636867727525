export const styles = (theme, props) => ({
  categoryNameWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: (props.depth + 1) * 10,
  },
  categoryName: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  categoryNameParent: {
    paddingTop: 5,
    paddingBottom: 5,
    fontStyle: 'italic',
    color: theme.palette.koalaGrey,
    paddingLeft: 8,
  },
  categoryRow: {
    width: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 5,
    marginLeft: props.isRollup ? 10 * (props.depth + 1) : null,
    '&.rollup': {
      background: theme.palette.silverGrey,
    },
  },
  categoryAmount: {
    flex: 0,
    minWidth: 120,
    paddingLeft: 4,
    paddingRight: 2,
    background: theme.palette.shinyGrey,
    border: `solid 1px ${theme.components.register.fieldBorderColor}`,
    borderRadius: 3,
    marginRight: 6,
    textAlign: 'right',
  },
  rollUpAmount: {
    flex: 0,
    minWidth: 120,
    paddingLeft: 4,
    paddingRight: 2,
    marginRight: 10 + 20 * (props.depth + 1),
    textAlign: 'right',
  },
  amountInputStyle: {
    paddingTop: 2,
    paddingBottom: 2,
    fontWeight: props.isRollup ? 500 : null,
  },
  dollarAdornment: { paddingLeft: theme.spacing(1) },
  barChartIconHolder: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  barChartIcon: {
    color: theme.components.categoryCard.barChartIconColor,
  },
  deleteIcon: {
    color: theme.palette.koalaGrey,
  },
  categoryReviewCardRoot: {
    maxWidth: 650,
    width: 450,
  },
  categoryReviewTxList: {
    maxHeight: 200,
  },
  hide: {
    visibility: 'hidden',
  },
  iconHolder: {
    minWidth: 82,
  },
});
