import { createSelector } from 'reselect';

import { List } from 'immutable';

import { REDUCER_KEY } from './reducer';
import type { BillerStore } from './types';

export const getBillerStore = (state: any): BillerStore => state[REDUCER_KEY];
export const getBillersById = (state: any) => getBillerStore(state).resourcesById;
export const getPopularBillerIds = (state: any) => getBillerStore(state).popularIds;
export const getLastSyncDate = (state: any) => getBillerStore(state).lastSyncDate;

export const getBiller = (state: any, id: string) => {
  const billersById = getBillersById(state);
  return billersById.get(id);
};

export const getPopularBillers = createSelector(
  getBillersById,
  getPopularBillerIds,
  (billersById, popularIds) => {
    if (!popularIds) {
      return null;
    }
    const popularBillers = popularIds.reduce((list, id) => {
      const biller = billersById.get(id);
      if (biller) {
        list.push(biller);
      }
      return list;
    }, []);
    if (popularBillers.length === 0) {
      return null;
    }
    return List(popularBillers.slice(0, 15));
  }
);
