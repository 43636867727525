export const styles = (theme) => ({
  labelStyle: {
    color: theme.palette.text.secondary,
  },
  datasetTitle: {
    margin: '0 25px',
  },
  ownerName: {
    fontWeight: 500, 
    color: theme.palette.koalaGrey,
  },
  textEllipsis: {
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap',
  },
  datasetNameWrapper: {
    maxWidth: 350,
  },
  expiredLabel: {
    marginLeft: 30,
    marginBottom: 15,
  },
});
