import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  loadingLinearProgress: {
    height: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    zIndex: theme.zIndex.tooltip,
  },
}));

const LoadingLinearProgress = () => {
  const { classes } = useStyles();
  return (
    <LinearProgress className={classes.loadingLinearProgress} />
  );
};

export default LoadingLinearProgress;
