import * as React from 'react';
import { Map } from 'immutable';
import { throttle } from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { NavLink } from 'react-router-dom';
import { useEffectOnce, useUnmount } from 'react-use';

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

import { tracker } from 'companion-app-components/utils/core';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { accountsTypes } from 'companion-app-components/flux/accounts';
import type RootState from 'companion-app-components/utils/redux-store/rootState';
import { entitlementsSelectors, entitlementsTypes } from 'companion-app-components/flux/entitlements';

import GuidedTooltip from 'components/GuidedTooltip';
import { featurePages, navMainOrder } from 'containers/App/paths';
import useQPreferences from 'components/QPreferences/useQPreferences';
import { extractDetailsForTracker } from 'containers/BudgetsV2/utils';

import quickenLogo from 'assets/thewhiteq.svg';
import HelpIcon from 'assets/nav-menu/help.inline.svg';

import MoreButton from './MoreButton';
import ProfileMenu from './ProfileMenu';
import RefreshButton from './RefreshButton';
import { MainNavItem } from './MainNavItem';
import FeedbackButton from './FeedbackButton';
import QCardToggleButton from './QCardToggleButton';
import HelpSupportButton from './HelpSupportButton';

import { styles } from './styles';

const useStyle = makeStyles()(styles as Record<string, any>);

interface HeaderProps {
  selected: string;
  selectedAccountNodes: accountsTypes.Account;
  showRefreshIcon: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  qCardsOn: boolean;
  onQCardsClick: () => void;
  hide: boolean;
}

const Header: React.FC<HeaderProps> = ({ selected, onMouseEnter, onMouseLeave, showRefreshIcon, qCardsOn, hide, selectedAccountNodes, onQCardsClick }) => {
  
  const [canOpen, setCanOpen] = React.useState(true);
  const [showNewRuleTooltip, setShowNewRuleTooltip] = React.useState(false);
  const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerHeight <= 768);
  
  const tier: entitlementsTypes.Entitlement | undefined = useSelector(entitlementsSelectors.getTopTierEntitlement);
  const authenticated: boolean | undefined = useSelector(entitlementsSelectors.isAuthenticated);
  const featureFlags: Map<string, any> = useSelector(featureFlagsSelectors.getFeatureFlags);
  const showIntercom: boolean = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlag(state, 'intercom'));
  
  const { classes, theme }: { classes: Record<string, any>, theme: Record<string, any> } = useStyle();
  const { sharedPreferences, datasetPreferences, setDatasetPreference } = useQPreferences();

  const settingsPageNavRef = React.useRef<HTMLSpanElement | null>(null);

  const onWindowResize = () => setIsSmallScreen(window.innerHeight <= 768);

  useEffectOnce(() => window.addEventListener('resize', throttle(onWindowResize, 500)));

  useUnmount(() => window.removeEventListener('resize', throttle(onWindowResize, 500)));

  const drawerCannotOpen = () => setCanOpen(false);

  const handleTracking = (pathClicked) => {
    if (pathClicked === '/budgets') {
      const detailsToTrack = extractDetailsForTracker(sharedPreferences);
      tracker.track(tracker.events.clickBudgetMenu, detailsToTrack);
    }
  };

  const getSettingsPage = () => {
    const nav = featurePages.find((x) => x.path === '/settings');
    if (nav && (!nav.featureFlag || featureFlags.get(nav.featureFlag))) {
      return (
        <>
          <MainNavItem
            key="nav-item-settings"
            nav={nav}
            iconRef={settingsPageNavRef}
            selected={selected}
            classes={classes}
            iconSize={theme.components.navigation.iconSize}
            displayNode={
              selectedAccountNodes && nav.preserveDisplayNode ?
                selectedAccountNodes[nav.path] : 'all'
            }
            closeHeader={drawerCannotOpen}
            handleTracking={handleTracking}
          />
          <GuidedTooltip
            anchorEl={settingsPageNavRef.current}
            open={showNewRuleTooltip}
            placement={'right'}
            title="Rule created"
            body="Payee rename and category rules can be edited or deleted in Settings."
            cancelLabel="don't show again"
            continueLabel="got it"
            onCancel={() => {
              setShowNewRuleTooltip(false);
              setDatasetPreference({ dontShowNewRuleTooltip: true });
            }}
            onContinue={() => {
              setShowNewRuleTooltip(false);
            }}
            modifiers={{
              offset: {
                enabled: true,
                offset: '0,20',
              },
            }}
          />
        </>
      );
    }
    return null;
  };

  const drawerCanOpen = () => setCanOpen(true);

  const getNavbarStyle = () => classNames(classes.navbar, !canOpen ? 'expand' : 'closed');

  const onCloseHeader = () => {
    drawerCanOpen();
    onMouseLeave();
  };

  return (
    <Slide in={authenticated} direction="right">
      <AppBar
        id="application-header-bar"
        elevation={0}
        position="fixed"
        className={hide ? classes.staticNav : getNavbarStyle()}
        onMouseLeave={drawerCanOpen}
        onMouseEnter={drawerCannotOpen}
      >
        <div className={classes.appDiv}>
          <div
            className={classes.logoSection}
            style={{ cursor: hide ? 'default' : undefined }}
          >
            {/* @ts-expect-error: No overload matches this call. */}
            <Button
              onClick={drawerCannotOpen}
              id="logo-nav"
              classes={{
                root: classes.rootButton,
                text: classes.textButton,
              }}
              component={
                React.forwardRef((prop, ref: any) => hide ? // eslint-disable-line react/no-unstable-nested-components
                  <div
                    {...prop}
                    ref={ref}
                  />
                  :
                  <NavLink
                    {...prop}
                    // @ts-expect-error: Property 'innerRef' does not exist on type 'IntrinsicAttributes & NavLinkProps & RefAttributes<HTMLAnchorElement>'
                    innerRef={ref}
                  />)
              }
              to="/"
              style={{ cursor: hide ? 'default' : undefined }}
            >
              <img
                src={quickenLogo}
                style={{ filter: 'grayscale(1)', height: 32 }}
                alt="quicken logo, click to go home"
              />
            </Button>
          </div>
          <div
            className={classes.appBarContainer}
            onMouseEnter={hide ? undefined : onMouseEnter}
            onMouseLeave={hide ? undefined : onMouseLeave}
          >
            <Toolbar classes={{ root: classes.toolbar }}>
              <div className={classes.betaLabel}>
                {`${datasetPreferences.kioskmode ? '*' : ''}`}
              </div>
              {
                !hide &&
                  <div className={classes.navItemsHolder}>
                    <div>
                      <div className={classes.navPopover}>
                        {navMainOrder.map((navKey) => {
                          const nav = featurePages.find((x) => x.path === `/${navKey}`);
                          if (nav && (!nav.featureFlag || featureFlags.get(nav.featureFlag)) &&
                            (!nav.hideOnTiers.length || !nav.hideOnTiers.includes(tier?.id))) {
                            return (
                              <MainNavItem
                                key={`nav-item-${nav.path}`}
                                nav={nav}
                                selected={selected}
                                classes={classes}
                                canOpenSubMenu={!canOpen}
                                iconSize={theme.components.navigation.iconSize}
                                displayNode={
                                  selectedAccountNodes && nav.preserveDisplayNode ?
                                    selectedAccountNodes[nav.path] : 'all'
                                }
                                closeHeader={onCloseHeader}
                                handleTracking={handleTracking}
                              />
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  </div>
              }
            </Toolbar>
          </div>
          <div className={classes.navBottomSection}>
            <div className={classes.navButtonBottom}>
              {
                onQCardsClick &&
                  <QCardToggleButton
                    size={theme.components.navigation.iconSize}
                    qCardsOn={qCardsOn}
                    onClick={onQCardsClick}
                    closeHeader={drawerCannotOpen}
                  />
              }
              {!isSmallScreen && (
                <HelpSupportButton
                  classes={classes}
                  selected={selected}
                  iconSize={theme.components.navigation.iconSize}
                  settingsPageNavRef={settingsPageNavRef}
                  canOpenSubMenu={!canOpen}
                  closeHeader={onCloseHeader}
                  handleTracking={handleTracking}
                />
              )}

              {
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                <>
                  <MainNavItem
                    key="nav-item-docs"
                    closeHeader={drawerCannotOpen}
                    nav={featurePages.find((x) => x.path === '/docs')}
                    selected={selected}
                    classes={classes}
                    iconSize={theme.components.navigation.iconSize}
                    handleTracking={handleTracking}
                  />
                  <div style={{ paddingTop: 24 }} />
                </>
/////////////////////////
              }

              {
                !isSmallScreen && showRefreshIcon && !hide &&
                  <RefreshButton
                    // @ts-expect-error: Property 'id' does not exist on type 'IntrinsicAttributes
                    id="sync-arrow"
                    closeHeader={drawerCannotOpen}
                    size={theme.components.navigation.iconSize}
                  />
              }

              {!isSmallScreen && !hide && getSettingsPage()}

              { isSmallScreen && <MoreButton 
                classes={classes} 
                showRefreshIcon={showRefreshIcon}
                hide={hide}
                featureFlags={featureFlags}
              />}
              {
                showIntercom &&
                  <ButtonBase
                    id="intercom-launcher"
                    className={classes.navMenuItemSupport}
                  >
                    <HelpIcon />
                    <Typography className={classes.navText}>
                      Help Center
                    </Typography>
                  </ButtonBase>
              }
              <FeedbackButton classes={classes} />
              <ProfileMenu classes={classes} />
            </div>
          </div>
        </div>
      </AppBar>
    </Slide>
  );
};

export default Header;
