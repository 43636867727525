import { List, OrderedMap, Record as ImRecord, Map as ImMap } from 'immutable';
import type { RecordOf } from 'immutable';

import { AccountNode } from 'data/accountNodes/types';

type DisplayOptionsProps = {
  selected: boolean;
  expand?: boolean;
  path?: null | List<string>;
  children?: null | OrderedMap<string, AccountNode>;
};

export type DisplayOptions = RecordOf<DisplayOptionsProps>;

export const makeDisplayOptions = ImRecord<DisplayOptionsProps>({
  expand: true,
  selected: false,

  path: null,
  children: null,
});

export type AccountListState = {
  displayOptionsMap: ImMap<string, DisplayOptions>;
};
