import React from 'react';
import classNames from 'classnames';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

interface TableHeaderProps {
  columns: Record<string, any>;
  classes: Record<string, string>;
}

const TableHeader: React.FC<TableHeaderProps> = ({ columns, classes }) => (
  <TableHead>
    <TableRow>
      {Object.keys(columns).map((key) => {
        const column = columns[key];
        return (
          <TableCell key={key} className={classNames(classes.resetCell, classes?.[key])}>
            <div className={classes?.[`${key}Container`]}>{column.label}</div>
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);

export default React.memo(TableHeader);
