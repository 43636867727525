// @flow
import * as React from 'react';

import MuiSelect from '@mui/material/Select';
import type { SelectProps as MuiSelectProps } from '@mui/material/Select';
import type { FieldProps } from 'formik';

// would be nice if we could exclude following props, not sure how or if we can in flow
//   form, checked, defaultChecked, name, onChange, value
export type SelectProps = MuiSelectProps & FieldProps & {
  onFieldChange: (Event, child?: Node) => void,
};

export const fieldToSelect =
  ({
    field,
    form: { isSubmitting },
    disabled = false,
    ...props
  }: SelectProps): MuiSelectProps => ({
    disabled: disabled || isSubmitting,
    ...props,
    ...field,
  });

const handleChange = (onChange, onFieldChange) => (e, child) => {
  onChange(e);

  if (onFieldChange) {
    onFieldChange(e, child);
  }
};

const FormikSelect: React.ComponentType<SelectProps> = (props: SelectProps) => {
  const { onChange, onFieldChange, value, ...selectProps } = fieldToSelect(props);

  return (
    <MuiSelect
      onChange={handleChange(onChange, onFieldChange)}
      value={value || ''}
      {...selectProps}
    />
  );
};

FormikSelect.displayName = 'FormikSelect';
export default FormikSelect;
