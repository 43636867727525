export function applyOpacityToHex(hex, opacity) {
  const strippedHex = hex.replace('#', '');
  const r = parseInt(strippedHex.substring(0, 2), 16);
  const g = parseInt(strippedHex.substring(2, 4), 16);
  const b = parseInt(strippedHex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// This function was made for the use case of replacing the mui defined shadows rgba values with our own hex values
export function replaceRgbaColor(input, newColor = '') {
  if (input && newColor) {
    return input.replace(/rgba\((.*?)\)/g, newColor);
  }
  return input;
}

