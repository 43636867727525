import styled from 'styled-components';

export const DatePickerHolder = styled.div`

  input {
    width: 100%;
    &:focus {
      outline: none;
      width: 95%;
    }
  }  
`;
