

export const getCurrencyForAccount = (account) => account ? account.currency : 'N/A';

export const getCombinedCurrency = (currency1, currency2) => {
  if (currency1 === currency2) {
    return currency1;
  }
  if (!currency1 && !currency2) {
    return 'N/A';
  }
  return currency1 || currency2;
};

