// @flow
import { getBuildConfig } from 'companion-app-components/utils/core';

export const getDefaultChannel = (institution, featureFlags) => {
  switch (institution.aggregator) {
    case 'FDP':
      return institution.fdp.channelData.type;
    case 'FDS':
      return institution.fds.channelData.type;
    case 'FINICITY':
      // TODO: we should read this from institution
      return 'FINICITY';
    case 'PLAID': {
      // In Plaid, we can use different Plaid environments in each of the QCS environments. This allows us to use
      // the Plaid Sandbox environment in production.
      const plaidEnv = featureFlags.get('plaidEnv') || getBuildConfig().plaid_env;
      if (plaidEnv === 'sandbox') {
        return 'PLAID_SANDBOX';
      }
      if (plaidEnv === 'development') {
        return 'PLAID_DEV';
      }
      return 'PLAID';
    }
    default:
      // should log warning
      break;
  }
  return undefined;
};

export const getAggregatorFromChannel = (channel) => {
  const channelEnvStart = channel.indexOf('_');
  return channelEnvStart === -1 ? channel : channel.substr(0, channelEnvStart);
};

export const getTrackingIdFromInstitution = (institution) => institution.plaid?.id || institution.ofx?.bid;

