// @flow
import React, { useState, useImperativeHandle, useCallback } from 'react';
import type { Element } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/ClearRounded';
import BackIcon from '@mui/icons-material/ArrowBackRounded';
import { useTheme } from '@mui/material/styles';

import { removeDialog } from 'data/rootUi/actions';
import { lightOrDark } from 'utils/color';
import { applyOpacityToHex } from 'themes/themeUtils';
import ErrorBoundary from 'components/ErrorBoundary';

import useStyles from './styles';
import { DIALOG_CLOSE_EVENTS } from './types';

// This component should be used for all Dialogs. It uses the material-ui Dialog

export const isDialogCloseAction = (actionEvent: string) => DIALOG_CLOSE_EVENTS.find((event) => event === actionEvent);

type Props = {
  children: Element<any>,
  classes?: any,
  colorBar?: string,
  dialogId: string,
  fullWidth?: boolean,
  onBack?: (?Event) => void,
  onClose?: (?Event, string) => void,
  open?: boolean,
  sharedcomponentid?: string,
  showBackButton: boolean,
  showCloseButton: boolean,
  stdDialogRef: any,
  disableBackdropClick?: boolean,
  title: string | Element<any>,
  titleClasses?: any,
}

const StdDialog = (props: Props) => {
  const theme = useTheme();

  const {
    children,
    classes: ferryClasses,
    colorBar = theme.palette.greyScaleDeprecated[6],
    dialogId,
    fullWidth = false,
    onBack,
    onClose,
    showCloseButton = true,
    title,
    open,
    showBackButton = false,
    stdDialogRef,
    sharedcomponentid,
    disableBackdropClick,
    titleClasses,
    ...otherProps
  } = props;
  const dispatch = useDispatch();

  // React.useEffect(() => {!open && closeDialog()}, [open]);

  const [close, setClose] = useState(false);

  const { classes } = useStyles();
  const closeDialog = useCallback(() => {
    setClose(true);
    setTimeout(() => dispatch(removeDialog(dialogId)), 2000);
  }, [dialogId, dispatch]);

  const handleClose = useCallback((event: ?Event = null, reason: string) => {
    let localReason = reason;

    // since mui v5 removed 'disableBackdropClick' support - handle it manually
    if (disableBackdropClick && localReason === 'backdropClick') {
      return;
    }

    if (!localReason || localReason === 'backdropClick') {
      localReason = 'BACKDROP_CLICK';
    } else if (!localReason || localReason === 'enterKeyDown') {
      localReason = 'ENTER_KEY_DOWN';
    } else if (!localReason || localReason === 'escapeKeyDown') {
      localReason = 'ESCAPE_KEY_DOWN';
    }

    if (onClose) {
      onClose(event, localReason);
    } else {
      closeDialog();
    }
  }, [onClose, closeDialog, disableBackdropClick]);

  useImperativeHandle(stdDialogRef, () => ({ handleClose, closeDialog }), [handleClose, closeDialog]);

  // I know normally hardcodes are bad, but this color is being derived off of the inputted colorBar, and so it
  // can't be taken straight from theme as dark theme would reverse the color needed
  let iconColor = '#000000';
  if (colorBar && lightOrDark(colorBar) === 'dark') {
    iconColor = '#ffffff';
  }
  iconColor = applyOpacityToHex(iconColor, 0.8);

  function renderCloseButton() {
    if (showCloseButton) {
      return (
        <IconButton
          className={classes.closeButton}
          style={{ color: iconColor }}
          aria-label="Close"
          id="dlg-close"
          type="button"
          onClick={(event) => handleClose(event, 'CLOSE_BUTTON')}
        >
          <CloseIcon />
        </IconButton>
      );
    }
    return null;
  }
  function handleBackKeyDown(e) {
    if (e.keyCode === 13) onBack();
  }

  function renderBackButton() {
    if (showBackButton) {
      return (
        <IconButton
          className={classes.backButton}
          style={{ color: iconColor }}
          aria-label="Back"
          id="dlg-back"
          onClick={onBack}
          onKeyDown={handleBackKeyDown}
        >
          <BackIcon />
        </IconButton>
      );
    }
    return null;
  }

  return (
    <Dialog
      open={open === undefined ? !close : open}
      classes={ferryClasses}
      fullWidth={fullWidth}
      aria-labelledby="dialog-title"
      {...otherProps}
      onClose={handleClose}
    >
      {(title || showBackButton || showCloseButton) && (
        <DialogTitle
          sharedcomponentid={sharedcomponentid || 'STD_DIALOG'}
          className={classNames(classes.titleBar, (showBackButton || showCloseButton) && classes.buttonSpace, titleClasses)}
          style={{ backgroundColor: colorBar }}
        >
          {renderBackButton()}
          {title ?? ''}
          {renderCloseButton()}
        </DialogTitle>
      )}

      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Dialog>
  );
};

export default StdDialog;

