// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import { noop } from 'lodash';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

import { Formik } from 'formik';
import type { FormikBag } from 'formik';

import { accountsActions, accountsTypes } from 'companion-app-components/flux/accounts';
import { tracker } from 'companion-app-components/utils/core';

import InteractiveStateView from 'components/InteractiveStateView';
import QTip from 'components/QuickenControls/QTip';
import StdDialogActions from 'components/Dialogs/StdDialogActions';
import StdDialogContent from 'components/Dialogs/StdDialogContent';
import StdDialogFormik from 'components/Dialogs/StdDialogFormik';

import { addInstitutionLoginAndAccounts, refreshAccounts } from 'data/institutionLogins/actions';

// import { getLogger } from 'utils/logger';
import { isQuicken } from 'isAcme';

import ActiveAccountRow from './ActiveAccountRow';
import NewAccountRow from './NewAccountRow';
import { useStyles } from './styles';
import * as selectors from '../selectors';
import TertiaryTrust from '../TertiaryTrust';

// const log = getLogger('InstitutionAccountsUpdater/index.js');

type Props = {
  trackingProperties: Object,
  scope: string,

  disconnect: boolean,
  updateMode: boolean,

  onCancel: (any) => void,
  onReset: (any) => void,
  onSubmit: (any) => void,

  // When QCS supports ignore flag in general, can remove
  addIgnoredAccounts: boolean,
};

const InstitutionAccountsUpdater = (props: Props) => {
  const { addIgnoredAccounts, disconnect, onCancel, onReset, scope, trackingProperties, updateMode, onSubmit } = props;
  const dispatch = useDispatch();

  const updateStrategy = useSelector((state) => selectors.getUpdateStrategy(state, { scope }));

  const newAccounts = useSelector((state) => selectors.getUpsertableNewAccountsData(state, { scope }));
  const activeAccounts = useSelector((state) => selectors.getActiveAccountsData(state, { scope }));
  const deadAccounts = useSelector((state) => selectors.getDeadAccountsData(state, { scope }));

  const isPolling = useSelector((state) => selectors.getIsPolling(state, { scope }));
  const isSubmitting = useSelector((state) => selectors.getIsSubmitting(state, { scope }));
  const institutionLogin = useSelector((state) => selectors.getInstitutionLogin(state, { scope }));

  const otherAccountsAtSameInstitution = useSelector((state) => selectors.getOtherAccountAtSameInstitution(state, { scope }));

  const { classes } = useStyles();

  const handleCancelAndReset = (reset) => {
    tracker.track(!updateMode ? tracker.events.addFIComplete : tracker.events.fixFIComplete,
      ({
        ...trackingProperties,
        accounts_added: 0,
        accounts_ignored: 0,
        accounts_linked: 0,
        accounts_changed_name: 0,
        accounts_changed_type: 0,
        accounts_changed_subtype: 0,
        accounts_already_connected: activeAccounts.length,
      }));
    if (reset) {
      if (onReset) {
        onReset();
      }
    } else if (onCancel) {
      onCancel();
    }
  };

  const buildAggregatorParams = (discoveredAccount) => ({
    id: discoveredAccount.id,
    cpId: discoveredAccount.cpId,
    cpLoginId: discoveredAccount.cpLoginId,

    channel: discoveredAccount.channel,

    accountName: discoveredAccount.name,
    accountNumberMasked: discoveredAccount.maskedNumber,
  });

  const handleSubmit = (values) => {
    let numberAdded = 0;
    let numberIgnored = 0;
    let numberLinked = 0;
    let numberChangedNames = 0;
    let numberChangedType = 0;
    let numberChangedSubType = 0;
    let accountsToUpsert = newAccounts.reduce((list, account) => {
      const accountValues = values[account.formFieldName];
      const accountAction = accountValues.action.type;
      const aggregators = List([accountsTypes.mkAccountAggregator(buildAggregatorParams(account.discoveredAccount))]);
      const accountParams = {
        id: accountValues.action.accountToLinkTo ? accountValues.action.accountToLinkTo.id : account.discoveredAccount.shadowAccountId,
        shadowId: account.discoveredAccount.shadowAccountId,
        clientId: uuidv4().toUpperCase(),
        institutionLoginId: institutionLogin.id,
        isIgnored: accountAction === 'IGNORE',

        name: accountValues.name,
        subType: accountValues.type.qcsSubType,

        disconnect: disconnect ? true : undefined,
      };
      let accountToUpsert = null;
      if (accountAction !== 'IGNORE' || addIgnoredAccounts) {
        accountToUpsert = accountsTypes.mkUpdateAccount(accountValues.type.qcsType, { ...accountParams, aggregators });
        list.push(accountToUpsert);
      }
      switch (accountAction) {
        case 'ADD_ACCOUNT':
          numberAdded += 1;
          if (accountToUpsert) {
            numberChangedNames = (account.name !== accountToUpsert.name) ? 1 : 0;
            numberChangedType += (account.type !== accountToUpsert.type) ? 1 : 0;
            numberChangedSubType += (account.subType !== accountToUpsert.subType) ? 1 : 0;
          }
          break;
        case 'IGNORED':
          numberIgnored += 1;
          break;
        case 'LINK_TO':
          numberLinked += 1;
          break;
        default:
          break;
      }
      return list;
    }, []);

    // If replacing, change aggregator data in active accounts
    if (institutionLogin.cpSetupMode === 'DISCOVER_AND_REPLACE_ACCOUNTS') {
      accountsToUpsert = activeAccounts.reduce((list, { account, discoveredAccount }) => {
        const aggregators = List([accountsTypes.mkAccountAggregator(buildAggregatorParams(discoveredAccount))]);
        list.push(accountsTypes.mkUpdateAccount(account.type, { id: account.id, clientId: uuidv4().toUpperCase(), aggregators }));
        return list;
      }, accountsToUpsert);
    }

    // Cleanup DEAD Accounts (effective disconnects but still belongs to login)
    accountsToUpsert = deadAccounts.reduce((list, account) => {
      if (!list.find(({ id }) => id === account.id)) {
        list.push(accountsTypes.mkUpdateAccount(account.type, { id: account.id, clientId: uuidv4().toUpperCase(), isCpAccountDeleted: true }));
      }
      return list;
    }, accountsToUpsert);

    if (accountsToUpsert?.length) {
      if (institutionLogin.id && institutionLogin.id !== '0' && updateStrategy !== 'REPLACE') {
        new Promise((resolve, reject) => dispatch(accountsActions.batchAccounts(accountsToUpsert, { resolve, reject, scope })))
          .then((_response) => dispatch(refreshAccounts({ institutionLoginId: institutionLogin.id }, { context: 'addAccounts', scope })))
          .catch(noop);
      } else {
        dispatch(addInstitutionLoginAndAccounts({ institutionLogin, accounts: accountsToUpsert }, { scope }));
      }
      // remember accounts added for billScout filtering
      onSubmit?.(accountsToUpsert?.map((account) => account.clientId));

      // mixpanel tracking
      tracker.track(!updateMode ? tracker.events.addFIComplete : tracker.events.fixFIComplete,
        {
          ...trackingProperties,
          accounts_added: numberAdded,
          accounts_ignored: numberIgnored,
          accounts_linked: numberLinked,
          accounts_changed_name: numberChangedNames,
          accounts_changed_type: numberChangedType,
          accounts_changed_subtype: numberChangedSubType,
          accounts_already_connected: activeAccounts.length,
        });
    }
  };

  const renderForm = (formikBag: FormikBag) => {
    const {
      handleSubmit: formikHandleSubmit,
      values,
    } = formikBag;

    let linkError = null;
    const stagedLinkAccounts = [];
    values && Object.values(values).forEach((account) => {
      const { action } = account;
      if (action.accountToLinkTo) {
        const { id } = action.accountToLinkTo;
        if (stagedLinkAccounts.includes(id)) {
          linkError = 'You cannot link multiple accounts to the same Unconnected Account';
        } else {
          stagedLinkAccounts.push(id);
        }
      }
    });

    const productName = isQuicken ? 'Quicken' : 'Simplifi';

    // This is to make sure two accountNames aren't the same
    const stagedNameList = Object.values(values).filter((value) => value.action.type === 'ADD_ACCOUNT' && value.name).map((value) => value.name?.toLowerCase());

    return (
      <StdDialogFormik onSubmit={formikHandleSubmit}>
        {newAccounts.length > 0 &&
          <>
            <StdDialogContent useContainerFlexHeight maxHeight={295}>
              {linkError &&
                <Box alignItems="center" display="flex" justifyContent="center" className={classes.linkOverload}>
                  <Typography variant={'caption'}>{linkError}</Typography>
                </Box>}
              {!isQuicken &&
                <Box display={'flex'} component={'span'}>
                  <Typography varaint="subtitle1">We found the following accounts.</Typography>
                  <QTip arrow title={`Editing the name or account type here will only change what is in ${productName}.`} placement={'right'}>
                    <InfoIcon className={classes.infoIcon} />
                  </QTip>
                </Box>}
              <Box>
                {newAccounts.map((account, index) => (
                  <NewAccountRow
                    account={account}
                    formik={formikBag}
                    index={index}
                    key={account.formFieldName}
                    otherNames={stagedNameList}
                    values={values[account.formFieldName]}
                  />
                ))}
              </Box>
              {!isQuicken && activeAccounts.size > 0 &&
                <>
                  <Box display={'flex'} mt={2} mb={1} component={'span'}>
                    <Typography varaint="subtitle1">Accounts already connected to {productName} for this username.</Typography>
                    <QTip arrow title="These accounts are already connected." placement={'right'}>
                      <InfoIcon className={classes.infoIcon} />
                    </QTip>
                  </Box>
                  <Box>
                    {activeAccounts.map((activeAccount) => (
                      <ActiveAccountRow
                        cpName={activeAccount.discoveredAccount.name}
                        cpNumber={activeAccount.discoveredAccount.maskedNumber}
                        key={activeAccount.account.id}
                        name={activeAccount.account.name}
                        type={activeAccount.account.subType}
                      />
                    ))}
                  </Box>
                </>}
              {!isQuicken && otherAccountsAtSameInstitution.size > 0 &&
              <>
                <Box display={'flex'} mt={2} mb={1} component={'span'}>
                  <Typography varaint="subtitle1">Accounts already connected to {productName} for same institution.</Typography>
                  <QTip arrow title="These accounts are already connected to institution." placement={'right'}>
                    <InfoIcon className={classes.infoIcon} />
                  </QTip>
                </Box>
                <Box>
                  {otherAccountsAtSameInstitution.map((account) => (
                    <ActiveAccountRow
                      cpName={account.aggregators.get(0).accountName}
                      cpNumber={account.aggregators.get(0).accountNumberMasked}
                      key={account.id}
                      name={account.name}
                      type={account.subType}
                    />
                  ))}
                </Box>
              </>}
            </StdDialogContent>
            <StdDialogActions
              primaryId="acct-upsert-submit"
              primaryDisabled={isSubmitting || Boolean(linkError)}
              primaryLabel="Complete"
              secondaryLabel="Cancel"
              secondaryVariant="outlined"
              secondaryOnClick={onCancel}
              tertiaryNode={<TertiaryTrust />}
            />
          </>}
        {newAccounts.length === 0 &&
          <>
            <InteractiveStateView
              primary=" "
              secondary="You have already added all your accounts at this bank."
              goText="TRY ANOTHER BANK"
              backText="CANCEL"
              goClick={() => handleCancelAndReset(true)}
              backClick={() => handleCancelAndReset(false)}
            />
            <div className={classes.footer}>
              <TertiaryTrust />
            </div>
          </>}
      </StdDialogFormik>
    );
  };

  const newAccountsValues = newAccounts.reduce((obj, account) => {
    // eslint-disable-next-line no-param-reassign
    obj[account.formFieldName] = { name: account.name, type: account.type, action: account.action };
    return obj;
  }, {});

  const isBusy = isPolling || isSubmitting;

  return (
    <Box display="flex" flex="1" flexDirection="column">
      { isBusy &&
      <Box display="flex" flexDirection="column" height="0px" style={{ transform: 'translate(0, 48px)' }}>
        <CircularProgress size={130} style={{ margin: 'auto' }} />
      </Box>}
      <Box display="flex" flex="1" flexDirection="column" style={{ visibility: isBusy ? 'hidden' : null }}>
        <Formik
          initialValues={{ ...newAccountsValues }}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnSubmit
        >
          {renderForm}
        </Formik>
      </Box>
    </Box>
  );
};

export default InstitutionAccountsUpdater;

