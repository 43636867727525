const configRaw = require('webappConfig');  // eslint-disable-line

const env = { ...process.env }; // to get build time process env (not the runtime env) (hack)

// eslint-disable-next-line no-restricted-globals
const selfOrWindow = (typeof window !== 'undefined') ? window : self;

export const HOSTNAME = selfOrWindow.location?.hostname;
export const PORT = selfOrWindow.location?.port;
export const PROTOCOL = selfOrWindow.location?.protocol || 'http:';

export const ENVIRONMENT = env.SERVICE_ENV || (HOSTNAME && configRaw.hosts[HOSTNAME]?.environment_default) || 'prod';
export const BUILD_TYPE = (HOSTNAME && configRaw.hosts[HOSTNAME]?.build_override) || env.NODE_ENV || 'production';

function processConfig(config) {

  const newConfig = {};
  Object.keys(config).forEach((key) => {
    let keyObj = config[key];
    if (typeof (config[key]) === 'object' && !Array.isArray(config[key])) {
      keyObj = processConfig(config[key]);
    } else {
      keyObj = typeof (config[key]) === 'string' ? config[key].replace('ENV_PORT', PORT) : config[key];
    }
    newConfig[key] = keyObj;
  });

  return newConfig;
}

export const Config = processConfig(configRaw);

// we can't use logger here - some logger dependencies might not always available in backend environment
// eslint-disable-next-line no-console
// console.log(
//   '\nEnvironment:\n' +
//   '---------- build time\n' +
//   `NODE_ENV = ${env.NODE_ENV}\n` +
//   `SERVICE_ENV = ${env.SERVICE_ENV}\n` +
//   '---------- config (to use)\n' +
//   `BUILD_TYPE = ${BUILD_TYPE}\n` +
//   `ENVIRONMENT = ${ENVIRONMENT}\n` +
//   '---------- window\n' +
//   `HOSTNAME = ${HOSTNAME}\n` +
//   `PORT = ${PORT}\n` +
//   `PROTOCOL = ${PROTOCOL}\n` +
//   '---------- process.env\n',
//   process.env,
//   '\n---------- config\n',
//   Config,
// );

