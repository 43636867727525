import { makeStyles } from 'tss-react/mui';


export const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    maxWidth: 755,
  },
  content: {
    margin: 'auto',
    flex: 1,
  },
  imageRoot: {
    marginLeft: 15,
    flex: 0,
    minWidth: 300,
  },
  image: {
    display: 'block',
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  base: {
    fontWeight: 200,
    padding: 3,
  },
  bodyText: {
    padding: 3,
    fontWeight: 200,
    fontSize: '18px',
    flex: 1,
  },
  bulletListItem: {
    display: 'flex',
  },
  bullet: {
    color: '#426ec1',
    flex: 0,
    minWidth: 32,
    marginTop: 4,
  },
  title: {
    padding: 3,
    fontSize: '25px',
    fontWeight: 400,
  },
  bulletItem: {
    marginTop: 20,
  },
}));
