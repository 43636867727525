import React from 'react';
import PropTypes from 'prop-types';
import QPureComponent from 'QPureComponent';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import withRouterLegacy from 'components/withRouterLegacy';

import { accountsSelectors } from 'companion-app-components/flux/accounts';

import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';
import Skeleton from '@mui/material/Skeleton';

import zeroStateIcon from 'assets/zero-state-images/pie-chart.svg';

import { isAcme } from 'isAcme';

// import LoadingView from 'components/LoadingView';
import ZeroStateView from 'components/ZeroStateView';
import MonthlyBarChart from 'containers/Reports/components/monthlyBarChart';

import { getSpendingForPeriods, getIncomeForPeriods } from 'data/otReports/selectors';

import { getPredefinedRange } from 'utils/date/utils';
import { tracker } from 'companion-app-components/utils/core';

import { computeViewState } from '../helpers';

const styles = (theme) => ({
  root: {
    height: '100%',
  },
  link: {
    color: theme.palette.link,
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'none',
    },
  },
  skeletonBarOne: {
    background: theme.palette.greyScaleDeprecated[5],
    marginBottom: '4%',
    marginLeft: '14%',
    marginRight: '4%',
    borderRadius: 8,
  },
  skeletonBar: {
    background: theme.palette.greyScaleDeprecated[5],
    margin: '4%',
    borderRadius: 8,
    minWidth: 10,
  },
  skeletonBarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 300,
    alignItems: 'flex-end',
    paddingBottom: '60px',
    justifyContent: 'space-between',
  },
});

class MonthlyData extends QPureComponent {

  static propTypes = {
    hasReportableAccounts: PropTypes.bool,
    monthlyData: PropTypes.object,
    history: PropTypes.object,
    classes: PropTypes.object,
    theme: PropTypes.object,
    overrideMonthlyData: PropTypes.object,
    toolTipLabel: PropTypes.string,
    onBarGraphClick: PropTypes.func,
    isIncome: PropTypes.bool,
    excludeCategoryInTooltip: PropTypes.bool,
  };

  constructor(props) {
    const { isIncome, theme } = props;
    super(props);
    this.state = {
      selectedMonthIndex: null,
    };
    this.colors = {
      positiveDefault: isIncome
        ? theme.components.income.positiveDefault
        : theme.components.spendingOverTime.positiveDefault,
      positiveDimmed: isIncome
        ? theme.components.income.positiveDimmed
        : theme.components.spendingOverTime.positiveDimmed,
      negativeDefault: isIncome
        ? theme.components.income.negativeDefault
        : theme.components.spendingOverTime.negativeDefault,
      negativeDimmed: isIncome
        ? theme.components.income.negativeDimmed
        : theme.components.spendingOverTime.negativeDimmed,
      tooltipBullet: isIncome
        ? theme.components.income.positiveDefault
        : theme.components.spendingOverTime.positiveDefault,
    };
  }

  onBarGraphClick = (indexOfClickedMonth) => {
    if (this.props.onBarGraphClick) {
      return this.props.onBarGraphClick(indexOfClickedMonth);
    }
    const { isIncome } = this.props;
    this.setState({
      selectedMonthIndex: indexOfClickedMonth,
    });
    if (indexOfClickedMonth === 0 || indexOfClickedMonth) {
      tracker.track(tracker.events.barSelected, { chart: isIncome ? 'income' : 'spending' });
      setTimeout(() => { this.navigateToReportsWithMonth(indexOfClickedMonth); }, 1000);
    }
    return false;
  };

  navigateToReportsWithMonth = (index) => {
    const { isIncome } = this.props;
    this.props.router.navigate(`/reports/${isIncome ? 'income' : 'spending'}/by-none/over-time${index !== null ? `?selectMonth=${index}` : ''}`);
  };

  navigateToReports = (e) => {
    e.stopPropagation();
    if (this.props.onBarGraphClick) {
      return;
    }
    const { isIncome } = this.props;
    this.props.router.navigate(`/reports/${isIncome ? 'income' : 'spending'}/by-none/over-time`);
  };

  dataIsNull = () => (this.props.overrideMonthlyData || this.props.monthlyData) === null;
  dataIsEmpty = () => {
    const monthlyData = (this.props.overrideMonthlyData || this.props.monthlyData);
    !this.dataIsNull() && monthlyData.every((data) => data.txns.size === 0);
  };

  render() {
    const { classes, hasReportableAccounts, monthlyData, isIncome, overrideMonthlyData, toolTipLabel, excludeCategoryInTooltip } = this.props;
    const { selectedMonthIndex } = this.state;
    const view = computeViewState(this.dataIsNull, this.dataIsEmpty, hasReportableAccounts);

    return (
      <div
        className={classes.root}
        role="presentation"
      >
        <>
          {view === 'loading' &&
          <div className={classes.skeletonBarWrapper}>
            <Skeleton variant="rectangular" width={50} height="40%" className={classes.skeletonBarOne} />
            <Skeleton variant="rectangular" width={50} height="50%" className={classes.skeletonBar} />
            <Skeleton variant="rectangular" width={50} height="40%" className={classes.skeletonBar} />
            <Skeleton variant="rectangular" width={50} height="40%" className={classes.skeletonBar} />
            <Skeleton variant="rectangular" width={50} height="50%" className={classes.skeletonBar} />
            <Skeleton variant="rectangular" width={50} height="60%" className={classes.skeletonBar} />
          </div>}
          {view === 'zero' &&
            <ZeroStateView
              icon={zeroStateIcon}
              onClick={this.navigateToReports}
              secondary={[
                'Sync your financial institutions to',
                <br key="br" />,
                `see your ${isIncome ? 'income' : 'spending'} over time.`,
              ]}
            />}
          {(view === 'empty' && !isAcme) &&
            <ZeroStateView
              icon={zeroStateIcon}
              onClick={this.navigateToReports}
              primary={`No ${isIncome ? 'Income' : 'Spending'}`}
              secondary={`It looks like you haven't ${isIncome ? 'received' : 'spent'} any money in the last six months.`}
            />}
          {/* Simplifi empty spending by month card */}
          {(view === 'empty' && isAcme && !isIncome) &&
          <ZeroStateView
            size="small"
            icon={zeroStateIcon}
            onClick={this.navigateToReports}
            primary={'No Spending'}
            secondary={"It looks like you haven't spent any money in the last six months."}
          />}
          {/* Simplifi empty income by month card */}
          {(view === 'empty' && isAcme && isIncome) &&
          <ZeroStateView
            icon={zeroStateIcon}
            onClick={this.navigateToReports}
            primary={'No Income'}
            secondary={"It looks like you haven't received any money in the last six months."}
          />}
          {view === 'main' &&
          <MonthlyBarChart
            idPrefix={isIncome ? 'income' : 'spending'}
            negator={isIncome ? 1 : -1}
            selectedMonthIndex={selectedMonthIndex}
            monthlyData={overrideMonthlyData || monthlyData}
            sequencedInterpolation
            excludeCategoryInTooltip={excludeCategoryInTooltip}
            onBarGraphClick={this.onBarGraphClick}
            navigateToReports={this.navigateToReports}
            tooltipLabel={toolTipLabel || (isIncome ? 'Income' : 'Spending')}
            getBarAmount={(amount) => isIncome ? amount : -amount}
            colors={this.colors}
            defaultStateTransitionDuration={600}
            hiddenStateTransitionDuration={400}
            isDashboard={this.props.isDashboard}
          />}
        </>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const dateInterval = getPredefinedRange({ value: '6_MONTHS' });
  let monthlyData = ownProps.overrideMonthlyData;
  if (!monthlyData) {
    monthlyData = ownProps.isIncome
      ? getIncomeForPeriods(state, { dateInterval, ...ownProps })
      : getSpendingForPeriods(state, { dateInterval, ...ownProps });
  }

  return {
    hasReportableAccounts: accountsSelectors.getHasReportableAccounts(state, ownProps),
    showZeroState: window.location.href.indexOf('zeroState') !== -1,
    monthlyData: monthlyData ? monthlyData.periods : null,
  };
}

export default compose(
  withTheme,
  connect(mapStateToProps),
  withRouterLegacy,
)(withStyles(MonthlyData, styles));
