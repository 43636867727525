import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { OrderedMap } from 'immutable';

import { undoActions } from 'companion-app-components/flux/undo';
import IconButton from '@mui/material/IconButton/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

import store from 'store';

import { tracker } from 'companion-app-components/utils/core';
import { mkNotification } from './notificationsTypes';
import * as notificationsActions from './notificationsActions';

export const dispatchSimpleNotification = (message) => {
  const id = uuidv4();
  store.dispatch(notificationsActions.addNotifications(OrderedMap([[id, mkNotification({ id, message })]])));
};

export const notificationFromUndo = (undo) => (
  mkNotification({
    id: 'toast-message', //  undo.key,
    message: undo.userMessage,
    createdAt: undo.createdAt,
    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
    snackbarContentProps: {
      action: [
        ...(!undo.actions?.length ? [] : [
          <Button
            id="undo-button"
            key="undo"
            aria-label="undo"
            color="primary"
            onClick={() => {
              tracker.track(tracker.events.undoAction,
                {
                  type: undo.actions[0].type,
                });
              store.dispatch(undoActions.undo(undo));
            }}
          >
            Undo
          </Button>,
        ]),
        <IconButton
          id="close-undo"
          key="close"
          aria-label="Close"
          onClick={() => store.dispatch(undoActions.deleteUndo(undo))}
          size="large"
          style={{ color: '#FFFFFF' }}  //  MUI5 broke color="inherit", so it is just set directly
        >
          <CloseIcon />
        </IconButton>,
      ],
      onClose: (event, reason) => {
        if (reason !== 'clickaway') {
          store.dispatch(undoActions.deleteUndo(undo));
        }
      },
    },
  })
);
