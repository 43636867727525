// @flow
import { createAction } from 'utils/actionHelpers';

// plural
export const getBillerLogins = createAction('GET_BILLER_LOGINS');
export const getBillerLoginsSuccess = createAction('GET_BILLER_LOGINS_SUCCESS');
export const getBillerLoginsFailure = createAction('GET_BILLER_LOGINS_FAILURE');

// singular
export const startRediscovery = createAction('START_REDISCOVERY');
export const rediscoverBillerLogin = createAction('REDISCOVER_BILLER_LOGIN');

export const getBillerLogin = createAction('GET_BILLER_LOGIN');
export const getBillerLoginSuccess = createAction('GET_BILLER_LOGIN_SUCCESS');
export const getBillerLoginFailure = createAction('GET_BILLER_LOGIN_FAILURE');

export const updateBillerLogin = createAction('UPDATE_BILLER_LOGIN');
export const updateBillerLoginSuccess = createAction('UPDATE_BILLER_LOGIN_SUCCESS');
export const updateBillerLoginFailure = createAction('UPDATE_BILLER_LOGIN_FAILURE');

export const deleteBillerLogin = createAction('DELETE_BILLER_LOGIN');
export const deleteBillerLoginSuccess = createAction('DELETE_BILLER_LOGIN_SUCCESS');
export const deleteBillerLoginFailure = createAction('DELETE_BILLER_LOGIN_FAILURE');

export const discoverBiller = createAction('DISCOVER_BILLER');
export const discoverBillerSuccess = createAction('DISCOVER_BILLER_SUCCESS');
export const discoverBillerFailure = createAction('DISCOVER_BILLER_FAILURE');

export const addBillerLoginAndAccounts = createAction('ADD_BILLER_LOGIN_AND_ACCOUNTS');




