export const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: '100',
  },
  spacedButton: {
    top: 6,
    right: 6,
  },
  title: {
    maxHeight: 76,
    alignItems: 'center',
    flex: '0 0 auto',
    zIndex: '0',
    padding: '16px 0px 16px 20px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.greyScaleDeprecated[6],
  },
  backButton: {
    position: 'absolute',
    left: 12,
    padding: 4,
    top: 12,
    zIndex: 100,
  },
});
