import { DateTime } from 'luxon';
import { chartOfAccountsUtils } from 'companion-app-components/flux/chart-of-accounts';

import { getQueryByName } from 'utils/queryUtils';

// Uses dateTime to avoid moment's 'incorrect input' warning
// returns an ISODate
export function parseDateString(string) {
  if (string) {
    let date = DateTime.fromISO(string);
    if (!date.isValid) {
      date = DateTime.fromRFC2822(string);
    } 
    if (!date.isValid) {
      date = DateTime.fromHTTP(string);
    }
    if (!date.isValid) {
      date = DateTime.fromJSDate(new Date(string));
    }
    return date.isValid ? date.toISODate() : undefined;
  }
  return undefined;
}


export function getEnvFromHostname(hostname) {
  const regex = /^signin-([^.]+)\.quickencs\.com/;
  const match = regex.exec(hostname);
  if (match && match.length === 2) {
    return match[1];
  }
  return 'prod';
}

export function getClientTypeFromClientId(clientId) {
  const regex = /^quicken(?:_.+)?_(.+)/;
  const match = regex.exec(clientId);
  if (match && match.length === 2) {
    return match[1];
  }
  return 'unknown';
}

export function checkForQueryFlag(query, flag) {
  let result = false;
  if (query[flag] !== undefined) {
    result = query[flag] !== null ? query[flag] : true;
  }
  return result;
}

// utility function to simple copy an object
export function copyObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}


export function noNaN(n) {
  return (parseFloat(n) ? n : 0);
}
//-------------------------------------------------------
// Normalize amount to 2 digits (cents)

export function normalizeAmt(amt, sign = 1) {
  return (parseFloat((parseFloat(amt) * sign).toFixed(2)).toString());
}

export function addEventListenerOnce(element, event, fn) {
  const func = () => {
    element.removeEventListener(event, func);
    fn();
  };
  element.addEventListener(event, func);
}

export function filterByKeys(obj, filterFn) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (filterFn(obj[key])) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export function mapJSObject(obj, fn) {

  const ret = {};
  Object.keys(obj).forEach((key) => {
    ret[key] = fn(obj[key]);
  });
  return ret;
}

export function findInObject(obj, compareFn) {
  const matches = [];
  Object.keys(obj).forEach((key) => {
    if (compareFn(obj[key], key)) {
      matches.push(obj[key]);
    }
  });
  return matches;
}

export function reduceObjectToArray(obj, filterFn) {
  const newArray = [];
  Object.keys(obj).forEach((key) => {
    newArray.push(filterFn(obj[key]));
  });
  return newArray;
}

export function exists(v) {
  return (v !== undefined && v !== null);
}

export function isOldEdge() {
  return (navigator.userAgent.indexOf('Edge') > -1);
}

export function isMac() {
  return (navigator.userAgent.indexOf('Mac') > -1);
}

// ask for 'safari', 'firefox', 'edge', or 'opera'
export function isBrowser(type) {

  const browserMap = {};
  browserMap.chrome = navigator.userAgent.indexOf('Chrome') > -1;
  browserMap.firefox = navigator.userAgent.indexOf('Firefox') > -1;
  browserMap.safari = navigator.userAgent.indexOf('Safari') > -1;
  browserMap.opera = navigator.userAgent?.toLowerCase().indexOf('op') > -1;
  if ((browserMap.chrome) && (browserMap.safari)) { browserMap.safari = false; }
  if ((browserMap.chrome) && (browserMap.opera)) { browserMap.chrome = false; }

  return browserMap[type?.toLowerCase()];
}


export function dispatchEscapeKeyEvent() {
  const e = new Event('keydown');
  e.key = 'escape';
  e.keyCode = 27;
  e.which = e.keyCode;
  e.altKey = false;
  e.ctrlKey = true;
  e.shiftKey = false;
  e.metaKey = false;
  document.dispatchEvent(e);
}

export function safeRefIn(obj, refs) {
  if (obj && refs) {
    if (refs.length === 0) {
      return obj;
    }
    return safeRefIn(obj[refs[0]], refs.slice(1));
  }
  return null;
}

export function listSize(list) {
  if (list.size !== undefined) return list.size;
  return list.length;
}

export function replaceRawASCII(string) {
  return string?.replace(/&#(.*?);/g, (code) => {
    let num = code.replace(/&#|x|;/g, '');
    num = parseInt(num, (code[2] === 'x') ? 16 : 10);
    return String.fromCharCode(num);
  });
}



export function isValidQCSId(id) {
  return id >= 1000000000000;
}

export function isBrokenAccountId(id) {
  return chartOfAccountsUtils.unSyncedIdsList.includes(String(id)) ||
    String(id) === chartOfAccountsUtils.TRANSFER_NOT_FOUND_COA_ID;
}

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const generateAcNodeId = (acNodeId, location) => {
  let acNodeIdCopy = acNodeId;
  if (acNodeIdCopy === 'HIDDEN') {
    const type = getQueryByName(location, 'type');
    if (type) {
      acNodeIdCopy += `_${type}`;
    }
  }
  return acNodeIdCopy;
};

export const getPayeeDisplayText = (payee) => payee || 'No Payee';
