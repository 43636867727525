import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { makeStyles, withStyles } from 'tss-react/mui';

import ClearAllIcon from '@mui/icons-material/ClearAll';

import LogoText from 'components/logoText';

import Canvas from './canvas';

const useStyles = makeStyles()((theme: Record<string, any>) => ({
  canvas: {
    height: '100%',
    width: '100%',
  },
  colors: {
    display: 'flex',
  },
  color: {
    borderRadius: '50%',
    height: '50px',
    width: '50px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '90%',
  },
  grouped: {
    borderRadius: '50%',
  },
  header: {
    height: 64,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'transparent',
    padding: 8,
    marginTop: 32,
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      margin: 0,
    },
  },
  main: {
    height: '100vh',
    maxWidth: 1152,
    margin: '0  auto !important',
    '@media (min-width: 769px)': {
      marginLeft: theme.defaults.content.paddingLeft,
      marginRight: theme.defaults.content.paddingRight,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paletteSelector: {
    alignSelf: 'center',
  },
  paper: {
    boxShadow: '0 8px 24px 0 rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minWidth: 200,
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      background: 'none',
      margin: 16,
    },
  },
  toolarea: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  toolbar: {
    justifyContent: 'center',
  },
}));

const StyledToggleButtonGroup = withStyles(ToggleButtonGroup, (theme) => ({
  root: {
    justifyContent: 'center',
  },
  grouped: {
    margin: theme.spacing(2),
    border: 'none',
    borderRadius: '50%',
    '&:not(:first-child)': {
      borderRadius: '50%',
    },
    '&:not(:last-child)': {
      borderRadius: '50%',
    },
  },
}));

const StyledToggleButton = withStyles(ToggleButton, () => ({
  root: {
    borderRadius: '50%',
  },
  selected: {
    border: 'black solid 2px',
  },
}));

const palettes = {
  default: {
    name: 'Default Palette',
    colors: ['#F4908E', '#F2F097', '#88B0DC', '#F7B5D1', '#53C4AF', '#FDE38C'],
  },
  PaulTol: {
    name: 'Paul Tol Palette',
    colors: ['#B997C6', '#824D99', '#4E79C4', '#57A2AC', '#7EB875', '#D0B440', '#E67F33'],
  },
};

const OfflinePage: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const { classes }: Record<string, any> = useStyles();
  const theme: Record<string, any> = useTheme();

  const [palette, setPalette] = useState(palettes.default);
  const [color, setColor] = useState(palettes.default.colors[0]);

  const handleClear = () => canvasRef && canvasRef.current?.clear();
  const handleColorChange = (_event, newColor) => setColor(newColor);
  const handlePaletteChange = (event) => {
    setPalette(event.target.value);
    setColor(event.target.value.colors[0]);
  };

  return (
    <>
      <Helmet>
        <title>{theme.project}</title>
        <meta name="offline" content="Offline Page" />
      </Helmet>

      <div className={classes.main}>
        <div className={classes.header}>
          <LogoText />
        </div>
        <Slide direction="up" in appear mountOnEnter unmountOnExit>
          <div className={classes.content}>
            <div>
              <Typography align="center" variant="h5">
                So, there&apos;s good news and bad news
              </Typography>
              <Typography align="center" variant="subtitle1">
                Bad news: It looks like you&apos;re offline
              </Typography>
              <Typography align="center" variant="subtitle1">
                Good news: Until we support working offline, you can draw a picture when offline
              </Typography>
              <Typography align="center" variant="body1">
                (pick a color and start drawing)
              </Typography>
            </div>
            <div className={classes.toolarea}>
              <Select
                className={classes.paletteSelector}
                value={palette}
                onChange={handlePaletteChange}
                inputProps={{ 'aria-label': 'Palette' }}
              >
                {Object.keys(palettes).map((key) => (
                  <MenuItem key={key} value={palettes[key]}>{palettes[key].name}</MenuItem>
                ))}
              </Select>
              <Toolbar className={classes.toolbar}>
                <StyledToggleButtonGroup
                  classes={{
                    grouped: classes.grouped,
                  }}
                  className={classes.colors}
                  exclusive
                  onChange={handleColorChange}
                  value={color}
                >
                  {palette.colors.map((paletteColor, index) => (
                    <StyledToggleButton
                      aria-label={`color-${index}`}
                      className={classes.color}
                      sx={{ bgcolor: paletteColor }}
                      value={paletteColor}
                    />
                  ))}
                </StyledToggleButtonGroup>
                <IconButton
                  aria-label="Clear All"
                  className={classes.button}
                  color="primary"
                  onClick={handleClear}
                  size="large"
                >
                  <ClearAllIcon />
                </IconButton>
              </Toolbar>
            </div>
            <Paper
              className={classes.paper}
              square
            >
              <Canvas className={classes.canvas} color={color} ref={canvasRef} />
            </Paper>

          </div>
        </Slide>
      </div>
    </>);
};

export default OfflinePage;
