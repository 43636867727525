import React, { FC, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';

import SettingsIcon from '@mui/icons-material/SettingsRounded';

import { datasetsSelectors, datasetsUtils } from 'companion-app-components/flux/datasets';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { authSelectors } from 'companion-app-components/flux/auth';
import type RootState from 'companion-app-components/utils/redux-store/rootState';

import { regFieldsSortType } from 'data/preferences/defaults';

import QMenu from 'components/QMenu';
import useQPreferences from 'components/QPreferences/useQPreferences';
import QHelp from 'components/QHelp';
import {
  getAvailableColumns, getColumnDefaults,
  fieldData,
} from 'components/TransactionRegister/transactionsConfig';
import compose from 'utils/compose';

interface TxPrefMenuProps {
  selected: string;
  onChange: (str: string) => void;
  customItems: Record<string, any>[];
  accountType: string;
  showQHelp: (str: string) => void;
}

const TxPrefMenu: FC<TxPrefMenuProps> = ({
  selected: initialSelected,
  onChange,
  customItems,
  accountType,
  showQHelp,
}) => {

  const { datasetPreferences, setDatasetPreference } = useQPreferences(); 

  const [selected, setSelected] = useState(initialSelected);
  const [displayColumnsKey, setDisplayColumnsKey] = useState(
    regFieldsSortType(datasetPreferences.transactionRegister.regFieldsSort, accountType),
  );

  const datasetId = useSelector((state: RootState) => authSelectors.getDatasetId(state));
  const datasetsById = useSelector((state: RootState) => datasetsSelectors.getDatasetsById(state));
  const prefShowPreferences = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state)).get('prefShowPreferences');

  useEffect(() => {
    setSelected(initialSelected);
  }, [initialSelected]);

  useEffect(() => {
    setDisplayColumnsKey(
      regFieldsSortType(datasetPreferences.transactionRegister.regFieldsSort, accountType),
    );
  }, [datasetPreferences, accountType]);

  const fieldIsChecked = useCallback(
    (field) =>
      datasetPreferences.transactionRegister.regFieldsSort[displayColumnsKey].includes(field),
    [datasetPreferences, displayColumnsKey],
  );

  const calculateInsertPosition = useCallback(
    (regSort, fieldObj) =>
      regSort.findIndex((x) => fieldData[x].position > fieldObj.position),
    [],
  );

  const resetDefaults = useCallback(() => {
    const regSort = ImmutableList(getColumnDefaults(accountType));
    const newPref = { transactionRegister: { regFieldsSort: { [displayColumnsKey]: regSort } } };
    setDatasetPreference(newPref);
  }, [accountType, displayColumnsKey, setDatasetPreference]);

  const handleChange = useCallback(
    (value, groupId) => {
      if (groupId === 'txnColumns') {
        let regSort = datasetPreferences.transactionRegister.regFieldsSort[displayColumnsKey];

        if (fieldIsChecked(value)) {
          const index = regSort.indexOf(value);
          if (index >= 0) {
            regSort = regSort.delete(index);
            const newPref = { transactionRegister: { regFieldsSort: { [displayColumnsKey]: regSort } } };
            setDatasetPreference(newPref);
          }
        } else {
          const slot = calculateInsertPosition(regSort, fieldData[value]);
          regSort = regSort.insert(slot, value);
          const newPref = { transactionRegister: { regFieldsSort: { [displayColumnsKey]: regSort } } };
          setDatasetPreference(newPref);
        }
        return;
      }

      if (value === 'resetDefaults') {
        resetDefaults();
        return;
      }

      if (value === 'reviewedHelp') {
        showQHelp('windowsReviewed');
        return;
      }

      if (onChange) {
        onChange(value);
      }
    },
    [datasetPreferences, displayColumnsKey, setDatasetPreference, showQHelp, onChange, calculateInsertPosition, fieldIsChecked, resetDefaults],
  );

  const menuItems = [
    {
      label: 'Transaction Columns',
      groupId: 'txnColumns',
      isGroupList: true,
      groupList: getAvailableColumns(accountType).map((field, index) => ({
        noCloseOnClick: true,
        selected: fieldIsChecked(field),
        label: fieldData[field].label,
        value: field,
        divider: index === getAvailableColumns(accountType).length - 1,
      })),
    },
    { label: 'Reset Defaults', value: 'resetDefaults' },
  ];

  if (datasetsUtils.isWindowsDataset(datasetsById.get(datasetId))) {
    menuItems.push({ label: 'About Reviewed...', value: 'reviewedHelp' });
  }

  let options = [
    {
      label: 'Transaction Display',
      groupId: 'txnDisplay',
      isGroupList: true,
      selected,
      groupList: [
        { label: 'Comfortable', value: 'comfortable' },
        { label: 'Normal', value: 'normal' },
        { label: 'Compact', value: 'compact', divider: true },
      ],
    },
    {
      label: 'Show/Hide Columns',
      value: 'txcols',
      subMenu: menuItems,
      itemOpensMenu: true,
    },
  ];

  if (prefShowPreferences) {
    options.push({
      label: 'Preferences...',
      value: 'preferences',
      divider: Boolean(customItems),
    });
  }

  if (customItems) {
    options = options.concat(customItems);
  }

  return (
    <div>
      <QMenu
        name="txpref"
        title="Transactions Settings"
        menuIcon={SettingsIcon}
        menuIconButtonSize="small-target"
        options={options}
        onChange={handleChange}
      />
    </div>
  );
};

export default compose(
  QHelp(), 
)(TxPrefMenu);
