import { createAction } from 'redux-actions';
import { Map as ImmutableMap } from 'immutable';

import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';

export const DIALOG_TYPE = 'WHATS_NEW';

export const showWhatsNewDialog = createAction(
  createDialog.toString(),
  (pageData, dispatchShowReleaseNotesDialog) => mkRootUiData({
    type: DIALOG_TYPE,
    allowNesting: true,
    props: ImmutableMap({
      pageData,
      dispatchShowReleaseNotesDialog,
    }),
  })
);
