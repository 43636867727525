import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import StdDialog from 'components/Dialogs/StdDialog';

const ReleaseNotesView = ({ classes, removeDialog }) => (
  <StdDialog
    open
    onClose={removeDialog}
    title="Release Notes"
    sharedcomponentid="RELEASE_NOTES"
    titleClasses={classes.dialogTitle}
    PaperProps={{
      sx: {
        maxWidth: 800,
        maxHeight: 700,
        width: '75vw',
        height: '90vh',
      },
    }}
  >
    <DialogContent className={classes.dialogContent}>
      <iframe
        title="Release Notes"
        src="/release-notes.html"
        className={classes.iFrame}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={removeDialog}>Done</Button>
    </DialogActions>
  </StdDialog>
);

ReleaseNotesView.propTypes = {
  classes: PropTypes.object,
  removeDialog: PropTypes.func,
};

export default React.memo(ReleaseNotesView);
