
import React, { FC } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';

import QCurrency from 'components/QCurrency';

interface BudgetSummaryItemProps {
  classes: Record<string, any>;
  amount: number;
  title: string;
  currency: string;
  disableColor: boolean;
  formatAmount: (amount: number, currency: string, showCent: boolean) => string;
}

const BudgetSummaryItem: FC<BudgetSummaryItemProps> = ({ title, amount, classes, currency, disableColor, formatAmount }) => {
  const colorClass = !disableColor && (amount < 0 ? 'negative' : 'positive');
  return (
    <div
      className={classes.summaryItem}
    >
      <Typography
        variant="body2"
        className={classes.summaryItemHeader}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={classNames(classes.summaryItemBody, colorClass)}
      >
        {formatAmount(amount, currency, true)}
      </Typography>
    </div>
  );
};

export default compose(
  QCurrency(),
)(BudgetSummaryItem);
