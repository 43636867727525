import { initializeUtilsCore } from 'companion-app-components/utils/core';

import './configInit';
import platform from './platform';

const History = {
  navigate: null,

  push: (page, ...other) => History.history.push(page, ...other),
  replace: (page, ...other) => History.history.replace(page, ...other),
};

initializeUtilsCore({
  history: History,
  platform,
  localStorage,
});
