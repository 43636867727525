import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

const useStyles = makeStyles()((theme) => ({
  checkedIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    boxShadow: theme.shadows[2],
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  circularProgress: {
    display: 'grid',
    color: theme.palette.grey[50],
  },
}));

const QSwitch = React.forwardRef((props, ref) => {
  const { showCheckProgress, showUncheckProgress, checked, onChange, disabled, id, ...otherProps } = props;
  const { classes } = useStyles(props);
  const [internalChecked, setInternalChecked] = useState(false);
  const [internalDisabled, setInternalDisabled] = useState(false);

  useEffect(() => {
    setInternalChecked(checked);
    setInternalDisabled(false);
  }, [checked]);

  useEffect(() => {
    if (!showCheckProgress && !showUncheckProgress) {
      setInternalDisabled(false);
    }
  }, [showCheckProgress, showUncheckProgress]);

  return (
    <Switch
      id={`${id}-${checked ? 'checked' : 'unchecked'}`}
      sharedcomponentid={'Q_SWITCH'}
      ref={ref}
      disabled={disabled || internalDisabled}
      checked={checked}
      onChange={(event, value) => {
        setInternalChecked(value);
        setInternalDisabled(true);
        if (onChange) {
          onChange(event, value);
        }
      }}
      color="primary"
      {...(showCheckProgress && internalChecked !== checked ? {
        icon: (
          <Zoom in mountOnEnter unmountOnExit>
            <Fab
              sx={{
                all: 'unset',
                '&:hover, &:focus, &:active': {
                  all: 'unset',
                },
              }}
              disableRipple
              disableFocusRipple
            >
              <span className={classes.checkedIcon}>
                <CircularProgress
                  className={classes.circularProgress}
                  size={12}
                />
              </span>
            </Fab>
          </Zoom>
        ),
      } : undefined)}
      {...(showUncheckProgress && internalChecked !== checked ? {
        checkedIcon: (
          <Zoom in mountOnEnter unmountOnExit>
            <Fab
              sx={{
                all: 'unset',
                '&:hover, &:focus, &:active': {
                  all: 'unset',
                },
              }}
              disableRipple
              disableFocusRipple
            >
              <span className={classes.checkedIcon}>
                <CircularProgress
                  className={classes.circularProgress}
                  size={12}
                />
              </span>
            </Fab>
          </Zoom>
        ),
      } : undefined)}
      {...otherProps}
    />
  );
});

QSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showCheckProgress: PropTypes.bool,
  showUncheckProgress: PropTypes.bool,
  id: PropTypes.string,
};

export default QSwitch;
