import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { List } from 'immutable';

import { BudgetTreeNodeProps, BudgetItemType } from 'companion-app-components/flux/budgets/budgetsTypes';

import ZeroStateView from 'components/ZeroStateView';
import EmbeddedRegister from 'components/EmbeddedRegister';
import BudgetNode from 'components/Budgets/BudgetNode';
import { cacheableObject } from 'utils/objectCache';
import AnnualViewTable from 'containers/BudgetsV2/components/AnnualViewTable';
import { BUDGET_VIEWS } from 'containers/BudgetsV2/constants';
import AnnualSpendingChart from '../AnnualSpendingChart';
import { useBudgetOptions } from '../../hooks';
import styles from './styles';
import { BudgetUiStateType } from '../../types';

interface BudgetEmbeddedProps {
  budgetsCreation: boolean;
  budget: BudgetItemType;
  budgetTreeNodes: List<BudgetTreeNodeProps>;
  isLoading: boolean;
  transactions: Record<string, any>;
  accountIds: Record<string, any>;
  navigationPath: Record<string, any>[];
  onTxnUpdate: () => void;
  handleNavigationClick: (node: null | Record<string, any>) => void;
  budgetUiState: BudgetUiStateType;
  setBudgetUiState: (key: Record<string, any>) => void;
  dashboardRef: string | string[] | null,
}

let nodeIndex = 0;
const mountId = uuidv4();
const useStyles = makeStyles()(styles as Record<string, any>);

const BudgetEmbedded: FC<BudgetEmbeddedProps> = ({
  navigationPath,
  isLoading,
  budget,
  transactions,
  budgetTreeNodes,
  accountIds,
  budgetsCreation,
  onTxnUpdate,
  handleNavigationClick,
  budgetUiState,
  setBudgetUiState,
  dashboardRef,
}) => {

  const { classes, theme }: { classes: Record<string, any>, theme: Record<string, any> } = useStyles();
  const [transactionsBarStuck, setTransactionsBarStuck] = useState(false);
  const { budgetView } = useBudgetOptions(budget.id, dashboardRef);

  useEffect(() => {
    let transactionsBarObserver: Record<string, any> | null = null;
    const transactionsBarSentinel = document.querySelector('#transactionsBarSentinel');
    if (transactionsBarSentinel && typeof IntersectionObserver !== 'undefined') {
      transactionsBarObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTransactionsBarStuck(false);
          } else {
            setTransactionsBarStuck(true);
          }
        });
      }, {
        root: null,
        threshold: 0,
      });
      transactionsBarObserver?.observe(transactionsBarSentinel);
    }

    return () => {
      transactionsBarObserver?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoading && (
    <div
      className={classes.root}
      id="container"
    >
      {budgetView === BUDGET_VIEWS.annual.key ? (
        <div className={classes.content} id="annual-view">
          <AnnualSpendingChart budgetId={budget.id as string} />
          <AnnualViewTable 
            budget={budget} 
            budgetUiState={budgetUiState} 
            setBudgetUiState={setBudgetUiState} 
            updateBudgetCalcAndItems={onTxnUpdate}
          />
        </div>
      ) : (
        <>
          <div className={classes.content} id="budgetItems">
            <div id="budgetsBarSentinel" className={classes.transactionsBarSentinel} />
            {budgetTreeNodes && budgetTreeNodes.size > 0 ?
              <Paper className={classes.barSection}>
                <div className={classes.breadCrumbWrapper}>
                  <button
                    id="budgets-title"
                    className={classNames(classes.pathButton, { isLastLeaf: !navigationPath })}
                    color="primary"
                    disabled={!navigationPath}
                    onClick={() => handleNavigationClick(null)}
                    type="button"
                  >
                    {budget && budget.name}
                  </button>
                  {navigationPath && navigationPath.map((node, i) => (
                    <span key={node.key}>
                      <span className={classes.pathDivider}>/</span>
                      <button
                        id={`budgets-path-${i}`}
                        className={classNames(classes.pathButton, { isLastLeaf: i === navigationPath.length - 1 })}
                        color="primary"
                        onClick={() => handleNavigationClick(node)}
                        type="button"
                      >
                        {node.displayLabel}
                      </button>
                    </span>
                  ))}
                </div>
                {budgetTreeNodes.map((node) => (
                  <React.Fragment key={node.key}>
                    <BudgetNode
                      index={nodeIndex++} // eslint-disable-line no-plusplus
                      key={node.key}
                      style={{ padding: 20 }}
                      budget={budget}
                      node={node}
                      showChildLink={!navigationPath}
                      editInPlace={!budgetsCreation}
                      isParent
                    />
                    {navigationPath && navigationPath.length > 0 && node.children &&
                      node.children.map((childNode) => (
                        <BudgetNode
                          index={nodeIndex++} // eslint-disable-line no-plusplus
                          key={childNode.key}
                          style={{ padding: 20, paddingLeft: 60 }}
                          showChildLink
                          budget={budget}
                          node={childNode}
                          editInPlace={!budgetsCreation}
                        />
                      ))}
                  </React.Fragment>
                ))}
              </Paper>
              :
              <ZeroStateView
                primary=" "
                secondary="There are no budget items to display."
              />}
          </div>

          <AppBar
            position="sticky"
            color="default"
            elevation={transactionsBarStuck ? 5 : 0}
            className={classes.header}
            onClick={(event) => {
              event.stopPropagation();
              const element = document.querySelector('#transactionsBarSentinel') as HTMLElement;
              if (element && window.scrollTo) {
                window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
              }
            }}
          >
            <Paper elevation={0} square>
              <Toolbar className={classes.headerContent}>
                <div
                  id="budgets-transactions-heading"
                  className={classes.transactionsHeader}
                >
                  {'Transactions for '}
                  <span style={{ fontWeight: 500, color: theme.palette.greyScaleDeprecated[1] }}>
                    {navigationPath && navigationPath.length > 0
                      ? navigationPath[navigationPath.length - 1].displayLabel
                      : budget && budget.name}
                  </span>
                </div>
              </Toolbar>
            </Paper>
          </AppBar>
          <div id="budgetsBarSentinel" className={classes.transactionsBarSentinel} />
          {transactions && transactions.size > 0 ?
            <div
              id="transactions"
              className={classes.content}
              style={{ height: 568 }}
            >
              <EmbeddedRegister
                name={`Reg_Budgets-${mountId}`}
                overrideTxns={cacheableObject(transactions)}
                currency={budget && budget.currency}
                showControls
                hideDividers
                hideSearch
                noNew
                bottomMargin={0}
                accountIds={cacheableObject(accountIds)}
                onTxnUpdate={onTxnUpdate}
              />
            </div> :
            <ZeroStateView
              size="small"
              primary=" "
              secondary="There are no transactions to display."
            />}
          <div style={{ height: theme.spacing(3) }}>&nbsp;</div>
        </>
      )}
    </div>
  );
};

export default React.memo(BudgetEmbedded);
