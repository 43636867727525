// @flow
import { Record, OrderedMap } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';
import { DateTime } from 'luxon';

type AnchorOrigin = {
  horizontal: 'center' | 'left' | 'right',
  vertical: 'bottom' | 'top',
};

type NotificationType = {
  id: string,
  message: ?string,
  snackbarContent: ?any,
  snackbarContentProps: any,
  autoHideDuration: ?number,
  createdAt: string,
  anchorOrigin: ?AnchorOrigin,
  anchorIndex: ?number,
  height: ?number,
};
export type Notification = RecordOf<NotificationType>;
export const mkNotification: RecordFactory<NotificationType> = Record({
  id: undefined,
  message: undefined,
  snackbarContent: undefined,
  snackbarContentProps: undefined,
  autoHideDuration: undefined,
  createdAt: DateTime.local().toISO(),
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
  anchorIndex: undefined, // auto calculated prop
  height: 70,
});

type NotificationsStoreType = {
  notifications: ?OrderedMap<string, Notification>,
};
export type NotificationsStore = RecordOf<NotificationsStoreType>;
export const mkNotificationsStore: RecordFactory<NotificationsStoreType> = Record({
  notifications: OrderedMap(),
});
