import { List } from 'immutable';

import { accountsTypes } from 'companion-app-components/flux/accounts';

function transformPlaidAccountTypeToAccountType(plaidAccountType) {
  switch (plaidAccountType) {
    case 'credit':
      return 'CREDIT';
    case 'depository':
      return 'BANK';
    case 'investment':
      return 'INVESTMENT';
    case 'loan':
      return 'LOAN';
    case 'other':
    default:
      // TODO: Check with Plaid when this will occur (UNKNOWN)
      return 'UNKNOWN';
  }
}

function transformPlaidAccountSubTypeToAccountSubType(plaidAccountSubtype) {

  switch (plaidAccountSubtype) {

    // BANK Account Types
    case 'cd':
      return 'CD';
    case 'checking':
      return 'CHECKING';
    case 'hsa':                     // also under investment type
      return undefined;
    case 'money market':
      return 'MONEY_MARKET';
    case 'paypal':                  // also under credit type
      return undefined;
    case 'savings':
      return 'SAVINGS';
    case 'prepaid':
      return 'OTHER_BANKING';

    // CREDIT Account Types
    case 'credit card':
      return 'CREDIT_CARD';

    // INVESTMENT Account Types
    case '401a':
      return 'OTHER_INVESTMENTS';
    case '401k':
      return '401K';
    case '403B':
      return '403B';
    case '457b':
      return 'OTHER_INVESTMENTS';
    case '529':
      return '529';
    case 'brokerage':
      return 'BROKERAGE';
    case 'cash isa':
      return 'OTHER_INVESTMENTS';
    case 'education savings account':
      return 'OTHER_INVESTMENTS';
    case 'gic':
      return 'OTHER_INVESTMENTS';
    case 'health reimbursement arrangement':
      return 'OTHER_INVESTMENTS';
    case 'isa':
      return 'OTHER_INVESTMENTS';
    case 'ira':
      return 'IRA';
    case 'lif':
      return 'OTHER_INVESTMENTS';
    case 'lira':
      return 'OTHER_INVESTMENTS';
    case 'lrif':
      return 'OTHER_INVESTMENTS';
    case 'lrsp':
      return 'OTHER_INVESTMENTS';
    case 'non-taxable brokerage account':
      return 'OTHER_INVESTMENTS';
    case 'other':
      return 'OTHER_INVESTMENTS';
    case 'prif':
      return 'OTHER_INVESTMENTS';
    case 'rdsp':
      return 'OTHER_INVESTMENTS';
    case 'resp':
      return 'OTHER_INVESTMENTS';
    case 'rlif':
      return 'OTHER_INVESTMENTS';
    case 'rrif':
      return 'OTHER_INVESTMENTS';
    case 'pension':
      return 'OTHER_INVESTMENTS';
    case 'profit sharing plan':
      return 'OTHER_INVESTMENTS';
    case 'retirement':
      return 'OTHER_INVESTMENTS';
    case 'roth':
      return 'ROTH_IRA';
    case 'roth 401k':
      return 'ROTH_401K';
    case 'rrsp':
      return 'OTHER_INVESTMENTS';
    case 'sep ira':
      return 'SEP_IRA';
    case 'simple ira':
      return 'SIMPLE_IRA';
    case 'sipp':
      return 'OTHER_INVESTMENTS';
    case 'stock plan':
      return 'OTHER_INVESTMENTS';
    case 'thrift savings plan':
      return 'OTHER_INVESTMENTS';
    case 'tfsa':
      return 'OTHER_INVESTMENTS';
    case 'ugma':
    case 'utma':
      return 'UGMA_UTMA';
    case 'variable annuity':
      return 'OTHER_INVESTMENTS';

    // LOAN Account Types
    // MISSING: MILITARY_LOAN, SMB
    case 'auto':
      return 'AUTO_LOAN';
    case 'commercial':
      return 'undefined';
    case 'construction':
      return 'CONSTRUCTION_LOAN';
    case 'consumer':
      return 'CONSUMER_LOAN';
    case 'home':
      return 'undefined';
    case 'loan':
      return 'LOAN';
    case 'mortgage':
      return 'MORTGAGE';
    case 'overdraft':
      return 'undefined';
    case 'student':
      return 'STUDENT_LOAN';

    // In QCS, these are under the CREDIT type
    case 'home equity':                 // Is this really correct or is it different
    case 'line of credit':
      return 'LINE_OF_CREDIT';

    default:
      return undefined;
  }
}

export function transformPlaidAccountsToAccounts(channel, accounts) {
  return accounts.reduce((list, account) => {
    const type = transformPlaidAccountTypeToAccountType(account.type);
    const subType = transformPlaidAccountSubTypeToAccountSubType(account.subtype);

    const aggregatorParams = {
      cpId: account.id,
      channel,

      subType,

      accountName: account.name,
      accountNumberMasked: account.mask,
    };
    const aggregators = List([accountsTypes.mkAccountAggregator(aggregatorParams)]);

    list.push(accountsTypes.mkAccount(type, { aggregators }));
    return list;
  }, []);
}
