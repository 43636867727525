import * as React from 'react';
import { makeStyles } from 'tss-react/mui';

import IconButton from '@mui/material/IconButton';
import QCardIconOn from '@mui/icons-material/Chat';
import QCardIconOff from '@mui/icons-material/ChatBubbleOutline';

import QTip from 'components/QuickenControls/QTip';

const styles = ({ components }) => ({
  buttonRoot: {
    outline: 'none !important',
    fontSize: 'unset',
    padding: components.navigation.iconVerticalSpacing,
    color: components.navigation.iconColor,
    opacity: components.navigation.rowOpacity,
    justifyContent: 'unset',
  },
});

const useStyle = makeStyles()(styles as Record<string, any>);

interface QCardToggleButtonProps {
  size: number;
  qCardsOn: boolean;
  closeHeader: () => void;
  onClick: (qCardsOn: boolean) => void;
}

const QCardToggleButton: React.FC<QCardToggleButtonProps> = ({ size, qCardsOn, closeHeader, onClick }) => {

  const { classes } = useStyle();

  const QCardIcon = qCardsOn ? QCardIconOn : QCardIconOff;

  return (
    <QTip
      placement="right"
      title={`QCards are currently ${qCardsOn ? 'on' : 'off'}`}
    >
      <IconButton
        id={qCardsOn ? 'qCardsTrue' : 'qCardsFalse'}
        aria-label="QCard Toggle"
        classes={{
          root: classes.buttonRoot,
        }}
        onClick={(e) => {
          closeHeader();
          onClick(!qCardsOn);
          e.stopPropagation();
        }}
        size="large"
      >
        <QCardIcon style={{ fontSize: size }} />
      </IconButton>
    </QTip>
  );
};

export default QCardToggleButton;
