// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { getCurrentTheme } from 'themes/themesSelectors';

type Props = {
  children: React.Node,
}

const CombinedProviders = ({ children } : Props) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <StyledEngineProvider injectFirst>
      <EmotionThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <StyledComponentsThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              {children}
            </LocalizationProvider>
          </StyledComponentsThemeProvider>
        </ThemeProvider>
      </EmotionThemeProvider>
    </StyledEngineProvider>
  );

};

export default CombinedProviders;
