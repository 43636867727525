import { Theme } from '@mui/material/styles';

export const style = (theme: Theme) => ({
  iconColor: {
    color: theme.palette.text.primary,
  },
  standardItem: {
    paddingLeft: 16,
  },
  standardItemHoverContainer: {
    borderRadius: theme.shape.borderRadius * 1,
  },
  menuSubHead: {
    paddingBottom: 5,
    color: theme.palette.text.secondary,
  },
  listIcon: {
    marginLeft: 0,
    marginRight: 5,
    minWidth: 0,
  },
  listText: {
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body2.fontSize,
  },
  listTextRoot: {
    lineHeight: 'normal',
  },
  popOver: {
    padding: 20,
  },
  iconFiller: {
    width: 24,
    marginRight: 5,
  },
  parentList: {
    paddingBottom: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  divider: {
    marginBottom: 8,
  },
  subIndent: {
    marginLeft: 5,
  },
  menuItem: {
    zIndex: 2000,
    height: 'auto',
    width: 'auto',
    padding: theme.spacing[2],
  },
  paperStyling: {
    whiteSpace: 'nowrap',
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
  },
  dividerStyling: {
    borderBottom: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.slateGrey,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subMenu: {
    textAlign: 'right' as const,
    width: 50,
  },
});
