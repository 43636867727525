import PropTypes from 'prop-types';

export function convertToLegiblePropTypes(propTypes) {
  if (!propTypes) return null;
  const convertedPropTypes = {};
  Object.entries(propTypes).forEach(([key, val]) => {
    switch (val) {
      case PropTypes.func:
        convertedPropTypes[key] = 'function';
        break;
      case PropTypes.string:
        convertedPropTypes[key] = 'string';
        break;
      case PropTypes.object:
        convertedPropTypes[key] = 'object';
        break;
      case PropTypes.number:
        convertedPropTypes[key] = 'number';
        break;
      case PropTypes.bool:
        convertedPropTypes[key] = 'bool';
        break;
      default:
        convertedPropTypes[key] = 'unknown';
    }
  });
  return convertedPropTypes;
}

export function findItemInChildren(itemId, items) {
  let found;
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === itemId) {
      return items[i];
    }
    if (Array.isArray(items[i].children)) {
      found = findItemInChildren(itemId, items[i].children);
      if (found) {
        return found;
      }
    }
  }
  return found;
}

export function searchIncludesNode(searchText, node) {
  return node.name?.toLowerCase().includes(searchText?.toLowerCase())
    || (node.searchTags && node.searchTags.some((tag) => tag?.toLowerCase().includes(searchText?.toLowerCase())));
}

export function getParameterNames(func) {

  const strippedFunc = func.toString()
    .replace(/\/\*[\s\S]*?\*\//g, '')  // Remove comments of the form /* ... */
    .replace(/\/\/(.)*/g, '')  // Removing comments of the form //
    .replace(/{[\s\S]*}/, '')  // Remove body of the function { ... }
    .replace(/=>/g, '')  // removing '=>' if func is arrow function
    .trim();

  return strippedFunc.substring(strippedFunc.indexOf('(') + 1, strippedFunc.length - 1).split(', ')
    .map((param) => param.replace(/=[\s\S]*/g, '').trim())  // strip default args
    .filter((param) => param.length > 0);
}
