import React, { FC, useState, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Set, List } from 'immutable';

import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

import QCloseBox from 'components/QCloseBox';
import CategoryReviewCard from 'components/CategoryReviewCard';
import { chartOfAccountsTypes, chartOfAccountsUtils } from 'companion-app-components/flux/chart-of-accounts';

import { formatNumber } from 'components/QuickenControls/AmountField';
import { setAmountSign } from 'components/Budgets/EditBudgetsDialog/editBudgetHelpers';

import { TitleHeader, CategoryWrappedField, AmountWrappedField, ButtonArea } from './subComponents';

import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface BudgetAddCategoryProps {
  onAdd: (obj: { coa: chartOfAccountsTypes.ChartOfAccountProps | null, amount: number }) => void;
  onClose: () => void;
  accountIds: List<string> | null;
  currency: string;
  categoryIds: string[];
}

// @ts-expect-error: Only a void function can be called with the 'new' keyword.
const BudgetAddCategory: FC<BudgetAddCategoryProps> = ({ onAdd, onClose, accountIds, currency = new Set(), categoryIds }) => {

  const [selectedCategory, setSelectedCategory] = useState<chartOfAccountsTypes.ChartOfAccount | null>(null);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [monthlyAverage, setMonthlyAverage] = useState(0);

  const { classes } = useStyles();

  const internalOnChange = (e) => {
    if (Number(e.target.value) !== Number(currentAmount)) setCurrentAmount(Number(e.target.value));
  };
  const internalOnAdd = () => {
    onAdd({ coa: selectedCategory, amount: currentAmount });
  };
  const onCatChange = (coa) => {
    if (!chartOfAccountsUtils.coasAreEqual(coa, selectedCategory)) setSelectedCategory(chartOfAccountsTypes.mkChartOfAccount(coa));
  };
  const onSubmit = (e) => {
    if (selectedCategory) {
      onAdd({ coa: selectedCategory, amount: Number(e.target.value) });
      onClose();
    }
  };

  const duplicateCategory = useMemo(() => selectedCategory ? categoryIds.includes(selectedCategory.id) : false, [selectedCategory, categoryIds]);

  return (
    <Dialog
      maxWidth="md"
      open
      onClose={onClose}
      classes={{ paperWidthMd: classes.dialogRoot }}
    >
      <QCloseBox onClose={onClose} id="add-to-budget-close-button" />
      <TitleHeader
        classes={classes}
      />
      <Divider classes={{ root: classes.dividerRoot }} />

      <div className={classes.fieldsContainer}>
        <CategoryWrappedField
          catFieldProps={{
            value: selectedCategory,
            onChange: onCatChange,
            // filterFn: catFilterFn,
            numPopularCats: 10,
            InputProps: {
              placeholder: 'Select a Category',
            },
          }}
        />
        <AmountWrappedField
          currentAmount={currentAmount}
          classes={{ inputField: classes.amountInputStyle }}
          onBlur={(e) => internalOnChange(e)}
          onSubmit={onSubmit}
          currency={currency}
        />
      </div>

      <Divider classes={{ root: classes.dividerRoot }} />

      {selectedCategory &&
        <>
          <CategoryReviewCard
            coa={selectedCategory}
            accountIds={accountIds}
            numMonths="3_MONTHS_PRIOR"
            classes={{ transactionList: classes.categoryReviewTxList, root: classes.categoryReviewCardRoot }}
            onAverageCalc={setMonthlyAverage}
            currency={currency}
            onSetValueFromGraph={(amt) => setCurrentAmount(setAmountSign(Number(Number(amt).toFixed(0)), selectedCategory))}
          />
          <div
            className={classes.monthlyAverage}
          >
            <ButtonBase
              focusRipple
              onClick={() => setCurrentAmount(setAmountSign(Number(Number(monthlyAverage).toFixed(0)), selectedCategory))}
            >
              <Typography
                variant="h6"
              >
                {`Monthly Average: ${formatNumber(setAmountSign(monthlyAverage, selectedCategory), currency)}`}
              </Typography>
            </ButtonBase>
          </div>

        </>}

      <ButtonArea
        onAdd={internalOnAdd}
        onClose={onClose}
        disableSubmit={selectedCategory === null || duplicateCategory}
      />
    </Dialog>
  );
};

export default BudgetAddCategory;
