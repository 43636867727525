import * as React from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import FeedbackIcon from 'assets/nav-menu/feedback.inline.svg';

interface FeedbackButtonProps {
  classes: Record<string, any>;
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({ classes }) => (
  <Button
    className={classes.navMenuItem}
    id="nav-menu-feedback"
    onClick={() => window.Usersnap?.logEvent('web_feedback_button_click')}
  >
    <FeedbackIcon />
    <Typography
      textAlign="left"
      ml={24}
      className={classes.navText}
    >
      Feedback
    </Typography>
  </Button>
);

export default FeedbackButton;
