export const isSimplifi = process.env.PROJECT === 'acme';
export const isAcme = isSimplifi;
export const isQuicken = !isSimplifi;

let clientLong;
let clientShort;
/////////////////////////////////////
////////////////////////////////
/////////////////////////
/////////
/////////////////////////////////////
clientLong = 'Quicken Web App';
clientShort = 'Quicken';
/////////
export const clientType = clientLong;
export const client = clientShort;

export default isAcme;
