//  import theme from 'isAcme';

/*
 *
 * DEFAULT SETUPS FOR QUICKEN / COMPANION
 * DEFAULT SETUPS FOR QUICKEN / COMPANION
 * DEFAULT SETUPS FOR QUICKEN / COMPANION
 *
 */


export const requiredColumns = {
  default: [
    'amount',
    'category',
    'split',
    'postedOn',
    'payee',
    'select',
    'account',
    'accountColor',
    'menu',
  ],
};

export const availableColumns = {
  default: [
    'attachment',
    'balance',
    'check',
    'match',
    'notes',
    'state',
    'tags',
    'close',
    'save',
    'reviewed',
  ],
  credit: [
    'attachment',
    'balance',
    'match',
    'notes',
    'state',
    'tags',
    'close',
    'save',
    'reviewed',
  ],
};

export const fieldData = {
  accountColor: { label: 'Account', sameAs: 'account', editable: true, position: 0, moveable: false, exportable: false },
  account: { label: 'Account', sameAs: 'accountColor', editable: true, position: 10, moveable: false, exportable: true },
  close: { label: 'Close', editable: true, position: 86, moveable: false, exportable: false },
  select: { label: 'Select', editable: true, position: 5, moveable: false, exportable: false },
  reviewed: { label: 'Reviewed', editable: true, position: 15, moveable: true, exportable: false },
  match: { label: 'Entry Method', editable: false, position: 20, moveable: true, exportable: false },
  postedOn: { label: 'Date', editable: true, position: 25, moveable: true, exportable: true },
  check: { label: 'Check #', editable: true, position: 30, moveable: true, exportable: true },
  payee: { label: 'Payee', editable: true, position: 35, moveable: true, exportable: true },
  category: { label: 'Category', editable: true, position: 40, moveable: true, exportable: true },
  split: { label: 'Split', editable: true, position: 40, moveable: false, exportable: false },
  tags: { label: 'Tags', editable: true, position: 45, moveable: true, exportable: true },
  notes: { label: 'Notes', editable: true, position: 50, moveable: true, exportable: true },
  state: { label: 'Clr', editable: true, position: 55, moveable: true, exportable: true },
  attachment: { label: 'Attachment', editable: false, position: 60, moveable: true, exportable: false },
  expense: { label: 'Money Out', editable: true, position: 65, moveable: true, exportable: false },
  income: { label: 'Money In', editable: true, position: 70, moveable: true, exportable: false },
  amount: { label: 'Amount', editable: true, position: 75, moveable: true, exportable: true },
  balance: { label: 'Balance', editable: true, position: 80, moveable: true, exportable: false },
  save: { label: 'Save', editable: true, position: 85, moveable: false, exportable: false },
  menu: { label: 'Action Menu', editable: true, position: 90, moveable: false, exportable: false },
};

// This is the default columns shown, and default sort order
// This is used by QPreferences to initialize the state in preferences

export const columnDefaults = {
  default: [
    'accountColor', 'select', 'reviewed', 'account', 'postedOn', 'payee', 'category', 'split', 'state',
    'attachment', 'expense', 'income', 'amount', 'balance', 'save', 'close', 'menu'],
  credit: [
    'accountColor', 'select', 'reviewed', 'account', 'postedOn', 'payee', 'category', 'split', 'state',
    'attachment', 'expense', 'income', 'amount', 'balance', 'save', 'close', 'menu'],
};

export const clrFieldOptions = {
  styles: (theme) => ({
    '&.Uncleared': {
      borderColor: theme.palette.gray5,
    },
    '&.Cleared': {
      borderColor: theme.palette.positiveLight,
    },
    '&.Reconciled': {
      borderColor: theme.palette.gray2,
    },
    '&.No_Change': {
      borderColor: theme.palette.positiveLight,
      color: '#3e3e3c',
      background: '#f7f7f7',
    },
  }),
  options: [
    { label: 'Uncleared', state: 'PENDING', menu: true },
    { label: 'Cleared', state: 'CLEARED', menu: true },
    { label: 'Reconciled', state: 'RECONCILED', menu: true },
  ],
};

//
// This array describes which fields are editable for recurring transactions
//
export const recurringEditable = ['postedOn', 'select', 'amount', 'income', 'expense', 'save', 'close'];
