//
// SELECTORS, these are functions designed to work directly on and with the
// redux data store
//

import { createCachedSelector, LruObjectCache } from 're-reselect';
import { createSelector } from 'reselect';
import { DateTime } from 'luxon';

import { List as ImmutableList, OrderedMap, Map } from 'immutable';

import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { getLogger } from 'companion-app-components/utils/core';
import { accountsSelectors, accountsUtils } from 'companion-app-components/flux/accounts';
import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { transactionsUtils } from 'companion-app-components/flux/transactions';

import { noNaN } from 'utils/utils';
import { getOptimalCacheFromObject, setOptimalCacheFromObject } from 'data/optimalCacheManager';
import { memoizeWithKey, memoizeWithKeyRetrieveCache } from 'utils/memoizeWithKey';
import { getOrCreateStringFromObject } from 'utils/objectToKeyUtils';
import { formatNumber, ShowSignEnum } from 'components/QuickenControls/AmountField';

import {
  getTransactionsByAccountId, getTransactionsByAccountIdsSelectors, getTransactionsByAccountIdRaw,
  getTransactionsById, getTransactionsStore,
  txnTypesDefault, TxnTypes, getIsLoading as getTransactionsAreLoading, getLoadPending as getTransactionsLoadPending,
} from 'data/transactions/selectors';
import { getBalancesByAccountId, includeRecurringTxn } from 'data/accountBalances/selectors';
import {
  isUncategorizedTxn,
  isUnacceptedScheduledTxn,
  getTxnStateInfo,
  isRefundTxn,
  RefundState,
  getRefundState,
  isUnAcceptedBankPendingTxn,
  isBankOwnedPending,
  transactionsSortFunction,
} from 'data/transactions/utils';

import {
  optimalCacheProcessFn,
  optimalCacheCompareFn,
  optimalCacheCompareFnPostedOn,
  optimalCacheProcessFnTxnList,
  optimalCacheCompareFnPostedOnAndAmount,
} from 'data/transactionList/optimalCacheFunctions';
import * as preferencesSelectors from 'data/preferences/selectors';

import { getPayeesForAccounts } from 'data/payees/selectors';
import { PAST_DAYS_NUMBER, RANGE_INDICES } from 'components/ProjectedBalances/constants';

import { groupTxnListByDate, groupTxnListByAmount, groupTxnListByString } from './grouping';
import { calcRunningBalances, getFutureTxnsForAccountIds } from './utils';
import { sortTransactions } from './sorting';

const log = getLogger('data/transactionList/selectors');

//-----------------------------------------------
//-----------------------------------------------

// getTransactionListForAccountIds
//
// This selector expects the following properties:
//   - an array of accountIds
//   - sortBy property
//   - sortOrder property
//   - filter function that if defined is provided the transaction list to filter. null
//     functions as the identity filter (show all)
//
// Returns an immutable list of transactions from accounts specified by the
// accountIds array.


export const getTransactionListForAccountIds = createSelector(
  (state, props) => props.accountIds,
  (state, props) => props.sortBy || 'date',
  (state, props) => props.sortOrder || 'sortDescending',
  (state, props) => props.filterFn,
  (state, props) => props.overrideTxns,
  (state, props) => props.reminderSetting || 0,
  (state, props) => props.id,
  getTransactionsStore,
  accountsSelectors.getAccountsById,
  getTransactionsByAccountId,
  getTransactionsByAccountIdsSelectors,
  getBalancesByAccountId,
  featureFlagsSelectors.getFeatureFlags,
  preferencesSelectors.getRunningBalanceExcludeAccountIds,
  getTransactionsAreLoading,
  accountsSelectors.getIsLoading,

  (function combinerClosure() {

    return (
      (
        accountIds,
        sortBy,
        sortOrder,
        filterFn,
        overrideTxns,
        reminderSetting,
        id,
        transactionsStore,
        accountsById,
        transactionsByAccountIds,
        transactionsByAccountIdsSelectors,
        balancesById,
        featureFlags,
        runningBalanceExcludeAccountIds,
        transactionsAreLoading,
        accountsAreLoading,
      ) => {

        const cacheObject = {
          name: 'txList',
          accountIds,
          sortBy,
          sortOrder,
          filterFn,
          overrideTxns,
          reminderSetting,
          id,
          accountsById,
          transactionsByAccountIdsSelectors,
          featureFlags,
        };
        const strictCacheObject = {
          ...cacheObject,
          transactionsByAccountIds,
          balancesById,
        };

        if (!transactionsAreLoading && !accountsAreLoading) {
          // if we are forcing a cache break, then skip cache processing, and go straight to regneeration,
          const cachedValues = getOptimalCacheFromObject(
            transactionsStore,
            'txList',
            cacheObject,
            strictCacheObject,
            (oldTxn, newTxn) => optimalCacheCompareFn(oldTxn, newTxn, sortBy, filterFn),
            (cache, newTxn) => optimalCacheProcessFn(cache, newTxn, filterFn),
            10,
          );
          if (cachedValues) log.debug('===================== TXLIST CACHE HIT =======================');
          if (cachedValues) return cachedValues;
        }

        log.debug('===================== TXLIST CACHE MISS =======================');

        let accountIdsList = accountIds;
        if (!accountIds || accountIds.length === 0) {
          accountIdsList = accountsById.map((x) => x.id).toList();
        }

        let transactionsForAccountIds;
        if (overrideTxns) {
          transactionsForAccountIds = overrideTxns;
        } else {
          transactionsForAccountIds = ImmutableList();

          accountIdsList.forEach((accountId) => {

            const acct = accountsById.get(accountId);

            const hideTxns = accountsUtils.transactionsNotSupportedForAccountId(
              accountId,
              featureFlags.get('hideLoanTransactions'),
              featureFlags.get('hideConnectedLoanTransactions'),
            );

            if (acct && !hideTxns) {
              const transactionsForAccountIdSelector =
                transactionsByAccountIdsSelectors.get(accountId);

              const transactionsForAccountId =
                transactionsForAccountIdSelector(transactionsByAccountIds);

              // Filter out reminder transactions as appropriate
              let filteredTransactions = transactionsForAccountId;
              if (!featureFlags.get('scheduledTransactionsInRegister')) {
                filteredTransactions = transactionsForAccountId.filter((txn) => !isUnacceptedScheduledTxn(txn));
              } else {
                // we use the reminderSetting to determine which unaccepted scheduled transactions we show

                let acctReminderSetting = reminderSetting;

                if (acctReminderSetting === 'account' && acct) {
                  acctReminderSetting = accountsUtils.getReminderSettingInDays(acct.recurringTxn);
                }

                filteredTransactions =
                  filteredTransactions.filter((txn) =>
                    (!isUnacceptedScheduledTxn(txn) || includeRecurringTxn(txn, acctReminderSetting, transactionsForAccountId)) && !isUnAcceptedBankPendingTxn(acct, txn));
              }
              transactionsForAccountIds = transactionsForAccountIds.concat(filteredTransactions);
            }

          });
        }

        let filteredTxns = filterFn ? filterFn(transactionsForAccountIds) : transactionsForAccountIds;
        if (sortBy === 'reviewed') {
          filteredTxns = filteredTxns.filter((txn) => sortOrder === 'ascending' ? !txn.isReviewed : txn.isReviewed);
        }

        // if the app is using the upcomingSection, then filter out any future transactions
        // and DUE/OVERDUE scheduled transactions
        // but do not do this if caller sent in their own txn list (override txns)
        if (featureFlags.get('upcomingSection') && !overrideTxns) {
          filteredTxns = filteredTxns.filter((txn) =>
            DateTime.fromISO(txn.postedOn) <= DateTime.now() && (!isUnacceptedScheduledTxn(txn)));
        }

        const sortedTxns = sortTransactions(filteredTxns, sortBy, sortOrder, featureFlags);

        let txnsWithBalances = sortedTxns;

        if ((balancesById.size > 0 && accountIdsList.size === 1 && Number(accountIdsList.first()) !== 0)) {
          const balObj = balancesById.get(accountIdsList.first());
          const endingBalance = balObj ? balObj.endingBalance : 0;
          const isExcludePendingTxn = runningBalanceExcludeAccountIds?.includes(accountIdsList.first());
          // const { endingBalance } = balancesById.get(accountIdsList.first());
          txnsWithBalances = calcRunningBalances(sortedTxns, sortBy, sortOrder, endingBalance, isExcludePendingTxn);
        }

        // log.log(txnsWithBalances);

        let groupedTxns;
        switch (sortBy) {

          case 'date':
            groupedTxns = groupTxnListByDate(txnsWithBalances, featureFlags, true);
            groupedTxns = groupedTxns.sortBy(
              (x, k) => k,
              (a, b) => {
                if (a === b) return 0;
                return (a < b) ? -1 : 1;
              },
            );
            groupedTxns = (sortOrder === 'descending') ? groupedTxns.reverse() : groupedTxns;
            break;

          case 'category':
          case 'payee':
          case 'check':
            groupedTxns = groupTxnListByString(txnsWithBalances, sortBy);
            break;

          case 'reviewed':
            groupedTxns = groupTxnListByDate(txnsWithBalances, featureFlags, true, sortOrder);
            break;

          case 'status':
            groupedTxns = groupTxnListByString(txnsWithBalances, 'state');
            break;

          case 'amount':
            groupedTxns = groupTxnListByAmount(txnsWithBalances);
            break;

          default:
            groupedTxns = groupTxnListByString(txnsWithBalances, 'payee');
        }

        if (!transactionsAreLoading && !accountsAreLoading) {
          setOptimalCacheFromObject(transactionsStore, 'txList', cacheObject, strictCacheObject, groupedTxns, 10);
        }
        return groupedTxns;
      });
  }()),
);


/*
 * GetTransactionsWithCategoyrSuggestions
 *
 * Measured with a reasonably large dataset, this takes 1 to 2ms
 */
export const getTransactionsWithCategorySuggestions = createCachedSelector(
  (state, props) => props.accountIds,
  getTransactionsByAccountId,
  getPayeesForAccounts,
  accountsSelectors.getAccountsById,
  getTransactionsByAccountIdsSelectors,
  (
    accountIds,
    transactionsByAccountIds,
    payeeList,
    accountsById,
    transactionsByAccountIdsSelectors,
  ) => {

    const pt = DateTime.now().toMillis();
    let accountIdsList = accountIds;
    if (!accountIds || accountIds.length === 0) {
      accountIdsList = accountsById.map((x) => x.id).toList();
    }

    let transactionsForAccountIds;
    transactionsForAccountIds = ImmutableList();
    accountIdsList.forEach((accountId) => {

      const acct = accountsById.get(accountId);

      if (acct && acct.type !== 'LOAN') {
        const transactionsForAccountIdSelector =
          transactionsByAccountIdsSelectors.get(accountId);

        const transactionsForAccountId =
          transactionsForAccountIdSelector(transactionsByAccountIds);

        transactionsForAccountIds = transactionsForAccountIds.concat(transactionsForAccountId);
      }
    });

    const ret = transactionsForAccountIds.filter((txn) => isUncategorizedTxn(txn) && payeeList.has((txn.payee || '')?.toLowerCase()));

    log.debug(`RETURNING CAT SUGGESTIONS, total time ${DateTime.now().toMillis() - pt}`);
    return ret;
  },
)({
  keySelector: (_, props) => `${props.accountIds}`,
  cacheObject: new LruObjectCache({ cacheSize: 3 }),
});

/*
 * GetRecentTransactions
 */

export const getRecentTransactions = createSelector(
  (state) => accountsSelectors.getLoadPending(state) || categoriesSelectors.getLoadPending(state) || getTransactionsLoadPending(state),
  accountsSelectors.getAccountsById,
  getTransactionsById,
  categoriesSelectors.getCategoriesById,
  getTransactionsStore,
  accountsSelectors.getPendingTxnExcludedAccountIds,
  (isLoadPending, accountByIds, txnList, categoriesById, transactionsStore, pendingTxnExcludedAccountIds) => {
    if (isLoadPending) {
      return ImmutableList([]);
    }

    const pt = DateTime.now().toMillis();

    const cacheObject = {
      categoriesById,
      pendingTxnExcludedAccountIds,
    };
    const strictCacheObject = {
      ...cacheObject,
      txnList,
    };

    const cachedValues = getOptimalCacheFromObject(
      transactionsStore,
      'recentTransactions',
      cacheObject,
      strictCacheObject,
      optimalCacheCompareFnPostedOn, // uses same comparator as Proj Txn
      optimalCacheProcessFnTxnList,  // uses same "grouped" process function as getTransactionList
      2,
    );
    if (cachedValues) {
      return cachedValues;
    }
    // ======== FOR RECENT TX. CARD (SIMPLIFI) =============

    const filterByDate = false;
    let returnObject = null;
    const [...idsToUse] = accountByIds.filter((account) => !account.isExcludedFromReports)?.keys() || [];
    let txns = txnList.filter((txn) => {
      const startDate = DateTime.now().minus({ months: 2 });
      const endDate = DateTime.now();
      const txnDate = DateTime.fromISO(txn.postedOn);
      const isValid = transactionsUtils.isSplitTxn(txn)
        ? !(txn.source && txn.source === 'SCHEDULED_TRANSACTION_PENDING') && idsToUse.includes(txn.accountId)
        : txn && !(txn.source && txn.source === 'SCHEDULED_TRANSACTION_PENDING') 
          && txn.coa && txn.coa.type && txn.coa.type !== 'BALANCE_ADJUSTMENT' 
          && (!transactionsUtils.isTransferTxn(txn) || (Number(txn.amount) < 0))
          && !(isBankOwnedPending(txn) && pendingTxnExcludedAccountIds.includes(txn.accountId))
          && idsToUse.includes(txn.accountId);
      const beforeToday = txnDate < endDate;
      return isValid && (filterByDate ? beforeToday && txnDate > startDate : beforeToday);
    });
    txns = txns.sort((a, b) => transactionsSortFunction(b, a));
    returnObject = txns.toList().slice(0, 5);
    log.debug(`RETURNING RECENT TXNS, total time ${DateTime.now().toMillis() - pt}`);
    setOptimalCacheFromObject(transactionsStore, 'recentTransactions', cacheObject, strictCacheObject, returnObject, 2);
    return returnObject;
  },
);

export const transactionListMetrics = createCachedSelector(
  (state, transactionList) => transactionList,
  (txnList) => {
    let numTxns = 0;
    let sumTxns = 0;
    let earliestDate = DateTime.now();
    let reviewedCount = 0;
    let hasScheduledInstances = false;

    txnList.forEach((section, _) => {
      numTxns += section.size;
      section.forEach((txn) => {
        sumTxns += Number(txn.amount);
        const postedDate = DateTime.fromISO(txn.postedOn);
        if (postedDate < earliestDate) {
          earliestDate = postedDate;
        }
        reviewedCount += (txn.isReviewed ? 1 : 0);
        hasScheduledInstances = hasScheduledInstances || isUnacceptedScheduledTxn(txn);
      });
    });
    return { numTxns, sumTxns, earliestDate, reviewedCount, hasScheduledInstances };
  },
)({
  keySelector: (_, props) => props.accountIds ? props.accountIds.toString() : 'all',
  cacheObject: new LruObjectCache({ cacheSize: 10 }),
});

// ## ================================================================================================ ##
//
// ##                              Chris Selectors   (OH NO)  RUN TO THE HILLS!!!! RUN FOR YOUR LIFE!! ##  (Iron Maiden) \,,/
//
// ## ================================================================================================ ##

// let hackGlobal;

export const getProjectedTxnList = createSelector(
  (state, props) => props.accountIds,
  (state, props) => props.overrideTxns,
  (state, props) => props.maxTxns,
  (state, props) => props.endDate,
  accountsSelectors.getAccountsById,
  (state, props) => getTransactionsByAccountId(state, { txnTypes: props.txnTypes || (txnTypesDefault | TxnTypes.REFUND_VIRTUAL_TXN) }),
  getTransactionsByAccountIdsSelectors,
  featureFlagsSelectors.getFeatureFlags,
  getTransactionsStore,

  (function combinerClosure() {
    return (
      (
        accountIds,
        overrideTxns,
        maxTxns,
        endDate,
        accountsById,
        transactionsByAccountId,
        transactionsByAccountIdsSelectors,
        featureFlags,
        transactionsStore,
      ) => {

        const cacheObject = {
          name: 'projTxnList',
          accountIds,
          overrideTxns,
          accountsById,
          transactionsByAccountIdsSelectors,
          featureFlags,
          endDate,
        };
        const strictCacheObject = {
          ...cacheObject,
          transactionsByAccountId,
        };

        // console.log("GETTING CACHE OBJECT GET PROJECTED TXN LIST", cacheObject, strictCacheObject);
        // console.log("hash code for transactions ", transactionsByAccountId?.hashCode());

        const cachedValues = getOptimalCacheFromObject(
          transactionsStore,
          'projTxnList',
          cacheObject,
          strictCacheObject,
          optimalCacheCompareFnPostedOn,
          optimalCacheProcessFnTxnList,
          10,
        );

        // console.log("CACHED VALUES ARE ", cachedValues);
        // console.log("RETURNING CACHE ", cachedValues?.hashCode(), hackGlobal?.hashCode(), cachedValues === hackGlobal);
        if (cachedValues) {
          if (maxTxns && cachedValues.size > maxTxns) {
            return cachedValues.slice(0, maxTxns);
          }
          return cachedValues;
        }

        const sortBy = 'date';
        const sortOrder = 'ascending';

        let accountIdsList = accountIds;
        if (!accountIds || accountIds.length === 0) {
          accountIdsList = accountsById.map((x) => x.id).toList();
        }

        // TODO: memoize the getFutureTxns call
        const transactionsForAccountIds = overrideTxns || getFutureTxnsForAccountIds({
          accountIdsList,
          accountsById,
          transactionsByAccountId,
          transactionsByAccountIdsSelectors,
          featureFlags,
        });

        let filteredTxnsForAccountIds = transactionsForAccountIds;
        if (endDate && transactionsForAccountIds.size > 0) {
          filteredTxnsForAccountIds = transactionsForAccountIds.filter((txn) =>
            DateTime.fromISO(txn.postedOn).diff(DateTime.fromISO(endDate), 'days').toObject().days < 0);
        }

        let upcomingTxns = sortTransactions(filteredTxnsForAccountIds, sortBy, sortOrder);
        setOptimalCacheFromObject(transactionsStore, 'projTxnList', cacheObject, strictCacheObject, upcomingTxns, 10);

        if (maxTxns && upcomingTxns.size > maxTxns) {
          upcomingTxns = upcomingTxns.slice(0, maxTxns);
        }
        return upcomingTxns;

      }
    );
  }()),
);

/** *****************************************************************************************
 * HELPER FUNCTIONS
 ******************************************************************************************** */
// This selector is basically used for cache (could use memoize instead)
export const futureFilteredTxns = (txns, date, schTxns) =>
  txns.filter((txn) => {
    const txnDate = DateTime.fromISO(txn.postedOn);
    if (schTxns) {
      return (
        (txn.source === 'USER_ENTERED' &&
          txnDate.diff(DateTime.local(), 'days').toObject().days >= 0) || txn.source === 'SCHEDULED_TRANSACTION_PENDING'
      );
    }
    return (
      txn.source === 'USER_ENTERED' ||
      txn.source === 'SCHEDULED_TRANSACTION_PENDING' // adding this so we can have the 'waiting' button
    );
  });

export const getDateGroupedTransactions = createSelector(
  (txns) => txns,
  (txns, date) => date,
  (txns, date, schTxns) => schTxns,
  (function combinerClosure() {
    return (
      (
        txns,
        date,
        schTxns,
      ) => {
        const key = getOrCreateStringFromObject(
          'dateGroupedTxns',
          { txns, date, schTxns },
          5,
          memoizeWithKeyRetrieveCache,   // fn used for garbage collection for unusued object strings
        );
        return memoizeWithKey(
          'dateGroupedTxns',
          () => futureFilteredTxns(txns, date, schTxns)?.groupBy((txn) => DateTime.fromISO(txn.postedOn).toISODate()),
          key,
          5,
        );
      });
  }()),
);

const getDateMap = createSelector(
  (previousDays) => previousDays,
  (_, days) => days,
  (previousDays, days) => {
    const startDate = DateTime.local().minus({ days: previousDays });
    let dateMap = OrderedMap();
    let currentDate = startDate;
    let currentIndex = 0;
    while (currentIndex < days) {
      dateMap = dateMap.set(currentDate.toISODate(), currentIndex);
      currentDate = currentDate.plus({ days: 1 });
      currentIndex += 1;
    }
    dateMap = dateMap.set(currentDate.toISODate(), currentIndex);

    return dateMap;
  },
);
export const getGroupedRecentAndFutureAccountTransactions = createSelector(
  getTransactionsByAccountIdRaw,
  getTransactionsStore,
  (state, props) => props.dateRange,
  (txnsByAccountId, transactionsStore, dateRange) => {
    const endDate = DateTime.local().startOf('day').plus({ days: dateRange });
    const pastCutoff = DateTime.local().startOf('day').minus({ days: 10 });

    const cacheKey = getOrCreateStringFromObject(
      'recent-grouped-txns',
      {
        name: 'recentAcctTxns',
        pastCutoff: pastCutoff.ts,
        txnsByAccountId,
      },
      5,
      memoizeWithKeyRetrieveCache,   // fn used for garbage collection for unusued object strings
    );

    return memoizeWithKey(
      'recent-grouped-txns',
      () => {
        const todayTxnArr = []; // for due or overdue txns
        const groupedTxns = Map().withMutations((accountMapSetter) => {
          txnsByAccountId.forEach((account, key) => {
            const ungroupedTxns = account.filter((txn) => {
              if (txn.id === txn.clientId) { // filter out optimistically created manual transactions because they crash AMcharts
                return false;
              }
              const refundState = getRefundState(txn);
              if (refundState === RefundState.EXPECTED || refundState === RefundState.OVERDUE) {
                return true;
              }
              const txnDate = txn.postedOn && DateTime.fromISO(txn.postedOn);
              const todayDate = DateTime.local().startOf('day');
              const info = getTxnStateInfo(txn);
              if (info.status === 'DUE' || info.status === 'OVERDUE') {
                if (txnDate < todayDate) {
                  todayTxnArr.push(txn);
                }
                return true;
              }
              return (txnDate && txnDate < endDate && txnDate > pastCutoff);
            });
            const ret = ungroupedTxns?.groupBy((txn) => DateTime.fromISO(txn.postedOn).toISODate());
            accountMapSetter.set(key, ret);
          });
        });
        return ({
          todayTxnArr,
          groupedTxns,
        });
      },
      cacheKey,
      5,
    );

    // log.debug("REGENERATING RECENT ACCOUNT TRANSACTIONS", accountId);

    // Because of the way that the projected balances graph is situated, we want separation and grouping of transactions by account
  },
);

export const getAMChartData = createSelector(
  (state, props) => getDateMap(PAST_DAYS_NUMBER, props.dateRange + PAST_DAYS_NUMBER),
  (state, props) => props.dateRange,
  (state, props) => getGroupedRecentAndFutureAccountTransactions(state, { dateRange: props.dateRange }),
  getBalancesByAccountId,
  accountsSelectors.getAccountsById,
  getTransactionsById,
  getTransactionsStore,
  (
    dateMap,
    dateRange,
    groupedTxnObj,
    balancesById,
    accountsById,
    transactionsById,
    transactionsStore,
  ) => {

    // So we need to "recalc" if the amount or date changed, as this will change the actual values inside
    // of chartData, or could impact the sort order (and only way getRecent might return something different)
    // since we only return "ids", we don't have to process changes from optimal cache manager, but we do
    // use it for comparing all changes

    const cacheObject = {
      dateMap,
      dateRange,
      // accountIds,
      balancesById,
      accountsById,
    };

    // represents the resource I can optimize on
    const strictCacheObject = {
      ...cacheObject,
      transactionsById,
    };

    const cachedValues = getOptimalCacheFromObject(
      transactionsStore,
      'amChartData',
      cacheObject,
      strictCacheObject,
      optimalCacheCompareFnPostedOnAndAmount, // uses same comparator as Proj Txn
      (workingCache) => workingCache,  // we don't bother altering the workingCache
      10,
    );
    if (cachedValues) {
      log.debug('RETURNING CACHED VALUES for AmGraph');
      return cachedValues;
    }

    let index = 0;
    const groupedTxns = groupedTxnObj?.groupedTxns;
    const todayTxnArr = groupedTxnObj?.todayTxnArr; // due or overdue instances (they get added to today bullet)
    const accountsMap = Map().withMutations((accountMap) => {
      accountsById.forEach((account, accountId) => {
        let min = 0;
        const minArr = [];
        let max = 0;
        const maxArr = [];
        if (account.type === 'INVESTMENT') {
          return;
        }
        const credit = account.type === 'CREDIT' ? -1 : 1;
        const chartData = [];
        let currentBalance = 0;
        let todayBalance = 0;
        const balObj = balancesById?.get(accountId);
        currentBalance = balObj ? balObj.currentBalance : 0;
        todayBalance = balObj ? balObj.currentBalance : 0;

        const pastArr = [];
        let prevKey;
        let dateCount = 0;
        dateMap.forEach((value, key) => {
          const txnGroup = groupedTxns.get(accountId)?.get(key);
          let noIncome = true;
          let noRefund = true;
          const isToday = key === DateTime.local().startOf('day').toISODate();
          const isPast = key < DateTime.local().startOf('day').toISODate();

          if (isPast) {
            // add key to array
            pastArr.unshift(key);
          } else {
            if (isToday) {
              // if we don't do it like this, the balances would be incorrect. You need to backtrack from today.
              pastArr.forEach((dateVal) => {
                // pastArr contains only past dates, as we are using current balance, so to get the balance on past date we need to 
                // subtract the amount of the txns posted from Today. So adding a day to include these txns
                const dateToBackTrack = DateTime.fromISO(dateVal).plus({ day: 1 }).toFormat('yyyy-MM-dd');
                const group = groupedTxns.get(accountId)?.get(dateToBackTrack);
                if (group) {
                  group.forEach((txn) => {
                    // minus instead of plus because we are going backwards
                    if (txn.source !== 'SCHEDULED_TRANSACTION_PENDING') {
                      todayBalance -= noNaN(txn.amount);
                    }
                  });
                }
                if (min > todayBalance * credit) {
                  min = todayBalance * credit;
                }
                if (max < todayBalance * credit) {
                  max = todayBalance * credit;
                }
                chartData.push({
                  balance: (todayBalance * credit).toFixed(2),
                  date: dateVal,
                  txns: null,
                  disabled: true,
                  noIncome: true,
                  noRefund: true,
                });
              });
              chartData.reverse();
            }
            // this is so we have flat sections. Basically, we don't want to add anything to the chart data array
            // unless there is a txn or if there is a previous blank piece of data next to a group with a transaction in order to keep the line flat
            if (prevKey && txnGroup && !groupedTxns.get(accountId)?.get(prevKey)) {
              chartData.push(
                {
                  balance: (currentBalance * credit).toFixed(2),
                  date: prevKey,
                  txns: null,
                  disabled: true,
                  noIncome: true,
                  noRefund: true,
                },
              );
            }
            if (txnGroup || isToday) {
              const txnArr = [];
              let showBulletForToday = false;
              if (isToday) {
                todayTxnArr.forEach((txn) => {
                  if (txn.accountId === accountId) {
                    currentBalance += noNaN(txn.amount);
                    txnArr.push(txn);
                    showBulletForToday = true; // show today bullet if there are due/overdue instances
                  }
                });
              }
              txnGroup?.forEach((txn) => {
                if (!isToday || txn.source === 'SCHEDULED_TRANSACTION_PENDING') {
                  currentBalance += noNaN(txn.amount);
                  txnArr.push(txn);
                  if (isToday) {
                    showBulletForToday = true; // show today bullet if there are instances for today
                  }
                }

                if (categoriesSelectors.isIncomeCat(null, txn.coa?.id) && txn.source === 'SCHEDULED_TRANSACTION_PENDING') {
                  noIncome = false;
                }
                if (isRefundTxn(txn) && txn.source === 'SCHEDULED_TRANSACTION_PENDING') {
                  noRefund = false;
                }
              });
              if (isToday && !showBulletForToday) {
                noIncome = true;
                noRefund = true;
              }
              let txnHTML = '';
              txnArr.forEach((x) => {
                txnHTML += `<div style="display: flex; justify-content: space-between; width: 100%; max-width: 200px;">
                              <span style="font-size: 12px;height: 15px;font-weight: 400;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize; ">${x.payee}</span>
                              <span style="font-size: 12px;height: 15px;font-weight: 400;">${formatNumber(parseFloat(x.amount), 'USD', '0,0.00', ShowSignEnum.POSITIVE_ONLY)}</span>
                            </div>`;
              });

              chartData.push(
                {
                  balance: (currentBalance * credit).toFixed(2),
                  date: key,
                  txns: txnHTML,
                  disabled: (isToday && !showBulletForToday) || !noIncome,
                  noIncome,
                  noRefund,
                  firstTxnId: txnArr[0]?.id || '-1',
                },
              );
            }
            if (RANGE_INDICES.indexOf(dateCount) !== -1) {
              if (!txnGroup) {
                chartData.push(
                  {
                    balance: (currentBalance * credit).toFixed(2),
                    date: key,
                    txns: null,
                    disabled: true,
                    noIncome: true,
                    noRefund: true,
                    firstTxnId: '-1',
                  },
                );
              }
              minArr.push(min > 0 ? 0 : min);
              maxArr.push(max < 0 ? 0 : max);
            }
            if (min > (currentBalance * credit)) {
              min = currentBalance * credit;
            }
            if (max < (currentBalance * credit)) {
              max = currentBalance * credit;
            }
          }
          prevKey = key;
          dateCount += 1;
        });

        if (min > 0) {
          min = 0;
        }
        if (max < 0) {
          max = 0;
        }
        accountMap.set(accountId, {
          chartData,
          credit: (credit === -1),
          min,
          max,
          name: account.name,
          index,
          minArr,
          maxArr,
        });
        index += 1;
      });
    });

    // console.log(`getAmData took ${performance.now() - start}ms to run`);
    setOptimalCacheFromObject(transactionsStore, 'amChartData', cacheObject, strictCacheObject, accountsMap, 10);
    return accountsMap;
  },
);

export const processCalendarTxns = createSelector(
  (txns) => txns,
  (txns) => txns.groupBy(() => '9999-99:Pending'),
);
