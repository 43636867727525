// @flow
import { List, Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

type AlertDialogPropsProps = {
  title: string,
  content: string,
  onClose: ?() => void,
  buttons: List<string>,
  paperStyle: ?Object,
  rootStyle: string,
  titleIcon: ?Object,
  showCloseButton: boolean,
  tertiaryButton: string,
}
export type AlertDialogProps = RecordOf<AlertDialogPropsProps>;
export const mkAlertDialogProps: RecordFactory<AlertDialogPropsProps> =
  Record({
    title: 'Alert',
    content: '',
    onClose: null,
    buttons: List(['Yes', 'No']),
    paperStyle: null,
    rootStyle: null,
    titleIcon: null,
    showCloseButton: false,
    tertiaryButton: '',
  });

export const DIALOG_TYPE = 'DIALOG_ALERT';

