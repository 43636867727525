import { createCachedSelector, LruObjectCache } from 're-reselect';

import { INVESTMENT_SYMBOLS_KEY } from './investmentSymbolsTypes';

export const getResourceStore = (state) => state[INVESTMENT_SYMBOLS_KEY];
export const getLoadPending = (state) => getResourceStore(state).loadPending;
export const getIsLoading = (state) => getResourceStore(state).isLoading;
export const getInvestmentSymbols = (state) => getResourceStore(state).resourcesById;

export const getInvestmentSymbolsFiltered = createCachedSelector(
  getInvestmentSymbols,
  (state, filter) => filter?.toUpperCase(),
  (investmentSymbols, filter) => investmentSymbols
    .filter((investmentSymbol) => filter && (investmentSymbol.symbol.includes(filter)
      || investmentSymbol.name.toUpperCase().includes(filter)))
    .toList()
    .sort((investmentSymbol1, investmentSymbol2) =>
      (investmentSymbol1.symbol.startsWith(filter) ? 0 : 1) - (investmentSymbol2.symbol.startsWith(filter) ? 0 : 1)
      || (investmentSymbol1.symbol.includes(filter) ? 0 : 1) - ((investmentSymbol2.symbol.includes(filter) ? 0 : 1))
      || investmentSymbol1.symbol.localeCompare(investmentSymbol2.symbol)),
)({
  keySelector: (_, filter) => filter,
  cacheObject: new LruObjectCache({ cacheSize: 10 }),
});
