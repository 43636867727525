import numeral from 'numeral';
import { DateTime } from 'luxon';

import { tagsSelectors } from 'companion-app-components/flux/tags';
import { chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';
import { transactionsUtils } from 'companion-app-components/flux/transactions';

import store from 'store';

import { getAccountString, getCurrencyString } from 'data/accounts/retrievers';
import { formatNumber } from 'components/QuickenControls/AmountField';

import isAcme from 'isAcme';

import { exists } from 'utils/utils';


/**
 * SMART SEARCH tricks
 *
 *
 * If the string typed in evaluates to a number (Not NaN), then we treat it as a number.
 *
 * If the user starts expression with a '-' or a '+' it restricts the search to positive or negative values only
 * If the user types an & sign before the number, we look for an amount in the range of the 2 following comma separated values(Not comma separated anymore, now separated by ".to.")
 * If the user types a >, we look for all income and expenses whose absolute value is greater than the amount given
 * If the user types a <, we look for all income and expenses whose absolute value is less than the amount given
 * If the user types a ~ sign before the expression, or adds 'ish' to the end of it, we look for values relatively close to the value
 *
 * If the filter typed in is a valid DATE, then DATE SEARCH is used
 * >date is all dates after this date
 * <date is all dates before this date
 * ~ at the beginning, or 'ish' added to the end will search 15 days on either side of the date
 * &date,date will search for dates between the 2 given dates
 *
 * For TEXT searches, we remove all punctuation characters (from both the search string, and the string bases search targets
 * UNLESS the user types a '=' as the first character, then an exact string match is considered, including case
 * ALL text searching is case insensitive
 *
 * You may combine expressions using the boolean 'AND' operator expressed as .and.
 *   "+>02/23/2020 .and. Amazon"
 *
 * If the filter expression (can be between .AND. and .OR.) is 'reviewed' or 'notreviewed' then it will filter based on reviewed status
 *
 */

const searchDateFormats = [
  'M/d/yyyy',
  'MMMd,yyyy',
  'MMMMd,yyyy',
  'M/yyyy',
  'MMM,yyyy',
  'MMMMyyyy',
  'MMMyyyy',
];

const cleanString = (str) => str.split(' ').join('');

function isValidDate(dateString) {
  return searchDateFormats.some((fmt) =>
    DateTime.fromFormat(cleanString(dateString), fmt).isValid);
}

function convertFilterToDateRange(dateString) {

  let val1 = DateTime.local();
  let val2 = val1;

  searchDateFormats.some((fmt) => {
    const dt = DateTime.fromFormat(cleanString(dateString), fmt);
    if (dt.isValid) {
      val1 = dt;
      if (fmt.indexOf('d') !== -1) {
        val2 = dt;
      } else {
        val2 = dt.plus({ months: 1 });
      }
    }
    return false;
  });
  return { val1, val2 };
}

export function filterTransactionsByExpression(txns, _filter) {

  if (_filter && _filter.length > 0) {

    const expressions = _filter.split('.AND.');

    // if multiple expressions, successively filter them
    if (expressions.length > 1) {
      let results = txns;
      expressions.forEach((phrase) => {
        results = filterTransactionsByExpression(results, phrase.trim());
      });
      return results;
    }

    let filter = _filter;
    let fuzzy = false;

    // Is this a fuzzy search?
    if (filter.slice(-3) === 'ish') {
      filter = filter.slice(0, -3);
      fuzzy = true;
    }
    // Does user want a fuzzy search
    if (filter[0] === '~') {
      fuzzy = true;
      filter = filter.slice(1);
    }


    let amountSearch = false;
    let dateSearch = false;
    let val1 = null;
    let val2 = null;

    // Does user want to restrict values to only income or expense?
    const negOnly = filter[0] === '-';
    const posOnly = filter[0] === '+';
    const startChar = (negOnly || posOnly) ? 1 : 0;
    let prefix = filter[startChar];

    // Does user want a less than/greater than/ or range search?
    if (prefix === '>' || prefix === '<' || prefix === '&') {
      const values = filter.slice(startChar + 1).split('.to.');
      if (!Number.isNaN(Number(values[0]))) {
        val1 = Number(values[0]);
        val2 = values.length > 1 ? Number(values[1]) : 0;
        amountSearch = !Number.isNaN(val1) && !Number.isNaN(val2);
      } else if (isValidDate(values[0])) {  // date?
        val1 = convertFilterToDateRange(values[0]).val1;
        val2 = values.length > 1 ? convertFilterToDateRange(values[1]).val2 : DateTime.local();
        dateSearch = val1.isValid && val2.isValid;
      }
      // No PREFIX, so if text is a date or a number, apply a special search to them
    } else if (!Number.isNaN(Number(filter))) {
      amountSearch = true;
      if (fuzzy) {
        const filterAsAmount = Number(filter);
        const variancePct = 0.02;
        // construct a range search
        val2 = Number((Number(filterAsAmount) * (1 + variancePct)).toFixed(2));
        val1 = Number((Number(filterAsAmount) * (1 - variancePct)).toFixed(2));
        prefix = '&';
      }
    } else if (isValidDate(filter)) {
      dateSearch = true;
      const dateSpread = fuzzy ? 5 : 0;
      const dateRange = convertFilterToDateRange(filter);
      val1 = dateRange.val1.minus({ days: dateSpread });
      val2 = dateRange.val2.plus({ days: dateSpread });
      prefix = '&';
    }

    // First, remove apostophes from the search
    let filterClean = filter.replace("'", '');
    filterClean = filterClean.replace('`', '');
    // Convert special characters to spaces for the search
    filterClean = filterClean.replace(/([!"#%&'*()+,\-./:;<=>?\][@\\^_])/g, ' ');

    // console.log("SEARCH WITH ", filter, val1, val2, dateSearch, amountSearch);

    const baseValue = Number(filter.slice((negOnly || posOnly) ? 1 : 0)); // with math operand removed
    const exactDecimals = filter.includes('.');

    return txns.filter((txn) => {
      /*
       * AMOUNT SEARCH
       */
      if (amountSearch) {
        if ((negOnly && txn.amount >= 0) || (posOnly && txn.amount <= 0)) {
          return false;
        }
        const txnAmt = Math.abs(Number(txn.amount));
        switch (prefix) {
          case '>':
            return Math.abs(txnAmt) >= val1;
          case '<':
            return Math.abs(txnAmt) <= val1;
          case '&': {
            val1 = Math.abs(val1);
            val2 = Math.abs(val2);
            if (val1 > val2) {
              return ((txnAmt >= val2) && (txnAmt <= val1));
            }
            return ((txnAmt >= val1) && (txnAmt <= val2));
          }

          default: {
            if (baseValue === Math.floor(txnAmt)) {
              return true;
            }
            if (exactDecimals) {
              return baseValue === txnAmt;
            } 
          }
        }
      }

      /*
       * DATE SEARCH
       */
      if (dateSearch) {
        const txnDate = DateTime.fromISO(txn.postedOn);
        switch (prefix) {
          case '>':
            return txnDate >= val1;
          case '<':
            return txnDate <= val1;
          case '&':
            return txnDate >= val1 && txnDate <= val2;
          default:
            return false;
        }
      }

      /*
       * NON-AMOUNT SEARCH
       */
      if (filter === 'reviewed' || filter === 'notreviewed') {
        return txn.isReviewed ? filter === 'reviewed' : !(filter === 'reviewed');
      }
      let descString = `${txn.payee}~`;
      descString = `${descString}${DateTime.fromISO(txn.postedOn).toFormat('MM/dd/yyyy')}~`;
      if (isAcme) descString = `${descString}${DateTime.fromISO(txn.postedOn).toFormat('MMM dd,yyyy')}~`;
      if (txn.coa) {
        // TODO leverage longCats txlist preference to match search to display of category
        descString = `${descString}${chartOfAccountsSelectors.getCoaStringSelector(undefined, txn.coa)}~`;
      }
      descString = `${descString}${txn.state}~`;
      if (txn.check && txn.check.number) {
        descString = `${descString}${txn.check.number}~`;
      }
      descString = `${descString}${String(numeral(txn.amount).format('$0,00.00'))}~`;

      if (txn.split && txn.split.items) {
        txn.split.items.forEach((item) => {
          // TODO leverage longCats txlist preference to match search to display of category
          descString = `${descString}${item.memo}~${String(item.amount)}~${makeTagsString(item.tags)}~${chartOfAccountsSelectors.getCoaStringSelector(undefined, item.coa)}~`;
        });
      }
      if (txn.tags) {
        descString = `${descString}${makeTagsString(txn.tags)}~`;
      }
      if (txn.memo) {
        descString = `${descString}${txn.memo}~`;
      }

      if (txn.check) {
        descString = `${descString}${txn.check}~`;
      }
      // First, remove apostophes from the search
      descString = descString.replace("'", '');
      descString = descString.replace('`', '');
      // Convert special characters to spaces for the search
      descString = descString.replace(/([!"#%&*'()+,`\-./:;<=>?\][@\\^_])/g, ' ');

      return descString?.toLowerCase().indexOf(filterClean?.toLowerCase()) >= 0;
    });
  }
  return txns;
}
export function getFieldString(field, txn, longCats) {

  switch (field) {

    case 'postedOn':
    case 'date':
      return DateTime.fromISO(txn.postedOn).toLocaleString(DateTime.DATE_SHORT);
    case 'category':
      return transactionsUtils.isSplitTxn(txn) ? 'SPLIT' : chartOfAccountsSelectors.getCoaStringSelector(undefined, txn.coa, longCats);
    case 'account':
    case 'accountColor':
      return getAccountString(txn.accountId);
    case 'amount':
      return formatNumber(txn.amount, getCurrencyString(txn.accountId), '0,00.00');
    case 'balance':
      return formatNumber(txn.balance, getCurrencyString(txn.accountId), '0,00.00');
    case 'tags': {
      return makeTagsString(txn.tags) || '';
    }
    case 'check': {
      return txn.check && txn.check.number;
    }
    case 'status':
      return txn.state;
    case 'notes':
      return txn.memo ? txn.memo : '';
    default:
      return txn[field] ? txn[field] : '';
  }
}

export function makeTagsString(tags, useHashes) {
  let str = null;
  if (tags) {
    tags.forEach((x) => {
      const tag = tagsSelectors.getTagById(store.getState(), x.id) || x;
      if (tag && tag.name) {
        str = str ? `${str}${useHashes ? ' ' : '/'}` : '';
        str = `${str}${useHashes ? `#${tag.name}` : tag.name}`;
      }
    });
  }
  return str;
}


/*
 Filter Transactions By Filter Object

 Note, the first parameter is an immutable list of transactions

 Filter Object at this time is a dot addressable object (JS object or Immutable Record) with the following optional keys
 coas,                    List of coa objects that include type and id
 tagsIds,                 List of tag ID's that should be included
 payees    ,              list of payee names to be included
 accounts,                list of accountIds to be included (if not specified, it is all accounts)
 isBillOrSubscription,    if set (not undefined or null), it will filter by whether txns are bills or not
 isExcludedFromReports,    if set (not undefined or null), it will filter by whether txns have their ignored flag set

 */

function filterExists(filter) {
  return filter && filter.size > 0;
}

export const filterTransactionsByFilterObject = (txns, filterObject) =>
  (txns.filter((txn) => {

    let keep = true;

    if (keep && exists(filterObject?.advanced.isBillOrSubscription)) {
      keep = (Boolean((txn.isBill || txn.isBillOrSubscription)) === filterObject.advanced.isBillOrSubscription);
    }
    if (keep && exists(filterObject?.advanced.isExcludedFromReports)) {
      keep = (Boolean((txn.isExcludedFromReports)) === filterObject.advanced.isExcludedFromReports);
    }
    if (keep && exists(filterObject?.advanced.isReviewed)) {
      keep = (Boolean((txn.isReviewed)) === filterObject.advanced.isReviewed);
    }
    if (keep && exists(filterObject?.advanced.isExcludedFromF2S)) {
      keep = (Boolean((txn.isExcludedFromF2S)) === filterObject.advanced.isExcludedFromF2S);
    }

    if (keep && filterExists(filterObject?.accounts)) keep = filterObject?.accounts.includes(txn.accountId);
    if (keep && filterExists(filterObject?.payeeNames)) keep = filterObject?.payeeNames.includes(txn.payee?.toLowerCase());
    if (keep && filterExists(filterObject?.categories)) keep = filterObject?.categories.find((coaId) => txnHasCoa(txn, coaId));
    if (keep && filterExists(filterObject?.tags)) keep = filterObject?.tags.find((tagId) => txnHasTag(txn, tagId));

    return filterObject.filterOut ? !keep : keep;
  }));

function txnHasCoa(txn, coaId) {
  if (!transactionsUtils.isSplitTxn(txn)) {
    return (txn.coa?.id === coaId)
      || (txn.coa?.type === 'UNCATEGORIZED' && coaId === 'UNCATEGORIZED')
      || (txn.coa?.type === 'BALANCE_ADJUSTMENT' && coaId === `BALANCE_ADJUSTMENT_${txn.coa?.id}`);
  }
  return txn.split?.items?.find((item) => (item.coa?.id === coaId) || (item.coa?.type === 'UNCATEGORIZED' && coaId === 'UNCATEGORIZED'));
}

function txnHasTag(txn, tagId) {
  if (!transactionsUtils.isSplitTxn(txn)) return (txn.tags?.find((x) => x.id === tagId));
  return txn.split?.items?.find((item) => (item.tags?.find((x) => x.id === tagId)));
}

