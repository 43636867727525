import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

const SDatePicker = (props) => {
  const { value, onChange, onClose, textFieldProps, id, inputFormat, error, helperText, ...otherProps } = props;

  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      id="date"
      label="Date"
      inputFormat={inputFormat}
      value={value ? DateTime.fromISO(value) : null}
      onChange={onChange}
      open={Boolean(open)}
      onOpen={() => setOpen(!open)}
      onClose={(e) => {
        onClose?.(e);
        setOpen(false);
      }}
      slots={{
        textField: (params) => ( // eslint-disable-line react/no-unstable-nested-components
          <TextField
            {...params}
            id={id}
            autoComplete="off"
            onFocus={(_event) => {
              if (open === undefined) {
                setOpen(true);
              }
            }}
            error={error}
            helperText={helperText}
            {...textFieldProps}
            inputProps={{
              ...params.inputProps,
              placeholder: 'mm/dd/yyyy',
              ...textFieldProps?.inputProps,
            }}
          />
        ),
      }}
      {...otherProps}
    />
  );
};

SDatePicker.propTypes = {
  value: PropTypes.object, // PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  textFieldProps: PropTypes.object,
  id: PropTypes.string,
  inputFormat: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

SDatePicker.defaultProps = {
  inputFormat: 'MM/dd/yyyy',
};

export default SDatePicker;
