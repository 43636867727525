import React from 'react';
import QMenu from 'components/QMenu';
import AlarmOn from '@mui/icons-material/AlarmOn';
import AlarmOff from '@mui/icons-material/AlarmOff';

import { schedTxViewsWindows, schedTxViewsMac } from 'data/accountBalances/types';

interface ScheduleViewMenuProps {
  reminderSetting?: string | number;
  onChange?: (value: string | number) => void;
  isWindows?: boolean;
}

const ScheduleViewMenu: React.FC<ScheduleViewMenuProps> = ({
  reminderSetting,
  onChange,
  isWindows,
  ..._other
}) => (
  <QMenu
    menuIcon={!reminderSetting || reminderSetting === 'off' ? AlarmOff : AlarmOn}
    name="schedTxnMenu"
    title="Show reminders in register"
    menuIconButtonSize="mediumNoPadding"
    options={[
      {
        label: 'Show reminders for',
        groupId: 'reminders',
        isGroupList: true,
        selected: reminderSetting,
        groupList: isWindows ? schedTxViewsWindows : schedTxViewsMac,
      },
    ]}
    onChange={onChange}
  />
);

export default ScheduleViewMenu;



