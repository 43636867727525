import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Map as ImmutableMap } from 'immutable';

export const DIALOG_TYPE = 'BILL_SCOUT_DIALOG';

export const showBillScoutDialog = createAction(
  createDialog.toString(),
  (institutionLoginId, accountClientIds) => mkRootUiData({
    type: DIALOG_TYPE,
    id: DIALOG_TYPE,
    allowNesting: true,
    props: ImmutableMap({
      institutionLoginId,
      accountClientIds,
    }),
  })
);
