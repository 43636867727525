import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material';

import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Zoom from '@mui/material/Zoom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Fab from '@mui/material/Fab';

import { boxShadowsToDropShadows } from 'themes/themeBase';

const arrowSize = 10;
const borderRadius = 20;

const useStyles = makeStyles()((theme, { dark, color, greyscaled }) => ({
  popper: {
    zIndex: theme.zIndex.modal,
    filter: boxShadowsToDropShadows(theme.shadows[16]),
    backgroundColor: color,
    color: dark ? theme.palette.text.lightContrast : theme.palette.text.primary,
    borderRadius,
    '&[data-popper-placement*="left"] .arrow': {
      right: 0,
      marginRight: -arrowSize - arrowSize + 1,
      marginTop: borderRadius,
      marginBottom: borderRadius,
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '&[data-popper-placement*="top"] .arrow': {
      bottom: 0,
      marginBottom: -arrowSize - arrowSize + 1,
      marginLeft: borderRadius,
      marginRight: borderRadius,
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '&[data-popper-placement*="right"] .arrow': {
      left: 0,
      marginLeft: -arrowSize - arrowSize + 1,
      marginTop: borderRadius,
      marginBottom: borderRadius,
      borderLeftColor: 'transparent',
      borderTopColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '&[data-popper-placement*="bottom"] .arrow': {
      top: 0,
      marginTop: -arrowSize - arrowSize + 1,
      marginLeft: borderRadius,
      marginRight: borderRadius,
      borderLeftColor: 'transparent',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
    },
  },
  arrow: {
    zIndex: theme.zIndex.tooltip,
    position: 'absolute',
    borderColor: color,
    borderStyle: 'solid',
    borderWidth: arrowSize,
  },
  content: ({ 
    borderRadius,
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: greyscaled ? theme.components.categoryDialog.primary : undefined,
  }),
}));

const QPopper = (props) => {
  const { 
    modifiers, 
    onClickAway, 
    className, 
    style, // eslint-disable-line
    arrowPadding, 
    transitionEffect = 'zoom', 
    children,
    placement = 'auto',
    ...otherProps 
  } = props;
  const theme = useTheme();

  let color = theme.palette.background.paper;
  if (props.dark) {
    color = theme.palette.greyScaleDeprecated[0];
  } else if (props.greyscaled) {
    color = theme.components.categoryDialog.primary;
  }
  const { classes, cx } = useStyles({ ...props, color });
  const [arrowRef, setArrowRef] = useState();

  const renderPopperContents = () => (
    <div>
      <span
        className={cx(classes.arrow, 'arrow')}
        ref={(ref) => setArrowRef(ref)}
      />
      <Paper
        elevation={0}
        className={cx(classes.content, className)}
      >
        {children}
      </Paper>
    </div>
  );

  return props.anchorEl ? (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        className={classes.popper}
        keepMounted={false}
        disablePortal={false}
        sharedcomponentid={'Q_POPPER'}
        placement={placement}
        modifiers={[{
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'right', 'bottom', 'left'],
          },
        }, {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
            padding: arrowPadding ?? 20,
          },
        },
        ...(modifiers ?? []),
        ]}
        {...otherProps}
        transition
      >
        {({ TransitionProps }) => (
          <>
            {
              transitionEffect === 'zoom' && 
              <Zoom {...TransitionProps} timeout={200}>
                <Fab
                  sx={{
                    all: 'unset',
                    '&:hover, &:focus, &:active': {
                      all: 'unset',
                    },
                  }}
                  disableRipple
                  disableFocusRipple
                >
                  { renderPopperContents() }
                </Fab>
              </Zoom>
            }
            {
              transitionEffect === 'fade' && 
              <Fade {...TransitionProps} timeout={350}>
                { renderPopperContents() }
              </Fade>
            }
          </>
        )}
      </Popper>
    </ClickAwayListener>
  ) : null;
};

QPopper.defaultProps = {
  onClickAway: noop,
};

QPopper.propTypes = {
  anchorEl: PropTypes.object,
  modifiers: PropTypes.array,
  children: PropTypes.any,
  open: PropTypes.bool,
  onClickAway: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  dark: PropTypes.bool,
  greyscaled: PropTypes.bool,
  arrowPadding: PropTypes.any,
  transitionEffect: PropTypes.oneOf(['zoom', 'fade']),
  placement: PropTypes.string,
};

export default QPopper;
