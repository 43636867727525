const maxNetworkLogsLength = 128;
const networkLogs = [];

// monkey patch XMLHttpRequest to get network logs
export function captureNetworkLogs(xhr) {

  // Capture request before any network activity occurs:
  const { open } = xhr;
  // eslint-disable-next-line no-param-reassign
  xhr.open = function _open(method, url, ...rest) {
    networkLogs.push({
      key: this,
      method,
      url,
      requestHeaders: {},
    });
    if (networkLogs.length > maxNetworkLogsLength) {
      networkLogs.splice(0, networkLogs.length - maxNetworkLogsLength);
    }
    return open.call(this, method, url, ...rest);
  };

  const { setRequestHeader } = xhr;
  // eslint-disable-next-line no-param-reassign
  xhr.setRequestHeader = function _setRequestHeader(header, value) {
    const index = networkLogs.findIndex((item) => item.key === this);
    if (index >= 0) {
      networkLogs[index].requestHeaders[header] = value;
    }
    return setRequestHeader?.call(this, header, value);
  };

  const { send } = xhr;
  // eslint-disable-next-line no-param-reassign
  xhr.send = function _send(requestData, ...rest) {
    const index = networkLogs.findIndex((item) => item.key === this);
    if (index >= 0) {
      networkLogs[index] = { ...networkLogs[index], requestData };

      const { onloadend } = this;
      this.onloadend = function _onloadend(...params) {
        networkLogs[index] = {
          ...networkLogs[index],
          responseHeaders: Object.fromEntries(this.getAllResponseHeaders().split('\n').map((entry) => entry.split(': '))),
          responseData: this.response,
          status: this.status,
          statusText: this.statusText,
        };
        return onloadend?.apply(this, params);
      };
    }

    return send.call(this, requestData, ...rest);
  };
}

export const getNetworkLogs = () => networkLogs.map((logItem) => {
  const { key, requestData, responseData, ...rest } = logItem;

  let requestDataObj = requestData;
  try {
    requestDataObj = JSON.parse(requestData);
  } catch (_e) {}     // eslint-disable-line no-empty

  let responseDataObj = requestData;
  try {
    responseDataObj = JSON.parse(responseData);
  } catch (_e) {}     // eslint-disable-line no-empty

  return {
    ...rest,
    requestData: requestDataObj,
    responseData: responseDataObj,
  };
});
