import { Map as ImmutableMap, Record, Set } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

import type { QcsSyncResourceStoreProps } from 'companion-app-components/flux/core/resourceStoreTypes';


// example credentials
// type CredentialProps = {
//   type: string,
//   pattern: string,
//   // display: string, (this is a UI only field that I generate in loginDiscovery)
//   description: string,
//   status: string,
//   examples: Array,
//   choices: Array,
// };

export const RefreshStatusEnum = Object.freeze({
  UNKNOWN: 'UNKNOWN',
  REFRESHING: 'REFRESHING',
  REFRESHING_CREDENTIALS_VERIFIED: 'REFRESHING_CREDENTIALS_VERIFIED',
  REFRESHED_PARTIAL: 'REFRESHED_PARTIAL',
  REFRESHED: 'REFRESHED',
  CREDENTIALS_VALID: 'CREDENTIALS_VALID',
  CREDENTIALS_INVALID: 'CREDENTIALS_INVALID',
  CREDENTIALS_MISSING: 'CREDENTIALS_MISSING',
  LOCKED: 'LOCKED',
  CLOSED: 'CLOSED',
  OUTAGE: 'OUTAGE',
  PENDING: 'PENDING',
  DEPRECATED: 'DEPRECATED',
});

type BillerLoginProps = {
  id: string,
  billerID: string,
  providerBillerID: string,
  providerBillerLoginID: string,

  name: string,

  accountType: string,
  refreshStatus: string,
  notice: string,

  credentials: Array, // <Credentials>,
  credentialAnswers: Array,

  isEncryptionEnabled: boolean,
  fieldEncryptionId: string,

  isDeleted: boolean,
}
export type BillerLogin = RecordOf<BillerLoginProps>;
export const mkBillerLogin: RecordFactory<BillerLoginProps> =
  Record({
    id: undefined,
    billerID: undefined,
    providerBillerID: undefined, // this is the ID that maps to our billerStore
    providerBillerLoginID: undefined,

    name: undefined,

    accountType: undefined,
    refreshStatus: undefined,
    notice: undefined,

    credentials: undefined,
    credentialAnswers: undefined,

    isEncryptionEnabled: false,
    fieldEncryptionId: undefined,

    isDeleted: false,
  });

type BillerLoginStoreProps = QcsSyncResourceStoreProps & {

  discovering: any,

  isRefreshingAll: boolean,
  idsRefreshing: Set<string>,

  idsRefreshingWithUserActionsPending: Set<string>,
  idsRefreshingWithCancelledUserActions: Set<string>,

  // credentialBlobsForRefreshingLogins: ImmutableMap<string, string>,
  // credentialsForRefreshingLogins: ImmutableMap<string, List<Credential>>,
};

export type BillerLoginStore = RecordOf<BillerLoginStoreProps>;
export const mkBillerLoginStore: RecordFactory<QcsSyncResourceStoreProps> =
  Record({
    resourcesById: ImmutableMap(),
    lastSyncDate: null,

    loadPending: true,
    isLoading: false,

    error: null,

    // appended types
    discovering: null,

    isRefreshingAll: false,
    idsRefreshing: Set(),

    idsRefreshingWithUserActionsPending: Set(),
    idsRefreshingWithCancelledUserActions: Set(),
  });



