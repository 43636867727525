// @flow
import React from 'react';

import { makeStyles } from 'tss-react/mui';

import QButton from 'components/QButton';

export const useStyles = makeStyles()((theme) => {
  const { spacing } = theme;
  return ({
    actionBar: {
      alignItems: 'stretch',
      alignSelf: 'stretch',
      display: 'flex',
      padding: spacing(3),
    },
    actions: {
      display: 'flex',
      flex: 0,
      justifyContent: 'flex-end',
      '& > :not(:first-of-type)': {
        marginLeft: spacing(2),
      },
    },
    divider: {
      borderTop: '1px solid black',
    },
    spacer: {
      flex: 1,
    },
  });
});

type Props = {
  divider?: boolean,
  primaryDisabled?: boolean,
  primaryId?: string,
  primaryLabel?: string,
  primaryOnClick?: () => void,
  primaryType?: string,
  primaryVariant?: string,
  secondaryDisabled?: boolean,
  secondaryId?: string,
  secondaryLabel?: string,
  secondaryOnClick?: () => void,
  secondaryType?: string,
  secondaryVariant?: string,
  tertiaryActionLabel?: string,
  tertiaryActionOnClick?: () => void,
  tertiaryNode?: React.Node,
};

const StdDialogActions = (props: Props) => {
  const {
    divider,
    primaryDisabled,
    primaryId,
    primaryLabel,
    primaryOnClick,
    primaryType,
    primaryVariant,
    secondaryDisabled,
    secondaryId,
    secondaryLabel,
    secondaryOnClick,
    secondaryType,
    secondaryVariant,
    tertiaryActionLabel,
    tertiaryActionOnClick,
    tertiaryNode,
  } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.actionBar, divider ? classes.divider : null)}>
      {tertiaryActionLabel && tertiaryActionOnClick &&
        <QButton onClick={tertiaryActionOnClick}>
          {tertiaryActionLabel}
        </QButton>}
      {tertiaryNode}
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {secondaryOnClick &&
          <QButton
            disabled={secondaryDisabled}
            id={secondaryId}
            onClick={secondaryOnClick}
            type={secondaryType}
            variant={secondaryVariant}
          >
            {secondaryLabel}
          </QButton>}
        <QButton
          disabled={primaryDisabled}
          id={primaryId}
          onClick={primaryOnClick}
          type={primaryType}
          variant={primaryVariant}
        >
          {primaryLabel}
        </QButton>
      </div>
    </div>
  );
};

StdDialogActions.defaultProps = {
  primaryId: 'primaryButton',
  primaryLabel: 'Confirm',
  primaryType: 'submit',
  primaryVariant: 'contained',
  secondaryId: 'secondaryButton',
  secondaryLabel: 'Cancel',
  secondaryVariant: 'contained',
};

export default StdDialogActions;
