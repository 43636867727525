import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { Map as ImmutableMap } from 'immutable';

export const DIALOG_TYPE = 'SCHEDULED_TRANSACTION_ADD_DIALOG';

export const showAddScheduledTransactionDialog = createAction(
  createDialog.toString(),
  (props) => mkRootUiData({
    type: DIALOG_TYPE,
    id: DIALOG_TYPE,
    props: ImmutableMap(props),
  })
);
