/**
*
* ResourceRegister
 *
 * IMPORTANT NOTE!!  This component is also used by the QResourcePicker, as a generic tree list display with
 * The ability to select items.  It is used for accounts, tags, and payees as well as categories
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import SearchBox from 'components/SearchBox';
import TreeNodeRow from 'components/ResourceRegister/TreeNodeRow';
import { resourceRegisterStyles as styles } from 'components/ResourceRegister/styles';
import Divider from '@mui/material/Divider';

// import { getLogger } from 'utils/logger';

// const logger = getLogger('ResourceRegister');

const INITIAL_STATE = {
  editingId: null,
  cancelEditingId: null,
};

class ResourceRegister extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  requestEditing = (editingId) => {
    const {
      editingId: sEditingId,
      cancelEditingId: sCancelEditingId,
    } = this.state;

    this.setState({
      editingId,
      cancelEditingId: sEditingId !== editingId ? sEditingId : sCancelEditingId,
    });
  };

  cleanEditingData = (editingId) => {
    this.setState({
      ...INITIAL_STATE,
      editingId: editingId || INITIAL_STATE.editingId,
    });
  };

  renderHeader() {
    const { classes, dataDictionary } = this.props;
    return (
      <div className={classes.headerContainer}>
        {dataDictionary.map((row, index) => {
          const labelStyles = row.type === 'actions' ? { minWidth: 140 } : {};
          return (
            <Typography
              key={row.id}
              style={{ ...labelStyles, flex: row.flex }}
              variant="subtitle2"
              className={classNames(classes.baseRowHead, { [classes.firstRowHead]: index === 0 })}
            >{row.label}
            </Typography>
          );
        })}
      </div>
    );
  }

  renderRows() {
    const { classes, dataDictionary, data, onSave, onDelete, onRemoveNewItem, getCategoryByID, narrowMode,
      setDialog, onSelect, selectedItems, onSearchChange, resourceType, autoFocus, children, placeholder, noBorder, searchChildren } = this.props;

    return (
      <div className={classes.rowsContainer}>
        {children}
        {onSearchChange &&
          <SearchBox
            classes={{ smartSearch: noBorder ? classes.smartSearchNoBorder : classes.smartSearch }}
            onSearch={onSearchChange}
            autoFocus={autoFocus}
            placeholder={placeholder || `Search ${resourceType || 'items'}`}
            autoSearch
          />}
        {noBorder && <Divider />}
        {data.map((item, index) => (
          <TreeNodeRow
            key={item.id}
            narrowMode={narrowMode}
            item={item}
            rowIndex={[index]}
            dataDictionary={dataDictionary}
            onSave={onSave}
            onDelete={onDelete}
            onRemoveNewItem={onRemoveNewItem}
            getCategoryByID={getCategoryByID}
            setDialog={setDialog}
            onSelect={onSelect}
            selectedItems={selectedItems}
            expandForSearch={searchChildren}
            editingProps={{
              editingId: this.state.editingId,
              cancelEditingId: this.state.cancelEditingId,
              requestEditing: this.requestEditing,
              cleanEditingData: this.cleanEditingData,
            }}
          />
        ))}
      </div>
    );
  }

  render() {
    const { classes, noHeader } = this.props;

    return (
      <div className={classes.rootContainer}>
        {(!noHeader) ? this.renderHeader() : ''}
        {this.renderRows()}
      </div>
    );
  }
}

ResourceRegister.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  narrowMode: PropTypes.bool,
  dataDictionary: PropTypes.object,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  selectedItems: PropTypes.object,
  onRemoveNewItem: PropTypes.func,
  getCategoryByID: PropTypes.func,
  setDialog: PropTypes.func,
  noHeader: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onSearchChange: PropTypes.func,
  resourceType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  placeholder: PropTypes.string,
  noBorder: PropTypes.bool,
  searchChildren: PropTypes.bool,
};

export default withStyles(ResourceRegister, styles);
