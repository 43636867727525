import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import compose from 'utils/compose';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { withStyles } from 'tss-react/mui';
import QPureComponent from 'QPureComponent';

const allItemsKey = '0B2F7296-D641-45DC-BBD0-14168635D69E';

const styles = (theme) => ({
  selectedMenuItem: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
});

class QMultiSelectField extends QPureComponent {

  constructor(props) {
    super(props);

    const selectedItems = props.selectedItems ? (props.selectedItems || []) : ((props.autoSelectNewItems && props.items) || []);
    this.state = {
      selectedItems,
    };
    if (props.autoSelectNewItems && props.onSelected) {
      props.onSelected(selectedItems);
    }
  }

  componentDidUpdate(prevProps) {
    const { props, state } = this;
    if (prevProps.items !== props.items || prevProps.selectedItems !== props.selectedItems) {
      const newSelectedItems = prevProps.selectedItems === props.selectedItems ? state.selectedItems : props.selectedItems;
      const selectedItems = this.syncSelectedItems(newSelectedItems, props.items, prevProps.items);
      this.setState({ selectedItems }); // eslint-disable-line react/no-did-update-set-state
      props.onSelected && props.onSelected(selectedItems);
    }
  }

  syncSelectedItems(inSelectedItems, inItems, inPrevItems) {
    const selectedItems = inSelectedItems || [];
    const prevItems = inPrevItems || [];
    const items = inItems || [];
    const { props } = this;
    const newItems = items.filter((item) => !prevItems.find((prevItem) => props.keyFromItem(prevItem) === props.keyFromItem(item)) && !selectedItems.find((selectedItem) => props.keyFromItem(selectedItem) === props.keyFromItem(item)));
    const newSelectedItems = [...(selectedItems || []), ...(props.autoSelectNewItems ? newItems : [])];
    const verifiedSelectedItems = newSelectedItems.filter((selectedItem) => items.find((item) => props.keyFromItem(item) === props.keyFromItem(selectedItem)));
    return verifiedSelectedItems;
  }

  render() {
    const { state } = this;

    return (
      <FormControl id={this.props.id}>
        <Select
          multiple
          value={state.selectedItems}
          onChange={(event) => {
            let newSelectedItems = event.target.value;
            if (newSelectedItems.includes(allItemsKey)) {
              if (state.selectedItems.length >= this.props.items.length) {
                newSelectedItems = [];
              } else {
                newSelectedItems = this.props.items;
              }
            }
            this.setState({ selectedItems: newSelectedItems });
            this.props.onSelected && this.props.onSelected(newSelectedItems);
          }}
          renderValue={(selectedItems) => {
            let result;
            if (!selectedItems.length) {
              result = '';
            } else if (selectedItems.length === 1) {
              result = this.props.labelFromItem(selectedItems[0]);
            } else if (selectedItems.length === this.props.items.length) {
              result = `All ${this.props.name}`;
            } else {
              result = `${selectedItems.length} ${this.props.name}`;
            }
            return result;
          }}
        >
          {this.props.showAllItemsMenu && this.props.items && this.props.items.length > 1 &&
          <MenuItem value={allItemsKey} id={this.props.name}>
            <Checkbox
              checked={state.selectedItems.length === this.props.items.length}
              color="primary"
            />
            <ListItemText primary={`All ${this.props.name}`} />
          </MenuItem>}
          {this.props.showAllItemsMenu && this.props.items && this.props.items.length > 1 &&
          <Divider />}

          {this.props.items && this.props.items.map((item) => (
            <MenuItem
              key={this.props.labelFromItem(item)}
              value={item}
              classes={{ selected: this.props.classes.selectedMenuItem }}
            >
              <Checkbox
                checked={state.selectedItems.includes(item)}
                color="primary"
              />
              <ListItemText
                id={`${this.props.id}-item-${this.props.labelFromItem(item)}`}
                primary={this.props.labelFromItem(item)}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

}

QMultiSelectField.defaultProps = {
  name: 'Items',
};

QMultiSelectField.propTypes = {
  items: PropTypes.array.isRequired,
  labelFromItem: PropTypes.func.isRequired,
  keyFromItem: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  selectedItems: PropTypes.array,
  showAllItemsMenu: PropTypes.bool,
  autoSelectNewItems: PropTypes.bool,
  id: PropTypes.string,
  classes: PropTypes.object,
};

export default compose(
  withTheme,
)(withStyles(QMultiSelectField, styles, { name: { QMultiSelectField } }));
