
// CORE

import { accountsSelectors } from 'companion-app-components/flux/accounts';

// MUI

// DATA
import { useSelector } from 'react-redux';

// CUSTOM

import * as QC from './core';

function useQCurrency() {

  const accountsById = useSelector(accountsSelectors.getAccountsById);

  const getObjectSharedCurrency = (...args) => QC.getObjectSharedCurrency(...args);
  const getAccountsSharedCurrency = (...args) => QC.getAccountsSharedCurrency(...args, accountsById);
  const getSharedCurrency = (...args) => QC.getSharedCurrency(...args, accountsById);
  const getAccountIdsCurrency = (...args) => QC.getAccountIdsCurrency(...args, accountsById);
  const getTxnCurrency = (...args) => this.getAccountIdsCurrency(...args);
  const formatAmount = (...args) => QC.formatAmount(...args);
  const formatTxnAmount = (...args) => QC.formatTxnAmount(...args);


  return {
    getObjectSharedCurrency,
    getAccountsSharedCurrency,
    getSharedCurrency,
    getAccountIdsCurrency,
    getTxnCurrency,
    formatAmount,
    formatTxnAmount,
  };
}

export default useQCurrency;
