// @flow
import { Record, List as ImmutableList } from 'immutable';
import type { RecordFactory } from 'immutable';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

/*
 * KNOWN KEYS
 *
 *  'bankTransactions' - array of transactionsTypes.CashFlowTransaction types
 */

export type ClipboardStoreProps = {
  clipboard: Object,
};
export const mkClipboardStore: RecordFactory<ClipboardStoreProps> =
  Record({
    clipboard: ImmutableList(),
  });

type ClipboardProps = {
  key: string,
  data: Object,
  id: string,
  createdOn: string,
};

const mkClipboardItemInternal: RecordFactory<ClipboardProps> = Record({
  key: undefined,
  data: undefined,
  id: null,
  createdOn: null,
});

export const mkClipboardItem = (defaults) =>
  mkClipboardItemInternal({
    id: uuidv4(),
    createdOn: DateTime.local().toISO(),
    ...defaults,
  });

