import * as React from 'react';
import { FC, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { getAnnualSpendings, getBudgetById } from 'companion-app-components/flux/budgets/budgetsSelectors';
import type RootState from 'companion-app-components/utils/redux-store/rootState';

import { formatNumber } from 'components/QuickenControls/AmountField';

import ProgressChart from './progressChart';
import styles from './styles';
import { generateChartData } from './utils';

interface AnnualSpendingChartProps {
  budgetId: string;
}

const useStyles = makeStyles()(styles as Record<string, any>);

const AnnualSpendingChart: FC<AnnualSpendingChartProps> = ({ budgetId }) => {

  const { classes, theme }: { classes: Record<string, any>, theme: Record<string, any> } = useStyles();

  const data = useSelector((state: RootState) => getAnnualSpendings(state, budgetId)) as {
    startDate: string;
    endDate: string;
    totalSpendings: number;
    annualBudget: number;
    totalSavings: number;
    currency: string;
  };
  
  const budget = useSelector((state: RootState) => getBudgetById(state, budgetId));

  const { startDate, endDate, totalSpendings, annualBudget, totalSavings, currency } = data;
  const amountFormat = budget?.showCents ? '0,00.00' : '0,0';
  const dataForChart = useMemo(() => generateChartData(totalSpendings, annualBudget, totalSavings, currency, theme, amountFormat), [totalSpendings, annualBudget, totalSavings, currency, theme, amountFormat]);

  if (totalSpendings > 0 || annualBudget > 0 || totalSavings > 0) {
    return (
      <Paper className={classes.progressWrapper}>
        <Typography className={classes.rangeHeader}>
          {DateTime.fromISO(startDate).toFormat('MMM yyyy')} - {DateTime.fromISO(endDate).toFormat('MMM yyyy')}
        </Typography>
        <div className={classes.budgetAmountWrapper}>
          <Typography>
            Spending {formatNumber(totalSpendings, currency, amountFormat)} of {formatNumber(annualBudget, currency, amountFormat)}
          </Typography>
          {totalSavings > 0 && (
            <Typography>
              Savings {formatNumber(totalSavings, currency, amountFormat)}
            </Typography>
          )}
        </div>
        <ProgressChart data={dataForChart} />
      </Paper>
    );
  }
  return null;
};

export default AnnualSpendingChart;
