export const styles = {
  categoryName: {
    fontWeight: 500,
  },
  rootNode: {
    textTransform: 'uppercase',
    '&.rollup': {
      background: '#fff0f3',
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 5,
    },
  },
};
