const Styles = () => ({
  progressWrapper: {
    padding: 20,
    marginBottom: 20,
  },
  rangeHeader: {
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 10,
    textTransform: 'uppercase',
  },
  budgetAmountWrapper: {
    display: 'flex',
    '& p': {
      fontSize: 13,
      '&:nth-of-type(2)': {
        marginLeft: 'auto',
      },
    },
  },
  progressChart: {
    height: 20,
  },
});

export default Styles;
