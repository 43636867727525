import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

export default function QChoiceChips(props) {
  const { items, labelFromItem, keyFromItem, defaultSelectedItems, selectedItems, onSelected, multiple } = props;
  const theme = useTheme();
  const [selectedItemsState, setSelectedItemsState] = useState(defaultSelectedItems || selectedItems || []);
  const selected = defaultSelectedItems ? selectedItemsState : selectedItems;

  return (
    <div sharedcomponentid={'Q_CHOICE_CHIPS'}>
      {items.map((item) => (
        <Chip
          key={keyFromItem ? keyFromItem(item) : item}
          id={keyFromItem ? keyFromItem(item) : item}
          label={labelFromItem ? labelFromItem(item) : item}
          clickable
          onClick={() => {
            let newSelectedItems = selected;
            if (selected.includes(item)) { // remove
              if (multiple) {
                newSelectedItems = selected.filter((existingItem) => existingItem !== item);
              }
            } else if (multiple) {
              newSelectedItems = [...selected, item];
            } else {
              newSelectedItems = [item];
            }
            setSelectedItemsState(newSelectedItems);
            onSelected(newSelectedItems);
          }}
          variant="outlined"
          style={{
            backgroundColor: selected.includes(item) ? theme.palette.secondary.light : undefined,
            margin: 2,
          }}
        />
      ))}
    </div>
  );
}

QChoiceChips.propTypes = {
  items: PropTypes.array.isRequired,
  labelFromItem: PropTypes.func,
  keyFromItem: PropTypes.func,
  defaultSelectedItems: PropTypes.array, // uncontrolled
  selectedItems: PropTypes.array, // controlled
  onSelected: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};
