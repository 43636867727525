// @flow
export const ViewStateEnum = Object.freeze({
  DISABLED: 'disabled', // show 'under construction'
  LOADING: 'loading', // show loading progress
  LOAD_PENDING: 'loadPending', // show 'can't reach server'
  ZERO: 'zero', // show 'setup required'
  EMPTY: 'empty', // show 'no data for selected filters'
  MAIN: 'main', // show a feature
});
export type ViewStateType = $Values<typeof ViewStateEnum>; // eslint-disable-line no-undef

export const computeViewState = (
  isLoading: () => boolean | boolean, // data is loading right now
  isDataEmpty: () => boolean | boolean, // server has no data for selected filters
  noDependencies?: boolean, // extra setup required to start using feature
  loadPending?: boolean, // can't reach server
  disabled?: boolean, // feature is disabled temporary
) : ViewStateType => {

  const showZeroState = window.location.href.indexOf('zeroState') !== -1;
  const showEmptyState = window.location.href.indexOf('emptyState') !== -1;
  const showLoadingState = window.location.href.indexOf('loadingState') !== -1;

  if (showZeroState) return ViewStateEnum.ZERO;
  if (showEmptyState) return ViewStateEnum.EMPTY;
  if (showLoadingState) return ViewStateEnum.LOADING;

  if (disabled) return ViewStateEnum.DISABLED;
  if ((typeof isLoading === 'function' ? isLoading() : isLoading) && loadPending !== false) return ViewStateEnum.LOADING;
  if (loadPending) return ViewStateEnum.LOAD_PENDING;
  if ((typeof isDataEmpty === 'function' ? isDataEmpty() : isDataEmpty) || !noDependencies) return noDependencies ? ViewStateEnum.EMPTY : ViewStateEnum.ZERO;

  return ViewStateEnum.MAIN;
};
