import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';

const TableCellExpandable = (props) => {
  const { expanded, maxHeight, ...otherProps } = props;
  return (
    <TableCell
      style={{
        ...(!expanded && { opacity: 0 }),
        ...(!expanded && { border: 'none' }),
        ...(!expanded && { paddingTop: 0 }),
        ...(!expanded && { paddingBottom: 0 }),
        transition: 'all 300ms',
      }}
      className={otherProps.className}
      sharedcomponentid={'TABLE_CELL_EXPANDABLE'}
      {...otherProps}
    >
      <div
        style={{
          overflow: 'hidden',
          maxHeight: expanded ? maxHeight ?? 60 : 0,
          transition: 'all 300ms',
        }}
      >
        {props.children || null}
      </div>
    </TableCell>
  );
};

TableCellExpandable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  expanded: PropTypes.bool,
  maxHeight: PropTypes.number,
};

export default TableCellExpandable;
