import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { NavLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

// import Badge from '@mui/material/Badge';
import QuickenClassicLogo from 'assets/quicken-classic-logo.svg';

const useStyles = makeStyles()(() => ({
  logoText: {
    height: 48,
    marginRight: 24,
  },
  betaBadge: {
    backgroundColor: '#F5A623',
    padding: '1px 2px',
    height: 'auto',
    borderRadius: 2,
    textTransform: 'none',
    lineHeight: 1,
    fontSize: 12,
  },
  logoButton: {
    padding: 8,
    minWidth: 0,
  },
}));

const LogoText = () => {
  const { classes } = useStyles();
  return (
    <Zoom in style={{ transitionDelay: '100ms' }}>
      <Fab 
        sx={{
          all: 'unset',
          '&:hover, &:focus, &:active': {
            all: 'unset',
          },
        }}
        disableRipple
        disableFocusRipple
      >
        <Button
          id="logo-nav"
          // eslint-disable-next-line
          component={React.forwardRef((propers, ref) => <NavLink {...propers} innerRef={ref} />)}
          to="/"
          className={classes.logoButton}
        >
          <img
            src={QuickenClassicLogo}
            className={classes.logoText}
            alt="Quicken Classic, click to go home"
          />
        </Button>
      </Fab>
    </Zoom>
  );
};

export default LogoText;
