import * as React from 'react';
import { FC, forwardRef, ReactNode } from 'react';
import Box from '@mui/material/Box';

import { StyledMenuItem, StyledTypography } from './styles';

interface IconMenuItemProps {
  leftIcon: ReactNode;
  rightIcon: ReactNode;  
  label: string; 
  MenuItemProps: Record<string, any>; 
  className: string;
}

const IconMenuItem:FC<IconMenuItemProps> = forwardRef<HTMLLIElement, IconMenuItemProps>(({ 
  leftIcon, 
  rightIcon, 
  label, 
  MenuItemProps, 
  className, 
  ...props
}, ref) => (
  <StyledMenuItem
    {...MenuItemProps}
    ref={ref}
    className={className}
    {...props}
  >
    <Box sx={{ display: 'flex' }}>
      {leftIcon}
      <StyledTypography>{label}</StyledTypography>
    </Box>
    {rightIcon}
  </StyledMenuItem>
));

export default IconMenuItem;
