// @flow
import React from 'react';
import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Clear';
import BackIcon from '@mui/icons-material/ArrowBackRounded';
import { withStyles } from 'tss-react/mui';
import { removeDialog } from 'data/rootUi/actions';

import { styles } from './styles';
import { DIALOG_CLOSE_EVENTS } from './types';

export const isDialogCloseAction = (actionEvent: string) => DIALOG_CLOSE_EVENTS.find((event) => event === actionEvent);

type Props = {
  colorBar: ?string,
  dialogId: string,
  dialogProps: Object,
  disableTitleTypography : boolean,
  showCloseButton: boolean,
  spacedButton: boolean,
  title: string,
  wrappedDialog: any,
  showBackButton: boolean,
  onBack: () => void,

  onClose: () => void,
  removeDialog: (?string) => void,

  renderActions: () => void;
  // from withStyles
  classes: Object,
}

type State = {
  open: boolean,
}

export default () => (WrappedDialog: ComponentType<any>): ComponentType<any> => {
  class StdDialog extends React.PureComponent<Props, State> {

    wrappedDialog: any;

    static defaultProps = {
      showCloseButton: false,
    };

    constructor(props) {
      assert(props.dialogId, 'unique dialogId should be set');
      super(props);
      this.state = { open: true };
    }

    closeDialog = () => {
      this.setState({ open: false });
      setTimeout(() => this.props.removeDialog(this.props.dialogId), 300);
    };

    handleClose = (event: ?Event = null, reason: string) => {
      let localReason = reason;
      if (!localReason || localReason === 'backdropClick') {
        localReason = 'BACKDROP_CLICK';
      }
      if (!localReason || localReason === 'escapeKeyDown') {
        localReason = 'ESCAPE_KEY_DOWN';
      }

      if (this.wrappedDialog.handleClose) {
        this.wrappedDialog.handleClose(event, localReason);
      } else {
        this.closeDialog();
      }
    };

    renderCloseButton = () => {
      const { classes, showCloseButton, spacedButton } = this.props;
      if (showCloseButton) {
        return (
          <IconButton
            className={classNames(classes.closeButton, spacedButton && classes.spacedButton)}
            style={{ color: this.props.colorBar ? '#ffffff' : '' }}
            aria-label="Close"
            id="dlg-close"
            onClick={(event) => this.handleClose(event, 'CLOSE_BUTTON')}
          >
            <CloseIcon />
          </IconButton>
        );
      }
      return null;
    };

    renderTitle = () => {
      const { classes, disableTitleTypography, title } = this.props;
      if (title) {
        if (disableTitleTypography) {
          return title;
        }
        return (
          <Typography
            className={classes.title}
            variant="h5"
            id="dialog-title"
          >
            {this.props.title}
          </Typography>
        );
      }
      return null;
    };

    renderBackButton = () => {
      if (this.props.showBackButton) {
        return (
          <IconButton
            className={this.props.classes.backButton}
            // style={{ color: iconColor }}
            aria-label="Back"
            id="dlg-back"
            onClick={this.props.onBack}
          >
            <BackIcon />
          </IconButton>
        );
      }
      return null;
    }

    render() {
      const { dialogProps, classes, renderActions, ...other } = this.props;
      return (
        <Dialog
          open={this.state.open}
          {...dialogProps}
          onClose={(event, reason) => this.handleClose(event, reason)}
          aria-labelledby="dialog-title"
        >
          {this.renderBackButton()}
          {this.renderCloseButton()}
          {this.renderTitle()}
          {this.renderCloseButton()}
          <WrappedDialog
            {...other}
            closeDialog={this.closeDialog}
            onRef={(ref) => {
              this.wrappedDialog = ref;
            }}
          />
          {renderActions && renderActions({ handleClose: this.handleClose })}
        </Dialog>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      removeDialog: (props) => dispatch(removeDialog(props)),
    };
  }

  return compose(
    connect(null, mapDispatchToProps, null),
  )(withStyles(StdDialog, styles, { name: 'StdDialog' }));

};


