const styles = (theme) => ({
  parentContainer: {
    zIndex: 1000,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '8em 4em',
    [theme.breakpoints.down('xl')]: { padding: '6em, 2em' },
    [theme.breakpoints.down('lg')]: { padding: '4em, 1em' },
  },
  textItem: {
    fontWeight: 300,
    [theme.breakpoints.down('xl')]: { fontSize: 24, margin: 12 },
    [theme.breakpoints.down('lg')]: { fontSize: 16, margin: 8 },
  },
  paperAirplane: {
    width: '12em',
    height: '12em',
    [theme.breakpoints.down('xl')]: { width: '8em', height: '8em' },
    [theme.breakpoints.down('lg')]: { width: '6em', height: '6em' },
  },
  tryAgainButton: {
    fontSize: 24,
    fontWeight: 400,
    [theme.breakpoints.down('xl')]: { fontSize: 16, margin: 12 },
    [theme.breakpoints.down('xl')]: { fontSize: 14, margin: 8 },
  },
  reportProblem: {
    [theme.breakpoints.down('xl')]: { fontSize: 16, margin: 12 },
    [theme.breakpoints.down('xl')]: { fontSize: 14, margin: 8 },
  },
});

export default styles;
