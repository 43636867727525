import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DateTime, Info } from 'luxon';
import { useLocation } from 'react-router-dom';
import { List } from 'immutable';

import { makeStyles } from 'tss-react/mui';
import ButtonBase from '@mui/material/ButtonBase';
import PrevIcon from '@mui/icons-material/ChevronLeftRounded';
import NextIcon from '@mui/icons-material/ChevronRightRounded';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/ClearRounded';
import TodayIcon from '@mui/icons-material/Stop'; // Do not use rounded version for this one
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import Button from '@mui/material/Button';

import { accountsSelectors } from 'companion-app-components/flux/accounts';

import DownshiftField from 'components/QuickenControls/DownshiftField';
import QMenu from 'components/QMenu';
import useQPreferences from 'components/QPreferences/useQPreferences';
import { CurrMonth } from 'components/TransactionCalendar/types';
import { applyOpacityToHex } from 'themes/themeUtils';
import { calTopHeaderStyles } from '../../styles';

interface YearsOrMonthsData {
  key: string;
  label: string;
}

interface MonthsData extends YearsOrMonthsData {
  index: number;
}

interface YearsData extends YearsOrMonthsData {
  value: number;
}

interface CalTopHeaderProps {
  curMonth: CurrMonth | undefined;
  updateDateState: (date: DateTime | undefined | null, text: string) => void;
  onPrefsOpen: () => void;
  onAccountsPrefsModalOpen: () => void;
  showCloseButton: boolean;
  onClose: () => void;
}

const useStyles = makeStyles()(calTopHeaderStyles as Record<string, any>);

const monthsData: MonthsData[] = Info.months().map((month, index) => ({
  key: month, label: month, index: index + 1,
}));

const prevYears: number = 10;
const nextYears: number = 5;
const prevYearsArray: number[] = [...Array(prevYears).keys()].map((i) => DateTime.local().year - (i + 1)).reverse();
const nextYearsArray: number[] = [...Array(nextYears).keys()].map((i) => DateTime.local().year + (i + 1));
const yearsData: YearsData[] = [...prevYearsArray, DateTime.local().year, ...nextYearsArray]
  .map((year) => ({ key: `${year}`, label: `${year}`, value: year }));

const qMenuAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const qMenuTransformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

  
const CalTopHeader: React.FC<CalTopHeaderProps> = (props) => {
  const { classes, cx } = useStyles();
  const { updateDateState, onPrefsOpen, onAccountsPrefsModalOpen, showCloseButton, onClose, curMonth } = props;
  const { date } = curMonth ?? { date: null };

  const closeAndExit = useCallback(() => onClose && onClose(), [onClose]);

  const iconColor = applyOpacityToHex('#000000', 0.8);
  const location = useLocation();
  const showAllAccounts = location.search?.includes('displayNode=all');

  const { datasetPreferences, setDatasetPreference } = useQPreferences();

  const accountsByIdData = useSelector(accountsSelectors.getAccountsById);

  const originalAccountsToShow = useMemo(() => datasetPreferences.calendarTransaction.accountsToShow, [datasetPreferences]) || List();
  const accountsById = useMemo(() => accountsByIdData && accountsByIdData.filterNot((account) => (account.type === 'INVESTMENT')), [accountsByIdData]);
  const qMenuOptions = useMemo(() => [{ label: 'Preferences', value: 'preferences' }, ...showAllAccounts ? [{ label: 'Calendar Accounts', value: 'accounts' }] : []], [showAllAccounts]);

  const showAccountPrefReset = useMemo(() => {
    if (originalAccountsToShow.size !== accountsById.size) return true;
    if (originalAccountsToShow.size && originalAccountsToShow.every((acc) => !accountsById.has(acc))) return true;
    return false;
  }, [originalAccountsToShow, accountsById]);

  const onNextMonth = useCallback(() => {
    updateDateState(date?.plus({ months: 1 }), 'next-month');
  }, [updateDateState, date]);

  const onPrevMonth = useCallback(() => {
    updateDateState(date?.minus({ months: 1 }), 'previous-month');
  }, [updateDateState, date]);

  const onCurrentMonth = useCallback(() => {
    updateDateState(DateTime.local(), 'current-month');
  }, [updateDateState]);

  const onCustomMonth = useCallback((monthData: MonthsData) => {
    updateDateState(DateTime.local(date!.year, monthData.index), 'month-picker');
  }, [updateDateState, date]);

  const onCustomYear = useCallback((yearData: YearsData) => {
    updateDateState(DateTime.local(yearData.value, date!.month), 'year-picker');
  }, [updateDateState, date]);


  const labelFromItem = useCallback((item: any) => item?.label, []);
  const keyFromItem = useCallback((item: any) => item?.key, []);
  const onQMenuChange = useCallback((value: string) => {
    if (value === 'preferences') {
      onPrefsOpen();
    } else if (value === 'accounts') {
      onAccountsPrefsModalOpen();
    }
  }, [onPrefsOpen, onAccountsPrefsModalOpen]);

  const monthSelectedIndex = (date?.month ?? 0) - 1;
  const yearSelectedIndex = yearsData.findIndex((y) => y.value === date?.year);

  const textFieldProps = useMemo(() => ({
    InputProps: {
      disableUnderline: true,
      classes: {
        input: classes.inputRoot,
      },
    },
    fullWidth: true,
  }), [classes]);

  const disablePrevBtn = useMemo(() => {
    const firstYear = yearsData && DateTime.local(yearsData[0]?.value).minus({ months: 1 }).toISODate();
    return firstYear === date?.minus({ months: 1 }).toISODate();
  }, [date]);

  const disableNextBtn = useMemo(() => {
    const lastYear = yearsData && DateTime.local(yearsData[yearsData.length - 1]?.value, 12).plus({ months: 1 }).toISODate();
    return lastYear === date?.plus({ months: 1 }).toISODate();
  }, [date]);

  const onResetHandler = useCallback(() => {
    setDatasetPreference({ calendarTransaction: { accountsToShow: accountsById.map((acc) => acc.id).toSet() } });
  }, [accountsById, setDatasetPreference]);

  return (
    <header className={classes.monthHeader}>
      <div className={classes.controlGroup}>
        <div className={classes.controlRoot}>
          <ButtonBase
            id="cal-prev-month"
            className={cx(classes.iconControl, { [classes.iconControlDisabled]: disablePrevBtn })}
            onClick={onPrevMonth}
            disabled={disablePrevBtn}
          >
            <PrevIcon />
          </ButtonBase>
        </div>
        <div className={classes.controlRoot}>
          <ButtonBase id="cal-current-month" className={classes.iconControl} onClick={onCurrentMonth}>
            <TodayIcon className={classes.iconToday} />
          </ButtonBase>
        </div>
        <div className={classes.controlRoot}>
          <ButtonBase
            id="cal-next-month"
            className={cx(classes.iconControl, { [classes.iconControlDisabled]: disableNextBtn })}
            onClick={onNextMonth}
            disabled={disableNextBtn}
          >
            <NextIcon />
          </ButtonBase>
        </div>

        {date && (
          <>
            <div className={cx(classes.controlRoot, classes.monthPickerRoot)}>
              <DownshiftField
                key={`month-${date.month}`}
                items={monthsData}
                initialItemSelected={monthsData[monthSelectedIndex]}
                initialInputValue={monthsData[monthSelectedIndex].label}
                itemToString={labelFromItem}
                itemKey={keyFromItem}
                autoFocus={false}
                onSelected={onCustomMonth}
                textFieldProps={textFieldProps}
              />
            </div>

            <div className={cx(classes.controlRoot, classes.yearPickerRoot)}>
              <DownshiftField
                key={`year-${date.year}`}
                items={yearsData}
                initialItemSelected={yearsData[yearSelectedIndex]}
                initialInputValue={yearsData[yearSelectedIndex]?.label}
                itemToString={labelFromItem}
                itemKey={keyFromItem}
                autoFocus={false}
                onSelected={onCustomYear}
                textFieldProps={textFieldProps}
              />
            </div>
          </>
        )}
      </div>

      <div className={classes.settingsRoot}>
        {showAllAccounts && showAccountPrefReset &&
          <Button
            id="reset-button"
            variant="contained"
            onClick={onResetHandler}
            classes={{ root: classes.button }}
          >
            Reset To Default
          </Button>}
        <QMenu
          customTrigger={<SettingsIcon className={classes.settingsIcon} />}
          name={'qmenu-calendar-settings'}
          menuIconButtonSize="medium"
          customTriggerClass={classes.settingsIconRoot}
          options={qMenuOptions}
          anchorOrigin={qMenuAnchorOrigin}
          transformOrigin={qMenuTransformOrigin}
          onChange={onQMenuChange}
        />
        {showCloseButton &&
        <IconButton
          style={{ color: iconColor }}
          aria-label="Close"
          id="dlg-close"
          type="button"
          onClick={closeAndExit}
        >
          <CloseIcon />
        </IconButton>}
      </div>
    </header>
  );
};


export default React.memo(CalTopHeader);
