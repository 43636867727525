import { call, put, takeEvery } from 'redux-saga/effects';

import { actionHelpers, resourceStoreTypes, resourceSagaUtils } from 'companion-app-components/flux/core';

import * as actions from './actions';

const mkResourceConfig = (props) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'job statues',
  resourceBaseUrl: '/job-statuses',
  ...props,
});

export function* deleteJobStatus(config: resourceStoreTypes.QcsSyncResourcesConfig, action: any): Generator<*, *, *> {
  const { id } = action.payload;
  if (id) {
    yield call(resourceSagaUtils.qcsSyncDeleteResource, config, action);
  } else {
    // if we don't have ID (then create on QCS probably failed) - still show success since nothing to really delete
    yield put(config.successAction(null, actionHelpers.cpMetaForResponseAction({ ...action.meta })));
  }
}

function* createJobStatusActionWatcher() {
  const resourceConfig = mkResourceConfig({
    successAction: actions.createJobStatusSuccess,
    failureAction: actions.createJobStatusFailure,
  });
  yield takeEvery(actions.createJobStatus, resourceSagaUtils.qcsSyncCreateResource, resourceConfig);
}

function* deleteJobStatusActionWatcher() {
  const resourceConfig = mkResourceConfig({
    successAction: actions.deleteJobStatusSuccess,
    failureAction: actions.deleteJobStatusFailure,
  });
  yield takeEvery(actions.deleteJobStatus, deleteJobStatus, resourceConfig);
}

export default [
  createJobStatusActionWatcher,
  deleteJobStatusActionWatcher,
];
