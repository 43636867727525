import React from 'react';
import SnackbarContent from '@mui/material/SnackbarContent';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';

const NotificationContent = React.forwardRef((props, ref) => {
  const { name, onClose, message, action, ...other } = props;
  return (
    <SnackbarContent
      ref={ref}
      aria-describedby="mc-toast-snackbar"
      message={
        <span id="Bill Presentment">
          Connecting to {name || 'biller'}
        </span>
      }
      action={[
        <CircularProgress
          key={'spinner'}
          size={24}
          // style={{ marginRight: 8 }}
        />,
        <Tooltip key={'tippy'} title={'Cancel'} arrow enterDelay={300}>
          <IconButton
            id="close-undo"
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>,
      ]}
      {...other}
    />
  );
});

NotificationContent.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func,
  message: PropTypes.any,
  action: PropTypes.any,
};

export default NotificationContent;
