import useQPreferences from 'components/QPreferences/useQPreferences';
import { BUDGET_VIEWS, BUDGET_ANNUAL_SUBVIEWS } from './constants';
import { getKeyFutureMonthsVisibility } from './utils';

export const useBudgetOptions = (budgetId:string = '', dashboardRef: string | string[] | null = null) => {
  const { datasetPreferences, setDatasetPreference } = useQPreferences();
  const isToDateColumnEnabled = datasetPreferences?.budgetOptions?.showToDateColumn || false;
  const viewState = datasetPreferences?.budgetOptions?.viewState?.[budgetId] || {};
  let budgetView = BUDGET_VIEWS.monthly.key;
  if (viewState?.viewType && dashboardRef !== 'dashboard') {
    budgetView = viewState?.viewType;
  }
  const annualSubView = viewState?.subViewType || BUDGET_ANNUAL_SUBVIEWS.budget.key;
  const showFutureMonthDetails = viewState[getKeyFutureMonthsVisibility(viewState?.subViewType)] || false;

  return {
    viewState,
    budgetView,
    annualSubView,
    showFutureMonthDetails,
    isToDateColumnEnabled,
    setDatasetPreference,
  };
};
