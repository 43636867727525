export const styles = (theme) => ({
  VL: {
    padding: '10px 10px',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      WebkitBoxShadow: '0 0 1px rgba(245, 245, 245, 0.5)',
    },
  },
  txnLine: {
    height: 56,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: 16,
    boxShadow: theme.shadows[2],
    '&:hover': {
      transform: 'translate(0px, 0px)',
      backgroundColor: theme.palette.shinyGrey,
      cursor: 'pointer',
    },
  },
  txnLineShaded: {
    height: 56,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: 16,
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.silverGrey,
    '&:hover': {
      transform: 'translate(0px, 0px)',
      backgroundColor: theme.palette.silverGrey,
      cursor: 'pointer',
    },
  },
  dater: { // 30
    width: 136,
    paddingRight: 16,
  },
  payer: { // 31
    width: '31%',
    minWidth: 90,
    paddingRight: 16,
  },
  typer: { // 28
    width: '28%',
    paddingRight: 16,
  },
  amter: { // 18
    width: '16%',
    textAlign: 'right',
    minWidth: 64,
  },
  checkWrap: {
    height: 22,
    width: 22,
    marginLeft: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  checkIcon: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    color: theme.palette.white,
    width: 20,
    height: 20,
    padding: 2,
  },
  selectIcon: {
    borderRadius: '50%',
    border: '1px solid grey',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  noCheck: {
    width: 20,
    height: 20,
    padding: 2,
    opacity: 0,
    backgroundColor: 'inherit',
  },
  zeroState: {
    textAlign: 'center',
    lineHeight: 2,
  },
  stateView: {
    height: 360,
    width: '100%',
  },
  zeroRoot: {
    height: '80%',
  },
  zeroReset: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    height: 32,
  },
  resetText: { // TODO Add this to replace #666 somehow...
    color: theme.palette.slateGrey,
    lineHeight: '32px',
  },
});
