import React, { FC, CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';

import QButton from 'components/QButton';
import CategoryField from 'components/QuickenControls/CategoryField';
import QAmountField from 'components/QAmountField';

import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface TitleHeaderProps {
  classes: Record<string, any>;
}

export const TitleHeader: FC<TitleHeaderProps> = ({ classes }) => (
  <div
    className={classes.header}
  >
    <Typography
      variant="h5"
      className={classes.title}
    >
      Add Budget Category
    </Typography>
    <Typography
      variant="body1"
    >
      Select a category to add to your budget.  An average amount from your previous transactions
      will be entered on your behalf.  You may choose to update the amount.
    </Typography>
  </div>
);

interface ButtonAreaProps {
  onAdd: () => void;
  onClose: () => void;
  disableSubmit: boolean;
}

export const ButtonArea: FC<ButtonAreaProps> = ({ onAdd, onClose, disableSubmit }) => (
  <DialogActions>
    <QButton onClick={onClose} id="add-budget-cancel-button">
      Cancel
    </QButton>
    <QButton disabled={disableSubmit} onClick={onAdd} id="add-to-budget-button">
      Add To Budget
    </QButton>
  </DialogActions>
);

interface WrappedFieldProps {
  children: any;
  caption: string;
  style: CSSProperties;
}

export const WrappedField: FC<WrappedFieldProps> = ({ children, caption, ...other }) => {
  const { classes } = useStyles();
  return (
    <div
      {...other}
    >
      <Typography
        variant="caption"
      >
        {caption}
      </Typography>
      <div className={classes.fieldWrapper}>
        {children}
      </div>
    </div>
  );
};

interface CategoryWrappedFieldProps {
  selectedCategory?: Record<string, any>;
  onChange?: () => void;
  catFieldProps: Record<string, any>;
}

export const CategoryWrappedField: FC<CategoryWrappedFieldProps> = ({ catFieldProps, ...other }) => (
  <WrappedField
    caption="Select a Category"
    style={{ flex: 1, marginRight: 40 }}
    {...other}
  >
    <CategoryField
      name="budget-add-cat-field-category"
      id="budget-add-cat-field-category"
      fontSize="22px"
      editable
      allowBlank
      createEnabled
      disableUnderline
      initialFocus
      {...catFieldProps}
    />
  </WrappedField>
);

interface AmountWrappedFieldProps {
  currentAmount: string | number;
  onBlur: (e: any) => void;
  onSubmit: (e: any) => void;
  classes: Record<string, any>;
  currency: string;
}

export const AmountWrappedField: FC<AmountWrappedFieldProps> = ({ currentAmount, onBlur, classes, onSubmit, currency, ...other }) => (
  <WrappedField
    caption="Amount"
    style={{ flex: 0, minWidth: 150 }}
    {...other}
  >
    <QAmountField
      name="budgetAddAmountField"
      id="budget-category-amount-field"
      editable
      value={currentAmount}
      currency={currency}
      classes={classes}
      onSubmit={onSubmit}
      onBlur={onBlur}
      disableUnderline
      decimals={0}
    />
  </WrappedField>
);
