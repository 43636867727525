const { sprintf } = require('sprintf-js');

class ConsoleMirror extends Object {

  #superConsole;
  #superLog;
  #superInfo;
  #superError;
  #superWarn;
  #superDebug;

  #maxLogLength = 4096;
  #log = [];

  attach() {
    this.#superConsole = console;
    this.#superLog = console.log; // eslint-disable-line no-console
    console.log = (...rest) => this.applyLog(this.#superLog, rest, '[log] '); // eslint-disable-line no-console
    this.#superInfo = console.info; // eslint-disable-line no-console
    console.info = (...rest) => this.applyLog(this.#superInfo, rest, '[info] '); // eslint-disable-line no-console
    this.#superError = console.error; // eslint-disable-line no-console
    console.error = (...rest) => this.applyLog(this.#superError, rest, '[error] '); // eslint-disable-line no-console
    this.#superWarn = console.warn; // eslint-disable-line no-console
    console.warn = (...rest) => this.applyLog(this.#superWarn, rest, '[warn] '); // eslint-disable-line no-console
    this.#superDebug = console.debug; // eslint-disable-line no-console
    console.debug = (...rest) => this.applyLog(this.#superDebug, rest, '[debug] '); // eslint-disable-line no-console
  }

  applyLog(superMethod, parametersRaw, tag = '') {
    superMethod.apply(this.#superConsole, parametersRaw);
    try {
      let message = '';
      let format;
      if (parametersRaw.length > 1 && typeof parametersRaw[0] === 'string') { // might be substitutions string
        format = parametersRaw.shift()
          .replace('%c', '%.0s') // drop %c on the floor
          .replace('%o', '%s') // printf doesn't support %o
          .replace('%O', '%s'); // printf doesn't support %O
      }
      const parameters = parametersRaw.map((param) => typeof param === 'object' ? JSON.stringify(param, null, 2) : param);
      if (format) {
        message += sprintf(format, ...parameters);
        const capturedNum = format.match(/%\.?\d*[dsif]/g)?.length ?? 0;
        parameters.splice(0, capturedNum);
      }
      message += parameters.length ? ' ' : '';
      message += parameters.join(' ');
      const date = (new Date()).toISOString();
      this.#log.push(`${tag}${date}: ${message}`);
      if (this.#log.length > this.#maxLogLength) {
        this.#log.splice(0, this.#log.length - this.#maxLogLength);
      }
    } catch (error) {
      verify(false, error);
    }
  }

  get log() {
    return this.#log;
  }
}

const consoleMirror = new ConsoleMirror();
export default consoleMirror;
