import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import * as notificationsSelectors from 'data/notifications/notificationsSelectors';
import * as notificationsActions from 'data/notifications/notificationsActions';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

import Slide from '@mui/material/Slide';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Notifications = () => {
  const dispatch = useDispatch();
  const notificationsEnabled = useSelector((state) => featureFlagsSelectors.getFeatureFlag(state, 'notifications'));
  const notifications = useSelector(notificationsSelectors.getNotifications);
  const seqNotifications = notifications?.toIndexedSeq();
  const height = seqNotifications?.first()?.height || 70;

  const onClose = useCallback((id) => {
    dispatch(notificationsActions.removeNotifications([id]));
  }, [dispatch]);

  const transitionDirection = useCallback((anchorOrigin) => {
    let direction = 'right';
    if (anchorOrigin.horizontal === 'right') {
      direction = 'left';
    } else if (anchorOrigin.horizontal === 'left') {
      direction = 'right';
    } else if (anchorOrigin.vertical === 'top') {
      direction = 'down';
    } else if (anchorOrigin.vertical === 'bottom') {
      direction = 'up';
    } else {
      assert(false, 'unexpected anchorOrigin = ', anchorOrigin);
    }
    return direction;
  }, []);

  const snackbarPosition = useCallback((anchorOrigin, index) => {
    let position = {};
    switch (anchorOrigin.vertical) {
      case 'top':
        position = {
          ...position,
          top: index * height + 20,
        };
        break;
      case 'bottom':
        position = {
          ...position,
          bottom: index * height + 20,
        };
        break;
      default:
        assert(false, 'unexpected anchorOrigin.vertical = ', anchorOrigin.vertical);
    }

    switch (anchorOrigin.horizontal) {
      case 'left':
        position = {
          ...position,
          left: 100,
        };
        break;
      case 'center':
        position = {
          ...position,
          left: '50%',
        };
        break;
      case 'right':
        position = {
          ...position,
          right: 20,
        };
        break;
      default:
        assert(false, 'unexpected anchorOrigin.horizontal = ', anchorOrigin.horizontal);
    }
    return position;
  }, [height]);

  return (
    <>
      {notificationsEnabled && seqNotifications.map((notification) => {
        const SnackbarContentElement = notification.snackbarContent || SnackbarContent;
        return (
          <Snackbar
            id={notification.id}
            key={notification.id}
            anchorOrigin={notification.anchorOrigin}
            open={notifications.includes(notification)}
            style={{
              ...snackbarPosition(notification.anchorOrigin, notification.anchorIndex),
              transition: 'all 500ms',
            }}
            autoHideDuration={notification.autoHideDuration === undefined ? 10000 : notification.autoHideDuration}
            onClose={(event, reason) => {
              if (notification.snackbarContentProps?.onClose) {
                notification.snackbarContentProps.onClose(event, reason);
              } else if (reason !== 'clickaway') {
                onClose(notification.id);
              }
            }}
            TransitionComponent={Slide}
            TransitionProps={{ direction: transitionDirection(notification.anchorOrigin) }}
            transitionDuration={500}
          >
            <SnackbarContentElement
              message={notification.message}
              action={
                <IconButton
                  size="small"
                  aria-label="dismiss"
                  id="snackbar-dismiss"
                  color="inherit"
                  onClick={() => onClose(notification.id)}
                >
                  <CloseIcon />
                </IconButton>
              }
              {...notification.snackbarContentProps}
            />
          </Snackbar>
        );
      })}
    </>
  );
};

export default Notifications;

