// CORE
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// MUI
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// DATA
import { removeDialog } from 'data/rootUi/actions';

// COMPONENTS
import StdDialog from 'components/Dialogs/StdDialog';
import MemorizedRuleDialogForm from 'components/Forms/MemorizedRuleDialogForm';
import LoadingView from 'components/LoadingView';

export const DIALOG_TYPE_MEMORIZED_RULE_EDIT = 'DIALOG_TYPE_MEMORIZED_RULE_EDIT';

const MemorizedRuleEditDialog = React.memo((props) => {
  const { memorizedRule, fromTxn, onClose, dialogId, open, ...otherProps } = props;
  const dispatch = useDispatch();

  const [viewExistingTransactions, setViewExistingTransactions] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const onCloseProxy = useCallback((_event, reason) => {
    if (reason === 'submit' && !memorizedRule.id) {
      setShowLoading(true);
      return;
    }
    if (reason === 'create-complete' || reason === 'create-error') {  // handle done creating rule
      onClose?.('submit');  // pass onClose upwards finishing submission
      if (open === undefined && dialogId) { // uncontrolled version of dialog
        dispatch(removeDialog(dialogId));
      }
      return;
    }
    onClose?.(reason);
    if (open === undefined && dialogId) { // uncontrolled version of dialog
      dispatch(removeDialog(dialogId));
    }
  }, [dispatch, dialogId, onClose, open, memorizedRule]);

  let dialogTitle = memorizedRule?.id ? 'Edit category rule' : 'New category rule';
  if (viewExistingTransactions) dialogTitle = 'Existing transactions';
  if (showLoading) dialogTitle = null;

  return (
    <StdDialog
      title={dialogTitle}
      dialogId={dialogId}
      open={open}
      onClose={onCloseProxy}
      disableBackdropClick
      disableEscapeKeyDown
      showCloseButton={!showLoading}
      showBackButton={viewExistingTransactions && !showLoading}
      onBack={() => setViewExistingTransactions(false)}
      sharedcomponentid={'DIALOG_TYPE_MEMORIZED_RULE_EDIT'}
      fullWidth
      maxWidth="md"
      {...otherProps}
    >
      {
        showLoading ?
          <Box padding={5} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h5">
              Creating category rule
            </Typography>
            <Box marginTop={3} marginBottom={5}>
              <LoadingView size={100} />
            </Box>
          </Box>
          :
          <MemorizedRuleDialogForm
            memorizedRule={memorizedRule}
            fromTxn={fromTxn}
            onClose={onCloseProxy}
            viewExistingTransactions={viewExistingTransactions}
            setViewExistingTransactions={setViewExistingTransactions}
          />
      }
    </StdDialog>
  );
});

MemorizedRuleEditDialog.propTypes = {
  memorizedRule: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool, // set manually for controlled dialog
  dialogId: PropTypes.string, // set automatically for uncontrolled dialog
  fromTxn: PropTypes.bool,
};

export default MemorizedRuleEditDialog;
