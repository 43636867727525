import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { DIALOG_TYPE as DIALOG_TYPE_WF_DESKTOP_FEATURES } from './types';

export const doAdditionalDesktopFeatures = createAction(
  createDialog.toString(),
  () => mkRootUiData({
    id: 'WFDesktopFeatures',
    type: DIALOG_TYPE_WF_DESKTOP_FEATURES,
    allowNesting: false,
  }),
);
