import React, { FC, useState, useCallback } from 'react';
import isString from 'lodash/isString';
import { withStyles } from 'tss-react/mui';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import QButton from 'components/QButton';

import styles from './styles';

export const dialogActions = {
  CLOSE: 'Close',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  KEYDOWN: 'KeyDown',
};

interface DialogHocType {
  classes?: Record<string, any>, // eslint-disable-line
  dialog: Record<string, any>, // eslint-disable-line
  dispatchDialogAction: any, // eslint-disable-line
}

interface DialogState {
  title: string, 
  content: string, 
  callback: (obj: Record<string, any>) => void, 
  buttons: [],
}

export default () => (WrappedComponent) => {

  const DialogHoc: FC<DialogHocType> = (props) => {

    const [dialog, setDialogState] = useState<null | DialogState>(null);

    const setDialog = useCallback((title, content, callback, buttons) => {
      setDialogState({ title, content, callback, buttons });
    }, []);

    const clearDialog = useCallback(() => {
      setDialogState(null);
    }, []);

    const dispatchDialogAction = useCallback((action) => (event, reason) => {
      let dispatchAction = action;

      if (dispatchAction === dialogActions.KEYDOWN && event.key === 'Enter') {
        dispatchAction = dialogActions.ENTER;
      } else if (reason === 'escapeKeyDown') {
        dispatchAction = dialogActions.ESCAPE;
      }

      if (dispatchAction !== dialogActions.KEYDOWN && dialog?.callback) {
        dialog.callback({ action: dispatchAction });
        clearDialog();
      }
    }, [dialog, clearDialog]);

    const renderDialog = useCallback((dialogData) => {
      // eslint-disable-next-line
      const DialogComponent: FC<DialogHocType> = (prop) => (
        <Dialog
          maxWidth="md"
          onClose={prop.dispatchDialogAction(dialogActions.CLOSE)}
          onKeyPress={prop.dispatchDialogAction(dialogActions.KEYDOWN)}
          aria-labelledby="dialogHoc-title"
          open
        >
          <DialogTitle id="dialogHoc-title" className={prop.classes?.dialogTitleRoot}>
            <Typography variant="h6">
              {prop.dialog.title}
            </Typography>
            <IconButton
              aria-label="Close"
              className={prop.classes?.dialogCloseButton}
              onClick={prop.dispatchDialogAction(dialogActions.CLOSE)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={prop.classes?.dialogContentRoot}>
            <Typography variant="subtitle1" component="div" className={prop.classes?.dialogContentText}>
              {prop.dialog.content}
            </Typography>
          </DialogContent>
          {prop.dialog.buttons && (
            <DialogActions className={prop.classes?.dialogActionsRoot}>
              {prop.dialog.buttons.map((Btn, i) => {
                if (isString(Btn)) {
                  const variant = i === prop.dialog.buttons.length - 1 ? 'contained' : 'outlined';
                  return (
                    <QButton
                      id={`dialog_${Btn}`}
                      key={Btn}
                      className={prop.classes?.dialogActionsButton}
                      variant={variant}
                      onClick={prop.dispatchDialogAction(Btn)}
                    >
                      {Btn}
                    </QButton>
                  );
                }
                return <Btn key={Btn} onClick={prop.dispatchDialogAction(Btn)} />;
              })}
            </DialogActions>
          )}
        </Dialog>
      );

      const DialogComponentWithStyles: FC<DialogHocType> = withStyles(DialogComponent, styles as Record<string, any>);

      return (
        <DialogComponentWithStyles
          dialog={dialogData}
          dispatchDialogAction={dispatchDialogAction}
        />
      );
    }, [dispatchDialogAction]);

    return (
      <>
        <WrappedComponent 
          {...props}
          setDialog={setDialog}
        />
        {dialog && renderDialog(dialog)}
      </>
    );
  };

  return DialogHoc;
  
};
