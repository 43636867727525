import {
  IBudgetViews,
  IBudgetSubViews,
  BudgetAnnualMonthsType,
  BudgetAnnualCopyOptionsType,
  BudgetStickyTopHeightType,
} from './types';

export const BUDGET_ANNUAL_INVISIBLE_AMOUNT_SYMBOL = '-';

export const BUDGET_VIEWS: IBudgetViews = {
  monthly: {
    key: 'monthly',
    label: 'Monthly View',
  },
  annual: {
    key: 'annual',
    label: 'Annual View',
  },
};

export const BUDGET_ANNUAL_SUBVIEWS: IBudgetSubViews = {
  details: {
    key: 'details',
    label: 'Details',
    amountKey: 'balanceAmount',
    columnLabel: 'Balance',
  },
  budget: {
    key: 'budget',
    label: 'Budget only',
    amountKey: 'budgetAmount',
    columnLabel: 'Budget',
  },
  actual: {
    key: 'actual',
    label: 'Actuals only',
    amountKey: 'actualAmount',
    columnLabel: 'Actual',
  },
  balance: {
    key: 'balance',
    label: 'Balance only',
    amountKey: 'balanceAmount',
    columnLabel: 'Balance',
  },
};

export const BUDGET_ANNUAL_MONTHS: BudgetAnnualMonthsType[] = [
  {
    number: 1,
    shortName: 'Jan',
    name: 'January',
  },
  {
    number: 2,
    shortName: 'Feb',
    name: 'February',
  },
  {
    number: 3,
    shortName: 'Mar',
    name: 'March',
  },
  {
    number: 4,
    shortName: 'Apr',
    name: 'April',
  },
  {
    number: 5,
    shortName: 'May',
    name: 'May',
  },
  {
    number: 6,
    shortName: 'Jun',
    name: 'June',
  },
  {
    number: 7,
    shortName: 'Jul',
    name: 'July',
  },
  {
    number: 8,
    shortName: 'Aug',
    name: 'August',
  },
  {
    number: 9,
    shortName: 'Sep',
    name: 'September',
  },
  {
    number: 10,
    shortName: 'Oct',
    name: 'October',
  },
  {
    number: 11,
    shortName: 'Nov',
    name: 'November',
  },
  {
    number: 12,
    shortName: 'Dec',
    name: 'December',
  },
];

export const BUDGET_ANNUAL_COPY_OPTIONS: BudgetAnnualCopyOptionsType = {
  pastMonths: 'pastMonths',
  restOfYear: 'restOfYear',
  currentMonth: 'currentMonth',
  wholeYear: 'wholeYear',
};

export const BUDGET_STICKY_TOP_HEIGHT: BudgetStickyTopHeightType = {
  rowAmountType: 46,
  rowProgressBar: 78,
};
