import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import classNames from 'classnames';
import compose from 'utils/compose';
import { v4 as uuidv4 } from 'uuid';
import { List as ImmutableList, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';

import { tracker } from 'companion-app-components/utils/core';
import { authSelectors } from 'companion-app-components/flux/auth';
import { categoriesActions } from 'companion-app-components/flux/categories';
import { scheduledTransactionsActions, scheduledTransactionsTypes, scheduledTransactionsSelectors } from 'companion-app-components/flux/scheduled-transactions';
import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { transactionListSelectors } from 'companion-app-components/flux/transaction-list';
import { ScheduledTransaction } from 'companion-app-components/flux/scheduled-transactions/scheduledTransactionsTypes';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStdForm from 'data/StdForm';
import StdFormDialog from 'components/Dialogs/StdFormDialog';
import QButton from 'components/QButton';
import ScheduleTransactionSelector, { INCOME_STEP, BILLS_STEP, ALL_STEP } from 'components/Dialogs/WfBillScout/ScheduleTransactionSelector';
import { WFBillScoutStyles as styles } from 'components/Dialogs/WfBillScout/styles';
import { getAllUpcomingTransactions } from 'data/transactions/selectors';
import TransactionSelectList from 'components/TransactionSelectList';
import SearchBox from 'components/SearchBox';
import ScheduledTransactionFormContainer from 'components/ScheduledTransactions/ScheduledTransactionFormContainer';
import { getIdsRefreshing } from 'data/institutionLogins/selectors';


const typeStdForm = 'WEBFIRST_BILL_SCOUT_WIZARD';
const INITIAL_STEP = BILLS_STEP;

const UI_SCHEDULE_TRANSACTION_SELECTOR = 'SCHEDULE_TRANSACTION_SELECTOR';
const UI_TRANSACTION_SELECTOR = 'TRANSACTION_SELECTOR';
const INITIAL_CURRENT_UI = UI_SCHEDULE_TRANSACTION_SELECTOR;

const INITIAL_SELECTED_ITEMS = ImmutableMap({
  [INCOME_STEP]: ImmutableList<string>([]),
  [BILLS_STEP]: ImmutableList<string>([]),
  [ALL_STEP]: ImmutableList<string>([]),
});

const titles = {
  [`${UI_SCHEDULE_TRANSACTION_SELECTOR}_${BILLS_STEP}`]: {
    headline: 'Let\'s start with your bills',
    caption: `Review these recurring payments we found in your transactions.
    Only select your recurring payments.`,
  },
  [`${UI_SCHEDULE_TRANSACTION_SELECTOR}_${BILLS_STEP}_review`]: {
    headline: 'Review your bills',
    caption: 'Review your bills. You can update or remove any if required',
  },
  [`${UI_TRANSACTION_SELECTOR}_${BILLS_STEP}`]: {
    headline: 'Find bills from your transactions',
    caption: 'Only choose transactions that represent recurring bills, subscriptions or transfers.',
  },
  [`${UI_TRANSACTION_SELECTOR}_${ALL_STEP}`]: {
    headline: 'Find bills/income from your transactions',
    caption: 'Only choose transactions that represent recurring bills, income, subscriptions or transfers.',
  },
  [`${UI_SCHEDULE_TRANSACTION_SELECTOR}_${INCOME_STEP}`]: {
    headline: 'Now add your income',
    caption: `Review these recurring income we found in your transactions.
    Select & add all that are your income.`,
  },
  [`${UI_SCHEDULE_TRANSACTION_SELECTOR}_${INCOME_STEP}_review`]: {
    headline: 'Review your income',
    caption: 'Review your income. You can update or remove any if required',
  },
  [`${UI_TRANSACTION_SELECTOR}_${INCOME_STEP}`]: {
    headline: 'Find income from your transactions',
    caption: 'Only choose items that represent recurring income payments (not one-time payments).',
  },
};

const trackingEvents = {
  [`${BILLS_STEP}-confirmed`]: tracker.events.confirmedBill,
  [`${BILLS_STEP}-addedNew`]: tracker.events.addedNewBill,
  [`${BILLS_STEP}-removed`]: tracker.events.removedBill,
  [`${BILLS_STEP}-edited`]: tracker.events.editedBill,
  [`${BILLS_STEP}-skip`]: tracker.events.skipBill,
  [`${INCOME_STEP}-confirmed`]: tracker.events.confirmedIncome,
  [`${INCOME_STEP}-addedNew`]: tracker.events.addedNewIncome,
  [`${INCOME_STEP}-removed`]: tracker.events.removedIncome,
  [`${INCOME_STEP}-edited`]: tracker.events.editedIncome,
  [`${INCOME_STEP}-skip`]: tracker.events.skipIncome,
};

const useStyles = makeStyles()(styles as Record<string, any>);

interface WfBillScoutProps {
  dialogId: string;
  // BillsIncomeFlow
  initialStep: string,
  singleStep: boolean,
  billsIncomeFlow: boolean,
  hideAddManually: boolean,
  addManualST: () => void;
}

const WfBillScout: FC<WfBillScoutProps> = ({
  dialogId,
  initialStep,
  singleStep,
  billsIncomeFlow,
  hideAddManually,
  addManualST,
}) => {
  const { classes } = useStyles();
  const scheduledTransactions: ImmutableMap<string, ScheduledTransaction> | null =
    useSelector(scheduledTransactionsSelectors.getScheduledTransactionsSortedByPayee);
  const allUpcomingTxns: ImmutableMap<string, transactionsTypes.CashFlowTransaction> =
    useSelector((state) => getAllUpcomingTransactions(state, scheduledTransactions));
  const institutionsRefreshing: ImmutableSet<string> = useSelector(getIdsRefreshing);
  const isWebFirstDataset: boolean = useSelector(authSelectors.getIsWebfirstDataset);

  const dispatch = useDispatch();

  const getCategories = () => dispatch(categoriesActions.getCategories());
  const dispatchUpdateScheduledTransactionAction =
    (data) => dispatch(scheduledTransactionsActions.updateScheduledTransaction(data));
  const dispatchDeleteScheduledTransactionAction =
    (data) => dispatch(scheduledTransactionsActions.deleteScheduledTransaction(data, { undo: { userMessage: 'Schedule Transaction deleted.' } }));
  const dispatchCreateBillScoutScheduleAction = (data) => dispatch(scheduledTransactionsActions.createBillScoutSchedule(data));

  const [currentUI, setcurrentUI] = useState(isWebFirstDataset ? INITIAL_CURRENT_UI : UI_TRANSACTION_SELECTOR);
  const [step, setStep] = useState(billsIncomeFlow && !isWebFirstDataset ? initialStep : INITIAL_STEP);
  const [reviewCurrentStep, setReviewCurrentStep] = useState(false);
  const [selectedScheduleTxns, setSelectedScheduleTxns] = useState(INITIAL_SELECTED_ITEMS);
  const [selectedTxns, setSelectedTxns] = useState(INITIAL_SELECTED_ITEMS);
  const [search, setSearch] = useState(undefined);
  const [editScheduledTransaction, setEditScheduledTransaction]: React.SetStateAction<any> = useState(undefined);


  useEffect(() => {
    const getScheduledTransactions = () => dispatch(scheduledTransactionsActions.getScheduledTransactions());
    if (institutionsRefreshing.isEmpty()) {
      getScheduledTransactions();
    }
  }, [institutionsRefreshing, dispatch]);

  useEffectOnce(() => {
    getCategories();
  });

  const setSelectedScheduleTxnsItems = (thisStep, list) => {
    setSelectedScheduleTxns(selectedScheduleTxns.set(thisStep, list));
  };

  const setSelectedTxnsItems = (thisStep, list) => {
    setSelectedTxns(selectedTxns.set(thisStep, list));
  };

  const openTransactionSelector = () => {
    tracker.track(tracker.events.findFromTransaction);
    setcurrentUI(UI_TRANSACTION_SELECTOR);
  };


  const closeTransactionSelector = (handleClose) => (event) => {
    // Check if it's a web first dataset to handle transactions selector, CMP-7267.
    if (isWebFirstDataset) {
      setcurrentUI(UI_SCHEDULE_TRANSACTION_SELECTOR);
    } else {
      handleClose(event, 'SKIP_BUTTON');
    }
  };

  const createEmptyScheduleTransaction = (handleClose) => {
    const clientId = uuidv4().toUpperCase();
    tracker.track(tracker.events.addManually);

    closeTransactionSelector(handleClose);
    setEditScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
      clientId,
      type: step,
    }));
  };

  const handleAddManually = ({ handleClose }) => (event) => {
    tracker.track(tracker.events.addManually);
    if (addManualST) {
      handleClose(event, 'SKIP_BUTTON');
      addManualST();
    } else {
      createEmptyScheduleTransaction(handleClose);
    }
  };

  const showNextStep = () => {
    if (step === BILLS_STEP) {
      setStep(INCOME_STEP);
      setReviewCurrentStep(false);
    }
  };

  const getSchTxnsOfStep = () => scheduledTransactions?.filter((x) => x.type === step);

  const filterTxnsByType = ({ amount }) => {
    if (step === ALL_STEP) {
      return true;
    }
    return step === INCOME_STEP ? amount > 0 : amount < 0;
  };

  const resetSearch = () => setSearch(undefined);

  const getBaseId = (id = ''): string => `WFBillScout-${currentUI}-${step}${id !== '' ? `-${id}` : ''}`;

  const getSelectedTxns = () => selectedTxns.get(step);

  const getSelectedSchTxns = () => selectedScheduleTxns.get(step);

  const submitScheduleTransactionForm = ({ id, isUserVerified }) => {
    if (id) {
      tracker.track(trackingEvents[`${step}-edited`], { number: 1 });
    } else {
      tracker.track(trackingEvents[`${step}-addedNew`], { number: 1 });
    }

    if (!isUserVerified) {
      const selectedItems = getSelectedSchTxns();
      if (selectedItems) {
        const isSelected = selectedItems.findIndex((x) => x === id) >= 0;
        if (!isSelected) {
          setSelectedScheduleTxnsItems(step, selectedItems?.push(id));
        }
      }
    }
  };

  const createAndDeleteSchTxns = (closeAction) => (event) => {
    const selectedSchTxns: ImmutableList<string> | undefined = selectedScheduleTxns.get(step);
    const schTxnOfStep = getSchTxnsOfStep();

    const schTxnToVerify = schTxnOfStep?.filter((schTxn) => !schTxn.isUserVerified && selectedSchTxns?.contains(schTxn.id!));

    // Verify Schedule Transactions
    if (schTxnToVerify && schTxnToVerify.size > 0) {
      schTxnToVerify.forEach((schTxn) => {
        dispatchUpdateScheduledTransactionAction(schTxn.merge({
          isUserVerified: true,
        }));
      });
      tracker.track(trackingEvents[`${step}-confirmed`], { number: schTxnToVerify.size });
    }

    // Clean search for next step
    resetSearch();

    // Clean selectedScheduleTxnsItems
    setSelectedScheduleTxnsItems(step, INITIAL_SELECTED_ITEMS.get(step));

    // Show review page before show next step
    if (!reviewCurrentStep) {
      setReviewCurrentStep(true);
      return;
    }

    // Show next step or close modal
    if (step === BILLS_STEP) {
      showNextStep();
    } else if (closeAction) {
      closeAction(event);
    }
  };

  const deleteScheduleTransaction = (schTxn) => {
    tracker.track(trackingEvents[`${step}-removed`], { number: 1 });
    dispatchDeleteScheduledTransactionAction(schTxn.merge({
      isDeleted: true,
    }));
  };

  const onSkip = (closeAction) => (event) => {
    tracker.track(trackingEvents[`${step}-skip`]);

    if (!reviewCurrentStep) {
      setReviewCurrentStep(true);
      return;
    }

    // Show next step or close modal
    if (step === BILLS_STEP) {
      showNextStep();
    } else if (closeAction) {
      closeAction(event);
    }
  };

  const onGoBack = () => setReviewCurrentStep(false);

  const createBillScout = (handleClose) => {
    const selectedTransactions = getSelectedTxns();

    selectedTransactions?.forEach((txnID) => {
      if (txnID) {
        const clientId = uuidv4().toUpperCase();
        dispatchCreateBillScoutScheduleAction({ clientId, id: txnID });
      }
    });

    // Clean selectedTxns
    setSelectedTxnsItems(step, INITIAL_SELECTED_ITEMS.get(step));

    closeTransactionSelector(handleClose);
    setReviewCurrentStep(true);
  };

  const checkTxnIsSelected = (id: string) => {
    const selectedItems = getSelectedTxns();
    if (selectedItems) {
      return selectedItems.findIndex((x) => x === id) >= 0;
    }
    return false;
  };


  const selectTxn = (event) => {
    const { id } = JSON.parse(event.currentTarget.id);
    const isSelected = checkTxnIsSelected(id);
    let selectedTransactions = getSelectedTxns();

    if (isSelected) {
      selectedTransactions = selectedTransactions?.filterNot((x) => x === id);
    } else {
      selectedTransactions = selectedTransactions?.push(id);
    }
    setSelectedTxnsItems(step, selectedTransactions);
  };

  const renderTitle = () => {
    const titleKey = currentUI !== UI_TRANSACTION_SELECTOR ?
      `${currentUI}_${step}${reviewCurrentStep ? '_review' : ''}` : `${currentUI}_${step}`;
    const titleData = titles[titleKey] || {};

    if (billsIncomeFlow && currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR && !reviewCurrentStep) {
      titleData.headline = step === INCOME_STEP ? 'Select your income' : 'Select the bills you pay';
    }

    return (
      <div className={classes.headline}>
        <div className={classes.headlineTitle}>{titleData.headline}</div>
        <div id={getBaseId('headline')} className={classes.headlineCaption}>{titleData.caption}</div>
      </div>
    );
  };



  const renderActions = ({ handleClose }) => {
    const selectedTransactions = getSelectedTxns();
    const selectedSchTransactions = getSelectedSchTxns();
    const stepSchTransactions = getSchTxnsOfStep()?.filter((x) => x.isUserVerified);
    const stepLabel = step === BILLS_STEP ? 'bills' : 'income';
    return (
      <div className={classes.actions}>
        {currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR && singleStep && reviewCurrentStep &&
          <QButton
            className={classes.wideButton}
            variant="contained"
            onClick={(event) => handleClose(event, 'SKIP_BUTTON')}
            id={getBaseId('action-done-button')}
          >
            Done
          </QButton>}
        {currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR && singleStep && !reviewCurrentStep &&
          <QButton
            className={classes.wideButton}
            variant="contained"
            onClick={() => setReviewCurrentStep(true)}
            id={getBaseId('action-review-button')}
          >
            Review
          </QButton>}
        {currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR && !singleStep && (
          <>
            <div className={classes.helperLine}>
              {reviewCurrentStep ?
                `${stepSchTransactions?.size} ${stepLabel} added` :
                `${selectedSchTransactions?.size} ${stepLabel} selected`}
            </div>
            {!reviewCurrentStep && (
              <QButton
                className={classes.wideButton}
                classes={{ textButton: classes.skipButton }}
                onClick={onSkip((event) => handleClose(event, 'SKIP_BUTTON'))}
                id={getBaseId('action-skip-button')}
              >
                Skip suggestions
              </QButton>
            )}
            <QButton
              className={classes.wideButton}
              classes={{ disableOther: classes.continueButton }}
              variant="contained"
              onClick={createAndDeleteSchTxns((event) => handleClose(event, 'COMPLETE_FLOW'))}
              id={getBaseId('action-continue-button')}
              disabled={!reviewCurrentStep && selectedSchTransactions?.size === 0}
            >
              {!reviewCurrentStep && 'Add to list'}
              {reviewCurrentStep && step === BILLS_STEP && 'Continue to Income'}
              {reviewCurrentStep && step === INCOME_STEP && 'Finish'}
            </QButton>
          </>
        )}
        {currentUI === UI_TRANSACTION_SELECTOR && (
          <>
            <Typography variant="body2" className={classes.manualLine}>
              {"Couldn't Find?"}
              <QButton
                className={classes.manualButton}
                id="add-manually-transaction"
                onClick={handleAddManually({ handleClose })}
              >
                ADD MANUALLY
              </QButton>
            </Typography>
            <QButton
              className={classNames(classes.wideButton, classes.backButton)}
              onClick={closeTransactionSelector(handleClose)}
              id={getBaseId('action-back-button')}
            >
              Cancel
            </QButton>
            <QButton
              className={classes.wideButton}
              classes={{ disableOther: classes.continueButton }}
              disabled={selectedTransactions?.size === 0}
              onClick={() => createBillScout(handleClose)}
              id={getBaseId('action-add-to-list-button')}
              variant="contained"
            >
              Add to List
            </QButton>
          </>
        )}
      </div>
    );
  };


  return (
    <StdFormDialog
      className={classes.dialog}
      dialogProps={{
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        fullWidth: true,
        maxWidth: 'lg',
        classes: { paperWidthMd: classes.dialog },
      }}
      showCloseButton={currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR}
      title={renderTitle()}
      renderActions={renderActions}
      dialogId={dialogId}
    >
      <>
        {currentUI === UI_SCHEDULE_TRANSACTION_SELECTOR && (
          <Grid container className={classes.body}>
            <ScheduleTransactionSelector
              scheduledTransactions={getSchTxnsOfStep()}
              allUpcomingTxns={allUpcomingTxns}
              selected={getSelectedSchTxns()}
              setSelectedItems={setSelectedScheduleTxnsItems}
              setEditScheduledTransaction={setEditScheduledTransaction}
              deleteScheduleTransaction={deleteScheduleTransaction}
              openTransactionSelector={openTransactionSelector}
              createEmptyScheduleTransaction={createEmptyScheduleTransaction}
              showFindingRecurring={!institutionsRefreshing.isEmpty()}
              type={step}
              reviewCurrentStep={reviewCurrentStep}
              baseId={getBaseId}
              hideAddManually={hideAddManually}
              onGoBack={onGoBack}
            />
          </Grid>
        )}

        {currentUI === UI_TRANSACTION_SELECTOR && (
          <Grid container className={classNames(classes.body, classes.bodyTxnSelector)}>
            <div id={getBaseId('search')} className={classes.searchBarRoot}>
              <SearchBox
                onSearch={setSearch}
                autoSearch={false}
                autoFocus
                collapsable={false}
                initialValue={search}
                placeholder="Search Transactions"
                showUnderline
                classes={{
                  root: classes.searchBar,
                  input: classes.searchInput,
                  searchIcon: classes.searchIcon,
                  cancelIcon: classes.cancelIcon,
                }}
              />
            </div>
            <TransactionSelectList
              clickFunc={selectTxn}
              highlight={getSelectedTxns()}
              filterByType={filterTxnsByType}
              filter={search}
              onReset={resetSearch}
              multiSelect
              hideCategory
              transactionListSelector={transactionListSelectors.getLesserBilledTransactions}
            />
          </Grid>
        )}
        {editScheduledTransaction &&
          <ScheduledTransactionFormContainer
            key={editScheduledTransaction ? (editScheduledTransaction.id || editScheduledTransaction.clientId) : 'noKey'}
            scheduledTransaction={editScheduledTransaction && editScheduledTransaction.toJS()}
            onClose={() => { setEditScheduledTransaction(null); }}
            onSubmit={submitScheduleTransactionForm}
            deleteUserVerificationFromPayload
            hideDeleteIcon
          />}
      </>
    </StdFormDialog>
  );
};

export default compose(
  withStdForm({ type: typeStdForm }),
)(WfBillScout);
