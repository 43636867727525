import styled from 'styled-components';

import { MyBlueButton } from '../../StyledComponents/HomePageAll';

export const HiddenInput = styled.input`
  position: fixed;
  height: 2px;
  width: 2px;
  opacity: 0;
`;

export const ImageDiv = styled.img`
  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
  cursor: pointer;
  margin: auto;
  display: block;
`;

export const ThumbDiv = styled.div`
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-top: 10px;
  margin-right: 5px;
  &.selected {
    box-shadow: ${({ theme }) => theme.palette.color6.opacity100} 0px 0px 3px;
  }
  img {
    max-width: 50px;
    max-height: 50px;
    &.show {
      display: block;
    }
    &.hide {
      display: none;
    }
  }
  cursor: pointer;
`;

export const ViewPort = styled.div`
  overflow: scroll;
  height: 415px;
`;

export const Thumbs = styled.div`
  padding: 15px;
  padding-top: 0px;
  width: 80%;
`;

export const DelButton = styled(MyBlueButton)`
  position: absolute;
  right: 10px;
  margin-top: 10px;
`;

export const SubTitle = styled.p`
  font-size: 12px;
  text-align: center;
`;

