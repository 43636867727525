
import React, { useState, useMemo, FC, Fragment, ReactNode } from 'react';
import { Set, List } from 'immutable';
import { makeStyles } from 'tss-react/mui';
import { useSelector, shallowEqual } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';

import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { chartOfAccountsUtils } from 'companion-app-components/flux/chart-of-accounts';

import QCard from 'components/QCard';
import QButton from 'components/QButton';
import QCloseBox from 'components/QCloseBox';

import { getPredefinedRange } from 'utils/date/utils';
import { getPopularCatsMonthlyAverage } from 'data/transactions/selectors';

import SuggestedBudgetItem from './SuggestedBudgetItem';
import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface TitleHeaderProps {
  classes: Record<string, any>;
}

export const TitleHeader: FC<TitleHeaderProps> = ({ classes }) => (
  <div
    className={classes.header}
  >
    <Typography
      variant="h5"
      className={classes.title}
    >
      Choose from your most used categories
    </Typography>
    <Typography
      variant="body1"
    >
      Select a category to add to your budget.  An average amount from your previous transactions
      will be entered on your behalf.  After you select the items and save them, you can change the default amounts
      on the main edit screen.
    </Typography>
  </div>
);

interface CategorySelectionListProps {
  classes: Record<string, any>;
  expenseNodes: ReactNode[];
  incomeNodes: ReactNode[];
}

export const CategorySelectionList: FC<CategorySelectionListProps> = ({ classes, expenseNodes, incomeNodes }) => (
  <div style={{ overflowY: 'scroll' }}>
    <Typography
      variant="subtitle1"
      className={classes.sectionHeader}
    >
      INCOME
    </Typography>
    {incomeNodes}

    <Typography
      variant="subtitle1"
      className={classes.sectionHeader}
    >
      EXPENSES
    </Typography>
    {expenseNodes}
  </div>
);

interface BudgetSuggestedCategoriesProps {
  onAddItems: (cb: () => void) => void;
  onClose: () => void;
  accountIds: List<string> | null;
  exclusionSet: Record<string, any>;
  currency: string;
}

const qCards = {
  firstCard: {
    elementId: 'suggested-categories-checkbox-Category',
    edge: 'left',
    width: 284,
    height: 200,
    next: 'secondCard',
    title: 'Select All Categories',
    content: 'If you want to start from your most regularly used categories, click here to select all categories.  You can ' +
    'then de-select any that you do not want to include.',
  },
  secondCard: {
    elementId: 'cat-row-container: 0',
    edge: 'right',
    width: 284,
    height: 200,
    prev: 'firstCard',
    title: 'Suggested Amounts',
    content: 'The suggested amount is based on your past spending patterns.  Once you add the item, you will be ' +
      'able to edit the amounts on the main screen.',
  },
};

const MAX_LIST_SIZE = 20;

// @ts-expect-error: Only a void function can be called with the 'new' keyword.
const BudgetSuggestedCategories: FC<BudgetSuggestedCategoriesProps> = ({ onAddItems, onClose, accountIds, currency, exclusionSet = new Set() }) => {

  // @ts-expect-error: Only a void function can be called with the 'new' keyword.
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [qCardsOn, setQCardsOn] = useState(true);

  const popularCatsMonthlyAverage = useSelector((state) =>
    getPopularCatsMonthlyAverage(state,
      { dateRange: getPredefinedRange({ value: '12_MONTHS_PRIOR' }), sortByAmount: true }), shallowEqual);

  const popularCatsList = useMemo(() => popularCatsMonthlyAverage.filter((x) =>
    !exclusionSet.find((xcoa) => chartOfAccountsUtils.coasAreEqual(xcoa, x.coa))).slice(0, MAX_LIST_SIZE), [exclusionSet, popularCatsMonthlyAverage]);

  const incomeCats = popularCatsList.filter((x) => categoriesSelectors.isIncomeCat(null, x.coa.id));
  const expenseCats = popularCatsList.filter((x) => !categoriesSelectors.isIncomeCat(null, x.coa.id));

  const { classes } = useStyles();

  const showHideItem = (id) => {

    const newSelectedItems = selectedItems.has(id) ? selectedItems.delete(id) : selectedItems.add(id);
    setSelectedItems(newSelectedItems);
  };

  const selectUnselectAll = () => {
    if (selectedItems.size === popularCatsList.size) {
      // @ts-expect-error: Only a void function can be called with the 'new' keyword.
      setSelectedItems(new Set());
    } else {
      // @ts-expect-error: Only a void function can be called with the 'new' keyword.
      setSelectedItems(new Set(popularCatsList.map((x) => x.coa.id)));
    }
  };

  const buildAddListFromIds = () => selectedItems.map((id) => {

    const catId = id;
    const node = popularCatsList.find((x) => x.coa.id === id);

    return (
      {
        coa: { type: 'CATEGORY', id: catId },
        amount: node ? Math.abs(node.monthlyAverage) : 0,
      });
  });

  const getInnerNodes = (name, nodes) => {

    const localNodeList: ReactNode[] = [];
    nodes.forEach((node, index) => {

      localNodeList.push(
        <Fragment key={`budgetNode:${name}:${node.key || (node.coa && node.coa.id)}`}>
          <div id={`cat-row-container: ${index}`}>
            <SuggestedBudgetItem
              item={node}
              selected={selectedItems.has(node.key || node.coa.id)}
              onChange={showHideItem}
              accountIds={accountIds}
              currency={currency}
            />
          </div>
        </Fragment>,
      );
    });
    return localNodeList;
  };

  return (
    <Dialog
      maxWidth="md"
      open
      onClose={onClose}
      classes={{ paperWidthMd: classes.dialogRoot }}
    >
      <QCard
        open={qCardsOn}
        onClose={() => setQCardsOn(false)}
        cards={qCards}
        name="WFBudgetSetupSuggestedCats"
        initialCard="firstCard"
        classes={{ qcard: classes.qCard }}
      />
      <QCloseBox onClose={onClose} id="choose-budget-close-button" />

      <TitleHeader classes={classes} />
      <Divider classes={{ root: classes.dividerRoot }} />

      <SuggestedBudgetItem
        header
        selected={selectedItems.size === popularCatsList.size}
        onChange={selectUnselectAll}
        classesExternal={{ categoryRow: classes.headerRow }}
      />

      <CategorySelectionList
        classes={classes}
        incomeNodes={getInnerNodes('income', incomeCats)}
        expenseNodes={getInnerNodes('expense', expenseCats)}
      />

      <DialogActions className={classes.dialogActions}>
        <QButton onClick={onClose} id="choose-budget-cancel">
          Cancel
        </QButton>
        <QButton
          disabled={selectedItems.size === 0}
          onClick={() => onAddItems(buildAddListFromIds())}
          id="add-items-to-budget-button"
        >
          {`Add ${selectedItems.size} Items to Budget` }
        </QButton>
      </DialogActions>

    </Dialog>
  );
};

export default BudgetSuggestedCategories;

