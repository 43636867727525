import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';

import compose from 'utils/compose';
import { removeDialog } from 'data/rootUi/actions';
import ReleaseNotesView from './ReleaseNotesView';
import styles from './styles';

const mapDispatchToProps = (dispatch) => ({
  removeDialog: () => dispatch(removeDialog()),
});

export default compose(
  withTheme,
  connect(null, mapDispatchToProps)
)(withStyles(ReleaseNotesView, styles));
