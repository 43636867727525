import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import { categoriesSelectors } from 'companion-app-components/flux/categories';

import QIconButton from 'components/QIconButton';

import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';


// STYLES HOOK
import { styles } from '../styles';
const useStyles = makeStyles()(styles);

const FilterItemList = (props) => {
  const { title, items, getNameFromItem, onRemoveItem, itemKey } = props;
  const { classes } = useStyles();

  const categoriesById = useSelector(categoriesSelectors.getCategoriesById);
  const filteredItems = useMemo(() => {
    if (itemKey !== 'categories') return items;

    return items.filter((catId) => !categoriesById.get(catId)?.parentId || !items.has(categoriesById.get(catId)?.parentId));
  }, [itemKey, items, categoriesById]);

  const handleRemove = (item) => (_e) => {
    onRemoveItem(itemKey, item);
  };

  if (filteredItems.size === 0 || filteredItems.length === 0) return null;
  return (
    <div>
      <Typography variant={'subtitle2'} className={classes.fliHeader}>{title}</Typography>
      {filteredItems.map((item) => (
        <div key={`filter-item-list-${title}-${item}`} className={classes.fliItem}>
          <Typography
            className={classes.fliLabel}
            variant={'caption'}
            id={`filter-item-list-${title}-${getNameFromItem(itemKey, item)}`}
          >
            {getNameFromItem(itemKey, item)}
          </Typography>
          <QIconButton
            size={'small'}
            className={classes.fliRemove}
            id={`filter-item-list-${title}-${getNameFromItem(itemKey, item)}-remove`}
            onClick={handleRemove(item)}
          >
            <ClearIcon />
          </QIconButton>
        </div>
      ))}
    </div>
  );
};

FilterItemList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  getNameFromItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  itemKey: PropTypes.string,
};

export default FilterItemList;
