
import { createAction } from 'utils/actionHelpers';

export const getBiller = createAction('BILLER_GET_REQUEST');
export const getBillerSuccess = createAction('BILLER_GET_SUCCESS');
export const getBillerFailure = createAction('BILLER_GET_FAILURE');

export const getBillerLogo = createAction('BILLER_GET_LOGO');
export const getBillerLogoResponse = createAction('BILLER_GET_LOGO_RESPONSE');
export const getBillerLogoError = createAction('BILLER_LOGO_FAILURE');

export const getPopularBillersAction = createAction('GET_POPULAR_BILLERS_REQUEST');
export const getPopularBillersSuccess = createAction('GET_POPULAR_BILLERS_SUCCESS');
export const getPopularBillersFailure = createAction('GET_POPULAR_BILLERS_FAILURE');

export const searchForBillers = createAction('SEARCH_FOR_BILLERS');
export const searchForBillersSuccess = createAction('SEARCH_FOR_BILLERS_SUCCESS');
export const searchForBillersFailure = createAction('SEARCH_FOR_BILLERS_FAILURE');


