import { takeEvery } from 'redux-saga/effects';

import { resourceStoreTypes, resourceSagaUtils } from 'companion-app-components/flux/core';

import * as actions from './feedbackActions';

export function* sendFeedbackActionWatcher() {
  const config = resourceStoreTypes.mkQcsSyncResourceConfig({
    resourceName: 'feedback',
    resourceBaseUrl: '/feedback',
    successAction: actions.sendFeedbackSuccess,
    failureAction: actions.sendFeedbackFailure,
    axiosConfig: {
      headers: {
        Authorization: null,
      },
    },
  });
  yield takeEvery(actions.sendFeedback, resourceSagaUtils.qcsSyncCreateResource, config);
}

// ====================================================
// EXPORTS

export default [
  sendFeedbackActionWatcher,
];
