import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map as ImmutableMap } from 'immutable';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import RootState from 'companion-app-components/utils/redux-store/rootState';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { authActions, authSelectors } from 'companion-app-components/flux/auth';
import { profileSelectors } from 'companion-app-components/flux/profile';
import { getBuildConfig, getLogger, localPreferences } from 'companion-app-components/utils/core';

import {
  createDialog as createDialogAction,
  updateDialogProps as updateDialogPropsAction,
} from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';

import {
  DIALOG_TYPE as DIALOG_CONFIRMATION,
  mkConfirmationDialogProps,
} from 'components/Dialogs/ConfirmationDialog';
import useQPreferences from 'components/QPreferences/useQPreferences';
import QHelp from 'components/QHelp';
import { safeRefIn } from 'utils/utils';
import compose from 'utils/compose';
import VersionDisplay from './versionDisplay';

const log = getLogger('components/Main/index.tsx');

export const InactivityCountdown = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;

let versionWarningGiven = false;

export function resetVersionWarning() {
  versionWarningGiven = false;
}

function meetsMinimumVersionReqs(v1, minVer) {
  // prevent a crash, tell user to fix their profile :-)
  if (!v1 || !minVer) {
    return false;
  }

  const v1Parts = v1.split('.');
  const v2Parts = minVer.split('.');
  let retVal;
  let index = 0;

  while (index < v1Parts.length && retVal === undefined) {
    if (Number(v1Parts[index]) !== Number(v2Parts[index])) {
      retVal = Number(v1Parts[index]) > Number(v2Parts[index]);
    }
    index += 1;
  }
  // equality (still undefined) means strings are equal, and requirements are met
  return retVal === undefined ? true : retVal;
}

const inactivityTimeoutInSeconds = getBuildConfig()?.inactivity_timeout_in_seconds || 900;
const inactivityCountdownTimeoutInSeconds: number = getBuildConfig()?.inactivity_countdown_timeout_in_seconds || 30;
let inactivityExpirationTime: null | DateTime = null;
let inactivityExpirationChecker: null | ReturnType<typeof setInterval> = null;
let inactivityCountdownUpdater: null | ReturnType<typeof setInterval> = null;
let inactivityCountdownValue = -1;
let inactivityCountdownDialogId: null | string = null;

interface MainProps {
  children: React.ReactNode;
  // from QHelp
  showQHelp: any;
}

const Main: React.FC<MainProps> = (props) => {
  const { datasetPreferences, setDatasetPreference } = useQPreferences();
  const isLoggedIn = useSelector((state: RootState) => authSelectors.getAuthSession(state).accessToken);
  const profile = useSelector((state: RootState) => profileSelectors.getProfile(state));
  const featureFlags = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state));
  const checkDesktopMinVersion = featureFlags.get('checkDesktopMinVersion');
  const minWindowsBuildNumber = featureFlags.get('minWindowsBuildNumber');
  const minMacReleaseNumber = featureFlags.get('minMacReleaseNumber');

  const dispatch = useDispatch();
  const authLogout = (data, meta) => dispatch(authActions.authLogout(data, meta));
  const createDialog = (rootUiData) => dispatch(createDialogAction(rootUiData));
  const updateDialogProps = (dialogProps) => dispatch(updateDialogPropsAction(dialogProps));

  const inKioskmode = datasetPreferences.kioskmode;

  // this is a kiosk hack, if you add ?kioskmode to the URL, it will disable the
  // inactivity timeout, but only for that app session (module existence).  If this module
  // is reloaded (i.e. refresh page) and kioskmode is not in the URL at that point, it will
  // re-enable the timeout.  Currently the app does not preserve arbitrary url parms across pages.
  const queryObj = queryString.parse(window.location.search);
  if ('kioskmode' in queryObj) {
    setDatasetPreference({ kioskmode: Boolean(queryObj.kioskmode === 'enabled') });
    log.log(`Setting Kiosk Mode to ${String(queryObj.kioskmode === 'enabled')}`);
  }
  log.log(`Kiosk Mode is ${String(queryObj.kisokmode === 'enabled')}`);

  const logout = (reason, context) => {
    authLogout({ reason }, { context });
  };

  const getContentForInactivityCountdownDialog = () => (
    <DialogContent>
      <Typography variant="subtitle1">
        To ensure security of your session please confirm that you want to continue or you will be logged out automatically.
      </Typography>
      <InactivityCountdown>
        {inactivityCountdownValue}
      </InactivityCountdown>
    </DialogContent>
  );

  // Handle situation where user has already expired but this wasn't detected during our normal checks. This
  // is mostly likely to occur when user puts their system into hibernate mode and then comes back to it.
  const checkIfAlreadyExpired = (now) => {
    if (inactivityExpirationTime
      && now > inactivityExpirationTime
      && now > inactivityExpirationTime.plus({ seconds: inactivityCountdownTimeoutInSeconds })
    ) {
      log.log('Already past expiration and countdown time, so logging out...');
      logout('AUTH_LOGOUT_INACTIVE', 'checkIfAlreadyExpired');
      return true;
    }
    return false;
  };

  const stopInactivityChecker = () => {
    if (inactivityExpirationChecker !== null) {
      clearInterval(inactivityExpirationChecker);
      inactivityExpirationChecker = null;

      inactivityExpirationTime = null;
    }
  };

  const stopInactivityCountdown = () => {
    if (inactivityCountdownUpdater) {
      clearInterval(inactivityCountdownUpdater);
      inactivityCountdownUpdater = null;

      inactivityCountdownValue = -1;
      inactivityCountdownDialogId = null;
    }
  };

  const inactivityCountdownContinueAction = () => {
    stopInactivityCountdown();
    startOrResetInactivityChecker(); // eslint-disable-line
  };

  const inactivityCountdownLogoutAction = () => {
    logout('AUTH_LOGOUT_USER_INITIATED', 'inactivityCountdownLogoutAction');
  };

  const startInactivityCountdown = () => {
    inactivityCountdownValue = inactivityCountdownTimeoutInSeconds;
    inactivityCountdownUpdater = setInterval(() => {
      if (checkIfAlreadyExpired(DateTime.local())) {
        return;
      }
      if (inactivityCountdownValue <= 0) {
        logout('AUTH_LOGOUT_INACTIVE', 'inactivityCountdownValue');
      } else {
        inactivityCountdownValue -= 1;
        updateDialogProps({
          id: inactivityCountdownDialogId,
          props: ImmutableMap({ content: getContentForInactivityCountdownDialog() }),
        });
      }
    }, 1000);

    inactivityCountdownDialogId = uuidv4();
    createDialog(mkRootUiData({
      id: inactivityCountdownDialogId || '0',
      type: DIALOG_CONFIRMATION,
      allowNesting: true,
      props: ImmutableMap(mkConfirmationDialogProps({
        title: 'Session Inactivity',
        content: getContentForInactivityCountdownDialog(),
        confirmLabel: 'Continue',
        confirmAction: inactivityCountdownContinueAction,
        denyLabel: 'Logout',
        denyAction: inactivityCountdownLogoutAction,
        dialogCloseAliasAction: 'CONFIRM',
      })),
    }));
  };

  const startOrResetInactivityChecker = () => {
    const now = DateTime.local();
    if (checkIfAlreadyExpired(now)) {
      return;
    }

    inactivityExpirationTime = now.plus({ seconds: inactivityTimeoutInSeconds });
    if (inactivityExpirationChecker === null) {
      inactivityExpirationChecker = setInterval(() => {
        if (!inactivityCountdownUpdater) {
          const nowInInactivityTimeoutChecker = DateTime.local();
          if (inactivityExpirationTime && nowInInactivityTimeoutChecker >= inactivityExpirationTime) {
            startInactivityCountdown();
          }
        }
      }, 1000);
    }
  };

  const handleEvent = () => {
    if (inactivityExpirationChecker !== null && inactivityCountdownUpdater === null) {
      startOrResetInactivityChecker();
    }
  };

  if (!inKioskmode && isLoggedIn && inactivityExpirationChecker === null && !localPreferences.getKeepLoggedIn()) {
    startOrResetInactivityChecker();
  } else if ((inKioskmode || !isLoggedIn) && inactivityExpirationChecker !== null) {
    stopInactivityCountdown();
    stopInactivityChecker();
  }

  React.useEffect(() => {
    // Just once check to see if the version of the desktop version being used (for companion app) is
    // at the minumum version number supported for Mac and Windows.
    if (profile && !versionWarningGiven && checkDesktopMinVersion) {
      if ('product' in profile) {
        const clientType = safeRefIn(profile, ['product', 'clientType']);
        let yourBuildNumber: string;
        let minBuildNumber: string;
        let numberField: string;
        let skipWarning: boolean = false;

        if (clientType === 'MAC') {
          numberField = 'releaseNumber';
          yourBuildNumber = safeRefIn(profile, ['product', numberField]);
          minBuildNumber = minMacReleaseNumber;

          const fields = yourBuildNumber.split('.');
          if (Number(fields[0]) === 0) {
            skipWarning = true;
          }
        } else {
          numberField = 'buildNumber';
          yourBuildNumber = safeRefIn(profile, ['product', numberField]);
          minBuildNumber = minWindowsBuildNumber;
          // this sucks, windows build numbers are nonsensical, and I just have to hack through them
          // if 2nd or 3rd field is zero, this is either a beta or dev build
          const fields = yourBuildNumber.split('.');
          if (Number(fields[1]) === 0 || Number(fields[2]) === 0) {
            skipWarning = true;
          }
        }

        const giveWarning = !skipWarning &&
          !meetsMinimumVersionReqs(yourBuildNumber, minBuildNumber);

        log.log(`CHECKING DESKTOP VERSION .${clientType}. `, minBuildNumber, yourBuildNumber);

        if (giveWarning) {
          props.showQHelp('upgradeVersion', { ...safeRefIn(profile, ['product']), minBuildNumber });
          versionWarningGiven = true;
        }
      }
    }
  }, [props, profile, checkDesktopMinVersion, minWindowsBuildNumber, minMacReleaseNumber]);

  return (
    <div id="main" onMouseOver={handleEvent} onFocus={handleEvent}>
      {props.children}
      <VersionDisplay />
    </div>
  );
};

export default compose(
  QHelp(),
)(React.memo(Main));
