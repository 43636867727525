/**
 * Asynchronously loads the component for NotFoundPage
 */
import * as React from 'react';
import LoadingView from 'components/LoadingView';
import retryPromise from 'utils/retryPromise';

const Component = React.lazy(() => retryPromise(() => import('./index')));

const loadable = (props) =>
  <React.Suspense fallback={<LoadingView />}>
    <Component {...props} />
  </React.Suspense>;

export default loadable;
