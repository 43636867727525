import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';
import { formatNumber } from 'components/QuickenControls/AmountField';
import QTypography from '../MUIWrappers/QTypography';

const useStyles = makeStyles()(
  (theme, { height, color, widthPercent, backgroundColor, noRoundCorners }) => ({
    valueBarStart: {
      width: 0,
      height,
      backgroundColor: color,
      transition: 'width 0.5s ease',
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.greyScaleDeprecated[7],
    },
    valueBar: {
      backgroundColor: color,
      transition: 'width 0.5s ease',
      width: `${widthPercent}%`,
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.greyScaleDeprecated[7],
    },
    totalBar: {
      height,
      backgroundColor,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      borderRadius: noRoundCorners ? 0 : 4,
      position: 'relative',
    },
    rest: {
      height,
      width: `${100 - widthPercent}%`,
      visibility: 'none',
    },
  })
);

function QProgressBar(props) {
  const { id, value, total, height, classNameOuter, classNameInner, backgroundColor, color, shouldAnimate, noRoundCorners, text, tooltip } = props;

  const [firstRender, setFirstRender] = useState(shouldAnimate);
  useLayoutEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let widthPercent = 0;
  const safeValue = !Number.isNaN(value) ? value : 0;
  const safeTotal = Number.isNaN(total) || total === 0 ? 1 : total;
  if (safeValue > 0 && safeValue > 0 && (Math.abs(safeValue) < Math.abs(safeTotal))) {
    widthPercent = (Math.abs(safeValue) / Math.abs(safeTotal)) * 100;
  }
  if ((Math.abs(safeValue) >= Math.abs(safeTotal))) {
    widthPercent = 100;
  }

  const { classes, cx } = useStyles({ widthPercent, height, backgroundColor, color, noRoundCorners });


  return (
    <div sharedcomponentid={'Q_PROGRESS_BAR'} className={cx(classes.totalBar, classNameOuter)} id={id}>
      {tooltip ?
        <>
          <Tooltip
            title={`${formatNumber(parseFloat(Math.abs(safeValue)), 'USD', '0,0.00')} spent`}
            placement={'top'}
            arrow
          >
            <div className={cx(firstRender ? classes.valueBarStart : classes.valueBar, classNameInner)}>
              <QTypography thinFont>
                {text}
              </QTypography>
            </div>
          </Tooltip>
          <Tooltip
            title={`${formatNumber(parseFloat(Math.abs(total - safeValue)), 'USD', '0,0.00')} left`}
            placement={'top'}
            arrow
          >
            <div className={classes.rest} />
          </Tooltip>
        </>
        :
        <>
          <div className={cx(firstRender ? classes.valueBarStart : classes.valueBar, classNameInner)}>
            <QTypography thinFont>
              {text}
            </QTypography>
          </div>
          <div className={classes.rest} />
        </>}
    </div>
  );
}

QProgressBar.defaultProps = {
  shouldAnimate: true,
  noRoundCorners: false,
  text: '',
  height: 24,
  color: '#5EBFEA',
  backgroundColor: '#91E5C5',
  tooltip: true,
};

QProgressBar.propTypes = {
  value: PropTypes.number,
  total: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  classNameOuter: PropTypes.string,
  classNameInner: PropTypes.string,
  id: PropTypes.string,
  noRoundCorners: PropTypes.bool,
  shouldAnimate: PropTypes.bool,
  text: PropTypes.string,
  tooltip: PropTypes.bool,
};

export default QProgressBar;
