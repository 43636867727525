
import React, { FC, useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';

import { categoriesSelectors } from 'companion-app-components/flux/categories';

import BudgetSummaryItem from './budgetSummaryItem';

const calculateSummaryTotals = (budgetItems) => {
  const newSummary = { income: 0, expenses: 0, balance: 0 };
  budgetItems.forEach((item) => {
    switch (item.type) {
      case 'GROUP':
        if (item.amount > 0) {
          newSummary.income += Number(item.amount);
        } else {
          newSummary.expenses += Number(item.amount);
        }
        break;

      default:
        if (item.coa && item.coa.type === 'CATEGORY' && categoriesSelectors.isIncomeCat(null, item.coa.id)) {
          newSummary.income += Number(item.amount);
        } else {
          newSummary.expenses += Number(item.amount);
        }
        break;
    }
  });
  newSummary.balance = newSummary.income + newSummary.expenses;
  return newSummary;
};

interface BudgetSummaryProps {
  classes: Record<string, any>;
  budgetItems: Record<string, any>;
  currency: string;
}

const BudgetSummary: FC<BudgetSummaryProps> = ({ budgetItems, classes, currency = 'USD' }) => {

  const [summaryTotals, setSummaryTotals] = useState({ income: 0, expenses: 0, balance: 0 });

  useEffect(() => {
    setSummaryTotals(calculateSummaryTotals(budgetItems));
  }, [budgetItems]);

  return (
    <div
      className={classes.budgetSummary}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Typography
          variant="subtitle1"
          className={classes.summaryHeader}
        >
          BUDGET SUMMARY
        </Typography>
        <BudgetSummaryItem
          classes={classes}
          title="INCOME"
          currency={currency}
          amount={summaryTotals.income}
          disableColor
        />
        <BudgetSummaryItem
          classes={classes}
          title="EXPENSES"
          currency={currency}
          amount={summaryTotals.expenses}
        />
        <BudgetSummaryItem
          classes={classes}
          title="SAVINGS"
          currency={currency}
          amount={summaryTotals.balance}
        />
      </div>
    </div>
  );
};

export default BudgetSummary;
