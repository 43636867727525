import React, { Component } from 'react';

// import { getLogger } from 'utils/logger';
// const log = getLogger('components/QDom/index.js');

// QDom Component
//
// Used to add specific DOM related capabilities and for browser compatibility
//

export default () => (WrappedComponent) => {

  class QDom extends Component {

    scrollIntoViewIfNeeded = (el, centerIfNeeded = false) => {

      if (el && !el.scrollIntoViewIfNeeded) {
        /* eslint-disable no-mixed-operators */
        const parent = el.parentNode;
        const parentComputedStyle = window.getComputedStyle(parent, null);
        const parentBorderTopWidth = Number(parentComputedStyle.getPropertyValue('border-top-width'));
        const parentBorderLeftWidth = Number(parentComputedStyle.getPropertyValue('border-left-width'));
        const overTop = el.offsetTop - parent.offsetTop < parent.scrollTop;
        const overBottom = (el.offsetTop - parent.offsetTop + el.clientHeight - parentBorderTopWidth) > (parent.scrollTop + parent.clientHeight);
        const overLeft = el.offsetLeft - parent.offsetLeft < parent.scrollLeft;
        const overRight = (el.offsetLeft - parent.offsetLeft + el.clientWidth - parentBorderLeftWidth) > (parent.scrollLeft + parent.clientWidth);
        const alignWithTop = overTop && !overBottom;

        if ((overTop || overBottom) && centerIfNeeded) {
          parent.scrollTop = el.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + el.clientHeight / 2;
        }

        if ((overLeft || overRight) && centerIfNeeded) {
          parent.scrollLeft = el.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + el.clientWidth / 2;
        }

        if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
          this.scrollIntoView(alignWithTop);
        }
      } else if (el) {
        el.scrollIntoViewIfNeeded();
      }
    };

    render() {

      const qDomProps = {
        scrollIntoViewIfNeeded: this.scrollIntoViewIfNeeded,
      };

      // wrapped component with its props, the state from HOC and uiStateProps
      return (
        <WrappedComponent
          {...{
            ...this.props,
            ...qDomProps,
          }}
        />
      );
    }
  }

  // the HOC itself is wrapped in connect
  return QDom;
};
