// @flow
import React from 'react';
import SnackbarContent from '@mui/material/SnackbarContent';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { isAcme } from 'isAcme';
import type { MCRuleMessage } from 'data/mcRules/mcRulesTypes';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { showMCPDialog } from 'components/Dialogs/MCPDialog/mcpDialogActions';
import * as mcRulesActions from 'data/mcRules/mcRulesActions';

const useStyles = makeStyles()((theme) => ({
  content: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    marginRight: 16,
  },
}));

type Props = {
  message: MCRuleMessage,
  action: any,
  onClose: () => void,
}

const MCToastSnackbarContent = React.forwardRef((props: Props, ref: any) => {
  const { onClose, message, action, ...other } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  return (
    <SnackbarContent
      ref={ref}
      className={classes.content}
      aria-describedby="mc-toast-snackbar"
      onClick={() => {
        dispatch(showMCPDialog(message.id));
        if (onClose) {
          onClose();
        }
      }}
      message={
        <span id="mc-toast-snackbar">
          <InfoIcon className={classes.icon} />
          {`Message from ${isAcme ? 'Simplifi' : 'Quicken'}`}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={(event) => {
            dispatch(mcRulesActions.mcMessagesTrack({
              id: message.id,
              isRemindMeLater: message.canUserDismiss ? true : undefined,
              reDisplayCount: message.reDisplayCount,
              ruleContext: message.ruleContext,
            }));
            event.stopPropagation();
            if (onClose) {
              onClose();
            }
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>,
      ]}
      {...other}
    />
  );
});

export default MCToastSnackbarContent;
