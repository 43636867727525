import styled from 'styled-components';

export const BlueButton = styled.button`
  color: white;
  background-color: #147cc6;
  border-radius: 4px;
  border: none;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 14px;
  height: 45px;
  width: 100%;
  &:hover {
     background-color: #0061A0;
   }
  &:active {
     background-color: #005190;
   }
  &:focus {
    box-shadow: #555 0px 0px 4px;
   }
    
   `;

export const BackButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
`;

export const SmallWhiteButton = styled.button`
  color: #147cc6;
  background-color: white;
  border-radius: 4px;
  border: solid 1px #147cc6;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 14px;
  height: 45px;
  width: 100px;
  margin-top: 20px;
  &:focus {
    box-shadow: #555 0px 0px 4px;
   }
`;

export const LargeWhiteButton = styled.button`
  color: #147cc6;
  background-color: white;
  border-radius: 4px;
  border: solid 1px #147cc6;
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 14px;
  height: 45px;
  width: 100%;
  &:focus {
    box-shadow: #555 0px 0px 4px;
   }
`;

