// @flow
import { DateTime } from 'luxon';
import { tracker, localPreferences } from 'companion-app-components/utils/core';

// pwa_data
//   uses
//   lastUsedOn
//   installLastPromptedOn
//   installPromptCount
//   installPromptDisabled
//   pushNotificationsLastPromptedOn
//   pushNotificationsPromptCount
//   pushNotificationsDisabled

const pwaDataKey = 'pwa_data';
let pwaData = null;

const usesBeforeInstallPrompt = 5;
const daysBetweenInstallPrompt = 13;
const countWhenInstallPromptCanBeDisabled = 3;

const usesBeforePushNotificationsPrompt = 7;
const daysBetweenPushNotificationsPrompt = 17;
const countWhenPushNotificationsPromptCanBeDisabled = 3;

let activePwaNotification = null;

const getPwaData = () => {
  if (!pwaData) {
    pwaData = localPreferences.getItem(pwaDataKey);
    if (pwaData) {
      pwaData.lastUsedOn = pwaData.lastUsedOn ? DateTime.fromISO(pwaData.lastUsedOn) : null;
      pwaData.installLastPromptedOn = pwaData.installLastPromptedOn ? DateTime.fromISO(pwaData.installLastPromptedOn) : null;
      pwaData.installPromptCount = pwaData.installPromptCount ? pwaData.installPromptCount : 0;
      pwaData.pushNotificationsLastPromptedOn = pwaData.pushNotificationsLastPromptedOn ? DateTime.fromISO(pwaData.pushNotificationsLastPromptedOn) : null;
      pwaData.pushNotificationsPromptCount = pwaData.pushNotificationsPromptCount ? pwaData.pushNotificationsPromptCount : 0;
    } else {
      setPwaData({
        uses: 1,
        lastUsedOn: DateTime.local(),
        installPromptCount: 0,
        pushNotificationsPromptCount: 0,
      });
    }
  }
  return pwaData;
};

const setPwaData = (data) => {
  // cache data
  pwaData = data;

  const toISODate = (date) => date ? date.toISODate() : undefined;
  localPreferences.setItem(
    pwaDataKey,
    {
      uses: data.uses,
      lastUsedOn: toISODate(data.lastUsedOn),
      installLastPromptedOn: toISODate(data.installLastPromptedOn),
      installPromptCount: data.installPromptCount,
      installPromptDisabled: data.installPromptDisabled,
      pushNotificationsLastPromptedOn: toISODate(data.pushNotificationsLastPromptedOn),
      pushNotificationsPromptCount: data.pushNotificationsPromptCount,
      pushNotificationsPromptDisabled: data.pushNotificationsPromptDisabled,
    },
  );
};

const getToday = () => DateTime.local().startOf('days');

export const getActivePwaNotification = () => activePwaNotification;
export const setActivePwaNotification = (value) => { activePwaNotification = value; };

export const trackUse = () => {
  const data = getPwaData();
  let { uses, lastUsedOn } = data;

  lastUsedOn = DateTime.fromISO(lastUsedOn);
  const today = getToday();

  if (today.diff(lastUsedOn, 'days').days > 0) {
    uses += 1;
    setPwaData({
      ...data,
      uses: uses + 1,
      lastUsedOn: today,
    });
  }
};

export const shouldUserBePromptedToInstall = () => {
  const { uses, installLastPromptedOn, installPromptDisabled } = getPwaData();
  const today = getToday();

  return !installPromptDisabled
    && uses >= usesBeforeInstallPrompt
    && (!installLastPromptedOn || today.diff(installLastPromptedOn, 'days').days >= daysBetweenInstallPrompt);
};

export const allowInstallPromptToBeDisabled = () => {
  const { installPromptCount } = getPwaData();
  return installPromptCount >= countWhenInstallPromptCanBeDisabled;
};

export const trackInstallPrompted = () => {
  const data = getPwaData();
  tracker.track(tracker.events.pwaAlertShown, { count: data.pwaInstallPrompted });

  setPwaData({
    ...data,
    installLastPromptedOn: DateTime.local(),
    installPromptCount: data.installPromptCount + 1,
  });
};

export const trackInstallDismissed = (disable = false) => {
  const data = getPwaData();
  tracker.track(tracker.events.pwaInstallResponse, { choice: 'dismissed', count: data.installPromptCount, disabled: disable });

  if (disable) {
    setPwaData({
      ...data,
      installPromptDisabled: true,
    });
  }
};

export const trackInstall = () => {
  const data = getPwaData();
  tracker.track(tracker.events.pwaInstallResponse, { choice: 'accepted', count: data.installPromptCount });
};

export const trackBrowserInstall = (enabled) => {
  const data = getPwaData();
  if (enabled) {
    tracker.track(tracker.events.pwaInstallBrowserResponse, { choice: 'accepted', count: data.installPromptCount });
  } else {
    tracker.track(tracker.events.pwaInstallBrowserResponse, { choice: 'dismissed', count: data.installPromptCount, disabled: true });
  }
};

export const shouldUserBePromptedForPushNotifications = () => {
  const { uses, pushNotificationsLastPromptedOn, pushNotificationsPromptDisabled } = getPwaData();
  const today = getToday();

  return !pushNotificationsPromptDisabled
    && uses >= usesBeforePushNotificationsPrompt
    && (!pushNotificationsLastPromptedOn || today.diff(pushNotificationsLastPromptedOn, 'days').days >= daysBetweenPushNotificationsPrompt);
};

export const allowPushNotificationsPromptToBeDisable = () => {
  const { installPushNotificationsCount } = getPwaData();
  return installPushNotificationsCount >= countWhenPushNotificationsPromptCanBeDisabled;
};

export const trackPushNotificationsPrompted = () => {
  const data = getPwaData();
  setPwaData({
    ...data,
    pushNotificationsLastPromptedOn: DateTime.local(),
    pushNotificationsPromptCount: data.pushNotificationsPromptCount + 1,
  });
};

export const trackPushNotificationsDismissed = (disable = false) => {
  const data = getPwaData();
  if (disable) {
    setPwaData({
      ...data,
      PushNotificationsPromptDisabled: true,
    });
  }
  tracker.track(tracker.events.pwaPushNotificationsResponse, { choice: 'dismissed', count: data.pushNotificationsPromptCount, disabled: disable });
};

export const trackPushNotifications = () => {
  const data = getPwaData();
  tracker.track(tracker.events.pwaPushNotificationsResponse, { choice: 'accepted', count: data.pwaInstallPrompted });
};

export const trackBrowserPushNotifications = (enabled) => {
  const data = getPwaData();
  if (enabled) {
    tracker.track(tracker.events.pwaPushNotificationsBrowserResponse, { choice: 'accepted', count: data.pushNotificationsPromptCount });
  } else {
    tracker.track(tracker.events.pwaPushNotificationsBrowserResponse, { choice: 'dismissed', count: data.pushNotificationsPromptCount, disabled: true });
  }
};




