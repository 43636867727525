
import { isAcme } from 'isAcme';
const checkBoxFontSize = 12;
const fieldFontSize = 14;

export const styles = (theme) => ({
  paperWidthMd: {
    width: '40%',
    overflow: 'visible',
    backgroundColor: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
  },
  backgroundStyle: {
    backgroundColor: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
  },
  scrollPaperTablet: {
    alignItems: 'baseline',
  },
  panel: {
    padding: '10px 20px 10px 20px',
    background: theme.palette.greyScaleDeprecated[7],
    overflow: 'visible',
  },
  textField: {
    '& label.Mui-focused': {
      color: theme.palette.greyScaleDeprecated[0],
    },
    '& label': {
      color: theme.palette.greyScaleDeprecated[0],
    },

    '& input': {
      fontSize: fieldFontSize,
      paddingBottom: 4,
      '&::placeholder': {
        color: 'black',
        opacity: '100%',
      },
    },
  },
  clrSection: {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    paddingTop: 12,
    backgroundColor: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
    color: 'black',
    fontSize: '13px',
    borderRadius: 0,
  },
  editSection: {
    background: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
    color: isAcme ? theme.palette.text.primary : 'black',
    fontSize: '13px',
    height: 56,
    width: '100%',
    borderBottomStyle: 'solid',
    borderRadius: 0,
    borderBottomWidth: 'thin',
    borderColor: theme.palette.greyScaleDeprecated[6],
    display: 'flex',
  },
  icon: {
    paddingRight: isAcme ? 4 : 12,
    width: '30px',
    alignSelf: isAcme ? 'center' : 'unset',
  },
  splitsContainer: {
    marginBottom: 15,
    marginLeft: 24,
    marginRight: 24,
    backgroundColor: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
    overflow: 'visible',
  },
  inputLabel: {
    fontSize: fieldFontSize,
    '& label.Mui-focused': {
      color: 'black',
    },
    '& label': {
      color: 'black',
    },
    // color: 'black',
    '&::placeholder': {
      color: 'black',
      opacity: '100%',
    },
  },
  formLabel: {
    height: 20,
    marginLeft: 0,
  },
  checkboxLabel: {
    fontSize: checkBoxFontSize,
    color: theme.palette.greyScaleDeprecated[2],
    marginLeft: 5,
  },
  checkbox: {
    fontSize: checkBoxFontSize + 6,
    paddingBottom: 3,
    height: 30,
    marginTop: 10,
  },
  categoryField: {
    fontSize: fieldFontSize,
    '& input': {
      width: '95%',
      paddingBottom: 1,
      '& label.Mui-focused': {
        color: 'black',
      },
      '& label': {
        color: 'black',
      },
      '&::placeholder': {
        color: 'black',
        opacity: '100%',
      },
    },
  },
  categoryFieldSection: {
    background: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
    color: isAcme ? theme.palette.text.primary : 'black',
    fontSize: '15px',
    width: '100% !important',
    display: 'flex',
    height: 56,
    borderBottomStyle: 'solid',
    borderRadius: 0,
    borderBottomWidth: 'thin',
    borderColor: theme.palette.greyScaleDeprecated[6],
  },
  buttonHolder: {
    textAlign: 'right',
    margin: 'auto',
    marginTop: '10px',
    background: isAcme ? theme.palette.background.default : theme.palette.greyScaleDeprecated[7],
    padding: 24,
    paddingTop: 0,
  },
  disclaimer: {
    fontSize: '11px',
    color: theme.palette.greyScaleDeprecated[2],
  },
  headerTitle: {
    display: 'flex',
    padding: 24,
    alignItems: 'center',
    backgroundColor: theme.palette.grey.level1,
    justifyContent: 'space-between',
    borderRadius: '8px 8px 0 0',
  },
  closeButtonTop: {
    padding: 0,
  },
});


