// attemptsLeft = 2 means 3 total attempts
export const retryPromise = (makeOriginalPromise, attemptsLeft = 2, attemptsInterval = 1000) =>
  new Promise((resolve, reject) => {
    makeOriginalPromise()
      .then(resolve)
      .catch((error) => {
        if (attemptsLeft > 0) {
          setTimeout(() =>
            retryPromise(makeOriginalPromise, attemptsLeft - 1)
              .then(resolve, reject),
          attemptsInterval);
        } else {
          reject(error);
        }
      });
  });

export default retryPromise;
