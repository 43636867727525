import { put, takeEvery, select } from 'redux-saga/effects';

import { datasetSharesActions } from 'companion-app-components/flux/dataset-shares';
import { datasetsSelectors } from 'companion-app-components/flux/datasets';

import { showInviteSentDialog } from 'components/Dialogs/DatasetSharing/actions';

function* handleDatasetSharedCreatedAction({ payload }) {
  const datasets = yield select(datasetsSelectors.getDatasetsById);
  const datasetName = datasets.get(payload.datasetId).name;
  yield put(showInviteSentDialog({ 
    name: `${payload.granteeFirstName} ${payload.granteeLastName}`,
    datasetName, 
  }));
}

export function* createDatasetSharingActionWatcher() {
  yield takeEvery(
    [datasetSharesActions.createDatasetShareSuccess.type],
    handleDatasetSharedCreatedAction,
  );
}

export default [createDatasetSharingActionWatcher];
