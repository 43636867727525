import { handleActions, combineActions } from 'redux-actions';

import { getLogger } from 'companion-app-components/utils/core';
import { resourceStoreTypes, resourceUpserters } from 'companion-app-components/flux/core';
import * as actions from './categoryGroupsActions';

const log = getLogger('data/categoryGroups/categoryGroupsReducer.js');

export const CATEGORY_GROUPS_REDUCER_KEY = 'categoryGroupsStore';

const initialState = resourceStoreTypes.mkQcsSyncResourceStore();
const categoryGroupsReducer = handleActions({
  [combineActions(
    actions.getCategoryGroupsAction,
    actions.createCategoryGroupsAction
  )]: (state) => {
    log.log('getCategoryGroupsAction || createCategoryGroupsAction...');
    return state.merge({ isLoading: true });
  },
  [combineActions(
    actions.getCategoryGroupsSuccessAction,
    actions.createCategoryGroupsSuccessAction
  )]: (state, { payload }) => {
    log.log('getCategoryGroupSuccessAction || createCategoryGroupsSuccessAction...\n', payload);
    return resourceUpserters.upsertResources(state, payload);
  },
  [combineActions(
    actions.getCategoryGroupsFailureAction,
    actions.createCategoryGroupsFailureAction
  )]: (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

  [actions.updateCategoryGroupAction]: (state) => {
    log.log('updateCategoryGroupAction...');
    return state.merge({ isLoading: true });
  },
  [actions.updateCategoryGroupSuccessAction]: (state, { payload }) => {
    log.log('updateCategoryGroupSuccessAction...\n', payload);
    return resourceUpserters.upsertResource(state, payload, false); // no merge
  },
  [actions.updateCategoryGroupFailureAction]: (state, { payload: error }) => {
    log.log('updateCategoryGroupFailureAction...\n', error);
    return resourceUpserters.completeWithError(state, error);
  },

}, initialState);

export default categoryGroupsReducer;
