import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useEffectOnce } from 'react-use';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { removeDialog } from 'companion-app-components/flux/root-ui/rootUiActions';
import { tracker } from 'companion-app-components/utils/core';

import InviteSendIcon from 'assets/inviteSent.svg';

import { DIALOG_TYPE_INVITE_SENT } from './actions';

import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface InviteSentProps {
  name: string;
  datasetName: string;
}

const InviteSent: FC<InviteSentProps> = ({ name, datasetName }) => {

  const dispatch = useDispatch();
  const { classes, cx } = useStyles();

  useEffectOnce(() => {
    tracker.track(tracker.events.datasetSharing, { property: 'userInvited' });
  });

  const handleClose = () => {
    dispatch(removeDialog(DIALOG_TYPE_INVITE_SENT));
  };

  return (
    <Dialog
      open
      sx={{ '& .MuiPaper-root': { padding: '0 20px' } }}
    >
      <DialogTitle
        className={classes.dialogTitleWrapper}
      >
        <Typography variant="h6" className={classes.title}>
          Invite Sent
        </Typography>
        <Typography variant="body1" className={classes.textTertiary}>
          The invite link expires in 24 hours.
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className={classes.closeButton}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogContent className={classes.contents}>
        <img src={InviteSendIcon} className={classes.sentImg} alt="Invite Sent" />
        <Typography gutterBottom variant="h6" className={cx(classes.weight400, classes.title)}>
          {`${name} has been invited to ${datasetName}`}
        </Typography>
        <Typography gutterBottom variant="caption" className={classes.textTertiary}>
          {`You will be notified when ${name} accepts your invite.`} 
        </Typography>
      </DialogContent>
      <DialogActions
        className={classes.dialogAction}
      >
        <Button 
          onClick={handleClose}
          variant="contained"
          size="small"
        >
          DONE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteSent;
