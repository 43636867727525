import { createAction } from 'redux-actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';

export const DIALOG_TYPE_SYNC_SENTIMENTS_SURVEY = 'DIALOG_TYPE_SYNC_SENTIMENTS_SURVEY';
export const DIALOG_TYPE_SYNC_SENTIMENTS_THANK_YOU = 'DIALOG_TYPE_SYNC_SENTIMENTS_THANK_YOU';

export const triggerSyncSentimentsDialog = createAction(
  createDialog.toString(),
  () => mkRootUiData({
    id: DIALOG_TYPE_SYNC_SENTIMENTS_SURVEY,
    type: DIALOG_TYPE_SYNC_SENTIMENTS_SURVEY,
    allowNesting: false,
  }),
);

export const triggerSyncSentimentsThankYouDialog = createAction(
  createDialog.toString(),
  () => mkRootUiData({
    id: DIALOG_TYPE_SYNC_SENTIMENTS_THANK_YOU,
    type: DIALOG_TYPE_SYNC_SENTIMENTS_THANK_YOU,
    allowNesting: false,
  }),
);
