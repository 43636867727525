import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';
import 'react-tippy/dist/tippy.css';
import { DateTime } from 'luxon';

import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ButtonBase from '@mui/material/ButtonBase';
import TextField from '@mui/material/TextField';
import MUISplitIcon from '@mui/icons-material/CallSplit';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ManualIcon from '@mui/icons-material/Create';
import DownloadedIcon from '@mui/icons-material/AccountBalance';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SaveIcon from '@mui/icons-material/Save';
import FastCatIcon from '@mui/icons-material/FlashOn';
import CloseIcon from '@mui/icons-material/Close';
import AlarmOn from '@mui/icons-material/AlarmOn';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import HourglassEmptyRounded from '@mui/icons-material/HourglassEmptyRounded';

import { datasetsSelectors, datasetsUtils } from 'companion-app-components/flux/datasets';
import { authSelectors } from 'companion-app-components/flux/auth';
import { transactionsUtils } from 'companion-app-components/flux/transactions';
import { scheduledTransactionsSelectors } from 'companion-app-components/flux/scheduled-transactions';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { getLogger } from 'companion-app-components/utils/core';
import { accountColorForId } from 'companion-app-components/flux/preferences/selectors';

import { isCompareToRegisterEnabled } from 'data/preferences/selectors';
import { getPayeesForAccounts } from 'data/payees/selectors';
import { getFieldString, makeTagsString } from 'data/transactions/searchFilter';
import { getTxnDifferences, getTxnStateInfo, isNextScheduledInstance, transactionEditsNotAllowed,
  isUnacceptedScheduledTxn, isUncategorizedTxn, isPendingTxn, nextScheduledInstanceAfterDate,
  isBankOwnedPending, isBankPendingTxn, txnCategorySign } from 'data/transactions/utils';
import { getAccountString } from 'data/accounts/retrievers';
import { getTransactionsByAccountId } from 'data/transactions/selectors';

import { fieldIsEditable } from 'components/TransactionRegister/helpers';
import QIconButton from 'components/QIconButton';
import QMenu from 'components/QMenu';
import AmountField, { ShowSignEnum, ShowColorEnum } from 'components/QuickenControls/AmountField/index';
import DateField from 'components/QuickenControls/DateField/index';
import IgnoredField from 'components/IgnoredField';
import SplitsAndDetailsPanel from 'components/SplitsAndDetailsPanel';
import TagsField from 'components/QuickenControls/TagsField';
import QCurrency from 'components/QCurrency';
import QHelp from 'components/QHelp';
import QPreferences from 'components/QPreferences';
import AccountsMenu from 'components/QuickenControls/AccountsMenu';
import ClrField from 'components/QuickenControls/ClrField';
import FlagField from 'components/QuickenControls/FlagField';
import QDialogs from 'components/QDialogs';
import Dump from 'components/Dump';
import InvestmentTransactionTypeField from 'components/InvestmentTransactionTypeField';
import QTip from 'components/QuickenControls/QTip';
import QCheckbox from 'components/QCheckbox';
import TransactionMenu from 'components/Transactions/TransactionMenu';
import { showTxnDetailsDialog } from 'components/Transactions/DetailsDialog/actions';
import compose from 'utils/compose';
import { isOldEdge } from 'utils/utils';
import QTypography from 'components/MUIWrappers/QTypography';
import { isAcme, isQuicken } from 'isAcme';
import { RegTxList, RegTxListRow, RegListItem, styles } from './styles';
// THE FIELDS
import RegCategoryField from './components/RegCategoryField';
import RegPayeeField from './components/RegPayeeField';

const log = getLogger('components/TransactionRegister/TransactionRow/index.js');
const isIe = require('is-iexplorer') || isOldEdge();  // eslint-disable-line

let SHOW_ALL = false;
const msgGrossPaycheck = "Paycheck can't be edited.";

class TransactionRow extends PureComponent { // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);

    const dataset = this.props.datasetsById.get(this.props.datasetId);

    this.state = {
      currencySymbol: this.getCurrencySymbol(props),
      accountMenuEl: null,
      prevTxn: props.txn, // keep track of starting point of editable for comparison on save
      isWindowsDataset: datasetsUtils.isWindowsDataset(dataset), // prevents re-render for simplifi
      showIcons: false,
      isBankPending: isBankPendingTxn(props.txn),
      showTooltip: false,
    };

    const { scheduledTransactionsById, txn, transactionsByAccountId } = this.props;
    this.isTypeGrossPaycheck = transactionsUtils.isTxnGrossPaycheckType(
      txn,
      transactionsByAccountId,
      scheduledTransactionsById,
    );
  }

  componentDidMount() {
    this.initDatasetClientCreator();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    // log.log('TRANSACTION ROW--------------------------------------------------------');
    // Object.keys(nextProps).forEach((key) => {
    //   if (nextProps[key] !== this.props[key]) {
    //     log.debug(`keyvalue ${key} changed`);
    //   }
    // });


    if ((this.props.showCurrencySymbol !== nextProps.showCurrencySymbol) ||
        this.props.txn !== nextProps.txn) {
      this.setState({ currencySymbol: this.getCurrencySymbol(nextProps) });
    }
    // keep track of the baseline editable transaction for comparison
    if (this.props.editable !== nextProps.editable) {
      this.setState({ prevTxn: nextProps.txn });
    }
    if ((this.props.datasetsById !== nextProps.datasetsById) || (this.props.datasetId !== nextProps.datasetId)) {
      this.initDatasetClientCreator();
    }
  }

  componentDidUpdate() {
    if (this.props.onFocusField && this.focusField) {
      this.props.onFocusField(this.focusField);
    }
  }

  initDatasetClientCreator = () => {

    if (this.props.datasetId) {
      const dataset = this.props.datasetsById.get(this.props.datasetId);
      if (dataset) {
        this.setState({ isWindowsDataset: datasetsUtils.isWindowsDataset(dataset) });
      }
    }
  };

  onRowSelect = (id, value) => {
    this.props.onRowSelect(id, value);
  };
  
  getCurrencySymbol = ({ showCurrencySymbol, txn, getTxnCurrency }) =>
    showCurrencySymbol && txn.accountId ? getTxnCurrency(txn.accountId) : 'N/A';

  //
  // This processes the "SAVE" call from the details panels (splits and/or edit details)
  // We give each of those a copy of the txnBeingEdited so that cancel is easy enough, but
  // it is possible that the user can edit the txnBeingEdited from the transaction row while
  // splits and details are open, which can lead to the transaction fields being edited from
  // two places at once.  We need to a) not lose any edited data from the transaction row, and
  // b) resolve conflicts if the data is edited both places.  In this case, the main txn field
  // wins.
  //
  saveTransaction = (editedTxn, autoSave, txnDirty, splitToggle = undefined) => {

    const { prevTxn } = this.state;
    const txnBeingEdited = this.props.txn;
    const newTxn = editedTxn;
    let localDirtyFlag = false;

    const changed = getTxnDifferences(txnBeingEdited, prevTxn);

    // if user changed memo, tags, payee, coa, postedOn, or check number, then we use the
    // version from the main transaction instead of the details panel.  Exception, if tx is split
    // we ignore the apparent coa change
    //

    changed.forEach((fld) => {
      switch (fld) {
        case 'postedOn':
        case 'memo':
        case 'check':
        case 'state':
        case 'payee':
          newTxn[fld] = txnBeingEdited[fld];
          localDirtyFlag = true;
          break;

        case 'split':
          localDirtyFlag = true;
          break;

        case 'amount':
        case 'tags':
        case 'coa':
          if (!transactionsUtils.isSplitTxn(newTxn)) {
            newTxn[fld] = txnBeingEdited[fld] || newTxn[fld];  // don't ever purposefully assign null
            localDirtyFlag = true;
          }
          break;

        default:
          break;
      }
    });

    this.props.callbacks.saveTransaction(newTxn, autoSave, txnDirty || localDirtyFlag, splitToggle);

  };

  itemKeydown = (e, fld, txn) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.stopPropagation();
      this.props.callbacks.regFieldClick(fld, txn);
    }
  };

  // Props must have:
  // accountIds, editRunningBalance, regEditableFields, headerFields, editDownloaded
  // showAccountColors, showSplitWindow, scheduledTransactionsById, txnExtraColumn
  fieldIsEditable = (txn, fld) => {

    const { accountIds, editRunningBalance, scheduledTransactionsById, editDownloaded, txnCancelExtraColumn,
      showAccountColors, headerFields, regEditableFields, showSplitWindow, allAccountIds } = this.props;

    const options = {
      accountIds,
      editRunningBalance,
      scheduledTransactionsById,
      editDownloaded,
      txnCancelExtraColumn,
      showAccountColors,
      headerFields,
      regEditableFields,
      showSplitWindow,
      allAccountIds,
    };

    if (isAcme || !isUnacceptedScheduledTxn(txn)) {
      return fieldIsEditable(txn, fld, options);
    }
    return isNextScheduledInstance(txn, this.props.scheduledTransactionsById) && fieldIsEditable(txn, fld, options);
  };

  renderBankPendingState = (classes) => {

    const title = this.state.isWindowsDataset && this.props.userPreferences.windowsReviewedWarningEnabled ?
      'Click here to learn what this is and how to use it' : 'Pending Transaction';

    return (
      <QIconButton
        aria-label="Bank Pending Transaction"
        focusRipple
        IconComponent={HourglassEmptyRounded}
        IconProps={{ className: classes.bankPending }}
        size="small-target"
        tooltip={title}
      />
    );
  };

  renderReviewedState = (txn, classes, fieldActive, forceStatic) => {

    let title;
    if (this.state.isWindowsDataset) {

      if (this.props.userPreferences.windowsReviewedWarningEnabled) {
        title = 'Click here to learn what this is and how to use it';
      } else {
        title = txn.isReviewed
          ? "Transaction 'Accepted', click to undo 'Accept' (Learn more in Transactions Settings -> Show/Hide Columns)"
          : "Transaction 'Not Accepted', click to mark 'Accepted' (Learn more in Transactions Settings -> Show/Hide Columns)";
      }
    } else {
      title = txn.isReviewed
        ? "Transaction marked 'reviewed', click to mark 'not reviewed"
        : "Transaction marked 'Not reviewed', click to mark 'reviewed'";
    }

    return (
      <QIconButton
        aria-label="Set Reviewed State"
        ref={fieldActive ?
          (input) => {
            this.focusField = input;
          } : null}
        disabled={forceStatic || this.isTypeGrossPaycheck}
        autoFocus={fieldActive}
        focusRipple
        IconComponent={txn.isReviewed ? RadioButtonUncheckedIcon : CircleIcon}
        IconProps={{ className: classNames(classes.reviewed, txn.isReviewed ? 'reviewed' : 'not-reviewed') }}
        size="small-target"
        onMouseEnter={() => this.setState({ showTooltip: true }, () => {
          setTimeout(() => {
            this.setState({ showTooltip: false });
          }, 3000);
        })}
        onMouseLeave={() => this.setState({ showTooltip: false })}
        tooltip={isAcme ? null : title}
        TooltipProps={{ 
          open: this.state.showTooltip, 
          position: 'left', 
          disableInteractive: true,
          leaveDelay: 3,
        }}
      />
    );
  };

  renderMatchState = (txn, classes, _handleMenu) => {

    let txnState = getTxnStateInfo(txn).entry;

    if (!isAcme && txnState === 'scheduled') {
      txnState = 'blank';
    }
    switch (txnState) {

      case 'bank':
        return (
          <QTip
            title="Bank downloaded transaction"
            wrapId={`${txn.id}-match`}
          >
            <DownloadedIcon
              className={classNames(classes.matchStateIcon, txnState)}
            />
          </QTip>
        );
      case 'manual':
        return (
          <QTip
            title="Manually entered transaction"
            wrapId={`${txn.id}-match`}
          >
            <ManualIcon
              className={classNames(classes.matchStateIcon, txnState)}
            />
          </QTip>
        );
      case 'matched':
        return (
          <QTip
            title="Matched transaction"
            wrapId={`${txn.id}-match`}
          >
            <DownloadedIcon
              className={classNames(classes.matchStateIcon, txnState)}
            />
          </QTip>
        );
      case 'scheduled':
        return (
          <QTip
            title="Scheduled transaction"
            wrapId={`${txn.id}-match`}
          >
            <AlarmOn
              className={classNames(classes.matchStateIcon, txnState)}
            />
          </QTip>
        );
      default:
        return (
          ''
        );
    }

  };

  renderRecurringField = (options) => {

    const { txn, fieldActive, onChange } = options;

    const accountTransactions = this.props.transactionsByAccountId.get(txn.accountId);
    let qtipTitle = null;
    const recurringStatus = getTxnStateInfo(txn);

    const nextInstance = accountTransactions &&
      nextScheduledInstanceAfterDate(DateTime.now().toISODate(), accountTransactions, txn.stModelId);
    const isNext = nextInstance && nextInstance.id === txn.id;
    const isUpcoming = isNextScheduledInstance(txn, this.props.scheduledTransactionsById);

    let menuOptions = isUpcoming ?
      [
        { label: 'Enter this reminder', value: 'ACCEPT' },
        { label: 'Ignore this reminder', value: 'SKIP' },
      ]
      :
      [
        { label: 'Enter this and all previous overdue reminders for this Payee as Paid', value: 'ACCEPT' },
        { label: 'Ignore this and all previous overdue reminders for this Payee', value: 'SKIP' },
      ];

    let iconClass = '';

    switch (recurringStatus.status) {

      case 'DUE':
        qtipTitle = 'This reminder is due';
        iconClass = 'due';
        break;

      case 'OVERDUE':
        qtipTitle = 'This reminder is overdue';
        iconClass = 'overdue';
        break;

      default:
        if (isNext) {
          qtipTitle = 'This will be the next instance that becomes due';
        } else {
          qtipTitle = 'This reminder is not yet due';
          iconClass = 'notdue';
          menuOptions = null;
        }
        break;
    }

    // if this scheduled instance contains transfers to unsynced accounts, then do not allow the user to act
    if (transactionEditsNotAllowed(txn)) {
      qtipTitle = 'This scheduled instance is a billpay transaction, or contains a transfer to an unsynced account ' +
        'or account that does not support transactions.' +
        ' You can only edit or act upon it from the desktop application';
      menuOptions = null;
    } else if (this?.isTypeGrossPaycheck) {
      qtipTitle = msgGrossPaycheck;
      menuOptions = null;
    }
    const customTrigger = (
      <AlarmOn className={this.props.classes[iconClass]} />
    );

    if (menuOptions) {
      return (
        <QMenu
          customTrigger={customTrigger}
          name={`${txn.id}-recurring`}
          title={qtipTitle}
          menuIconButtonSize="mediumNoPadding"
          autoFocus={fieldActive}
          options={menuOptions}
          onChange={onChange}
          buttonRef={fieldActive ? (x) => { this.focusField = x; } : null}
        />
      );
    }
    return (
      <QTip
        title={qtipTitle}
        wrapId={`${txn.id}-recurring`}
      >
        {customTrigger}
      </QTip>
    );
  };


  // Handles the interplay between MoneyOut and MoneyIn fields
  // What we are going for is a seamless usage of these fields across a single transaction amount
  // First, since we use an AmountField component both for the static and editable states, we need
  // to manage the values/properties appropriatly based on the value of the transaction. We also want
  // behavior to be elegant, so when the user starts typing in one field, the other field blanks out
  // (but not before the user types).  WE use the '&' character to represent zero/blank fields, which
  // provides an actual value, but gets validated into a null.  Null itself would prevent a component update
  //
  renderDoubleAmountField = (
    field,
    amountEditable,
    enableAutoFocus,
    attributes,
  ) => {

    const { editable, txn, currEditFieldName, callbacks, classes } = this.props;
    const { currencySymbol } = this.state;

    const thisFieldActive = (amountEditable && editable && (currEditFieldName === field));

    const classString = classNames('amount',
      this.props.classes.regItem,
      'protectWidth',
      thisFieldActive ? 'editable' : '');

    let value = '0';
    if (thisFieldActive) {
      //
      // we need to just pass through the current txn amount string
      const amountValid = (field === 'expense') ? txn.amount <= 0 : txn.amount > 0;
      value = amountValid ? txn.amount : '&';

    } else {
      const amountValid = (field === 'expense') ? txn.amount <= 0 : txn.amount > 0;
      value = amountValid ? Math.abs(txn.amount) : '&';
    }
    const zeroIsBlank = txn.amount === 0 ? (field !== 'expense') : true;

    // note, no BLUR handling here, otherwise it breaks by causing 0 values to wipe out partner field
    //
    return (
      <RegListItem
        {...attributes}
        className={classString}
      >
        {SHOW_ALL &&
        <AmountField
          disableUnderline={this.props.theme.components.register.noUnderline}
          clickable={amountEditable ? 'true' : null}
          amountType={field}
          onChange={callbacks.handleChange}
          onBlur={(e) => {
            callbacks.onAmountBlur(e, field);
          }}
          editable={thisFieldActive}
          autoFocus={enableAutoFocus}
          fontSize={this.props.theme.components.register.fontSize.default}
          value={String(value)}
          color={field === 'expense' ? 'black' : 'green'}
          useAbsoluteValue={field === 'expense' ? true : null}
          zeroIsBlank={zeroIsBlank}
          className={classNames(classes.regInput, 'amount')}
          currencySymbol={currencySymbol}
          inputRef={thisFieldActive ?
            (input) => {
              this.focusField = input;
            } : null}
        >
        </AmountField>}
      </RegListItem>
    );
  };

  renderAccountField(options) {

    const { attributes, retRow, fieldActive, wrapClass, classes, callbacks, forceStatic, txn, editable } = options;
    const { isBankPending } = this.state;

    const flexClass = this.props.showAccountColors ? 'none' : 'flex1 protectWidth';
    const regClass = this.props.showAccountColors ? '' : this.props.classes.regItem;
    const acctName = getAccountString(txn.accountId);

    // only allow editing if this is NOT a bank downloaded transaction
    const allowEditing = editable; // && this.fieldIsEditable(txn, 'account');

    function renderCore(showColorBar, newAccountColorForId) {
      return (
        <>
          {showColorBar &&
            <div
              className={classes.accountFieldColor}
            >
              <div
                style={{
                  height: '100%',
                  minHeight: 20,
                  width: '40%',
                  background: newAccountColorForId,
                }}
              >
              </div>
            </div>}

          {!showColorBar &&
            <div
              className={classNames(classes.accountFieldName, isBankPending ? classes.bankPendingText : '')}
            >
              <QTip
                title={acctName}
                distance={3}
                wrapOnly
                position="bottom"
                wrapId={`${txn.id}-account`}
              >
                <QTypography
                  thinFont
                  clickable
                  className={classes.regItemCore}
                >
                  {acctName}
                </QTypography>
              </QTip>
            </div>}
        </>
      );
    }

    retRow.push(
      <RegListItem
        {...attributes}
        className={classNames(fieldActive && 'editable', regClass, flexClass, fieldActive ? 'wraptext' : wrapClass)}
        tabIndex="0"
      >
        {SHOW_ALL &&
          <>
            {allowEditing &&
            <ButtonBase
              id={`${this.props.showAccountColors ? 'AcctColorMenu' : 'AcctMenu'}${txn.id}`}
              aria-label="Account Menu"
              classes={{ root: classes.buttonRoot }}
              focusRipple
              autoFocus
              disabled={forceStatic}
              ref={fieldActive ? (input) => {
                this.focusField = input;
              } : null}
              onClick={fieldActive ? (e) =>
                this.setState({ accountMenuEl: e.currentTarget }) : null}
            >

              {renderCore(this.props.showAccountColors, this.props.accountColorForId)}

            </ButtonBase>}

            {!allowEditing &&
            <>
              {renderCore(this.props.showAccountColors, this.props.accountColorForId)}
            </>}

            {allowEditing && fieldActive && this.state.accountMenuEl && (
              <AccountsMenu
                element={this.state.accountMenuEl}
                selected={txn.accountId}
                showColors={this.props.showAccountColors}
                onChange={(event) => {
                  this.setState({ accountMenuEl: null });
                  callbacks.handleChange(event, 'account');
                }}
                onClose={() => {
                  this.setState({ accountMenuEl: null });
                  setTimeout(() => callbacks.resetFocus(), 100);
                }}
              />
            )}
          </>}
      </RegListItem>,
    );
  }

  onFieldBlur = (_e, _field) => {
    // this.props.callbacks.onFieldBlur(e, field);
  };

  onClickAway = () => {
    this.props.callbacks.onFieldBlur(true);
  };

  dateFieldOnChange = (e) => this.props.callbacks?.handleChange?.(e, 'postedOn');

  payeeOnChange = (e, fieldName, rawValue) => this.props.callbacks.handleChange(e, fieldName || 'payee', rawValue);

  payeeUnputRef = (input) => { this.focusField = input; };

  // =============================================================================
  // renderFields
  // =============================================================================

  renderFields = (options) => {
    // TODO: put back these currently unused props (was getting lint issues), when showAccounts is used again
    // scheduledTransactionsById, usePayeeField, payeeAutoFillCat, payeeAutoFillMemo, payeeAutoFillAmount, 
    // payeeAutoFillTags, payeeShowFillInfoInMenu, sortBy
    const { wrapText, callbacks, txn, lastRow, showAccounts, classes,
      showBalance, longCats, currEditFieldName, showSplitWindow, suggestCategoryForUncategorized,
      txnCancelExtraColumn, noDateOnBankPending, isC2REnabled, allAccountIds } = this.props;

    const { currencySymbol, isBankPending } = this.state;
    const { showSplitsAndDetails, scriptId, showColor } = options;

    const enableAutoFocus = true;
    const amountProposedSign = (!txn.id || Number(txn.amount) === 0) ? txnCategorySign(txn) : null;
    const wrapClass = wrapText ? 'wraptext' : '';

    const retRow = [];

    let editable = true;
    if (this?.isTypeGrossPaycheck) {
      editable = false;
    } else {
      editable = !isBankOwnedPending(txn) ? this.props.editable : (this.props.editable && !isC2REnabled);
    }

    // Condition for account not synced case
    let isAccountNotSynced = false;
    if (txn.id && txn?.coa?.type === 'ACCOUNT' && !allAccountIds.includes(txn.coa.id)) {
      editable = false;
      isAccountNotSynced = true;
    }

    this.props.headerFields.forEach((field) => {

      const forceStatic = editable && !this.fieldIsEditable(txn, field);
      const fieldActive = (editable && !forceStatic && currEditFieldName === field);
      let staticValue = (<span>{'\u00A0'}</span>);

      const fieldKey = `${scriptId}_${field}_${fieldActive ? 'e' : ''}`;
      const editClass = fieldActive ? 'editable' : '';
      const hoverClass = (fieldActive || !this.fieldIsEditable(txn, field)) ? '' : classes.editHover;
      let bankPendingClass = '';
      if (isBankPending) {
        bankPendingClass = isBankOwnedPending(txn) ? classes.bankOwnedPendingText : classes.bankPendingText;
      }

      const attributes = {
        key: fieldKey,
        id: fieldKey,
        tabIndex: '0',
        onClick: () => callbacks.regFieldClick(field, txn),
        onKeyDown: (e) => !editable && this.itemKeydown(e, field, txn),
      };

      switch (field) {

        case 'action':
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              onClick={null}
              className={classNames(this.props.classes.regItem, 'protectWidth', editClass, hoverClass)}
            >
              {SHOW_ALL && (
                <InvestmentTransactionTypeField
                  id="type"
                  sx={{ minWidth: null }}
                  forcePopupIcon={false}
                  readOnly
                  textFieldProps={{
                    variant: 'standard',
                    label: null,
                    InputProps: {
                      disableUnderline: true,
                    },
                  }}
                  value={txn.type === 'INVESTMENT' ? txn.investmentTxnType : 'VIRTUAL_CASH_FLOW'}
                  onChange={noop} // this.props.callbacks?.handleChange?.({}, 'action', value)}
                />
              )}
            </RegListItem>,
          );
          break;

        case 'security':
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              onClick={null}
              className={classNames(this.props.classes.regItem, 'accountFieldName', 'protectWidth', wrapClass, editClass, hoverClass)}
            >
              {SHOW_ALL && <div>{txn.symbol}{txn.symbol && ' / '}{txn.payee}</div>}
            </RegListItem>,
          );
          break;

        case 'quantity':
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              onClick={null}
              className={classNames('amount', this.props.classes.regItem, 'quantity', editClass, hoverClass)}
            >
              {SHOW_ALL && <div>{txn.units || '-'}</div>}
            </RegListItem>,
          );
          break;

        case 'price':
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              onClick={null}
              className={classNames('amount', this.props.classes.regItem, 'protectWidth', editClass, hoverClass)}
            >
              {SHOW_ALL && <div>{txn.costBasis || '-'}</div>}
            </RegListItem>,
          );
          break;

        // ------------------------------------------------
        // MATCH FIELD
        // ------------------------------------------------
        case 'match':
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              onClick={null}
              className={classNames(classes.iconColumn, 'nopoint')}
            >
              {SHOW_ALL && this.renderMatchState(txn, classes, callbacks.handleMenu)}
            </RegListItem>,
          );
          break;

        // ------------------------------------------------
        // REVIEWED FIELD
        // ------------------------------------------------
        case 'reviewed':
          if (isBankOwnedPending(txn)) {
            retRow.push(
              <RegListItem
                {...attributes}
                tabIndex={null}
                className={classNames(classes.iconButtonColumn)}
              >
                {this.renderBankPendingState(classes)}
              </RegListItem>,
            );
          } else if (isUnacceptedScheduledTxn(txn)) {
            retRow.push(
              <RegListItem
                {...attributes}
                tabIndex={null}
                className={classNames(classes.iconButtonColumn, forceStatic ? 'nopoint' : '')}
              >
              </RegListItem>,
            );
          } else {
            retRow.push(
              <RegListItem
                {...attributes}
                id={`${scriptId}-${field}-${txn.isReviewed ? 'yes' : 'no'}_`}
                tabIndex={null}
                onClick={forceStatic || this.isTypeGrossPaycheck || (isAccountNotSynced && !editable) ? null : () => {

                  if (!isAcme && this.props.userPreferences.windowsReviewedWarningEnabled) {
                    this.props.setUserPreference({ windowsReviewedWarningEnabled: false });
                    return this.props.showQHelp('windowsReviewed');
                  }
                  return callbacks.handleMenu('reviewed', txn);
                }}
                className={classNames(classes.iconButtonColumn, forceStatic ? 'nopoint' : '')}
              >
                {SHOW_ALL && this.renderReviewedState(txn, classes, fieldActive, forceStatic)}
              </RegListItem>,
            );
          }
          break;

        // ------------------------------------------------
        // FLAG FIELD
        // ------------------------------------------------
        case 'userFlag':
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              onClick={null}
              className={this.props.classes.iconButtonColumn}
            >
              <FlagField
                classes={{ iconRoot: this.props.classes.iconButtonColumn }}
                value={txn.userFlag}
                autoFocus={fieldActive}
                inputRef={fieldActive ? (input) => { this.focusField = input; } : null}
                editable={editable}
                onChange={(e) => callbacks.handleMenu('userFlag', txn, e.target.value)}
                show={(txn.userFlag && txn.userFlag !== 'NONE') || editable || this.state.showIcons}
              />
            </RegListItem>,
          );
          break;

        // ------------------------------------------------
        // IGNORED FIELD
        // ------------------------------------------------
        case 'ignored':
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              onClick={null}
              className={this.props.classes.iconButtonColumn}
            >
              <IgnoredField
                value={txn}
                autoFocus={fieldActive}
                inputRef={fieldActive ? (input) => { this.focusField = input; } : null}
                onChange={(e) => callbacks.handleMenu('ignored', txn, e.target.value)}
                classes={{ iconRoot: this.props.classes.iconButtonColumn }}
                show={(txn.isExcludedFromReports || txn.isExcludedFromF2S) || editable || this.state.showIcons}
              />
            </RegListItem>,
          );
          break;

        // ------------------------------------------------
        // SELECT FIELD or RECURRING ICON FIELD
        // ------------------------------------------------
        case 'select':
          if (isUnacceptedScheduledTxn(txn) && !isAcme) {
            retRow.push(
              <RegListItem
                {...attributes}
                tabIndex={null}
                onClick={null}
                className={this.props.classes.iconButtonColumn}
              >
                {this.renderRecurringField({ txn, fieldActive, forceStatic, onChange: (val) => callbacks.actOnScheduledTxn(txn, val) })}
              </RegListItem>,
            );
          } else {
            retRow.push(
              <RegListItem
                {...attributes}
                tabIndex={null}
                onClick={null}
                className={this.props.classes.iconButtonColumn}
              >
                {SHOW_ALL &&
                <QCheckbox
                  size={this.props.theme.components.register.checkboxSize}
                  color="primary"
                  inputProps={{ 'aria-label': 'Select or Unselect' }}
                  autoFocus={editable && currEditFieldName === 'select'}
                  disabled={(isAccountNotSynced && !editable) || forceStatic || (isBankOwnedPending(txn) && isC2REnabled) || this.isTypeGrossPaycheck}
                  checked={this.props.selected}
                  inputRef={editable && !forceStatic && currEditFieldName === 'select' ?
                    (input) => {
                      this.focusField = input;
                    } : null}
                  onClick={() => this.onRowSelect(txn, !this.props.selected)}
                />}
              </RegListItem>,
            );
          }
          break;

        // ------------------------------------------------
        // ACCOUNT COLOR FIELD
        // ------------------------------------------------
        case 'accountColor': {

          if (this.props.showAccountColors) {
            this.renderAccountField({
              attributes,
              retRow,
              fieldActive,
              wrapClass,
              callbacks,
              txn,
              classes,
              forceStatic,
              editable,
            });
          }
          break;
        }

        // ------------------------------------------------
        // ACCOUNT NAME FIELD
        // ------------------------------------------------
        case 'account': {

          if (showAccounts && !this.props.showAccountColors) {
            this.renderAccountField({
              attributes,
              retRow,
              fieldActive,
              wrapClass,
              callbacks,
              txn,
              classes,
              forceStatic,
              editable,
            });
          }
          break;
        }

        // ------------------------------------------------
        // POSTED_ON FIELD
        // ------------------------------------------------
        case 'postedOn': {

          const isPending = (isPendingTxn(txn) && noDateOnBankPending) && !isUnacceptedScheduledTxn(txn);
          const cname = isAcme ? classNames(classes.statusColumn, hoverClass, 'protectWidthDate') :
            classNames(this.props.classes.regItem, editClass, !isPending && hoverClass, 'protectWidthDate');

          retRow.push(

            <RegListItem
              {...attributes}
              className={cname}
            >
              {SHOW_ALL && (
                <DateField
                  disableKeyboardInput={isAcme}
                  fieldId="postedOn"
                  editable={!forceStatic && editable && currEditFieldName === 'postedOn'}
                  value={txn.postedOn}
                  onChange={this.dateFieldOnChange}
                  fontSize={this.props.theme.components.register.fontSize.default}
                  autoFocus={enableAutoFocus}
                  clickable={!forceStatic ? 'true' : null}
                  className={classNames(classes.dateField, ...(isPending && showColor ? [classes.pending] : []), bankPendingClass)}
                  changeDateOnKeyDown={isQuicken}
                />
              )}
            </RegListItem>,
          );
          break;
        }
        // ------------------------------------------------
        // TAGS FIELD
        // ------------------------------------------------
        case 'tags': {

          const tagStatic = makeTagsString(txn.tags, this.props.tagsAsHashes);
          const isSplit = transactionsUtils.isSplitTxn(txn);

          let tagFrag;
          if (!showSplitWindow && fieldActive && !isSplit) {
            tagFrag = (
              <TagsField
                disableUnderline={this.props.theme.components.register.noUnderline}
                value={txn.tags}
                onChange={(e) => callbacks.handleChange(e, 'tags')}
                fieldId="tags"
                autoFocus={enableAutoFocus}
                inputRef={
                  (input) => {
                    this.focusField = input;
                  }
                }
              />
            );
          } else if (isSplit || showSplitWindow) {
            tagFrag =
              <QTypography
                clickable
                thinFont
                className={classes.regItemCore}
              >
              </QTypography>;
          } else {
            tagFrag =
              <QTip
                wrapOnly
                title={this.props.wrapText ? null : tagStatic}
                wrapId={`${txn.id}-${field}`}
              >
                <QTypography
                  clickable={!forceStatic ? 'true' : null}
                  thinFont
                  className={classes.regItemCore}
                >
                  {tagStatic || staticValue}
                </QTypography>
              </QTip>;
          }


          retRow.push(
            <RegListItem
              {...attributes}
              className={classNames(this.props.classes.regItem,
                'flex2',
                fieldActive ? 'wraptext' : wrapClass,
                editClass,
                hoverClass)}
            >
              {SHOW_ALL && tagFrag}
            </RegListItem>,
          );
          break;
        }

        // ------------------------------------------------
        // NOTES FIELD
        // ------------------------------------------------
        case 'notes':

          retRow.push(
            <RegListItem
              {...attributes}
              className={classNames(this.props.classes.regItem, 'flex2', wrapClass, editClass, hoverClass)}
            >
              {SHOW_ALL &&
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {editable && !forceStatic && currEditFieldName === 'notes' ?
                  <TextField
                    variant="standard"
                    InputProps={{ disableUnderline: this.props.theme.components.register.noUnderline }}
                    className={classes.regInput}
                    onFocus={callbacks.handleFocus}
                    autoFocus={enableAutoFocus}
                    placeholder="Memo"
                    value={txn.memo || ''}
                    onChange={(e) => callbacks.handleChange(e, 'notes')}
                    onBlur={(e) => this.onFieldBlur(e, 'notes')}
                    inputRef={(input) => {
                      this.focusField = input;
                    }}
                  >
                  </TextField> :
                  <QTip
                    wrapOnly
                    title={this.props.wrapText ? null : txn.memo}
                    wrapId={`${txn.id}-${field}`}
                  >
                    <QTypography
                      clickable={!forceStatic ? 'true' : null}
                      thinFont
                      className={classes.regItemCore}
                    >
                      {txn.memo || staticValue}
                    </QTypography>
                  </QTip>}
              </>}
            </RegListItem>,
          );
          break;

        // ------------------------------------------------
        // CHECK FIELD
        // ------------------------------------------------
        case 'check':

          if (txn.check && (txn.check.number || txn.check.memo)) {
            staticValue = txn.check.number || txn.check.memo;
          }

          retRow.push(
            <RegListItem
              {...attributes}
              className={classNames(this.props.classes.regItem, wrapClass, editClass, hoverClass)}
            >
              {SHOW_ALL &&
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {editable && !forceStatic && currEditFieldName === 'check' ?
                  <TextField
                    InputProps={{ disableUnderline: this.props.theme.components.register.noUnderline }}
                    className={classes.regInput}
                    onFocus={callbacks.handleFocus}
                    placeholder="Check #"
                    autoFocus={enableAutoFocus}
                    value={(txn.check && (txn.check.number || txn.check.memo)) || ''}
                    onChange={(e) => callbacks.handleChange(e, 'check')}
                    onBlur={(e) => this.onFieldBlur(e, field)}
                    inputRef={(input) => {
                      this.focusField = input;
                    }}
                  >
                  </TextField> :
                  <QTypography
                    clickable={!forceStatic ? 'true' : null}
                    thinFont
                    className={classes.regItemCore}
                  >
                    {staticValue}
                  </QTypography>}
              </>}
            </RegListItem>,
          );
          break;

        // ------------------------------------------------
        // PAYEE FIELD
        // ------------------------------------------------
        case 'payee':

          retRow.push(
            <RegListItem
              {...attributes}
              className={classNames(this.props.classes.regItem, 'flex2', wrapClass, editClass, hoverClass, bankPendingClass)}
            >
              {SHOW_ALL &&
              <RegPayeeField
                editable={editable && currEditFieldName === 'payee'}
                txn={txn}
                autoFocus={enableAutoFocus}
                forceStatic={forceStatic}
                onFocus={callbacks.handleFocus}
                onChange={this.payeeOnChange}
                inputRef={this.payeeUnputRef}
                regFieldKey={this.props.callbacks.regFieldKey}
                accountIds={this.props.accountIds}
              />}
            </RegListItem>,
          );
          break;

        // ------------------------------------------------
        // CATEGORY FIELD
        // ------------------------------------------------
        case 'category':

          retRow.push(
            <RegListItem
              {...attributes}
              className={classNames(
                this.props.classes.regItem, 
                'flex2',
                wrapClass,
                editClass, 
                hoverClass,
                bankPendingClass,
                (transactionsUtils.isSplitTxn(txn) && editable) ? classes.withClearSplits : null,
              )}
              onClick={(!txn.split && forceStatic) ? null : () =>
                callbacks.regFieldClick('category', txn)}
            >
              {SHOW_ALL &&
                <RegCategoryField
                  txn={txn}
                  editable={editable && currEditFieldName === 'category'}
                  onChange={callbacks.handleChange}
                  forceStatic={forceStatic}
                  longCats={longCats}
                  wrapText={wrapText}
                  showSplitWindow={showSplitWindow}
                  context={this.props.wrapperId}
                  inputRef={editable && !forceStatic && currEditFieldName === 'category' ?
                    (input) => {
                      this.focusField = input;
                    } : null}
                />}
              {transactionsUtils.isSplitTxn(txn) && editable && 
                <Chip 
                  label="Clear Splits" 
                  size="small" 
                  onClick={(e) => {
                    e.stopPropagation();
                    callbacks.regFieldClick('clearSplits', txn);
                  }}
                  className={classes.clearSplits}
                />}
            </RegListItem>,
          );
          break;

        // ------------------------------------------------
        // SPLIT FIELD
        // ------------------------------------------------
        case 'split': {

          let suggestTxn = null;
          if (isUncategorizedTxn(txn) && suggestCategoryForUncategorized) {
            const suggestRecord =
              txn.payee && this.props.payeeCategoryMap ? this.props.payeeCategoryMap.get(txn.payee?.toLowerCase()) : null;
            if (suggestRecord && !transactionsUtils.isSplitTxn(suggestRecord.txn) && !isUncategorizedTxn(suggestRecord.txn)) {
              suggestTxn = suggestRecord.txn;
            }
          }
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              className={classes.iconColumn}
              onClick={null}
            >
              {SHOW_ALL && !forceStatic &&
              <>
                {(transactionsUtils.isSplitTxn(txn) || editable) &&
                <QIconButton
                  aria-label="Split Transaction"
                  autoFocus={fieldActive}
                  ref={fieldActive ? (input) => {
                    this.focusField = input;
                  } : null}
                  focusRipple
                  IconComponent={MUISplitIcon}
                  onClick={() => callbacks.regFieldClick('split', txn)}
                  size="small-target"
                  TooltipProps={{ enterDelay: 10 }}
                />}

                {!editable && suggestTxn &&
                <QIconButton
                  aria-label="Fast Categorize"
                  autoFocus={fieldActive}
                  disabled={forceStatic}
                  focusRipple
                  ref={fieldActive ? (input) => {
                    this.focusField = input;
                  } : null}
                  IconComponent={FastCatIcon}
                  onClick={(!txn.split && forceStatic) ? null : () =>
                    callbacks.regFieldClick('categorySuggest', txn, suggestTxn)}
                  size="small-target"
                  tooltip={`Click to fast categorize as ${getFieldString('category', suggestTxn)}`}
                  TooltipProps={{ enterDelay: 10 }}
                />}
              </>}
            </RegListItem>,
          );
          break;
        }

        // ------------------------------------------------
        // STATE FIELD
        // ------------------------------------------------
        case 'state': {

          const txnInfo = getTxnStateInfo(txn); // Linda is crazy

          retRow.push(
            <RegListItem
              {...attributes}
              className={classNames(classes.statusColumn, !isC2REnabled ? hoverClass : '')}
              onClick={null}
            >
              {SHOW_ALL && !(isBankOwnedPending(txn) && isC2REnabled) &&
              <ClrField
                value={isAcme ? txnInfo.status : txn.state}
                className={classes.select}
                onChange={(e) => callbacks.handleMenu('state', txn, e.target.value)}
                inputRef={fieldActive ? (input) => { this.focusField = input; } : null}
                editable={this.fieldIsEditable(txn, field) && !this.isTypeGrossPaycheck}
                autoFocus={fieldActive}
                clearedIsBlank={isAcme}
              />}
            </RegListItem>,
          );
          break;
        }

        // ------------------------------------------------
        // ATTACHMENT FIELD
        // ------------------------------------------------
        case 'attachment':
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              className={classes.iconColumn}
              onClick={txn.attachments ? null : () => callbacks.handleMenu('addAttachments', txn)}
            >
              {txn.attachments && (txn.attachments.length > 0) && SHOW_ALL &&
                <QIconButton
                  aria-label="View Attachments"
                  autoFocus={fieldActive}
                  ref={fieldActive ?
                    (input) => {
                      this.focusField = input;
                    } : null}
                  focusRipple
                  IconComponent={AttachFileIcon}
                  onClick={() => callbacks.handleMenu('viewAttachments', txn)}
                  tooltip={`Transaction has ${txn.attachments.length} attachment${txn.attachments.length > 1 ? 's' : ''}`}
                  size="small-target"
                />}
            </RegListItem>,
          );
          break;

        // ------------------------------------------------
        // EXPENSE FIELD
        // ------------------------------------------------
        case 'expense':
          if (this.props.doubleAmounts) {
            retRow.push(
              this.renderDoubleAmountField(
                'expense',
                this.fieldIsEditable(txn, field),
                enableAutoFocus,
                attributes,
              ),
            );
          }
          break;

        // ------------------------------------------------
        // INCOME FIELD
        // ------------------------------------------------
        case 'income':
          if (this.props.doubleAmounts) {

            retRow.push(this.renderDoubleAmountField(
              'income',
              this.fieldIsEditable(txn, field),
              enableAutoFocus,
              attributes,
            ));
          }
          break;

        // ------------------------------------------------
        // AMOUNT FIELD
        // ------------------------------------------------
        case 'amount':
          if (!this.props.doubleAmounts) {
            retRow.push(
              <RegListItem
                {...attributes}
                className={classNames('amount', this.props.classes.regItem, 'protectWidth', editClass, hoverClass, bankPendingClass)}
              >
                {SHOW_ALL &&
                <AmountField
                  disableUnderline={this.props.theme.components.register.noUnderline}
                  clickable={this.fieldIsEditable(txn, field) ? 'true' : null}
                  thinFont
                  fontSize={this.props.theme.components.register.fontSize.default}
                  amountType="amount"
                  proposedSign={amountProposedSign}
                  lockInitialProposedSign
                  onChange={callbacks.handleChange}
                  onBlur={(e, fieldType, wasEnter) => {
                    callbacks.onAmountBlur(e, field, wasEnter);
                  }}
                  editable={this.fieldIsEditable(txn, field) && editable && currEditFieldName === 'amount'}
                  autoFocus={enableAutoFocus}
                  value={String(txn.amount)}
                  className={classNames(classes.regInput, 'amount')}
                  currencySymbol={currencySymbol}
                  inputRef={this.fieldIsEditable(txn, field) && editable && currEditFieldName === 'amount' ?
                    (input) => {
                      this.focusField = input;
                    } : null}
                  showSign={isAcme ? ShowSignEnum.POSITIVE_ONLY : ShowSignEnum.NEGATIVE_ONLY}
                  showColor={ShowColorEnum.POSITIVE_ONLY}
                >
                </AmountField>}
              </RegListItem>,
            );
          }
          break;

        // ------------------------------------------------
        // BALANCE FIELD
        // ------------------------------------------------
        case 'balance':
          if (showBalance) {

            const canEdit = this.fieldIsEditable(txn, field) && editable && currEditFieldName === 'balance' && this.props.editRunningBalance;

            retRow.push(
              <RegListItem
                {...attributes}
                tabIndex={null}
                className={classNames('amount', this.props.classes.regItem, canEdit ? editClass : '', this.props.editRunningBalance ? hoverClass : '', bankPendingClass)}
                onClick={this.fieldIsEditable(txn, field) ? () => callbacks.regFieldClick('balance', txn) : null}
              >
                {SHOW_ALL &&
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {!lastRow && (txn.balance !== null && txn.balance !== undefined) ?
                    <QTip
                      title={canEdit ? `Adjust the balance for ${DateTime.fromISO(txn.postedOn).toFormat('MM/dd/yyyy')}` : null}
                      wrapId={`${txn.id}-${field}`}
                    >
                      <AmountField
                        disableUnderline={this.props.theme.components.register.noUnderline}
                        clickable={this.props.editRunningBalance && this.fieldIsEditable(txn, field) ? 'true' : null}
                        thinFont
                        amountType="balance"
                        onChange={callbacks.handleChange}
                        onBlur={(e, type) => {
                          callbacks.onAmountBlur(e, type);
                        }}
                        editable={canEdit}
                        autoFocus={enableAutoFocus}
                        value={String(txn.balance)}
                        color={this.getColorName(showColor, txn.balance)}
                        className={classNames(classes.regInput, 'amount')}
                        currencySymbol={currencySymbol}
                        inputRef={canEdit ?
                          (input) => {
                            this.focusField = input;
                          } : null}

                      >
                      </AmountField>
                    </QTip> :
                    ''}
                </>}
              </RegListItem>,
            );
          }
          break;

        // ------------------------------------------------
        // MENU FIELD
        // ------------------------------------------------
        case 'menu': {
          const showModal = isAcme && this.props.txnDetailsModal;
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              className={this.props.classes.iconButtonColumn}
              onClick={null}
            >
              {SHOW_ALL && this.fieldIsEditable(txn, field) &&
              (showModal ?
                <TransactionMenu txn={txn} />
                :
                <QIconButton
                  autoFocus={fieldActive}
                  aria-label={!showSplitsAndDetails ? 'Show details' : 'Hide details'}
                  ref={fieldActive ? (input) => {
                    this.focusField = input;
                  } : null}
                  focusRipple
                  IconComponent={!showSplitsAndDetails ? ExpandMoreIcon : ExpandLessIcon}
                  onClick={() => callbacks.handleMenu(showSplitsAndDetails ? 'hideDetails' : 'showDetails', txn)}
                  size="small-target"
                />)}
            </RegListItem>,
          );
          break;
        }

        // ------------------------------------------------
        // SAVE FIELD
        // ------------------------------------------------
        case 'save':
          retRow.push(
            <RegListItem
              {...attributes}
              tabIndex={null}
              onClick={forceStatic ? null : () => callbacks.handleMenu('save', txn)}
              className={classNames(classes.iconButtonColumn, forceStatic ? 'nopoint skinny' : 'skinny')}
            >
              {editable && SHOW_ALL &&
                <QIconButton
                  aria-label="Save Transaction"
                  autoFocus={fieldActive}
                  focusRipple
                  ref={fieldActive ?
                    (input) => {
                      this.focusField = input;
                    } : null}
                  disabled={forceStatic}
                  IconComponent={SaveIcon}
                  IconProps={{
                    className: classNames(classes.reviewed, 'save'),
                  }}
                  size="small-target"
                  tooltip="Save Transaction"
                  TooltipProps={{ distance: 2 }}
                />}
            </RegListItem>,
          );
          break;

        // ------------------------------------------------
        // CLOSE FIELD
        // ------------------------------------------------
        case 'close':
          if (txnCancelExtraColumn) {
            retRow.push(
              <RegListItem
                {...attributes}
                tabIndex={null}
                onClick={forceStatic ? null : () => callbacks.cancelEdit()}
                className={classNames(classes.iconButtonColumn, forceStatic ? 'nopoint skinny' : 'skinny')}
              >
                {editable && SHOW_ALL &&
                <QIconButton
                  aria-label="Set Reviewed State"
                  ref={fieldActive ?
                    (input) => {
                      this.focusField = input;
                    } : null}
                  disabled={forceStatic}
                  autoFocus={fieldActive}
                  focusRipple
                  IconComponent={CloseIcon}
                  IconProps={{
                    className: classNames(classes.reviewed, 'cancel'),
                    disabled: forceStatic,
                  }}
                  size="small-target"
                  tooltip="Cancel Editing"
                  TooltipProps={{ distance: 3 }}
                />}
              </RegListItem>,
            );
          }
          break;


        default:
          log.error(`Bad Field ${field} sent to TransactionRow`);
          break;
      }
    });

    return retRow;
  };

  getColorClass = (showColor, amount) => {
    if (showColor) {
      return Number(amount) > 0 ? ' income' : ' expense';
    }
    return '';
  };

  getColorName = (showColor, amount) => {
    if (showColor) {
      return Number(amount) > 0 ? 'green' : 'red';
    }
    return 'black';
  };

  showTransactionModal = (e) => {
    if (this.isTypeGrossPaycheck) {
      this.props.dialogAlert(
        'Notification',
        msgGrossPaycheck,
        noop,
        ['Ok'],
      );
      return;
    }
    if (e.target === e.currentTarget) { // only open dialog if same root (not inside a child field)
      switch (this.props.txn.type) {
        case 'CASH_FLOW':
          this.props.showTxnDetailsDialog();
          break;
        default:
          assert(false, `unexpected transaction type: ${this.props.txn.type}`);
      }
    }
  };


  render() {
    const { callbacks, txn, editable, shade, lastRow, showSplitWindow, labelPendingAsFuture,
      simpleEdit, longCats, id, refFn, flashTx, scriptId, showGotoTransferInDetails, calendar, splitsDetailsInDialog } = this.props;

    SHOW_ALL = true;

    const panelOpen = (showSplitWindow || !simpleEdit) && editable;
    const showColor = !calendar;

    /* eslint-disable prefer-template */
    const className = (shade ? 'shade' : '') +
      (lastRow ? ' lastRow appear' : '') +
      (panelOpen ? ' panel' : '') +
      (editable ? ' editable appear' : '') +
      (` ${this.props.registerComfort}${isIe ? '-ie' : ''}`) +
      (DateTime.fromISO(txn.postedOn) > (DateTime.now()) && !labelPendingAsFuture && showColor ? ' pending' : '') +
      (isPendingTxn(txn) && process.env.PROJECT === 'acme' && showColor ? ' pending' : '') +
      // TODO temporary way to show the scheduled txn state
      (isUnacceptedScheduledTxn(txn) && showColor ? ' pending' : '') +
      (this.getColorClass(!showColor, txn.amount));

    const showSplitsAndDetails = editable && !splitsDetailsInDialog && (showSplitWindow || !simpleEdit);
    const fieldsToRender = this.renderFields({ scriptId, showSplitsAndDetails, txn, lastRow, showColor });

    const ClickAwayComponent = editable ? ClickAwayListener : React.Fragment;
    const clickAwayProps = editable ? { onClickAway: this.onClickAway } : {};

    const showModal = isAcme && this.props.txnDetailsModal;
    let isSplitsEditable = true;
    if (this?.isTypeGrossPaycheck) {
      isSplitsEditable = false;
    } else {
      isSplitsEditable = isBankOwnedPending(txn) ? !this.props.isC2REnabled : true;
    }

    return (
      <RegTxList
        ref={refFn}
        aria-label={scriptId}
        id={id}
        key={`${txn.id}`}
        className={className}
        onMouseEnter={() => this.setState({ showIcons: true })}
        onMouseLeave={() => this.setState({ showIcons: false })}
      >
        <div {...(this.props.qcardId ? { id: this.props.qcardId } : {})}>
          {/* --------------------------------------------------- */}
          {/* -- ALL ROW FIELDS -- */}
          {/* --------------------------------------------------- */}

          <ClickAwayComponent
            {...clickAwayProps}
            key={`cac:${txn.id}`}
          >
            <RegTxListRow
              className={classNames(flashTx ? 'flashTx' : '', className)}
              onClick={this.isTypeGrossPaycheck || showModal ? this.showTransactionModal : undefined}
            >
              {fieldsToRender}
              <Dump obj={txn} />
            </RegTxListRow>
          </ClickAwayComponent>

          {/* --------------------------------------------------- */}
          {/* -- SPLITS/DETAILS PANEL -- */}
          {/* --------------------------------------------------- */}
          {showSplitsAndDetails &&
            <SplitsAndDetailsPanel
              autoFocus
              txn={txn}
              closeFn={() => callbacks.hideModal('splits')}
              saveFn={this.saveTransaction}
              deleteFn={() => callbacks.handleMenu('delete', txn)}
              onAmountChange={callbacks.onAmountChange}
              longCats={longCats}
              showCurrencySymbol={this.props.showCurrencySymbol}
              onSizeChange={this.props.sizesChanged}
              showDetails={!simpleEdit}
              showSplits={showSplitWindow}
              unMatchAction={() => callbacks.handleMenu('unMatch', txn)}
              goToTransferAction={showGotoTransferInDetails ? () => callbacks.handleMenu('transfer', txn) : null}
              getSaveFn={callbacks.detailsRefSaveFn}
              splitSwitch={(on) => on ? callbacks.handleMenu('split', txn) : callbacks.handleMenu('hideSplit', txn)}
              editable={isSplitsEditable}
            >
            </SplitsAndDetailsPanel>}
        </div>
      </RegTxList>
    );
  }
}

TransactionRow.defaultProps = {
  shade: false,
  lastRow: false,
  editable: false,
  showBalance: true,
  showAccounts: false,
};

TransactionRow.propTypes = {
  theme: PropTypes.object,
  accountIds: PropTypes.object,
  refFn: PropTypes.func,
  sizesChanged: PropTypes.func,
  id: PropTypes.string,
  callbacks: PropTypes.object,
  txn: PropTypes.object,
  editable: PropTypes.bool,
  shade: PropTypes.bool,
  lastRow: PropTypes.bool,
  classes: PropTypes.object,
  simpleEdit: PropTypes.bool,
  onRowSelect: PropTypes.func,
  selectedTxns: PropTypes.object,
  selected: PropTypes.bool,
  showBalance: PropTypes.bool,
  doubleAmounts: PropTypes.bool,
  editDownloaded: PropTypes.bool,
  showSplitWindow: PropTypes.bool,
  longCats: PropTypes.bool,
  wrapText: PropTypes.bool,
  flashTx: PropTypes.bool,
  onFocusField: PropTypes.func,
  showCurrencySymbol: PropTypes.bool,
  showAccounts: PropTypes.bool,
  registerComfort: PropTypes.string,
  currEditFieldName: PropTypes.string,
  headerFields: PropTypes.object,
  showAccountColors: PropTypes.bool,
  scriptId: PropTypes.string,
  calendar: PropTypes.bool,
  regEditableFields: PropTypes.object,
  sortBy: PropTypes.string,
  qcardId: PropTypes.string,
  wrapperId: PropTypes.string,
  allAccountIds: PropTypes.object,

  // accountPreferences
  accountColorForId: PropTypes.string,

  // QCurrency
  getTxnCurrency: PropTypes.func,

  // QPrefs
  userPreferences: PropTypes.object,
  setUserPreference: PropTypes.func,

  // Generated
  usePayeeField: PropTypes.bool,
  suggestCategoryForUncategorized: PropTypes.bool,
  showGotoTransferInDetails: PropTypes.bool,
  payeeShowFillInfoInMenu: PropTypes.bool,
  payeeAutoFillCat: PropTypes.bool,
  payeeAutoFillAmount: PropTypes.bool,
  payeeAutoFillMemo: PropTypes.bool,
  payeeAutoFillTags: PropTypes.bool,
  txnCancelExtraColumn: PropTypes.bool,
  editRunningBalance: PropTypes.bool,
  payeeCategoryMap: PropTypes.object,
  datasetId: PropTypes.string,
  datasetsById: PropTypes.object,
  scheduledTransactionsById: PropTypes.object,
  transactionsByAccountId: PropTypes.object,
  dialogAlert: PropTypes.func,
  splitsDetailsInDialog: PropTypes.bool,

  showQHelp: PropTypes.func,
  tagsAsHashes: PropTypes.bool,
  showCategorySettings: PropTypes.bool,
  labelPendingAsFuture: PropTypes.bool,
  noDateOnBankPending: PropTypes.bool,
  txnDetailsModal: PropTypes.bool,
  showTxnDetailsDialog: PropTypes.func,
  showInvestmentTransactionDetailsDialog: PropTypes.func,

  isC2REnabled: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {

  const featureFlags = featureFlagsSelectors.getFeatureFlags(state);
  return {
    selected: Boolean(ownProps.selectedTxns?.find((x) => x.id === ownProps.txn.id)),
    suggestCategoryForUncategorized: featureFlags.get('suggestCategoryForUncategorized'),
    showGotoTransferInDetails: true, // featureFlagsSelectors.getFeatureFlags(state).get('showGotoTransferInDetails'),
    payeeCategoryMap: getPayeesForAccounts(state, ownProps),
    accountColorForId: accountColorForId(state, ownProps.txn.accountId),
    usePayeeField: featureFlags.get('usePayeeField'),
    payeeShowFillInfoInMenu: featureFlags.get('payeeShowFillInfoInMenu'),
    payeeAutoFillCat: featureFlags.get('payeeAutoFillCat'),
    payeeAutoFillAmount: featureFlags.get('payeeAutoFillAmount'),
    payeeAutoFillMemo: featureFlags.get('payeeAutoFillMemo'),
    payeeAutoFillTags: featureFlags.get('payeeAutoFillTags'),
    txnCancelExtraColumn: featureFlags.get('txnCancelExtraColumn'),
    enableWindowsReviewedColumn: featureFlags.get('enableWindowsReviewedColumn'),
    editRunningBalance: featureFlags.get('editRunningBalance'),
    tagsAsHashes: featureFlags.get('tagsAsHashes'),
    showCategorySettings: featureFlags.get('showCategorySettings'),
    labelPendingAsFuture: featureFlags.get('labelPendingAsFuture'),
    noDateOnBankPending: featureFlags.get('noDateOnBankPending'),
    splitsDetailsInDialog: featureFlags.get('splitsDetailsInDialog'),
    datasetId: authSelectors.getDatasetId(state),
    datasetsById: datasetsSelectors.getDatasetsById(state),
    scheduledTransactionsById: scheduledTransactionsSelectors.getScheduledTransactions(state),
    transactionsByAccountId: getTransactionsByAccountId(state),
    txnDetailsModal: featureFlags.get('txnDetailsModal'),
    isC2REnabled: isCompareToRegisterEnabled(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showTxnDetailsDialog: () => dispatch(showTxnDetailsDialog({ txn: ownProps.txn })),
  };
}

export default compose(
  withTheme,
  QCurrency(),
  QHelp(),
  QDialogs(),
  QPreferences(),
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(TransactionRow, styles));
